import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { ResponseModel } from "../../shared/models/api-models/api-models";
import { OperatorForLogin } from "../../shared/models/operator/operator.model";
import { ConstantStorage } from "../../shared/models/constants/constant-stroage";
import { CoreSession } from '../../core/core.session';

@Injectable()
export class LoginService {
  private url = "";
  private httpOptions;
  languageId = 1;
  employeeId = 0;
  deviceInfo: string;
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string, private coreSession: CoreSession) {
    this.url = baseUrl + "Login/";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token"
      })
    };
  }


  login(user: OperatorForLogin) {
    this.languageId = +localStorage.getItem(ConstantStorage.LanguageId);
    const urlValue: string = this.url + "Login?languageId=" + this.languageId;;
    return this.http
      .post(urlValue, JSON.stringify(user), this.httpOptions)
      .map(response => {
        localStorage.setItem(ConstantStorage.LanguageId, this.coreSession.selectedLanguageId.toString());
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }

  logout() {
    const urlValue: string = this.url + "Logout";
    return this.http.post(urlValue + '?deviceInfo=' + this.deviceInfo
      + '&operatorId=' + this.coreSession.CurrentOperator.operatorId
      , this.httpOptions).map(response => {
        let result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }

  setLanguageId(languageId: number) {
    const urlValue: string =
      this.url + "SetLanguageId?languageId=" + languageId;
    return this.http.get(urlValue);
  }

  getConfigurationValue(filter: any) {
    const urlValue = this.url + "GetConfigurationValue";
    return this.http
      .get<ResponseModel>(urlValue + "?" + filter)
      .map(response => {
        return response;
      });
  }
  changePassword(oldPassword: string, newPassword: string, userName: string) {
    this.languageId = +localStorage.getItem(ConstantStorage.LanguageId);
    this.employeeId = this.coreSession.CurrentOperator.employeeId;
    const urlValue: string = this.url + "ChangePassword?languageId=" + this.languageId;;
    let operatorObj = {
      'oldPassword': oldPassword,
      'newPassword': newPassword,
      'userName': userName,
      'employeeId': this.employeeId,

    };

    return this.http
      .post(urlValue, JSON.stringify(operatorObj), this.httpOptions)
      .map(response => {
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }
}
