import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject } from "rxjs";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ItemCategoryService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "ItemCategory";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  getItemCategoryList() {
    const urlValue: string = this.url + "/GetItemCategoryList";
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }

  DeleteCategory(categoryId: any) {
    const urlValue = this.url + '/DeleteCategory';
    return this.http.post<ResponseModel>(urlValue, categoryId).map(response => {
      return response;
    });
  }

  getCategoryData(categoryId: number) {
    const urlValue: string = this.url + '/GetCategoryData';
    return this.http.post<ResponseModel>(urlValue, categoryId).pipe(map(
      response => {
        return response;
      }));
  }
  saveCategory(category: FormData) {
    const urlValue = this.url + "/InsertCategory";
    return this.http.post<ResponseModel>(urlValue, category)
      .map(response => {
        return response;
      })
  }
  editCategory(category: FormData) {
    const urlValue = this.url + "/UpdateCategory";
    return this.http.post<ResponseModel>(urlValue, category)
      .map(response => {
        return response;
      })
  }

  checkIfCategoryCodeExist(code: string, categoryId: number) {
    const urlValue = this.url + "/CheckCategoryCodeExist";
    let itemCategory = {
      itemCategoryCode: code,
      itemCategoryId: categoryId
    }
    return this.http.post<ResponseModel>(urlValue, itemCategory).pipe(
      map(response => {
        return response;
      })
    );
  }

  getAllCategories(divisionId: any) {
    const urlValue: string = this.url + '/GetAllCategories';
    return this.http.post<ResponseModel>(urlValue, divisionId).pipe(map(
      response => {

        return response;
      }));
  }
}
