import { Component, OnInit, Input } from '@angular/core';
import { WarehouseTransactionStatusModel } from '../../../models/warehouse-transactions/warehouse-transaction.model';
import { VehicleOperationService } from '../../../../content/vehicle-operation/vehicle-operation.service';
import { ResponseModel } from '../../../models/api-models/api-models';
import { WarehouseTransactionStatus } from '../../../models/enums/warehouse-transaction-status.enum';
import { FormGroup, AbstractControl } from '@angular/forms';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';

@Component({
  selector: 'app-warehouse-trans-status-filter',
  templateUrl: './warehouse-trans-status-filter.component.html',
  styleUrls: ['./warehouse-trans-status-filter.component.css']
})
export class WarehouseTransStatusFilterComponent implements OnInit {

  warehouseTransactionStatusesList: WarehouseTransactionStatusModel[] = [];
  selectedStatusId: number = WarehouseTransactionStatus.Pending.valueOf();
  @Input() parentForm: FormGroup;
  @Input() formProcessMode: FormProcessMode;
  isWarehouseLoading = false;
  isRequired = false;

  constructor(private vehicleOperationService: VehicleOperationService) { }

  ngOnInit() {
    if (this.parentForm.get('warehouseTransactionStatusId').validator != null) {
      const validator = this.parentForm.get('warehouseTransactionStatusId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.FillWarehouseStatuses();
  }
  FillWarehouseStatuses() {
    this.vehicleOperationService
      .getWarehouseTransactionStatuses(this.formProcessMode)
      .subscribe((response: ResponseModel) => {
        if (response.status != null && response.status >= 0) {
          this.warehouseTransactionStatusesList = response.data;
          if (this.formProcessMode == FormProcessMode.QualityControl)
            this.parentForm.get('warehouseTransactionStatusId').setValue(WarehouseTransactionStatus.Approved.valueOf());
          else
            this.parentForm.get('warehouseTransactionStatusId').setValue(WarehouseTransactionStatus.Pending.valueOf());
        }
      });
  }
}
