import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { WarehouseService } from '../../../services/data-definition/warehouse-and-vehicle/warehouse.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { WarehouseTypes } from '../../../models/enums/warehouse-types.enum';

@Component({
  selector: 'app-warehouse-type-filter',
  templateUrl: './warehouse-type-filter.component.html',
  styleUrls: ['./warehouse-type-filter.component.css']
})
export class WarehouseTypeFilterComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() excludeVehicle = false;
  @Input() excludeTruck = false;
  @Output() selectedWarehouseType = new EventEmitter();
  @Input() excludeCustomerVirtualActualWarehouses:boolean = false;
  warehouseTypesList: any[];
  isTypesLoading = false;
  isRequired = false;

  constructor(private warehouseSerive: WarehouseService) {
  }
  ngOnInit() {
    if(this.parentForm.get('warehouseTypeId').validator != null){
      const validator = this.parentForm.get('warehouseTypeId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateWarehouseTypesList();
  }
  populateWarehouseTypesList() {
    this.isTypesLoading = true;
    this.excludeCustomerVirtualActualWarehouses
    this.warehouseSerive.getWarehouseTypes(this.excludeCustomerVirtualActualWarehouses).subscribe(result => {
      if(this.excludeVehicle && result && result.length > 0){
        var index = result.findIndex(x => x.warehouseTypeId === WarehouseTypes.Vehicle.valueOf());
        if(index >= 0){
          result.splice(index, 1);
        }
      }
      if(this.excludeTruck && result && result.length > 0){
        var index = result.findIndex(x => x.warehouseTypeId === WarehouseTypes.TruckWarehouse.valueOf());
        if(index >= 0){
          result.splice(index, 1);
        }
      }
      this.warehouseTypesList = result;
      this.isTypesLoading = false;
    });
}
  onChangeType(selectedType:any) {
    this.selectedWarehouseType.emit(selectedType);
  }
}


