import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { CustomerService } from '../../../../services/data-definition/customer/customers.service';

@Component ({
  selector: 'app-customer-type-filter',
  templateUrl: './customer-type-filter.component.html',
  styleUrls: ['./customer-type-filter.component.css']
})
export class CustomerTypeFilterComponent implements OnInit {


  isClassLoading = false;
  customerTypeDataSource= [];
  @Input() parentForm: FormGroup;
  @Output() onChange:EventEmitter<number>=new EventEmitter<number>();
  isRequired = false;
  constructor(private customerService:CustomerService) { }

  ngOnInit() {
    if(this.parentForm.get('customerTypeId').validator != null){
      const validator = this.parentForm.get('customerTypeId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateTypesList();
  }

  populateTypesList() {
    this.isClassLoading = true;
    this.customerService.getCustomerTypes().subscribe(result => {
      this.customerTypeDataSource = result.data;
      this.isClassLoading = false;
    });
  }
  onCustomerTypeChanged(){
  this.onChange.emit(this.parentForm.value.customerTypeId);
  }

}
