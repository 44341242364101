import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseModel } from '../../models/api-models/api-models';
import { CoreSession } from '../../../core/core.session';
import { Observable } from 'rxjs-compat';


@Injectable({
  providedIn: 'root'
})
export class WebFormService {
  httpOptions;
  private url = '';
  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'WebForm';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  initializeWebFormList(filter) {
    const urlValue: string = this.url + '/InitializeWebFormList';
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }
  getWebFormListData(filter) {
    const urlValue: string = this.url + '/GetWebFormListData';
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }
  initializeWebFormEntry(filter) {
    const urlValue: string = this.url + '/InitializeWebFormEntry';
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }
  saveEntryForm(formData, isEditMode: boolean) {
    const urlValue: string = this.url + (isEditMode ? '/UpdateEntryForm' : '/InsertEntryForm');
    return this.http.post(urlValue, formData).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  fillControlsDataSourceBasedOnParentControlValue(childsControls) {
    const urlValue: string = this.url + '/FillControlsDataSourceBasedOnParentControlValue';
    return this.http.post(urlValue, childsControls, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  checkForUniqueValidation(form) {
    const urlValue: string = this.url + '/CheckForUniqueValidation';
    return this.http.post(urlValue, JSON.stringify(form), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  performActionProcedure(filter) {
    const urlValue: string = this.url + '/PerformActionProcedure';
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

    downloadFile(primaryKey: string, webMenuURL: string, fileName: string, fileType: string): Observable<any> {
        let fileExtension = fileType;
        const urlValue =
            this.url +
            "/DownloadFile?primaryKey=" + primaryKey + "&webMenuURL=" + webMenuURL + "&fileName=" + fileName;

        return this.http.post(urlValue, "", { responseType: "blob" }).map(res => {
            var blob = new Blob([res], { type: fileExtension });
            return blob;
        });
    }
}
