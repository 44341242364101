import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogResult } from '../../models/enums/dialog-result.enum';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreSession } from '../../../core/core.session';
import { ConstantMessages } from '../../models/constants/constant-message';
import { CustomerPointsAction } from '../../models/enums/customer-points-action.enum';
import { CustomerPointsBalanceModel } from '../../models/customer/customer-points-balance.model';

@Component({
  selector: 'app-customer-points-adjustment-dialog',
  templateUrl: './customer-points-adjustment-dialog.component.html',
  styleUrls: ['./customer-points-adjustment-dialog.component.css']
})
export class CustomerPointsAdjustmentDialogComponent implements OnInit {
 //#region [DECLARATIONS]

 @Input() events: Observable<void>;
 @Input() customerPointsObj: CustomerPointsBalanceModel ;
 @ViewChild('content', { static: true }) content: any;
 OkResult: DialogResult = DialogResult.Ok;
 form: FormGroup;
 
 //#endregion

 //#region [CONSTRUCTOR]
  constructor(private modalService: NgbModal, private coreSession: CoreSession,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.initForm();
  }
  ngOnChanges(){
    if (this.customerPointsObj && this.customerPointsObj.pointsBalance >= 0){
      this.fillFormInEditMode();
    }
  }
//#endregion

//#region [EVENTS]  

onSaveClicked(modal) {
  this.fillCustomerPointsObject();
  if (this.form.invalid) // || !this.customerPointsObj.note || this.customerPointsObj.note == '') 
  {
    this.coreSession.markFormGroupTouched(this.form as FormGroup);
    this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
    return;
  }
  if (this.customerPointsObj.actionId == CustomerPointsAction.Redeem.valueOf() && this.newPointsBalanceControl && (this.newPointsBalanceControl.value == 0)) {
    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgValidRedeemValue));
    return;
  }
  if (this.customerPointsObj.actionId == CustomerPointsAction.Redeem.valueOf() && this.newPointsBalanceControl && (this.newPointsBalanceControl.value > this.customerPointsObj.pointsBalance)) {
    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgCustomerPointsValueValidation));
    return;
  }
  else {
    this.saveData(modal);
  }
}


//#endregion

 //#region [METHODS]
 saveData(modal) {
  modal.close(DialogResult.Ok);

}
fillCustomerPointsObject() {
    this.customerPointsObj.newPointsBalance =  + (this.newPointsBalanceControl ? this.newPointsBalanceControl.value : 0);
}
initForm() {
  this.form = new FormGroup({
    oldPointsBalance: new FormControl(null),
    newPointsBalance: new FormControl(null,Validators.required)
  });
}
fillFormInEditMode() {
  this.oldPointsBalanceControl.setValue(this.customerPointsObj.pointsBalance);
  this.newPointsBalanceControl.setValue(this.customerPointsObj.newPointsBalance);

  // disable 
  this.oldPointsBalanceControl.disable();
}
public showDialog() {
  return this.modalService.open(this.content, { centered: true }).result.then(
    (result) => {
      if (result === DialogResult.Ok) {
        return this.customerPointsObj;
      } else {
        return -1;
      }
    });
}
//#endregion

//#region [PROPERTIES]

get oldPointsBalanceControl() {
  return this.form.get('oldPointsBalance');
}
get newPointsBalanceControl() {
  return this.form.get('newPointsBalance');
}

//#endregion

}
