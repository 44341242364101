import { Component, OnInit } from "@angular/core";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { CoreSession } from "./core/core.session";
import { ConstantStorage } from "./shared/models/constants/constant-stroage";
import { LoginService } from "./content/login/login.service";
import { SessionDataProvider } from './core/session-data-provider.service';
import { DeviceDetectorService } from "ngx-device-detector";
// import { ConnectionService } from "ng-connection-service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    public coreSession: CoreSession,
    private loginService: LoginService,
    private sessionDataProvider: SessionDataProvider,
    private deviceService: DeviceDetectorService,
    // private connectionService: ConnectionService
  ) {
    this.setSystemLanguages();
  }

  ngOnInit() {
    this.loginService.deviceInfo = this.deviceService.getDeviceInfo().userAgent;
    this.coreSession.selectedLanguageStr = this.translate.currentLang;
    this.OnLanguageChange();
    // this.subscribeToConnectionState();
  }

  setSystemLanguages() {
    // this.translate.addLangs(["en", "ar"]);
    // this.translate.setDefaultLang("en");

    // let localStorageLang = "";
    // this.coreSession.selectedLanguageId = +localStorage.getItem(
    //   ConstantStorage.LanguageId
    // );
    // if (this.coreSession.selectedLanguageId === 1) {
    //   localStorageLang = "en";
    // } else if (this.coreSession.selectedLanguageId == 2) {
    //   localStorageLang = "ar";
    // } else {
    //   localStorageLang = "en"; // Default language
    // }
    // this.translate.use(
    //   localStorageLang.match(/en|ar/) ? localStorageLang : "en"
    // );
    let allLangs = this.sessionDataProvider.getSessionLanguages();

    let langsCode = allLangs.filter(a => a.active == true).map(a => a.code);

    this.translate.addLangs(langsCode);

    this.translate.setDefaultLang(langsCode[0]);

    this.coreSession.selectedLanguageId = +localStorage.getItem(ConstantStorage.LanguageId);

    if(this.coreSession.selectedLanguageId){
      let currentLangCode = allLangs.filter(a => a.languageId == this.coreSession.selectedLanguageId)[0].code;
      this.translate.use(currentLangCode);
    }else{
      this.translate.use(langsCode[0]);
    }
  }

  OnLanguageChange() {
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.coreSession.selectedLanguageStr = event.lang;
    //   if (event.lang == "ar") {
    //     document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    //     document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
    //     localStorage.setItem(ConstantStorage.LanguageId, "2");
    //     this.coreSession.selectedLanguageId = 2;
    //     this.coreSession.isRTL = true;
    //     this.UpdateServeLanguage();
    //   } else {
    //     document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    //     document.getElementsByTagName("html")[0].setAttribute("lang", "en");
    //     localStorage.setItem(ConstantStorage.LanguageId, "1");
    //     this.coreSession.selectedLanguageId = 1;
    //     this.coreSession.isRTL = false;
    //     this.UpdateServeLanguage();
    //   }
    // });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.coreSession.selectedLanguageStr = event.lang;
      let selectedLanguage = this.sessionDataProvider.getSessionLanguages().filter(a => a.code == event.lang)[0];
      if (selectedLanguage.rtl) {
        document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
        this.coreSession.isRTL = true;

      } else {
        document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
        this.coreSession.isRTL = false;
      }
      document.getElementsByTagName("html")[0].setAttribute("lang", selectedLanguage.code);
      localStorage.setItem(ConstantStorage.LanguageId, selectedLanguage.languageId);
      this.coreSession.selectedLanguageId = selectedLanguage.languageId;
      this.UpdateServeLanguage();

    });
  }

  UpdateServeLanguage() {
    if (this.coreSession.CurrentOperator.isLoggedIn) {
      this.loginService
        .setLanguageId(this.coreSession.selectedLanguageId)
        .subscribe(res => {});
    }
  }
  setSystemLanguages2(){

    let allLangs = this.sessionDataProvider.getSessionLanguages();

    let langsCode = allLangs.filter(a => a.active ).map(a => a.code);

    this.translate.addLangs(langsCode);

    this.translate.setDefaultLang(langsCode[0]);


    let localStorageLang = '';
    this.coreSession.selectedLanguageId = +localStorage.getItem(ConstantStorage.LanguageId);

    if(this.coreSession.selectedLanguageId === 1 ){
      localStorageLang = "en";
    }else if(this.coreSession.selectedLanguageId == 2){
      localStorageLang = "ar";
    }else{
      localStorageLang = "en" ; // Default language
    }
    this.translate.use(localStorageLang.match(/en|ar/) ? localStorageLang : 'en');
  }

  OnLanguageChange2(){
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {
      this.coreSession.selectedLanguageStr =event.lang ;
      if(event.lang == 'ar')
      {
        document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl');
        document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
        localStorage.setItem(ConstantStorage.LanguageId, "2");
        this.coreSession.selectedLanguageId = 2 ;
        this.coreSession.isRTL = true ;
        this.UpdateServeLanguage();
      }
      else
      {
        document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr');
        document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
        localStorage.setItem(ConstantStorage.LanguageId, "1");
        this.coreSession.selectedLanguageId = 1 ;
        this.coreSession.isRTL = false ;
        this.UpdateServeLanguage();
      }
    })
  }

  // subscribeToConnectionState() {
  //   this.connectionService.monitor().subscribe(isConnected => {  
  //    this.coreSession.isInternetConnected = isConnected;
  //     if (isConnected) {  
  //       this.coreSession.updateConnectionState(true);
  //     }  
  //     else {  
  //       this.coreSession.updateConnectionState(false);
  //     }  
  //   });
  // }
}
