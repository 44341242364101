import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { VehicleOperationService } from '../../../../content/vehicle-operation/vehicle-operation.service';
import { WarehouseService } from '../../../services/data-definition/warehouse-and-vehicle/warehouse.service';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { VehicleService } from '../../../services/data-definition/warehouse-and-vehicle/vehicle.service';

@Component({
  selector: 'app-vehicle-filter',
  templateUrl: './vehicle-filter.component.html',
  styleUrls: ['./vehicle-filter.component.css']
})
export class VehicleFilterComponent implements OnInit {
  //#region [Declarations]
  @Input() parentForm: FormGroup;
  @Input() formProcessMode: FormProcessMode = FormProcessMode.LoadRequest;

  vehicleFilter: any = {
    employeeId: -1,
    organizationId: -1,
    getFree: false,
    processModeId: -1
  };
  @Output() vehicleSelected: EventEmitter<number> = new EventEmitter<number>();

  vehicleDataSource: any[];
  selectedVehicleId: number;
  isVehicleLoading = false;
  isRequired = false;
  //#endregion
  //#region [Events]
  constructor(private vehicleService: VehicleService) { }

  ngOnInit() {
    if (this.parentForm.get('vehicleId').validator != null) {
      const validator = this.parentForm.get('vehicleId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.vehicleFilter.processModeId = this.formProcessMode.valueOf();
    this.populateVehicleList();
  }
  onVehicleDataChanged() {
    this.selectedVehicleId = this.parentForm.value.vehicleId;
    this.vehicleSelected.emit(this.selectedVehicleId);
  }
  //#endregion
  //#region [Methods]
  populateVehicleList() {
    this.isVehicleLoading = true;
    this.vehicleService.getAllVehicles(this.vehicleFilter).subscribe(
      (result) => {
        this.vehicleDataSource = result;
        this.isVehicleLoading = false;
      }
    );
  }

  //#endregion
}
