import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./content/home/home.component";
import { NgModule } from "@angular/core";
import { ConstantURLs } from "./shared/models/constants/constant-URL";
import { LoginComponent } from "./content/login/login.component";
import { HomeLayoutComponent } from "./core/layout/home-layout.component";
import { LoginLayoutComponent } from "./core/layout/login-layout.component";
import { AuthGuard } from "./core/auth/auth.gurad";
import { MyProfileComponent } from "./content/my-profile/my-profile.component";
import { ChangeLanguageComponent } from "./content/admin-settings/change-language/change-language.component";
import { SetupWizardComponent } from "./content/admin-settings/setup/setup-wizard/setup-wizard.component";
import { SettedUpResolver } from "./shared/resolvers/settedup.resolver";
import { NoMenusAvailableComponent } from './content/no-menus-available/no-menus-available.component';
import { ItemsListsListComponent } from './content/master-data-definition/items-lists-definition/items-lists-list/items-lists-list.component';
import { GeneralSearchResultComponent } from "./core/header/general-search-result/general-search-result.component";

const appRoute: Routes = [
  {
    path: "",
    component: HomeLayoutComponent,
    children: [
      {
        path: ConstantURLs.homeUrl,
        canActivate: [AuthGuard],
        component: HomeComponent
      },
      {
        path: ConstantURLs.searchResultURL,
        component: GeneralSearchResultComponent
      },
      {
        path: ConstantURLs.orderManagementURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/order/order.module').then(m => m.OrderModule)
      },
      {
        path: ConstantURLs.consignmentOrderURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/consignment-order/consignment-order.module').then(m => m.ConsignmentOrderModule)
      },
      {
        path: ConstantURLs.customerOperationsURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/customer-operation/customer-operation.module').then(m => m.CustomerOperationModule)
      },
      {
        path: ConstantURLs.vehicleOperationURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/vehicle-operation/vehicle-operation.module').then(m => m.VehicleOperationModule)
      },
      {
        path: ConstantURLs.warehouseOperationURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/warehouse-operation/warehouse-operation.module').then(m => m.WarehouseOperationModule)
      },
      {
        path: ConstantURLs.dataDefinitionURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/master-data-definition/master-data.module').then(m => m.MasterDataModule)
      },
      {
        path: ConstantURLs.tripManagementURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/trip-managment/trip.module').then(m => m.TripModule)
      },
      {
        path: ConstantURLs.dashboardURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: ConstantURLs.reportURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/report/report.module').then(m => m.ReportModule)
      },
      {
        path: ConstantURLs.employeeOperationURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/employee-operation/employee-operation.module').then(m => m.EmployeeOperationModule)
      },
      {
        path: ConstantURLs.settingsURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/admin-settings/admin-settings.module').then(m => m.AdminSettingsModule)
      },
      {
        path: ConstantURLs.customerAppPropertiesURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/customer-app-properties/customer-app-properties.module')
          .then(m => m.CustomerAppPropertiesModule)
      },
      {
        path: ConstantURLs.serialOperationURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/serial-operation/serial-operation.module')
          .then(m => m.SerialOperationModule)
      },
      {
        path: ConstantURLs.performanceURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/performance/performance.module')
        .then(m => m.AchievementModule)
      },
      {
        path: ConstantURLs.selectLanguageURL,
        component: ChangeLanguageComponent
      },
      {
        path: ConstantURLs.myProfileURL,
        component: MyProfileComponent
      },
      {
        path: ConstantURLs.noMenusURL,
        component: NoMenusAvailableComponent
      },
      {
        path: ConstantURLs.customerItemsListURL,
        canActivate: [AuthGuard],
        component: ItemsListsListComponent
      },
      {
        path: ConstantURLs.recallsMainURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/recalls/recall.module').then(m => m.RecallModule)
      },
      {
        path: ConstantURLs.ForecastingMainURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/forecasting/forecasting.module').then(m => m.ForecastingModule)
      },
      {
        path: ConstantURLs.proofsURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/proofs/proof.module').then(m => m.ProofModule)
      },
      {
        path: ConstantURLs.qcMainURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/quality-controls/quality-control.module').then(m => m.QualityControlModule)
      },
      {
        path: ConstantURLs.dataReviewMainURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/data-review/data-review.module').then(m => m.DataReviewModule)
      },
      {
        path: ConstantURLs.roiMainURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/roi/roi.module').then(m => m.ROIModule)
      },
      {
        path: ConstantURLs.chattingURL,
        canActivate: [AuthGuard],
        loadChildren: () => import('./content/chatting/chatting.module').then(m => m.ChattingModule)
      },
      { path: "", redirectTo: "/" + ConstantURLs.homeUrl, pathMatch: "full" }
    ]
  },
  {
    path: "",
    component: LoginLayoutComponent,
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: "login",
        component: LoginComponent,
        resolve: { isSettedUp: SettedUpResolver }
      },
      {
        path: 'setup',
        component: SetupWizardComponent
      }

    ]
  },
  { path: "**", redirectTo: "/" + ConstantURLs.homeUrl, pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoute)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
