export enum GlobalErrors {
    NotInitialized = 0,
    Success = 1,
    Error = 2,
    NoMoreRows = 3,
    SuccessWithZeroRowAffected = 4,
    NotEnoughQuantity = 5,
    PasswordNotMatch = 6,
    ValueIsMoreThanRequested = 7,
    PasswordShouldBeChanged = 8,
    LoggedInOtherSession = 9
}

export enum LicenseResult {
    Licensed = 1,
    Error = 2,
    Unauthorized = 3,
    LicenseServerError = 4,
    NearToExpired = 5
}