export enum NotificationTypes {
    None = 0,
    Email = 1,
    Delivery = 2,
    Chatting = 3,
    UpdateChattingUserStatus = 4,
    AddRemoveChattingAssignment = 5,
    EmployeeKeys = 6,
    TransferOutIN = 7,
    FinalIncentive = 8,
    SupportData = 9,
    CreateNewOrder = 10,
    Recall = 11,
    QualityControl = 12,
    SetMessageAsRead = 13,
    Fawri = 14,
    DiscountKeys = 15,
    Promotion = 16,
    ChattingMediaDownloaded = 17,
    SetMessageAsReceived = 18,
}
