import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject } from "rxjs";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DiscountService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "Discount";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  getDiscounts(filter: any) {
    const urlValue: string = this.url + '/GetDiscounts';
    return this.http.post<ResponseModel>(urlValue, filter).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }
  getDiscountData(filter) {
    const urlValue: string = this.url + '/GetDiscountData';
    return this.http.post<ResponseModel>(urlValue, filter).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }
  getDiscountTypes() {
    const urlValue: string = this.url + '/GetDiscountTypes';
    return this.http.post<ResponseModel>(urlValue, "").map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }
  getSalesOrderTypes() {
    const urlValue: string = this.url + '/GetSalesOrderTypes';
    return this.http.post<ResponseModel>(urlValue, "").map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }
  saveDiscount(formData: FormData, isEditMode: boolean) {
    const urlValue = this.url + (isEditMode ? '/UpdateDiscount' : '/InsertDiscount');
    return this.http
      .post<ResponseModel>(urlValue, formData)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  updateDiscountActiveStatus(filter) {
    const urlValue: string = this.url + '/UpdateDiscountActiveStatus';
    return this.http.post(urlValue, filter).map(
      (response) => {
        let result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  deleteDiscount(discountId: number) {

    const urlValue = this.url + '/DeleteDiscount';
    return this.http
      .post<ResponseModel>(urlValue, discountId)
      // '?priceListId=' +          priceListId,'')
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
}
