export enum MenuActions {
  IsAvailable = 0,
  Add = 1,
  Edit = 2,
  Delete = 3,
  Void = 4,
  Collect = 5,
  View = 6,
  Approve = 7,
  Disapprove = 8,
  Generate = 9,
  AssignBoxes = 10,
  ReleaseBoxes = 11,
  Cancel = 12,
  Execute = 13,
  Clone = 14,
  Dispatch = 15,
  RemoveAssignment = 16,
  SendLoadRequest = 17,
  Reschdule = 18,
  InvoiceOrder = 19,
  Delivery = 20,
  Receive = 21,
  Adjust = 22,
  Redeem = 23,
  Close = 24,
  Reverse = 25,
  Print = 26,
  Bounce = 27,
  Confirm = 28,
  Resend = 29,
  Reset = 30,
  Offload = 31,
  DivisionsPaymentTerm = 32,
  DivisionsOnHoldStatus = 33,
  Reject = 34,
  Process = 35,
  SendNotification = 36,
  PostPayment = 37,
  AccessSecurityData = 38
}
