import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MasterDataService } from '../../../../content/master-data-definition/master-data.service';
import { CoreSession } from '../../../../core/core.session';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { MasterDataStatus } from '../../../models/enums/master-data-screens';
import { ReasonModel } from '../../../models/reason-model/reason-model';

@Component({
  selector: 'app-reason-filter',
  templateUrl: './reason-filter.component.html',
  styleUrls: ['./reason-filter.component.css']
})
export class ReasonFilterComponent implements OnInit, OnChanges {
  @Output('Result') result = new EventEmitter();
  @Output() onReasonSelected = new EventEmitter();
  @Input() reasonObject = new ReasonModel();
  @Input() parentForm: FormGroup;
  reasonDataSource: any[];
  label: string = '';
  isRequired = false;
  hideReasonFilter = false;
  constructor(
    public coreSession: CoreSession,
    private translateService: TranslateService,
    private masterDataService: MasterDataService
  ) { }

  ngOnInit() {
    
    if (this.parentForm.get('reasonId').validator != null) {
      const validator = this.parentForm.get('reasonId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
  }

  ngOnChanges() {
    
    if (this.reasonObject) {
      this.label = this.reasonObject.status == MasterDataStatus.Activate ? 'Desc_Activation_Reason' : 'Desc_Deactivation_Reason';
      this.fillActivateDeactivateReasons();
    }
  }


  fillActivateDeactivateReasons() {
    this.parentForm.get('reasonId').setValue(null);
    this.reasonDataSource = [];
    this.coreSession.ModalLoading.Show();
    this.masterDataService.getActivateDeactivateReason(this.reasonObject.screenId, this.reasonObject.status).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data && response.data.length > 0) {
            this.reasonDataSource = response.data;
            this.hideReasonFilter = true;
            this.isRequired = true;
            this.parentForm.get('reasonId').setValidators(Validators.required)
            this.parentForm.get('reasonId').updateValueAndValidity();
          } else {
            this.hideReasonFilter = false;
            this.isRequired = false;
            this.parentForm.get('reasonId').setValidators(null)
            this.parentForm.get('reasonId').updateValueAndValidity();
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      })
  }

  onChangeReason(selectedReason) {
    this.onReasonSelected.emit(selectedReason);
  }

}
