import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { FormGroup, AbstractControl } from '@angular/forms';
import { PackGroupFilter } from './pack-group-filter-interface';
import { PackGroupService } from '../../../services/data-definition/product/pack-group.service';

@Component({
  selector: 'app-pack-group-filter',
  templateUrl: './pack-group-filter.component.html',
  styleUrls: ['./pack-group-filter.component.css']
})
export class PackGroupFilterComponent implements OnInit {

  @Input() packGroupFilter: PackGroupFilter = {
    currentFormControlName: 'packGroupId',
    getGroupsHavingSamePackQuantity: false,
  }
  @Input() hideLabel=false;
  @Input() includesPacks = false;
  @Input() parentForm: FormGroup;
  @Input() allowedPackGroups:any[] = null;
  @Output() onPackGroupSelected = new EventEmitter();
  packGroupsDataSource: any[];
  isGroupsLoading = false;
  isRequired = false;

  constructor(private packGroupService: PackGroupService) {
  }

  ngOnInit() {
    if (this.parentForm.get(this.packGroupFilter.currentFormControlName).validator != null) {
      const validator = this.parentForm.get(this.packGroupFilter.currentFormControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populatePackGroupsList();
  }
  populatePackGroupsList() {
    this.isGroupsLoading = true;
    var filter = {
      getGroupsHavingSamePackQuantity : this.packGroupFilter.getGroupsHavingSamePackQuantity,
      selectedOraganizationIds : '',
      includePacks : this.includesPacks,
      allowedPackGroupIds : this.allowedPackGroups
    }
    this.packGroupService.getPackGroups(filter).subscribe(result => {
      this.packGroupsDataSource = result.data;
      this.isGroupsLoading = false;
    });
  }
  onChangePackGroup(selectedPackGroup) {
    this.onPackGroupSelected.emit(selectedPackGroup);
  }
}

