import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { ItemService } from "../../../services/data-definition/product/item.service";
import { FormProcessMode } from "../../../models/enums/form-process-mode.enum";
import { TransactionMainTypes } from "../../../models/enums/transaction.main.types.enum";
import { TotalsModel } from "../../../models/Item/totals.model";
import {
  ItemPackModel,
  ItemCategoryModel,
  ItemDivisionModel,
} from "../../../models/Item/item.model";
import { CoreSession } from "../../../../core/core.session";
import { CalculationService } from "../../../services/calculations.service";
import { SalesTransactionTypes } from "../../../models/enums/sales-transaction-types";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import { WarehouseTransactionStatus } from "../../../models/enums/warehouse-transaction-status.enum";
import { DialogMode } from "../../../models/enums/dialog-mode.enum";
import { DialogResult } from "../../../models/enums/dialog-result.enum";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject } from "rxjs";
import * as cloneDeep from "lodash/cloneDeep";
import { WarehouseStockService } from "../warehouse-stock-service";
import { BatchSelectionComponent } from "../../batch/batch-selection/batch-selection.component";
import { SerialsPropertiesInterface } from "../../Serials/serials-properties.interface";
import { SerialStatus } from "../../../models/enums/SerialStatus";
import { DateTimeModel } from "../../../models/dateTimeModel/date-time.model";
import { InvalidSerialsComponent } from "../../Serials/invalid-serials/invalid-serials.component";
import { OrderStatus } from "../../../models/enums/order-status.enum";
import { SharedDialogComponent } from "../../shared-dialog/shared-dialog.component";
import { OrderTypes } from "../../../models/enums/order-types.enum";
import {
  NgbModal,
  NgbModalConfig,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { IDateTimePickerProperties } from "../../../models/dateTimeModel/date-time-properties.interface";
import { DatePipe } from "@angular/common";
import { DiscountValueTypes } from "../../../models/enums/discount-value-types.enum";
import { CustomerTypes } from "../../../models/enums/customer-types.enum";

@Component({
  selector: "app-item-list",
  templateUrl: "./item-list.component.html",
  styleUrls: ["./item-list.component.css"],
})
export class ItemsListComponent implements OnInit, OnDestroy {
  //#region [DECLARATIONS]
  @ViewChild("returnReasonModal", { static: true })
  returnReasonModal: NgbModal;
  @ViewChild("transactionLevelPackStatusModal", { static: true })
  transactionLevelPackStatusModal: NgbModal;
  modalRef: NgbModalRef;
  saveBatchesSubject: Subject<void> = new Subject<void>();
  @Input("StockWarehouseId") stockWarehouseId = -1;
  @Input("DetailsWarehouseId") detailsWarehouseId = -1;
  @Input("RefWarehouseId") refWarehouseId = -1;
  @Input("DivisionId") divisionId = -1;
  @Input("CustomerId") customerId = -1;
  @Input("OutletId") outletId = -1;
  @Input("CustomerTypeId") customerTypeId = -1;
  @Input("ItemProcessMode") formProcessMode: FormProcessMode =
    FormProcessMode.Orders;
  @Input("TransactionMainType") transactionMainType: TransactionMainTypes =
    TransactionMainTypes.CustomerTransaction;
  @Input("ShowTax") showTax = false;
  @Input("ShowRetailTax") ShowRetailTax = false;
  @Input("ShowGross") showGross = true;
  @Input("ShowDiscount") showDiscount = true;
  @Input("ShowNetTotal") showNetTotal = true;
  @Input("ShowTotalQty") showTotalQty = true;
  @Input("EditedTransactionId") editedTransactionId = null;
  @Input("WHTransactionstatus") WHTransactionstatus =
    WarehouseTransactionStatus.None;
  @Input("AddedItems") addedItemsList: ItemPackModel[] = [];
  @Input("orderSignature") orderSignature = "";
  @Input("order") order: any;
  @Input('warehouseTransaction') warehouseTransaction: any;
  @Input('salesTransaction') salesTransaction: any;
  @Input("HideLoadingModal") hideLoadingModal: boolean = true;
  @Input("someItemHasSerial") someItemHasSerial: boolean = false;
  @Input("vendorId") vendorId: number = -1;
  @Input("readingId") readingId: number = -1;
  @Input("isFromPurchaseOrder") isFromPurchaseOrder: boolean = false;
  @Input("executeOperation") executeOperation = false;
  @Input("isFromSSCC") isFromSSCC = false;
  @Input("isFromSSCCMainList") isFromSSCCMainList = false;
  @Input("ssccBarcode") ssccBarcode = "";
  @Input("isQualityControl") isQualityControl = false;
  @Input("allowAddingNewItems") allowAddingNewItems = true;
  @Input() requestedBy = -1;
  @Input() isFullyOffload: boolean;
  @Input("orderTypeId") orderTypeId = -1;
  @Input("returnReasonDescriptions") returnReasonDescriptions: any[] = [];
  @Input("hidePriceInReturn") hidePriceInReturn: boolean = false;
  @Input("hideDiscountInReturn") hideDiscountInReturn: boolean = false;
  @Input("isEditMode") isEditMode: boolean = false;
  @Input("isReturnItem") isReturnItem: boolean = false;
  @Input("restrictReturnFromInvoices") restrictReturnFromInvoices: boolean = false;
  @Input("restrictReturnQtyFromInvoices") restrictReturnQtyFromInvoices: boolean = false;
  @Input("isDamagedWHSelected") isDamagedWHSelected: boolean = false;
  @Output("CheckOutEvent") checkOutEvent = new EventEmitter();
  @Output("ItemChangeEvent") itemChangeEvent = new EventEmitter();
  @Output("ValueChangeEvent") valueChangeEvent = new EventEmitter();
  @Output() onImportCompleted = new EventEmitter();

  packObjectTest: any[];
  @Input("totals") totals: TotalsModel = new TotalsModel();
  itemsList: ItemPackModel[];
  allAddedPackObjects: any[];
  originalItemsList: ItemPackModel[];
  allItemsStockList: any[] = [];
  roiPackList: any[] = [];
  priceRangeList: any[] = [];
  itemsExceedAvlList: ItemPackModel[] = [];
  showAddItemsBySerial: boolean = false;
  selectedWarehouseId: number = -1;
  selectedVehicleId: number = -1;
  warehouseId: number = -1;
  vehicleId: number = -1;
  requiredQuantity: number = -1;
  quantityMustMatch: boolean = false;
  showAddSerialsBtn: boolean = true;
  showImportSerialsBtn: boolean = false;
  showImportSerialsSlider: boolean = false;
  itemImagePath =
    document.getElementsByTagName("base")[0].href + "Resources/items-img/";
  itemNoProductImagePath =
    document.getElementsByTagName("base")[0].href + "Resources/";
  isLoading = false;
  categoryList: ItemCategoryModel[];
  divisionList: ItemDivisionModel[];
  originalCategoryList: ItemCategoryModel[];
  searchForm: FormGroup;
  selectedDivisionId = null;
  selectedCategoryId = null;
  searchItemFilterString = "";
  pageSize = this.coreSession.pageSize;
  pageIndex = 0;
  totalItemsCountinPagination = 0;
  isFilterOpend = false;
  warehouseTransactionStatus: WarehouseTransactionStatus;
  isSerialItemsExists: boolean = false;
  showBatchesSlider = false;
  selectedItemPackObject: any = {};
  showSerialsSlider = false;
  showSelectSecondaryPackSlider = false;
  saveSerialsSubject: Subject<void> = new Subject<void>();
  saveSecondaryPackSubject: Subject<void> = new Subject<void>();
  saveSerialsSubjectBySerialNo: Subject<void> = new Subject<void>();
  saveAddedItemsBySerialSubject: Subject<void> = new Subject<void>();
  serialsProperties: SerialsPropertiesInterface;
  checkForAvailableItemLevel = false;
  showVehicleQTY = false;
  showMWHQty = false;
  showAvlQty = false;
  showRequestedQty = false;
  checkForAvailableBatchLevel = false;

  blockIfExceedAvailable = false;
  confirmationIfExceedAvailable = false;

  enableBatchSelection = false;
  enableBatchInsertion = false;
  useDefaultBatch = false;

  calculatePendingQty = false;
  isROIScreen = false;
  showROIPrice = false;
  showROITax = false;
  showROIDiscountOnPackLevel = false;
  showROIDiscountOnTransLevel = false;
  selectedROIDiscountTypeId = DiscountValueTypes.Percentage.valueOf();
  selectedROIDiscount = 0;
  selectedROIDiscountPercentage = 0;
  roiPriceClass = "";
  // Configurations =>
  CheckMainWHStockForLoad = false;
  UseDefaultExpiryAndBatchForItems = false;
  AllowBatchSelectionInWarehouseOperations = false;
  AutoExecuteLoadRequestInBO = false;
  AutoExecuteOffloadRequestInBO = false;
  AllowTripGeneration = false;
  LimitBOSalesToWarehouseStock = false;
  RestoreBOReturnToWarehouse = false;
  AllowEditItemInTransfer = false;
  allowEditPrice: boolean = false;
  AllowBatchSelectionInDelivery = false;
  enableAddSerial = false;
  enableAddSecondaryPack = false;
  disableItemsInTransferIn = false;
  addedSerialsForAllPacks = [];
  addSerialCaption = "Desc_Add_Serials";
  filterOnSalesType = false;
  showSerialsAsRanges = true;
  checkAvailableCreditToLoad = "";
  allowEditDiscount = false;
  //#endregion

  filledQtyColor = "#f8f9fa"; // filled qty color
  noColor = "#FFF"; // no color
  exceedAvlColor = "#FFE4B5"; // exceed available qty color
  isCartExpanded = false;
  focColor = "#d8b5b5";

  invalidSerials = [];
  enableAddItemByLiters = false;
  secondaryPacksList: any[] = [];
  selectedSecondaryPack: any;
  @ViewChild("secondarySelectionDialog", { static: true })
  secondarySelectionDialog: SharedDialogComponent;
  useWeightPriceInOrder = false;
  useReturnPriceListForReturns = false;
  @ViewChild("invalidSerialsDialog", { static: true })
  invalidSerialsDialog: InvalidSerialsComponent;
  isFOCMode = false;
  @Input('isExchangeMode') isExchangeMode = false;
  focEnumValue = SalesTransactionTypes.FOC.valueOf();
  allowTax = false;
  allowTaxOverFreeItems = false;

  showReturnReason: boolean = false;
  returnReasonForm: FormGroup;
  returnReasonDataSource: any[] = [];
  packStatusDataSource: any[] = [];
  isReturnReasonLoading: boolean = false;
  isPackStatusLoading: boolean = false;
  expiryDateProperties: IDateTimePickerProperties = {
    label: "Desc_Expiry_Date",
    formControlName: "expiryDate",
    labelHidden: true,
    isCalendarOnly: true
  };
  forceSelectingPackStatusPerTransaction: boolean = false;
  showExpiryInReturnsAndExchange: boolean = false;
  enableEnteringBatchNoInReturn: boolean = false;
  enterExpiryDateForGoodReturnsOnly: boolean = false;
  isCurrentPackResellable: boolean = false;
  selectedPack: ItemPackModel;
  selectedExpiryDate: any;
  copyOfOrderAddedItems: any[] = [];
  txLevelPackStatus: any;
  defaultReturnReason: any;
  defaultPackStatus: any;
  showQuantityInOrder = false;
  excludedOrderId = null;
  isZeroAvItems: boolean;
  isZeroAvlWareHouse:boolean;

  forceReturnWithSoldPrice: boolean = false;

  enableReturnBatchFromInvoice: boolean = false;
  allowPartialDeliveryInProcessReturn: boolean = false;

  showAvailabilityWarning: boolean = false;

  hideDeleteInProcess: boolean = false;
  showTaxInReturn: boolean = false;
  allowRetailTaxOnItems: boolean = false;

  //exchange
  forceEqualityInExchange = false;
  forceSalesGreaterThanOrEqualsReturnInExchange = false;
  forceSalesLessThanOrEqualsReturnInExchange = false;
  ExchangeRuleForCashCustomers = "";
  ExchangeRuleForCreditCustomers = "";
  AcceptedToleranceInExchange = "";
  AcceptedPercentageOfToleranceInExchange = "";
  returnNetTotal: number = 0;
  salesNetTotal: number = 0;
  AllowAddExchangeOrderWithoutReturnDetails = false;
  isTolerated = false;
  areItemsLoading: boolean = false;

  showTaxInWhOperations: boolean = false;

  allowReturnWithZeroPrice: boolean = false;
  allowEditPriceInOrder: boolean = false;
  checkPriceLimit: boolean = false;
  allowEditPriceConfiguration: boolean = false;
  editedItem: ItemPackModel = null;
  @ViewChild("editPriceDialog", { static: true }) editPriceDialog: NgbModal;
  OkResult: DialogResult = DialogResult.Ok;
  isPriceChanged: boolean = false;
  //#region [CONSTRUCTOR]

  constructor(
    public coreSession: CoreSession,
    private calculationService: CalculationService,
    private itemService: ItemService,
    private translateService: TranslateService,
    private sessionData: SessionDataProvider,
    private warehouseStockService: WarehouseStockService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private datepipe: DatePipe
  ) {
    config.backdrop = true;
  }

  ngOnInit() {
    this.copyOfOrderAddedItems = cloneDeep(this.addedItemsList);
    this.filterOnSalesType =
      (this.isFromSSCC && (this.isDelivery() || this.isPartialDelivery())) ||
      this.formProcessMode === FormProcessMode.Sales ||
      (this.formProcessMode === FormProcessMode.Delivery && this.sessionData.getConfigurationValue(ConstantConfigurations.AllowBatchSelectionInDelivery).toLowerCase() === "true");
    this.itemImagePath =
      document.getElementsByTagName("base")[0].href + "Resources/items-img/";
    this.useWeightPriceInOrder =
      this.sessionData
        .getConfigurationValue(ConstantConfigurations.UseWeightPriceInOrder)
        .toLowerCase() === "true";
    this.useReturnPriceListForReturns = this.sessionData.getConfigurationValue(ConstantConfigurations.UseReturnPriceListForReturns).toLowerCase() === "true";
    this.CheckMainWHStockForLoad =
      this.sessionData
        .getConfigurationValue(ConstantConfigurations.CheckMainWHStockForLoad)
        .toLowerCase() === "true";
    this.showReturnReason =
      this.sessionData
        .getConfigurationValue(ConstantConfigurations.ShowReturnReasn)
        .toLowerCase() === "true";
    this.UseDefaultExpiryAndBatchForItems =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.UseDefaultExpiryAndBatchForItems
        )
        .toLowerCase() === "true";
    this.AllowBatchSelectionInWarehouseOperations =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.AllowBatchSelectionInWarehouseOperations
        )
        .toLowerCase() === "true";
    this.AutoExecuteLoadRequestInBO =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.AutoExecuteLoadRequestInBO
        )
        .toLowerCase() === "true";
    this.AutoExecuteOffloadRequestInBO =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.AutoExecuteOffloadRequestInBO
        )
        .toLowerCase() === "true";
    this.AllowTripGeneration =
      this.sessionData
        .getConfigurationValue(ConstantConfigurations.AllowTripGeneration)
        .toLowerCase() === "true";
    this.LimitBOSalesToWarehouseStock =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.LimitBOSalesToWarehouseStock
        )
        .toLowerCase() === "true";
    this.RestoreBOReturnToWarehouse =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.RestoreBOReturnToWarehouse
        )
        .toLowerCase() === "true";
    this.AllowEditItemInTransfer =
      this.sessionData
        .getConfigurationValue(ConstantConfigurations.AllowEditItemInTransfer)
        .toLowerCase() === "true";
    this.AllowBatchSelectionInDelivery =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.AllowBatchSelectionInDelivery
        )
        .toLowerCase() === "true";
    this.checkAvailableCreditToLoad = this.sessionData.getConfigurationValue(
      ConstantConfigurations.CheckAvailableCreditToLoad
    );
    this.forceSelectingPackStatusPerTransaction =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.ForceSelectingPackStatusPerTransaction
        )
        .toLowerCase() === "true";
    this.showExpiryInReturnsAndExchange =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.ShowExpiryInReturnsAndExchange
        )
        .toLowerCase() === "true";
    this.enableEnteringBatchNoInReturn =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.EnableEnteringBatchNoInReturn
        )
        .toLowerCase() === "true";
    this.enterExpiryDateForGoodReturnsOnly =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.EnterExpiryDateForGoodReturnsOnly
        )
        .toLowerCase() === "true";
    this.allowRetailTaxOnItems = this.sessionData.getConfigurationValue(
      ConstantConfigurations.AllowRetailTaxOnItems).toLowerCase() == 'true';

    this.isFOCMode =
      this.sessionData.getConfigurationValue(ConstantConfigurations.AllowFOC).toLocaleLowerCase() === "true"
      && this.sessionData.getConfigurationValue(ConstantConfigurations.CheckFOCLimit).toLocaleLowerCase() === "false"
      && (this.formProcessMode === FormProcessMode.Orders || this.formProcessMode === FormProcessMode.Sales);
    this.allowTax = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTax).toLowerCase() === "true";
    this.allowTaxOverFreeItems = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTaxOverFreeItems).toLowerCase() === "true";


    this.forceReturnWithSoldPrice = this.sessionData
      .getConfigurationValue(
        ConstantConfigurations.ForceReturnWithSoldPrice
      )
      .toLowerCase() === "true";
    this.allowPartialDeliveryInProcessReturn = this.sessionData
      .getConfigurationValue(
        ConstantConfigurations.AllowPartialDeliveryInProcessReturn
      )
      .toLowerCase() === "true";
    this.ExchangeRuleForCashCustomers = this.sessionData.getConfigurationValue(ConstantConfigurations.ExchangeRuleForCashCustomers);
    this.ExchangeRuleForCreditCustomers = this.sessionData.getConfigurationValue(ConstantConfigurations.ExchangeRuleForCreditCustomers);
    this.AllowAddExchangeOrderWithoutReturnDetails = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowAddExchangeOrderWithoutReturnDetails).toLowerCase() === "true";
    this.AcceptedToleranceInExchange = this.sessionData.getConfigurationValue(ConstantConfigurations.AcceptedToleranceInExchange);
    this.AcceptedPercentageOfToleranceInExchange = this.sessionData.getConfigurationValue(ConstantConfigurations.AcceptedPercentageOfToleranceInExchange);
    this.allowReturnWithZeroPrice = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowReturnWithZeroPrice).toLowerCase() == 'true';
    this.allowEditPriceConfiguration = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowEditPrice).toLowerCase() === "true";
    this.allowEditPriceInOrder = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowEditPriceInOrder).toLowerCase() === "true";
    //edit price
    if (this.allowEditPriceConfiguration && this.allowEditPriceInOrder && this.isOrders() && this.orderTypeId === OrderTypes.Sales.valueOf()) {
      this.allowEditPrice = true;
      this.checkPriceLimit = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowEditPriceWithinDefinedRange).toLowerCase() === "true";
      if (this.isEditMode) {
        this.isPriceChanged = true;
      }
    }

    this.showTaxInWhOperations = this.transactionMainType === TransactionMainTypes.WarehouseTransaction && this.allowTax && this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTaxInWarehouseOperations).toLowerCase() === "true";
    if (this.orderTypeId === OrderTypes.Return.valueOf() || this.isExchangeMode) {
      // this.calculationService.setReturnsBool(true);
      if (this.forceSelectingPackStatusPerTransaction && !this.restrictReturnFromInvoices) {
        this.getPackStatuses();
      }
      // if order == Return and returnFromInvoices =>
      if (this.restrictReturnFromInvoices) {
        // batchlevel.
        this.enableReturnBatchFromInvoice = true;
        // this.checkForAvailableBatchLevel = true;
        this.showTaxInReturn = true;
        if (this.isEditMode || this.formProcessMode == FormProcessMode.ProcessReturn.valueOf()) {
          this.showRequestedQty = true;
        }
      }
      if (this.formProcessMode == FormProcessMode.ProcessReturn.valueOf() && !this.allowPartialDeliveryInProcessReturn) {
        this.hideDeleteInProcess = true;
      }
    }
    if (
      this.formProcessMode === FormProcessMode.DamagedStock ||
      this.formProcessMode === FormProcessMode.ManufacturingDefects ||
      this.formProcessMode === FormProcessMode.ROIIncomming ||
      this.formProcessMode === FormProcessMode.ROIIncommingReturns
    ) {
      this.isROIScreen = true;
    }
    if (
      (this.sessionData.getConfigurationValue(
        ConstantConfigurations.AllowEditDiscount
      ) === "true" &&
        this.formProcessMode === FormProcessMode.Sales) ||
      (this.sessionData.getConfigurationValue(
        ConstantConfigurations.AllowEditDiscountInOrders
      ) === "true" &&
        this.formProcessMode === FormProcessMode.Orders)
    ) {
      this.allowEditDiscount = true;
    }
    if (
      (!this.AutoExecuteOffloadRequestInBO &&
        this.formProcessMode === FormProcessMode.OffLoad &&
        !this.executeOperation) ||
      (!this.AutoExecuteLoadRequestInBO &&
        this.formProcessMode === FormProcessMode.LoadRequest &&
        !this.executeOperation) ||
      this.formProcessMode === FormProcessMode.Orders ||
      this.formProcessMode === FormProcessMode.InputVoucher ||
      this.formProcessMode === FormProcessMode.SerialsAggregation ||
      this.formProcessMode === FormProcessMode.PurchaseOrder ||
      this.isFromSSCC ||
      (this.formProcessMode === FormProcessMode.Sales &&
        this.LimitBOSalesToWarehouseStock
        || this.formProcessMode === FormProcessMode.Return
        || this.formProcessMode === FormProcessMode.ExchangeReturn)
    ) {
      this.showAddSerialsBtn = false;
    } else {
      this.checkIfThereIsSerialItemsAvailable();
    }

    this.manageStockAndUI();
    this.populateItemsList();
    this.populateSearchList();
    this.initSearchForm();
    this.initReturnReasonForm();
    if (this.isDivisionLevel()) {
      this.selectedDivisionId = +this.divisionId;
    }
    this.enableSerials();
    if (
      this.formProcessMode === FormProcessMode.TransferIn &&
      !this.AllowEditItemInTransfer &&
      this.someItemHasSerial
    ) {
      this.disableItemsInTransferIn = true;
    }
    if (
      this.formProcessMode === FormProcessMode.InputVoucher ||
      this.formProcessMode === FormProcessMode.OutputVoucher ||
      this.formProcessMode === FormProcessMode.LoadRequest ||
      this.formProcessMode === FormProcessMode.OffLoad
    ) {
      this.enableAddItemByLiters = true;
    }
  }
  //#endregion

  //#region [METHODS]

  isDivisionLevel() {
    if (this.transactionMainType === TransactionMainTypes.CustomerTransaction) {
      return (
        this.sessionData
          .getConfigurationValue(
            ConstantConfigurations.ControlTransactionOnDivisionLevel
          )
          .toLowerCase() === "true"
      );
    } else {
      return (
        this.sessionData
          .getConfigurationValue(
            ConstantConfigurations.ControlTransactionOnDivisionLevel
          )
          .toLowerCase() === "true" &&
        this.sessionData
          .getConfigurationValue(
            ConstantConfigurations.ShowDivisionSelectionOnWHTransaction
          )
          .toLowerCase() === "true"
      );
    }
  }
  qtyChanged(item: any) {
    this.itemChangeEvent.emit(item);
  }
  startEndReadingChanged(item: ItemPackModel) {
    item.requiredQty = 0;
    item.invalidValue = false;
    if (item.endReading >= item.startReading) {
      item.requiredQty = item.endReading - item.startReading;
    } else {
      item.invalidValue = true;
    }
    this.onAddEditItemClicked(item);
  }

  manageStockAndUI() {
    var isEmptyTrans =
      !this.editedTransactionId ||
      this.editedTransactionId === null ||
      this.editedTransactionId === "";
    if (this.transactionMainType === TransactionMainTypes.CustomerTransaction) {
      if (
        this.formProcessMode == FormProcessMode.Orders &&
        this.orderTypeId === OrderTypes.Sales
      ) {
        this.showQuantityInOrder = true;
        this.excludedOrderId = this.order.orderId;
      }

      if (
        this.formProcessMode === FormProcessMode.Delivery ||
        this.formProcessMode === FormProcessMode.PartialDelivery ||
        ((this.formProcessMode === FormProcessMode.Sales || this.formProcessMode == FormProcessMode.ExchangeReturn) && this.LimitBOSalesToWarehouseStock)) {
        this.checkForAvailableItemLevel = true;
        this.useDefaultBatch = true;
        this.blockIfExceedAvailable = true;
        this.showAvlQty = true;
      } else {
        this.useDefaultBatch = true;
      }
      if (
        (this.formProcessMode === FormProcessMode.Delivery ||
          this.formProcessMode === FormProcessMode.PartialDelivery) &&
        this.AllowBatchSelectionInDelivery
      ) {
        this.enableBatchSelection = true;
        this.checkForAvailableBatchLevel = true;
        this.checkForAvailableItemLevel = false;
      }
    } else if (
      this.transactionMainType === TransactionMainTypes.WarehouseTransaction
    ) {
      this.showMWHQty = true;
      if (this.formProcessMode == FormProcessMode.LoadRequest) {
        if (this.CheckMainWHStockForLoad) {
          this.showAvlQty = true;
          this.showVehicleQTY = true;
          if (this.AllowTripGeneration) {
            this.checkForAvailableItemLevel = true;
            this.useDefaultBatch = true;
          } else {
            if (
              !this.UseDefaultExpiryAndBatchForItems &&
              this.AllowBatchSelectionInWarehouseOperations
            ) {
              this.checkForAvailableBatchLevel = true;
              this.enableBatchSelection = true;
              if (!this.AutoExecuteLoadRequestInBO && !this.isFromSSCC) {
                this.enableBatchInsertion = true;
              }
            } else {
              this.checkForAvailableItemLevel = true;
              this.useDefaultBatch = true;
            }
          }
          if (
            (!this.AutoExecuteLoadRequestInBO && !this.executeOperation) ||
            (this.AutoExecuteLoadRequestInBO &&
              !isEmptyTrans &&
              !this.executeOperation)
          ) {
            this.confirmationIfExceedAvailable = true;
            this.calculatePendingQty = true;
          } else {
            this.blockIfExceedAvailable = true;
            this.calculatePendingQty = false;
          }
        } else {
          if (this.AllowTripGeneration) {
            this.useDefaultBatch = true;
          } else if (this.UseDefaultExpiryAndBatchForItems) {
            this.useDefaultBatch = true;
          } else {
            this.enableBatchInsertion = true;
          }
        }
      } else if (
        this.formProcessMode == FormProcessMode.OffLoad ||
        this.formProcessMode == FormProcessMode.OutputVoucher ||
        this.formProcessMode == FormProcessMode.DamagedStock ||
        this.formProcessMode == FormProcessMode.ManufacturingDefects ||
        this.formProcessMode == FormProcessMode.ROIIncomming ||
        this.formProcessMode == FormProcessMode.ROIIncommingReturns ||
        this.formProcessMode == FormProcessMode.TransferOut ||
        this.formProcessMode == FormProcessMode.MoveOrder ||
        this.isFromSSCC
      ) {
        this.showAvlQty = true;
        this.blockIfExceedAvailable = true;
        if (this.formProcessMode === FormProcessMode.OffLoad) {
          this.showVehicleQTY = true;
          if (
            (!this.AutoExecuteOffloadRequestInBO && !this.executeOperation) ||
            (this.AutoExecuteOffloadRequestInBO &&
              !isEmptyTrans &&
              !this.executeOperation)
          ) {
            this.calculatePendingQty = true;
          }
        }
        if (
          this.formProcessMode == FormProcessMode.MoveOrder &&
          this.AllowTripGeneration
        ) {
          this.checkForAvailableItemLevel = true;
          this.useDefaultBatch = true;
        } else {
          if (
            !this.UseDefaultExpiryAndBatchForItems &&
            this.AllowBatchSelectionInWarehouseOperations
          ) {
            this.checkForAvailableBatchLevel = true;
            this.enableBatchSelection = true;
          } else {
            this.checkForAvailableItemLevel = true;
            this.useDefaultBatch = true;
          }
        }
      } else if (this.formProcessMode == FormProcessMode.InputVoucher) {
        if (this.UseDefaultExpiryAndBatchForItems) {
          this.useDefaultBatch = true;
        } else {
          this.enableBatchInsertion = true;
        }
      } else if (this.formProcessMode == FormProcessMode.TransferIn) {
        this.enableBatchInsertion = true;
      }
    }
  }
  enableSerials() {
    if (
      this.formProcessMode === FormProcessMode.InputVoucher ||
      this.formProcessMode === FormProcessMode.OutputVoucher ||
      this.formProcessMode === FormProcessMode.DamagedStock ||
      this.formProcessMode === FormProcessMode.ManufacturingDefects ||
      this.formProcessMode === FormProcessMode.ROIIncomming ||
      this.formProcessMode === FormProcessMode.ROIIncommingReturns ||
      this.formProcessMode === FormProcessMode.TransferIn ||
      this.formProcessMode === FormProcessMode.TransferOut ||
      this.formProcessMode === FormProcessMode.Delivery ||
      this.formProcessMode === FormProcessMode.PartialDelivery ||
      (this.formProcessMode === FormProcessMode.Sales &&
        this.LimitBOSalesToWarehouseStock) ||
      this.formProcessMode === FormProcessMode.BackToStoreOffload ||
      this.formProcessMode === FormProcessMode.ResendHelloJordan ||
      this.formProcessMode === FormProcessMode.SerialReassignment
    ) {
      this.enableAddSerial = true;
    }
    if (this.formProcessMode === FormProcessMode.SerialsAggregation) {
      this.enableAddSerial = true;
      this.addSerialCaption = "Desc_Aggregate";
    }
    if (this.formProcessMode === FormProcessMode.LoadRequest) {
      if (this.AutoExecuteLoadRequestInBO || this.executeOperation) {
        this.enableAddSerial = true;
      }
    }
    if (this.formProcessMode === FormProcessMode.OffLoad) {
      if (this.AutoExecuteOffloadRequestInBO || this.executeOperation) {
        this.enableAddSerial = true;
      }
    }
    if (this.formProcessMode === FormProcessMode.Sales &&
      this.LimitBOSalesToWarehouseStock) {
      this.enableAddSerial = true;
      this.showImportSerialsBtn = true;
    }
  }
  initSearchForm() {
    this.searchForm = new FormGroup({
      division: new FormControl(null),
      category: new FormControl(null),
    });
  }

  populateItemsList() {
    // Cash saved transaction distinct item ids to apply stock validations
    var distintAddedItemIdsList = [];
    var editedTransactionItemIdsString = "";

    if (this.addedItemsList && this.addedItemsList.length > 0) {
      this.addedItemsList
        .map(({ itemId }) => itemId)
        .forEach((id) => {
          if (distintAddedItemIdsList.findIndex((x) => x === id) < 0) {
            distintAddedItemIdsList.push(id);
          }
        });
      if (distintAddedItemIdsList && distintAddedItemIdsList.length > 0) {
        editedTransactionItemIdsString = distintAddedItemIdsList.join(", ");
      }
    }

    // Cash saved transaction distinct pack ids to apply pricing validations
    var distintAddedPackIdsList = [];
    var editedTransactionPackIdsString = "";
    if (this.addedItemsList && this.addedItemsList.length > 0) {
      this.addedItemsList
        .map(({ packId }) => packId)
        .forEach((id) => {
          if (distintAddedPackIdsList.findIndex((x) => x === id) < 0) {
            distintAddedPackIdsList.push(id);
          }
        });
      if (distintAddedPackIdsList && distintAddedPackIdsList.length > 0) {
        editedTransactionPackIdsString = distintAddedPackIdsList.join(", ");
      }
    }
    var orgFormMode = this.formProcessMode;
    if (this.isFromSSCC) {
      if (this.isDelivery() || this.isPartialDelivery()) {
        orgFormMode = FormProcessMode.SSCCFromDelivery;
      } else if (this.formProcessMode === FormProcessMode.LoadRequest) {
        orgFormMode = FormProcessMode.SSCCFromLoadExecute;
      } else if (this.isFromSSCCMainList) {
        orgFormMode = FormProcessMode.SSCCFromMainList; //SSCCFromLoadExecute;
      }
    }
    // Ror transfer-in => get all items in system, but build stock for transfer-out saved batches if UseDefaultExpiryAndBatchForItems is false
    // so stockWarehouseId and detailsWarehouseId and editedTransactionId here represent transfer-out data
    var filter = {
      selectedEmployeeId: ((this.formProcessMode === FormProcessMode.LoadRequest || this.formProcessMode === FormProcessMode.OffLoad) && this.warehouseTransaction) ? this.warehouseTransaction.employeeId : -1,
      stockWarehouseId: this.stockWarehouseId,
      detailsWarehouseId: this.detailsWarehouseId,
      refWarehouseId: this.refWarehouseId,
      customerId: this.customerId,
      outletId: this.outletId,
      divisionId: this.divisionId,
      transactionMainTypes: this.transactionMainType,
      processMode: orgFormMode,
      editedTransactionId: this.editedTransactionId, // filled just if edit mode
      status: this.WHTransactionstatus,
      includeTax: this.showTax,
      editedTransactionItemIds: editedTransactionItemIdsString,
      editedTransactionPackIds: editedTransactionPackIdsString,
      orderSignature: "",
      vendorId: this.vendorId,
      ssccBarcode: this.ssccBarcode,
      isQualityControl: this.isQualityControl,
      allowAddingNewItems: this.allowAddingNewItems,
      isExecuteOperation: this.executeOperation, // this flag send to distinguish between edit or execute WH operations
      requestedBy:
        orgFormMode == FormProcessMode.Orders
          ? this.order.employeeId
          : this.requestedBy,
      isFullyOffload: this.isFullyOffload,
      excludedOrderId: this.excludedOrderId,
      showQuantityInOrder: this.showQuantityInOrder,
      orderTypeId: this.orderTypeId && this.orderTypeId > 0 ? this.orderTypeId : -1,
      restrictReturnFromInvoices: this.restrictReturnFromInvoices
    };
    // if(this.formProcessMode=== FormProcessMode.TransferIn && !this.AllowEditItemInTransfer && this.someItemHasSerial){

    // } else{
    if (
      this.formProcessMode === FormProcessMode.Delivery ||
      this.formProcessMode === FormProcessMode.PartialDelivery ||
      this.formProcessMode === FormProcessMode.ProcessReturn ||
      this.formProcessMode === FormProcessMode.Return ||
      this.formProcessMode === FormProcessMode.ExchangeReturn
    )
      filter.orderSignature = this.orderSignature;
    this.coreSession.ModalLoading.Show();
    this.areItemsLoading = true;
    this.itemService.getAllItemsNew(filter).subscribe(
      (response) => {
        this.areItemsLoading = false;
        if (response.status >= 0) {
          if (
            response.data &&
            response.data.itemslist &&
            response.data.itemslist.length > 0
          ) {
            // originalItemsList : represents items in system according to selected process mode
            // itemsStock : list over the transaction containes available stock from item on item level or item batch level
            // the stock list will contain all items stock in case check availability is on item level
            // the stock list will contain only OLD SAVED items stock in case check availability is on batch level
            // when availability is on batch level then get item stock for each new added item when click on batch button

            this.originalItemsList = response.data.itemslist;
            this.allItemsStockList = response.data.itemsStock;
            this.roiPackList = response.data.roiPackList;
            this.priceRangeList = response.data.priceRangeList;
            if (this.originalItemsList && this.originalItemsList.length > 0) {
              if (
                this.isROIScreen &&
                this.roiPackList &&
                this.roiPackList.length > 0
              ) {
                this.showROIPrice = true;
                this.showROITax = this.allowTax;
                this.showROIDiscountOnTransLevel =
                  this.roiPackList[0].showDiscountOnOperationLevel;
                this.showROIDiscountOnPackLevel =
                  this.roiPackList[0].showDiscountOnPackLevel;
                if (this.showROIDiscountOnPackLevel) {
                  this.roiPriceClass = "col-md-3";
                } else {
                  this.roiPriceClass = "col-md-6";
                }
                this.roiPackList.forEach((p) => {
                  let index = this.originalItemsList.findIndex(
                    (a) => a.packId === p.packId
                  );
                  if (index >= 0) {
                    this.originalItemsList[index].price = p.price;
                    this.originalItemsList[index].tax = this.allowTax ? p.tax : 0;
                    this.originalItemsList[index].usedPriceListId =
                      p.priceListID;
                  }
                });
              }
              this.originalItemsList.forEach((orginalItem) => {
                if (orginalItem.hasImage) {
                  orginalItem.imagePath =
                    this.itemImagePath + orginalItem.itemId + ".png";
                } else {
                  orginalItem.imagePath =
                    this.itemNoProductImagePath + "noProduct.jpg";
                }
                orginalItem.requiredQty = null;
                if (this.isExchangeMode) {
                  orginalItem.requiredReturnQty = null;
                } else {
                  orginalItem.requiredReturnQty = 0;
                }
                orginalItem.oldRequiredQty = 0;
                if (
                  this.formProcessMode === FormProcessMode.Orders &&
                  orginalItem.hasSecondaryPack
                ) {
                  this.enableAddSecondaryPack = true;
                }
                if (this.formProcessMode === FormProcessMode.Sales) {
                  orginalItem.salesTransactionTypeId =
                    SalesTransactionTypes.Sales;
                }
                // Apply item stock on each pack for that item
                if (
                  this.allItemsStockList.findIndex(
                    (x) => x.itemId === orginalItem.itemId
                  ) >= 0
                ) {
                  if (this.checkForAvailableItemLevel) {
                    orginalItem.availableQtyInPcs = this.allItemsStockList.find(
                      (x) => x.itemId === orginalItem.itemId
                    ).actualAvailableQtyInPcs;
                  } else if (
                    this.enableBatchSelection ||
                    this.enableBatchInsertion
                  ) {
                    orginalItem.itemStockBatches = cloneDeep(
                      this.allItemsStockList.filter(
                        (x) => x.itemId === orginalItem.itemId
                      )
                    );
                    if (
                      orginalItem.itemStockBatches &&
                      orginalItem.itemStockBatches.length > 0
                    ) {
                      orginalItem.availableQtyInPcs = 0;
                      orginalItem.itemStockBatches.forEach((batch) => {
                        batch.packId = orginalItem.packId;
                        orginalItem.availableQtyInPcs +=
                          batch.actualAvailableQtyInPcs;
                      });
                    }
                  }
                }

                // check if this item exist in saved items then, fill required qty, cash selected batches and reflect total calculations
                if (this.filterOnSalesType) {
                  if (
                    orginalItem.salesTransactionTypeId ===
                    SalesTransactionTypes.Sales &&
                    this.addedItemsList &&
                    this.addedItemsList.length > 0 &&
                    this.addedItemsList.findIndex(
                      (a) =>
                        a.itemId === orginalItem.itemId &&
                        a.packId === orginalItem.packId &&
                        a.salesTransactionTypeId === SalesTransactionTypes.Sales
                    ) >= 0
                  ) {
                    this.subscribeAddedItems(orginalItem);
                  } else if (
                    orginalItem.salesTransactionTypeId ===
                    SalesTransactionTypes.Promotion &&
                    this.addedItemsList &&
                    this.addedItemsList.length > 0 &&
                    this.addedItemsList.findIndex(
                      (a) =>
                        a.itemId === orginalItem.itemId &&
                        a.packId === orginalItem.packId &&
                        a.salesTransactionTypeId ===
                        SalesTransactionTypes.Promotion
                    ) >= 0
                  ) {
                    this.subscribePromotionItems(cloneDeep(orginalItem));
                  }
                } else {
                  if (
                    this.addedItemsList &&
                    this.addedItemsList.length > 0 &&
                    this.addedItemsList.findIndex(
                      (a) =>
                        a.itemId === orginalItem.itemId &&
                        a.packId === orginalItem.packId
                    ) >= 0
                  ) {
                    // Reflect added quantity from addedItems from order on originalItems
                    if ((this.orderTypeId === OrderTypes.Return.valueOf() && this.restrictReturnFromInvoices) || this.isExchangeMode) {
                      this.prepareAddedQtysInReturn(orginalItem);
                    }
                    this.subscribeAddedItems(orginalItem);
                  }
                }

                if (orginalItem.discountTypeId == DiscountValueTypes.Percentage.valueOf()) {
                  orginalItem.discountString = orginalItem.discount + " %";
                } else if (orginalItem.discountTypeId == DiscountValueTypes.Amount.valueOf()) {
                  orginalItem.discountString =
                    orginalItem.discount +
                    " " +
                    this.coreSession.baseCurrencyCode;
                }
                orginalItem.baseTax = orginalItem.tax;
                if ((this.formProcessMode == FormProcessMode.Return || (this.formProcessMode == FormProcessMode.Orders && this.orderTypeId == OrderTypes.Return.valueOf())) && this.useReturnPriceListForReturns) {
                  orginalItem.price = orginalItem.returnPrice;
                  let index = this.priceRangeList.findIndex(p => (orginalItem.packId === p.packId &&
                    p.isReturnItem == true));
                  orginalItem.tax = index > -1 ? this.priceRangeList[index].tax : 0;
                }
              }); // end the loop on original items

              if (
                this.executeOperation &&
                !this.isFromSSCC &&
                (this.formProcessMode === FormProcessMode.LoadRequest ||
                  this.formProcessMode === FormProcessMode.OffLoad)
              ) {
                this.originalItemsList = this.originalItemsList.filter(
                  (x) => x.requiredQty && x.requiredQty > 0
                );
              }

              if (this.addedItemsList && this.addedItemsList.length > 0) {
                this.fillTotalRequiredQty();
                if (
                  this.checkForAvailableItemLevel ||
                  this.checkForAvailableBatchLevel
                ) {
                  // loop on distinct item ids from added items to reflect stock for all OLD SAVED  packs
                  if (
                    distintAddedItemIdsList &&
                    distintAddedItemIdsList.length > 0
                  ) {
                    distintAddedItemIdsList.forEach((id) => {
                      if (this.checkForAvailableItemLevel) {
                        // update avalilability for all packs after deduct required qty
                        this.updateAvailabilityItemLevel(id);
                      }
                      if (this.checkForAvailableBatchLevel) {
                        // update availability for all pack-batches after deduct required qty
                        this.updateAvailabilityBatchLevel(id);
                      }
                    });
                  }
                }
              }

              // items List represents original items in one page, and on move to onother page it will change
              this.totalItemsCountinPagination = this.originalItemsList.length;

              if (this.originalItemsList.length > this.pageSize) {
                this.itemsList = this.originalItemsList.slice(0, this.pageSize);
              } else {
                this.itemsList = this.originalItemsList;
              }
              this.pageIndex = 0;
              if (this.hideLoadingModal) this.coreSession.ModalLoading.Hide();

              if (this.showAvailabilityWarning) {
                this.coreSession.showWarrning(
                  this.translateService.instant(ConstantMessages.WarningCaption),
                  this.translateService.instant(ConstantMessages.MsgSomeUnAvailableBatches)
                );
                this.showAvailabilityWarning = false;
              }
            }
          } else {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              this.translateService.instant(ConstantMessages.MsgNoItemsFound)
            );
          }
        } else {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.areItemsLoading = false;
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }

  prepareAddedQtysInReturn(orginalItem) {
    // This method reflects added qty's from order on the original item with the updated available qtys from
    // [CustomerBatchesList] table.
    // If allowPartialDeliveryInProcessReturn is true, cache avaiable qty's in [allItemsStockList] for this item.
    var index = this.allItemsStockList.findIndex(i => i.itemId === orginalItem.itemId);
    if (index === -1) {
      var itm = cloneDeep(orginalItem)
      this.allItemsStockList.push(itm);
    }

    var idx = this.addedItemsList.findIndex(
      (a) =>
        a.itemId === orginalItem.itemId &&
        a.packId === orginalItem.packId
    );
    this.addedItemsList[idx].returnFromInvoiceAvailableBatchList.forEach(inv => {
      if (inv.requiredQuantity > 0) {
        var i = orginalItem.returnFromInvoiceAvailableBatchList.findIndex(o => o.transactionId === inv.transactionId &&
          o.batchNo === inv.batchNo);
        if (i > -1) {
          // If Available Qty >= required Qty => item is valid
          if (orginalItem.returnFromInvoiceAvailableBatchList[i].availableQtyFromInvoice >=
            (inv.requiredQuantity * this.addedItemsList[idx].piecesInPack)) {
            inv.invalid = false;
            orginalItem.returnFromInvoiceAvailableBatchList[i].invalid = false;
          } else {
            // inv.requiredQuantity = 0;
            // orginalItem.returnFromInvoiceAvailableBatchList[i].requiredQuantity = 0;
            inv.invalid = true;
            orginalItem.returnFromInvoiceAvailableBatchList[i].invalid = true;
            this.showAvailabilityWarning = true;
            orginalItem.isExceedAvailableQty = true;
          }
          orginalItem.returnFromInvoiceAvailableBatchList[i].requiredQuantity = inv.requiredQuantity;
        } else {
          // Tx-batch Does not exist anymore.
          inv.invalid = true;
          this.showAvailabilityWarning = true;
          orginalItem.isExceedAvailableQty = true;
        }
      }
    });
  }

  onImportDialogCompleted() {
    this.onImportCompleted.emit();
  }
  populateSearchList() {
    this.itemService.initiateSearchList().subscribe((result) => {
      if (result.status == 0 && result.data) {
        this.divisionList = result.data.divisionList;
        this.categoryList = result.data.categoryList;
        this.originalCategoryList = result.data.categoryList;
      }
    });
  }
  subscribeAddedItems(orginalItem: ItemPackModel) {
    var addedItem;
    var focAddedItem;
    var returnAddedItem;
    if (this.filterOnSalesType)
      addedItem = this.addedItemsList.find(
        (a) =>
          a.itemId === orginalItem.itemId &&
          a.packId === orginalItem.packId &&
          a.salesTransactionTypeId === SalesTransactionTypes.Sales
      );
    else
      addedItem = this.addedItemsList.find(
        (a) =>
          a.itemId === orginalItem.itemId && a.packId === orginalItem.packId
      );

    if (this.isFOCMode) {
      focAddedItem = this.addedItemsList.find(
        (a) =>
          a.itemId === orginalItem.itemId &&
          a.packId === orginalItem.packId &&
          a.salesTransactionTypeId == SalesTransactionTypes.FOC.valueOf()
      );
      let focIndex = this.addedItemsList.findIndex(
        (a) =>
          a.itemId === orginalItem.itemId &&
          a.packId === orginalItem.packId &&
          a.salesTransactionTypeId == SalesTransactionTypes.FOC.valueOf()
      );
      if (focIndex >= 0) {
        focAddedItem.isFOCItem = true;
        orginalItem.requiredFOCQty = this.addedItemsList[focIndex].requiredQty;
        focAddedItem.requiredFOCQty = this.addedItemsList[focIndex].requiredQty;
        this.onAddEditItemClicked(focAddedItem);
      }
      addedItem = this.addedItemsList.find(
        (a) =>
          a.itemId === orginalItem.itemId &&
          a.packId === orginalItem.packId &&
          a.salesTransactionTypeId !== SalesTransactionTypes.FOC.valueOf()
      );
      if (!addedItem) return;
    }

    orginalItem.requiredQty = addedItem.requiredQty;
    orginalItem.oldRequiredQty = orginalItem.requiredQty;
    orginalItem.originalQuantity = addedItem.originalQuantity;
    addedItem.itemName = orginalItem.itemName;
    addedItem.itemCode = orginalItem.itemCode;
    addedItem.uom = orginalItem.uom;
    addedItem.piecesInPack = orginalItem.piecesInPack;

    orginalItem.expiryDateModel = addedItem.expiryDateModel;
    orginalItem.expiryDateModel.day = addedItem.expiryDateModel.day;
    orginalItem.expiryDateModel.month = addedItem.expiryDateModel.month;
    orginalItem.expiryDateModel.year = addedItem.expiryDateModel.year;
    orginalItem.batchNo = addedItem.batchNo;

    if (this.allowEditPriceConfiguration && this.allowEditPriceInOrder && this.isOrders() && this.orderTypeId === OrderTypes.Sales.valueOf()) {
      let index = this.priceRangeList.findIndex(p => (addedItem.packId === p.packId &&
        addedItem.requiredQty >= p.rangeStart &&
        addedItem.requiredQty <= p.rangeEnd &&
        p.isReturnItem == false));
        orginalItem.minPrice = index > -1 ? this.priceRangeList[index].minPrice : 0;
        orginalItem.maxPrice = index > -1 ? this.priceRangeList[index].maxPrice : 0;
    }
    if ((this.restrictReturnFromInvoices &&
      this.orderTypeId === OrderTypes.Return.valueOf()) || this.isExchangeMode
    ) {
      orginalItem.returnReasonId = addedItem.returnReasonId;
      orginalItem.packStatusId = addedItem.packStatusId;
      orginalItem.requestedQuantity = addedItem.requestedQuantity;
    }
    if (addedItem.hasSerialNumber) {
      orginalItem.addedSerials = addedItem.addedSerials;
      this.fillAddedSerialsInAllAddedPackObjects(addedItem);
    }
    if (addedItem.hasSecondaryPack) {
      orginalItem.secondaryPack = addedItem.secondaryPack;
    }
    if (this.transactionMainType === TransactionMainTypes.CustomerTransaction) {
      orginalItem.price = 0;
      orginalItem.tax = 0;
      orginalItem.discount = 0;
      orginalItem.discountTypeId = DiscountValueTypes.None.valueOf();

      // get price data from old saved pack (in case edit order)
      orginalItem.price = addedItem.price;
      orginalItem.tax = addedItem.tax;
      orginalItem.discount = addedItem.discount;
      orginalItem.discountTypeId = addedItem.discountTypeId;
      orginalItem.salesTransactionTypeId = this.orderTypeId !== OrderTypes.Return.valueOf() ? SalesTransactionTypes.Sales : SalesTransactionTypes.None;
      if ((this.orderTypeId === OrderTypes.Return.valueOf() && this.restrictReturnFromInvoices) || this.isExchangeMode) {
        this.calculationService.calculateReturnPackTotals(orginalItem);
        this.calculationService.updateReturnTotals(this.totals, orginalItem, true);
        if (orginalItem.requiredQty === 0) {
          var idx = this.addedItemsList.findIndex(i => i.itemId === orginalItem.itemId && i.packId === orginalItem.packId);
          if (idx > -1) {
            this.addedItemsList.splice(idx, 1);
          }
        }
      } else {
        this.calculationService.calculatePackTotal(orginalItem);
        this.calculationService.updateTotals(this.totals, orginalItem, true);
      }
    } else {
      orginalItem.salesTransactionTypeId = SalesTransactionTypes.None;
      if (this.transactionMainType === TransactionMainTypes.WarehouseTransaction &&
        (this.formProcessMode === FormProcessMode.TransferIn || this.formProcessMode === FormProcessMode.LoadRequest || this.formProcessMode === FormProcessMode.OffLoad)) {
        if (!this.isFullyOffload) {
          orginalItem.price = addedItem.price;
          orginalItem.tax = addedItem.tax;
          orginalItem.discount = addedItem.discount;
          orginalItem.discountTypeId = addedItem.discountTypeId;
        }
        this.calculationService.calculatePackTotal(orginalItem);
        this.calculationService.updateTotals(this.totals, orginalItem, true);
      }
    }
    if (this.enableBatchSelection || this.enableBatchInsertion) {
      // fill selected batches from added item
      orginalItem.selectedBatches = addedItem.selectedBatches;
      addedItem.itemStockBatches = orginalItem.itemStockBatches;

      // fill required qty for stock batches from selected batches
      if (orginalItem.selectedBatches) {
        orginalItem.selectedBatches.forEach((selectedBatch) => {
          if (orginalItem.itemStockBatches) {
            var itemStockBatch = orginalItem.itemStockBatches.find(
              (x) =>
                x.itemId === selectedBatch.itemId &&
                x.batchNo === selectedBatch.batchNo &&
                x.expiryDateModel.year == selectedBatch.expiryDateModel.year &&
                x.expiryDateModel.month ==
                selectedBatch.expiryDateModel.month &&
                x.expiryDateModel.day == selectedBatch.expiryDateModel.day
            );
            if (itemStockBatch) {
              itemStockBatch.requiredQty = selectedBatch.requiredQty;
              selectedBatch.actualAvailableQtyInPcs =
                itemStockBatch.actualAvailableQtyInPcs;
              selectedBatch.availableQtyInPcs =
                itemStockBatch.availableQtyInPcs;
            }
          }
        });
      }
    }
    // make same reference
    var index = -1;
    if (this.filterOnSalesType)
      index = this.addedItemsList.findIndex(
        (a) =>
          a.itemId === orginalItem.itemId &&
          a.packId === orginalItem.packId &&
          a.salesTransactionTypeId === orginalItem.salesTransactionTypeId
      );
    else
      index = this.addedItemsList.findIndex(
        (a) =>
          a.itemId === orginalItem.itemId && a.packId === orginalItem.packId
      );
    if (index >= 0) {
      // cache packStatus,return reason , expiry, batch for pack.
      if (this.orderTypeId === OrderTypes.Return.valueOf() || this.isExchangeMode) {
        var tempStatusDetails = this.addedItemsList[index];
        orginalItem.packStatusId = tempStatusDetails.packStatusId;
        orginalItem.returnReasonId = tempStatusDetails.returnReasonId;
        orginalItem.batchNo = tempStatusDetails.batchNo;
        orginalItem.expiryDateModel = tempStatusDetails.expiryDateModel;
        this.addedItemsList[index] = orginalItem;
      } else {
        this.addedItemsList[index] = orginalItem;
      }
    }
    var indexOfOrginalItem = -1;
    if (this.filterOnSalesType)
      indexOfOrginalItem = this.originalItemsList.findIndex(
        (a) =>
          a.itemId === orginalItem.itemId &&
          a.packId === orginalItem.packId &&
          a.salesTransactionTypeId === orginalItem.salesTransactionTypeId
      );
    else
      indexOfOrginalItem = this.originalItemsList.findIndex(
        (a) =>
          a.itemId === orginalItem.itemId && a.packId === orginalItem.packId
      );

    if (indexOfOrginalItem >= 0) {
      this.originalItemsList[indexOfOrginalItem] = orginalItem;
    }
  }
  subscribePromotionItems(orginalItem: ItemPackModel) {
    var addedItem = this.addedItemsList.find(
      (a) =>
        a.itemId === orginalItem.itemId &&
        a.packId === orginalItem.packId &&
        a.salesTransactionTypeId === SalesTransactionTypes.Promotion
    );

    orginalItem.requiredQty = addedItem.requiredQty;
    orginalItem.oldRequiredQty = orginalItem.requiredQty;
    orginalItem.originalQuantity = addedItem.originalQuantity;
    addedItem.itemName = orginalItem.itemName;
    addedItem.itemCode = orginalItem.itemCode;
    addedItem.uom = orginalItem.uom;
    addedItem.piecesInPack = orginalItem.piecesInPack;

    orginalItem.expiryDateModel = addedItem.expiryDateModel;
    orginalItem.expiryDateModel.day = addedItem.expiryDateModel.day;
    orginalItem.expiryDateModel.month = addedItem.expiryDateModel.month;
    orginalItem.expiryDateModel.year = addedItem.expiryDateModel.year;
    orginalItem.batchNo = addedItem.batchNo;
    if (addedItem.hasSerialNumber) {
      orginalItem.addedSerials = addedItem.addedSerials;
      this.fillAddedSerialsInAllAddedPackObjects(addedItem);
    }

    orginalItem.price = 0;
    orginalItem.tax = 0;
    orginalItem.discount = 0;
    orginalItem.discountTypeId = DiscountValueTypes.None.valueOf();

    // get price data from old saved pack (in case edit order)
    orginalItem.price = addedItem.price;
    orginalItem.tax = addedItem.tax;
    orginalItem.discount = addedItem.discount;
    orginalItem.discountTypeId = addedItem.discountTypeId;
    orginalItem.salesTransactionTypeId = SalesTransactionTypes.Promotion;
    this.calculationService.calculateFreePackTotal(orginalItem);
    this.calculationService.updateTotals(this.totals, orginalItem, true);

    if (this.enableBatchSelection || this.enableBatchInsertion) {
      // fill selected batches from added item
      orginalItem.selectedBatches = addedItem.selectedBatches;
      addedItem.itemStockBatches = orginalItem.itemStockBatches;

      // fill required qty for stock batches from selected batches
      if (orginalItem.selectedBatches) {
        orginalItem.selectedBatches.forEach((selectedBatch) => {
          if (orginalItem.itemStockBatches) {
            var itemStockBatch = orginalItem.itemStockBatches.find(
              (x) =>
                x.itemId === selectedBatch.itemId &&
                x.batchNo === selectedBatch.batchNo &&
                x.expiryDateModel.year == selectedBatch.expiryDateModel.year &&
                x.expiryDateModel.month ==
                selectedBatch.expiryDateModel.month &&
                x.expiryDateModel.day == selectedBatch.expiryDateModel.day
            );
            if (itemStockBatch) {
              itemStockBatch.requiredQty = selectedBatch.requiredQty;
              selectedBatch.actualAvailableQtyInPcs =
                itemStockBatch.actualAvailableQtyInPcs;
              selectedBatch.availableQtyInPcs =
                itemStockBatch.availableQtyInPcs;
            }
          }
        });
      }
    }
    // make same reference
    var index = this.addedItemsList.findIndex(
      (a) =>
        a.itemId === orginalItem.itemId &&
        a.packId === orginalItem.packId &&
        a.salesTransactionTypeId === orginalItem.salesTransactionTypeId
    );
    if (index >= 0) {
      this.addedItemsList[index] = orginalItem;
    }

    var indexOfOrginalItem = this.originalItemsList.findIndex(
      (a) =>
        a.itemId === orginalItem.itemId &&
        a.packId === orginalItem.packId &&
        a.salesTransactionTypeId === orginalItem.salesTransactionTypeId
    );
    if (indexOfOrginalItem >= 0) {
      this.originalItemsList[indexOfOrginalItem] = orginalItem;
    }
  }
  findItems(startIndex, endIndex, pageIndex) {
    let checkItemName = false;
    let checkDivision = false;
    let checkCategory = false;
    if (
      this.searchItemFilterString.trim().length === 0 ||
      this.searchItemFilterString.trim() === "" ||
      this.searchItemFilterString.trim().length === null
    ) {
      checkItemName = false;
    } else {
      checkItemName = true;
    }
    if (this.selectedDivisionId > 0) {
      checkDivision = true;
    } else {
      checkDivision = false;
    }
    if (this.selectedCategoryId > 0) {
      checkCategory = true;
    } else {
      checkCategory = false;
    }
    if (!checkCategory && !checkDivision && !checkItemName && !this.isZeroAvItems && !this.isZeroAvlWareHouse) {
      this.totalItemsCountinPagination = this.originalItemsList.length;
      if (this.originalItemsList.length > this.pageSize) {
        this.itemsList = this.originalItemsList.slice(startIndex, endIndex);
      } else {
        this.itemsList = this.originalItemsList;
      }
      this.pageIndex = pageIndex;
      return;
    }
    let tempList = [];
    this.originalItemsList.forEach((i) => {
      if (
        (!checkDivision || i.divisionId === this.selectedDivisionId) &&
        (!checkCategory || i.itemCategoryId === this.selectedCategoryId) &&
        (!checkItemName ||
          (i.itemName + "")
            .toLowerCase()
            .includes(this.searchItemFilterString.toLowerCase().trim()) ||
          (i.itemCode + "")
            .toLowerCase()
            .includes(this.searchItemFilterString.toLowerCase().trim()) ||
          (i.uom + "")
            .toLowerCase()
            .includes(this.searchItemFilterString.toLowerCase().trim())) &&
        (this.isZeroAvItems
          ? (this.showAvlQty ? (i.availableQtyInPcs / i.piecesInPack) > 0 : true)
          : true) &&
        (this.isZeroAvItems
          ? (this.showQuantityInOrder ? (i.stockQty / i.piecesInPack) > 0 : true)
          : true) &&
          (this.isZeroAvlWareHouse
            ? (this.showMWHQty ? (i.warehouseAvailableQtyInPCs / i.piecesInPack) > 0 : true)
            : true)
      ) {
        tempList.push(i);
      }
    });
    this.totalItemsCountinPagination = tempList.length;
    if (tempList.length > this.pageSize) {
      this.itemsList = tempList.slice(startIndex, endIndex);
    } else {
      this.itemsList = tempList;
    }

    this.pageIndex = pageIndex;
  }
  fillTotalRequiredQty() {
    this.totals.itemsCount = this.calculationService.fillItemsCount(
      this.addedItemsList
    );
    var itemsQtyTotal = 0;
    let value = 0;
    this.addedItemsList.forEach((i) => {
      itemsQtyTotal = itemsQtyTotal + i.requiredQty;
      value += i.price * i.requiredQty;
    });
    this.totals.itemsQtyTotal = itemsQtyTotal;

    if (
      this.formProcessMode == FormProcessMode.LoadRequest &&
      this.checkAvailableCreditToLoad.includes("1")
    ) {
      this.valueChangeEvent.emit(value);
    }
  }
  updateAvailabilityItemLevel(selectedItemId: number) {
    if (
      this.allItemsStockList.filter((x) => x.itemId === selectedItemId)
        .length <= 0
    ) {
      return;
    }
    var itemStock = this.allItemsStockList.find(
      (x) => x.itemId === selectedItemId
    );
    var addedPacksforSameItem = this.addedItemsList.filter(
      (x) => x.itemId === selectedItemId
    );
    var packsForSameItem = this.originalItemsList.filter(
      (pack) => pack.itemId === selectedItemId
    );

    // 1. update available for item stock list
    itemStock.availableQtyInPcs = itemStock.actualAvailableQtyInPcs;
    addedPacksforSameItem.forEach((pack) => {
      if (pack.requiredQty && pack.requiredQty > 0) {
        itemStock.availableQtyInPcs -= pack.requiredQty * pack.piecesInPack;
      }
    });

    // 2. update available for each item pack to be equals to other added packs qty with exclude current pack,
    // to do that update avl for pack to be same as avl for item in stock list then add pack required qty
    packsForSameItem.forEach((pack) => {
      pack.isExceedAvailableQty = false;
      pack.availableQtyInPcs = itemStock.availableQtyInPcs;
      if (pack.requiredQty > 0) {
        pack.availableQtyInPcs += pack.requiredQty * pack.piecesInPack;
        if (
          pack.availableQtyInPcs < pack.requiredQty * pack.piecesInPack &&
          (this.blockIfExceedAvailable || this.confirmationIfExceedAvailable)
        ) {
          pack.isExceedAvailableQty = true;
        }
      }
    });
  }
  updateAvailabilityBatchLevel(selectedItemId: number) {
    if (
      this.allItemsStockList.filter((x) => x.itemId === selectedItemId)
        .length <= 0
    ) {
      return;
    }
    var addedPacksforSameItem = this.addedItemsList.filter(
      (x) => x.itemId === selectedItemId
    );
    var allPacksForSameItem = this.originalItemsList.filter(
      (x) => x.itemId === selectedItemId
    );
    var stockItem = this.allItemsStockList.filter(
      (x) => x.itemId === selectedItemId
    );

    var avlInPcsItemLevel = 0;
    // 1. update available for item stock list
    stockItem.forEach((itemBatch) => {
      itemBatch.availableQtyInPcs = itemBatch.actualAvailableQtyInPcs;
      avlInPcsItemLevel += itemBatch.actualAvailableQtyInPcs; // add avl from batch
      addedPacksforSameItem.forEach((addedPack) => {
        if (addedPack.selectedBatches) {
          addedPack.selectedBatches
            .filter(
              (selectedBatch) =>
                selectedBatch.itemId === itemBatch.itemId &&
                selectedBatch.batchNo === itemBatch.batchNo &&
                selectedBatch.expiryDateModel.year ==
                itemBatch.expiryDateModel.year &&
                selectedBatch.expiryDateModel.month ==
                itemBatch.expiryDateModel.month &&
                selectedBatch.expiryDateModel.day ==
                itemBatch.expiryDateModel.day
            )
            .forEach((selectedBatch) => {
              itemBatch.availableQtyInPcs -=
                selectedBatch.requiredQty * addedPack.piecesInPack;
              avlInPcsItemLevel -=
                selectedBatch.requiredQty * addedPack.piecesInPack;
            });
        }
      });
    });

    // 2. update available quantity for all item pack-batches
    stockItem.forEach((itemBatch) => {
      allPacksForSameItem.forEach((pack) => {
        pack.isExceedAvailableQty = false;
        pack.itemStockBatches
          .filter(
            (batch) =>
              batch.itemId === itemBatch.itemId &&
              batch.batchNo === itemBatch.batchNo &&
              batch.expiryDateModel.year == itemBatch.expiryDateModel.year &&
              batch.expiryDateModel.month == itemBatch.expiryDateModel.month &&
              batch.expiryDateModel.day == itemBatch.expiryDateModel.day
          )
          .forEach((batch) => {
            batch.availableQtyInPcs = itemBatch.availableQtyInPcs;
            if (batch.requiredQty && batch.requiredQty > 0) {
              // 3. update available for each item pack batch to be equals to other added packs qty from same item batch with exclude current pack qty
              // to do that update avl for pack batch to be same as avl for item batch in stock list then add pack batch required qty
              batch.availableQtyInPcs += batch.requiredQty * pack.piecesInPack;
              if (
                batch.availableQtyInPcs <
                batch.requiredQty * pack.piecesInPack &&
                (this.blockIfExceedAvailable ||
                  this.confirmationIfExceedAvailable)
              ) {
                pack.isExceedAvailableQty = true;
              }
            }
          });
      });
    });

    //3. handle pack.availableQtyInPcs
    allPacksForSameItem.forEach((pack) => {
      pack.availableQtyInPcs = avlInPcsItemLevel;
      if (pack.requiredQty > 0) {
        pack.availableQtyInPcs += pack.requiredQty * pack.piecesInPack;
        if (
          pack.availableQtyInPcs < pack.requiredQty * pack.piecesInPack &&
          (this.blockIfExceedAvailable || this.confirmationIfExceedAvailable)
        ) {
          pack.isExceedAvailableQty = true;
        }
      }
    });
  }
  checkAvailabilityBeforeCheckOut(numberOfContainerItems: number): boolean {
    var isExceed = false;
    if (this.checkForAvailableItemLevel || numberOfContainerItems >= 0) {
      var list = [];
      if (this.checkForAvailableItemLevel) {
        list = this.addedItemsList.filter((item) => !item.hasSerialNumber);
      } else {
        list = this.addedItemsList.filter(
          (item) => !item.hasSerialNumber && item.itemTypeId === 2
        );
      }

      list.forEach((addedPack) => {
        var index = -1;
        // check if exist in exceed avl list, then no need to check other packs else continue checking :
        index = this.itemsExceedAvlList.findIndex(
          (x) => x.itemId === addedPack.itemId
        );

        if (index < 0) {
          // if available on item level < 0 then it's exceed avl
          index = this.allItemsStockList.findIndex(
            (x) => x.itemId === addedPack.itemId && x.availableQtyInPcs < 0
          );
          if (index >= 0) {
            isExceed = true;
            addedPack.isExceedAvailableQty = true;
            // Append distinct items only
            this.itemsExceedAvlList.push(addedPack);
          }
        } else {
          // if one item exceed then all his packs exceed
          addedPack.isExceedAvailableQty = true;
        }
      });
    }
    if (this.checkForAvailableBatchLevel) {
      this.addedItemsList
        .filter((item) => !item.hasSerialNumber && item.itemTypeId != 2)
        .forEach((addedPack) => {
          var index = -1;
          // check if exist in exceed avl list, then no need to check other packs else continue checking :
          index = this.itemsExceedAvlList.findIndex(
            (x) => x.itemId === addedPack.itemId
          );
          if (index < 0) {
            addedPack.selectedBatches.forEach((addedBatch) => {
              // check if exist in exceed avl list, then no need to check other batches else continue checking :
              index = this.itemsExceedAvlList.findIndex(
                (x) => x.itemId === addedPack.itemId
              );
              if (index < 0) {
                // no need to check new added batch they already exceed and user know that
                // if available on item - batch level < 0 then this batch in exceed
                index = this.allItemsStockList.findIndex(
                  (x) =>
                    !x.isNewBatch &&
                    x.itemId === addedBatch.itemId &&
                    x.batchNo === addedBatch.batchNo &&
                    x.expiryDateModel.year == addedBatch.expiryDateModel.year &&
                    x.expiryDateModel.month ==
                    addedBatch.expiryDateModel.month &&
                    x.expiryDateModel.day == addedBatch.expiryDateModel.day &&
                    x.availableQtyInPcs < 0
                );
                if (index >= 0) {
                  addedPack.isExceedAvailableQty = true;
                  isExceed = true;
                  // Append distinct items only
                  this.itemsExceedAvlList.push(addedPack);
                }
              }
            });
          } else {
            // if one item exceed then all his packs exceed
            addedPack.isExceedAvailableQty = true;
          }
        });
    }
    return isExceed;
  }
  resetDiscount(item) {
    if (this.isROIScreen) {
      item.discount = 0;
      item.discountTypeId = DiscountValueTypes.Percentage.valueOf();
    }
  }
  onZeroAvailableItemsChange(checked) {
    const addedItemsCopy = [...this.addedItemsList];
    addedItemsCopy.forEach(item => {
      if (this.showAvlQty) {
        if ((item.availableQtyInPcs / item.piecesInPack) <= 0) {
          this.onDeleteItemClicked(item);
        }
      } else if (this.showQuantityInOrder) {
        if ((item.stockQty / item.piecesInPack) <= 0) {
          this.onDeleteItemClicked(item);
        }
      }
      if(this.showMWHQty){
        if ((item.warehouseAvailableQtyInPCs / item.piecesInPack) <= 0) {
          this.onDeleteItemClicked(item);
        }
      }
    });
    this.isZeroAvItems = checked;
    this.isZeroAvlWareHouse = checked && this.formProcessMode === FormProcessMode.LoadRequest;
    this.onItemsFilterFindClicked();
  }
  //#endregion

  //#region [EVENTS]

  onHeaderDiscountTypeChange(discountType) {
    this.selectedROIDiscountTypeId = discountType;
    this.onHeaderDiscountValueChanged();
    //Recalculate Discounts
  }
  onDiscountTypeChange(item, discountType) {
    item.discountTypeId = discountType;
    this.onAddEditItemClicked(item);
  }

  onPriceValueChanged(item: ItemPackModel) {
    if (!item.price || item.price === 0) {
      this.onDeleteItemClicked(item);
    } else {
      this.onAddEditItemClicked(item);
    }
    // this.onAddEditItemClicked(item);
  }



  onHeaderDiscountValueChanged() {
    //Recalculate Discounts
    this.selectedROIDiscountPercentage = 0;
    if (this.selectedROIDiscount > 0) {
      if (this.selectedROIDiscountTypeId === DiscountValueTypes.Percentage.valueOf()) {
        if (this.selectedROIDiscount > 100) {
          this.selectedROIDiscount = 100;
        }
        this.selectedROIDiscountPercentage = this.selectedROIDiscount;
      } else if (this.selectedROIDiscountTypeId === DiscountValueTypes.Amount.valueOf()) {
        if (
          this.totals.grossTotal <
          this.calculationService.getNumberOnDigitFormat(
            this.selectedROIDiscount
          )
        ) {
          this.selectedROIDiscount = this.totals.grossTotal;
        }
        this.selectedROIDiscountPercentage =
          (this.selectedROIDiscount / this.totals.grossTotal) * 100;
      }
      //Recalculate
      if (this.addedItemsList) {
        this.addedItemsList.forEach((i) => {
          this.calculationService.updateTotals(this.totals, i, false);
          i.discountTypeId = DiscountValueTypes.Percentage.valueOf();
          i.discount = this.selectedROIDiscountPercentage;
          this.calculationService.calculatePackTotal(i);
          this.calculationService.updateTotals(this.totals, i, true);
        });
      }
    }
  }
  onDiscountValueChanged(item: ItemPackModel) {
    if (item.discount && item.discount > 0) {
      if (item.discountTypeId === DiscountValueTypes.Percentage.valueOf()) {
        if (item.discount > 100) {
          item.discount = 100;
        }
      } else if (item.discountTypeId === DiscountValueTypes.Amount.valueOf()) {
        let gross = this.calculationService.getNumberOnDigitFormat(
          item.price * item.requiredQty
        );
        if (
          gross < this.calculationService.getNumberOnDigitFormat(item.discount)
        ) {
          item.discount = gross;
        }
      }
    }
    this.onAddEditItemClicked(item);
  }
  onCheckOutClicked() {
    this.isCartExpanded = false;
    let checkIfNotExistFOCItems = true;
    let checkIfNotExistReturnItems = true;
    let checkIfNotExistSaleItems = true;
    var index = this.addedItemsList.findIndex(x => x.requiredQty === 0);
    if (index >= 0) {
      this.addedItemsList.splice(index, 1);
    }
    if ((this.formProcessMode === FormProcessMode.Orders && this.order.orderTypeId == OrderTypes.Sales.valueOf()) || this.formProcessMode === FormProcessMode.Sales || this.formProcessMode === FormProcessMode.Delivery || this.formProcessMode === FormProcessMode.ExchangeSale || (((this.formProcessMode === FormProcessMode.Orders && this.order.orderTypeId == OrderTypes.Return.valueOf()) || this.formProcessMode === FormProcessMode.Return || this.formProcessMode === FormProcessMode.ExchangeReturn) && !this.allowReturnWithZeroPrice)) {
      var index = this.addedItemsList.findIndex(x => x.price == 0 && x.salesTransactionTypeId != SalesTransactionTypes.FOC.valueOf());
      if (index >= 0) {
        let itemNames = this.addedItemsList[index].itemCodeName;
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgItemHasZeroPrice).replace('{0}', itemNames));
        return;
      }
    }
    if (this.isFOCMode && !this.isExchangeMode) {
      let filterItems = this.addedItemsList.filter(
        (x) => x.salesTransactionTypeId != SalesTransactionTypes.FOC.valueOf()
      );
      checkIfNotExistFOCItems = filterItems.length <= 0 ? false : true;
    }
    if (this.isExchangeMode) {
      let filterItems = this.addedItemsList.filter(
        (x) => x.salesTransactionTypeId != SalesTransactionTypes.None.valueOf()
      );
      checkIfNotExistReturnItems = filterItems.length <= 0 ? false : true;
      let filterSaleItems = this.addedItemsList.filter(
        (x) => x.salesTransactionTypeId != SalesTransactionTypes.Sales.valueOf()
      );
      checkIfNotExistSaleItems = filterSaleItems.length <= 0 ? false : true;
    }
    var invalidReturnReason = false;
    var invalidExpiryDate = false;
    var invalidPackStatus = false;
    var invalidBatchNumber = false;
    var invalidExpiryForGoodReturn = false;
    var isValidQty = true;
    if (this.orderTypeId === OrderTypes.Return.valueOf() || this.isExchangeMode) {
      // if(this.forceSelectingPackStatusPerTransaction &&
      //   (!this.returnReasonForm.get('txPackStatusId').value || this.returnReasonForm.get('txPackStatusId').value < 0)) {
      //     this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption)
      //     ,this.translateService.instant(ConstantMessages.DescPackStatusMustBeDefinedForTransaction));
      //     return;
      //   }
      var itemReturnBatchStock = [];
      var invalidItemIds = [];
      this.addedItemsList.forEach(i => {
        if (!this.isExchangeMode || (this.isExchangeMode && i.isReturnItem == true)) {
          if (!this.UseDefaultExpiryAndBatchForItems && !this.restrictReturnFromInvoices) {
            // If enableEnteringBatchNoInReturn check for batch no.
            if (
              this.enableEnteringBatchNoInReturn &&
              (!i.batchNo || i.batchNo === "" || i.batchNo.trim() === "")
            ) {
              invalidBatchNumber = true;
              i.hasReturnReason = false;
              return;
            } else {
              i.hasReturnReason = true;
            }
            // If ShowExpiryInReturnsAndExchange check for EnterExpiryDateForGoodReturnsOnly:
            if (this.showExpiryInReturnsAndExchange) {
              //If enterExpiryDateForGoodReturnsOnly === false, expiry date is mandatory for all items.
              if (
                !this.enterExpiryDateForGoodReturnsOnly &&
                (!i.expiryDateModel || i.expiryDateModel === undefined)
              ) {
                invalidExpiryDate = true;
                return;
              } else if (this.enterExpiryDateForGoodReturnsOnly) {
                var packStatusInfo = this.packStatusDataSource.filter(
                  (s) => s.packStatusId === i.packStatusId && s.reSellable === 1
                );
                if (
                  packStatusInfo &&
                  packStatusInfo.length > 0 &&
                  (!i.expiryDateModel || i.expiryDateModel === undefined)
                ) {
                  invalidExpiryForGoodReturn = true;
                  return;
                }
              }
            }
          }
          // check for packStatusId for all items.
          if (i.packStatusId <= 0 || !i.packStatusId) {
            invalidPackStatus = true;
            i.hasReturnReason = false;
            return;
          } else {
            i.hasReturnReason = true;
          }

          if (
            this.showReturnReason &&
            (i.returnReasonId === -1 || !i.returnReasonId)
          ) {
            invalidReturnReason = true;
            return;
          }

          if (this.orderTypeId === OrderTypes.Return.valueOf() && this.restrictReturnFromInvoices) {
            if (invalidItemIds.findIndex(invalidId => invalidId === i.itemId) > 0) {
              i.isExceedAvailableQty = true;
            }
            if (i.returnFromInvoiceAvailableBatchList) {
              var rtns = i.returnFromInvoiceAvailableBatchList.filter(i => i.requiredQuantity > 0)
              rtns.forEach(inv => {
                var idx = itemReturnBatchStock.
                  findIndex(s =>
                    s.transactionId === inv.transactionId &&
                    s.batchNo === inv.batchNo &&
                    s.itemId === inv.itemId &&
                    s.expiryDateModel &&
                    s.expiryDateModel.day === inv.expiryDateModel.day &&
                    s.expiryDateModel.month === inv.expiryDateModel.month &&
                    s.expiryDateModel.year === inv.expiryDateModel.year
                  );
                if (idx === -1) {
                  var obj = {
                    transactionId: inv.transactionId,
                    batchNo: inv.batchNo,
                    itemId: inv.itemId,
                    availableQtyFromInvoice: inv.originalAvailableQtyFromInvoice,
                    requiredQuantity: 0 + inv.requiredQuantity * i.piecesInPack,
                    expiryDateModel: inv.expiryDateModel,
                  }
                  if (obj.requiredQuantity > obj.availableQtyFromInvoice) {
                    isValidQty = false;
                    i.isExceedAvailableQty = true;
                    inv.invalid = true;
                    if (invalidItemIds.findIndex(itm => itm.itemId === i.itemId) === -1) {
                      invalidItemIds.push(i.itemId);
                    }
                  }
                  itemReturnBatchStock.push(obj);
                } else {
                  itemReturnBatchStock[idx].requiredQuantity += inv.requiredQuantity * i.piecesInPack;
                  if (itemReturnBatchStock[idx].requiredQuantity > itemReturnBatchStock[idx].availableQtyFromInvoice) {
                    isValidQty = false;
                    i.isExceedAvailableQty = true;
                    inv.invalid = true;
                    if (invalidItemIds.findIndex(itm => itm.itemId === i.itemId) === -1) {
                      invalidItemIds.push(i.itemId);
                    }
                  }
                }
              });
            }
          }
        }
      });
      this.addedItemsList.forEach(i => {
        if (invalidItemIds.findIndex(id => id === i.itemId) > -1 && !i.isExceedAvailableQty) {
          i.isExceedAvailableQty = true;
        }
      });
    }

    if (this.isFOCMode) {
      this.addedItemsList.forEach(i => {
        if (i.salesTransactionTypeId == SalesTransactionTypes.FOC.valueOf()) {
          i.addedSerials = i.addedFOCSerials;
        }
      });
    }
    if (this.transactionMainType == TransactionMainTypes.WarehouseTransaction) {
      this.addedItemsList.forEach(i => {
        i.priceString = this.calculationService.getNumberOnDigitFormat(i.price).toString();
      });
    }
    if (this.isExchangeMode) {
      this.salesNetTotal = 0
      this.returnNetTotal = 0;
      this.addedItemsList.forEach(i => {
        if (i.salesTransactionTypeId == SalesTransactionTypes.Sales.valueOf()) {
          this.salesNetTotal += i.netTotal;
        } if (i.salesTransactionTypeId == SalesTransactionTypes.None.valueOf()) {
          this.returnNetTotal += i.netTotal;
        }
      });
      if (!this.isValidateToSaveExchange()) {
        return;
      }
    }
    if (!isValidQty) {
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption)
        , this.translateService.instant(ConstantMessages.MsgSomeUnAvailableBatches));
      return;
    }
    if (invalidPackStatus) {
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption)
        , this.translateService.instant(ConstantMessages.DescPackStatusMustBeDefinedForAllItems));
      return;
    }
    if (invalidReturnReason) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(
          ConstantMessages.DescReturnReasonBeDefinedForAllItems
        )
      );
      return;
    }
    if (invalidExpiryForGoodReturn) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(
          ConstantMessages.DescExpiryDateMustBeDefinedForAllGoodReturns
        )
      );
      return;
    }
    if (invalidExpiryDate) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(
          ConstantMessages.DescExpiryDateMustBeDefinedForAllGoodReturns
        )
      );
      return;
    }
    if (invalidBatchNumber) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(
          ConstantMessages.DescBatchNumberMustBeDefinedForAllItems
        )
      );
      return;
    }

    if (this.addedItemsList && this.addedItemsList.length > 0) {
      if ((this.orderTypeId === OrderTypes.Return.valueOf() && this.restrictReturnFromInvoices)) {
        if (this.addedItemsList.filter(i => i.requiredQty > 0).length === 0) {
          this.coreSession.showWarrning(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(
              ConstantMessages.MsgNoItemsFound
            )
          );
          return;
        }
      }
      if (!checkIfNotExistReturnItems) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(
            ConstantMessages.DescYouMustAddAtLeastOneSalesItem
          )
        );
        return;
      }
      if (!checkIfNotExistSaleItems) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(
            ConstantMessages.DescYouMustAddAtLeastOneReturnsItem
          )
        );
        return;
      }
      if (checkIfNotExistFOCItems) {
        var obj = {
          itemsList: this.addedItemsList,
          totals: this.totals,
          showBatches: this.enableBatchSelection || this.enableBatchInsertion
            ||
            (this.orderTypeId === OrderTypes.Return.valueOf() && this.enableReturnBatchFromInvoice) || (this.isExchangeMode && this.enableReturnBatchFromInvoice),
        };
        if (this.enableAddSerial && !this.checkIfSerialsAdded()) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(
              ConstantMessages.Desc_Serials_Count_Not_Match_Req_Qnty
            )
          );
          return;
        }
        if (
          (this.enableBatchInsertion || this.enableBatchSelection) &&
          !this.checkIfAllBatchesInserted()
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(
              ConstantMessages.Desc_Batches_Not_Selected
            )
          );
          return;
        }
        if (
          this.formProcessMode === FormProcessMode.Delivery &&
          !this.checkIfQuantityMatchRequested()
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(
              ConstantMessages.Desc_Applied_Quantity_Not_Match_Req_Qnty
            )
          );
          return;
        }
        if (
          this.formProcessMode === FormProcessMode.PartialDelivery &&
          !this.checkIfQuantityMoreThanRequested()
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(
              ConstantMessages.RequierdMoreThanRequested
            )
          );
          return;
        }
        this.itemsExceedAvlList = [];
        let numberOfContainerItems = this.addedItemsList.findIndex(
          (x) => x.itemTypeId === 2
        );

        if (
          this.checkForAvailableItemLevel ||
          this.checkForAvailableBatchLevel
        ) {
          var isExceedAvailable = this.checkAvailabilityBeforeCheckOut(
            numberOfContainerItems
          );
          if (
            isExceedAvailable &&
            this.itemsExceedAvlList &&
            this.itemsExceedAvlList.length > 0
          ) {
            var itemsExceedAvlNamesList = this.itemsExceedAvlList.map(
              ({ itemName }) => itemName
            );
            var itemsExceedAvlNamesString = itemsExceedAvlNamesList.join("\n");

            if (this.blockIfExceedAvailable) {
              this.coreSession.ModalDialog.ShowMessage(
                this.translateService.instant(
                  ConstantMessages.MsgExceedAvailableQtyBlock
                ) +
                "\n" +
                itemsExceedAvlNamesString,
                DialogMode.Ok,
                this.translateService.instant(ConstantMessages.ErrorCaption)
              );
              // this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgExceedAvailableQtyBlock));
              return;
            } else if (this.confirmationIfExceedAvailable) {
              this.coreSession.ModalDialog.ShowMessage(
                this.translateService.instant(
                  ConstantMessages.MsgExceedAvailableQtyConfirmation
                ) +
                "\n" +
                itemsExceedAvlNamesString,
                DialogMode.YesNo,
                this.translateService.instant(ConstantMessages.WarningCaption)
              ).then((res: DialogResult) => {
                if (res === DialogResult.Yes) {
                  this.calculationService.roundTotals(this.totals);
                  this.checkOutEvent.emit(obj);
                }
              });
            }
          } else {
            this.calculationService.roundTotals(this.totals);
            this.checkOutEvent.emit(obj);
          }
        } else {
          this.calculationService.roundTotals(this.totals);
          this.checkOutEvent.emit(obj);
        }
      } else {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(
            ConstantMessages.DescYouMustAddAtLeastOneSalesItem
          )
        );
      }
    } else {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgNoItemsFound)
      );
    }
  }
  isValidateToSaveExchange(): boolean {
    this.forceEqualityInExchange = (this.customerTypeId == CustomerTypes.CashCustomer.valueOf() && this.ExchangeRuleForCashCustomers.includes("2")) || (this.customerTypeId == CustomerTypes.CreditCustomer.valueOf() && this.ExchangeRuleForCreditCustomers.includes("2"));
    this.forceSalesGreaterThanOrEqualsReturnInExchange = (this.customerTypeId == CustomerTypes.CashCustomer.valueOf() && this.ExchangeRuleForCashCustomers.includes("1")) || (this.customerTypeId == CustomerTypes.CreditCustomer.valueOf() && this.ExchangeRuleForCreditCustomers.includes("1"));
    this.forceSalesLessThanOrEqualsReturnInExchange = (this.customerTypeId == CustomerTypes.CashCustomer.valueOf() && this.ExchangeRuleForCashCustomers.includes("3")) || (this.customerTypeId == CustomerTypes.CreditCustomer.valueOf() && this.ExchangeRuleForCreditCustomers.includes("3"));

    if (this.forceSalesGreaterThanOrEqualsReturnInExchange) {
      if (this.returnNetTotal > this.salesNetTotal) {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgSalesMustBeGreatorThanReturnExchange));
        return false;
      }
    }
    else if (this.forceEqualityInExchange && !this.AllowAddExchangeOrderWithoutReturnDetails) {
      if (this.returnNetTotal != this.salesNetTotal) {
        this.isTolerated = this.CheckToleranceValue(this.salesNetTotal, this.returnNetTotal);
        if (!this.isTolerated) {
          this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgEqualExchange));
          return false;
        }
      }
    }
    else if (this.forceSalesLessThanOrEqualsReturnInExchange && this.AllowAddExchangeOrderWithoutReturnDetails) {
      if (this.returnNetTotal < this.salesNetTotal) {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgSalesMustBeLessThanReturnExchange));
        return false;
      }
    }
    else {
      return true;
    }
    return true;
  }
  CheckToleranceValue(saleTotal, returnTotal): boolean {
    let differenceAmount = 0;
    let differencePercentage = 0;
    if (this.AcceptedToleranceInExchange > "0" || this.AcceptedPercentageOfToleranceInExchange > "0") {

      differenceAmount = Math.abs(returnTotal - saleTotal);
      if (differenceAmount.toString() > this.AcceptedToleranceInExchange) {
        return false;
      }
      if (this.AcceptedPercentageOfToleranceInExchange > "0") {
        differencePercentage = Math.round(((differenceAmount / Math.max(returnTotal, saleTotal)) * 100));
        if (differencePercentage.toString() > this.AcceptedPercentageOfToleranceInExchange)
          return false;
      }
    }
    return false;
  }
  checkIfQuantityMatchRequested() {
    let isMatch = true;
    if (this.addedItemsList && this.addedItemsList.length > 0) {
      let index = this.addedItemsList.findIndex(
        (x) => x.originalQuantity != x.requiredQty
      );
      if (index !== -1) {
        isMatch = false;
      }
      return isMatch;
    }
  }
  checkIfQuantityMoreThanRequested() {
    let isMatch = true;
    if (this.addedItemsList && this.addedItemsList.length > 0) {
      let index = this.addedItemsList.findIndex(
        (x) => x.originalQuantity < x.requiredQty
      );
      if (index !== -1) {
        isMatch = false;
      }
      return isMatch;
    }
  }
  checkIfAllBatchesInserted() {
    let allAdded = true;
    if (this.addedItemsList && this.addedItemsList.length > 0) {
      this.addedItemsList
        .filter((x) => !x.hasSerialNumber)
        .forEach((item) => {
          if (allAdded) {
            if (
              (!item.selectedBatches || item.selectedBatches.length === 0) &&
              item.itemTypeId !== 2
            ) {
              allAdded = false;
            }
          }
        });
    }
    return allAdded;
  }
  checkIfSerialsAdded() {
    let allAdded = true;
    let serialsCount = 0;

    if (this.addedItemsList && this.addedItemsList.length > 0) {
      this.addedItemsList
        .filter((x) => x.hasSerialNumber)
        .forEach((item) => {
          if (allAdded) {
            serialsCount = 0;
            if (item.addedSerials) {
              if (this.showSerialsAsRanges) {
                if (
                  this.formProcessMode === FormProcessMode.InputVoucher ||
                  this.formProcessMode === FormProcessMode.SerialsAggregation
                ) {
                  item.addedSerials
                    .filter(
                      (serial) =>
                        serial.parentSerial === null ||
                        serial.parentSerial === ""
                    )
                    .forEach((serial) => {
                      serialsCount += serial.serialsCount;
                    });
                } else {
                  item.addedSerials.forEach((serial) => {
                    serialsCount += serial.serialsCount;
                  });
                }
              } else {
                if (
                  this.formProcessMode === FormProcessMode.InputVoucher ||
                  this.formProcessMode === FormProcessMode.SerialsAggregation
                ) {
                  serialsCount = item.addedSerials.filter(
                    (serial) => serial.parentSerial === ""
                  ).length;
                } else {
                  serialsCount = item.addedSerials.length;
                }
              }
            }
            if (!(allAdded && item.requiredQty === serialsCount)) {
              allAdded = false;
            }
          }
        });
    }
    return allAdded;
  }
  onUpdateAddedItem(addedItem) {
    var index = this.originalItemsList.findIndex(
      (x) => x.itemId === addedItem.itemId && x.packId === addedItem.packId
    );
    if (index >= 0) {
      if (this.isFOCMode) {
        if (
          addedItem.salesTransactionTypeId ==
          SalesTransactionTypes.Sales.valueOf()
        ) {
          this.originalItemsList[index].requiredQty = addedItem.requiredQty;
          this.originalItemsList[index].isFOCItem = false;
        } else if (
          addedItem.salesTransactionTypeId ==
          SalesTransactionTypes.FOC.valueOf()
        ) {
          this.originalItemsList[index].oldRequiredFOCQty =
            this.originalItemsList[index].requiredFOCQty;
          this.originalItemsList[index].requiredFOCQty =
            addedItem.requiredFOCQty;
          this.originalItemsList[index].isFOCItem = true;
        }
      } else {
        if (!this.isExchangeMode) {
          this.originalItemsList[index].requiredQty = addedItem.requiredQty;
          this.originalItemsList[index].isFOCItem = false;
        }
      }
      if (this.isExchangeMode) {
        if (
          addedItem.salesTransactionTypeId ==
          SalesTransactionTypes.Sales.valueOf()
        ) {
          this.originalItemsList[index].requiredQty = addedItem.requiredQty;
          this.originalItemsList[index].isReturnItem = false;
        } else if (
          addedItem.salesTransactionTypeId ==
          SalesTransactionTypes.None.valueOf()
        ) {
          this.originalItemsList[index].oldRequiredReturnQty =
            this.originalItemsList[index].requiredReturnQty;
          this.originalItemsList[index].requiredReturnQty =
            addedItem.requiredQty;
          this.originalItemsList[index].isReturnItem = true;
        }
      } else {
        this.originalItemsList[index].requiredQty = addedItem.requiredQty;
        this.originalItemsList[index].isReturnItem = false;
      }
      this.onAddEditItemClicked(this.originalItemsList[index]);
    }
    this.qtyChanged(addedItem);
  }

  onAddEditItemClicked(item: ItemPackModel, isReturnMode?: boolean) {
    if (this.formProcessMode === FormProcessMode.Return || (this.formProcessMode == FormProcessMode.Orders && this.orderTypeId == OrderTypes.Return.valueOf())) {
      if (this.useReturnPriceListForReturns) {
        let index = this.priceRangeList.findIndex(p => (item.packId === p.packId &&
          item.requiredQty >= p.rangeStart &&
          item.requiredQty <= p.rangeEnd &&
          p.isReturnItem == true));
        item.price = index > -1 ? this.priceRangeList[index].price : 0;
        item.tax = index > -1 ? this.priceRangeList[index].tax : 0;
        if (index == -1) {
          let index = this.priceRangeList.findIndex(p => (item.packId === p.packId &&
            item.requiredQty >= p.rangeStart &&
            item.requiredQty <= p.rangeEnd &&
            p.isReturnItem == false));
          item.price = index > -1 ? this.priceRangeList[index].price : 0;
          item.tax = index > -1 ? this.priceRangeList[index].tax : 0;
        }
      } else {
        let index = this.priceRangeList.findIndex(p => (item.packId === p.packId &&
          item.requiredQty >= p.rangeStart &&
          item.requiredQty <= p.rangeEnd &&
          p.isReturnItem == false));
        item.price = index > -1 ? this.priceRangeList[index].price : 0;
        item.tax = index > -1 ? this.priceRangeList[index].tax : 0;
      }
    } else if (!this.isROIScreen && !this.isPriceChanged) {
      let index = this.priceRangeList.findIndex(p => (item.packId === p.packId &&
        item.requiredQty >= p.rangeStart &&
        item.requiredQty <= p.rangeEnd &&
        p.isReturnItem == false));
      item.price = index > -1 ? this.priceRangeList[index].price : 0;
        item.tax = index > -1 ? this.priceRangeList[index].tax : 0;
      item.basePrice = index > -1 ? this.priceRangeList[index].basePrice : 0;
      item.minPrice = index > -1 ? this.priceRangeList[index].minPrice : 0;
      item.maxPrice = index > -1 ? this.priceRangeList[index].maxPrice : 0;
    }
    if (isReturnMode) {
      if (this.useReturnPriceListForReturns) {
        let index = this.priceRangeList.findIndex(p => (item.packId === p.packId &&
          item.requiredReturnQty >= p.rangeStart &&
          item.requiredReturnQty <= p.rangeEnd &&
          p.isReturnItem == true));
        item.returnPrice = index > -1 ? this.priceRangeList[index].price : 0;
        // item.tax = index > -1 ? this.priceRangeList[index].tax : 0;
      } else {
        let index = this.priceRangeList.findIndex(p => (item.packId === p.packId &&
          item.requiredReturnQty >= p.rangeStart &&
          item.requiredReturnQty <= p.rangeEnd));
        item.returnPrice = index > -1 ? this.priceRangeList[index].price : 0;
        item.tax = index > -1 ? this.priceRangeList[index].tax : 0;
      }
    }
    if (this.isReturnItem) item.hasReturnReason = true;
    if (
      this.formProcessMode == FormProcessMode.Orders &&
      this.sessionData
        .getConfigurationValue(ConstantConfigurations.CheckConsumerPrice)
        .toLowerCase() === "true" &&
      item.consumerPrice <= 0 &&
      this.orderTypeId !== OrderTypes.Return.valueOf()
    ) {
      item.requiredQty = null;
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgInvalidConsumerPrice)
      ); // invalid consumer price
      return;
    }

    if (item.isFOCItem && this.isFOCMode) {
      item = this.prepareFocItem(item);
    }
    if ((isReturnMode && this.isExchangeMode) || item.isReturnItem) {
      item = this.prepareReturnItem(item);
    }

    if (!item.requiredQty || item.requiredQty === null) {
      item.requiredQty = 0;
    }
    if (!item.requiredReturnQty || item.requiredReturnQty === null) {
      item.requiredReturnQty = 0;
    }
    if (!item.oldRequiredQty || item.oldRequiredQty === null) {
      item.oldRequiredQty = 0;
    }
    // do nothing if user press delete or backspace on empty qty field
    if (item.requiredQty === 0 && item.oldRequiredQty === 0) {
      item.requiredQty = null;
      if (this.isFromSSCC) {
        item.requiredQty = 0;
      }
      this.resetDiscount(item);
      return;
    }
    if (this.isFromSSCC) {
      if (
        item.originalQuantity < item.requiredQty &&
        (this.formProcessMode === FormProcessMode.LoadRequest ||
          this.formProcessMode === FormProcessMode.Delivery)
      ) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(
            ConstantMessages.RequierdMoreThanRequested
          )
        );
        item.requiredQty = item.oldRequiredQty;
        return;
      }
    }
    if (this.enableAddSecondaryPack && item.hasSecondaryPack) {
      item = this.populateSecondaryPacks(item, false);
    }
    // this code will show warning msg when inserted qty exceed avl stock
    if (
      this.formProcessMode == FormProcessMode.Delivery ||
      this.formProcessMode === FormProcessMode.PartialDelivery
    ) {
      if (
        item.availableQtyInPcs < item.requiredQty * item.piecesInPack &&
        this.formProcessMode === FormProcessMode.PartialDelivery
      ) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(
            ConstantMessages.RequierdMoreThanRequested
          )
        );
        item.requiredQty = item.oldRequiredQty;
        return;
      }
      // else if (item.availableQtyInPcs != item.requiredQty * item.piecesInPack && this.formProcessMode === FormProcessMode.Delivery && item.hasSerialNumber) {
      //   this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.Desc_Serials_Count_Not_Match_Req_Qnty));
      //   item.requiredQty = item.oldRequiredQty;
      //   return;
      // }
    } else if (
      (this.checkForAvailableItemLevel || item.itemTypeId === 2) &&
      (this.blockIfExceedAvailable || this.confirmationIfExceedAvailable)
    ) {
      {
        if (item.availableQtyInPcs < item.requiredQty * item.piecesInPack) {
          this.coreSession.showWarrning(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(
              ConstantMessages.RequiredMoreThanAvailable
            )
          );
        }
      }
    }
    if (this.transactionMainType === TransactionMainTypes.CustomerTransaction) {
      if (!this.filterOnSalesType) {
        item.salesTransactionTypeId = item.isFOCItem
          ? SalesTransactionTypes.FOC.valueOf()
          : this.orderTypeId && this.orderTypeId === OrderTypes.Return.valueOf()
            ? SalesTransactionTypes.None.valueOf()
            : SalesTransactionTypes.Sales.valueOf();
      }
      if (this.isFOCMode) {
        item.salesTransactionTypeId = item.isFOCItem
          ? SalesTransactionTypes.FOC.valueOf()
          : this.orderTypeId && this.orderTypeId === OrderTypes.Return.valueOf()
            ? SalesTransactionTypes.None.valueOf()
            : SalesTransactionTypes.Sales.valueOf();
      }
      if (this.isExchangeMode) {
        item.salesTransactionTypeId = item.isReturnItem
          ? SalesTransactionTypes.None.valueOf()
          : SalesTransactionTypes.Sales.valueOf();
      }
    } else {
      item.salesTransactionTypeId = SalesTransactionTypes.None.valueOf();
    }
    // Check if pack added before :
    var index = -1;
    if (this.filterOnSalesType || this.isFOCMode || this.isExchangeMode)
      index = this.addedItemsList.findIndex(
        (a) =>
          a.itemId === item.itemId &&
          a.packId === item.packId &&
          a.salesTransactionTypeId === item.salesTransactionTypeId
      );
    else
      index = this.addedItemsList.findIndex(
        (a) => a.itemId === item.itemId && a.packId === item.packId
      );

    // Item is exist => Edit mode
    if (index >= 0) {
      // Delete item from added items and set item properties to default in original items list if the inserted qty is zero
      if (!item.requiredQty || item.requiredQty <= 0) {
        // this.onDeleteItemClicked(item);
        // return;
      }
      if (
        this.restrictReturnFromInvoices &&
        this.restrictReturnQtyFromInvoices &&
        this.orderTypeId === OrderTypes.Return.valueOf()
      ) {
        this.calculationService.updateReturnTotals(this.totals, item, false);
        this.calculationService.calculateReturnPackTotals(item);
      } else if (
        this.transactionMainType === TransactionMainTypes.CustomerTransaction ||
        this.isROIScreen ||
        (!this.restrictReturnFromInvoices &&
          this.orderTypeId === OrderTypes.Return.valueOf()) ||
        this.isExchangeMode ||
        this.transactionMainType === TransactionMainTypes.WarehouseTransaction
      ) {
        // Deduct old item total from totals
        this.calculationService.updateTotals(this.totals, item, false);
        // Calculate item total after edit quantity
        if (
          item.salesTransactionTypeId === SalesTransactionTypes.Sales ||
          this.isROIScreen ||
          this.formProcessMode === FormProcessMode.ProcessReturn ||
          this.orderTypeId == OrderTypes.Return.valueOf() ||
          this.formProcessMode === FormProcessMode.Return ||
          this.formProcessMode == FormProcessMode.ExchangeReturn ||
          this.transactionMainType === TransactionMainTypes.WarehouseTransaction
        ) {
          this.calculationService.calculatePackTotal(item);
        } else {
          this.calculationService.calculateFreePackTotal(item);
        }
      }
      if (
        (this.orderTypeId === OrderTypes.Return.valueOf() &&
          !this.restrictReturnFromInvoices) ||
        this.isExchangeMode
      ) {
        item.packStatusId =
          this.txLevelPackStatus && this.txLevelPackStatus.packStatusId
            ? this.txLevelPackStatus.packStatusId
            : -1;
        if (
          this.addedItemsList[index].returnReasonId &&
          this.addedItemsList[index].returnReasonId > 0
        ) {
          var returnReason = this.addedItemsList[index].returnReasonId;
          item.returnReasonId = returnReason;
        } else {
          item.returnReasonId =
            this.defaultReturnReason != null ? this.defaultReturnReason : -1;
        }
      }
      // Replace old item with edited one
      this.addedItemsList[index] = item;
    }
    // Item is not exist => Add mode
    else if (item.requiredQty > 0) {
      if (this.orderTypeId === OrderTypes.Return.valueOf()) {
        if (this.restrictReturnFromInvoices) {
          this.calculationService.calculateReturnPackTotals(item);
        } else {
          item.packStatusId = null;
          item.returnReasonId = null;
          this.calculationService.calculatePackTotal(item);
        }
      } else {
        if (
          (this.transactionMainType ===
            TransactionMainTypes.CustomerTransaction ||
            this.isROIScreen ||
            this.isExchangeMode || this.transactionMainType ===
            TransactionMainTypes.WarehouseTransaction) &&
          !item.isFOCItem
        ) {
          this.calculationService.calculatePackTotal(item);
        } else {
          this.calculationService.calculateFreePackTotal(item);
        }
      }
      if (
        this.useDefaultBatch &&
        this.orderTypeId !== OrderTypes.Return.valueOf()
      ) {
        item.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
        item.expiryDateModel.day = this.coreSession
          .DefaultExpiryDate()
          .getDay();
        item.expiryDateModel.month =
          this.coreSession.DefaultExpiryDate().getMonth() + 1;
        item.expiryDateModel.year = this.coreSession
          .DefaultExpiryDate()
          .getFullYear();
        item.batchNo = this.coreSession.DefaultBatchNumber;
      }
      if (this.useDefaultBatch && this.isExchangeMode && !item.isReturnItem) {
        item.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
        item.expiryDateModel.day = this.coreSession
          .DefaultExpiryDate()
          .getDay();
        item.expiryDateModel.month =
          this.coreSession.DefaultExpiryDate().getMonth() + 1;
        item.expiryDateModel.year = this.coreSession
          .DefaultExpiryDate()
          .getFullYear();
        item.batchNo = this.coreSession.DefaultBatchNumber;
        item.returnReasonId = -1;
        item.packStatusId = -1;
      }

      if (
        (this.useDefaultBatch &&
          this.orderTypeId === OrderTypes.Return.valueOf()) ||
        this.isExchangeMode
      ) {
        if (this.UseDefaultExpiryAndBatchForItems) {
          item.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
          item.expiryDateModel.day = this.coreSession
            .DefaultExpiryDate()
            .getDay();
          item.expiryDateModel.month =
            this.coreSession.DefaultExpiryDate().getMonth() + 1;
          item.expiryDateModel.year = this.coreSession
            .DefaultExpiryDate()
            .getFullYear();
          item.batchNo = this.coreSession.DefaultBatchNumber;
        } else if (!this.enableEnteringBatchNoInReturn) {
          item.batchNo = this.coreSession.DefaultBatchNumber;
        } else if (!this.showExpiryInReturnsAndExchange) {
          item.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
          item.expiryDateModel.day = this.coreSession
            .DefaultExpiryDate()
            .getDay();
          item.expiryDateModel.month =
            this.coreSession.DefaultExpiryDate().getMonth() + 1;
          item.expiryDateModel.year = this.coreSession
            .DefaultExpiryDate()
            .getFullYear();
        }
      }

      if (
        this.forceSelectingPackStatusPerTransaction &&
        this.txLevelPackStatus &&
        this.orderTypeId === OrderTypes.Return.valueOf() &&
        !this.restrictReturnFromInvoices
      ) {
        item.packStatusId = this.txLevelPackStatus.packStatusId;
      }
      if (
        this.forceSelectingPackStatusPerTransaction &&
        this.showReturnReason &&
        this.orderTypeId === OrderTypes.Return.valueOf() &&
        !this.restrictReturnFromInvoices
      ) {
        if (this.defaultReturnReason != undefined)
          item.returnReasonId =
            this.defaultReturnReason && this.defaultReturnReason > -1
              ? this.defaultReturnReason
              : null;
        else {
          item.returnReasonId = -1;
        }
      }
      // Add the item to the list
      this.addedItemsList.push(item);
    }

    // update totals with item totals
    if (
      this.transactionMainType === TransactionMainTypes.CustomerTransaction ||
      this.isROIScreen || this.transactionMainType === TransactionMainTypes.WarehouseTransaction
    ) {
      if (
        this.restrictReturnFromInvoices &&
        this.orderTypeId === OrderTypes.Return.valueOf()
      ) {
        this.calculationService.updateReturnTotals(this.totals, item, true);
      } else {
        this.calculationService.updateTotals(this.totals, item, true);
      }
    }

    this.fillTotalRequiredQty();

    //update Availability :
    if (this.checkForAvailableItemLevel || item.itemTypeId === 2) {
      this.updateAvailabilityItemLevel(item.itemId);
    } else if (this.checkForAvailableBatchLevel && item.itemTypeId != 2) {
      this.updateAvailabilityBatchLevel(item.itemId);
    }
    item.oldRequiredQty = item.requiredQty;
    if (item.isFOCItem) item.oldRequiredFOCQty = item.requiredQty;
    if (item.isReturnItem) item.oldRequiredReturnQty = item.requiredReturnQty;
    // Calculate ROI Discount
    if (
      this.isROIScreen &&
      this.showROIDiscountOnTransLevel &&
      this.selectedROIDiscountPercentage > 0
    ) {
      this.onHeaderDiscountValueChanged();
    }
  }
  prepareFocItem(item: ItemPackModel) {
    let itemFOCQty = item;
    item = new ItemPackModel();
    item = cloneDeep(itemFOCQty);
    item.price = 0;
    item.discount = 0;
    item.basePrice = itemFOCQty.basePrice;
    item.tax = this.allowTax && this.allowTaxOverFreeItems ? itemFOCQty.tax : 0;
    item.baseTax =
      this.allowTax && this.allowTaxOverFreeItems ? itemFOCQty.baseTax : 0;
    item.requiredQty = itemFOCQty.requiredFOCQty;
    item.requiredFOCQty = itemFOCQty.requiredFOCQty;

    let index = this.addedItemsList.findIndex(
      (a) =>
        a.itemId === item.itemId &&
        a.packId === item.packId &&
        a.salesTransactionTypeId === SalesTransactionTypes.FOC.valueOf()
    );
    if (index >= 0) {
      item.oldRequiredQty = this.addedItemsList[index].oldRequiredQty;
      item.grossTotal = this.addedItemsList[index].grossTotal;
      item.calculatedTax = this.addedItemsList[index].calculatedTax;
    } else {
      item.oldRequiredQty = 0;
    }
    item.isFOCItem = true;
    return item;
  }
  prepareReturnItem(item: ItemPackModel) {
    let itemReturnQty = item;
    item = new ItemPackModel();
    item = cloneDeep(itemReturnQty);
    item.price = itemReturnQty.returnPrice;
    item.discount = itemReturnQty.discount;
    item.basePrice = itemReturnQty.basePrice;
    item.tax = itemReturnQty.tax;
    item.baseTax = itemReturnQty.baseTax;
    item.requiredQty = itemReturnQty.requiredReturnQty;
    item.requiredReturnQty = itemReturnQty.requiredReturnQty;

    let index = this.addedItemsList.findIndex(
      (a) =>
        a.itemId === item.itemId &&
        a.packId === item.packId &&
        a.salesTransactionTypeId === SalesTransactionTypes.None.valueOf()
    );
    if (index >= 0) {
      item.oldRequiredQty = this.addedItemsList[index].oldRequiredQty;
      item.grossTotal = this.addedItemsList[index].grossTotal;
      item.calculatedTax = this.addedItemsList[index].calculatedTax;
      item.netTotal = this.addedItemsList[index].netTotal;
    } else {
      item.oldRequiredQty = 0;
    }
    item.isReturnItem = true;
    return item;
  }
  onDeleteItemClicked(deletedItem: ItemPackModel) {
    var indexInAddedItems = -1;

    if (this.isFOCMode || this.isExchangeMode) {
      indexInAddedItems = this.addedItemsList.findIndex(
        (a) =>
          a.itemId === deletedItem.itemId &&
          a.packId === deletedItem.packId &&
          a.salesTransactionTypeId === deletedItem.salesTransactionTypeId
      );
    } else {
      indexInAddedItems = this.addedItemsList.findIndex(
        (a) =>
          a.itemId === deletedItem.itemId && a.packId === deletedItem.packId
      );
    }

    if (indexInAddedItems >= 0) {
      var orginalPack = this.originalItemsList.find(
        (a) =>
          a.itemId === deletedItem.itemId && a.packId === deletedItem.packId
      );
      if (orginalPack) {
        if (this.enableAddSerial && orginalPack.hasSerialNumber) {
          if (this.isFOCMode) {
            this.addedSerialsForAllPacks = this.addedSerialsForAllPacks.filter(
              (serial) =>
                serial.itemId !== orginalPack.itemId ||
                serial.packId !== orginalPack.packId ||
                serial.salesTransactionTypeId !==
                orginalPack.salesTransactionTypeId
            );
            this.removeAddedSerialsFromAllAddedPackObjects(deletedItem);
          } else {
            this.addedSerialsForAllPacks = this.addedSerialsForAllPacks.filter(
              (serial) =>
                serial.itemId !== orginalPack.itemId ||
                serial.packId !== orginalPack.packId
            );
            this.removeAddedSerialsFromAllAddedPackObjects(deletedItem);
          }
        }
        // remove this item from added items
        this.addedItemsList.splice(indexInAddedItems, 1);

        if (
          (this.orderTypeId === OrderTypes.Return.valueOf() &&
            this.copyOfOrderAddedItems.length > 0) ||
          this.isExchangeMode
        ) {
          var i = this.copyOfOrderAddedItems.findIndex(
            (c) =>
              c.itemId === deletedItem.itemId && c.packId === deletedItem.packId
          );
          if (i > -1) {
            this.copyOfOrderAddedItems.splice(i, 1);
          }
        }
        // remove this item total from whole transaction total
        if (
          this.transactionMainType ===
          TransactionMainTypes.CustomerTransaction ||
          this.isROIScreen ||
          this.transactionMainType === TransactionMainTypes.WarehouseTransaction
        ) {
          if (this.isFOCMode || this.isExchangeMode) {
            this.calculationService.updateTotals(
              this.totals,
              deletedItem,
              false
            );
          } else {
            this.calculationService.updateTotals(
              this.totals,
              orginalPack,
              false
            );
          }
        }

        let serialPackIndex = this.itemsList.findIndex(
          (x) => x.packId === deletedItem.packId
        );

        if (
          deletedItem.salesTransactionTypeId ==
          SalesTransactionTypes.FOC.valueOf()
        ) {
          if (
            serialPackIndex > -1 &&
            this.itemsList[serialPackIndex].addedFOCSerials &&
            this.itemsList[serialPackIndex].addedFOCSerials.length > 0
          ) {
            this.itemsList[serialPackIndex].addedFOCSerials = [];
          }
        } else if (
          serialPackIndex > -1 &&
          this.itemsList[serialPackIndex].addedSerials &&
          this.itemsList[serialPackIndex].addedSerials.length > 0
        ) {
          this.itemsList[serialPackIndex].addedSerials = [];
        }
        // update items count for the transaction
        this.fillTotalRequiredQty();
        if (
          (deletedItem.salesTransactionTypeId !==
            SalesTransactionTypes.FOC.valueOf() ||
            deletedItem.salesTransactionTypeId !==
            SalesTransactionTypes.None.valueOf()) && !this.isExchangeMode && !this.isReturnItem && !this.isFOCMode
        ) {
          // update orginal item properties
          orginalPack.isCalculated = false;
          orginalPack.requiredQty = 0;
          orginalPack.oldRequiredQty = 0;
          orginalPack.grossTotal = 0;
          orginalPack.netTotal = 0;
          orginalPack.calculatedTax = 0;
          orginalPack.calculatedDiscount = 0;
          orginalPack.calculatedPromotedDiscount = 0;
          orginalPack.calculatedDiscountTotal = 0;
          orginalPack.allItemDiscount = 0;
          // cleare selected batches
          orginalPack.selectedBatches = [];
          orginalPack.itemStockBatches.forEach((batch) => {
            batch.requiredQty = 0;
          });
        }

        //Update Availability for stock
        if (this.checkForAvailableItemLevel) {
          // item level :
          this.updateAvailabilityItemLevel(orginalPack.itemId);
        } else if (this.checkForAvailableBatchLevel) {
          // batch level :
          this.updateAvailabilityBatchLevel(orginalPack.itemId);
        }
        if (this.enableAddItemByLiters && orginalPack.requiredByLiter) {
          orginalPack.startReading = 0;
          orginalPack.endReading = 0;
        }
        this.qtyChanged(deletedItem);
        if (
          deletedItem.salesTransactionTypeId ==
          SalesTransactionTypes.Sales.valueOf()
        ) {
          orginalPack.requiredQty = null;
          // FOC
        } else if (deletedItem.salesTransactionTypeId ==
          SalesTransactionTypes.FOC.valueOf()) {
          orginalPack.requiredFOCQty = null;
          orginalPack.oldRequiredFOCQty = null;
          //Exchanges
        } else if (deletedItem.salesTransactionTypeId ==
          SalesTransactionTypes.None.valueOf()) {
          orginalPack.requiredReturnQty = null;
          orginalPack.oldRequiredReturnQty = null;
        }
        // Returns
        if (
          deletedItem.salesTransactionTypeId ==
          SalesTransactionTypes.None.valueOf() && this.isReturnItem
        ) {
          orginalPack.requiredQty = null;
          orginalPack.requiredReturnQty = null;
          orginalPack.oldRequiredReturnQty = null;
        }

        this.resetDiscount(deletedItem);
        // Calculate ROI Discount
        if (
          this.isROIScreen &&
          this.showROIDiscountOnTransLevel &&
          this.selectedROIDiscountPercentage > 0
        ) {
          this.onHeaderDiscountValueChanged();
        }
      } else if (
        (this.orderTypeId === OrderTypes.Return.valueOf() &&
          this.restrictReturnFromInvoices) ||
        this.isExchangeMode
      ) {
        // In this case, the added item is no longer available in customerBatchesList.
        this.addedItemsList.splice(indexInAddedItems, 1);
        this.fillTotalRequiredQty();
      }
    }
  }
  onDivisionChanged(division: ItemDivisionModel) {
    this.category.setValue(null);
    if (division && division.divisionId > 0) {
      this.categoryList = [];
      this.categoryList = this.originalCategoryList.filter(
        (i) => i.divisionId === division.divisionId
      );
    } else {
      this.categoryList = this.originalCategoryList;
    }
  }
  onItemsFilterFindClicked() {
    if (this.division.value) {
      this.selectedDivisionId = +this.division.value;
    } else {
      this.selectedDivisionId = -1;
    }
    if (this.category.value) {
      this.selectedCategoryId = +this.category.value;
    } else {
      this.selectedCategoryId = -1;
    }
    /* if (this.itemSearch.value) {
      this.searchItemFilterString = this.itemSearch.value;
    }
    else {
      this.searchItemFilterString = '';
    } */
    this.findItems(0, this.pageSize, 0);
  }
  onItemsFilterClearClicked() {
    this.division.setValue(null);
    this.category.setValue(null);
    this.searchItemFilterString = "";
    /* this.itemSearch.setValue(''); */
    this.onItemsFilterFindClicked();
  }
  onPageChanged(pageEvent: PageEvent) {
    let startIndex = pageEvent.pageIndex * this.pageSize;
    let endIndex = (pageEvent.pageIndex + 1) * this.pageSize;
    this.itemsList = this.originalItemsList.slice(startIndex, endIndex);
    this.pageIndex = pageEvent.pageIndex;
    this.findItems(startIndex, endIndex, this.pageIndex);
  }
  openFilter() {
    this.isFilterOpend = !this.isFilterOpend;
    if (this.isDivisionLevel()) {
      this.selectedDivisionId = +this.divisionId;
      if (
        this.selectedDivisionId &&
        this.selectedDivisionId > 0 &&
        this.originalCategoryList
      ) {
        this.categoryList = [];
        this.categoryList = this.originalCategoryList.filter(
          (i) => i.divisionId === this.selectedDivisionId
        );
      } else {
        this.categoryList = this.originalCategoryList;
      }
    }
  }
  onOpenSerials() {
    this.showAddItemsBySerial = true;

    this.fillSerialProperties();
  }
  onOpenImportSerial() {
    this.showImportSerialsSlider = true;
  }
  onCloseSerials() {
    this.showAddItemsBySerial = false;
  }
  onCloseFilter() {
    this.isFilterOpend = false;
  }
  openSecondaryPackSelection(selectedPack: ItemPackModel) {
    this.selectedItemPackObject = cloneDeep(selectedPack);

    this.populateSecondaryPacks(selectedPack, true);
  }
  openSliderToAddSerials(selectedPack: ItemPackModel) {
    //   this.addedSerialsForAllPacks=this.addedSerialsForAllPacks.filter(serial=> serial.itemId !== selectedPack.itemId && serial.packId!==selectedPack.packId)

    // if(!this.addedSerialsForAllPacks)this.addedSerialsForAllPacks=[];
    if (selectedPack.packHierarchyFilled) {
      this.selectedItemPackObject = cloneDeep(selectedPack);
      this.fillPackTemplateSerialIndex(selectedPack);
    } else {
      if (
        selectedPack.packTrimSerialStartDigits !== -1 ||
        selectedPack.packTrimSerialEndDigits !== -1
      ) {
        selectedPack.packHasTrimSerialRule = true;
        if (selectedPack.packTrimSerialStartDigits === -1)
          selectedPack.packTrimSerialStartDigits = 0;
        if (selectedPack.packTrimSerialEndDigits === -1)
          selectedPack.packTrimSerialEndDigits = 0;
      }

      if (selectedPack.hasSerialNumber && selectedPack.piecesInPack > 1) {
        this.coreSession.ModalLoading.Show();
        this.itemService
          .getPacksHierarchy(selectedPack, this.customerId, this.outletId)
          .subscribe(
            (response) => {
              this.coreSession.ModalLoading.Hide();
              if (response.status != null && response.status >= 0) {
                selectedPack.childPack = response.data.childPack;
                selectedPack.childPackPrice = response.data.childPackPrice;
                selectedPack.packQuantityInLevels =
                  response.data.packQuantityInLevels;
                selectedPack.packHierarchyFilled = true;

                this.selectedItemPackObject = cloneDeep(selectedPack);
                this.fillPackTemplateSerialIndex(selectedPack);
                // this.fillSerialProperties();
                //      this.showSerialsSlider=true;
              } else {
                this.coreSession.showError(
                  this.translateService.instant(ConstantMessages.ErrorCaption),
                  response.message
                );
              }
            },
            (error: HttpErrorResponse) => {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.ErrorCaption),
                this.translateService.instant(ConstantMessages.ErrorHappened)
              );
            }
          );
      } else {
        selectedPack.packQuantityInLevels = 1;
        this.selectedItemPackObject = cloneDeep(selectedPack);
        this.fillPackTemplateSerialIndex(selectedPack);
        // this.fillSerialProperties();
        //   this.showSerialsSlider=true;
      }
    }
  }

  checkIfThereIsSerialItemsAvailable() {
    this.coreSession.ModalLoading.Show();
    this.itemService.checkIfItemSerialsAvailable().subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data.isExist) {
            if (!response.data.isAnyPackHasTrimRule)
              //Until apply large count of serials changes
              this.showAddSerialsBtn = false;
            //  this.showAddSerialsBtn = true;
            else this.showAddSerialsBtn = false;
            if (
              (this.isPartialDelivery() || this.isDelivery()) &&
              !this.isFromSSCC
            )
              this.showImportSerialsBtn = true;
          } else {
            this.showAddSerialsBtn = false;
            this.showImportSerialsBtn = false;
          }
        } else {
          this.showAddSerialsBtn = false;
          this.showImportSerialsBtn = false;
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.showAddSerialsBtn = false;
        this.showImportSerialsBtn = false;
      }
    );
  }

  fillPackTemplateSerialIndex(selectedPack: ItemPackModel) {
    if (selectedPack.templateId && selectedPack.templateId > 0) {
      this.coreSession.ModalLoading.Show();
      this.itemService
        .getFieldsStartAndEndIndexTemplate(selectedPack)
        .subscribe(
          (response) => {
            this.coreSession.ModalLoading.Hide();
            if (response.status != null && response.status >= 0) {
              this.selectedItemPackObject.productionIncludedInTemplate =
                response.data.productionIncludedInTemplate;
              this.selectedItemPackObject.totalFieldsLengthOfItemTemplate =
                response.data.totalFieldsLengthOfItemTemplate;
              this.selectedItemPackObject.templateSerialsIndecies =
                response.data.templateSerialsIndecies;
              this.selectedItemPackObject.templateBatchNoIndecies =
                response.data.templateBatchNoIndecies;
              this.selectedItemPackObject.templateExpiryDateIndecies =
                response.data.templateExpiryDateIndecies;
              this.selectedItemPackObject.templateGTINIndecies =
                response.data.templateGTINIndecies;
              this.selectedItemPackObject.templateGLNIndecies =
                response.data.templateGLNIndecies;
              this.selectedItemPackObject.templateProductionDateIndecies =
                response.data.templateProductionDateIndecies;
              this.checkIfBatchAndExpiryInItemTemplate();
              this.fillSerialProperties();
              this.showSerialsSlider = true;
            } else {
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.ErrorCaption),
                response.message
              );
            }
          },
          (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              this.translateService.instant(ConstantMessages.ErrorHappened)
            );
          }
        );
    } else {
      this.fillSerialProperties();
      this.showSerialsSlider = true;
    }
  }
  checkIfBatchAndExpiryInItemTemplate() {
    if (
      this.selectedItemPackObject.templateId > 0 &&
      this.selectedItemPackObject.templateBatchNoIndecies &&
      this.selectedItemPackObject.templateBatchNoIndecies.length === 2
    ) {
      this.selectedItemPackObject.batchIncludedInTemplate = true;
    }
    if (
      this.selectedItemPackObject.templateId > 0 &&
      this.selectedItemPackObject.templateExpiryDateIndecies &&
      this.selectedItemPackObject.templateExpiryDateIndecies.length === 2
    ) {
      this.selectedItemPackObject.expiryIncludedInTemplate = true;
    }
  }
  fillSerialProperties() {
    this.warehouseId = this.detailsWarehouseId;
    this.vehicleId = -1;
    this.requiredQuantity = -1;
    this.quantityMustMatch = false;
    let transactionId = this.editedTransactionId;
    if (
      this.formProcessMode === FormProcessMode.LoadRequest ||
      this.formProcessMode === FormProcessMode.OffLoad ||
      this.formProcessMode === FormProcessMode.BackToStoreOffload ||
      this.formProcessMode === FormProcessMode.ResendHelloJordan
    ) {
      this.warehouseId = this.stockWarehouseId;
      if (this.formProcessMode !== FormProcessMode.ResendHelloJordan) {
        this.vehicleId = this.detailsWarehouseId;
      } else {
        this.vehicleId = this.refWarehouseId;
      }
      if (this.executeOperation && !this.isFromSSCC) {
        this.requiredQuantity = this.selectedItemPackObject.requiredQty;
        this.quantityMustMatch = true;
      }
    }

    if (
      this.isDelivery() ||
      (this.formProcessMode === FormProcessMode.TransferIn &&
        !this.AllowEditItemInTransfer)
    ) {
      this.requiredQuantity = this.selectedItemPackObject.requiredQty;
      if (!this.isFromSSCC) this.quantityMustMatch = true;
    }
    if (
      this.isPartialDelivery() &&
      this.order.orderStatus != OrderStatus.Delivered
    ) {
      if (
        this.order.warehouseTransactionId &&
        this.order.warehouseTransactionId.length > 0
      ) {
        this.warehouseId = this.order.referenceWarehouseId;
        this.vehicleId = this.order.warehouseId;
        transactionId = this.order.warehouseTransactionId;
      }
    }
    if (
      this.formProcessMode === FormProcessMode.InputVoucher &&
      this.isFromPurchaseOrder
    ) {
      this.requiredQuantity = this.selectedItemPackObject.requiredQty;
    }
    if (
      this.isFromSSCC &&
      this.formProcessMode != FormProcessMode.OutputVoucher
    ) {
      this.requiredQuantity = this.selectedItemPackObject.originalQuantity;
    }
    let packObjects = [];
    if (this.addedSerialsForAllPacks)
      packObjects.push(this.selectedItemPackObject);
    this.serialsProperties = {
      itemFormProcessMode: this.formProcessMode,
      packObj: this.selectedItemPackObject,
      packObjects: this.allAddedPackObjects,
      warehouseId: this.warehouseId,
      vehicleId: this.vehicleId,
      transactionId: transactionId,
      newSerialStatus: this.getSerialNewStatus(),
      requiredQuantity: this.requiredQuantity,
      quantityMustMatch: this.quantityMustMatch,
      customerId: this.customerId,
      outletId: this.outletId,
      transactionReadingId: this.readingId,
      ssccBarcode: this.ssccBarcode,
    };
  }
  getSerialNewStatus() {
    let newStatus: SerialStatus = SerialStatus.None;
    switch (this.formProcessMode) {
      case FormProcessMode.InputVoucher:
        newStatus = SerialStatus.LoadedToMainWarehouse;
        break;
      case FormProcessMode.OutputVoucher:
      case FormProcessMode.DamagedStock:
      case FormProcessMode.ROIIncommingReturns:
      case FormProcessMode.ROIIncomming:
      case FormProcessMode.ManufacturingDefects:
        newStatus = SerialStatus.OffloadedFromMainWarehouse;
        break;
      case FormProcessMode.LoadRequest:
        newStatus = SerialStatus.LoadedInVehicle;
        break;
      case FormProcessMode.OffLoad:
        newStatus = SerialStatus.OffloadedToWarehouse;
        break;
      case FormProcessMode.Delivery:
      case FormProcessMode.PartialDelivery:
        newStatus = SerialStatus.SoldToCustomer;
        break;
    }
    return newStatus;
  }

  prepareReturnOrderBatches(selectedPack: ItemPackModel) {
    selectedPack.isExceedAvailableQty = false;
    // Pack batches from invoice do not exist
    if (
      this.allItemsStockList.filter((x) => x.itemId === selectedPack.itemId)
        .length <= 0
    ) {
      let filter = {
        itemId: selectedPack.itemId,
        customerId: this.customerId,
        outletId: this.outletId,
      };
      // API
      this.coreSession.ModalLoading.Show();
      this.itemService.getReturnOrderItemsBatchesList(filter).subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (
            response.status != null &&
            response.status >= 0 &&
            response.data
          ) {
            var copyOfAvl = cloneDeep(response.data);
            this.allItemsStockList.push(response.data);

            this.getAddedQtyFromOtherPacks(selectedPack, copyOfAvl);

            if (selectedPack.requiredQty > 0) {
              copyOfAvl.returnFromInvoiceAvailableBatchList.forEach(
                (avlInv) => {
                  // check if each inv-batch is added before, and reflect its quantity.
                  var i =
                    selectedPack.returnFromInvoiceAvailableBatchList.findIndex(
                      (addedInv) =>
                        addedInv.transactionId === avlInv.transactionId &&
                        addedInv.batchNo === avlInv.batchNo
                    );
                  if (i > -1) {
                    avlInv.requiredQuantity =
                      selectedPack.returnFromInvoiceAvailableBatchList[
                        i
                      ].requiredQuantity;
                    avlInv.invalid =
                      selectedPack.returnFromInvoiceAvailableBatchList[
                        i
                      ].invalid;
                  }
                }
              );
            }
            if (
              this.formProcessMode === FormProcessMode.ProcessReturn &&
              !this.allowPartialDeliveryInProcessReturn
            ) {
              var lst = copyOfAvl.returnFromInvoiceAvailableBatchList.filter(
                (i) => i.requiredQuantity > 0
              );
              selectedPack.returnFromInvoiceAvailableBatchList = cloneDeep(lst);
            } else {
              selectedPack.returnFromInvoiceAvailableBatchList = cloneDeep(
                copyOfAvl.returnFromInvoiceAvailableBatchList
              );
            }
            this.selectedItemPackObject = cloneDeep(selectedPack);
            this.showBatchesSlider = true;
          } else {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              this.translateService.instant(response.message)
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
    }
    // Pack batches from invoice already exist
    else {
      var idx = this.allItemsStockList.findIndex(
        (x) => x.itemId == selectedPack.itemId
      );
      var copyOfAvl = cloneDeep(this.allItemsStockList[idx]);
      // new pack.
      if (!selectedPack.requiredQty || selectedPack.requiredQty === 0) {
        selectedPack.returnFromInvoiceAvailableBatchList = cloneDeep(
          copyOfAvl.returnFromInvoiceAvailableBatchList
        );
      }
      if (
        selectedPack.returnFromInvoiceAvailableBatchList &&
        selectedPack.returnFromInvoiceAvailableBatchList.length > 0
      ) {
        this.getAddedQtyFromOtherPacks(selectedPack, copyOfAvl);
        if (selectedPack.requiredQty > 0) {
          copyOfAvl.returnFromInvoiceAvailableBatchList.forEach((avlInv) => {
            // check if each inv-batch is added before, and reflect its quantity.
            var i = selectedPack.returnFromInvoiceAvailableBatchList.findIndex(
              (addedInv) =>
                addedInv.transactionId === avlInv.transactionId &&
                addedInv.batchNo === avlInv.batchNo &&
                addedInv.expiryDateModel.day === avlInv.expiryDateModel.day &&
                addedInv.expiryDateModel.month ===
                avlInv.expiryDateModel.month &&
                addedInv.expiryDateModel.year === avlInv.expiryDateModel.year &&
                addedInv.expiryDateModel.date === avlInv.expiryDateModel.date &&
                addedInv.salesTransactionTypeId ===
                avlInv.salesTransactionTypeId
            );
            if (i > -1) {
              avlInv.requiredQuantity =
                selectedPack.returnFromInvoiceAvailableBatchList[
                  i
                ].requiredQuantity;
              avlInv.invalid =
                selectedPack.returnFromInvoiceAvailableBatchList[i].invalid;
            }
          });
        }
        if (
          this.formProcessMode === FormProcessMode.ProcessReturn &&
          !this.allowPartialDeliveryInProcessReturn
        ) {
          var lst = copyOfAvl.returnFromInvoiceAvailableBatchList.filter(
            (i) => i.requiredQuantity > 0
          );
          selectedPack.returnFromInvoiceAvailableBatchList = cloneDeep(lst);
        } else {
          selectedPack.returnFromInvoiceAvailableBatchList = cloneDeep(
            copyOfAvl.returnFromInvoiceAvailableBatchList
          );
        }
        this.selectedItemPackObject = cloneDeep(selectedPack);
        this.showBatchesSlider = true;
      }
      // else {
      //   selectedPack.returnFromInvoiceAvailableBatchList = cloneDeep(copyOfAvl.returnFromInvoiceAvailableBatchList);
      // }
    }
  }

  getAddedQtyFromOtherPacks(selectedPackObj, originalStockList) {
    // get other packs for same item with added batches.
    var otherItemPacks = this.addedItemsList.filter(
      (x) =>
        x.itemId === selectedPackObj.itemId &&
        x.packId !== selectedPackObj.packId
    );
    // update avlQtyFromInvoice = avlQtyFromInvoice - sum(addedInOtherPacks);
    if (otherItemPacks && otherItemPacks.length > 0) {
      otherItemPacks.forEach((pack) => {
        var returnsFromInvoices =
          pack.returnFromInvoiceAvailableBatchList.filter(
            (r) => r.requiredQuantity > 0
          );
        returnsFromInvoices.forEach((returnObj) => {
          var i =
            originalStockList.returnFromInvoiceAvailableBatchList.findIndex(
              (x) =>
                x.transactionId === returnObj.transactionId &&
                x.batchNo === returnObj.batchNo &&
                x.salesTransactionTypeId === returnObj.salesTransactionTypeId
            );
          originalStockList.returnFromInvoiceAvailableBatchList[
            i
          ].availableQtyFromInvoice -=
            returnObj.requiredQuantity * pack.piecesInPack;
          if (
            originalStockList.returnFromInvoiceAvailableBatchList[i]
              .availableQtyFromInvoice < 0
          )
            originalStockList.returnFromInvoiceAvailableBatchList[
              i
            ].availableQtyFromInvoice = 0;
        });
      });
    }
  }
  openSliderToAddBatches(selectedPack: ItemPackModel) {
    if (this.enableReturnBatchFromInvoice) {
      this.prepareReturnOrderBatches(selectedPack);
    }
    // if batches for selected item is not cashed before then cash them from DB, if enable batch selection is true
    else if (
      this.allItemsStockList.filter((x) => x.itemId === selectedPack.itemId)
        .length <= 0 &&
      this.enableBatchSelection
    ) {
      // call API to get available batches from DB for selected item
      let filter = {
        itemId: selectedPack.itemId,
        stockWarehouseId: this.stockWarehouseId,
        detailsWarehouseId: this.detailsWarehouseId,
        refWarehouseId: this.refWarehouseId,
        editedTransactionId: this.editedTransactionId,
        calculatePendingQty: this.calculatePendingQty,
        excludeZeroAndMinusStock: this.blockIfExceedAvailable,
        processMode: this.formProcessMode,
      };

      this.coreSession.ModalLoading.Show();
      this.warehouseStockService.getItemAvailableStock(filter).subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            // Add Available batches to stock list
            if (
              response.data.itemStockList &&
              response.data.itemStockList.length > 0
            ) {
              // 1. add item stock batches list to all items stock list
              this.allItemsStockList = this.allItemsStockList.concat(
                response.data.itemStockList
              );
              // 2. add item stock batches list to every pack in this item
              var allPacksForSameItem = this.originalItemsList.filter(
                (x) => x.itemId === selectedPack.itemId
              );
              allPacksForSameItem.forEach((pack) => {
                pack.itemStockBatches = cloneDeep(response.data.itemStockList);
                pack.itemStockBatches.forEach((batch) => {
                  batch.packId = pack.packId;
                });
              });
            }

            if (
              selectedPack.itemStockBatches.length > 0 ||
              this.enableBatchInsertion
            ) {
              this.selectedItemPackObject = cloneDeep(selectedPack);
              this.showBatchesSlider = true;
            } else {
              this.coreSession.showWarrning(
                this.translateService.instant(ConstantMessages.WarningCaption),
                this.translateService.instant(
                  ConstantMessages.MsgNoAvailableBatches
                )
              );
            }
          } else {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
    } else {
      // batches are built prevoiusly for the selected item, or add new batch is allowed
      if (
        selectedPack.itemStockBatches.length > 0 ||
        this.enableBatchInsertion
      ) {
        this.selectedItemPackObject = cloneDeep(selectedPack);
        this.showBatchesSlider = true;
      } else {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(ConstantMessages.MsgNoAvailableBatches)
        );
      }
    }
  }
  closeSecondaryPackSlider() {
    this.showSelectSecondaryPackSlider = false;
  }
  closeSerialsSlider() {
    this.showSerialsSlider = false;
  }
  closeImportSerialsSlider() {
    this.showImportSerialsSlider = false;
  }
  onSaveSerialsClick() {
    this.saveSerialsSubject.next();
  }
  onSaveSecondaryPackClick(event) {
    if (event && event.saveCLicked) {
      this.saveSecondaryPackSubject.next();
    }
  }
  onImportSerialClicked() { }
  onSaveSerialsBySerialNoClick() {
    this.saveSerialsSubjectBySerialNo.next();
  }
  onSaveItemsAddedBySerialClick() {
    this.saveAddedItemsBySerialSubject.next();
  }
  onImportSerialsCompleted(data: any) {
    this.showImportSerialsSlider = false;
    if (data.isValid) {
      if (data.invalidSerials && data.invalidSerials.length > 0) {
        this.invalidSerials = data.invalidSerials;
        if (this.invalidSerials && this.invalidSerials.length > 0) {
          this.invalidSerialsDialog.showDialog().then((result) => { });
        }
      } else {
      }
      this.onAfterAddingItemsBySerial(data.addedSerials);
    }
  }
  onShowInvalid(data: any) {
    this.invalidSerials = data;
    if (data && data.length > 0) {
      this.invalidSerialsDialog.showDialog().then((result) => { });
    }
  }
  onAfterAddingItemsBySerial(addedSerials: any[]) {
    var showWarning = false;
    var repeatedItems = [];
    let packObject: any = {};
    let addedPackIds: number[] = [];
    let index: number = -1;
    this.allAddedPackObjects = [];
    addedSerials.forEach((serial) => {
      packObject = cloneDeep(
        this.originalItemsList.find((x) => x.packId === serial.packId)
      );
      if (packObject) {
        // Check if packObject already has added serials.
        if (packObject.addedSerials && packObject.addedSerials.length > 0) {
          showWarning = true;
          if (
            repeatedItems.findIndex(
              (i) =>
                i.itemId === packObject.itemId && i.packId === packObject.packId
            ) === -1
          ) {
            var obj = cloneDeep(packObject);
            repeatedItems.push(obj);
          }
        }
        if (
          this.transactionMainType ==
          TransactionMainTypes.CustomerTransaction &&
          serial.salesTransactionTypeId != SalesTransactionTypes.FOC &&
          serial.salesTransactionTypeId != SalesTransactionTypes.Promotion
        )
          serial.price = packObject.price;
        if (addedPackIds.findIndex((id) => id === packObject.packId) < 0) {
          addedPackIds.push(packObject.packId);

          if (!packObject.addedSerials) {
            packObject.addedSerials = [];
          }
          if (!packObject.addedFOCSerials) {
            packObject.addedFOCSerials = [];
          }

          if (
            serial.salesTransactionTypeId == SalesTransactionTypes.FOC.valueOf()
          ) {
            packObject.addedFOCSerials.push(serial);
          } else {
            packObject.addedSerials.push(serial);
          }

          if (
            !this.executeOperation &&
            !this.isDelivery() &&
            !this.isPartialDelivery()
          ) {
            if (
              serial.salesTransactionTypeId ==
              SalesTransactionTypes.FOC.valueOf()
            ) {
              packObject.requiredFOCQty += serial.serialsCount;
            } else {
              packObject.requiredQty += serial.serialsCount;
            }
            packObject.oldRequiredQty = 0;
          }

          this.allAddedPackObjects.push(packObject);
        } else {
          index = this.allAddedPackObjects.findIndex(
            (x) => x.packId === packObject.packId
          );
          if (!packObject.addedSerials) {
            packObject.addedSerials = [];
          }
          if (!packObject.addedFOCSerials) {
            packObject.addedFOCSerials = [];
          }

          if (
            serial.salesTransactionTypeId == SalesTransactionTypes.FOC.valueOf()
          ) {
            this.allAddedPackObjects[index].addedFOCSerials.push(serial);
          } else {
            this.allAddedPackObjects[index].addedSerials.push(serial);
          }
          if (!this.executeOperation && !this.isDelivery()) {
            if (!this.allAddedPackObjects[index].requiredQty) {
              this.allAddedPackObjects[index].requiredQty = 0;
            }
            if (!this.allAddedPackObjects[index].requiredFOCQty) {
              this.allAddedPackObjects[index].requiredFOCQty = 0;
            }

            if (serial.salesTransactionTypeId == SalesTransactionTypes.FOC.valueOf()) {
              this.allAddedPackObjects[index].requiredFOCQty += serial.serialsCount;
            } else {
              this.allAddedPackObjects[index].requiredQty += serial.serialsCount;
            }
          }
        }
      }
    });
    if (showWarning && repeatedItems && repeatedItems.length > 0) {
      this.showWarningAfterImport(repeatedItems);
    } else {
      this.continueAfterAddingItemsBySerial();
    }
  }
  showWarningAfterImport(repeatedItems) {
    this.coreSession.ModalDialog.ShowMessage(
      this.translateService.instant(
        ConstantMessages.MsgPreviousSerialsWillBeOverwritten
      ),
      DialogMode.YesNo,
      this.translateService.instant(ConstantMessages.WarningCaption)
    ).then((res: DialogResult) => {
      if (res === DialogResult.Yes) {
        this.continueAfterAddingItemsBySerial();
      } else {
        repeatedItems.forEach((pack) => {
          var idx = this.allAddedPackObjects.findIndex(
            (addedPack) =>
              addedPack.packId == pack.packId && addedPack.itemId == pack.itemId
          );
          if (idx > -1) {
            this.allAddedPackObjects[idx] = pack;
            if (!this.executeOperation && !this.isDelivery()) {
              this.allAddedPackObjects[idx].requiredQty =
                pack.addedSerials.length;
              this.allAddedPackObjects[idx].oldRequiredQty = 0;
            }
          }
        });
        this.continueAfterAddingItemsBySerial();
      }
    });
  }
  continueAfterAddingItemsBySerial() {
    this.serialsProperties = {
      itemFormProcessMode: this.formProcessMode,
      packObj: this.allAddedPackObjects[0],
      packObjects: this.allAddedPackObjects,
      warehouseId: this.warehouseId,
      vehicleId: this.vehicleId,
      transactionId: this.editedTransactionId,
      newSerialStatus: this.getSerialNewStatus(),
      requiredQuantity: this.requiredQuantity,
      quantityMustMatch: this.quantityMustMatch,
    };
    this.showAddItemsBySerial = false;

    for (let i = 0; i < this.allAddedPackObjects.length; i++) {
      if (
        this.allAddedPackObjects[i].addedSerials &&
        this.allAddedPackObjects[i].addedSerials.length > 0
      ) {
        this.allAddedPackObjects[i].isFOCItem = false;
        this.onAfterSaveSerials(this.allAddedPackObjects[i], true);
      }

      if (
        this.allAddedPackObjects[i].addedFOCSerials &&
        this.allAddedPackObjects[i].addedFOCSerials.length > 0
      ) {
        this.allAddedPackObjects[i].isFOCItem = true;
        this.onAfterSaveSerials(this.allAddedPackObjects[i], true);
      }
    }
  }
  populateSecondaryPacks(selectedPack, showDialog) {
    //this.isSecondaryPacksLoading = true;
    let originalPack = selectedPack;
    // let selectedSecondaryPack={};
    if (selectedPack.secondaryPacks && selectedPack.secondaryPacks.length > 0) {
      this.secondaryPacksList = selectedPack.secondaryPacks;
      if (
        !selectedPack.secondaryPack ||
        selectedPack.secondaryPack.secondaryPackId <= 0
      ) {
        this.selectedSecondaryPack = this.secondaryPacksList[0];
        //  this.selectedItemPackObject.secondaryPack=this.secondaryPacksList[0];
      } else {
        this.selectedSecondaryPack = selectedPack.secondaryPack;
      }
      this.calculateSecondaryPackValues(
        this.selectedSecondaryPack,
        selectedPack
      );
      originalPack = this.onAfterSaveSecondaryPack(this.selectedSecondaryPack);
      if (showDialog) {
        this.secondarySelectionDialog.Show(true).then((res) => { });
      }
    } else {
      this.coreSession.ModalLoading.Show();
      this.itemService
        .getSecondaryPacks(selectedPack.itemId)
        .subscribe((result) => {
          this.secondaryPacksList = result.data;
          if (this.secondaryPacksList && this.secondaryPacksList.length > 0) {
            if (
              !selectedPack.secondaryPack ||
              selectedPack.secondaryPack.secondaryPackId <= 0
            ) {
              this.selectedSecondaryPack = this.secondaryPacksList[0];
              this.selectedItemPackObject.secondaryPack =
                this.secondaryPacksList[0];
            } else {
              this.selectedSecondaryPack = selectedPack.secondaryPack;
            }

            this.calculateSecondaryPackValues(
              this.selectedSecondaryPack,
              selectedPack
            );
            originalPack = this.onAfterSaveSecondaryPack(
              this.selectedSecondaryPack
            );
          }
          //this.isSecondaryPacksLoading = false;
          this.coreSession.ModalLoading.Hide();
          if (showDialog) {
            this.secondarySelectionDialog.Show(true).then((res) => { });
          }
        });
    }
    return originalPack;
  }
  calculateSecondaryPackValues(selectedSecondaryPack, selectedPack) {
    selectedSecondaryPack.itemId = selectedPack.itemId;
    selectedSecondaryPack.packId = selectedPack.packId;
    selectedSecondaryPack.requiredQuantity = selectedPack.requiredQty;
    if (
      !this.orderSignature ||
      this.orderSignature === "" ||
      !this.useWeightPriceInOrder
    )
      selectedSecondaryPack.originalPrice = selectedPack.basePrice;
    if (this.useWeightPriceInOrder && selectedPack.isWeightPriced) {
      selectedSecondaryPack.modifiedPrice =
        this.calculationService.getNumberOnDigitFormat(
          selectedSecondaryPack.quantity * selectedSecondaryPack.originalPrice
        );
    } else {
      selectedSecondaryPack.modifiedPrice = selectedPack.basePrice;
    }

    selectedSecondaryPack.secondaryQuantity =
      this.calculationService.getNumberOnStockDigitFormat(
        selectedSecondaryPack.quantity * selectedSecondaryPack.requiredQuantity
      );
    selectedPack.price = selectedSecondaryPack.modifiedPrice;
  }
  onAfterSaveSecondaryPack(savedSecondaryPack) {
    var originalPack;
    originalPack = this.originalItemsList.find(
      (x) =>
        x.itemId === savedSecondaryPack.itemId &&
        x.packId === savedSecondaryPack.packId
    );
    if (originalPack && savedSecondaryPack) {
      originalPack.requiredQty = savedSecondaryPack.requiredQuantity;
      originalPack.price = savedSecondaryPack.modifiedPrice;
      originalPack.secondaryPack = savedSecondaryPack;
      originalPack.secondaryPacks = this.secondaryPacksList;
    }

    return originalPack;
  }
  onAfterSaveSecondaryPackFromDialog(savedSecondaryPack) {
    var originalPack = this.onAfterSaveSecondaryPack(savedSecondaryPack);
    this.secondarySelectionDialog.Close();
    this.onAddEditItemClicked(originalPack);
  }
  onAfterSaveSerials(savedPack, isFromAddItemBySerial) {
    var originalPack;
    if (
      this.filterOnSalesType &&
      savedPack.salesTransactionTypeId != SalesTransactionTypes.FOC.valueOf()
    ) {
      originalPack = this.originalItemsList.find(
        (x) =>
          x.itemId === savedPack.itemId &&
          x.packId === savedPack.packId &&
          x.salesTransactionTypeId === savedPack.salesTransactionTypeId
      );
    } else
      originalPack = this.originalItemsList.find(
        (x) => x.itemId === savedPack.itemId && x.packId === savedPack.packId
      );

    if (originalPack) {
      if (this.filterOnSalesType && !savedPack.isFOCItem) {
        this.addedSerialsForAllPacks = this.addedSerialsForAllPacks.filter(
          (serial) =>
            !(
              serial.itemId == savedPack.itemId &&
              serial.packId == savedPack.packId &&
              serial.salesTransactionTypeId == savedPack.salesTransactionTypeId
            )
        );
      } else if (this.isFOCMode && savedPack.isFOCItem) {
        this.addedSerialsForAllPacks = this.addedSerialsForAllPacks.filter(
          (serial) =>
            !(
              serial.itemId == savedPack.itemId &&
              serial.packId == savedPack.packId &&
              serial.salesTransactionTypeId == SalesTransactionTypes.FOC
            )
        );
      } else
        this.addedSerialsForAllPacks = this.addedSerialsForAllPacks.filter(
          (serial) =>
            !(
              serial.itemId == savedPack.itemId &&
              serial.packId == savedPack.packId
            )
        );

      if (!this.addedSerialsForAllPacks) this.addedSerialsForAllPacks = [];

      if (
        savedPack.addedSerials &&
        savedPack.addedSerials.length > 0 &&
        !savedPack.isFOCItem
      ) {
        savedPack.addedSerials.forEach((serial) => {
          this.addedSerialsForAllPacks.push(serial);
        });
      }

      if (
        savedPack.addedFOCSerials &&
        savedPack.addedFOCSerials.length > 0 &&
        savedPack.isFOCItem
      ) {
        savedPack.addedFOCSerials.forEach((serial) => {
          this.addedSerialsForAllPacks.push(serial);
        });
      }

      if (!savedPack.isFOCItem) {
        originalPack.addedSerials = savedPack.addedSerials;
        originalPack.requiredQty = savedPack.requiredQty;
      } else {
        originalPack.addedFOCSerials = savedPack.addedFOCSerials;
        originalPack.requiredFOCQty = savedPack.requiredFOCQty;
      }
      originalPack.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
      originalPack.expiryDateModel.day = this.coreSession
        .DefaultExpiryDate()
        .getDay();
      originalPack.expiryDateModel.month =
        this.coreSession.DefaultExpiryDate().getMonth() + 1;
      originalPack.expiryDateModel.year = this.coreSession
        .DefaultExpiryDate()
        .getFullYear();
      originalPack.batchNo = this.coreSession.DefaultBatchNumber;
    } else {
      return;
    }
    if (!this.allAddedPackObjects) {
      this.allAddedPackObjects = [];
    }
    if (!isFromAddItemBySerial) {
      this.fillAddedSerialsInAllAddedPackObjects(savedPack);
    } else {
      originalPack.isFOCItem = savedPack.isFOCItem;
    }
    this.onAddEditItemClicked(originalPack);
    this.showSerialsSlider = false;
  }
  fillAddedSerialsInAllAddedPackObjects(savedPack) {
    let index = -1;
    if (!this.allAddedPackObjects) this.allAddedPackObjects = [];
    if (this.filterOnSalesType)
      index = this.allAddedPackObjects.findIndex(
        (x) =>
          x.packId === savedPack.packId &&
          x.salesTransactionTypeId === savedPack.salesTransactionTypeId
      );
    else
      index = this.allAddedPackObjects.findIndex(
        (x) => x.packId === savedPack.packId
      );
    if (index >= 0) {
      this.allAddedPackObjects[index].addedFOCSerials =
        savedPack.addedFOCSerials;
      this.allAddedPackObjects[index].addedSerials = savedPack.addedSerials;
    } else {
      this.allAddedPackObjects.push(savedPack);
    }
  }
  removeAddedSerialsFromAllAddedPackObjects(savedPack) {
    let index = -1;
    if (!this.allAddedPackObjects) this.allAddedPackObjects = [];
    if (this.filterOnSalesType)
      index = this.allAddedPackObjects.findIndex(
        (x) =>
          x.packId === savedPack.packId &&
          x.salesTransactionTypeId === savedPack.salesTransactionTypeId
      );
    else
      index = this.allAddedPackObjects.findIndex(
        (x) => x.packId === savedPack.packId
      );
    if (index >= 0) {
      this.allAddedPackObjects.splice(index);
    }
  }
  closeBatchesSlider() {
    this.showBatchesSlider = false;
  }
  onSaveBatchesClick() {
    this.saveBatchesSubject.next();
  }
  onAfterSaveBatches(savedPack) {
    //Update ItemStockBatches & Selected Batches lists and required qty for the selected pack
    //var originalPack = this.originalItemsList.find(x => x.itemId === savedPack.itemId && x.packId === savedPack.packId);
    var originalPack;
    if (this.filterOnSalesType)
      originalPack = this.originalItemsList.find(
        (x) =>
          x.itemId === savedPack.itemId &&
          x.packId === savedPack.packId &&
          x.salesTransactionTypeId === savedPack.salesTransactionTypeId
      );
    else
      originalPack = this.originalItemsList.find(
        (x) => x.itemId === savedPack.itemId && x.packId === savedPack.packId
      );

    if (originalPack) {
      originalPack.itemStockBatches = savedPack.itemStockBatches;
      originalPack.selectedBatches = savedPack.selectedBatches;
      originalPack.requiredQty = savedPack.requiredQty;
    } else {
      return;
    }

    // Add new added batches for item stock and for all packs stock list
    var allPacksForSameItem = this.originalItemsList.filter(
      (x) => x.itemId === originalPack.itemId
    );

    originalPack.itemStockBatches
      .filter((x) => x.isNewBatch)
      .forEach((newBatch) => {
        if (
          this.allItemsStockList.filter(
            (itemBatch) =>
              itemBatch.batchNo === newBatch.batchNo &&
              itemBatch.expiryDateModel.year == newBatch.expiryDateModel.year &&
              itemBatch.expiryDateModel.month ==
              newBatch.expiryDateModel.month &&
              itemBatch.expiryDateModel.day == newBatch.expiryDateModel.day
          ).length <= 0
        ) {
          var newBatchAddedItemLevel = cloneDeep(newBatch);
          this.allItemsStockList.push(newBatchAddedItemLevel);
          allPacksForSameItem.forEach((pack) => {
            var newBatchAddedPackLevel = cloneDeep(newBatch);
            // Add new batch to other packs stock
            // For current pack stock the newBatch is added already in bathces slider
            // required qty will be zero for other packs
            if (pack.packId != originalPack.packId) {
              newBatchAddedPackLevel.requiredQty = 0;
              newBatchAddedPackLevel.packId = pack.packId;
              pack.itemStockBatches.push(newBatchAddedPackLevel);
            }
          });
        }
      });

    // var itemInItemsList =  this.itemsList.find(x => x.itemId === savedPack.itemId && x.packId === savedPack.packId);
    // Call Add/Edit  methos to reflect batches changes over the added items
    this.onAddEditItemClicked(originalPack);
    this.closeBatchesSlider();
  }

  onAfterSaveReturnBatches(packObj) {
    // reflect changes on selected.
    var idx = this.originalItemsList.findIndex(
      (pack) => pack.itemId === packObj.itemId && pack.packId === packObj.packId
    );
    var originalPack = this.originalItemsList[idx];
    originalPack.returnFromInvoiceAvailableBatchList = cloneDeep(
      packObj.returnFromInvoiceAvailableBatchList
    );
    originalPack.requiredQty = packObj.requiredQty;
    originalPack.returnReasonId = packObj.returnReasonId;
    originalPack.packStatusId = packObj.packStatusId;
    originalPack.isExceedAvailableQty = false;

    var itemStockList = cloneDeep(packObj.returnFromInvoiceAvailableBatchList);

    this.onAddEditItemClicked(originalPack);

    this.closeBatchesSlider();
  }
  //#endregion

  //#region [PROPERTIES]

  get division() {
    return this.searchForm.get("division");
  }
  get category() {
    return this.searchForm.get("category");
  }
  disableQtyInput(item: ItemPackModel) {
    if (
      (item.requiredByLiter && this.enableAddItemByLiters) ||
      this.enableReturnBatchFromInvoice ||
      (this.formProcessMode === FormProcessMode.ProcessReturn &&
        this.orderTypeId === OrderTypes.Return.valueOf() &&
        !this.allowPartialDeliveryInProcessReturn) ||
      // ((this.executeOperation ||
      // this.formProcessMode === FormProcessMode.Delivery) &&(
      // (this.enableAddSerial && item.hasSerialNumber) ||
      // (!item.hasSerialNumber &&
      //   (this.enableBatchSelection || this.enableBatchInsertion) &&
      //   item.itemTypeId !== 2)))
      //   ||
      (this.formProcessMode === FormProcessMode.Delivery) ||
      (this.enableAddSerial && item.hasSerialNumber) ||
      (!item.hasSerialNumber &&
        (this.enableBatchSelection || this.enableBatchInsertion) &&
        item.itemTypeId !== 2)
    ) {
      return true;
    } else {
      return false;
    }
  }
  isDelivery() {
    return this.formProcessMode === FormProcessMode.Delivery;
  }
  isPartialDelivery() {
    return this.formProcessMode === FormProcessMode.PartialDelivery;
  }
  isOrders() {
    return this.formProcessMode === FormProcessMode.Orders;
  }
  /* get itemSearch() {
    return this.searchForm.get('itemSearch');
  } */
  onChangeCartWidth() {
    this.isCartExpanded = !this.isCartExpanded;
  }

  onReturnReasonModalSave() {
    if (this.forceSelectingPackStatusPerTransaction) {
      this.returnReasonForm
        .get("txPackStatusId")
        .setValue(this.txLevelPackStatus.packStatusId);
      this.returnReasonForm
        .get("packStatusId")
        .setValidators(Validators.nullValidator);
      this.returnReasonForm.get("packStatusId").updateValueAndValidity();
    }
    this.coreSession.markFormGroupTouched(this.returnReasonForm);
    if (
      !this.batchNumberControl.value ||
      this.batchNumberControl.value === "" ||
      this.batchNumberControl.value.trim() === ""
    ) {
      this.batchNumberControl.setValue(null);
    }
    if (this.returnReasonForm.invalid) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgFillMandatory)
      );
      return false;
    }

    if (!this.UseDefaultExpiryAndBatchForItems) {
      // If batch number entering is enabled, fill batch no. value; else, it will be saved as default batch no.
      if (
        this.enableEnteringBatchNoInReturn ||
        this.showExpiryInReturnsAndExchange
      ) {
        this.selectedPack.batchNo =
          this.batchNumberControl.value &&
            this.batchNumberControl.value.trim() != ""
            ? this.batchNumberControl.value
            : null;
      } else {
        this.selectedPack.batchNo = null;
      }
      // if expiry date combo is shown, get date value; else , it will be saved as default expiry date.
      if (this.showExpiryInReturnsAndExchange) {
        this.selectedPack.expiryDateModel = this.selectedExpiryDate;
        if (
          (!this.forceSelectingPackStatusPerTransaction &&
            this.packStatusDataSource.find(
              (p) => p.packStatusId == this.packStatusIdControl.value
            ).expired == 1) ||
          (this.forceSelectingPackStatusPerTransaction &&
            this.packStatusDataSource.find(
              (p) =>
                p.packStatusId ==
                this.returnReasonForm.get("txPackStatusId").value
            ).expired == 1 &&
            this.selectedExpiryDate.date > new Date())
        ) {
          this.coreSession.showWarrning(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(
              ConstantMessages.MsgExpiryDateLessThanTodayDate
            )
          );
          return false;
        }
      } else {
        this.selectedPack.expiryDateModel = null;
      }
    }
    if (!this.forceSelectingPackStatusPerTransaction) {
      this.selectedPack.packStatusId = this.packStatusIdControl.value;
    }
    this.selectedPack.returnReasonId =
      this.returnReasonIdControl.value && this.returnReasonIdControl.value > -1
        ? this.returnReasonIdControl.value
        : -1;

    var idx = this.copyOfOrderAddedItems.findIndex(
      (i) =>
        i.itemId === this.selectedPack.itemId &&
        i.packId === this.selectedPack.packId
    );
    if (idx > -1) {
      this.copyOfOrderAddedItems[idx] = this.selectedPack;
    } else {
      this.copyOfOrderAddedItems.push(this.selectedPack);
    }
    this.onCloseReturnReasonModal();
  }

  onCloseReturnReasonModal() {
    this.returnReasonForm.reset();
    this.selectedExpiryDate = null;
    this.modalRef.dismiss();
  }

  onCloseTxPackStatusModal() {
    this.modalRef.dismiss();
  }

  openTxPackStatusModal() {
    if (this.txLevelPackStatus && this.txLevelPackStatus.packStatusId > -1) {
      this.returnReasonForm
        .get("txPackStatusId")
        .setValue(this.txLevelPackStatus.packStatusId);
    }
    this.modalRef = this.modalService.open(
      this.transactionLevelPackStatusModal,
      {
        centered: true,
      }
    );
    // this.config.backdrop = true;
  }
  onSaveTxPackStatus() {
    //Pack status changed reset return reasons.
    this.returnReasonForm.get("returnReasonId").setValue(null);
    this.defaultReturnReason = null;

    if (this.showReturnReason) {
      this.addedItemsList.map((i) => {
        if (i.isReturnItem || !this.isExchangeMode) i.returnReasonId = null;
      });
    }
    // reset pack, reset expirydate validator
    this.isCurrentPackResellable = false;

    this.returnReasonForm
      .get("expiryDate")
      .setValidators(Validators.nullValidator);
    this.returnReasonForm.get("expiryDate").updateValueAndValidity();
    // validate form
    if (
      !this.returnReasonForm.get("txPackStatusId").value ||
      this.returnReasonForm.get("txPackStatusId").value === -1
    ) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgFillMandatory)
      );
      return;
    }
    // filter return reasons based on selected txLevelPackStatus.
    this.txLevelPackStatus = this.packStatusDataSource.filter(
      (x) =>
        x.packStatusId === this.returnReasonForm.get("txPackStatusId").value
    )[0];
    if (
      this.txLevelPackStatus &&
      this.txLevelPackStatus != undefined &&
      this.txLevelPackStatus.packStatusId > -1
    ) {
      this.getReturnReasons(this.txLevelPackStatus.packStatusId, false, true);
      this.addedItemsList.map((i) => {
        i.packStatusId = this.txLevelPackStatus.packStatusId;
      });
      if (
        this.enterExpiryDateForGoodReturnsOnly &&
        !this.UseDefaultExpiryAndBatchForItems &&
        this.showExpiryInReturnsAndExchange
      ) {
        if (this.txLevelPackStatus.reSellable === 1) {
          this.isCurrentPackResellable = true;
          this.returnReasonForm
            .get("expiryDate")
            .setValidators(Validators.required);
          this.returnReasonForm.get("expiryDate").updateValueAndValidity();
        } else if (this.txLevelPackStatus.reSellable === 0) {
          this.isCurrentPackResellable = false;
          this.returnReasonForm
            .get("expiryDate")
            .setValidators(Validators.nullValidator);
          this.returnReasonForm.get("expiryDate").updateValueAndValidity();
        }
      } else if (
        !this.enterExpiryDateForGoodReturnsOnly &&
        !this.UseDefaultExpiryAndBatchForItems &&
        this.showExpiryInReturnsAndExchange
      ) {
        this.returnReasonForm
          .get("expiryDate")
          .setValidators(Validators.required);
        this.returnReasonForm.get("expiryDate").updateValueAndValidity();
      }
      if (
        this.UseDefaultExpiryAndBatchForItems ||
        !this.showExpiryInReturnsAndExchange
      ) {
        this.returnReasonForm
          .get("expiryDate")
          .setValidators(Validators.nullValidator);
        this.returnReasonForm.get("expiryDate").updateValueAndValidity();
      }
    }
    this.onCloseTxPackStatusModal();
  }

  openReturnReasonModal(item) {
    let returnItem = new ItemPackModel();
    returnItem = this.addedItemsList.find(
      (x) =>
        x.itemId == item.itemId &&
        x.packId == item.packId &&
        x.salesTransactionTypeId == SalesTransactionTypes.None.valueOf()
    );
    if (this.forceSelectingPackStatusPerTransaction) {
      if (!this.txLevelPackStatus || this.txLevelPackStatus.packStatusId <= 0) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(
            ConstantMessages.DescPackStatusMustBeDefinedForTransaction
          )
        );
        return;
      }
    }
    this.returnReasonForm.reset();
    var idx = this.copyOfOrderAddedItems.findIndex(
      (i) =>
        i.itemId === item.itemId &&
        i.packId === item.packId &&
        i.salesTransactionTypeId == SalesTransactionTypes.None.valueOf()
    );
    if (idx > -1) {
      var statusId = -1;
      if (
        !this.UseDefaultExpiryAndBatchForItems &&
        this.showExpiryInReturnsAndExchange &&
        this.enterExpiryDateForGoodReturnsOnly
      ) {
        statusId = returnItem.packStatusId;
      }

      if (!this.forceSelectingPackStatusPerTransaction) {
        this.getPackStatuses(true, statusId);

        this.returnReasonForm
          .get("packStatusId")
          .setValue(returnItem.packStatusId);
        if (this.showReturnReason && returnItem.returnReasonId > 0) {
          this.getReturnReasons(returnItem.packStatusId, true);
        }
        this.returnReasonForm
          .get("returnReasonId")
          .setValue(item.returnReasonId > 0 ? item.returnReasonId : null);
      } else {
        if (item.returnReasonId > 0) {
          this.returnReasonForm
            .get("returnReasonId")
            .setValue(item.returnReasonId);
          // this.getReturnReasons(this.txLevelPackStatus.packStatusId);
        }
      }
      if (item.requiredReturnQty > 0) {
        this.returnReasonForm
          .get("returnReasonId")
          .setValue(returnItem.returnReasonId);
      }

      if (!this.UseDefaultExpiryAndBatchForItems) {
        if (returnItem.expiryDateModel) {
          this.returnReasonForm
            .get("expiryDate")
            .setValue(returnItem.expiryDateModel.date);
          this.selectedExpiryDate = returnItem.expiryDateModel;
        }
        if (this.enableEnteringBatchNoInReturn) {
          this.returnReasonForm.get("batchNumber").setValue(returnItem.batchNo);
        }
      }
    } else {
      // Item does not exist
      if (!this.forceSelectingPackStatusPerTransaction) {
        this.getPackStatuses();
      } else {
        this.returnReasonForm
          .get("txPackStatusId")
          .setValue(this.txLevelPackStatus.packStatusId);
        if (item && item.returnReasonId && item.returnReasonId > 0) {
          this.returnReasonForm
            .get("returnReasonId")
            .setValue(item.returnReasonId);
        } else {
          if (item.isReturnItem || !this.isExchangeMode)
            item.returnReasonId = this.defaultReturnReason;
          this.returnReasonForm
            .get("returnReasonId")
            .setValue(this.defaultReturnReason);
        }
        // this.getReturnReasons(this.txLevelPackStatus.packStatusId);
      }
    }
    // if return = 0, x
    if (
      this.isExchangeMode &&
      this.addedItemsList.findIndex(
        (i) => i.packId === item.packId && i.isReturnItem
      ) > -1
    ) {
      this.selectedPack = returnItem;
    } else {
      this.selectedPack = item;
    }

    this.modalRef = this.modalService.open(this.returnReasonModal, {
      centered: true,
    });
    this.config.backdrop = true;
  }

  initReturnReasonForm() {
    this.returnReasonForm = new FormGroup({
      returnReasonId: new FormControl(
        null,
        this.showReturnReason ? Validators.required : null
      ),
      packStatusId: new FormControl(
        null,
        !this.forceSelectingPackStatusPerTransaction
          ? Validators.required
          : null
      ),
      expiryDate: new FormControl(
        null,
        !this.enterExpiryDateForGoodReturnsOnly &&
          !this.UseDefaultExpiryAndBatchForItems &&
          this.showExpiryInReturnsAndExchange &&
          !this.restrictReturnFromInvoices
          ? Validators.required
          : null
      ),
      batchNumber: new FormControl(
        null,
        !this.UseDefaultExpiryAndBatchForItems &&
          this.enableEnteringBatchNoInReturn &&
          !this.restrictReturnFromInvoices
          ? Validators.required
          : null
      ),
      txPackStatusId: new FormControl(
        null,
        this.forceSelectingPackStatusPerTransaction &&
          !this.restrictReturnFromInvoices &&
          this.orderTypeId === OrderTypes.Return.valueOf()
          ? Validators.required
          : null
      ),
    });
    if (
      this.orderTypeId === OrderTypes.Return.valueOf() &&
      !this.restrictReturnFromInvoices &&
      !this.allowPartialDeliveryInProcessReturn &&
      this.formProcessMode === FormProcessMode.ProcessReturn
    ) {
      this.returnReasonForm.get("txPackStatusId").disable();
      this.returnReasonForm.get("expiryDate").disable();
      this.returnReasonForm.get("batchNumber").disable();
      this.returnReasonForm.get("packStatusId").disable();
      this.returnReasonForm.get("returnReasonId").disable();
    }
  }

  onExpiryDateSelected(date) {
    // fill batch by default?
    if (date && date != undefined) {
      this.selectedExpiryDate = date;
      // this.batchNumber = null;
      // this.expiryDate = date;
      var batchNo;
      let batchNumberFormat = this.sessionData.getConfigurationValue(
        ConstantConfigurations.BatchNumberFormat
      );
      if (
        batchNumberFormat &&
        batchNumberFormat != "" &&
        batchNumberFormat != "No Format" &&
        batchNumberFormat != "NoFormat"
      ) {
        if (date && date.date) {
          batchNo = this.datepipe.transform(date.date, batchNumberFormat);
        } else {
          batchNo = "";
        }
      } else {
        batchNo = this.datepipe.transform(
          date.date,
          this.coreSession.BatchNoFormat
        );
      }
    }
    this.returnReasonForm.get("batchNumber").setValue(batchNo);
  }

  getReturnReasons(
    selectedPackStatusId: number,
    skipDefault?: boolean,
    defaultForAllItems?: boolean
  ) {
    if (!skipDefault || skipDefault === undefined) {
      skipDefault = false;
    }
    this.itemService.getReturnReasons(selectedPackStatusId).subscribe(
      (response) => {
        if (response && response.status != null && response.status >= 0) {
          this.returnReasonDataSource = response.data;
          if (!skipDefault) {
            var defaultReturnReason = this.returnReasonDataSource.filter(
              (rr) => rr.isDefault === 1
            );
            if (defaultReturnReason.length > 0) {
              this.returnReasonForm
                .get("returnReasonId")
                .setValue(defaultReturnReason[0].returnReasonId);
              if (this.forceSelectingPackStatusPerTransaction) {
                this.defaultReturnReason =
                  defaultReturnReason[0].returnReasonId;
                if (
                  (defaultForAllItems &&
                    this.showReturnReason &&
                    this.orderTypeId === OrderTypes.Return.valueOf()) ||
                  this.isExchangeMode
                ) {
                  this.addedItemsList.map((i) => {
                    i.returnReasonId = this.defaultReturnReason;
                  });
                }
              }
            }
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }

  getPackStatuses(skipDefault?: boolean, currentPackStatusId?: number) {
    if (!skipDefault || skipDefault == undefined) {
      skipDefault = false;
    }
    this.isPackStatusLoading = true;
    this.itemService.getPackStatuses(this.isDamagedWHSelected).subscribe(
      (response) => {
        this.isPackStatusLoading = false;
        if (response && response.status != null && response.status >= 0) {
          this.packStatusDataSource = response.data;
          if (!skipDefault) {
            var defaultStatus = this.packStatusDataSource.filter(
              (pck) => pck.isDefault === 1
            );
            if (defaultStatus.length > 0) {
              this.defaultPackStatus = defaultStatus[0];
              this.txLevelPackStatus = defaultStatus[0];
              if (
                this.forceSelectingPackStatusPerTransaction &&
                defaultStatus[0].packStatusId > -1
              ) {
                this.returnReasonForm
                  .get("txPackStatusId")
                  .setValue(defaultStatus[0].packStatusId);
              } else {
                this.returnReasonForm
                  .get("packStatusId")
                  .setValue(defaultStatus[0].packStatusId);
              }
              if (this.showReturnReason) {
                this.getReturnReasons(defaultStatus[0].packStatusId);
              }
              if (this.enterExpiryDateForGoodReturnsOnly) {
                if (defaultStatus[0].reSellable === 1) {
                  this.isCurrentPackResellable = true;
                  this.returnReasonForm.controls["expiryDate"].setValidators(
                    Validators.required
                  );
                } else if (defaultStatus[0].reSellable === 0) {
                  this.isCurrentPackResellable = false;
                  this.returnReasonForm.controls[
                    "expiryDate"
                  ].clearValidators();
                }
              }
            }
          }
          // if Edit mode, and all packs have same status, set txLevelPackStatusId
          if (
            this.forceSelectingPackStatusPerTransaction &&
            this.addedItemsList &&
            this.addedItemsList.length > 0 &&
            this.addedItemsList[0].packStatusId > 0 &&
            !this.restrictReturnFromInvoices
          ) {
            this.txLevelPackStatus = this.packStatusDataSource.filter(
              (s) => s.packStatusId === this.addedItemsList[0].packStatusId
            )[0];

            this.getReturnReasons(this.txLevelPackStatus.packStatusId);
          }
          if (this.addedItemsList != null && this.addedItemsList.length > 0) {
            var status = this.packStatusDataSource.find(
              (s) => s.packStatusId === this.addedItemsList[0].packStatusId
            );
            if (status && status.reSellable === 1) {
              this.expiryDateProperties.min = new Date();
            } else {
              this.expiryDateProperties.min = null;
            }
          }

          if (currentPackStatusId && currentPackStatusId > 0) {
            var status = this.packStatusDataSource.find(
              (s) => s.packStatusId === currentPackStatusId
            );
            if (status) {
              this.isCurrentPackResellable =
                status.reSellable === 1 ? true : false;
            }
          }
        }
      },

      (error: HttpErrorResponse) => {
        this.isPackStatusLoading = false;
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }

  onPackStatusSelected(packStatus) {
    this.expiryDateProperties.min = null;
    this.returnReasonForm.get("returnReasonId").setValue(null);
    this.isCurrentPackResellable = false;

    this.returnReasonForm
      .get("expiryDate")
      .setValidators(Validators.nullValidator);
    this.returnReasonForm.get("expiryDate").updateValueAndValidity();

    this.returnReasonDataSource = [];
    if (
      packStatus &&
      packStatus != undefined &&
      packStatus.packStatusId > -1 &&
      this.showReturnReason
    ) {
      if (packStatus.reSellable === 1) {
        this.expiryDateProperties.min = new Date();
        if (
          this.returnReasonForm.get("expiryDate").value <=
          this.expiryDateProperties.min
        ) {
          this.returnReasonForm.get("expiryDate").setValue(null);
        }
      }
      this.getReturnReasons(packStatus.packStatusId);
      if (
        this.enterExpiryDateForGoodReturnsOnly &&
        !this.UseDefaultExpiryAndBatchForItems &&
        this.showExpiryInReturnsAndExchange
      ) {
        if (packStatus.reSellable === 1) {
          this.isCurrentPackResellable = true;
          this.returnReasonForm
            .get("expiryDate")
            .setValidators(Validators.required);
          this.returnReasonForm.get("expiryDate").updateValueAndValidity();
        } else if (packStatus.reSellable === 0) {
          this.isCurrentPackResellable = false;
          this.returnReasonForm
            .get("expiryDate")
            .setValidators(Validators.nullValidator);
          this.returnReasonForm.get("expiryDate").updateValueAndValidity();
        }
      }
      if (
        !this.enterExpiryDateForGoodReturnsOnly &&
        !this.UseDefaultExpiryAndBatchForItems &&
        this.showExpiryInReturnsAndExchange
      ) {
        this.returnReasonForm
          .get("expiryDate")
          .setValidators(Validators.required);
        this.returnReasonForm.get("expiryDate").updateValueAndValidity();
      }
      if (
        this.UseDefaultExpiryAndBatchForItems ||
        !this.showExpiryInReturnsAndExchange
      ) {
        this.returnReasonForm
          .get("expiryDate")
          .setValidators(Validators.nullValidator);
        this.returnReasonForm.get("expiryDate").updateValueAndValidity();
      }
    }
  }

  onTxPackStatusSelected(packStatus) {
    // this.txLevelPackStatus = null;
    // if(packStatus && packStatus != undefined && packStatus.packStatusId > -1) {
    //   this.txLevelPackStatus = packStatus;
    // }
  }

  getReasonString(returnReasonId) {
    if (
      this.returnReasonDescriptions &&
      this.returnReasonDescriptions.length > 0
    ) {
      var idx = this.returnReasonDescriptions.findIndex(
        (r) => r.returnReasonId === returnReasonId
      );
      if (idx > -1) {
        return this.returnReasonDescriptions[idx].returnReasonDescription;
      } else {
        return " -- ";
      }
    } else {
      return " -- ";
    }
  }

  getItemDetailsColumnWidth(): string {
    if (
      (this.isFOCMode && this.formProcessMode === 2 && !this.isExchangeMode) ||
      (this.enableAddSerial) ||
      this.enableBatchSelection ||
      this.enableBatchInsertion ||
      this.enableAddSecondaryPack ||
      (this.orderTypeId === 2 && (this.showReturnReason || (!this.UseDefaultExpiryAndBatchForItems && (this.enableEnteringBatchNoInReturn || this.showExpiryInReturnsAndExchange))))
    ) {
      return 'col-sm-8';
    } else {
      return 'col-sm-9';
    }
  }
  showEditPrieDialog(item) {
    this.config.backdrop = "static";
    this.editedItem = item;
    this.editedItem.editedPrice = this.editedItem.price;
    this.modalService.open(this.editPriceDialog, { centered: true }).result.then(
      (result) => {
        this.config.backdrop = true;
        this.isPriceChanged = false;
        if (result == DialogResult.Ok) {
          this.isPriceChanged = true;
          this.editedItem.editedPrice = +this.editedItem.editedPrice;
          if (this.checkPriceLimit) { //should insert price when minprice and maxprice equal zero.
            if ((this.editedItem.editedPrice >= this.editedItem.minPrice && this.editedItem.editedPrice <= this.editedItem.maxPrice) || (this.editedItem.minPrice == 0 && this.editedItem.maxPrice == 0)) {
              item.price = this.editedItem.editedPrice;
              this.onPriceValueChanged(item);
            } else {
              this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgPriceOutLimitedRange));
            }
          } else {
            item.price = this.editedItem.editedPrice;
            this.onPriceValueChanged(item);
          }
        }
      });
  }
  onCancel() {
    this.modalService.dismissAll();
  }
  ngOnDestroy() {
    if (
      this.orderTypeId === OrderTypes.Return.valueOf() ||
      this.isExchangeMode
    ) {
      // this.calculationService.setReturnsBool(false);
    }
  }
  // returnReasonForm:
  get returnReasonIdControl() {
    return this.returnReasonForm.get("returnReasonId");
  }
  get packStatusIdControl() {
    return this.returnReasonForm.get("packStatusId");
  }
  get expiryDateControl() {
    return this.returnReasonForm.get("expiryDate");
  }
  get batchNumberControl() {
    return this.returnReasonForm.get("batchNumber");
  }
  //#endregion
}
