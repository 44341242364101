export enum OrderStatus {
    None= 0,
    New = 1,
    Approved = 2,
    Assigned= 3,
    WaitForLoad = 4,
    LoadRequestAccepted = 5,
    Invoiced = 6 ,
    Rescheduled = 7,
    Rejected = 8,
    Delivered = 9,
    Deleted= 10,
    Closed= 11,
    RejectCompleted = 12,
    Canceled = 13,
    OnHold = 14,
    LockedtoRN = 15,
    Collected= 16,
    NewAfterUpdate = 17,
    ComplementaryOrder = 18
}
