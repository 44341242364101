import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { warehouseFilter } from '../../models/shared-table/custom-list.interface';
import { ResponseModel } from '../../models/api-models/api-models';
import { CoreSession } from '../../../core/core.session';
import { WarehouseTransactionStatusModel } from '../../models/warehouse-transactions/warehouse-transaction.model';

@Injectable({
  providedIn: 'root'
})
export class StockCountingTaskService {
  vehicleId: number;
  private url = '';
  private httpOptions;
  public warehouseTransactionStatuses: WarehouseTransactionStatusModel[];
  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl + 'StockCountingTask';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  getStockCountingTasksStatus() {
    const urlValue: string = this.url + '/GetStockCountingTasksStatus';
    return this.http
      .get<ResponseModel>(urlValue
      )
      .map(response => {
        return <any>response.data;
      });
  }
  getStockCountingTasksSharedTableModel(stockCountingTasksFilter: any) {

    const urlValue: string = this.url + '/GetStockCountingTasksSharedTableModel';
    return this.http
      .post<ResponseModel>(urlValue, stockCountingTasksFilter)
      .map(response => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }
  SaveStockCountingTask(stockCountingTask: any) {
    const urlValue: string = this.url + '/InsertStockCountingTask';
    (urlValue);
    return this.http.post(urlValue, JSON.stringify(stockCountingTask), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  deleteStockCountingTask(warehouseId: number) {
    const urlValue: string = this.url + '/DeleteStockCountingTask';
    return this.http.post(urlValue, warehouseId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getStockCountingTaskItems(stockCountingTaskID: number) {
    const urlValue: string = this.url + '/GetStockCountingTaskItems';
    return this.http.post(urlValue, stockCountingTaskID).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
}
