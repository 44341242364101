import {
  Component,
  OnInit,
  Inject,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ImageSnippetModel } from "../../shared/models/image/image-snippet";
import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { ResponseModel } from "../../shared/models/api-models/api-models";
import { DialogMode } from "../../shared/models/enums/dialog-mode.enum";
import { CoreSession } from "../../core/core.session";
import { ConstantMessages } from "../../shared/models/constants/constant-message";
import { EmployeeService } from "../employee/employee.service";
import { MenuModel } from "../../shared/models/menu/menu.model";
import { ConstantURLs } from "../../shared/models/constants/constant-URL";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SessionDataProvider } from "../../core/session-data-provider.service";
import { NavBarService } from "../../core/sidebar/sidebar.service";
import { AuthService } from "../../core/auth/auth.service";
import { Observable } from "rxjs/Rx";

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.css"],
})
export class MyProfileComponent implements OnInit {
  @Input() events: Observable<void>;
  @Output() onSaveCompleted = new EventEmitter();
  saveBtnSubscription: any;
  selectedFile: ImageSnippetModel;
  headers: HttpHeaders;
  url: any = "";
  imagePath: any = "";
  employeeData = "EmployeeName-Code";
  employeeEmail = "";
  menuDataSource: MenuModel[];
  isMenusLoading = false;
  selectedFavMenu: MenuModel;
  selectedImage: File;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  dummyURL: string;
  selectedMenuId: string = "";
  imageSelected = false;
  currentEmployee: any;
  isAllowToChangePassword = true;
  type1: string = "password";
  type2: string = "password";
  type3: string = "password";
  eyeIcon: string = "fa fa-eye eye-icon";
  slashIcon: string = "fa fa-eye-slash eye-icon";
  notAllowChangePasswordMsg: string = null;
  // profileForm: FormGroup=new FormGroup({});
  constructor(
    private router: Router,
    private coreSession: CoreSession,
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private sessionDataProvider: SessionDataProvider,
    private navBarService: NavBarService,
    private authService: AuthService
  ) {
    this.translateService.use(this.translateService.currentLang);
  }

  ngOnInit() {
    this.subscribeSaveClick();
    this.getEmployeeDataFromDB();
    this.coreSession.SetTitle("My Profile");
    this.updateImageUrl();
    this.fillParentMenus();
    this.AllowChangePassword();
  }
  getEmployeeDataFromDB() {
    this.employeeService.getEmployeeProfileData().subscribe(
      (response: ResponseModel) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.currentEmployee = response.data;
          this.employeeData =
            this.currentEmployee.employeeName +
            " - " +
            this.currentEmployee.employeeCode;
          this.employeeEmail = this.currentEmployee.email;
          //wwwroot/Users-Img/
          this.imagePath =
            document.getElementsByTagName("base")[0].href +
            "" +
            response.data.imageUserProfilePath +
            "?" +
            new Date().getTime();
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  onChangeOldPassword() {
    if (this.oldPassword || this.oldPassword.length > 0) {
    }
  }
  fillParentMenus() {
    this.employeeService.getParentMenus().subscribe(
      (response: ResponseModel) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.menuDataSource = response.data;
          this.getLandingMenu();
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  getLandingMenu() {
    this.selectedFavMenu = new MenuModel();
    if (
      this.coreSession.CurrentOperator.landingMenu &&
      this.coreSession.CurrentOperator.landingMenu.menuId !== ""
    ) {
      this.selectedFavMenu = this.coreSession.CurrentOperator.landingMenu;
      this.selectedMenuId = this.coreSession.CurrentOperator.landingMenu.menuId;
    } else if (
      this.sessionDataProvider.allMenusAvailable.filter(
        (x) => !x.menuId.includes("-")
      ).length > 0
    ) {
      if (
        this.sessionDataProvider.allMenusAvailable.filter(
          (x) => x.url === ConstantURLs.orderManagementURL
        ).length > 0
      ) {
        this.selectedFavMenu.menuId = "300";
        this.selectedFavMenu.url = ConstantURLs.orderManagementURL;
        this.selectedMenuId = "300";
      } else {
        this.selectedFavMenu.menuId =
          this.sessionDataProvider.allMenusAvailable.filter(
            (x) => !x.menuId.includes("-")
          )[0].menuId;
        this.selectedFavMenu.url =
          this.sessionDataProvider.allMenusAvailable.filter(
            (x) => !x.menuId.includes("-")
          )[0].url;
        this.selectedMenuId = this.sessionDataProvider.allMenusAvailable.filter(
          (x) => !x.menuId.includes("-")
        )[0].menuId;
      }
    }
  }
  saveImage() {
    if (this.selectedImage) {
      this.employeeService.uploadImage(this.selectedImage).subscribe(
        (response: ResponseModel) => {
          // this.coreSession.ModalLoading.Hide();
          // if (response.status != null && response.status >= 0) {
          // }
          // else {
          //   this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.None);
          // }
        },
        (error: HttpErrorResponse) => {
          // this.coreSession.ModalLoading.Hide();
          // this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.ErrorHappened), DialogMode.None);
        }
      );
    }
  }
  InitForm() {}
  updateImageUrl() {
    this.imagePath = this.coreSession.baseURL + "assets/img/users-img/user.jpg";
  }

  validateDifferentNewPassword(): boolean {
    if(this.newPassword && this.newPassword !=='' ){
    if(this.newPassword === this.oldPassword) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgNewPasswordShouldBeDifferent));
      return false;
    } else {
      return true;
    }
   }
  else {
   return true;
 }
  }

  validatePasswordMatch(): boolean {
    if (this.newPassword && this.newPassword !== "") {
      if (this.oldPassword && this.oldPassword !== "") {
        if (this.isAllowToChangePassword) {
          if (this.newPassword !== this.confirmNewPassword) {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              this.translateService.instant(
                ConstantMessages.MsgPasswordsNotMatches
              )
            );
            return false;
          }
        }
        return true;
      } else {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(ConstantMessages.MsgFillOldPassword)
        );
        return false;
      }
    } else {
      return true;
    }
  }
  AllowChangePassword() {
    this.employeeService
      .getAllowChangePassword(this.coreSession.CurrentOperator.operatorId)
      .subscribe(
        (response: ResponseModel) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.isAllowToChangePassword = response.data;
          } else {
            //this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
            this.isAllowToChangePassword = false;
            this.notAllowChangePasswordMsg = response.message;
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  onMenuDataChanged(menu: MenuModel) {
    this.selectedFavMenu = menu;
  }
  saveNewData() {
    // this.coreSession.CurrentOperator.imageFile=this.selectedImage.file;
    if (this.validatePasswordMatch()) {
      if (this.validateDifferentNewPassword()) {
        this.saveImage();
        this.employeeService
          .uploadDataFromProfile(
            this.oldPassword,
            this.newPassword,
            this.selectedFavMenu.menuId,
            this.isAllowToChangePassword
          )
          .subscribe(
            (response: ResponseModel) => {
              this.coreSession.ModalLoading.Hide();
              if (response.status != null && response.status >= 0) {
                this.coreSession.CurrentOperator.landingMenu =
                  this.selectedFavMenu;
                localStorage.removeItem("CurrentOperator");
                localStorage.setItem(
                  "CurrentOperator",
                  JSON.stringify(this.coreSession.CurrentOperator)
                );
                ("new landing menus is: ");
                this.coreSession.CurrentOperator.landingMenu;
                // this.router.navigate(['/' + this.selectedFavMenu.url]);
                this.navBarService.setReloadSidebar();
                this.onSaveCompleted.emit();
                this.coreSession.showSuccess(
                  this.translateService.instant(
                    ConstantMessages.SuccessCaption
                  ),
                  this.translateService.instant(
                    ConstantMessages.MsgSavedSuccessfuly
                  )
                );
                //this.authService.navigateToHomeScreen();
              } else {
                this.coreSession.showError(
                  this.translateService.instant(ConstantMessages.ErrorCaption),
                  response.message
                );
              }
            },
            (error: HttpErrorResponse) => {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.ErrorCaption),
                this.translateService.instant(ConstantMessages.ErrorHappened)
              );
            }
          );
      }
    }
  }
  // back() {
  //   this.authService.navigateToHomeScreen();
  // }

  onUploadFinished(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      this.selectedImage = event.target.files[0];
      this.imageSelected = true;
      reader.onload = (event: ProgressEvent) => {
        this.imagePath = (<FileReader>event.target).result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  subscribeSaveClick() {
    if (this.events) {
      this.saveBtnSubscription = this.events.subscribe(() => {
        this.saveNewData();
      });
    }
  }
  ngOnDestroy() {
    if (this.saveBtnSubscription) this.saveBtnSubscription.unsubscribe();
  }
  onTogglePass(type: any) {
    switch (type) {
      case 1:
        if (this.type1 == "password") this.type1 = "text";
        else this.type1 = "password";
        break;
      case 2:
        if (this.type2 == "password") this.type2 = "text";
        else this.type2 = "password";
        break;
      case 3:
        if (this.type3 == "password") this.type3 = "text";
        else this.type3 = "password";
        break;
    }
  }
}
