import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject } from "rxjs";

import { map } from "rxjs/operators";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";

@Injectable({
  providedIn: "root",
})
export class DisplayUnitTemplateService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "DisplayUnitTemplate";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
  getDisplayUnitTemplateFilter() {
    const urlValue: string = this.url + '/GetDisplayUnitTemplateFilter';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }

  getDisplayUnitTemplateSharedTable(displayUnitTemplateFilter: any) {
    const urlValue: string = this.url + '/GetDisplayUnitTemplateSharedTable';
    return this.http.post(urlValue, JSON.stringify(displayUnitTemplateFilter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  getDisplayUnitTemplateById(templateId: number) {
    const urlValue = this.url + '/GetDisplayUnitTemplateByID';
    return this.http
      .post<ResponseModel>(urlValue, templateId)
      .map(response => {
        return response;
      });
  }

  saveDisplayUnitTemplate(template: FormData) {
    const urlValue = this.url + "/InsertDisplayUnitTemplate";
    return this.http.post<ResponseModel>(urlValue, template).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return response;
      }
    );
  }

  deleteDisplayUnitTemplate(displayUnitID: number) {
    const urlValue: string = this.url + '/DeleteDisplayUnitTemplate';
    return this.http.post(urlValue, displayUnitID, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }


}
