import { Component, EventEmitter, Injectable, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogResult } from '../../models/enums/dialog-result.enum';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-shared-dialog',
  templateUrl: './shared-dialog.component.html',
  styleUrls: ['./shared-dialog.component.css'],
  // encapsulation: ViewEncapsulation.None
})
@Injectable()
export class SharedDialogComponent {

  //#region [DECLARATIONS]

  saveResult: DialogResult = DialogResult.Save;
  cancelResult: DialogResult = DialogResult.Cancel;
  @ViewChild('content', { static: true }) content: any;
  @Input('MyForm') myForm: FormGroup;
  @Input('Title') titleCaption = 'Title';
  @Input('Size') size = 'md';
  @Input('BodyTemplate') bodyTemplate: TemplateRef<any>;
  @Input('ShowPrivacyCheck') showPrivacyCheck = false;
  @Input('ShowCancel') showCancel = true;
  @Input('CancelCaption') cancelCaption = 'Desc_Cancel';
  @Input('ShowSave') showSave = true;
  @Input('ShowAddLocation') showAddLocation = false;
  @Input('ShowCancelLocation') showCancelLocation = false;
  @Input('SaveCaption') saveCaption = 'Desc_Save';
  @Input('AddLoaction') addLocationCaption = 'Desc_Add_Location';
  @Input('CancelLoaction') cancelLoactionCaption = 'Desc_Cancel_Location';
  @Input('SaveIconClass') saveIconClass = 'fa fa-floppy-o';
  @Output('Result') result = new EventEmitter();
  @Output('Cancle') cancle = new EventEmitter();
  modal;
  checked = false;
  //#endregion

  //#region [INITIALIZED]

  constructor(private modalService: NgbModal) {
  }

  //#endregion

  //#region [METHODS]

  markAsTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markAsTouched(control);
      }
    });
  }

  public Show(reset) {
    if (reset && this.myForm) {
      this.myForm.reset();
    }
    return this.modalService.open(this.content, {
      size: this.size,
      centered: true,
      backdrop: 'static', keyboard: false
    }).result.then(
      (result: DialogResult) => {
        return result;
      },
      (resaon) => {
        return DialogResult.None;
      }
    );
  }
  public Close() {
    this.modal.close(this.cancelResult);
  }
  onSavedClicked(modal) {
    if (this.myForm) {
      this.markAsTouched(this.myForm);
      if (this.myForm.invalid) {
        return;
      }
      modal.close(this.saveResult);
    }
    else {
      this.modal = modal;
      let obj = {
        modal: modal,
        saveCLicked: true
      };
      this.result.emit(obj);
    }
  }
  onCancleClicked(modal) {
    if (this.myForm) {
      this.markAsTouched(this.myForm);
      if (this.myForm.invalid) {
        return;
      }
      modal.close(this.saveResult);
    }
    else {
      this.modal = modal;
      let obj = {
        modal: modal,
        saveCLicked: true
      };
      this.cancle.emit(obj);
      modal.close(this.saveResult);
    }
  }

  //#endregion
}
