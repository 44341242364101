export enum ImportDataTypes {
  None = 0,
  Items = 1,
  Employees = 2,
  Customers = 3,
  Serials = 4,
  Serials_InputVoucher = 5,
  DisplayUnit = 6,
  SerialReassignment = 7,
  BackToStoreOffload = 8,
  BackToStoreReset = 9,
  UnlockedSerial = 10,
  ItemsPricesWithQtyRange = 11,
  SalesIfFOC = 12,
  ItemsPricesWithoutQtyRange = 13,
  KPIs = 14,
  CommissionCalc = 15,
  ShopContractTargets = 16,
  MustSellItems = 17,
  KPIScorecard = 18,
  CustomerPromotion = 19,
  ExcludeCustomerPromotion = 20,
  TeleRepPlan = 21, // CRM
  CustomerOutletPromotion = 22,
  ExcludeCustomerOutletPromotion = 23,
  AppData = 24
}
