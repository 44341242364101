import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges  } from '@angular/core';
import { CurrencyService } from '../../../../content/currency/currency.service';
import { FormGroup, AbstractControl } from '@angular/forms';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';

@Component({
  selector: 'app-currency-filter',
  templateUrl: './currency-filter.component.html',
  styleUrls: ['./currency-filter.component.css']
})
export class CurrencyFilterComponent implements OnInit , OnChanges{

  @Input() parentForm: FormGroup;
  selectedCurrencyId: number;
  currencyDataSource: any[];
  isCurrencyLoading = false;
  isRequired = false;
  @Output() currencySelected=new EventEmitter();
  @Input() organizationId = -1;
  @Input() isEditMode = false;
  @Input() originalOrgId = -1; // this value for the original selected currency in edit mode
  @Input() isFromCustomer = false;
  ApplyCurrencyPerOrg = false;
  constructor(private currencyService: CurrencyService,private sessionData: SessionDataProvider) { }
  
  ngOnInit() {
    if(this.parentForm.get('currencyId').validator != null){
      const validator = this.parentForm.get('currencyId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    
    //this.populateCurrencyList();
  }

  ngOnChanges(){
    this.ApplyCurrencyPerOrg = this.sessionData.getConfigurationValue(ConstantConfigurations.ApplyCurrencyPerOrganization).toLowerCase() === "true";
    // in case of edit mode and user try to edit the selected org , then the base currency should be edited according to the new selected org .
    // but in customer entry there is not an original selected org , so the selected currenct for customer should be appeared in edit mode .
    if (((this.isEditMode && this.organizationId != this.originalOrgId && !this.isFromCustomer) 
    || (this.isEditMode && this.isFromCustomer && this.organizationId != -1) 
    || (!this.isEditMode)) && this.ApplyCurrencyPerOrg)
       {this.selectedCurrencyId = -1;
       this.parentForm.get('currencyId').reset();
       }
    this.populateCurrencyList();
  }


  populateCurrencyList() {

    this.isCurrencyLoading = true;
    this.currencyService.getAllCurrencies(this.organizationId).subscribe(result => {
      this.currencyDataSource = result;
      if(this.currencyDataSource != null && this.currencyDataSource.length > 0 && (this.parentForm.get('currencyId').value === -1 || this.parentForm.get('currencyId').value === null || this.parentForm.get('currencyId').value === '')){
        if(this.currencyDataSource.find(currency => currency.base === true) != null){
          let selectedCurrency=this.currencyDataSource.find(currency => currency.base === true);
          this.selectedCurrencyId = selectedCurrency.currencyId;
          this.parentForm.get('currencyId').setValue(this.selectedCurrencyId);
          this.currencySelected.emit(selectedCurrency);
        }
      }
      this.isCurrencyLoading = false;
    });
  
}
  onCurrencyDataChanged($event) {
    this.selectedCurrencyId = this.parentForm.get('currencyId').value
    this.currencySelected.emit($event);

  }

}
