import { KeyLimitDialogComponent } from './../../shared/components/import-dialog/key-limit/key-limit-dialog.component';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
  ViewRef,
} from "@angular/core";
import { NavBarService } from "../sidebar/sidebar.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { CoreSession } from "../core.session";
import { ConstantURLs } from "../../shared/models/constants/constant-URL";
import { AboutSonicComponent } from "../../content/about-sonic/about-sonic.component";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription, timer } from "rxjs";
import * as signalR from "@aspnet/signalr";
import { NotificationService } from "../notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ConstantMessages } from "../../shared/models/constants/constant-message";
import { NotificationTypes } from "../../shared/models/enums/notification-Types.enum";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { DialogResult } from "../../shared/models/enums/dialog-result.enum";
import { SessionDataProvider } from "../session-data-provider.service";
import { EmployeeKeyDialogComponent } from "../../content/employee-operation/employee-key-dialog/employee-key-dialog.component";
import { ChattingHistoryModel } from "../../shared/models/chatting/message.model";
import { EmployeeService } from "../../content/employee/employee.service";
import { ChattingService } from "../../content/chatting/chatting.service";
import { ChattingUser } from "../../shared/models/chatting/chatting-user.model";
import { ChattingConnectionStatus } from "../../shared/models/enums/chatting/chatting-connection-status.enum";
import { ResponseModel } from "../../shared/models/api-models/api-models";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  //#region [DECLARATIONS]

  private _hubConnection: signalR.HubConnection;
  notificationsList: any[] = [];
  newNotificationsCount = 0;
  unreadNotificationsCount = 0;
  selectedNotification: any = null;
  @ViewChild("notificationDetailsDialog", { static: true })
  notificationDetailsDialog: NgbModal;
  isNotificationsLoading = false;
  discountKeyList: any[];
  discountKeyListCurrentLanguage: any[];
  discountKeyObject: any;

  @ViewChild("keyModal", { static: true }) keyModal: EmployeeKeyDialogComponent;
  @ViewChild("discountKeyModal", { static: true }) discountKeyModal: KeyLimitDialogComponent;

  @ViewChild("aboutSonic", { static: true }) aboutSonic: AboutSonicComponent;
  showSlider = false;
  public isMinimized = true;
  public isOptionsClicked = false;
  showLoggedInOptions = true;
  public urls;
  saveSubject: Subject<void> = new Subject<void>();
  imagePath;
  langList: string[];
  currentLang = "";
  customStyleAvatar = {
    backgroundColor: "#ffffff",
    border: "1px solid #7e7e7e",
    borderRadius: "50%",
    color: "#7e7e7e",
    cursor: "pointer",
  };

  employeeNameToView = "";
  employeeNameToShourtcut = "";
  @ViewChild("changeLanguageDialog", { static: true })
  changeLanguageDialog: any;
  searchPattern: string = null;
  externalUrlsList: any[] = [];
  //#endregion

  copyOfUnreadMessages: any[] = [];
  unreadMessages: any[] = [];
  unreadMessagesCount = 0;
  isChattingOn: boolean = false;
  imagePath2 = "";

  currentEmployeeId = this.coreSession.CurrentOperator.employeeId;
  showChat: boolean = false;
  securityGroupPrivilegeChangeSubscription: any;
  messageReceivedEvent: any;
  messageReadEvent: any;
  isRTLdirection: any;
  internetConnectionState;
  chattingScreenStatusSubscription;
  isNormalLogout: boolean = false; // used to indicate if the log out event occured normally [user clicked on log out] , or abnormally [user forced to log out] 
  isInternetConnected: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navService: NavBarService,
    private authService: AuthService,
    public coreSession: CoreSession,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private sessionDataProvider: SessionDataProvider,
    private chattingService: ChattingService,
    private employeeService: EmployeeService,
    private _ngZone: NgZone,
    private ref: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.imagePath2 =
      this.coreSession.baseURL + "assets/img/users-img/user.jpg";
  }

  ngOnInit() {
    this.subscribeToChattingScreenState(); 
    this.subscribeToSGChange(); 

    this.isRTLdirection = this.coreSession.isRTL;
    this.urls = ConstantURLs;
    this.langList = this.translate.getLangs();
    this.currentLang = this.coreSession.selectedLanguageStr;
    this.getLoggendInEmployeeName();
    this.getEmployeeNotificationsLazyLoading();
    this.getEmployeeUnreadNotificationsCount();
    this.getExternalURLData();
    if (
      this.coreSession.CurrentOperator &&
      this.coreSession.CurrentOperator.isLoggedIn
    ) {
    } else {
      this.logout(true);
    }
    var hubURL = this.coreSession.baseURL + "notify";
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubURL)
      .build();
    this._hubConnection.serverTimeoutInMilliseconds = 100000; // 100 second
    this._hubConnection.start().then().catch();
    this._hubConnection.on("BroadcastMessage", (notification) => {
      if (
        notification.receiversList.findIndex(
          (x) => x === this.coreSession.CurrentOperator.employeeId
        ) >= 0
      ) {
        setTimeout(() => {
          notification.isNew = true;
          this.notificationsList = [];
          this.newNotificationsCount += 1;
          this.unreadNotificationsCount += 1;
          this.getEmployeeNotificationsLazyLoading();
        }, 50);
      }
    });
    
    this.getEmployeeDataFromDB(false);
  }

  subscribeToChattingScreenState() {
    // subscribe to chatting screen state [isOpen, isClosed]
    this.chattingScreenStatusSubscription =
    this.chattingService.isChattingScreenOpen.subscribe((isOpen:boolean) => {
      this.isChattingOn = isOpen;
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }
  ngOnDestroy() {
    if (this.modalService) {
      this.modalService.dismissAll();
    }
    if(this.securityGroupPrivilegeChangeSubscription) this.securityGroupPrivilegeChangeSubscription.unsubscribe();
    if(this.messageReceivedEvent) this.messageReceivedEvent.unsubscribe();
    if(this.messageReadEvent) this.messageReadEvent.unsubscribe();
    if(this.internetConnectionState) this.internetConnectionState.unsubscribe();
    if(this.chattingScreenStatusSubscription) this.chattingScreenStatusSubscription.unsubscribe();
    this.disposeChattingHubConnection();
    this.chattingService.automaticReconnect = false;
  }

  isChattingMenuAdded(): boolean {
    var idx = this.sessionDataProvider.addedMenus.findIndex(menu => menu.menuId === '2100' && menu.isAvailable); 
    if(idx > -1) {
      return true;
    } else {
      return false;
    }
  }
  
  checkChatIconVisibility() {
    if(this.isChattingMenuAdded()) {
      if(!this.chattingService._hubConnection) {
        this.createChattingHub();
      }
      this.showChat = true;
      this.getEmployeeUnreadMessages();
    } else {
      this.showChat = false;
    }
  }

  subscribeToSGChange() {
    // subscribe to changes in chatting security group privilege for current operator.
    this.securityGroupPrivilegeChangeSubscription =
      this.chattingService.securityGroupPrivilegeChanged.subscribe(() => {
        this.checkChatIconVisibility();
      });
  }

  // subscribeToInternetConnectionState() {
  //   // subscribe to internet connection state [connected, disconnected]
  //   this.internetConnectionState = this.coreSession.connectionState.subscribe((isConnected) => {
      
  //     if (isConnected) {
  //       if (!this.chattingService._hubConnection) {
  //         this.unsubscribeToChattingEvents();
  //         // this.createChattingHub();
  //       } else {
  //         // this.chattingService.startConnection(this.isChattingAvailableForEmployee && this.isInternetConnected && this.isChattingAvailableInDB);
  //         this.checkChatIconVisibility();
  //       }
  //     } else {
  //       // this.showChat = false;
  //       this.unsubscribeToChattingEvents();
  //       // this.disposeChattingHubConnection();
  //     }
  //   });
  // }
  
  disposeChattingHubConnection() {
    if(this.chattingService._hubConnection) {
      this.chattingService.closeConnection();
    }
  }

  openNotificationBubble() {
    this.newNotificationsCount = 0;
  }
  //#region Key

  //#endregion Key

  viewNotificationKeysDialog(notification) {
    this.selectedNotification = notification;
    if (!notification.isRead) {
      this.setNotificationReadFlag(notification, true);
    }

    if (notification.notificationTypeId == NotificationTypes.EmployeeKeys) {
      this.openKeyModal(notification);
      return;
    }
    if (notification.notificationTypeId == NotificationTypes.DiscountKeys) {
      this.openDiscountKeyModal(notification);
      return;
    }

    this.modalService
      .open(this.notificationDetailsDialog, { centered: true })
      .result.then((result) => {
        if (result === DialogResult.Ok) {
          // mark as read
        }
      });
    document.getElementById("notHeader").innerHTML =
      notification.headerDescription;
    document.getElementById("notBody").innerHTML = notification.bodyDescription;
  }

  viewNotificationDetailsDialog(notification) {
    this.selectedNotification = null;
    this.selectedNotification = notification;
    if (notification.notificationTypeId == NotificationTypes.EmployeeKeys) {
      this.viewNotificationKeysDialog(notification);
      return;
    }
    if (notification.notificationTypeId == NotificationTypes.DiscountKeys) {
      this.viewNotificationKeysDialog(notification);
      return;
    }
    if (!notification.isRead) {
      this.setNotificationReadFlag(notification, true);
    }

    this.modalService
      .open(this.notificationDetailsDialog, { centered: true })
      .result.then((result) => {
        if (result === DialogResult.Ok) {
          // mark as read
        }
      });
    document.getElementById("notHeader").innerHTML =
      notification.headerDescription;
    document.getElementById("notBody").innerHTML = notification.bodyDescription;
  }
  sendNotificationAPITest() {
    this.coreSession.ModalLoading.Show();
    var descriptionsList = [
      {
        bodyDescription:
          "With Following Items : 1001 first item [PC] : 100.000<br>1001 first item [Case] : 100.000<br>1002 second item [Case] : 50.000<br>1002 second item [PC] : 12.000<br>",
        headerDescription: "Recall TEST Offloading Warehouse 1001-MW",
        senderDescription: "Admin",
        isDefaultLanguage: false,
        languageId: 1,
      },
      {
        bodyDescription:
          "تفاصيل المواد : 1001 المادة الاولى [حبة] : 100.000<br>1001 المادة الاولى [كرتونة] : 100.000<br>1002 المادة الثانية [كرتونة] : 50.000<br>1002 second item [حبة] : 12.000<br>",
        headerDescription: "استرجاع rvgerg - vdfv : تفريغ المستودع من M27 - --",
        senderDescription: "مدير النظام",
        isDefaultLanguage: false,
        languageId: 2,
      },
    ];

    let receiversList = [0];
    let notification = {
      templateId: 1,
      senderId: 0,
      receiversList: receiversList,
      notificationTypeId: NotificationTypes.Recall,
      IsBOUser: true,
      senderCode: "Sys_Admin",
      descriptionsList: descriptionsList,
    };
    this.notificationService.sendNotification(notification).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          // this.coreSession.showSuccess(this.translate.instant(ConstantMessages.SuccessCaption), this.translate.instant(ConstantMessages.MsgSavedSuccessfuly));
        } else {
          // this.coreSession.showError(this.translate.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translate.instant(ConstantMessages.ErrorCaption),
          this.translate.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  getEmployeeNotificationsLazyLoading() {
    this.notificationsList = [];
    this.isNotificationsLoading = true;
    var currentNotificationCount = this.notificationsList.length;
    // this.coreSession.ModalLoading.Show();
    this.notificationService
      .getEmployeeNotificationsLazyLoading(currentNotificationCount)
      .subscribe(
        (response) => {
          this.isNotificationsLoading = false;
          //    this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            if (response.data && response.data.length > 0) {
              var list = response.data;
              for (var notification of list) {
                notification.shortCut =
                  notification.senderDescription.charAt(0);
                notification.replacedBody =
                  notification.bodyDescription.replace("<br>", " ");
                notification.replacedHeader =
                  notification.headerDescription.replace("<br>", " ");
                notification.imagePath =
                  this.coreSession.baseURL +
                  "assets/img/users-img/" +
                  notification.senderId +
                  ".jpg";
                if (
                  this.notificationsList.findIndex(
                    (x) => x.notificationId === notification.notificationId
                  ) < 0
                ) {
                  this.notificationsList.push(notification);
                }
              }
            }
          } else {
            // this.coreSession.showError(this.translate.instant(ConstantMessages.ErrorCaption), response.message);
          }
        },
        (error: HttpErrorResponse) => {
          this.isNotificationsLoading = false;
          //   this.coreSession.ModalLoading.Hide();
          // this.coreSession.showError(this.translate.instant(ConstantMessages.ErrorCaption), this.translate.instant(ConstantMessages.ErrorHappened));
        }
      );
  }
  getEmployeeUnreadNotificationsCount() {
    //   this.coreSession.ModalLoading.Show();
    this.notificationService.getUnreadNotificationsCount().subscribe(
      (response) => {
        //    this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.unreadNotificationsCount = response.data;
        } else {
          // this.coreSession.showError(this.translate.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        //   this.coreSession.ModalLoading.Hide();
        // this.coreSession.showError(this.translate.instant(ConstantMessages.ErrorCaption), this.translate.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  setNotificationReadFlag(notification, isRead) {
    notification.isRead = isRead;
    if (notification.isRead) {
      this.unreadNotificationsCount -= 1;
    } else {
      this.unreadNotificationsCount += 1;
    }
    let setNotificationAsReadModel = {
      notificationId: notification.notificationId,
      receiverId: notification.receiverId,
      markAsRead: notification.isRead,
    };
    //this.coreSession.ModalLoading.Show();
    this.notificationService
      .setNotificationReadFlag(setNotificationAsReadModel)
      .subscribe(
        (response) => {
          //this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            // this.coreSession.showSuccess(this.translate.instant(ConstantMessages.SuccessCaption), this.translate.instant(ConstantMessages.MsgSavedSuccessfuly));
          } else {
            // this.coreSession.showError(this.translate.instant(ConstantMessages.ErrorCaption), response.message);
          }
        },
        (error: HttpErrorResponse) => {
          //this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translate.instant(ConstantMessages.ErrorCaption),
            this.translate.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  getExternalURLData() {
    this.coreSession.ModalLoading.Show();
    this.sessionDataProvider.getExternalURLs().subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data && response.data.length > 0) {
            this.externalUrlsList = response.data;
            this.externalUrlsList.forEach((x) => {
              x.fullIcon = this.coreSession.baseURL + "ExternalURL/" + x.icon;
            });
          }
        } else {
          // this.coreSession.showError(this.translate.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        // this.coreSession.showError(this.translate.instant(ConstantMessages.ErrorCaption), this.translate.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  minimizeClicked() {
    this.isMinimized = !this.isMinimized;
    this.navService.setIsMiniMized(this.isMinimized);
  }
  updateImageUrl() {
    this.imagePath = this.coreSession.baseURL + "assets/img/users-img/user.jpg";
  }
  goToHome() {
    this.router.navigateByUrl("Home");
  }
  optionsClicked() {
    this.isOptionsClicked = !this.isOptionsClicked;
    this.navService.setSettingClicked(this.isOptionsClicked);
  }
  openChangeLanguage() {
    this.coreSession.showLanguageSlider = true;
    this.router.navigate(["/" + ConstantURLs.settingsURL], {
      relativeTo: this.route,
    });
  }
  logout(forcedLogout?: boolean) {
    forcedLogout = forcedLogout ? forcedLogout : false; // set undefined case to false.
    this.isNormalLogout = !forcedLogout;
    
    this.authService.logout();
  }
  openAboutSonic() {
    this.aboutSonic.Show();
  }
  openKeyModal(notification) {
    this.keyModal.Show(notification);
  }
  openDiscountKeyModal(notification) {
    this.discountKeyModal.Show(notification);
  }

  switchLanguage(language: string) {
    this.currentLang = language;
    this.translate.use(language);
  }
  getLoggendInEmployeeName() {
    this.employeeNameToView = this.coreSession.CurrentOperator.employeeName;
    var splitted = this.coreSession.CurrentOperator.employeeName.split(" ");

    for (let i = 0; i < splitted.length; i++) {
      if (i > 1) {
        break;
      }
      this.employeeNameToShourtcut += splitted[i][0];
      this.employeeNameToShourtcut += ".";
    }
    this.employeeNameToShourtcut = this.employeeNameToShourtcut.substring(
      0,
      this.employeeNameToShourtcut.length - 1
    );
  }

  private subscribeToEvents(): void {
    this.messageReceivedEvent = this.chattingService.messageReceived.subscribe(
      (message: ChattingHistoryModel) => {
        this._ngZone.run(() => {
          //ngZone
          // IF chatting screen is not on
          // OR chatting screen is on BUT chatting window is open with another user [not the message sender].
          // OR chatting screen is on BUT no selected user [THIS CASE DOES NOT OCCUR ANYMORE]
          // THEN display message notification.
          if (
            window.location.href.split("/")[
            window.location.href.split("/").length - 1
            ] !== ConstantURLs.chattingURL ||
            (this.chattingService.getCurrentChattingUser() &&
              this.chattingService.getCurrentChattingUser().userId !==
              message.senderId) ||
            (window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ] === ConstantURLs.chattingURL &&
              !this.chattingService.getCurrentChattingUser())
          ) {
            // If Message is not a broadcast
            if (
              message.receiversList === null ||
              message.receiversList.length === 0
            ) {
              if (message.receiverId === this.currentEmployeeId) {
                this.unreadMessagesCount += 1;

                var idx = this.unreadMessages.findIndex(
                  (msgObj) => msgObj.senderId === message.senderId
                );
                if (idx === -1) {
                  var msgNotificationObject = {
                    senderId: message.senderId,
                    messages: [],
                    imagePath:
                      this.coreSession.baseURL +
                      "Users-Img/" +
                      message.senderId +
                      ".jpg" +
                      "?" +
                      new Date().getTime(),
                  };
                  msgNotificationObject.messages.push(message);
                  this.unreadMessages.push(msgNotificationObject);
                } else {
                  this.unreadMessages[idx].messages.push(message);
                }
              }
            } else {
              //  Message is a broadcast
              // Check if current user is in receivers list.
              var i = message.receiversList.findIndex(
                (receiver) => receiver.receiverId === this.currentEmployeeId
              );
              if (i > -1) {
                message.receiverId = this.currentEmployeeId;
                message.receiverName = message.receiversList[i].receiverName;
                message.isBOReceiver = true;
                message.messageId = message.receiversList[i].messageId;
                message.receiverDeviceToken = "";
                message.messageDate = message.messageDateTimeModel.date;

                message.messageBody = message.messageBody.split("\n")[0];

                this.unreadMessagesCount += 1;
                var idx = this.unreadMessages.findIndex(
                  (msgObj) => msgObj.senderId === message.senderId
                );
                if (idx === -1) {
                  var msgNotificationObject = {
                    senderId: message.senderId,
                    messages: [],
                    imagePath: (this.imagePath =
                      this.coreSession.baseURL +
                      "Users-Img/" +
                      message.senderId +
                      ".jpg" +
                      "?" +
                      new Date().getTime()),
                  };
                  msgNotificationObject.messages.push(message);
                  this.unreadMessages.push(msgNotificationObject);
                } else {
                  this.unreadMessages[idx].messages.push(message);
                }
              }
            }
          }
        }); //ngZone
      }
    );
    this.messageReadEvent = this.chattingService.messageRead.subscribe(
      (message: ChattingHistoryModel) => {
        this._ngZone.run(() => {
          //ngZone
          if (
            message.receiverId === this.coreSession.CurrentOperator.employeeId
          ) {
            var idx = this.unreadMessages.findIndex(
              (msgObj) => msgObj.senderId === message.senderId
            );

            if (idx > -1) {
              this.unreadMessagesCount =
                this.unreadMessagesCount -
                this.unreadMessages[idx].messages.length;
              if (this.unreadMessagesCount < 0) {
                this.unreadMessagesCount = 0;
              }
              this.unreadMessages.splice(idx, 1);
            }
          }
        }); //ngZone
      }
    );
  }

  openSlider() {
    this.showSlider = true;
  }
  closeSlider() {
    this.showSlider = false;
  }
  onSaveCompleted() {
    this.getEmployeeDataFromDB(true);
    this.showSlider = false;
  }
  onSaveClick() {
    this.saveSubject.next();
  }
  openchangeLanguageDialog() {
    this.changeLanguageDialog.showDialog();
  }
  getSessionLanguages() {
    return this.sessionDataProvider.getSessionLanguages();
  }
  checkIfChangeLanguageMenuVisible() {
    if (
      this.sessionDataProvider.allMenusAvailable.filter(
        (x) => x.menuId === "900-001-01"
      ).length < 1
    ) {
      return false;
    } else {
      return true;
    }
  }
  applaySearchFilter() {
    if (
      this.searchPattern != null &&
      this.searchPattern != "" &&
      this.searchPattern.trim() != null &&
      this.searchPattern.trim() != ""
    ) {
      this.coreSession.generalSearchPattern = this.searchPattern;
      this.router.navigateByUrl(ConstantURLs.searchResultURL);
      this.navService.setGeneralSerachPattern();
    } else {
      this.coreSession.generalSearchPattern = null;
    }
  }

  getEmployeeUnreadMessages() {
    this.coreSession.ModalLoading.Show();
    this.chattingService
      .getEmployeeUnreadMessages(
        this.currentEmployeeId,
        this.coreSession.selectedLanguageId
      )
      .subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();

          if (response && response.status != null && response.status >= 0) {
            this.copyOfUnreadMessages = response.data;
            this.unreadMessagesCount = this.copyOfUnreadMessages.length;

            // Group messages by their sender
            this.copyOfUnreadMessages.forEach((msg) => {
              msg.isBOReceiver = true;
              msg.messageBody = msg.messageBody.split("\n")[0];
              var idx = this.unreadMessages.findIndex(
                (msgObj) => msgObj.senderId === msg.senderId
              );
              if (idx === -1) {
                var msgNotificationObject = {
                  senderId: msg.senderId,
                  messages: [],
                  imagePath: msg.imagePath
                  // (this.imagePath =
                  //   this.coreSession.baseURL +
                  //   "Users-Img/" +
                  //   msg.senderId +
                  //   ".jpg" +
                  //   "?" +
                  //   new Date().getTime()),
                };
                msgNotificationObject.messages.push(msg);
                msgNotificationObject.imagePath =  msg.imagePath
                this.unreadMessages.push(msgNotificationObject);
              } else {
                var msgIdx = this.unreadMessages[idx].messages.findIndex(m => m.messageId === msg.messageId);
                if(msgIdx == -1) {
                  this.unreadMessages[idx].messages.push(msg);
                }
              }
            });
          } else {
            this.coreSession.showError(
              this.translate.instant(ConstantMessages.ErrorCaption),
              this.translate.instant(response.message)
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translate.instant(ConstantMessages.ErrorCaption),
            this.translate.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }

  openUnreadMessages() {
    this.unreadMessagesCount = 0;

    if (this.unreadMessages === null || this.unreadMessages.length === 0) {
      if (
        window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ] !== ConstantURLs.chattingURL
      ) {
        this.router.navigate(["/" + ConstantURLs.chattingURL]);
      }
    }
  }

  oViewMessageInChatting(messageObj) {
    this.chattingService
      .getChattingUserData(
        messageObj.senderId,
        this.coreSession.selectedLanguageId
      )
      .subscribe(
        (response) => {
          if (response.status != null && response.status >= 0) {
            var user = response.data;

            this.chattingService.setCurrentChattingUser(user);
            if (
              window.location.href.split("/")[
              window.location.href.split("/").length - 1
              ] !== ConstantURLs.chattingURL
            ) {
              this.chattingService.setSelectedChatUserFromNotifications(
                messageObj.senderId
              );
              this.router.navigate(["/" + ConstantURLs.chattingURL]);
            }
          } else {
            this.coreSession.showError(
              this.translate.instant(ConstantMessages.ErrorCaption),
              this.translate.instant(ConstantMessages.ErrorHappened)
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.showError(
            this.translate.instant(ConstantMessages.ErrorCaption),
            this.translate.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }

  onSeeAllMessagesInChatting() {
    if (
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ] !== ConstantURLs.chattingURL
    ) {
      this.router.navigate(["/" + ConstantURLs.chattingURL]);
    }
  }

  onMarkUserMessagesAsRead(message) {
    this.chattingService
      .setMessagesAsRead(
        message.messages[0],
        this.coreSession.selectedLanguageId
      )
      .subscribe(
        (response) => {
          if (response && response.status != null && response.status >= 0) {
            var idx = this.unreadMessages.findIndex(
              (msgObj) => msgObj.senderId === message.senderId
            );

            if (idx > -1) {
              this.unreadMessagesCount =
                this.unreadMessagesCount -
                this.unreadMessages[idx].messages.length;
              this.unreadMessages.splice(idx, 1);
            }
          } else {
            this.coreSession.showError(
              this.translate.instant(ConstantMessages.ErrorCaption),
              this.translate.instant(response.message)
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.showError(
            this.translate.instant(ConstantMessages.ErrorCaption),
            this.translate.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }

  getEmployeeDataFromDB(fromSaveProfile: boolean) {
    this.employeeService.getEmployeeProfileData().subscribe(
      (response: ResponseModel) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          //wwwroot/Users-Img/
          this.imagePath =
            this.coreSession.baseURL +
            "" +
            response.data.imageUserProfilePath +
            "?" +
            new Date().getTime();

            this.handleChat(response,fromSaveProfile);
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }

  handleChat(response, fromSaveProfile) {
    this.isInternetConnected = response.data.isInternetConnected ? true : false;
    this.showChat = this.isChattingMenuAdded();
    if(!fromSaveProfile && this.showChat) {
      this.createChattingHub();
      this.getEmployeeUnreadMessages();
    }
  }

  createChattingHub() {
    this.chattingService.createConnection();
    // if there is no internet access or chatting is unavailable for employee, do not call InformChattingUesrs API. 
    // [to Avoid firebase push notification error or Unauthorized error]
    this.chattingService.startConnection(this.isInternetConnected && this.showChat); 
    this.chattingService.registerOnServerEvents();
    this.subscribeToEvents();
  }

  unsubscribeToChattingEvents() {
    if (this.messageReceivedEvent) this.messageReceivedEvent.unsubscribe();
    if (this.messageReadEvent) this.messageReadEvent.unsubscribe();
  }
}
