import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { CustomerModel } from '../../../../models/customer/customer.model';
import { CustomerOutletModel } from '../../../../models/customer/outlet.model';
import { CustomListFilter } from '../../../../models/shared-table/custom-list.interface';
import { Subject } from 'rxjs';
import { CustomerService } from '../../../../services/data-definition/customer/customers.service';
import { CoreSession } from '../../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../../models/constants/constant-message';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogMode } from '../../../../models/enums/dialog-mode.enum';

@Component({
  selector: 'app-customer-channel-filter',
  templateUrl: './customer-channel-filter.component.html',
  styleUrls: ['./customer-channel-filter.component.css']
})
export class CustomerChannelFilterComponent implements OnInit {

   // #regoion [DECLARATIONS]
   @Input() parentForm: FormGroup;
   @Input() isEditMode: boolean;
   @Input() channelEditValue: string;
   @Input() subChannelEditValue: string;
   @Input() enableSelectAllSubChannels: boolean = false;
   @Input() viewSubChannel: boolean = true;
   @Input() verticalView: boolean = false;
   @Input() emitIdAndCaption=false;
   @Input() allowSelectSubChannelOnly=false;
   @Output() onChangeChannel = new EventEmitter();
   @Output() onChangeSubChannel = new EventEmitter();

   @Output() onChangeIdCaptionChannel = new EventEmitter();
   @Output() onChangeIdCaptionSubChannel = new EventEmitter();

   @Input() resetClickedEvent: EventEmitter<boolean>;
   resetSubscription : any;

   channelDataSource: any[] = [];
   subChannelDataSource: any[] = [];

   selectedChannelId: number;
   selectedSubChannelId: number;

   isSubChannelLoading = false;
   isChannelLoading = false;

   lblSelectAll ='';

   isChannelRequired = false;
   isSubChannelRequired = false;
   // #endregion

   constructor(
     private customerService: CustomerService,
     private coreSession: CoreSession,
     private translateService: TranslateService
   ) {}

   ngOnChanges() {
     if(this.channelEditValue && this.channelEditValue.trim() !== "" && parseInt(this.channelEditValue) > -1 &&
      this.subChannelEditValue && this.subChannelEditValue.trim() !== "" && parseInt(this.subChannelEditValue)  > -1) {
        this.parentForm.get('channelId').setValue(parseInt(this.channelEditValue));
        this.parentForm.get('subChannelId').setValue(parseInt(this.subChannelEditValue));
        this.channelEditValue = "-1";
        this.subChannelEditValue = "-1";
      }

     this.selectedChannelId = this.parentForm.value.channelId;
     this.selectedSubChannelId = this.parentForm.value.subChannelId;
   }
   ngOnInit() {

     if(this.resetClickedEvent){
       this.resetSubscription = this.resetClickedEvent.subscribe(() => {
         this.subChannelDataSource = null;
        });
     }

     this.lblSelectAll = this.translateService.instant(ConstantMessages.CaptionAllSubChannels);

     if(this.parentForm.get('channelId').validator != null){
       const validator = this.parentForm.get('channelId').validator({} as AbstractControl);
       if (validator && validator.required) {
         this.isChannelRequired = true;
       }
     }
     if(this.parentForm.get('subChannelId').validator != null){
       const validator = this.parentForm.get('subChannelId').validator({} as AbstractControl);
       if (validator && validator.required) {
         this.isSubChannelRequired = true;
       }
     }

     if (this.isEditMode) {
     } else {
       this.populateChannelsList();
       if (this.selectedChannelId || this.allowSelectSubChannelOnly) {
         this.populateSubChannelList();
       }
     }

   }

   populateChannelsList() {

     this.isChannelLoading = true;
     this.customerService
       .getCustomerAllChannels()
       .subscribe(result => {
         if(result != undefined && result.data != undefined){
           this.channelDataSource =  result.data;
           }
         this.isChannelLoading = false;
       });
     }

   populateSubChannelList() {
     this.isSubChannelLoading = true;
     this.customerService.getCustomerAllSubChannels(this.selectedChannelId ? this.selectedChannelId : -1).subscribe(
       result => {
         this.subChannelDataSource = result.data;
         if(this.selectedChannelId){
          if (this.subChannelDataSource && this.subChannelDataSource.length > 0 && this.enableSelectAllSubChannels) {
            let subChannelObj = {
              subChannelId:-1,
              subChannelCode:'--',
              subChannelName:'--',
              subChannelCodeName:this.lblSelectAll
            };

            this.subChannelDataSource.unshift(subChannelObj);
          }
         }
         this.isSubChannelLoading = false;
         if (this.selectedChannelId && this.subChannelDataSource && this.subChannelDataSource.length > 0 && !this.selectedSubChannelId) {
           this.selectedSubChannelId = this.subChannelDataSource[0].subChannelId;
         }
         this.parentForm.get("subChannelId").setValue(this.selectedSubChannelId);
         this.onSubChannelDataChanged(this.selectedSubChannelId);
       },
       (error: HttpErrorResponse) => {
         this.isSubChannelLoading = false;
         this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
       }
     );
   }

   onChannelDataChanged(channel:any) {
     this.selectedChannelId = this.parentForm.value.channelId;
     this.subChannelDataSource = [];
     this.selectedSubChannelId = null;
     if(!this.emitIdAndCaption){
       this.onChangeChannel.emit(channel);
     }else{

       let event={
         id:this.selectedChannelId ? this.selectedChannelId : -1,
         caption: channel ? channel.channelCodeName : ''
       };
       this.onChangeIdCaptionChannel.emit(event);
     }

    if(this.allowSelectSubChannelOnly){
      this.parentForm.get("subChannelId").setValue(this.selectedSubChannelId);
      this.populateSubChannelList();
    }
    else{
      if (this.selectedChannelId) {
        this.populateSubChannelList();
      } else {
        this.parentForm.get("subChannelId").setValue(this.selectedSubChannelId);
      }
    }
   }

   onSubChannelDataChanged(subChannel:any) {
     this.selectedSubChannelId = this.parentForm.value.subChannelId;
     this.parentForm.get("subChannelId").setValue(this.selectedSubChannelId);


     if(!this.emitIdAndCaption){
       this.onChangeSubChannel.emit(subChannel);
     }else{

       let event={
         id:  this.selectedSubChannelId,
         caption:this.selectedSubChannelId==-1?'-- - --':subChannel.subChannelCodeName
       }
       this.onChangeIdCaptionSubChannel.emit(event);
     }
   }




   ngOnDestroy() {
     if(this.resetClickedEvent){
       this.resetSubscription.unsubscribe();
     }
   }



 }
