import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CoreSession } from '../../../../core/core.session';
import { ResponseModel } from '../../../models/api-models/api-models';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { WarehouseTransaction } from '../../../models/warehouse-transactions/warehouse-transaction.model';
import { SSCCGenerationService } from '../sscc-generation.service';

@Component({
  selector: 'app-scan-barcode',
  templateUrl: './scan-barcode.component.html',
  styleUrls: ['./scan-barcode.component.scss']
})
export class ScanBarcodeComponent implements OnInit {
  @ViewChild('content', { static: true }) content: any;
  @Output('onScanClickedInDialog') onScanClickedInDialog = new EventEmitter();
  @Output('onVerifyClickedInDialog') onVerifyClickedInDialog = new EventEmitter();
  @Input('transaction') transaction:WarehouseTransaction;
  @Input('orderObj') orderObj:any;
  @Input ('verifyBarcode') verifyBarcode=false;
  @Input('headerTitle') headerTitle='Desc_Scan_To_Add_Barcode';
  searchedBarcode='';
ssccObject:any={};
  constructor(private config: NgbModalConfig,
    private ssccGenerateService:SSCCGenerationService,
    private modalService: NgbModal,
    private coreSession:CoreSession,
    private translateService:TranslateService) { }

  ngOnInit() {
  }
  onSearchBarcodeClicked(){
    if(this.verifyBarcode){
      this.onVerifyClickedInDialog.emit(this.searchedBarcode);
      this.searchedBarcode='';
    }else{
      this.checkAndValidate();
    }

  }
  fillData(){
    if(this.transaction){
    this.ssccObject.transactionId=this.transaction.transactionId;
    this.ssccObject.orderId=this.transaction.sourceOrderId;
    this.ssccObject.warehouseId=this.transaction.refWarehouseId;
    this.ssccObject.divisionId=this.transaction.divisionId;
    }else if(this.orderObj){
      this.ssccObject.transactionId='';
      this.ssccObject.orderId=this.orderObj.orderId;
      this.ssccObject.warehouseId=this.orderObj.warehouseId;
      this.ssccObject.divisionId=this.orderObj.divisionId;
    }

  }
  checkAndValidate(){
    this.fillData();
this.coreSession.ModalLoading.Show();
this.ssccGenerateService.addScannedSSCCToTransaction(this.ssccObject.transactionId,this.ssccObject.orderId,this.ssccObject.warehouseId,this.ssccObject.divisionId,this.searchedBarcode)
.subscribe((response: ResponseModel) => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.onScanClickedInDialog.emit(this.searchedBarcode);
        this.searchedBarcode='';
      } else {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), response.message);

      }
    },
    (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
    }
  );

  }
  ngOnDestroy() {
    this.config.backdrop = true;
    this.config.keyboard = true;

  }
  closeDialog(){
  this.searchedBarcode='';
  this.modalService.dismissAll();
  }
  public showDialog() {
    this.config.backdrop="static";
    return this.modalService.open(this.content, { centered: true,backdrop:true }).result.then(
      (result) => {
          return result;
      });
    
  }

}
