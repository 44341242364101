import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { IDateTimePickerProperties } from '../../../models/dateTimeModel/date-time-properties.interface';
import { DateTimeModel } from '../../../models/dateTimeModel/date-time.model';

@Component({
  selector: 'app-main-filter',
  templateUrl: './main-filter.component.html',
  styleUrls: ['./main-filter.component.css']
})
export class MainFilterComponent implements OnInit {
  @ViewChild('content', { static: true }) content: any;
  resetForms = false;
  @Input() isWarehouseOperation = false;
  @Input('viewVehicle') viewVehicle = false;
  @Input('viewWarehouse') viewWarehouse = false;
  @Input('viewEmployee') viewEmployee = false;
  @Input('viewId') viewId = false;
  @Input('viewCustomer') viewCustomer = false;
  @Input('viewDate') viewDate = false;
  @Input('idName') idName = '';
  @Input('isCustomerMandatory') isCustomerMandatory = false;
  @Input('enableSelectAllOutlets') enableSelectAllOutlets = false;
  
  allFilters: any = {};
  filterForm: FormGroup;

  vehicleId: string;
  warehouseId: string;
  employeeId: string;
  transactionId: string;
  customerId: number;
  outletId: number;
  transactionDate: DateTimeModel;

  transactionDateProperties: IDateTimePickerProperties = {
    label: 'Desc_Transaction_Date',
    formControlName: 'transactionDate',
    isCalendarOnly: true
  };

  constructor(
    config: NgbModalConfig
    , private modalService: NgbModal) {
    config.backdrop = true;
  }
  ngOnInit() {
    this.filterForm = new FormGroup({
      'allData': new FormGroup({
        transactionId: new FormControl(this.transactionId),
        vehicleId: new FormControl(this.vehicleId),
        employeeId: new FormControl(this.employeeId),
        warehouseId: new FormControl(this.warehouseId),
        transactionDate: new FormControl(this.transactionDate),
        customerId: new FormControl(this.customerId, this.isCustomerMandatory ? Validators.required : null),
        outletId: new FormControl(this.outletId, this.isCustomerMandatory ? Validators.required : null)
      })
    });
  }

  public Show() {
    if(this.isCustomerMandatory){
      this.filterForm.get('allData').get('customerId').setValidators(Validators.required);
      this.filterForm.get('allData').get('outletId').setValidators(Validators.required);
      (this.filterForm);
    }
    
    return this.modalService
      .open(this.content, {
        centered: true,
        size: 'lg'
      })
      .result.then(
        result => {
          this.allFilters = {
            vehicleId: this.filterForm.value.allData.vehicleId === null || this.filterForm.value.allData.vehicleId === ''? -1 : this.vehicleId,
            warehouseId: this.filterForm.value.allData.warehouseId === null || this.filterForm.value.allData.warehouseId === '' ? -1 : this.filterForm.value.allData.warehouseId,
            employeeId: this.filterForm.value.allData.employeeId === null || this.filterForm.value.allData.employeeId === '' ? -1 : this.filterForm.value.allData.employeeId,
            transactionId: this.filterForm.value.allData.transactionId === null ? '' : this.filterForm.value.allData.transactionId,
            customerId: this.filterForm.value.allData.customerId === null || this.filterForm.value.allData.customerId === '' ? -1 : this.filterForm.value.allData.customerId,
            outletId: this.filterForm.value.allData.outletId === null || this.filterForm.value.allData.outletId === '' ? -1 : this.filterForm.value.allData.outletId,
            transactionDate: this.transactionDate,
          };
          return this.allFilters;
        },
        reason => {
          return DialogResult.None;
        }
      );
  }
  onReset() {
    this.resetForms = true;
    this.allFilters = {};
    this.transactionDate = null;
    this.filterForm.reset();
  }

  onTransactionDateSelected(date) {
    this.transactionDate = date;
  }
}
