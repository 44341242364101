import { Directive, OnInit, ElementRef, Renderer2, HostListener, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[appEffects]'
})
export class EffectsDirective implements OnInit {

 
@Input() defaultColor:string='transparent';
@Input() highlightColor:string='gainsboro';
@HostBinding('style.backgroundColor') backgroundcolor:string=this.defaultColor;
@HostBinding('style.cursor') cursor:string='';
  constructor(private elRef:ElementRef,private renderer:Renderer2 ) { }
ngOnInit(){
 // this.renderer.setStyle(this.elRef.nativeElement,'background-color',(true?this.backgroundcolor:this.defaultColor));
 // this.renderer.setStyle(this.elRef.nativeElement,'cursor',(true?'pointer':''));
}
@HostListener('mouseenter') onmouseover(event:Event){
  //this.renderer.setStyle(this.elRef.nativeElement,'backgroundColor',(true?'blue':'red'));
  this.backgroundcolor=this.highlightColor;
  this.cursor='pointer'
}
@HostListener('mouseleave') onmouseleave(event:Event){
//  this.renderer.setStyle(this.elRef.nativeElement,'backgroundColor','transparent');
this.backgroundcolor=this.defaultColor;
}

}
