import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CoreSession } from '../../../../core/core.session';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { ItemService } from '../../../services/data-definition/product/item.service';
import * as cloneDeep from "lodash/cloneDeep";
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';

@Component({
  selector: 'app-select-items',
  templateUrl: './select-items.component.html',
  styleUrls: ['./select-items.component.css']
})


export class SelectItemsComponent implements OnInit {

  @ViewChild('selectItemsDialog', { static: true }) selectItemsDialog: any;
  OkResult: DialogResult = DialogResult.Ok;

  @Input() currentId = 'customerSelection';
  @Input() addedItemsList: any[] = [];
  @Input() addedItemsListWithDelete: any[] = [];
  @Input() excludedItemIdsList: any[] = [];
  @Input() isFillPacks: false;
  @Input() itemGroupMode: false;
  @Input() removeAddedItems: false;
  @Input() deletedItemLists: any[] = []
  @Input() customerGroupId = -1;
  @Input() selectedEmployeeId: number = -1;
  @Input() isContractedFoc: boolean = false;
  @Input() isPackGroup: boolean = false;
  @Input('DivisionId') divisionId = -1;
  selectedOraganizationIds = '';
  labelItems = "";
  controlTransactionOnDivisionLevel = null;
  query;


  public itemFilterForm: FormGroup;

  sharedTabelData: ITableProperties = {
    pageSize: 50,
    showPaginator: true,
    isOnline: true,
    showSearch: true,
    // showSearchBtn: true,
    isMultiSelection: true,
    showEditButton: false,
    rowOperations: [],
    multiSelectionOperations: [],
    specificActionWhenCheckAll: true,
    specificActionWhenCheckRow: true,
    columns: [

    ]
  };
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  itemFilter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    divisionId: this.divisionId,
    itemCategoryId: -1,
    brandId: -1,
    packTypeId: -1,
    itemIdsList: [],
    excludedItemIdsList: [],
    itemGroupMode: false,
    selectedEmployeeId: -1,
    isContractedFoc: false
  };
  refreshTable = false;
  constructor(
    private coreSession: CoreSession,
    private itemService: ItemService,
    private translateService: TranslateService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private sessionData: SessionDataProvider) {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }
  ngOnInit() {
    this.initFilterForm();

    if (this.isFillPacks) {
      this.sharedTabelData.columns.push({ title: 'Desc_Packs', key: 'itemCodeName', isSortable: true, width: '90%' })
      this.labelItems = 'Desc_Select_Pack';
    } else {
      this.sharedTabelData.columns.push({ title: 'Desc_Items', key: 'itemCodeName', isSortable: true, width: '90%' })
      this.labelItems = 'Desc_Select_Item';
    }
    if (this.isContractedFoc) {
      this.sharedTabelData.columns[0] = ({ title: 'Desc_Packs', key: 'itemCodeName', isSortable: true, width: '50%' })
      this.sharedTabelData.columns.push({
        title: 'Desc_Quantity',
        key: 'requiredQty',
        isSortable: true,
        width: '35%',
        isEditable: true,
        disableInputBasedOnProperty: true,
        disableInputPropertyName: 'disableValuesUsed',
        numberOfStockDigits: true
      })
      this.labelItems = 'Desc_Select_Item';
      this.controlTransactionOnDivisionLevel = this.sessionData.getConfigurationValue(ConstantConfigurations.ControlTransactionOnDivisionLevel).toLowerCase() === "true"
    }
  }
  ngOnDestroy() {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }

  initFilterForm() {
    this.itemFilterForm = new FormGroup({
      'allData': new FormGroup({
        divisionId: new FormControl(),
        itemCategoryId: new FormControl(),
        brandId: new FormControl(),
        packTypeId: new FormControl(),
      })
    });
  }

  reflectAddedBeforeItems(dataSource) {
    if (dataSource && dataSource.data && dataSource.data.length > 0) {
      dataSource.data.forEach(newItem => {
        if (this.addedItemsList && this.addedItemsList.length > 0) {
          var index = this.addedItemsList.findIndex(addedItems => addedItems.itemsId === newItem.itemsId && addedItems.itemCodeName === newItem.itemCodeName);
          if (index >= 0) {
            newItem.isChecked = true;
            if (!this.isContractedFoc) {
              newItem = this.addedItemsList[index];
            }
            else {
              newItem.disableValuesUsed = false;
              newItem.requiredQty = +this.addedItemsList[index].requiredQty
            }
          }
        }
      })
    }

    // if (this.removeAddedItems && this.deletedItemLists != undefined && this.deletedItemLists.length > 0) {
    //   this.deletedItemLists.forEach(item => {
    //     dataSource.data.push(item)
    //     dataSource.totalItems = dataSource.totalItems + 1;
    //   })
    // }
    this.dataSource = {
      totalItems: dataSource.totalItems,
      data: dataSource.data
    }
  }
  onValueChanges(event: any) {
    if (this.isContractedFoc) {
      let changedValue = event.object;
      let requiredQty = +changedValue.requiredQty
      if (!this.addedItemsList) { this.addedItemsList = []; }
      let index = this.addedItemsList.findIndex(a => a.packId === changedValue.packId);
      if (index > -1) {
        this.addedItemsList[index].requiredQty = requiredQty;
      } else {
        this.addedItemsList[index].requiredQty = requiredQty;
      }
    }
  }
  get itemdivisionInput() {
    return this.itemFilterForm.get('allData').get('divisionId');
  }
  get itemCategoryInput() {
    return this.itemFilterForm.get('allData').get('itemCategoryId');
  }
  get itemBrandInput() {
    return this.itemFilterForm.get('allData').get('brandId');
  }
  get itemPackTypeInput() {
    return this.itemFilterForm.get('allData').get('packTypeId');
  }

  onFindCustomers() {
    this.populateItems(null);
  }
  onClearFilters() {
    this.itemFilterForm.reset();
  }
  onCheckRowOperation(itemCode) {
    let index = -1;
    if (this.isFillPacks) {
      index = this.addedItemsList.findIndex(x => x.packId === itemCode.packId);
    } else {
      index = this.addedItemsList.findIndex(x => x.itemId === itemCode.itemId);
    }


    if (itemCode.isChecked) {
      if (index < 0) {
        this.addedItemsList.push(itemCode);
        itemCode.disableValuesUsed = false;
      }
    } else {
      if (index >= 0) {
        itemCode.disableValuesUsed = true;
        this.addedItemsList.splice(index, 1);
      }
    }
  }

  onCheckAllOperation(checkAll: boolean) {
    let index = -1;
    this.dataSource.data.forEach(item => {
      if (this.isFillPacks) {
        index = this.addedItemsList.findIndex(x => x.packId === item.packId);
      } else {
        index = this.addedItemsList.findIndex(x => x.itemId === item.itemId);
      }
      if (index >= 0 && !checkAll) {
        item.disableValuesUsed = true;
        this.addedItemsList.splice(index, 1);
      } else if (index < 0 && checkAll) {
        item.disableValuesUsed = false;
        this.addedItemsList.push(item);
      }
    })
  }

  onTableFilterChanged(query: any) {
    this.itemFilter.customListFilter = query;
    this.query = query;
    this.populateItems(null);
  }

  public showDialog(selectedItems: any[], packTypeId: number = -1) {
    //this.selectedOraganizationIds = organizationIds;
    this.coreSession.SetTitle('Select Items');
    this.itemFilter.customListFilter = {
      searchFilter: '',
      page: 0,
      pageSize: this.sharedTabelData.pageSize
    };
    this.itemFilterForm.reset();
    this.itemPackTypeInput.setValue(packTypeId && packTypeId != -1 ? packTypeId : null);
    this.populateItems(selectedItems);
    return this.modalService.open(this.selectItemsDialog, { centered: true, size: 'lg' }).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          return this.addedItemsList;
        } else {
          return -1;
        }
      });
  }

  populateItems(selectedItems: any[]) {
    if (this.isContractedFoc && this.controlTransactionOnDivisionLevel) {
      this.itemFilter.divisionId = this.divisionId != null ? this.divisionId : -1;
    } else {
      this.itemFilter.divisionId = this.itemdivisionInput.value != null ? this.itemdivisionInput.value : -1;
    }
    this.itemFilter.itemCategoryId = this.itemCategoryInput.value != null ? this.itemCategoryInput.value : -1;
    this.itemFilter.brandId = this.itemBrandInput.value != null ? this.itemBrandInput.value : -1;
    this.itemFilter.packTypeId = this.itemPackTypeInput.value != null ? this.itemPackTypeInput.value : -1;
    this.itemFilter.itemIdsList = selectedItems && selectedItems.length > 0 ? selectedItems : [];
    this.itemFilter.itemGroupMode = this.itemGroupMode;
    this.itemFilter.selectedEmployeeId = this.selectedEmployeeId;
    this.itemFilter.excludedItemIdsList = this.excludedItemIdsList;
    if (this.isContractedFoc) {
      this.itemFilter.isContractedFoc = true;
    }
    this.coreSession.ModalLoading.Show();
    if (!this.isFillPacks) {
      this.itemService.getItemsListSharedTable(this.itemFilter).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.dataSource = {
            totalItems: response.data.length,
            data: response.data
          }
          this.reflectAddedBeforeItems(response.data);
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      });
    } else {
      this.itemService.getAllItemsLazyLoading(this.itemFilter).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.dataSource = {
            totalItems: response.data.length,
            data: response.data
          }
          this.reflectAddedBeforeItems(response.data);
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      });
    }
  }
  public closeSelectItemsDialog() {
    this.modalService.dismissAll()
  }


}
