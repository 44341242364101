import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SharedTableResult } from "../../../models/shared-table/shared-table-result.interface";
import { ITableProperties } from "../../../models/shared-table/table-properties.interface";
import { CoreSession } from "../../../../core/core.session";
import { RowOperation } from "../../../models/enums/shared-table-operation.enum";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { DialogMode } from "../../../models/enums/dialog-mode.enum";
import { DialogResult } from "../../../models/enums/dialog-result.enum";
import { SSCCGenerationService } from "../sscc-generation.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject } from "rxjs";
import { WarehouseTransaction } from "../../../models/warehouse-transactions/warehouse-transaction.model";
import { ItemPackModel } from "../../../models/Item/item.model";
import * as cloneDeep from "lodash/cloneDeep";
import { WarehouseTransactionStatus } from "../../../models/enums/warehouse-transaction-status.enum";
import { ScanBarcodeComponent } from "../scan-barcode/scan-barcode.component";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import { warehouseFilter } from "../../../models/shared-table/custom-list.interface";
import { SSCCStatuses } from "../../../models/enums/sscc-statuses-enum";
import { IOperationSummary } from "../../../models/operation-summary/operation-summary-interface";
import { FormProcessMode } from "../../../models/enums/form-process-mode.enum";
import { ConstantURLs } from "../../../models/constants/constant-URL";
import { MenuActions } from "../../../models/enums/menu-actions-enum";
import { OrderOperation } from "../../../models/enums/order-operation .enum";
import { OrderStatus } from "../../../models/enums/order-status.enum";
import { OrderService } from "../../../../content/order/order.service";
import { InvalidSerialsComponent } from "../../Serials/invalid-serials/invalid-serials.component";
import { SalesTransactionTypes } from "../../../models/enums/sales-transaction-types";
import { ResponseModel } from "../../../models/api-models/api-models";

@Component({
  selector: "app-sscc-list",
  templateUrl: "./sscc-list.component.html",
  styleUrls: ["./sscc-list.component.scss"],
})
export class SSCCListComponent implements OnInit {
  @Input() orderId = "";
  @Input() transactionId = "";
  @Input() disable = false;
  @Input() remarks = "";
  @Input() fromMainSSCCList = false;
  @Input() isFromDelivery = false;
  @Input() isFromPartialDelivery = false;
  @Input() showTax = false;
  @Input() showRetailTax = false;
  @Input() orderObj: any;
  @Output("OnSaveCompleted") saveCompleted = new EventEmitter();
  saveSubject: Subject<void> = new Subject<void>();
  @Input() warehouseTransaction: WarehouseTransaction;
  @ViewChild("scanBarcodeDialog", { static: true })
  scanBarcodeDialog: ScanBarcodeComponent;
  invalidSerials = [];
  @ViewChild("invalidSerialsDialog", { static: true })
  invalidSerialsDialog: InvalidSerialsComponent;
  viewSSCCDetailsEntry = false;
  viewSSCCDetailsSummary = false;
  ssccObject: any = {};
  ssccTotals = [];
  transactionItems = [];
  allQuantityPacked = false;
  ssccVerifiedBarcodes = [];
  verifyBarcode = false;
  saveBtnCaption = "Desc_Vehilce_Execute";
  ssccDataSource: SharedTableResult = {
    totalItems: 0,
    data: [],
  };
  isEditMode = false;
  isAddBtnAvailable: boolean = false;
  isEditBtnAvailable: boolean = false;
  isDeleteBtnAvailable: boolean = false;
  isRemoveAssignmentBtnAvailable: boolean = false;
  isLoading = false;
  operationSummaryData: IOperationSummary = {
    operationData: {},
    formProcessMode: FormProcessMode.SSCCFromMainList,
    formTitle: "",
  };
  ssccTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: true,
    showSearchBtn: false,
    isMultiSelection: false,
    showNewBtn: true,
    // showExtraBtn:!this.fromMainSSCCList,
    extraBtnIcon: "fa fa-barcode",
    extraBtnCaption: "Desc_Scan",
    newBtnCaption: "Desc_Generate",
    rowOperations: [],
    multiSelectionOperations: [],

    columns: [
      {
        title: "Desc_Verified",
        key: "verified",
        isSortable: false,
        isIcon: true,
        iconColor: "#28a745",
        width: "5%",
      },
      {
        title: "Desc_SSCC_Barcode",
        key: "ssccBarcode",
        isSortable: false,
        width: "45%",
      },
      {
        title: "Desc_Employee",
        key: "employeeCodeName",
        isSortable: true,
        width: "30%",
      },
      {
        title: "Desc_Date",
        key: "createdDate",
        isSortable: true,
        width: "20%",
        isDate: true,
      },
    ],
  };
  query: warehouseFilter = {
    customListFilter: {
      searchFilter: "",
      page: 0,
      pageSize: this.coreSession.pageSize,
    },
    employeeId: -1,
    refWarehouseId: -1,
    warehouseId: -1,
    processMode: FormProcessMode.SSCCFromMainList,
    transactionDate: null,
    transactionDateRange: null,
  };
  constructor(
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private ssccGeneratingService: SSCCGenerationService,
    private sessionData: SessionDataProvider,
    private orderService: OrderService
  ) {}

  ngOnInit() {
    if (
      this.coreSession.checkActivitiesAvailability(
        ConstantURLs.ssccGenerationURL,
        MenuActions.Add
      )
    ) {
      this.isAddBtnAvailable = true;
    }
    if (
      this.coreSession.checkActivitiesAvailability(
        ConstantURLs.ssccGenerationURL,
        MenuActions.Edit
      )
    ) {
      this.isEditBtnAvailable = true;
    }
    if (
      this.coreSession.checkActivitiesAvailability(
        ConstantURLs.ssccGenerationURL,
        MenuActions.Delete
      )
    ) {
      this.isDeleteBtnAvailable = true;
    }
    if (
      this.coreSession.checkActivitiesAvailability(
        ConstantURLs.ssccGenerationURL,
        MenuActions.RemoveAssignment
      )
    ) {
      this.isRemoveAssignmentBtnAvailable = true;
    }
    if ((this.isFromDelivery || this.isFromPartialDelivery) && this.orderObj) {
      this.transactionItems = this.orderObj.allItems;
    } else if (this.warehouseTransaction) {
      this.transactionItems = this.warehouseTransaction.addedItems;
    }
    this.setListProperties();

    if (this.isFromDelivery || this.isFromPartialDelivery) {
      this.saveBtnCaption = "Desc_Delivery";
    }
    this.populateSSCCList();
  }
  setListProperties() {
    if (!this.disable) {
      if (this.isEditBtnAvailable) {
        this.ssccTableData.rowOperations.push({
          operation: RowOperation.edit,
          title: "Desc_Edit",
          icon: "fa fa-pencil",
          color: "#12344d",
        });
      } else {
        this.ssccTableData.rowOperations.push({
          operation: RowOperation.View,
          title: "Desc_View",
          icon: "fa fa-eye",
          color: "#12344d",
        });
      }
      if (this.isRemoveAssignmentBtnAvailable) {
        this.ssccTableData.rowOperations.push({
          operation: RowOperation.Deactivate,
          title: "Desc_Remove_Assignment",
          icon: "fa fa-close",
          color: "#f1685e",
        });
      }
    }
    if (this.fromMainSSCCList) {
      if (this.isDeleteBtnAvailable) {
        this.ssccTableData.rowOperations.push({
          operation: RowOperation.delete,
          title: "Desc_Delete",
          icon: "fa fa-close",
          color: "#f1685e",
        });
      }
    }
    //this.ssccTableData.showExtraBtn=!this.fromMainSSCCList && this.isAddBtnAvailable;
    this.ssccTableData.showNewBtn = this.isAddBtnAvailable;
    this.ssccTableData.showSearchBtn = this.fromMainSSCCList;
    this.ssccTableData.clearSearchBoxAfterFinish = !this.fromMainSSCCList;
    if (!this.fromMainSSCCList) {
      this.ssccTableData.SearchBoxLabel = "Desc_Scan_SSCC_Barcode";
    }
    if (this.fromMainSSCCList) {
      this.ssccTableData.columns = [
        {
          title: "Desc_SSCC_Barcode",
          key: "ssccBarcode",
          isSortable: false,
          width: "25%",
        },
        {
          title: "Desc_Employee",
          key: "employeeCodeName",
          isSortable: true,
          width: "15%",
        },
        {
          title: "Desc_Transaction_ID",
          key: "transactionId",
          isSortable: true,
          width: "15%",
        },
        {
          title: "Desc_Order_ID",
          key: "orderId",
          isSortable: true,
          width: "15%",
        },
        {
          title: "Desc_Warehouse",
          key: "warehouseCodeName",
          isSortable: true,
          width: "15%",
        },
        {
          title: "Desc_Date",
          key: "createdDate",
          isSortable: true,
          width: "15%",
          isDate: true,
        },
      ];
    }
  }
  isDivisionLevel() {
    return (
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.ControlTransactionOnDivisionLevel
        )
        .toLowerCase() === "true" &&
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.ShowDivisionSelectionOnWHTransaction
        )
        .toLowerCase() === "true"
    );
  }
  onRowClicked(event: any) {
    switch (event.operation) {
      case RowOperation.Deactivate:
        this.cancelSSCC(event.object);
        break;
      case RowOperation.delete:
        if (this.isValidToDelete(event.object)) {
          this.coreSession.ModalDialog.ShowMessage(
            this.translateService.instant(
              ConstantMessages.MsgDeleteConfirmation
            ),
            DialogMode.YesNo,
            this.translateService.instant(ConstantMessages.WarningCaption)
          ).then((res: DialogResult) => {
            if (res === DialogResult.Yes) {
              this.deleteSSCC(event.object);
            }
          });
        }
        break;
      case RowOperation.edit:
        this.openEditOrViewSSCC(event.object);
        break;
      case RowOperation.rowClick:
        this.openEditOrViewSSCC(event.object);

        break;
    }
  }
  isValidToDelete(ssccObject) {
    if (ssccObject.transactionId !== null && ssccObject.transactionId !== "") {
      this.coreSession.showError(
        this.translateService.instant(ConstantMessages.ErrorCaption),
        this.translateService.instant(
          ConstantMessages.Desc_SSCC_Linked_To_Transaction
        )
      );
      return false;
    } else if (ssccObject.orderId !== null && ssccObject.orderId !== "") {
      this.coreSession.showError(
        this.translateService.instant(ConstantMessages.ErrorCaption),
        this.translateService.instant(
          ConstantMessages.Desc_SSCC_Linked_To_Order
        )
      );
      return false;
    } else {
      return true;
    }
  }
  openEditOrViewSSCC(ssccObject) {
    if (
      ssccObject.ssccStatus === SSCCStatuses.Active &&
      !this.disable &&
      this.isEditBtnAvailable
    ) {
      this.getSSCCDetails(ssccObject, true);
    } else {
      this.getSSCCDetails(ssccObject, false);
    }
  }
  setOriginalValues() {
    if (this.transactionItems) {
      this.transactionItems.forEach((pack) => {
        if (this.ssccTotals && this.ssccTotals.length > 0) {
          let packIndex = this.ssccTotals.findIndex(
            (whPack) =>
              whPack.packId === pack.packId &&
              whPack.salesTransactionTypeId === pack.salesTransactionTypeId
          );
          if (packIndex != -1) {
            pack.originalQuantity =
              pack.requiredQty - this.ssccTotals[packIndex].requiredQty;
          } else {
            pack.originalQuantity = pack.requiredQty;
          }
        } else {
          pack.originalQuantity = pack.requiredQty;
        }
      });
    }
  }
  closeViewSummarySlider() {
    this.operationSummaryData = null;
    this.ssccObject = null;
    this.viewSSCCDetailsSummary = false;
  }
  // onCloseScanBarcodeDialog(barcode){
  //   this.addToVerifiedBarcodes(barcode,false);
  //   this.populateSSCCList();
  // }
  onTableFilterChanged(query: any) {
    if (this.fromMainSSCCList) {
      this.query.customListFilter = query;
      this.populateSSCCList();
    } else if (query.searchFilter && query.searchFilter !== "") {
      this.onSearchBarcodeClicked(query.searchFilter);
    }
  }
  fillData() {
    if (this.warehouseTransaction) {
      this.ssccObject.transactionId = this.warehouseTransaction.transactionId;
      this.ssccObject.orderId = this.warehouseTransaction.sourceOrderId;
      this.ssccObject.warehouseId = this.warehouseTransaction.refWarehouseId;
      this.ssccObject.divisionId = this.warehouseTransaction.divisionId;
    } else if (this.orderObj) {
      this.ssccObject.transactionId = "";
      this.ssccObject.orderId = this.orderObj.orderId;
      this.ssccObject.warehouseId = this.orderObj.warehouseId;
      this.ssccObject.divisionId = this.orderObj.divisionId;
    }
  }
  checkAndValidateToAddBarcode(searchedBarcode) {
    this.fillData();
    this.coreSession.ModalLoading.Show();
    this.ssccGeneratingService
      .addScannedSSCCToTransaction(
        this.ssccObject.transactionId,
        this.ssccObject.orderId,
        this.ssccObject.warehouseId,
        this.ssccObject.divisionId,
        searchedBarcode
      )
      .subscribe(
        (response: ResponseModel) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.populateSSCCList();
            this.addToVerifiedBarcodes(searchedBarcode, false);
          } else {
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }

  populateSSCCList() {
    this.isLoading = true;
    this.coreSession.ModalLoading.Show();
    this.ssccGeneratingService
      .getSSCCList(this.orderId, this.transactionId, this.query)
      .subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            if (response.data.ssccList) {
              this.ssccDataSource = response.data.ssccList;
              this.ssccTotals = response.data.ssccTotals;
              this.setVerifiedBarcodes();
            }
            this.setOriginalValues();
            this.isLoading = false;
          } else {
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  onSaveClicked() {
    if (!this.CheckIfAllQuantityPacked()) {
      if (!this.allQuantityPacked) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(
            ConstantMessages.Desc_Not_All_Quantity_Packed_In_SSCC
          )
        );
      }
      return;
    }
    this.verifySSCCBarcodes();
    //this.ExecuteOrDeliverData();
  }
  verifySSCCBarcodes() {
    if (
      this.ssccVerifiedBarcodes &&
      this.ssccVerifiedBarcodes.length === this.ssccDataSource.totalItems
    ) {
      this.ExecuteOrDeliverData();
    } else {
      //you need to verify barcode
      this.verifyBarcode = true;
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(
          ConstantMessages.Desc_You_Must_Verify_All_Barcodes
        )
      );
    }
  }
  ExecuteOrDeliverData() {
    if (!this.CheckIfAllQuantityPacked()) {
      if (!this.allQuantityPacked) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(
            ConstantMessages.Desc_Not_All_Quantity_Packed_In_SSCC
          )
        );
      }
      return;
    }
    if (this.isFromPartialDelivery || this.isFromDelivery) {
      this.coreSession.ModalDialog.ShowMessage(
        this.translateService.instant(ConstantMessages.DeliveryConfirmation),
        DialogMode.YesNo,
        this.translateService.instant(ConstantMessages.Delivery)
      ).then((result: DialogResult) => {
        if (result === DialogResult.Yes) {
          if (this.isFromPartialDelivery) {
            this.isOrderValidToInvoice();
          } else {
            this.DeliverOrder();
          }
        }
      });
    } else {
      this.coreSession.ModalDialog.ShowMessage(
        this.translateService.instant(
          ConstantMessages.Desc_Confirmation_Execute
        ),
        DialogMode.YesNo,
        this.translateService.instant(ConstantMessages.Desc_Vehilce_Execute)
      ).then((result: DialogResult) => {
        if (result === DialogResult.Yes) {
          this.executeTransaction();
        }
      });
    }
  }
  DeliverOrder() {
    //this.orderObj.notes=this.remarks;
    if (this.isFromDelivery)
      this.orderObj.orderOperation = OrderOperation.FullDelivery;
    else this.orderObj.orderOperation = OrderOperation.PartialDelivery;

    this.coreSession.ModalLoading.Show();
    this.ssccGeneratingService
      .deliverOrderAfterGenerateSSCC(this.orderObj)
      .subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.coreSession.showSuccess(
              this.translateService.instant(ConstantMessages.SuccessCaption),
              this.translateService.instant(
                ConstantMessages.MsgSavedSuccessfuly
              )
            );
            this.saveCompleted.emit();
          } else {
            if (response.data != null && response.data.length > 0) {
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.ErrorCaption),
                response.message
              );
              this.invalidSerials = response.data;
              this.invalidSerialsDialog.showDialog().then((result) => {});
            } else
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.ErrorCaption),
                response.message
              );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  isOrderValidToInvoice() {
    this.coreSession.ModalLoading.Show();
    this.orderObj.orderStatus = OrderStatus.Delivered;
    this.orderObj.orderOperation = OrderOperation.PartialDelivery;
    this.orderService.isOrderValidToInvoice(this.orderObj).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.orderObj = response.data;
          this.DeliverOrder();
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }

  onFindButtonClicked(result) {
    this.query.customListFilter.page = 0;
    this.query.warehouseId = result.warehouseId;
    this.query.transactionDateRange = result.transactionDate;
    this.query.employeeId = result.employeeId;
    this.query.transactionId = result.transactionId;
    if (this.isDivisionLevel()) this.query.divisionId = result.divisionId;
    else this.query.divisionId = -1;
    this.populateSSCCList();
  }
  executeTransaction() {
    this.warehouseTransaction.whTransactionStatusId = WarehouseTransactionStatus.Executed.valueOf();
    this.warehouseTransaction.notes = this.remarks;

    this.warehouseTransaction.isNewTransaction = false;
    this.coreSession.ModalLoading.Show();
    this.ssccGeneratingService
      .saveWarehouseOperationAfterGenerateSSCC(this.warehouseTransaction)
      .subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.coreSession.showSuccess(
              this.translateService.instant(ConstantMessages.SuccessCaption),
              this.translateService.instant(
                ConstantMessages.MsgSavedSuccessfuly
              )
            );
            // this.updateOriginalQuantity();
            this.saveCompleted.emit();
          } else {
            if (response.data != null && response.data.length > 0) {
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.ErrorCaption),
                response.message
              );
              this.invalidSerials = response.data;
              this.invalidSerialsDialog.showDialog().then((result) => {});
            } else
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.ErrorCaption),
                response.message
              );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  CheckIfAllQuantityPacked() {
    if (this.fromMainSSCCList) {
      valid = true;
      this.allQuantityPacked = false;
    } else {
      var valid = true;
      let index = this.transactionItems.findIndex(
        (pack) => pack.originalQuantity > 0
      );
      if (index != -1) {
        this.allQuantityPacked = false;
        valid = false;
      } else {
        this.allQuantityPacked = true;
      }
    }

    return valid;
  }
  onNewBtnClicked() {
    this.CheckIfAllQuantityPacked();
    if (!this.allQuantityPacked) {
      this.fillSSCCAllItems();
      this.viewSSCCDetailsEntry = true;
    } else {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(
          ConstantMessages.Desc_All_Quantity_Packed_In_SSCC
        )
      );
    }
  }
  onScanBtnClicked() {
    this.CheckIfAllQuantityPacked();
    if (this.allQuantityPacked) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(
          ConstantMessages.Desc_All_Quantity_Packed_In_SSCC
        )
      );
      return;
    }
    this.verifyBarcode = false;
    this.scanBarcodeDialog.showDialog().then((result) => {});
  }
  cancelSSCC(selectedSSCCObject: any) {
    let formProcessMode: FormProcessMode;
    if (selectedSSCCObject.ssccStatus === SSCCStatuses.Posted) {
      this.coreSession.showError(
        this.translateService.instant(ConstantMessages.ErrorCaption),
        this.translateService.instant(ConstantMessages.Desc_Invalid_Operation)
      );
      return;
    }
    if (
      selectedSSCCObject.transactionId &&
      selectedSSCCObject.transactionId !== ""
    ) {
      formProcessMode = FormProcessMode.LoadRequest;
    } else if (
      selectedSSCCObject.orderId &&
      selectedSSCCObject.orderId !== ""
    ) {
      formProcessMode = FormProcessMode.Delivery;
    } else {
      this.coreSession.showError(
        this.translateService.instant(ConstantMessages.ErrorCaption),
        this.translateService.instant(ConstantMessages.Desc_Invalid_Operation)
      );
      return;
    }
    this.coreSession.ModalDialog.ShowMessage(
      this.translateService.instant(
        ConstantMessages.Desc_Confirmation_Remove_Barcode_Assignment
      ),
      DialogMode.YesNo,
      this.translateService.instant(ConstantMessages.WarningCaption)
    ).then((res: DialogResult) => {
      if (res === DialogResult.Yes) {
        this.ssccGeneratingService
          .cancelSSCC(selectedSSCCObject.ssccBarcode, formProcessMode)
          .subscribe(
            (response) => {
              this.coreSession.ModalLoading.Hide();
              if (response.status != null && response.status >= 0) {
                // this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), response.message);
                this.populateSSCCList();
                this.removeFromVerifiedBarcodes(selectedSSCCObject.ssccBarcode);
              } else {
                this.coreSession.showError(
                  this.translateService.instant(ConstantMessages.ErrorCaption),
                  response.message
                );
              }
            },
            (error: HttpErrorResponse) => {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.ErrorCaption),
                this.translateService.instant(ConstantMessages.ErrorHappened)
              );
            }
          );
      }
    });
  }
  deleteSSCC(selectedSSCCObject: any) {
    this.ssccGeneratingService
      .deleteSSCC(selectedSSCCObject.ssccBarcode)
      .subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.coreSession.showSuccess(
              this.translateService.instant(ConstantMessages.SuccessCaption),
              this.translateService.instant(
                ConstantMessages.MsgDeletedSuccessfully
              )
            );
            this.populateSSCCList();
          } else {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  getSSCCDetails(selectedSSCCObject: any, showEntrySlider: boolean) {

    this.isLoading = true;
    var processMode = FormProcessMode.LoadRequest;
    if (
      (!selectedSSCCObject.transactionId ||
        selectedSSCCObject.transactionId === "") &&
      (!selectedSSCCObject.orderId || selectedSSCCObject.orderId === "")
    ) {
      processMode = FormProcessMode.OutputVoucher;
    } else if (
      (!selectedSSCCObject.transactionId ||
        selectedSSCCObject.transactionId === "") &&
      selectedSSCCObject.orderId !== ""
    ) {
      processMode = FormProcessMode.Delivery;
    }
    this.coreSession.ModalLoading.Show();
    this.ssccGeneratingService
      .getSSCCDetails(
        selectedSSCCObject.ssccBarcode,
        processMode,
        showEntrySlider
      )
      .subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.ssccObject = selectedSSCCObject;
            this.ssccObject.details = cloneDeep(response.data);
            if (showEntrySlider) {
              this.fillSSCCAllItems();
              this.isEditMode = true;
              this.viewSSCCDetailsEntry = true;
            } else {
              this.operationSummaryData = {
                operationData: this.ssccObject,
                formProcessMode: FormProcessMode.SSCCFromMainList,
                formTitle: "Desc_SSCC_Details",
              };
              this.viewSSCCDetailsSummary = true;
            }

            this.isLoading = false;
          } else {
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  fillSSCCAllItems() {
    var newItem: ItemPackModel;
    if (this.transactionItems) {
      this.transactionItems.forEach((item) => {
        var index = -1;
        if (this.ssccObject.details)
          index = this.ssccObject.details.findIndex(
            (a) =>
              a.packId === item.packId &&
              a.batchNo === item.batchNo &&
              a.expiryDateModel.year === item.expiryDateModel.year &&
              a.expiryDateModel.month === item.expiryDateModel.month &&
              a.expiryDateModel.day === item.expiryDateModel.day &&
              a.salesTransactionTypeId === item.salesTransactionTypeId
          );
        else this.ssccObject.details = [];

        if (index === -1) {
          newItem = cloneDeep(item);
          newItem.requiredQty = 0;
          newItem.selectedBatches = [];
          if (!this.isFromDelivery && !this.isFromPartialDelivery) {
            newItem.salesTransactionTypeId = SalesTransactionTypes.None;
          }
          this.ssccObject.details.push(newItem);
        } else {
          if (this.isFromDelivery || this.isFromPartialDelivery) {
            this.ssccObject.details[index].price = item.price;
            this.ssccObject.details[index].tax = item.tax;
            this.ssccObject.details[index].discount = item.discount;
            this.ssccObject.details[index].discountTypeId = item.discountTypeId;
            this.ssccObject.details[index].salesTransactionTypeId =
              item.salesTransactionTypeId;
          } else {
            this.ssccObject.details[index].salesTransactionTypeId =
              SalesTransactionTypes.None;
          }

          // this.ssccObject.details[index].itemStockBatches=cloneDeep(this.ssccObject.details[index].selectedBatches);
        }
      });
      if (this.orderObj || this.warehouseTransaction)
        this.ssccObject.details = this.ssccObject.details.filter(
          (a) => a.originalQuantity > 0
        );
    }
  }
  removeFromVerifiedBarcodes(canceledBarcode) {
    if (!this.ssccVerifiedBarcodes) {
      var index = this.ssccVerifiedBarcodes.findIndex(
        (barcode) => barcode === canceledBarcode
      );
      if (index > -1) {
        this.ssccVerifiedBarcodes.splice(index, 1);
      }
    }
  }
  setVerifiedBarcodes() {
    if (!this.ssccVerifiedBarcodes) this.ssccVerifiedBarcodes = [];
    this.ssccVerifiedBarcodes.forEach((addedBarcode) => {
      let indexInList = this.ssccDataSource.data.findIndex(
        (barcode) => barcode.ssccBarcode === addedBarcode
      );
      if (indexInList > -1) {
        this.ssccDataSource.data[indexInList].verified = "fa fa-check";
      }
    });
  }
  addToVerifiedBarcodes(addedBarcode, showMsgs) {
    if (!this.ssccVerifiedBarcodes) this.ssccVerifiedBarcodes = [];
    var index = this.ssccVerifiedBarcodes.findIndex(
      (barcode) => barcode === addedBarcode
    );
    if (index === -1) {
      this.ssccVerifiedBarcodes.push(addedBarcode);
      this.setVerifiedBarcodes();
      if (showMsgs) {
        this.coreSession.showSuccess(
          this.translateService.instant(ConstantMessages.SuccessCaption),
          this.translateService.instant(ConstantMessages.Desc_Verified)
        );
      }
    } else if (showMsgs) {
      this.coreSession.showError(
        this.translateService.instant(ConstantMessages.ErrorCaption),
        this.translateService.instant(
          ConstantMessages.Desc_Barcode_Already_Verified
        )
      );
    }
  }
  onSearchBarcodeClicked(addedBarcode) {
    if (
      this.ssccDataSource.data.findIndex(
        (sscc) => sscc.ssccBarcode === addedBarcode
      ) === -1
    ) {
      this.checkAndValidateToAddBarcode(addedBarcode);
    } else {
      this.addToVerifiedBarcodes(addedBarcode, true);
    }
  }
  onSaveDetailsComplete(barcode) {
    this.addToVerifiedBarcodes(barcode, false);
    this.closeSlider();
    this.populateSSCCList();
  }
  closeSlider() {
    this.isEditMode = false;
    this.ssccObject = {};
    this.viewSSCCDetailsEntry = false;
  }
}
