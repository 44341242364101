export enum SerialStatus {
    None = -1,
    LoadedInVehicle = 1,
    SoldToCustomer = 2,
    OffloadedToWarehouse = 3,
    ReturnedFromCustomer = 4,
    LoadedToMainWarehouse = 5,
    OffloadedFromMainWarehouse = 6,
    ManuallySold = 7,
    ReturnedFromExchangeRequest = 8,
    TransferredIn = 9,
    TransferredOut = 10,
    InTranset = 11,
    Reassign = 12,
    BackToStore = 13,
    BackToStoreOffload = 14,
    Blocked=15,
    PackageOpened =16,
    MissingSerials=17,
    PackagePartiallyReturned = 18,
    SendToHelloJordan = 19,
    BackToStoreOffloadedFromMainWarehouse = 20,
    BackToStoreRejetced = 21
}
