import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MasterDataService } from '../../../../content/master-data-definition/master-data.service';

@Component({
  selector: 'app-bank-filter',
  templateUrl: './bank-filter.component.html',
  styleUrls: ['./bank-filter.component.css']
})
export class BankFilterComponent implements OnInit {

  @Input() currentFormControlName = 'bankId';
  @Input() hideLabel = false;
  @Input() parentForm: FormGroup;
  @Output() onChangeBank = new EventEmitter();
  banksList: any[];
  isLoading = false;
  isRequired = false;

  constructor(private masterDataService: MasterDataService) {
  }
  ngOnInit() {
    if (this.parentForm.get(this.currentFormControlName).validator != null) {
      const validator = this.parentForm.get(this.currentFormControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateBankssList();
  }

  populateBankssList() {
    this.isLoading = true;
    this.masterDataService.getBanks().subscribe(result => {
      this.banksList = result;
      this.isLoading = false;
    });
  }
  onChangeBankClick(bank) {
    this.onChangeBank.emit(bank);
  }
}

