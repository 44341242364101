import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from '../../../core/core.session';
import { ResponseModel } from '../../models/api-models/api-models';
import { GeneralFilter } from '../../models/general/generalFilter.model';
import { EmployeeKeyFilter } from '../../models/employee-keys/employee-keys';

@Injectable({
  providedIn: 'root'
})
export class EmployeeKeysService {
  private url = '';
  private httpOptions;

  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl + 'EmployeeKeys';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  provideEmployeeKey(filter: EmployeeKeyFilter) {
    const urlValue = this.url + '/ProvideEmployeeKey';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    });
  }

  setKeyToProvided(key) {
    const urlValue = this.url + '/SetKeyToProvided';
    return this.http.post<ResponseModel>(urlValue, key).map(response => {
      return response;
    });
  }
  getValidEmployeeKeys(filter: EmployeeKeyFilter) {
    const urlValue = this.url + '/GetValidEmployeesKeys';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    });
  }

  getUsedKeysList(filter: EmployeeKeyFilter) {
    const urlValue = this.url + '/getUsedKeysList';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    });
  }

  getEmployeeRouteCustomers(filter: EmployeeKeyFilter) {
    const urlValue = this.url + '/GetCustomersForKeys';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    });
  }

  updateKeyToProvided(key) {
    const urlValue = this.url + '/UpdateKeyToProvided';
    return this.http.post<ResponseModel>(urlValue, key).map(response => {
      return response;
    });
  }

  timeOutEmployeeKeyNotifictaion(key) {
    const urlValue = this.url + '/TimeOutEmployeeKeyNotifictaion';
    return this.http.post<ResponseModel>(urlValue, key).map(response => {
      return response;
    });
  }

  rejectKeyToProvided(key) {
    const urlValue = this.url + '/RejectKeyToProvided';
    return this.http.post<ResponseModel>(urlValue, key).map(response => {
      return response;
    });
  }
}
