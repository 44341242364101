import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { StockCountingTaskStatus } from '../../../../models/enums/stock-counting-task-status.enum';
import { StockCountingTaskService } from '../../../../services/warehouse-operation/stock-counting-task.service';

@Component({
  selector: 'app-stock-counting-tasks-status-filter',
  templateUrl: './stock-counting-tasks-status-filter.component.html',
  styleUrls: ['./stock-counting-tasks-status-filter.component.css']
})
export class StockCountingTasksStatusFilterComponent implements OnInit {
  //#region [Declarations]
  stockCountingTasksStatusList: any[] = [];
  isStockCountingTasksStatusLoading: boolean = false;
  selectedStatusId: number = StockCountingTaskStatus.Pending.valueOf();
  @Input() parentForm: FormGroup;
  //#endregion
  //#region [Events]
  constructor(private stockCountingTaskService: StockCountingTaskService) { }

  ngOnInit() {

    this.populateStockCountingTasksStatusList();
  }
  populateStockCountingTasksStatusList() {
    this.isStockCountingTasksStatusLoading = true;
    //warehouse service api -log the returned value!-
    this.stockCountingTaskService.getStockCountingTasksStatus().subscribe(
      (respone: any) => {
        this.stockCountingTasksStatusList = respone;
        this.isStockCountingTasksStatusLoading = false;

      }
    )
  }
  ngOnChanges() {
    this.populateStockCountingTasksStatusList();
  }
}
