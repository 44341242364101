import { EventEmitter } from "@angular/core";
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from "@angular/core";
import { CustomerModel } from "../../../models/customer/customer.model";
import { CustomerOutletModel } from "../../../models/customer/outlet.model";
import { FormGroup, AbstractControl } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { CoreSession } from "../../../../core/core.session";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { DialogMode } from "../../../models/enums/dialog-mode.enum";
import { CustomerService } from "../../../services/data-definition/customer/customers.service";

import {
  switchMap,
  debounceTime,
  distinctUntilChanged,
  distinct,
} from "rxjs/operators";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { GeneralFilter } from "../../../models/general/generalFilter.model";
import { DomSanitizer } from "@angular/platform-browser";
import { DashboardService } from "../../../../content/dashboard/dashboard.service";

@Component({
  selector: "app-customer-filter",
  templateUrl: "./customer-filter.component.html",
  styleUrls: ["./customer-filter.component.css"],
})
export class CustomerFilterComponent implements OnInit, OnChanges, OnDestroy {
  // #regoion [DECLARATIONS]
  @Input() currentCustomerFormControlName = "customerId";
  @Input() currentOutletFormControlName = "outletId";
  @Input() currentCustomerLabel = "Desc_Customer";
  @Input() currentOutletLabel = "Desc_Outlet";
  @Input() parentForm: FormGroup;
  @Input() isEditMode: boolean;
  @Input() customerEditValue: string;
  @Input() outletEditValue: string;
  @Input() enableSelectAllOutlets: boolean = false;
  @Input() viewOutlets: boolean = true;
  @Input() verticalView: boolean = false;
  @Input() emitIdAndCaption = false;
  @Input() territoryId = -1;
  @Input() routeId = -1;
  @Input() filterOnRoute = false;
  @Input() multiSelectOutlets = false;
  @Input("ShowExtraInfo") showExtraInfo = false;
  @Input('showNewCustomerOnly') showNewCustomerOnly = false;
  @Input() emitAllOutlets = false;
  @Input() resetClickedEvent: EventEmitter<boolean>;
  @Input() excludeOnHold: boolean = false;
  @Input() isDefaultCustomer = false;
  @Input() employeeId = -1;
  @Input() organizationId = -1;
  @Input() groupId = -1;
  @Input() resetCustomerDataSource: EventEmitter<number> = null;
  @Input() getChattingCustomers: boolean = false;
  @Input() showCustomersHavePendingInvoices: boolean = false;
  @Output() onChangeCustomer = new EventEmitter();
  @Output() onChangeOutlet = new EventEmitter();
  @Output() onSelectMultiOutlet = new EventEmitter();
  @Output() onDeSelectMultiOutlet = new EventEmitter();
  @Output() onChangeIdCaptionCustomer = new EventEmitter();
  @Output() onChangeIdCaptionOutlet = new EventEmitter();
  @Output() onChangeCustomerId = new EventEmitter();

  resetSubscription: any;
  resetDataSourceSubscription: any;
  customerDataSource: CustomerModel[] = [];
  outletDataSource: CustomerOutletModel[] = [];
  selectedCustomerId: number;
  selectedOutletId: number;
  selectedOutletIds: number;
  isOutletLoading = false;
  isCustomerLoading = false;
  @Input() newCustomerOnly = false;
  extraInfoURL = "";
  bufferSize = 200;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  totalCustomers = 0;
  filter: GeneralFilter = {
    customListFilter: {
      searchFilter: "",
      pageSize: 200,
      page: 0,
    },
    territoryId: -1,
    routeId: -1,
    isFromCollection: false
  };
  input$ = new Subject<string>();
  lblSelectAll = "";
  isCustomerRequired = false;
  isOutletRequired = false;
  selectedDashId = -1;
  multiOutletsDropdownSettings = {
    singleSelection: false,
    idField: "outletId",
    textField: "outletCodeName",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    searchPlaceholderText: this.translateService.instant(
      ConstantMessages.SearchCaption
    ),
    allowSearchFilter: true,
    itemsShowLimit: 2,
  };

  // #endregion

  constructor(
    private customerService: CustomerService,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private dashService: DashboardService
  ) {}

  ngOnChanges() {
    this.selectedCustomerId = this.parentForm.get(
      this.currentCustomerFormControlName
    ).value;
    this.selectedOutletId = this.parentForm.get(
      this.currentOutletFormControlName
    ).value;
    if (this.territoryId != -1 || this.routeId != -1) {
      this.parentForm.get(this.currentCustomerFormControlName).setValue(null);
      this.parentForm.get(this.currentOutletFormControlName).setValue(null);
      this.parentForm
        .get(this.currentCustomerFormControlName)
        .updateValueAndValidity();
      this.parentForm
        .get(this.currentOutletFormControlName)
        .updateValueAndValidity();
      this.selectedCustomerId = -1;
      this.customerDataSource = [];
      this.outletDataSource = [];
      this.selectedOutletId = null;
      if (this.multiSelectOutlets) {
        this.selectedOutletIds = null;
        this.parentForm.get("outletIds").updateValueAndValidity();
        this.parentForm.get("outletIds").setValue(null);
      }
      this.populateCustomersList();
    }
    if (this.resetClickedEvent) {
      this.customerDataSource = [];
      this.outletDataSource = [];
      this.filter.newCustomerOnly = false;
      if (this.showNewCustomerOnly && this.parentForm.get("newCustomerOnly") && this.parentForm.get("newCustomerOnly").value)
        this.filter.newCustomerOnly = true;
      this.populateCustomersList();
    }
  }
  ngOnInit() {
    if (this.showExtraInfo) {
      this.dashService.getOperationDashboardId(1).subscribe((result) => {
        if (result && result.data) {
          this.selectedDashId = result.data;
        }
      });
    }
    if (this.resetClickedEvent) {
      this.resetSubscription = this.resetClickedEvent.subscribe(() => {
        this.outletDataSource = null;
      });
    }

    this.lblSelectAll = this.translateService.instant(
      ConstantMessages.CaptionAllOutlets
    );

    if (
      this.parentForm.get(this.currentCustomerFormControlName).validator != null
    ) {
      const validator = this.parentForm
        .get(this.currentCustomerFormControlName)
        .validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isCustomerRequired = true;
      }
    }
    if (
      !this.multiSelectOutlets &&
      this.parentForm.get(this.currentOutletFormControlName).validator != null
    ) {
      const validator = this.parentForm
        .get(this.currentOutletFormControlName)
        .validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isOutletRequired = true;
      }
    }
    if (
      this.multiSelectOutlets &&
      this.parentForm.get("outletIds").validator != null
    ) {
      const validator = this.parentForm
        .get("outletIds")
        .validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isOutletRequired = true;
      }
    }

    if (this.isEditMode) {
    } else {
      this.populateCustomersList();
      if (this.selectedCustomerId && this.viewOutlets) {
        this.populateOutletsList();
      }
    }
    if (this.resetCustomerDataSource) {
      this.resetDataSourceSubscription = this.resetCustomerDataSource.subscribe((groupId) => {
        this.parentForm.get(this.currentCustomerFormControlName).setValue(null);
        this.parentForm.get(this.currentOutletFormControlName).setValue(null);
        this.parentForm
          .get(this.currentCustomerFormControlName)
          .updateValueAndValidity();
        this.parentForm
          .get(this.currentOutletFormControlName)
          .updateValueAndValidity();
        this.groupId = groupId;
        this.customerDataSource = [];
        this.outletDataSource = [];
        this.populateCustomersList()
      });
    }
    this.onSearch();
  }

  populateCustomersList() {
    this.filter.customListFilter.page = this.customerDataSource.length;
    if (this.territoryId > 0) {
      this.filter.territoryId = this.territoryId;
    } else {
      this.filter.territoryId = -1;
    }
    if (this.routeId > 0) {
      this.filter.routeId = this.routeId;
      this.filter.filterOnRoute = this.filterOnRoute;
    } else {
      this.filter.routeId = -1;
    }
    if (this.selectedCustomerId) {
      this.filter.customerId = this.selectedCustomerId;
    } else {
      this.filter.customerId = -1;
    }
    if (this.employeeId > -1) {
      this.filter.employeeId = this.employeeId;
    } else {
      this.filter.employeeId = -1;
    }
    if (this.organizationId > -1) {
      this.filter.organizationId = this.organizationId;
    } else {
      this.filter.organizationId = -1;
    }
    if (this.groupId > -1) {
      this.filter.groupId = this.groupId;
    } else {
      this.filter.groupId = -1;
    }
    this.filter.isDefaultCustomer = this.isDefaultCustomer;
    this.filter.getChattingCustomers = this.getChattingCustomers;
    this.isCustomerLoading = true;
    this.filter.isFromCollection = this.showCustomersHavePendingInvoices;
    this.customerService
      .getCustomerLazyLoading(this.filter)
      .subscribe((result) => {
        if (result != undefined && result.data != undefined) {
          if (this.filter.customListFilter.page == 0) {
            this.customerDataSource = result.data.data;
          } else
            this.customerDataSource = this.customerDataSource.concat(
              result.data.data
            );
          // this.customerDataSource = this.customerDataSource.concat(
          //   result.data.data
          // );
          this.totalCustomers = result.data.totalItems;
        }
        this.isCustomerLoading = false;
      });
  }

  populateOutletsList() {
    let territoryId = -1;
    if (this.territoryId > 0) {
      territoryId = this.territoryId;
    }
    if (this.selectedCustomerId && this.selectedCustomerId > 0) {
      this.isOutletLoading = true;
      this.customerService
        .getCustomerOutlets(
          this.selectedCustomerId,
          territoryId,
          this.showExtraInfo,
          this.selectedDashId,
          this.excludeOnHold,
          this.showCustomersHavePendingInvoices
        )
        .subscribe(
          (result) => {
            this.outletDataSource = result;
            if (this.emitAllOutlets) {
              let allOutletEvent = {
                outletDataSource: this.outletDataSource,
              };
              this.onChangeCustomerId.emit(allOutletEvent);
            }
            if (this.enableSelectAllOutlets) {
              let outletObj = new CustomerOutletModel();
              outletObj.outletId = -1;
              outletObj.outletCode = "--";
              outletObj.outletName = "--";
              outletObj.outletCodeName = this.lblSelectAll;
              if (
                this.outletDataSource != null &&
                this.outletDataSource.length > 0
              )
                this.outletDataSource.unshift(outletObj);
            }
            this.isOutletLoading = false;
            if (
              !this.selectedOutletId &&
              this.outletDataSource &&
              this.outletDataSource.length > 0
            ) {
              this.selectedOutletId = this.outletDataSource[0].outletId;
            }
            this.parentForm
              .get(this.currentOutletFormControlName)
              .setValue(this.selectedOutletId);
            this.onOutletDataChanged(this.outletDataSource[0]);
          },
          (error: HttpErrorResponse) => {
            this.isOutletLoading = false;
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              this.translateService.instant(ConstantMessages.ErrorHappened)
            );
          }
        );
    }
  }

  onCustomerDataChanged($event) {
    this.filter = {
      customListFilter: {
        searchFilter: "",
        pageSize: 200,
        page: 0,
      },
      territoryId: -1,
      routeId: -1,
    };

    this.selectedCustomerId = this.parentForm.get(
      this.currentCustomerFormControlName
    ).value;
    this.outletDataSource = [];
    this.selectedOutletId = null;
    if (!this.emitIdAndCaption) {
      this.onChangeCustomer.emit($event);
    } else {
      let event = {
        id: this.selectedCustomerId,
        caption: $event.customerCodeName,
      };
      this.onChangeIdCaptionCustomer.emit(event);
    }
    this.filter.isDefaultCustomer = this.isDefaultCustomer;
    if (this.selectedCustomerId && this.viewOutlets) {
      this.populateOutletsList();
    } else {
      this.extraInfoURL = undefined;
      this.parentForm
        .get(this.currentOutletFormControlName)
        .setValue(this.selectedOutletId);
    }
  }

  onOutletDataChanged($event) {
    this.selectedOutletId = this.parentForm.get(
      this.currentOutletFormControlName
    ).value;
    this.parentForm
      .get(this.currentOutletFormControlName)
      .setValue(this.selectedOutletId);
    let outlet = this.outletDataSource.find(
      (o) => o.outletId === this.selectedOutletId
    );
    if (outlet && outlet.extraInfoLink) {
      this.extraInfoURL = outlet.extraInfoLink;
    } else {
      this.extraInfoURL = undefined;
    }
    if (!this.emitIdAndCaption) {
      this.onChangeOutlet.emit($event);
    } else {
      let event = {
        id: this.selectedOutletId,
        caption: $event.outletCodeName,
      };
      this.onChangeIdCaptionOutlet.emit(event);
    }
  }

  onSelectMultiOutletChange($event, forAll: boolean) {
    var event = {
      forAll: forAll,
      outlets: $event,
    };
    this.onSelectMultiOutlet.emit(event);
  }
  onDeSelectMultiOutletsChange($event, forAll: boolean) {
    var event = {
      forAll: forAll,
      outlets: $event,
    };
    this.onDeSelectMultiOutlet.emit(event);
  }

  fetchMoreFromServer(term) {
    // this.filter.customListFilter.searchFilter = term;
    this.populateCustomersList();
  }

  onScroll({ end }) {
    if (
      this.isCustomerLoading ||
      this.totalCustomers === this.customerDataSource.length
    ) {
      return;
    }

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.customerDataSource.length
    ) {
      this.populateCustomersList();
    }
  }

  onCustomerSearchClear() {
    this.filter.customListFilter.searchFilter = null;
  }

  ngOnDestroy() {
    if (this.resetClickedEvent) {
      this.resetSubscription.unsubscribe();
    }
    if (this.resetCustomerDataSource) {
      this.resetDataSourceSubscription.unsubscribe();
    }
  }

  onSearch() {
    this.input$
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        switchMap((term) => this.getSearchResultFromServer(term))
      )
      .subscribe((data) => {
        this.customerDataSource = data.slice(0, this.bufferSize);
      });
  }

  private getSearchResultFromServer(term) {
    this.filter.customListFilter.page = 0;
    this.customerDataSource = null;
    this.filter.customListFilter.searchFilter = term;
    return this.customerService
      .getCustomerLazyLoading(this.filter)
      .map((response) => {
        return <CustomerModel[]>response.data.data;
      });
  }
  getSafeURL() {
    if (this.extraInfoURL) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.extraInfoURL);
    } else {
      return "";
    }
  }
  onChangeNewCustomerOnly() {
    this.customerDataSource = [];
    this.filter.newCustomerOnly = false;
    if (this.parentForm.get("newCustomerOnly") && this.parentForm.get("newCustomerOnly").value) {
      this.filter.newCustomerOnly = true;
    }
    this.populateCustomersList();
  }
}
