import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  Input,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../../../core/auth/auth.service";
import { CoreSession } from "../../../../core/core.session";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { ConstantStorage } from "../../../models/constants/constant-stroage";
import { IDateTimePickerProperties } from "../../../models/dateTimeModel/date-time-properties.interface";
import { EmailFileModel } from "../../../models/email/email.model";
import {
  WebBuiltInFilters,
  WebFormControlType,
  WebFormValidation,
} from "../../../models/enums/web-form.enum";
import { WebFormService } from "../web-form.service";
import { saveAs } from "file-saver";
@Component({
  selector: "app-web-form-entry",
  templateUrl: "./web-form-entry.component.html",
  styleUrls: ["./web-form-entry.component.css"],
})
export class WebFormEntryComponent implements OnInit {
  @Output() onClickSavingEvent = new EventEmitter();
  @Input() saveEvent: Observable<void>;
  @Input() entryData: any = null;
  @Input() isAddMode: boolean = false;
  @Input() isEditMode: boolean = false;
  @Input() isViewMode: boolean = false;
  saveBtnSubscription: any;
  entryFormGroup: FormGroup;
  excludedOrganizationId = -1;
  selectedOrganizationId = null;
  attachedFiles: EmailFileModel[] = [];
  deletedFiles: string[] = [];
  formData: FormData = new FormData();
  constructor(
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private webFormService: WebFormService,
    private authService: AuthService,
    private sessionData: SessionDataProvider,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.subscribeSaveClick();
    if (this.entryData.webFormName.toLowerCase() == 'currencyentry' && this.sessionData.getConfigurationValue(ConstantConfigurations.ApplyCurrencyPerOrganization).toLowerCase() === "false") {
      this.entryData.controls.find(x => x.description == "Desc_Organization").isVisible = false;
      this.entryData.controls.find(x => x.description == "Desc_Organization").validationId = 0;
    }
    this.entryData.visibleControls = this.entryData.controls.filter(
      (x) => x.isVisible
    );
    if (
      this.isEditMode &&
      this.entryData.webFormName.toLowerCase() === "organizationsentry"
    ) {
      var index = this.entryData.controls.findIndex(
        (x) => x.isPrimary && x.columnName === "organizationid"
      );
      if (index >= 0)
        this.excludedOrganizationId =
          +this.entryData.controls[index].selectedValue;
    }
    if (this.entryData.attachedFiles && this.entryData.attachedFiles.length > 0) {
      var filesNames = this.entryData.attachedFiles;
      if (filesNames && filesNames.length > 0) {
        for (let fileName of filesNames) {
          var currentPath = document.getElementsByTagName("base")[0].href + "Resources/Attachements/" + this.entryData.webMenuURL + "/" + this.entryData.attachmentFolder + "/" + fileName;
          let file = new EmailFileModel();
          let name = currentPath.substr(currentPath.lastIndexOf('/') + 1);
          file.name = name;
          let dotes = name && (name.length > 20) ? '..' : '';
          file.shortName = name.substring(0, 20) + dotes;
          let imgExtensions = ['png', 'jpg', 'jpeg', 'gif']
          file.isImage = imgExtensions.includes(name.split('.').pop()) ? true : false;
          file.path = file.isImage ? currentPath : this.coreSession.getDefaultPathAttachments();
          file.pathFile = file.name;
          this.getFile(currentPath).subscribe(response => {
            file.file = response;
            this.attachedFiles.push(file);
          });

        }
      }
    }

    this.initForm();
  }

  getFile(url) {
    return this.http
      .get(url, {
        responseType: "blob"
      })
      .pipe(
        map(response => {
          return new Blob([response], {
            type: "application/*"
          });
        })
      );
  }
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }
  subscribeSaveClick() {
    this.saveBtnSubscription = this.saveEvent.subscribe(() => {
      this.onSaveData();
    });
  }
  initForm() {
    this.entryFormGroup = new FormGroup({});
    this.entryData.visibleControls.forEach((control) => {
      if (control.controlTypeId === WebFormControlType.DescriptionText) {
        control.descriptionProperties = {
          formControlName: control.formControlName,
          placeHolder: control.description,
          label: control.description,
        };
        control.oldValues = []; //read from edited value
        control.otherLanguages = []; //read from on change
        if (this.isEditMode || this.isViewMode) {
          control.oldValues = control.descriptionLanguages;
        }
      }
      if (control.controlTypeId === WebFormControlType.MultiSelectDropDown) {
        control.dropdownSettings = {
          singleSelection: false,
          idField: "valueId",
          textField: "displayText",
          selectAllText: this.translateService.instant(
            ConstantMessages.SelectAllCaption
          ),
          unSelectAllText: this.translateService.instant(
            ConstantMessages.UnSelectAllCaption
          ),
          searchPlaceholderText: this.translateService.instant(
            ConstantMessages.SearchCaption
          ),
          allowSearchFilter: true,
          itemsShowLimit: 3,
        };
      }
      if (control.controlTypeId === WebFormControlType.Date) {
        var dateProperties: IDateTimePickerProperties = {
          label: control.description,
          formControlName: control.formControlName,
          isCalendarOnly: true,
          dateValue: new Date(),
        };
        control.dateProperties = dateProperties;
        if (this.isEditMode || this.isViewMode) {
          control.dateProperties.dateValue = control.selectedDateModelValue;
        } else {
          control.selectedDateModelValue = new Date();
        }
      }
      var validations = [Validators.nullValidator];
      if (
        control.validationId === WebFormValidation.Required ||
        control.validationId === WebFormValidation.RequiredUnique
      ) {
        validations = [Validators.required];
      } else if (control.validationId === WebFormValidation.RequiredNonZero) {
        validations = [Validators.required, Validators.min(0.0000001)];
      } else if (control.validationId === WebFormValidation.RequiredEmail) {
        validations = [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')];
      } else if (control.validationId === WebFormValidation.Email) {
        validations = [Validators.email, Validators.email, Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')];
      }
      var allowEdit = this.isAddMode || (this.isEditMode && control.allowEdit);

      // special case for root organization
      if (
        this.isEditMode &&
        this.entryData.webFormName.toLowerCase() === "organizationsentry" &&
        control.columnName === "parentorganizationid" &&
        this.excludedOrganizationId === 1
      ) {
        control.allowEdit = false;
        allowEdit = false;
      }
      var editedValue = null;
      var decimalValue = null;
      var isNumber = false;

      if (control.controlTypeId === WebFormControlType.MultiSelectDropDown) {
        editedValue = [];
        if (this.isEditMode && control.selectedValuesList.length > 0) {
          control.selectedValuesList.forEach((valueId) => {
            var index = control.dataSourceList.findIndex(
              (x) => x.valueId === valueId
            );
            if (index >= 0) {
              editedValue.push(control.dataSourceList[index]);
            }
          });
        }
      } else {
        if (
          (control.controlTypeId === WebFormControlType.DropDown &&
            control.builtIn) ||
          control.controlTypeId === WebFormControlType.Integer ||
          control.controlTypeId === WebFormControlType.NumberOfDigits ||
          control.controlTypeId === WebFormControlType.NumberOfStockDigits ||
          control.controlTypeId === WebFormControlType.Percentage
        ) {
          isNumber = true;
        }
        if (control.controlTypeId === WebFormControlType.Date) {
          editedValue = control.selectedDateModelValue.date;
        }
        if (
          control.controlTypeId != WebFormControlType.Date &&
          control.selectedValue != null &&
          control.selectedValue != "" &&
          control.selectedValue != "-1" &&
          (this.isEditMode || this.isViewMode)
        ) {
          editedValue = control.selectedValue;
          if (isNumber) {
            decimalValue = +editedValue;
          } else if (control.controlTypeId === WebFormControlType.Boolean) {
            if (
              control.selectedValue.toLowerCase() === "true" ||
              control.selectedValue == "1"
            )
              editedValue = true;
            else if (
              control.selectedValue.toLowerCase() === "false" ||
              control.selectedValue == "0"
            )
              editedValue = false;
          }
        }
      }
      if (this.isEditMode && control.dependsOnColumnIndex != -1) {
        var indexOfMainControl = this.entryData.visibleControls.findIndex(
          (x) => x.controlIndex === control.dependsOnColumnIndex
        );
        if (
          indexOfMainControl >= 0 &&
          this.entryData.visibleControls[indexOfMainControl].selectedValue +
          "" ===
          control.dependsOnValue + ""
        ) {
          // apply validations and set edited value
        } else {
          // remove all validations and set edited value to null
          validations = [Validators.nullValidator];
          decimalValue = null;
          editedValue = null;
        }
      }
      if (control.controlTypeId === WebFormControlType.DisabledText) {
        allowEdit = false;
        editedValue = control.selectedValue;
      }

      // this.entryForm.addControl(control.formControlName, new FormControl({ value: editedValue, disabled: !allowEdit }, (control.validationId === 1 || control.validationId === 3 || control.validationId === 4) ? Validators.required : null));
      this.entryFormGroup.addControl(
        control.formControlName,
        new FormControl(
          {
            value: isNumber ? decimalValue : editedValue,
            disabled: !allowEdit,
          },
          validations
        )
      );
    });
  }
  onOtherLanguagesTextSubmitted(selectedControl, event) {
    selectedControl.otherLanguages = event;
  }
  onChangeDate(selectedControl, event) {
    selectedControl.selectedDateModelValue = event;
  }
  onChangeComboSelection(mainControl) {
    if (
      mainControl.controlTypeId === WebFormControlType.DropDown &&
      mainControl.builtIn &&
      mainControl.builtInFilterId === WebBuiltInFilters.Organization
    ) {
      this.selectedOrganizationId = this.entryFormGroup.get(
        mainControl.formControlName
      ).value;
    }

    // Fill data source for childs controls based on parent control value
    var childControls = this.entryData.visibleControls.filter(
      (control) =>
        control.filterBySpecificControl &&
        !control.builtIn &&
        (control.controlTypeId === WebFormControlType.DropDown ||
          control.controlTypeId === WebFormControlType.MultiSelectDropDown ||
          control.controlTypeId === WebFormControlType.DisabledText) &&
        control.parentFilterControlIndex === mainControl.controlIndex &&
        control.parentFilterControlParameter
    );
    if (childControls && childControls.length > 0) {
      // Clear child data source and form control value
      for (var control of childControls) {
        control.dataSourceList = [];
        this.entryFormGroup.get(control.formControlName).setValue(null);
        if (this.entryFormGroup.get(mainControl.formControlName).value) {
          control.parentFilterControlValue =
            this.entryFormGroup.get(mainControl.formControlName).value + "";
        }
      }
      // Call Api to fill childs controls data source
      if (this.entryFormGroup.get(mainControl.formControlName).value) {
        this.fillControlsDataSourceBasedOnParentControlValue(
          mainControl,
          childControls
        );
      }
    }

    // Show,Hide,Validate childs controls based on parent control value
    var validator = [Validators.nullValidator];
    this.entryData.visibleControls
      .filter(
        (control) => control.dependsOnColumnIndex === mainControl.controlIndex
      )
      .forEach((control) => {
        if (
          this.entryFormGroup.get(mainControl.formControlName).value !=
          control.dependsOnValue ||
          this.entryFormGroup
            .get(mainControl.formControlName)
            .value.toString() != control.dependsOnValue.toString()
        ) {
          this.entryFormGroup.get(control.formControlName).reset();
        } else {
          if (
            control.validationId === WebFormValidation.Required ||
            control.validationId === WebFormValidation.RequiredUnique
          ) {
            validator = [Validators.required];
          } else if (
            control.validationId === WebFormValidation.RequiredNonZero
          ) {
            validator = [Validators.required, Validators.min(0.0000001)];
          } else if (control.validationId === WebFormValidation.RequiredEmail) {
            validator = [Validators.required, Validators.email];
          } else if (control.validationId === WebFormValidation.Email) {
            validator = [Validators.email, Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')];
          }
        }
        this.entryFormGroup
          .get(control.formControlName)
          .setValidators(validator);
        this.entryFormGroup
          .get(control.formControlName)
          .updateValueAndValidity();
      });
  }
  onSaveData() {
    this.coreSession.markFormGroupTouched(this.entryFormGroup);
    if (this.entryFormGroup.invalid) {
      // Validate email controls
      var emailControls = this.entryData.visibleControls.filter(
        (c) =>
          c.validationId === WebFormValidation.Email ||
          c.validationId === WebFormValidation.RequiredEmail
      );
      if (emailControls && emailControls.length > 0) {
        for (
          var emailIndex = 0;
          emailIndex < emailControls.length;
          emailIndex++
        ) {
          if (
            this.entryFormGroup.get(emailControls[emailIndex].formControlName)
              .invalid
          ) {
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              emailControls[emailIndex].description +
              " : " +
              this.translateService.instant(ConstantMessages.MsgEmailSyntax)
            );
            return;
          }
        }
      }

      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgFillMandatory)
      );
      return;
    }

    let formControlForMinValue = null;
    let formControlForMaxValue = null;
    if (
      this.entryData.visibleControls.findIndex(
        (c) => c.columnName === "minvalue"
      ) >= 0
    ) {
      formControlForMinValue = this.entryData.visibleControls.find(
        (c) => c.columnName === "minvalue"
      ).formControlName;
    }
    if (
      this.entryData.visibleControls.findIndex(
        (c) => c.columnName === "maxvalue"
      ) >= 0
    ) {
      formControlForMaxValue = this.entryData.visibleControls.find(
        (c) => c.columnName === "maxvalue"
      ).formControlName;
    }
    if (formControlForMinValue != null && formControlForMaxValue != null) {
      var minValue = +this.entryFormGroup.get(formControlForMinValue + "")
        .value;
      var maxValue = +this.entryFormGroup.get(formControlForMaxValue + "")
        .value;
      if (minValue > maxValue && !(minValue == 0 && maxValue == 0)) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(ConstantMessages.MsgMinExceedsMax)
        );
        return;
      }
    }
    if (this.entryData.hasAttachments && this.entryData.isAttachmentsMandatory) {
      if (this.attachedFiles.length == 0) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(ConstantMessages.Msg_Add_Attachments)
        );
        return;
      }
    }
    this.fillFormValues();
    var index = this.entryData.controls.findIndex(
      (c) =>
        c.validationId === WebFormValidation.Unique ||
        c.validationId === WebFormValidation.RequiredUnique
    );
    if (index >= 0) {
      this.checkForUniqueValidations();
    } else {
      this.saveDataToDataBase();
    }
  }
  fillFormValues() {
    this.entryData.isAddMode = this.isAddMode;
    this.entryData.isEditMode = this.isEditMode;

    this.entryData.visibleControls.forEach((control) => {
      if (control.controlTypeId === WebFormControlType.MultiSelectDropDown) {
        control.selectedValuesList = this.entryFormGroup.get(
          control.formControlName
        ).value
          ? this.entryFormGroup
            .get(control.formControlName)
            .value.map(({ valueId }) => valueId)
          : [];
      } else if (control.controlTypeId === WebFormControlType.DescriptionText) {
        let descriptions = control.otherLanguages;
        if (
          descriptions.findIndex(
            (x) => x.languageId === this.coreSession.selectedLanguageId
          ) < 0
        ) {
          descriptions.push({
            languageId: this.coreSession.selectedLanguageId,
            description: this.entryFormGroup.get(control.formControlName).value,
          });
          control.descriptionLanguages = descriptions;
        }
      } else if (control.controlTypeId === WebFormControlType.Boolean) {
        control.selectedValue = this.entryFormGroup.get(control.formControlName)
          .value
          ? "1"
          : "0";
      } else if (control.controlTypeId != WebFormControlType.Date) {
        control.selectedValue = this.entryFormGroup.get(control.formControlName)
          .value
          ? this.entryFormGroup.get(control.formControlName).value.toString()
          : "";
      }
    });
  }
  fillControlsDataSourceBasedOnParentControlValue(mainControl, childsControls) {
    this.coreSession.ModalLoading.Show();
    this.webFormService
      .fillControlsDataSourceBasedOnParentControlValue(childsControls)
      .subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            if (response.data && response.data.length > 0) {
              this.entryData.visibleControls
                .filter(
                  (control) =>
                    control.filterBySpecificControl &&
                    !control.builtIn &&
                    (control.controlTypeId === WebFormControlType.DropDown ||
                      control.controlTypeId ===
                      WebFormControlType.MultiSelectDropDown ||
                      control.controlTypeId === WebFormControlType.DisabledText) &&
                    control.parentFilterControlIndex ===
                    mainControl.controlIndex &&
                    control.parentFilterControlParameter
                )
                .forEach((control) => {
                  var controlIndex = response.data.findIndex(
                    (x) => x.controlIndex === control.controlIndex
                  );
                  if (controlIndex >= 0) {
                    if (control.controlTypeId === WebFormControlType.DropDown || control.controlTypeId === WebFormControlType.MultiSelectDropDown) {
                      control.dataSourceList = response.data[controlIndex].dataSourceList;
                    } else if (control.controlTypeId === WebFormControlType.DisabledText) {
                      control.selectedValue = response.data[controlIndex].selectedValue;
                      this.entryFormGroup.get(control.formControlName).setValue(control.selectedValue);
                    }
                  }
                });
            }
          } else {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  saveDataToDataBase() {
    this.entryData.expirationDate = this.authService.getTokenExpirationDate();
    this.coreSession.ModalLoading.Show();
    this.formData = new FormData();
    for (let file of this.attachedFiles) {
      this.formData.append('attachedFiles', file.file, file.name);
    }
    this.formData.append('entryFormString', JSON.stringify(this.entryData));
    this.webFormService.saveEntryForm(this.formData, this.isEditMode).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (this.entryData.webFormName == "OrganizationsEntry") {
            localStorage.setItem(
              ConstantStorage.TokenName,
              response.data.tokenString
            );
            localStorage.setItem(
              ConstantStorage.CurrentOperator,
              JSON.stringify(response.data.currentOperator)
            );
          }
          this.coreSession.showSuccess(
            this.translateService.instant(ConstantMessages.SuccessCaption),
            this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly)
          );
          this.onClickSavingEvent.emit();
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  checkForUniqueValidations() {
    this.coreSession.ModalLoading.Show();
    this.webFormService.checkForUniqueValidation(this.entryData).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data) {
            // success result and valid
            this.saveDataToDataBase();
          } else {
            // success result and invalid
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              response.message
            );
          }
        } else {
          // not success
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  onDeleteFile(file) {
    this.deletedFiles.push(file.name);
    this.attachedFiles.splice(this.attachedFiles.indexOf(file), 1);
  }

  downloadFile(fileName, isEditMode: boolean) {
    debugger
    //file type extension
    if (isEditMode) {
      let checkFileType = fileName.split('.').pop();
      var fileType;
      if (checkFileType == "txt") {
        fileType = "text/plain";
      }
      if (checkFileType == "pdf") {
        fileType = "application/pdf";
      }
      if (checkFileType == "doc") {
        fileType = "application/vnd.ms-word";
      }
      if (checkFileType == "docx") {
        fileType = "application/vnd.ms-word";
      }
      if (checkFileType == "xls") {
        fileType = "application/vnd.ms-excel";
      }
      if (checkFileType == "png") {
        fileType = "image/png";
      }
      if (checkFileType == "jpg") {
        fileType = "image/jpeg";
      }
      if (checkFileType == "jpeg") {
        fileType = "image/jpeg";
      }
      if (checkFileType == "gif") {
        fileType = "image/gif";
      }
      if (checkFileType == "csv") {
        fileType = "text/csv";
      }



      this.webFormService
        .downloadFile(this.entryData.attachmentFolder, this.entryData.webMenuURL, fileName, fileType)
        .subscribe(
          success => {
            saveAs(success, fileName);
          },
          err => {
            alert(
              this.translateService.instant(
                ConstantMessages.Desc_Server_Error_While_Downloading_File
              ),
            );
          }
        );
    }
  }
  maxIndex: number = 0;
  onUploadFiles(file) {
    if (file) {
      let files = file.srcElement.files;
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          if (files[index].size > 10000000) //10 MB
          {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorLargeSize));
          }
          else {
            let fileObject = new EmailFileModel();
            let reader = new FileReader();
            fileObject.file = files[index];
            fileObject.name = files[index].name;
            let dotes = files[index].name && (files[index].name.length > 20) ? '..' : '';
            fileObject.shortName = files[index].name.substring(0, 20) + dotes;
            fileObject.index = this.maxIndex;
            fileObject.isImage = files[index].type.includes('image') ? true : false;
            reader.onload = (event: ProgressEvent) => {
              if (fileObject.isImage) {
                fileObject.path = (<FileReader>event.target).result;
              } else {
                fileObject.path = this.coreSession.getDefaultPathAttachments();
              }
              fileObject.pathFile = fileObject.name;
            };
            reader.readAsDataURL(file.target.files[index]);
            this.maxIndex++;
            this.attachedFiles.push(fileObject);
          }
        }
      }
    }
  }

}
