import { Injectable, Inject } from '@angular/core';

import { ResponseModel } from '../../shared/models/api-models/api-models';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs';
import { OperatorModel } from '../../shared/models/operator/operator.model';
import { CoreSession } from '../../core/core.session';
import { MasterDataScreens, MasterDataStatus } from '../../shared/models/enums/master-data-screens';
@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  httpOptions;
  private url = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'MasterData';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }
  getOrganizationList() {
    return this.getOrganizationListExcludeOrganizationId(-1);
  }
  getOrganizationListExcludeOrganizationId(excludedOrganizationId) {
    const urlValue: string = this.url + '/GetOrganizationList';

    return this.http.post<ResponseModel>(urlValue, excludedOrganizationId).map(response => {
      return response.data;
    });
  }
  getDistributorOrganizations() {
    const urlValue: string = this.url + '/GetDistributorOrganizations';

    return this.http.post<ResponseModel>(urlValue, "").map(response => {
      return response.data;
    });
  }
  getBanks() {
    const urlValue: string = this.url + '/GetBanks';

    return this.http.post<ResponseModel>(urlValue, "").map(response => {
      return response.data;
    });
  }
  getSecurityGroupsList(filter: any) {
    const urlValue: string = this.url + '/GetSecurityGroupsList';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  checkIfUniqueValue(filter: any) {
    const urlValue: string = this.url + '/CheckIfUniqueValue';
    return this.http.post<ResponseModel>(urlValue, filter).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  checkIfUniqueDescription(filter: any) {
    const urlValue: string = this.url + '/CheckIfUniqueDescription';
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  checkIfUniqueValueOfListOfData(dataList: any[]) {
    const urlValue: string = this.url + '/CheckIfUniqueValueOfListOfData';
    return this.http.post<ResponseModel>(urlValue, dataList).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  getActivateDeactivateReason(screen: MasterDataScreens, status: MasterDataStatus) {
    const urlValue: string = this.url + '/GetActivateDeactivateReason';
    let filter = {
      screen: screen,
      status: status
    }
    return this.http.post<ResponseModel>(urlValue, filter).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }


  insertAuditMasterReason(auditMaster) {
    const urlValue = this.url + "/InsertAuditMasterReason";
    return this.http.post(urlValue, auditMaster).map((response) => {
      const res = new ResponseModel();
      res.data = response['data'];
      res.message = response['message'];
      res.status = response['status'];
      return res;
    });
  }

}
