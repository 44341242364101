import { Injectable, Inject, EventEmitter } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ModalDialogComponent } from "../shared/components/modal-dialog/modal-dialog.component";
import { ModalLoadingComponent } from "../shared/components/modal-loading/modal-loading.component";
import { DateTimeModel } from "../shared/models/dateTimeModel/date-time.model";
import { ToastyService } from "ng2-toasty";
import {
  OperatorModel,
  CachedGeneralData,
} from "../shared/models/operator/operator.model";
import { ConstantStorage } from "../shared/models/constants/constant-stroage";
import { ConfigurationModel } from "../shared/models/general/configuration.model";
import { PaymentTypeModel } from "../shared/models/payment/payment-type.model";
import { BankModel } from "../shared/models/payment/bank.model";
import { FormGroup } from "@angular/forms";
import { MenuModel } from "../shared/models/menu/menu.model";
import { SessionDataProvider } from "./session-data-provider.service";
import { MenuActions } from "../shared/models/enums/menu-actions-enum";
import { ActionTypesModel } from "../shared/models/menu/action-types.model";
import { ConstantConfigurations } from "../shared/models/constants/constant-configuration";
import { WeekDay } from "@angular/common";
import { WeeDaysPositions } from "../shared/models/route-visit/route-week-model";
import { WeekDayEnum } from "../shared/models/enums/week-day.enum";

@Injectable({ providedIn: "root" })
export class CoreSession {
  //#region [DECLARATIONS]
  public generalSearchPattern: string = null;
  public hideEmployeeEntrySliderFooter = false;
  public hideDisplayUnitEntrySliderFooter = false;
  public hideCustomerEntrySliderFooter = false;
  public hideSubRecallExecutionEntrySliderFooter = false;
  public showLanguageSlider = false;
  public addedActions: ActionTypesModel[] = [];
  public selectedLanguageStr = "";
  public isRTL = false;
  public selectedLanguageId = -1;
  public TitleService: Title;
  public ModalDialog: ModalDialogComponent;
  public ModalLoading: ModalLoadingComponent;
  public DefaultValue = "8zD2RB7Fl0dVbO8BMZ3TTw==";
  public DefaultBatchNumber = "1990/01/01";
  public BatchNoFormat = "yyyy/MM/dd";
  public CurrentOperator: OperatorModel;
  public cachedGeneralData: CachedGeneralData;
  public paymentTypesList: PaymentTypeModel[];
  public banksList: BankModel[];
  public pageSize = 25;
  public pageSizeOptions: number[] = [10, this.pageSize, 50, 75, 100];
  public baseCurrencyCode: string = "ABC";
  private timeOutDelay = 5000;
  public weekDaysPositionObj: WeeDaysPositions;
  operation1Length: number; // (/)
  operation2Length: number; // (-)
  dateTimeSequence = 0;
  showPrivacypolicy = false;
  isPrivacyLoaded = false;
  privacyText = "";
  contentHeight: number;
  baseURL = "";
  public isInternetConnected: boolean = false;
  public connectionState = new EventEmitter<Boolean>();
  //#endregion

  //#region [DECLARATIONS]

  constructor(
    @Inject(ToastyService) private toastyService: ToastyService,
    private sessionDataProvider: SessionDataProvider
  ) {
    this.setCurrentOperator();
    this.setCachedGeneralData();
    this.baseURL = document.getElementsByTagName("base")[0].href;
  }
  //#endregion

  //#region [GENERAL FUNCTIONS]

  getDateTimeSequence() {
    this.dateTimeSequence++;
    if (this.dateTimeSequence > 100) {
      this.dateTimeSequence = 0;
    }
    return this.dateTimeSequence;
  }
  checkActivitiesAvailability(menuURL: string, action: MenuActions) {
    let index = this.sessionDataProvider.allMenusAvailable.findIndex(
      (x) => x.url === menuURL && !x.isParentMenu
    );
    if (index > -1) {
      if (
        action &&
        this.sessionDataProvider.allMenusAvailable[index].addedActionTypes.find(
          (x) => x.actionTypeId === action.valueOf()
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  setCurrentOperator() {
    this.CurrentOperator = new OperatorModel();
    const operator = localStorage.getItem(ConstantStorage.CurrentOperator);
    if (operator) {
      // get currecnt operator from local storage
      this.CurrentOperator = JSON.parse(operator);
      this.sessionDataProvider.getBaseCurrency().subscribe(
        (response) => {
          if (response.status != null && response.status >= 0) {
            this.baseCurrencyCode = response.data;
          } else {
            this.baseCurrencyCode = "ABC";
          }
        },
        (error) => {}
      );
    }
  }
  setCachedGeneralData() {
    this.cachedGeneralData = new CachedGeneralData();
    const generalData = localStorage.getItem(ConstantStorage.generalData);
    if (generalData) {
      this.cachedGeneralData = JSON.parse(generalData);
    }
  }
  // DEFAULT EXPIRY DATE
  public DefaultExpiryDate(): Date {
    return new Date(1990, 0, 1);
  }
  // Generic Methods to set delays
  public SetDelay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  // SET TITLE
  public SetTitle(title: string) {
    this.TitleService.setTitle("Sonic");
    // this.TitleService.setTitle('Sonic - ' + title);
  }
  // Get Array Index Based On Value
  public GetIndexForList(arrayList, key, value) {
    for (let index = 0; index < arrayList.length; index++) {
      if (arrayList[index][key] === value) {
        return index;
      }
    }
    return -1;
  }

  public GetCurrentDateAsModel() {
    const currentDate = new Date();
    const date = new DateTimeModel();
    date.year = currentDate.getFullYear();
    date.month = currentDate.getMonth() + 1;
    date.day = currentDate.getDate();
    date.date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return date;
  }

  public GetCurrentDateTimeAsModel() {
    const currentDate = new Date();
    const date = new DateTimeModel();
    date.year = currentDate.getFullYear();
    date.month = currentDate.getMonth() + 1;
    date.day = currentDate.getDate();
    date.hour = currentDate.getHours();
    date.minute = currentDate.getMinutes();
    date.date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds(),
      currentDate.getMilliseconds()
    );
    return date;
  }

  public cloneObject(originalItem: any) {
    let targetItem: any;
    targetItem = Object.assign({}, originalItem);
    return targetItem;
  }

  toQueryString(obj: any) {
    const parts = [];
    // tslint:disable-next-line:forin
    for (const property in obj) {
      const value = obj[property];
      if (value != null && value !== undefined) {
        parts.push(
          encodeURIComponent(property) + "=" + encodeURIComponent(value)
        );
      }
    }
    return parts.join("&");
  }

  //#endregion

  //#region [DIALOG NOTIFICATIONS FUNCTIONS]

  public showWarrning(title: string, msg: string) {
    this.toastyService.warning({
      title: title,
      msg: msg,
      theme: "material",
      showClose: true,
      timeout: this.timeOutDelay,
    });
  }
  public showWarrningWithTimeOutDelay(
    title: string,
    msg: string,
    timeout: number
  ) {
    this.toastyService.warning({
      title: title,
      msg: msg,
      theme: "material",
      showClose: true,
      timeout: timeout,
    });
  }
  public showSuccessWithTimeOut(selectedTimeOut, title: string, msg: string) {
    this.toastyService.success({
      title: title,
      msg: msg,
      theme: "material",
      showClose: true,
      timeout: selectedTimeOut,
    });
  }
  public showSuccess(title: string, msg: string) {
    this.toastyService.success({
      title: title,
      msg: msg,
      theme: "material",
      showClose: true,
      timeout: this.timeOutDelay,
    });
  }
  public showInfo(title: string, msg: string) {
    this.toastyService.info({
      title: title,
      msg: msg,
      theme: "material",
      showClose: true,
      timeout: this.timeOutDelay,
    });
  }
  public showError(title: string, msg: string) {
    this.toastyService.error({
      title: title,
      msg: msg,
      theme: "material",
      showClose: true,
      timeout: this.timeOutDelay,
    });
  }
  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getDateTimeModel(selectedDateValue): DateTimeModel {
    const date = new DateTimeModel();
    date.year = selectedDateValue.getFullYear();
    date.month = selectedDateValue.getMonth() + 1;
    date.day = selectedDateValue.getDate();
    date.date = selectedDateValue;
    return date;
  }
  fillWeeKDaysPositions() {
    this.weekDaysPositionObj = new WeeDaysPositions();
    var startDayPosition = WeekDayEnum.Sunday;
    if (
      this.sessionDataProvider.getConfigurationValue(
        ConstantConfigurations.RouteVisitStartDay
      ) != "" &&
      this.sessionDataProvider.getConfigurationValue(
        ConstantConfigurations.RouteVisitStartDay
      ) != null
    ) {
      startDayPosition = +this.sessionDataProvider.getConfigurationValue(
        ConstantConfigurations.RouteVisitStartDay
      );
    }
    switch (startDayPosition) {
      case WeekDayEnum.Sunday:
        this.weekDaysPositionObj.SundayPosition = 1;
        this.weekDaysPositionObj.MondayPosition = 2;
        this.weekDaysPositionObj.TuesdayPosition = 3;
        this.weekDaysPositionObj.WednesdayPosition = 4;
        this.weekDaysPositionObj.ThursdayPosition = 5;
        this.weekDaysPositionObj.FridayPosition = 6;
        this.weekDaysPositionObj.SaturdayPosition = 7;
        break;
      case WeekDayEnum.Monday:
        this.weekDaysPositionObj.SundayPosition = 7;
        this.weekDaysPositionObj.MondayPosition = 1;
        this.weekDaysPositionObj.TuesdayPosition = 2;
        this.weekDaysPositionObj.WednesdayPosition = 3;
        this.weekDaysPositionObj.ThursdayPosition = 4;
        this.weekDaysPositionObj.FridayPosition = 5;
        this.weekDaysPositionObj.SaturdayPosition = 6;
        break;
      case WeekDayEnum.Tuesday:
        this.weekDaysPositionObj.SundayPosition = 6;
        this.weekDaysPositionObj.MondayPosition = 7;
        this.weekDaysPositionObj.TuesdayPosition = 1;
        this.weekDaysPositionObj.WednesdayPosition = 2;
        this.weekDaysPositionObj.ThursdayPosition = 3;
        this.weekDaysPositionObj.FridayPosition = 4;
        this.weekDaysPositionObj.SaturdayPosition = 5;
        break;
      case WeekDayEnum.Wednesday:
        this.weekDaysPositionObj.SundayPosition = 5;
        this.weekDaysPositionObj.MondayPosition = 6;
        this.weekDaysPositionObj.TuesdayPosition = 7;
        this.weekDaysPositionObj.WednesdayPosition = 1;
        this.weekDaysPositionObj.ThursdayPosition = 2;
        this.weekDaysPositionObj.FridayPosition = 3;
        this.weekDaysPositionObj.SaturdayPosition = 4;
        break;
      case WeekDayEnum.Thursday:
        this.weekDaysPositionObj.SundayPosition = 4;
        this.weekDaysPositionObj.MondayPosition = 5;
        this.weekDaysPositionObj.TuesdayPosition = 6;
        this.weekDaysPositionObj.WednesdayPosition = 7;
        this.weekDaysPositionObj.ThursdayPosition = 1;
        this.weekDaysPositionObj.FridayPosition = 2;
        this.weekDaysPositionObj.SaturdayPosition = 3;
        break;
      case WeekDayEnum.Friday:
        this.weekDaysPositionObj.SundayPosition = 3;
        this.weekDaysPositionObj.MondayPosition = 4;
        this.weekDaysPositionObj.TuesdayPosition = 5;
        this.weekDaysPositionObj.WednesdayPosition = 6;
        this.weekDaysPositionObj.ThursdayPosition = 7;
        this.weekDaysPositionObj.FridayPosition = 1;
        this.weekDaysPositionObj.SaturdayPosition = 2;
        break;
      case WeekDayEnum.Saturday:
        this.weekDaysPositionObj.SundayPosition = 2;
        this.weekDaysPositionObj.MondayPosition = 3;
        this.weekDaysPositionObj.TuesdayPosition = 4;
        this.weekDaysPositionObj.WednesdayPosition = 5;
        this.weekDaysPositionObj.ThursdayPosition = 6;
        this.weekDaysPositionObj.FridayPosition = 7;
        this.weekDaysPositionObj.SaturdayPosition = 1;
        break;
    }
  }

  //#endregion
  //Expecting format dd/MM/yyyy
  parseStringToDate(value: any): Date | null {
    if (typeof value === "string" && value.includes("/")) {
      const str = value.split("/");
      if (str.length === 3) {
        const year = Number(str[2]);
        const month = Number(str[1]) - 1;
        const day = Number(str[0]);
        if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
          if (
            ((!(year % 4) && year % 100) || !(year % 400)) &&
            month >= 1 &&
            month <= 12 &&
            day >= 1 &&
            day <= 31
          )
            return new Date(year, month, day);
          else return null;
        } else return null;
      } else {
        return null;
      }
    } else if (typeof value === "string" && value === "") {
      return new Date();
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  testUsedSeparator(inputText) {
    this.operation1Length = 0;
    this.operation2Length = 0;
    //Test which seperator is used '/' or '-'
    var opera1 = inputText.split("/");
    var opera2 = inputText.split("-");
    this.operation1Length = opera1.length;
    this.operation2Length = opera2.length;
    // Extract the string into month, date and year
    if (this.operation1Length > 1) {
      var pdate = inputText.split("/");
    } else if (this.operation2Length > 1) {
      var pdate = inputText.split("-");
    }
    return pdate;
  }
  vaildateAndReturnDate(dd, mm, yy) {
    // Create list of days of a month [assume there is no leap year by default]
    var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (mm == 1 || mm > 2) {
      if (dd > ListofDays[mm - 1]) {
        return null;
      }
    }
    if (mm == 2) {
      var lyear = false;
      if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
        lyear = true;
      }
      if (lyear == false && dd >= 29) {
        return null;
      }
      if (lyear == true && dd > 29) {
        return null;
      }
    }
    return new Date(yy, mm, dd);
  }
  validatedate(inputText, currentDateFormat) {
    let date = null;
    switch (currentDateFormat) {
      case "yyMMdd":
        var dateformat = /^\d{2}(0?[1-9]|1[012])(0?[1-9]|[12][0-9]|3[01])$/;
        if (inputText.match(dateformat)) {
          var yy = parseInt(inputText.substring(0, 2)) + 2000;
          var mm = parseInt(inputText.substring(2, 4)) - 1;
          var dd = parseInt(inputText.substring(4));
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "ddMMyyyy":
        var dateformat = /^(0?[1-9]|[12][0-9]|3[01])(0?[1-9]|1[012])\d{4}$/;
        if (inputText.match(dateformat)) {
          var dd = parseInt(inputText.substring(0, 2));
          var mm = parseInt(inputText.substring(2, 4)) - 1;
          var yy = parseInt(inputText.substring(4));
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "yyyyMMdd":
        var dateformat = /^\d{4}(0?[1-9]|1[012])(0?[1-9]|[12][0-9]|3[01])$/;
        if (inputText.match(dateformat)) {
          var yy = parseInt(inputText.substring(0, 4));
          var mm = parseInt(inputText.substring(4, 6)) - 1;
          var dd = parseInt(inputText.substring(6));
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MMddyyyy":
        var dateformat = /^(0?[1-9]|1[012])(0?[1-9]|[12][0-9]|3[01])\d{4}$/;
        if (inputText.match(dateformat)) {
          var mm = parseInt(inputText.substring(0, 2)) - 1;
          var dd = parseInt(inputText.substring(2, 4));
          var yy = parseInt(inputText.substring(4));
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MMddyy":
        var dateformat = /^(0?[1-9]|1[012])(0?[1-9]|[12][0-9]|3[01])\d{2}$/;
        if (inputText.match(dateformat)) {
          var mm = parseInt(inputText.substring(0, 2)) - 1;
          var dd = parseInt(inputText.substring(2, 4));
          var yy = parseInt(inputText.substring(4)) + 2000;
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "ddMMMyyyy":
        var MONTHS = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JULY",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        var dateformat =
          /^(0?[1-9]|[12][0-9]|3[01])(JAN|FEB|MAR|APR|MAY|JUN|JULY|AUG|SEP|OCT|NOV|DEC)\d{4}$/;
        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dateformat2 =
          /^(0?[1-9]|[12][0-9]|3[01])(Jan|Feb|Mar|Apr|May|Jun|July|Aug|Sep|Oct|Nov|Dec)\d{4}$/;
        if (inputText.match(dateformat)) {
          var dd = parseInt(inputText.substring(0, 2));
          var mm = MONTHS.indexOf(inputText.substring(2, 5).toUpperCase());
          var yy = parseInt(inputText.substring(5));
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else if (inputText.match(dateformat2)) {
          var dd = parseInt(inputText.substring(0, 2));
          var mm = MONTHS.indexOf(inputText.substring(2, 5).toUpperCase());
          var yy = parseInt(inputText.substring(5));
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "dd/MM/yyyy":
        var dateformat =
          /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var dd = parseInt(pdate[0]);
          var mm = parseInt(pdate[1]) - 1;
          var yy = parseInt(pdate[2]);
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "dd-MM-yyyy":
        var dateformat =
          /^(0?[1-9]|[12][0-9]|3[01])[\-](0?[1-9]|1[012])[\-]\d{4}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var dd = parseInt(pdate[0]);
          var mm = parseInt(pdate[1]) - 1;
          var yy = parseInt(pdate[2]);
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "yyyy-MM-dd":
        var dateformat =
          /^\d{4}[\-](0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var yy = parseInt(pdate[0]);
          var mm = parseInt(pdate[1]) - 1;
          var dd = parseInt(pdate[2]);
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MM/dd/yyyy":
        var dateformat =
          /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var mm = parseInt(pdate[0]) - 1;
          var dd = parseInt(pdate[1]);
          var yy = parseInt(pdate[2]);
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MM-dd-yyyy":
        var dateformat =
          /^(0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])[\-]\d{4}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var mm = parseInt(pdate[0]) - 1;
          var dd = parseInt(pdate[1]);
          var yy = parseInt(pdate[2]);
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MM/dd/yy":
        var dateformat =
          /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{2}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var mm = parseInt(pdate[0]) - 1;
          var dd = parseInt(pdate[1]);
          var yy = parseInt(pdate[2]) + 2000;
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "yy/MM/dd":
        var dateformat =
          /^\d{2}[\/](0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var yy = parseInt(pdate[0]) + 2000;
          var mm = parseInt(pdate[1]) - 1;
          var dd = parseInt(pdate[2]);
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "dd-MMM-yy":
        var MONTHS = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JULY",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        var dateformat =
          /^(0?[1-9]|[12][0-9]|3[01])[\-](JAN|FEB|MAR|APR|MAY|JUN|JULY|AUG|SEP|OCT|NOV|DEC)[\-]\d{2}$/;
        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dateformat2 =
          /^(0?[1-9]|[12][0-9]|3[01])[\-](Jan|Feb|Mar|Apr|May|Jun|July|Aug|Sep|Oct|Nov|Dec)[\-]\d{2}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var dd = parseInt(pdate[0]);
          var mm = MONTHS.indexOf(pdate[1].toUpperCase());
          var yy = parseInt(pdate[2]) + 2000;
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else if (inputText.match(dateformat2)) {
          var pdate = this.testUsedSeparator(inputText);
          var dd = parseInt(pdate[0]);
          var mm = months.indexOf(pdate[1]);
          var yy = parseInt(pdate[2]) + 2000;
          date = this.vaildateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
    }
  }

  getDefaultPathAttachments() {
    return this.baseURL + "assets/img/attachments1.png";
  }

  updateConnectionState(isConnected) {
    this.isInternetConnected = isConnected;
    this.connectionState.emit(isConnected);
  }
}
