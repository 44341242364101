import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EntryFormTypes } from '../../../models/enums/entry-form-types.enum';
import { CustomerClassModel, CustomerSubClassModel } from '../../../models/customer/customer-class.model';
import { Observable } from 'rxjs';
import { CustomerService } from '../../../services/data-definition/customer/customers.service';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../models/constants/constant-message';

@Component({
  selector: 'app-entry-form',
  templateUrl: './entry-form.component.html',
  styleUrls: ['./entry-form.component.css']
})
export class EntryFormComponent implements OnInit {

  parentForm: FormGroup;
  @Input() isEditMode = false;
  @Input() isViewMode = false;
  @Input() entryFormType: EntryFormTypes;
  @Output() onAddingNewCustomerClass = new EventEmitter();
  @Input() saveEvent: Observable<void>;
  saveBtnSubscription: any;
  constructor(private customerService: CustomerService, public coreSession: CoreSession, private translateService: TranslateService,) { }

  ngOnInit() {
    this.subscribeSaveClick();
    this.parentForm = new FormGroup({
      name: new FormControl("",Validators.required),
      code: new FormControl("",Validators.required)
    });
  }
  addNewCustomerClass() {
    var customerClass = new CustomerClassModel();
    customerClass.customerClassName = this.parentForm.value.name;
    customerClass.customerClassCode = this.parentForm.value.code;
    if (customerClass.customerClassName != null && customerClass.customerClassName != ''
      && customerClass.customerClassCode != null && customerClass.customerClassCode != '') {
      this.customerService.InsertNewCustomerClass(customerClass).subscribe(result => {
        customerClass = result.data;
        this.onAddingNewCustomerClass.emit(customerClass.customerClassId);

      });
    } else {
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      this.markAsTouched();
    }
  }
  addNewCustomerSubClass() {
    var customerClass = new CustomerSubClassModel();
    customerClass.customerSubClassName = this.parentForm.value.name;
    customerClass.customerSubClassCode = this.parentForm.value.code;

    if (customerClass.customerSubClassName != null && customerClass.customerSubClassName != ''
      && customerClass.customerSubClassCode != null && customerClass.customerSubClassCode != '') {
      this.customerService.InsertNewCustomerSubClass(customerClass).subscribe(result => {
        customerClass = result.data;
        this.onAddingNewCustomerClass.emit(customerClass.customerSubClassId);

      });
    } else {
      this.markAsTouched();
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
    }
  }

  markAsTouched() {
    this.parentForm.get("name").markAsTouched();
    this.parentForm.get("code").markAsTouched();
  }
  addNew() {
    switch (this.entryFormType) {
      case EntryFormTypes.CustomerClass:
        this.addNewCustomerClass();
        break;
      case EntryFormTypes.CustomerSubClass:
        this.addNewCustomerSubClass();
        break;
    }
  }
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.saveEvent.subscribe(() => {
      this.addNew();
    });
  }

}
