import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../models/constants/constant-message';

@Pipe({ name: 'dateAgo' })
export class DateAgo implements PipeTransform {

    timer;
    constructor(private translateService: TranslateService) {
    }
    value = '';

    transform(value) {
        this.removeTimer();
        let  d = new Date(value);
        let  now = new Date();
        let  seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
        let  timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;

        let  minutes = Math.round(Math.abs(seconds / 60));
        let  hours = Math.round(Math.abs(minutes / 60));
        let  days = Math.round(Math.abs(hours / 24));
        let  months = Math.round(Math.abs(days / 30.416));
        let  years = Math.round(Math.abs(days / 365));
        if (Number.isNaN(seconds)) {
            return '';
        }
        else if (seconds <= 45) {
            return this.translateService.instant(ConstantMessages.Desc_Few_Seconds_Ago_Caption);
        }
        else if (seconds <= 90) {
            return this.translateService.instant(ConstantMessages.Desc_One_Minute_Ago_Caption);
        }
        else if (minutes <= 45) {
            return this.translateService.instant(ConstantMessages.Desc_Notification_Time_Ago_Caption).replace('{0}', minutes).replace('{1}', this.translateService.instant(ConstantMessages.Desc_Notification_Minutes_Caption));
        }
        else if (minutes <= 90) {
            return this.translateService.instant(ConstantMessages.Desc_One_Hour_Ago_Caption);
        }
        else if (hours <= 22) {
            return this.translateService.instant(ConstantMessages.Desc_Notification_Time_Ago_Caption).replace('{0}', hours).replace('{1}', this.translateService.instant(ConstantMessages.Desc_Notification_Hours_Caption));
        }
        else if (hours <= 36) {
            return this.translateService.instant(ConstantMessages.Desc_One_Day_Ago_Caption);
        }
        else if (days <= 25) {
            return this.translateService.instant(ConstantMessages.Desc_Notification_Time_Ago_Caption).replace('{0}', days).replace('{1}', this.translateService.instant(ConstantMessages.Desc_Notification_Days_Caption));
        }
        else if (days <= 45) {
            return this.translateService.instant(ConstantMessages.Desc_One_Month_Ago_Caption);
        }
        else if (days <= 345) {
            return this.translateService.instant(ConstantMessages.Desc_Notification_Time_Ago_Caption).replace('{0}', months).replace('{1}', this.translateService.instant(ConstantMessages.Desc_Notification_Months_Caption));
        }
        else if (days <= 545) {
            return this.translateService.instant(ConstantMessages.Desc_One_Year_Ago_Caption);
        }
        else {
            // (days > 545)
            return this.translateService.instant(ConstantMessages.Desc_Notification_Time_Ago_Caption).replace('{0}', years).replace('{1}', this.translateService.instant(ConstantMessages.Desc_Notification_Years_Caption));
        }
    }
    /**
     * @return {?}
     */
    ngOnDestroy() {
        this.removeTimer();
    }
   
    removeTimer() {
        if (this.timer) {
            window.clearTimeout(this.timer);
            this.timer = null;
        }
    }
    
    getSecondsUntilUpdate(seconds) {
        let /** @type {?} */ min = 60;
        let /** @type {?} */ hr = min * 60;
        let /** @type {?} */ day = hr * 24;
        if (seconds < min) {
            // less than 1 min, update every 2 secs
            return 2;
        }
        else if (seconds < hr) {
            // less than an hour, update every 30 secs
            return 30;
        }
        else if (seconds < day) {
            // less then a day, update every 5 mins
            return 300;
        }
        else {
            // update every hour
            return 3600;
        }
    }
}

