import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { PackTypeFilter } from './pack-type-filter-interface';

@Component({
  selector: 'app-pack-type-filter',
  templateUrl: './pack-type-filter.component.html',
  styleUrls: ['./pack-type-filter.component.css']
})
export class PackTypeFilterComponent implements OnInit, OnDestroy {
  @Input() resetClickedEvent: EventEmitter<boolean>;
  @Input() hideLabel = false;
  @Input() parentForm: FormGroup;
  @Input() isPackGroup = false;
  @Input() id: string = "packTypeId";
  @Output() onPackTypeSelected = new EventEmitter();
  resetSubscription: any;
  packTypesDataSource: any[];
  isTypesLoading = false;
  isRequired = false;

  constructor(private itemService: ItemService) {
  }

  ngOnInit() {
    if (this.parentForm.get('packTypeId').validator != null) {
      const validator = this.parentForm.get('packTypeId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populatePackTypesList();

    if (this.resetClickedEvent) {
      this.resetSubscription = this.resetClickedEvent.subscribe(() => {
        this.populatePackTypesList();
      });
    }
  }
  ngOnDestroy() {
    if (this.resetClickedEvent) {
      this.resetSubscription.unsubscribe();
    }
  }
  populatePackTypesList() {
    this.isTypesLoading = true;
    if (!this.isPackGroup) {
      this.itemService.getPackType().subscribe(result => {
        this.packTypesDataSource = result.data;
        this.isTypesLoading = false;
      });
    } else {
      this.itemService.getPackTypeOfPackGroup().subscribe(result => {
        this.packTypesDataSource = result.data;
        this.isTypesLoading = false;
      });
    }
  }
  onChangePackType(selectedPackType) {
    this.onPackTypeSelected.emit(selectedPackType);
  }
}

