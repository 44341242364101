import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ItemPackModel } from '../models/Item/item.model';
import { TotalsModel } from '../models/Item/totals.model';
import { SalesTransactionTypes } from '../models/enums/sales-transaction-types';
import { SessionDataProvider } from '../../core/session-data-provider.service';
import { ConstantConfigurations } from '../models/constants/constant-configuration';
import { CoreSession } from '../../core/core.session';
import Decimal from 'decimal.js';

@Injectable()
export class CalculationService {

  calculateTaxBeforeDiscount: boolean = false;
  applyRoundingPerLine: boolean = false;
  applyZATCARoundingRules: boolean = false;
  applyAmountDiscountPerQuantity: boolean = false;
  numberOfStockDigitsFormat: number = 0;
  numberOfDigitsFormat: number = 0;
  applyTaxOverFreeItems = false;
  includeExciseTaxInDiscount: boolean = false;

  constructor(private decimalFormat: DecimalPipe, private sessionData: SessionDataProvider, public coreSession: CoreSession) {
    this.applyTaxOverFreeItems = this.sessionData
      .getConfigurationValue(ConstantConfigurations.AllowTaxOverFreeItems)
      .toLowerCase() === "true"
    this.calculateTaxBeforeDiscount = this.sessionData
      .getConfigurationValue(ConstantConfigurations.CalculateTaxBeforeDiscounts)
      .toLowerCase() === "true"
    this.applyRoundingPerLine = sessionData.getConfigurationValue(ConstantConfigurations.ApplyRoundingOnCalculations).toLowerCase() === "true";
    this.applyZATCARoundingRules = sessionData.getConfigurationValue(ConstantConfigurations.ApplyZATCARoundingRules).toLowerCase() === "true";
    this.applyAmountDiscountPerQuantity = sessionData.getConfigurationValue(ConstantConfigurations.ApplyAmountDiscountPerQuantity).toLowerCase() === "true";
    this.numberOfDigitsFormat = +sessionData.getConfigurationValue(ConstantConfigurations.NumberOfDigits);
    this.numberOfStockDigitsFormat = +sessionData.getConfigurationValue(ConstantConfigurations.NumberOfStockDigits);
    this.includeExciseTaxInDiscount = sessionData.getConfigurationValue(ConstantConfigurations.IncludeExciseTaxInDiscount).toLowerCase() === "true";
  }
  customRound(number, decimalPlaces: number) {
    if (isNaN(number)) {
      number = "0";
    }
    const factor = Math.pow(10, decimalPlaces);
    const adjustedNumber = Number((number * factor).toFixed(8));
    const truncatedNumber = Math.trunc(adjustedNumber);
    const decimalPart = adjustedNumber - truncatedNumber;
    const roundedDecimalPart = decimalPart >= 0.5 ? 1 : 0;
    number = (truncatedNumber + roundedDecimalPart) / factor;
    return number;
  }

  getNumberOnDigitFormat(numToRound: number) {
    return parseFloat(this.formatNumeric(numToRound, this.numberOfDigitsFormat));
  }
  getNumberOnStockDigitFormat(numToRound: number) {
    return parseFloat(this.formatNumeric(numToRound, this.numberOfStockDigitsFormat));
  }
  calculatePackTotal(itemPack: any) {
    itemPack.calculatedTax = 0;
    itemPack.calculatedRetailTax = 0;
    itemPack.calculatedDiscount = 0;
    itemPack.calculatedPromotedDiscount = 0;
    itemPack.calculatedDiscountTotal = 0;
    itemPack.grossTotal = 0;
    itemPack.netTotal = 0;
    itemPack.isCalculated = false;
    // itemPack.grossTotal = itemPack.price * itemPack.requiredQty;
    itemPack.grossTotal = this.multiplyTwoNumbers(itemPack.price, itemPack.requiredQty);
    // itemPack.calculatedRetailTax = itemPack.retailValue * itemPack.requiredQty;
    itemPack.calculatedRetailTax = this.multiplyTwoNumbers(itemPack.retailValue, itemPack.requiredQty);
    let addedExciseTax = 0;
    if (this.includeExciseTaxInDiscount) {
      addedExciseTax = itemPack.calculatedRetailTax;
      if (this.applyRoundingPerLine) {
        addedExciseTax = this.getNumberOnDigitFormat(addedExciseTax);
      }
    }
    if (this.applyRoundingPerLine) {
      itemPack.grossTotal = this.getNumberOnDigitFormat(itemPack.grossTotal);
    }
    if (this.applyRoundingPerLine) {
      itemPack.calculatedRetailTax = this.getNumberOnDigitFormat(itemPack.calculatedRetailTax);
    }
    if (itemPack.discountTypeId == 1) {
      // itemPack.calculatedDiscount = itemPack.grossTotal * (itemPack.discount / 100);
      itemPack.calculatedDiscount = this.multiplyTwoNumbers(this.sumTwoNumbers(itemPack.grossTotal, addedExciseTax), (this.divideTwoNumbers(itemPack.discount, 100)));
    }
    else {
      itemPack.calculatedDiscount = itemPack.discount;
      if (this.applyAmountDiscountPerQuantity) {
        // itemPack.calculatedDiscount = itemPack.calculatedDiscount * itemPack.requiredQty;
        itemPack.calculatedDiscount = this.multiplyTwoNumbers(itemPack.calculatedDiscount, itemPack.requiredQty);
      }
      //itemPack.discount = (100 * itemPack.calculatedDiscount) / itemPack.grossTotal;
    }
    // if discount amount exceed pack gross then set max calculated discount to be as gross to have percentage = 100%
    if (itemPack.calculatedDiscount > (this.sumTwoNumbers(itemPack.grossTotal, addedExciseTax))) {
      itemPack.calculatedDiscount = this.sumTwoNumbers(itemPack.grossTotal, addedExciseTax);
    }
    if (this.applyRoundingPerLine) {
      itemPack.calculatedDiscount = this.getNumberOnDigitFormat(itemPack.calculatedDiscount);
      //itemPack.discount = this.getNumberOnDigitFormat(itemPack.discount);
    }
    itemPack.calculatedDiscountTotal = itemPack.calculatedDiscount;
    // itemPack.calculatedPromotedDiscount = (itemPack.grossTotal - itemPack.calculatedDiscountTotal) * (itemPack.promotedDiscount / 100);
    itemPack.calculatedPromotedDiscount = this.multiplyTwoNumbers(this.subtractTwoNumbers(this.sumTwoNumbers(itemPack.grossTotal, addedExciseTax), itemPack.calculatedDiscountTotal), (this.divideTwoNumbers(itemPack.promotedDiscount, 100)));
    if (this.applyRoundingPerLine) {
      itemPack.calculatedPromotedDiscount = this.getNumberOnDigitFormat(itemPack.calculatedPromotedDiscount);
    }
    // itemPack.calculatedDiscountTotal = itemPack.calculatedDiscountTotal + itemPack.calculatedPromotedDiscount;
    itemPack.calculatedDiscountTotal = this.sumTwoNumbers(itemPack.calculatedDiscountTotal, itemPack.calculatedPromotedDiscount);
    if (this.applyRoundingPerLine) {
      itemPack.calculatedDiscountTotal = this.getNumberOnDigitFormat(itemPack.calculatedDiscountTotal);
    }
    // itemPack.taxableAmount = (itemPack.grossTotal + itemPack.calculatedRetailTax - itemPack.calculatedDiscountTotal);
    itemPack.taxableAmount = this.subtractTwoNumbers(this.sumTwoNumbers(itemPack.grossTotal, itemPack.calculatedRetailTax), itemPack.calculatedDiscountTotal);
    if (this.calculateTaxBeforeDiscount) {
      // itemPack.calculatedTax = (itemPack.grossTotal + itemPack.calculatedRetailTax) * (itemPack.tax / 100);
      itemPack.calculatedTax = this.multiplyTwoNumbers((this.sumTwoNumbers(itemPack.grossTotal, itemPack.calculatedRetailTax)), (this.divideTwoNumbers(itemPack.tax, 100)));

    }
    else {
      // itemPack.calculatedTax = (itemPack.grossTotal + itemPack.calculatedRetailTax - itemPack.calculatedDiscountTotal) * (itemPack.tax / 100);
      itemPack.calculatedTax = this.multiplyTwoNumbers((this.subtractTwoNumbers(this.sumTwoNumbers(itemPack.grossTotal, itemPack.calculatedRetailTax), itemPack.calculatedDiscountTotal)), (this.divideTwoNumbers(itemPack.tax, 100)));
    }
    if (this.applyRoundingPerLine && !this.applyZATCARoundingRules) {
      itemPack.calculatedTax = this.getNumberOnDigitFormat(itemPack.calculatedTax);
    }

    // itemPack.netTotal = itemPack.grossTotal + itemPack.calculatedRetailTax + itemPack.calculatedTax - itemPack.calculatedDiscountTotal;
    itemPack.netTotal = this.subtractTwoNumbers(this.sumTwoNumbers(this.sumTwoNumbers(itemPack.grossTotal, itemPack.calculatedRetailTax), itemPack.calculatedTax), itemPack.calculatedDiscountTotal);

    // Round pack total to avoid bugs
    if (this.applyRoundingPerLine) {
      itemPack.netTotal = this.getNumberOnDigitFormat(itemPack.netTotal);
    }
    itemPack.isCalculated = true;
  }
  calculateReturnPackTotals(itemPack: any, itemReturnBatchStock?: any[]) {
    // Invoice-level
    var discountAmount = 0;
    var taxAmount = 0;
    var netTotal = 0;
    var grossTotal = 0;
    var actualTotal = 0;
    var calculatedExciseTaxAmount = 0;
    // Pack-level
    var totalRequired = 0;
    var calculatedDiscount = 0;
    var calculatedTax = 0;
    let addedExciseTax = 0;
    let baseExciseTax = 0;

    itemPack.netTotal = 0;
    itemPack.requiredQty = 0;
    itemPack.grossTotal = 0;
    itemPack.calculatedDiscountTotal = 0;
    itemPack.calculatedTaxTotal = 0;
    itemPack.calculatedTax = 0;
    itemPack.availableQtyInPcs = 0;
    itemPack.calculatedRetailTax = 0;
    // Fill default expirydate and batchNo values for now.
    itemPack.batchNo = this.coreSession.DefaultBatchNumber;
    itemPack.expiryDate = this.coreSession.DefaultExpiryDate();
    var d = this.coreSession.DefaultExpiryDate();
    itemPack.expiryDateModel = this.coreSession.getDateTimeModel(d);
    // itemPack.returnFromInvoiceAvailableBatchList.filter(inv => inv.requiredQuantity && inv.requiredQuantity > 0);
    itemPack.returnFromInvoiceAvailableBatchList.forEach(inv => {
      if (this.includeExciseTaxInDiscount) {
        baseExciseTax = inv.baseExciseTax;
      }
      // if(inv.requiredQuantity && inv.requiredQuantity > 0 && !inv.invalid)
      if (inv.requiredQuantity && inv.requiredQuantity > 0) {
        // grossTotal = inv.basePrice * inv.requiredQuantity * itemPack.piecesInPack;
        grossTotal = this.multiplyTwoNumbers(this.multiplyTwoNumbers(inv.basePrice, inv.requiredQuantity), itemPack.piecesInPack);
        // discountAmount = inv.basePrice * (inv.discountPercentage / 100);
        discountAmount = this.multiplyTwoNumbers(this.sumTwoNumbers(inv.basePrice, baseExciseTax), (this.divideTwoNumbers(inv.discountPercentage, 100)));
        // calculatedDiscount = inv.basePrice * inv.requiredQuantity * itemPack.piecesInPack * (inv.discountPercentage / 100);
        calculatedDiscount = this.multiplyTwoNumbers(this.multiplyTwoNumbers(this.multiplyTwoNumbers(this.sumTwoNumbers(inv.basePrice, baseExciseTax), inv.requiredQuantity), itemPack.piecesInPack), (this.divideTwoNumbers(inv.discountPercentage, 100)));

        // calculatedExciseTaxAmount = inv.baseExciseTax * inv.requiredQuantity * itemPack.piecesInPack;
        calculatedExciseTaxAmount = this.multiplyTwoNumbers(this.multiplyTwoNumbers(inv.baseExciseTax, inv.requiredQuantity), itemPack.piecesInPack);
        if (this.applyRoundingPerLine) {
          grossTotal = this.getNumberOnDigitFormat(grossTotal);
          calculatedExciseTaxAmount = this.getNumberOnDigitFormat(calculatedExciseTaxAmount);
        }
        if (this.applyRoundingPerLine) {
          calculatedDiscount = this.getNumberOnDigitFormat(calculatedDiscount);
        }
        if (this.applyRoundingPerLine) {
          discountAmount = this.getNumberOnDigitFormat(discountAmount);
        }
        if (this.calculateTaxBeforeDiscount) {
          // taxAmount = (inv.basePrice + inv.baseExciseTax) * inv.baseTaxPercentage / 100;
          taxAmount = this.divideTwoNumbers(this.multiplyTwoNumbers((this.sumTwoNumbers(inv.basePrice, inv.baseExciseTax)), inv.baseTaxPercentage), 100);
          // calculatedTax = (grossTotal + calculatedExciseTaxAmount) * inv.baseTaxPercentage / 100;
          calculatedTax = this.divideTwoNumbers(this.multiplyTwoNumbers((this.sumTwoNumbers(grossTotal, calculatedExciseTaxAmount)), inv.baseTaxPercentage), 100);

        } else {
          // taxAmount = (inv.basePrice - discountAmount + inv.baseExciseTax) * inv.baseTaxPercentage / 100;
          taxAmount = this.divideTwoNumbers(this.multiplyTwoNumbers((this.sumTwoNumbers(this.subtractTwoNumbers(inv.basePrice, discountAmount), inv.baseExciseTax)), inv.baseTaxPercentage), 100);
          // calculatedTax = (grossTotal - calculatedDiscount + calculatedExciseTaxAmount) * inv.baseTaxPercentage / 100;
          calculatedTax = this.divideTwoNumbers(this.multiplyTwoNumbers((this.sumTwoNumbers(this.subtractTwoNumbers(grossTotal, calculatedDiscount), calculatedExciseTaxAmount)), inv.baseTaxPercentage), 100);
        }
        if (this.applyRoundingPerLine && !this.applyZATCARoundingRules) {
          taxAmount = this.getNumberOnDigitFormat(taxAmount);
        }
        if (this.applyRoundingPerLine && !this.applyZATCARoundingRules) {
          calculatedTax = this.getNumberOnDigitFormat(calculatedTax);
        }
        // netTotal = (inv.basePrice - discountAmount + taxAmount + inv.baseExciseTax);
        // actualTotal = netTotal * inv.requiredQuantity * itemPack.piecesInPack;
        // actualTotal = grossTotal + calculatedExciseTaxAmount + calculatedTax - calculatedDiscount;
        actualTotal = this.subtractTwoNumbers(this.sumTwoNumbers(this.sumTwoNumbers(grossTotal, calculatedExciseTaxAmount), calculatedTax), calculatedDiscount);

        if (this.applyRoundingPerLine) {
          actualTotal = this.getNumberOnDigitFormat(actualTotal);
        }
        // itemPack.netTotal += actualTotal;
        itemPack.netTotal = this.sumTwoNumbers(itemPack.netTotal, actualTotal);
        // itemPack.calculatedDiscountTotal += calculatedDiscount;
        itemPack.calculatedDiscountTotal = this.sumTwoNumbers(itemPack.calculatedDiscountTotal, calculatedDiscount);
        // itemPack.calculatedTaxTotal += calculatedTax;
        itemPack.calculatedTaxTotal = this.sumTwoNumbers(itemPack.calculatedTaxTotal, calculatedTax);
        // itemPack.calculatedTax += calculatedTax;
        itemPack.calculatedTax = this.sumTwoNumbers(itemPack.calculatedTax, calculatedTax);
        // itemPack.calculatedRetailTax += calculatedExciseTaxAmount;
        itemPack.calculatedRetailTax = this.sumTwoNumbers(itemPack.calculatedRetailTax, calculatedExciseTaxAmount);
        // itemPack.grossTotal += grossTotal;
        itemPack.grossTotal = this.sumTwoNumbers(itemPack.grossTotal, grossTotal);
        // itemPack.requiredQty += inv.requiredQuantity;
        itemPack.requiredQty = this.sumTwoNumbers(itemPack.requiredQty, inv.requiredQuantity);

        // totalRequired += 1;
        totalRequired = this.sumTwoNumbers(totalRequired, 1);
      }
    });
    if (this.includeExciseTaxInDiscount) {
      addedExciseTax = itemPack.calculatedRetailTax;
    }
    if (itemPack.requiredQty > 0) {
      // itemPack.price = itemPack.grossTotal / itemPack.requiredQty;
      itemPack.price = this.divideTwoNumbers(itemPack.grossTotal, itemPack.requiredQty);
      // itemPack.basePrice = itemPack.grossTotal / itemPack.requiredQty;
      itemPack.basePrice = this.divideTwoNumbers(itemPack.grossTotal, itemPack.requiredQty);
      // itemPack.discount = itemPack.grossTotal > 0 ? (itemPack.calculatedDiscountTotal / itemPack.grossTotal) * 100 : 0;
      itemPack.discount = itemPack.grossTotal > 0 ? this.multiplyTwoNumbers((this.divideTwoNumbers(itemPack.calculatedDiscountTotal, this.sumTwoNumbers(itemPack.grossTotal, addedExciseTax))), 100) : 0;
      // itemPack.tax = (itemPack.grossTotal - itemPack.calculatedDiscountTotal + itemPack.calculatedRetailTax) > 0 ? (itemPack.calculatedTax / (itemPack.grossTotal - itemPack.calculatedDiscountTotal + itemPack.calculatedRetailTax)) * 100 : 0;
      itemPack.tax = (this.sumTwoNumbers(this.subtractTwoNumbers(itemPack.grossTotal, itemPack.calculatedDiscountTotal), itemPack.calculatedRetailTax)) > 0 ? this.multiplyTwoNumbers((this.divideTwoNumbers(itemPack.calculatedTax, (this.sumTwoNumbers(this.subtractTwoNumbers(itemPack.grossTotal, itemPack.calculatedDiscountTotal), itemPack.calculatedRetailTax)))), 100) : 0;

    }
  }

  calculateFreePackTotal(itemPack: ItemPackModel) {
    itemPack.calculatedTax = 0;
    itemPack.calculatedRetailTax = 0;
    itemPack.calculatedDiscount = 0;
    itemPack.calculatedPromotedDiscount = 0;
    itemPack.calculatedDiscountTotal = 0;
    itemPack.grossTotal = 0;
    itemPack.netTotal = 0;
    itemPack.isCalculated = false;

    if (this.applyTaxOverFreeItems) {
      // itemPack.grossTotal = itemPack.basePrice * itemPack.requiredQty;
      itemPack.grossTotal = this.multiplyTwoNumbers(itemPack.basePrice, itemPack.requiredQty);
      if (this.applyRoundingPerLine) {
        itemPack.grossTotal = this.getNumberOnDigitFormat(itemPack.grossTotal);
      }
      // itemPack.calculatedRetailTax = itemPack.retailValue * itemPack.requiredQty;
      itemPack.calculatedRetailTax = this.multiplyTwoNumbers(itemPack.retailValue, itemPack.requiredQty);
      if (this.applyRoundingPerLine) {
        itemPack.calculatedRetailTax = this.getNumberOnDigitFormat(itemPack.calculatedRetailTax);
      }
      // itemPack.taxableAmount = (itemPack.grossTotal + itemPack.calculatedRetailTax);
      itemPack.taxableAmount = (this.sumTwoNumbers(itemPack.grossTotal, itemPack.calculatedRetailTax));
      // itemPack.calculatedTax = (itemPack.grossTotal + itemPack.calculatedRetailTax) * (itemPack.baseTax / 100);
      itemPack.calculatedTax = this.multiplyTwoNumbers((this.sumTwoNumbers(itemPack.grossTotal, itemPack.calculatedRetailTax)), (this.divideTwoNumbers(itemPack.baseTax, 100)));
      if (this.applyRoundingPerLine && !this.applyZATCARoundingRules) {
        itemPack.calculatedTax = this.getNumberOnDigitFormat(itemPack.calculatedTax);
      }
      // itemPack.netTotal = itemPack.calculatedTax + itemPack.calculatedRetailTax;  // + excise tax
      itemPack.netTotal = this.sumTwoNumbers(itemPack.calculatedTax, itemPack.calculatedRetailTax);  // + excise tax
      // Round pack total to avoid bugs
      itemPack.netTotal = this.getNumberOnDigitFormat(itemPack.netTotal);
      itemPack.grossTotal = 0;
    }
    itemPack.isCalculated = true;
  }
  roundTotals(totals: TotalsModel) {
    if (totals) {
      totals.grossTotal = this.getNumberOnDigitFormat(totals.grossTotal);
      totals.taxTotal = this.getNumberOnDigitFormat(totals.taxTotal);
      totals.retailTaxTotal = this.getNumberOnDigitFormat(totals.retailTaxTotal);
      totals.discountTotal = this.getNumberOnDigitFormat(totals.discountTotal);
      totals.promotedDiscountTotal = this.getNumberOnDigitFormat(totals.promotedDiscountTotal);
      totals.itemsQtyTotal = this.getNumberOnStockDigitFormat(totals.itemsQtyTotal);

      totals.netTotal = this.getNumberOnDigitFormat(totals.netTotal);

    }
  }
  updateTotals(totals: TotalsModel, itemPack: ItemPackModel, addNewPack: boolean) {
    if (!itemPack.isReturnItem) {
      if (addNewPack) {
        // totals.grossTotal = totals.grossTotal + itemPack.grossTotal;
        totals.grossTotal = this.sumTwoNumbers(totals.grossTotal, itemPack.grossTotal);
        // totals.taxTotal = totals.taxTotal + itemPack.calculatedTax;
        // totals.taxTotal = totals.taxTotal + itemPack.calculatedTax;
        totals.taxTotal = this.sumTwoNumbers(totals.taxTotal, itemPack.calculatedTax);
        // totals.retailTaxTotal = totals.retailTaxTotal + itemPack.calculatedRetailTax;
        totals.retailTaxTotal = this.sumTwoNumbers(totals.retailTaxTotal, itemPack.calculatedRetailTax);
        // totals.discountTotal = totals.discountTotal + itemPack.calculatedDiscountTotal;
        totals.discountTotal = this.sumTwoNumbers(totals.discountTotal, itemPack.calculatedDiscountTotal);
        // totals.promotedDiscountTotal = totals.promotedDiscountTotal + itemPack.calculatedPromotedDiscount;
        totals.promotedDiscountTotal = this.sumTwoNumbers(totals.promotedDiscountTotal, itemPack.calculatedPromotedDiscount);
        
      }
      else {
        // totals.grossTotal = totals.grossTotal - itemPack.grossTotal;
        totals.grossTotal = this.subtractTwoNumbers(totals.grossTotal, itemPack.grossTotal);
        // totals.taxTotal = totals.taxTotal - itemPack.calculatedTax;
        totals.taxTotal = this.subtractTwoNumbers(totals.taxTotal, itemPack.calculatedTax);
        // totals.retailTaxTotal = totals.retailTaxTotal - itemPack.calculatedRetailTax;
        totals.retailTaxTotal = this.subtractTwoNumbers(totals.retailTaxTotal, itemPack.calculatedRetailTax);
        // totals.discountTotal = totals.discountTotal - itemPack.calculatedDiscountTotal;
        totals.discountTotal = this.subtractTwoNumbers(totals.discountTotal, itemPack.calculatedDiscountTotal);
        // totals.promotedDiscountTotal = totals.promotedDiscountTotal - itemPack.calculatedPromotedDiscount;
        totals.promotedDiscountTotal = this.subtractTwoNumbers(totals.promotedDiscountTotal, itemPack.calculatedPromotedDiscount);

      }

      // totals.netTotal = this.getNumberOnDigitFormat(totals.grossTotal)
      //   + this.getNumberOnDigitFormat(totals.taxTotal)
      //   + this.getNumberOnDigitFormat(totals.retailTaxTotal)
      //   - this.getNumberOnDigitFormat(totals.discountTotal);
      totals.netTotal = this.subtractTwoNumbers(this.sumTwoNumbers(this.sumTwoNumbers(this.getNumberOnDigitFormat(totals.grossTotal)
        , this.getNumberOnDigitFormat(totals.taxTotal))
        , this.getNumberOnDigitFormat(totals.retailTaxTotal))
        , this.getNumberOnDigitFormat(totals.discountTotal));

    } else {
      if (addNewPack) {
        // totals.grossTotal = totals.grossTotal - itemPack.grossTotal;
        totals.grossTotal = this.subtractTwoNumbers(totals.grossTotal, itemPack.grossTotal);
        // totals.taxTotal = totals.taxTotal - itemPack.calculatedTax;
        totals.taxTotal = this.subtractTwoNumbers(totals.taxTotal, itemPack.calculatedTax);
        // totals.retailTaxTotal = totals.retailTaxTotal - itemPack.calculatedRetailTax;
        totals.retailTaxTotal = this.subtractTwoNumbers(totals.retailTaxTotal, itemPack.calculatedRetailTax);
        // totals.discountTotal = totals.discountTotal - itemPack.calculatedDiscountTotal;
        totals.discountTotal = this.subtractTwoNumbers(totals.discountTotal, itemPack.calculatedDiscountTotal);
        // totals.promotedDiscountTotal = totals.promotedDiscountTotal - itemPack.calculatedPromotedDiscount;
        totals.promotedDiscountTotal = this.subtractTwoNumbers(totals.promotedDiscountTotal, itemPack.calculatedPromotedDiscount);
        // totals.netTotal -= itemPack.netTotal;
        totals.netTotal = this.subtractTwoNumbers(totals.netTotal, itemPack.netTotal);
      } else {
        // totals.grossTotal = totals.grossTotal + itemPack.grossTotal;
        totals.grossTotal = this.sumTwoNumbers(totals.grossTotal, itemPack.grossTotal);
        // totals.taxTotal = totals.taxTotal + itemPack.calculatedTax;
        // totals.taxTotal = totals.taxTotal + itemPack.calculatedTax;
        totals.taxTotal = this.sumTwoNumbers(totals.taxTotal, itemPack.calculatedTax);
        // totals.retailTaxTotal = totals.retailTaxTotal + itemPack.calculatedRetailTax;
        totals.retailTaxTotal = this.sumTwoNumbers(totals.retailTaxTotal, itemPack.calculatedRetailTax);
        // totals.discountTotal = totals.discountTotal + itemPack.calculatedDiscountTotal;
        totals.discountTotal = this.sumTwoNumbers(totals.discountTotal, itemPack.calculatedDiscountTotal);
        // totals.promotedDiscountTotal = totals.promotedDiscountTotal + itemPack.calculatedPromotedDiscount;
        totals.promotedDiscountTotal = this.sumTwoNumbers(totals.promotedDiscountTotal, itemPack.calculatedPromotedDiscount);
        // totals.netTotal = this.getNumberOnDigitFormat(totals.grossTotal)
        //   + this.getNumberOnDigitFormat(totals.taxTotal)
        //   + this.getNumberOnDigitFormat(totals.retailTaxTotal)
        //   - this.getNumberOnDigitFormat(totals.discountTotal);
        totals.netTotal = this.subtractTwoNumbers(this.sumTwoNumbers(this.sumTwoNumbers(this.getNumberOnDigitFormat(totals.grossTotal)
          , this.getNumberOnDigitFormat(totals.taxTotal))
          , this.getNumberOnDigitFormat(totals.retailTaxTotal))
          , this.getNumberOnDigitFormat(totals.discountTotal));
      }
    }
  }
  updateReturnTotals(totals: TotalsModel, itemPack: ItemPackModel, addNewPack: boolean) {
    if (addNewPack) {
      // totals.grossTotal = totals.grossTotal + itemPack.grossTotal;
      totals.grossTotal = this.sumTwoNumbers(totals.grossTotal, itemPack.grossTotal);
      // totals.taxTotal = totals.taxTotal + itemPack.calculatedTax;
      totals.taxTotal = this.sumTwoNumbers(totals.taxTotal, itemPack.calculatedTax);
      // totals.retailTaxTotal = totals.retailTaxTotal + itemPack.calculatedRetailTax;
      totals.retailTaxTotal = this.sumTwoNumbers(totals.retailTaxTotal, itemPack.calculatedRetailTax);
      // totals.discountTotal = totals.discountTotal + itemPack.calculatedDiscountTotal;
      totals.discountTotal = this.sumTwoNumbers(totals.discountTotal, itemPack.calculatedDiscountTotal);
      // totals.promotedDiscountTotal = totals.promotedDiscountTotal + itemPack.calculatedPromotedDiscount;
      totals.promotedDiscountTotal = this.sumTwoNumbers(totals.promotedDiscountTotal, itemPack.calculatedPromotedDiscount);
      // totals.netTotal += itemPack.netTotal;
    }
    else {
      // totals.grossTotal = totals.grossTotal - itemPack.grossTotal;
      totals.grossTotal = this.subtractTwoNumbers(totals.grossTotal, itemPack.grossTotal);
      // totals.taxTotal = totals.taxTotal - itemPack.calculatedTax;
      totals.taxTotal = this.subtractTwoNumbers(totals.taxTotal, itemPack.calculatedTax);
      // totals.retailTaxTotal = totals.retailTaxTotal - itemPack.calculatedRetailTax;
      totals.retailTaxTotal = this.subtractTwoNumbers(totals.retailTaxTotal, itemPack.calculatedRetailTax);
      // totals.discountTotal = totals.discountTotal - itemPack.calculatedDiscountTotal;
      totals.discountTotal = this.subtractTwoNumbers(totals.discountTotal, itemPack.calculatedDiscountTotal);
      // totals.promotedDiscountTotal = totals.promotedDiscountTotal - itemPack.calculatedPromotedDiscount;
      totals.promotedDiscountTotal = this.subtractTwoNumbers(totals.promotedDiscountTotal, itemPack.calculatedPromotedDiscount);
      // totals.netTotal -= itemPack.netTotal;
    }
    // totals.netTotal = this.getNumberOnDigitFormat(totals.grossTotal)
    //   + this.getNumberOnDigitFormat(totals.taxTotal)
    //   + this.getNumberOnDigitFormat(totals.retailTaxTotal)
    //   - this.getNumberOnDigitFormat(totals.discountTotal);
    totals.netTotal = this.subtractTwoNumbers(this.sumTwoNumbers(this.sumTwoNumbers(this.getNumberOnDigitFormat(totals.grossTotal), this.getNumberOnDigitFormat(totals.taxTotal))
      , this.getNumberOnDigitFormat(totals.retailTaxTotal))
      , this.getNumberOnDigitFormat(totals.discountTotal));
  }
  fillItemsCount(itemsList) {
    // items count represents distinct items count regardless packs and sales types
    var count = 0;
    var distinctItemIds = [];
    for (var i = 0; i < itemsList.length; i++) {
      if (distinctItemIds.findIndex(x => x === itemsList[i].itemId) < 0) {
        distinctItemIds.push(itemsList[i].itemId);
      }
    }
    count = distinctItemIds.length;
    return count;
  }
  calculateTotalAfterPromotions(totals: TotalsModel, itemsList: ItemPackModel[]) {
    for (let pack of itemsList) {
      if (pack.salesTransactionTypeId == SalesTransactionTypes.Promotion || pack.salesTransactionTypeId == SalesTransactionTypes.FOC) {
        this.calculateFreePackTotal(pack);
      }
      else {
        this.calculatePackTotal(pack);
      }

      this.updateTotals(totals, pack, true);
    }
    totals.itemsCount = this.fillItemsCount(itemsList);
    totals.itemsQtyTotal = 0;
    itemsList.forEach(item => totals.itemsQtyTotal = totals.itemsQtyTotal + item.requiredQty);
    this.roundTotals(totals);
  }

  recalculateTotalRetrunsFromInvoice(totals: TotalsModel, itemsList: ItemPackModel[]) {

    itemsList.forEach((pack) => {
      if (pack.salesTransactionTypeId !== SalesTransactionTypes.FreeReturn) {
        this.calculateReturnPackTotals(pack);
        this.updateReturnTotals(totals, pack, true);
      }
    });
    totals.itemsCount = this.fillItemsCount(itemsList);
    totals.itemsQtyTotal = 0;
    itemsList.forEach(item => totals.itemsQtyTotal = totals.itemsQtyTotal + item.requiredQty);
    this.roundTotals(totals);
  }

  calculateTotalAfterCancelPromotions(totals: TotalsModel, itemsList: ItemPackModel[]) {
    for (let pack of itemsList) {
      if (pack.salesTransactionTypeId == SalesTransactionTypes.FOC)
        pack.requiredFOCQty = pack.requiredQty;
      if (pack.salesTransactionTypeId != SalesTransactionTypes.Promotion
        && pack.salesTransactionTypeId != SalesTransactionTypes.FOC) {
        pack.promotedDiscount = 0;
        pack.itemPromotedDiscount = 0;
        pack.calculatedPromotedDiscount = 0;
        pack.allItemDiscount = 0;
        pack.exciseContractPromotedDiscount = 0;
        pack.wholeSalesPromotedDiscount = 0;
        pack.dynamicPromotedDiscount = 0;
        this.calculatePackTotal(pack);
        this.updateTotals(totals, pack, true);
      }
    }
    totals.itemsCount = this.fillItemsCount(itemsList);
    totals.itemsQtyTotal = 0;
    itemsList.forEach(item => totals.itemsQtyTotal = totals.itemsQtyTotal + item.requiredQty);
    //this.roundTotals(totals);
  }
  cancelItemAndAllItemsPromotions(totals: TotalsModel, itemsList: ItemPackModel[]) {
    // totals.netTotal = totals.netTotal + totals.promotedDiscountTotal;
    totals.netTotal = this.sumTwoNumbers(totals.netTotal, totals.promotedDiscountTotal);
    totals.promotedDiscountTotal = 0;
    for (let pack of itemsList) {
      if (pack.salesTransactionTypeId != SalesTransactionTypes.Promotion
        && pack.salesTransactionTypeId != SalesTransactionTypes.FOC) {
        pack.promotedDiscount = 0;
        pack.itemPromotedDiscount = 0;
        pack.calculatedPromotedDiscount = 0;
        pack.allItemDiscount = 0;
        pack.exciseContractPromotedDiscount = 0;
        pack.wholeSalesPromotedDiscount = 0;
        pack.dynamicPromotedDiscount = 0;
        pack.seqFactor = 1;
      }
    }
    totals.itemsCount = this.fillItemsCount(itemsList);
    totals.itemsQtyTotal = 0;
    itemsList.forEach(item => totals.itemsQtyTotal = totals.itemsQtyTotal + item.requiredQty);
    //this.roundTotals(totals);
  }
  calculateTotalAfterCancelPromotionsForDelivery(totals: TotalsModel, itemsList: ItemPackModel[]) { // in Full Delivery case
    for (let pack of itemsList) {
      if (pack.salesTransactionTypeId != SalesTransactionTypes.Promotion
        && pack.salesTransactionTypeId != SalesTransactionTypes.FOC) {
        // pack.promotedDiscount = pack.itemPromotedDiscount + pack.allItemDiscount;
        pack.promotedDiscount = this.sumTwoNumbers(pack.itemPromotedDiscount, pack.allItemDiscount);
        pack.exciseContractPromotedDiscount = 0;
        pack.wholeSalesPromotedDiscount = 0;
        pack.dynamicPromotedDiscount = 0;
        this.calculatePackTotal(pack);
        this.updateTotals(totals, pack, true);
      }
    }
    totals.itemsCount = this.fillItemsCount(itemsList);
    totals.itemsQtyTotal = 0;
    itemsList.forEach(item => totals.itemsQtyTotal = totals.itemsQtyTotal + item.requiredQty);
    //this.roundTotals(totals);
  }
  formatNumeric(value, decimal) {
    return this.customRound(value, decimal);
  }
  multiplyTwoNumbers(num1, num2) {
    return +(Decimal.mul(num1, num2)).toString();
  }
  divideTwoNumbers(num1, num2) {
    return +(Decimal.div(num1, num2)).toString();
  }
  subtractTwoNumbers(num1, num2) {
    return +(Decimal.sub(num1, num2)).toString();
  }
  sumTwoNumbers(num1, num2) {
    return +(new Decimal(num1).plus(num2)).toString();
  }
}
