import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { AuthService } from "../../core/auth/auth.service";
import { HttpErrorResponse } from "@angular/common/http";
import {
  OperatorForLogin,
} from "../../shared/models/operator/operator.model";
import { LoginService } from "./login.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ModalLoadingComponent } from "../../shared/components/modal-loading/modal-loading.component";
import { ToastyService } from "ng2-toasty";
import { ConstantMessages } from "../../shared/models/constants/constant-message";
import { CoreSession } from "../../core/core.session";
import { TranslateService } from "@ngx-translate/core";
import { SessionDataProvider } from "../../core/session-data-provider.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConstantURLs } from "../../shared/models/constants/constant-URL";
import { GlobalErrors } from "../../shared/models/enums/globalErrors";
import { ResponseModel } from "../../shared/models/api-models/api-models";
import { DeviceDetectorService } from "ngx-device-detector";
import { DialogResult } from "../../shared/models/enums/dialog-result.enum";
import { DialogMode } from "../../shared/models/enums/dialog-mode.enum";
import { ModalDialogComponent } from "../../shared/components/modal-dialog/modal-dialog.component";
import { AESEncryptionService } from "../../shared/services/EncrDecrService.service";
import { ConstantConfigurations } from "../../shared/models/constants/constant-configuration";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, AfterViewInit {
  //#region [DECLARATIONS]

  public loginForm: FormGroup;
  public changePasswordForm: FormGroup;
  operator: OperatorForLogin = new OperatorForLogin();
  isChangePassword: boolean = false;
  currentYear = "";
  logoPath = '';
  backgroundPath = '';
  @ViewChild("loadingModal", { static: true })
  loadingModal: ModalLoadingComponent;
  @ViewChild("dialogModal", { static: true }) dialogModal: ModalDialogComponent;
  @ViewChild("userName") userName: ElementRef;
  ForceChangeOperatorPasswordAtFirstLogin = false;

  langList: string[];
  currentLang = "";
  deviceInfo = null;
  private timeOutDelay = 25000;
  type1: string = "password";
  type2: string = "password";
  type3: string = "password";
  eyeIcon: string = "fa fa-eye";
  slashIcon: string = "fa fa-eye-slash";
  //#endregion

  //#region [INITIALIZATION]

  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    public coreSession: CoreSession,
    private sessionDataProvider: SessionDataProvider,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(ToastyService) private toastyService: ToastyService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private secService: AESEncryptionService
  ) { }
  ngOnInit() {
    this.ForceChangeOperatorPasswordAtFirstLogin = this.sessionDataProvider.getConfigurationValue(ConstantConfigurations.ForceChangeOperatorPasswordAtFirstLogin).toLowerCase() === "true"
    this.logoPath = this.coreSession.baseURL + 'assets/img/sonic_main_logo.png';
    this.backgroundPath = "url('" + this.coreSession.baseURL + "assets/img/background.png') no-repeat";
    this.currentYear = new Date().getFullYear().toString();
    this.loginForm = new FormGroup({
      username: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
    });
    if (!this.route.snapshot.data.isSettedUp && !this.isChangePassword) {
      this.router.navigate(["/" + ConstantURLs.setupURL]);
    }
    if (this.authService.isTokenExist() && this.authService.isAuthenticated()) {
      this.authService.navigateToHomeScreen();
      return;
    }
    this.langList = this.translate.getLangs();
    this.currentLang = this.coreSession.selectedLanguageStr;
  }
  ngAfterViewInit() {
    // if (!this.isChangePassword)
    //   this.userName.nativeElement.focus();
  }
  //#endregion

  //#region [METHODS]

  initiateChangePassForm() {
    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl("", Validators.required),
      newPassword: new FormControl("", Validators.required),
      confirmPassword: new FormControl("", Validators.required),
    });
  }
  callLoginService() {
    this.loadingModal.Show();
    this.loginService.login(this.operator).subscribe(
      (result) => {
        result;
        if (result.status >= 0) {
          switch (result.data) {
            case GlobalErrors.PasswordShouldBeChanged:
              this.loadingModal.Hide();
              this.isChangePassword = true;
              this.initiateChangePassForm();
              this.coreSession.showWarrning(
                this.translate.instant(ConstantMessages.WarningCaption),
                result.message
              );
              this.oldPassword.setValue("");
              this.newPassword.setValue("");
              this.confirmPassword.setValue("");
              break;
            case GlobalErrors.LoggedInOtherSession:
              this.loadingModal.Hide();
              this.dialogModal
                .ShowMessage(
                  this.translate.instant(result.message),
                  DialogMode.YesNo,
                  this.translate.instant(ConstantMessages.WarningCaption)
                )
                .then((result: DialogResult) => {
                  if (result === DialogResult.Yes) {
                    this.operator.RemoveCurrentOperatorSession = true;
                    this.callLoginService();
                  }
                });
              break;
            default:
              if (result.data.isNearToExpired)
                this.coreSession.showWarrning(
                  this.translate.instant(ConstantMessages.WarningCaption),
                  result.message
                );
              let employeeId = result.data.operatorModel.employeeId;

              let decryptedAllAvailableMenus = this.secService.decryptData(result.data.menuList, employeeId);
              let decryptedAllAvailableMenusModel = JSON.parse(decryptedAllAvailableMenus);
              result.data.menuList = decryptedAllAvailableMenusModel;

              let decryptedAddedMenusList = this.secService.decryptData(result.data.addedList, employeeId);
              let decryptedAddedMenusListModel = JSON.parse(decryptedAddedMenusList);
              result.data.addedList = decryptedAddedMenusListModel;

              let decryptedConfigurationsList = this.secService.decryptData(result.data.configurationsList, employeeId);
              let decryptedConfigurationsListModel = JSON.parse(decryptedConfigurationsList);
              result.data.configurationsList = decryptedConfigurationsListModel;

              this.sessionDataProvider.GeneralConfigurations =
                result.data.configurationsList;
              this.sessionDataProvider.DivisionConfigurations =
                result.data.ConfigurationsListOnDivisionLevel;
              this.coreSession.showPrivacypolicy = result.data.showPrivacy;
              this.coreSession.privacyText = result.data.privacyText;
              this.coreSession.isPrivacyLoaded = true;
              this.authService.login(
                result.data.operatorModel,
                result.data.key,
                result.data.generalData,
                result.data.menuList,
                result.data.addedList
              );
              break;
          }
        } else {
          this.loadingModal.Hide();
          this.coreSession.showError(
            this.translate.instant(ConstantMessages.ErrorCaption),
            result.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.loadingModal.Hide();
        this.coreSession.showError(
          this.translate.instant(ConstantMessages.ErrorCaption),
          this.translate.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  login() {
    if (this.loginForm.valid) {
      var start = new Date().getTime();
      this.sessionDataProvider.allMenusAvailable = [];
      this.sessionDataProvider.addedMenus = [];

      this.operator = new OperatorForLogin();
      this.operator.username = this.username.value;
      this.operator.password = this.password.value;
      this.operator.languageId = this.coreSession.selectedLanguageId;
      this.deviceInfo = this.deviceService.getDeviceInfo().userAgent;
      this.operator.deviceInfo = this.deviceInfo;
      this.callLoginService();
    } else {
      this.coreSession.showError(
        this.translate.instant(ConstantMessages.ErrorCaption),
        this.translate.instant(ConstantMessages.InsertUserAndPass)
      );
    }
  }
  ChangePassword() {
    if (this.changePasswordForm.invalid) {
      this.coreSession.markFormGroupTouched(
        this.changePasswordForm as FormGroup
      );
      this.coreSession.showWarrning(
        this.translate.instant(ConstantMessages.WarningCaption),
        this.translate.instant(ConstantMessages.MsgFillMandatory)
      );
      return;
    } else if (this.ForceChangeOperatorPasswordAtFirstLogin && (this.newPassword.value == this.oldPassword.value)) {
      this.coreSession.showError(
        this.translate.instant(ConstantMessages.ErrorCaption),
        this.translate.instant(ConstantMessages.MsgNewPasswordShouldBeDifferent)
      );
      return;
    }
    else {
      if (this.newPassword.value !== this.confirmPassword.value) {
        this.coreSession.showError(
          this.translate.instant(ConstantMessages.ErrorCaption),
          this.translate.instant(ConstantMessages.MsgPasswordsNotMatches)
        );
        return;
      }
      this.loadingModal.Show();
      this.loginService
        .changePassword(
          this.oldPassword.value,
          this.newPassword.value,
          this.operator.username
        )
        .subscribe(
          (response: ResponseModel) => {
            if (response.status != null && response.status >= 0) {
              this.operator.password = this.newPassword.value;
              this.callLoginService();
            } else {
              this.coreSession.showWarrningWithTimeOutDelay(
                this.translate.instant(ConstantMessages.WarningCaption),
                response.message,
                this.timeOutDelay
              );
              this.loadingModal.Hide();
            }
          },
          (error: HttpErrorResponse) => {
            this.loadingModal.Hide();
            this.coreSession.showError(
              this.translate.instant(ConstantMessages.ErrorCaption),
              this.translate.instant(ConstantMessages.ErrorHappened)
            );
          }
        );
    }
  }
  showError(msg) {
    this.loginForm.reset();
    this.changePasswordForm.reset();
    this.username.setValue("");
    this.password.setValue("");
    this.oldPassword.setValue("");
    this.newPassword.setValue("");
    this.confirmPassword.setValue("");
    this.toastyService.error({
      title: "Error",
      msg: msg,
      theme: "material",
      showClose: true,
      timeout: 5000,
    });
  }

  onTogglePass(type: any) {
    switch (type) {
      case 1:
        if (this.type1 == "password") this.type1 = "text";
        else this.type1 = "password";
        break;
      case 2:
        if (this.type2 == "password") this.type2 = "text";
        else this.type2 = "password";
        break;
      case 3:
        if (this.type3 == "password") this.type3 = "text";
        else this.type3 = "password";
        break;
    }
  }

  //#endregion

  //#region [PROPERTIES]

  // Login
  get username() {
    return this.loginForm.get("username");
  }
  get password() {
    return this.loginForm.get("password");
  }
  // Change Password
  get oldPassword() {
    return this.changePasswordForm.get("oldPassword");
  }
  get newPassword() {
    return this.changePasswordForm.get("newPassword");
  }
  get confirmPassword() {
    return this.changePasswordForm.get("confirmPassword");
  }
  //#endregion
}
