import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CoreSession } from '../core.session';
import { OperatorModel, CachedGeneralData } from '../../shared/models/operator/operator.model';
import { ConstantStorage } from '../../shared/models/constants/constant-stroage';
import { LoginService } from '../../content/login/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantMessages } from '../../shared/models/constants/constant-message';
import { DialogMode } from '../../shared/models/enums/dialog-mode.enum';
import { ConstantURLs } from '../../shared/models/constants/constant-URL';
import { MenuModel } from '../../shared/models/menu/menu.model';
import { SessionDataProvider } from '../session-data-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ChattingUser } from '../../shared/models/chatting/chatting-user.model';
import { ChattingConnectionStatus } from '../../shared/models/enums/chatting/chatting-connection-status.enum';
import { ChattingService } from '../../content/chatting/chatting.service';


@Injectable()
export class AuthService {
  isMenuAvailable = false;

  constructor(private router: Router, private coreSession: CoreSession,
    private loginService: LoginService,
    private sessionDataProvider: SessionDataProvider,
    private translateService: TranslateService,
    ) {
  }
  checkMenuAvailability() {
    this.isMenuAvailable = true;
  }

  login(operator: OperatorModel, key: string, generalData: CachedGeneralData, menuList: MenuModel[], addedList: MenuModel[]) {
    this.coreSession.CurrentOperator = operator;
    this.coreSession.cachedGeneralData = generalData;
    this.sessionDataProvider.addedMenus = addedList;
    this.sessionDataProvider.allMenusAvailable = menuList;
    // this.sessionDataProvider.addedMenus = addedList;
    // CASH DATA IN STORAGE
    this.coreSession.CurrentOperator.isLoggedIn = true;
    localStorage.setItem(ConstantStorage.TokenName, key);
    localStorage.setItem(ConstantStorage.CurrentOperator, JSON.stringify(this.coreSession.CurrentOperator));
    localStorage.setItem(ConstantStorage.generalData, JSON.stringify(this.coreSession.cachedGeneralData));
    this.navigateToHomeScreen();
    
  }

  navigateToHomeScreen() {  
    if (this.coreSession.CurrentOperator.landingMenu && this.coreSession.CurrentOperator.landingMenu.menuId !== '') {      
      this.router.navigate(['/' + this.coreSession.CurrentOperator.landingMenu.url]);
    } else {
        if (this.sessionDataProvider.allMenusAvailable.filter(x => x.isWebMenu).length < 1) {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.NoMenusAvailable));
        this.router.navigate(['/' + ConstantURLs.noMenusURL]);        
      }
      else if (this.sessionDataProvider.allMenusAvailable.filter(x => x.url === ConstantURLs.orderManagementURL).length > 0) {
        this.router.navigate(['/' + ConstantURLs.orderManagementURL]);        
      } else {   
        var index = this.sessionDataProvider.allMenusAvailable.findIndex(x => !x.menuId.includes('-') && x.isWebMenu);
        if(index >= 0){
          this.router.navigate(['/' + this.sessionDataProvider.allMenusAvailable[index].url + '/' + (this.sessionDataProvider.allMenusAvailable[index].childMenus && this.sessionDataProvider.allMenusAvailable[index].childMenus.length > 0 ? this.sessionDataProvider.allMenusAvailable[index].childMenus[0].url : this.sessionDataProvider.allMenusAvailable[index].url)]);
        }else{
          this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.NoMenusAvailable));
          this.router.navigate(['/' + ConstantURLs.noMenusURL]);    
        }
      }
    }
  }

  logout() {

    this.coreSession.ModalLoading.Show();
      this.loginService.logout().subscribe(result => {
        if (result.status >= 0) {
          if (localStorage.length > 0) {
            localStorage.removeItem(ConstantStorage.TokenName);
            localStorage.removeItem(ConstantStorage.CurrentOperator);
            localStorage.removeItem(ConstantStorage.generalData);
            localStorage.removeItem(ConstantStorage.LastAction);
          }
          // this.loggedIn.next(false);
          this.sessionDataProvider.addedMenus = [];
          this.sessionDataProvider.allMenusAvailable = [];

          this.coreSession.SetTitle("Login");
          this.router.navigate(['/' + ConstantURLs.loginURL]);
     
        } else {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), result.message);
        }
      }, (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption),this.translateService.instant(ConstantMessages.ErrorHappened));
      });
  }

  public isAuthenticated(): boolean {
    const jwtHelper = new JwtHelperService();
    const token = localStorage.getItem(ConstantStorage.TokenName); 
    return !jwtHelper.isTokenExpired(token);
  }

  public isTokenExist(): boolean {
    const token = localStorage.getItem(ConstantStorage.TokenName); 
     return !(token == undefined);
  }

  public getTokenExpirationDate(): Date | null{
    const jwtHelper = new JwtHelperService();
    const token = localStorage.getItem(ConstantStorage.TokenName); 
    if(token && !jwtHelper.isTokenExpired(token)){
      return jwtHelper.getTokenExpirationDate(localStorage.getItem('TokenKey'));
    }else{
      return null;
    }
  }
}
