import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ResponseModel } from "../../shared/models/api-models/api-models";

@Injectable({
  providedIn: "root"
})
export class DashboardService {
  private url = "";
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.url = baseUrl + "Dashboard";
  }
  // getEndorsementDashboards(routeHistoryId: number) {
  //   const urlValue: string = this.url + "/GetEndorsementDashboards";
  //   return this.http.post<ResponseModel>(urlValue, routeHistoryId).map(response => {
  //     return response;
  //   });
  // }
  getAvailableDashboard() {
    const urlValue: string = this.url + "/GetAvailableDashboard";
    return this.http.post<ResponseModel>(urlValue, "").map(response => {
      return response;
    });
  }
  getAvailableAdvancedToolReport() {
    const urlValue: string = this.url + "/GetAvailableAdvancedToolReport";
    return this.http.post<ResponseModel>(urlValue, "").map(response => {
      return response;
    });
  }
  getAvailableReport() {
    const urlValue: string = this.url + "/GetAvailableReport";
    return this.http.post<ResponseModel>(urlValue, "").map(response => {
      return response;
    });
  }
  getOperationDashboardId(id) {
    const urlValue: string = this.url + "/GetOperationDashboardId";
    return this.http.post<ResponseModel>(urlValue, id).map(response => {
      return response;
    });
  }

  getEndOfDayReoports(routeHistoryId) {
    const urlValue: string = this.url + "/GetEndOfDayReoports";
    return this.http.post<ResponseModel>(urlValue, routeHistoryId).map(
        response => {
      return  response;
    });
  }
}
