import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from './core.session';
import { ResponseModel } from '../shared/models/api-models/api-models';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  httpOptions;
  private url = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Notification';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }
  sendNotification(notification) {
    const urlValue: string = this.url + '/SendNotification';
    return this.http.post(urlValue, JSON.stringify(notification), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }
  setNotificationReadFlag(notificationFilter) {
    const urlValue: string = this.url + '/SetNotificationReadFlag';
    return this.http.post(urlValue, JSON.stringify(notificationFilter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }
  getEmployeeNotificationsLazyLoading(currentNotificationCount: number) {
    const urlValue: string = this.url + '/GetEmployeeNotificationsLazyLoading';
    return this.http.post<ResponseModel>(urlValue, currentNotificationCount).map(
      (response) => {
        let result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getUnreadNotificationsCount() {
    const urlValue: string = this.url + '/GetEmployeeUnreadNotificationsCount';
    return this.http.get(urlValue).map(
      (response) => {
        let result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getNotificationTemplateListSharedTable(filter: any) {
    const urlValue: string = this.url + '/GetNotificationTemplateListSharedTable';
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  } 
  
  isValidToGetCCNotificationType() {
    const urlValue: string = this.url + '/IsValidToGetCommCalcNotificationType';
    return this.http.post(urlValue, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getNotificationTemplateData(templateId: number) {
    const urlValue = this.url + '/GetNotificationTemplateData';
    return this.http.post<ResponseModel>(urlValue, templateId).map(response => {
      return response;
    });
  }
  saveNotificationTemplate(template: any) {
    const urlValue: string = this.url + '/InsertNotificationTemplate';
    return this.http.post(urlValue, JSON.stringify(template), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  updateNotificationTemplateActiveStatus(filter) {
    const urlValue: string = this.url + '/UpdateNotificationTemplateActiveStatus';
    return this.http.post(urlValue, filter).map(
      (response) => {
        let result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  GetNotificationTemplateList(notificationTypeId: number) {
    const urlValue = this.url + '/GetNotificationTemplateList';
    return this.http.post<ResponseModel>(urlValue, notificationTypeId).map(response => {
      return response.data;
    });
  }
}
