import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CustomerSubClassModel } from '../../../../models/customer/customer-class.model';
import { FormGroup } from '@angular/forms';
import { EntryFormTypes } from '../../../../models/enums/entry-form-types.enum';
import { Subject } from 'rxjs';
import { CustomerService } from '../../../../services/data-definition/customer/customers.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedDialogComponent } from '../../../shared-dialog/shared-dialog.component';

@Component({
  selector: 'app-customer-sub-class-filter',
  templateUrl: './customer-sub-class-filter.component.html',
  styleUrls: ['./customer-sub-class-filter.component.css']
})
export class CustomerSubClassFilterComponent implements OnInit {

  @ViewChild("templateEntry", { static: true }) templateEntry: SharedDialogComponent;
  isClassLoading = false;
  lastAddedCustomerClassId:number;
  customerClassDataSource: CustomerSubClassModel[] = [];
  @Input() parentForm: FormGroup;
  @Input() isAddNewHidden:boolean = false;
  @Input() label:string = 'Desc_Customer_Sub_Class';
  entryFormType:EntryFormTypes=EntryFormTypes.CustomerSubClass;
  saveSubject: Subject<void> = new Subject<void>();

  constructor(private customerService:CustomerService,private modalService: NgbModal) { }


  ngOnInit() {
    this.populateClassesList();
    this.lastAddedCustomerClassId=this.parentForm.value.customerSubClassId;
  }

  populateClassesList() {
    this.isClassLoading = true;
    this.customerService.getCustomerSubClasses().subscribe(result => {
      this.customerClassDataSource = result.data;

      this.isClassLoading = false;
    });
  }

  onAddingNewCustomerClass(customerClassId:number){
    this.populateClassesList();
    this.lastAddedCustomerClassId=customerClassId;
    this.onCloseClassClicked();
  }

  onSaveClassClicked() {
    this.saveSubject.next();
  }
  onCloseClassClicked() {
    this.templateEntry.Close();
  }
  showSubClassModal() {
    this.templateEntry.Show(true).then(
      (res) => {
      }
    );
  }

  onDialogResult(event) {
    if (event && event.saveCLicked) {
      this.onSaveClassClicked();
    }
  }

}


