import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-commission-calc-price',
  templateUrl: './commission-calc-price.component.html',
  styleUrls: ['./commission-calc-price.component.css']
})
export class CommissionCalcPriceComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() currentIsPointControlName = 'isPoint';
  @Input() currentIsSpecialPointPriceName = 'isSpecialPointPrice';
  @Input() currentPointPriceName = 'pointPrice';
  @Output() onIsPointChangeEmit = new EventEmitter();
  @Input() onSpecialPointPriceChangeEmit = new EventEmitter();
  @Input() events: Observable<void>;
  resetSubscription: any;
  viewSpecialPointPrice: boolean = false;
  viewPointPrice: boolean = false;


  constructor() { }

  ngOnInit() {
    this.resetDetails();
  }
  resetDetails() {
    this.resetSubscription = this.events.subscribe(() => {
      this.onIsPointChange(null);
    });
  }
  onIsPointChange(event) {

    this.getIsSpecialPointPrice().setValue(false);
    if (this.getIsPoint().value) {
      this.viewSpecialPointPrice = true;
      this.viewPointPrice = true;
      this.getPointPrice().setValidators(Validators.required);
    } else {
      this.viewSpecialPointPrice = false;
      this.viewPointPrice = false;
      this.getPointPrice().setValidators(Validators.nullValidator);
    }
    this.getPointPrice().updateValueAndValidity();
    this.onIsPointChangeEmit.emit(this.getIsPoint().value);

  }

  onSpecialPointPriceChange(event) {

    if (this.getIsSpecialPointPrice().value) {
      this.viewPointPrice = false;
      this.getPointPrice().setValidators(Validators.nullValidator);
    } else {
      this.viewPointPrice = true;
      this.getPointPrice().setValidators(Validators.required);
      this.getIsSpecialPointPrice().setValue(false);
    }
    this.getPointPrice().updateValueAndValidity();
    this.onSpecialPointPriceChangeEmit.emit(this.getIsSpecialPointPrice().value);
  }


  getIsPoint() {
    return this.parentForm.get(this.currentIsPointControlName);
  }

  getIsSpecialPointPrice() {
    return this.parentForm.get(this.currentIsSpecialPointPriceName);
  }

  getPointPrice() {
    return this.parentForm.get(this.currentPointPriceName);
  }

}
