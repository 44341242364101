import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { DialogMode } from '../../../models/enums/dialog-mode.enum';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ItemPackModel } from '../../../models/Item/item.model';
import { SerialsService } from '../serials.service';
import { SerialsPropertiesInterface } from '../serials-properties.interface';
import { ResponseModel } from '../../../models/api-models/api-models';
import { HttpErrorResponse } from '@angular/common/http';
import { SerialValidationErrors } from '../../../models/enums/serial-validation-errors.enum';
import { IDateTimePickerProperties } from '../../../models/dateTimeModel/date-time-properties.interface';
import { DateTimeModel } from '../../../models/dateTimeModel/date-time.model';
import { DatePipe } from '@angular/common';
import * as cloneDeep from "lodash/cloneDeep";
import { constants } from "os";
import { InvalidSerialsComponent } from "../invalid-serials/invalid-serials.component";
import { DialogResult } from "../../../models/enums/dialog-result.enum";
import { SalesTransactionTypes } from "../../../models/enums/sales-transaction-types";
@Component({
  selector: 'app-add-serials',
  templateUrl: './add-serials.component.html',
  styleUrls: ['./add-serials.component.css'],
  providers: [DatePipe]
})
export class AddSerialsComponent implements OnInit, OnChanges {


  showSubSerials = false;
  @Input("formProcessMode") formProcessMode: FormProcessMode ;
  @Input() events: Observable<void>;
  @Input() serialsProperties: SerialsPropertiesInterface;
  @Input() addedSerialsForAllPacks = [];
  @Input() isFromSSCC=false;
  @Output() onSaveCompleted = new EventEmitter();
  @ViewChild('toSerialControl', { static: false }) toSerialControl: ElementRef;
  @ViewChild('fromSerialControl', { static: false }) fromSerialControl: ElementRef;
  @ViewChild("invalidSerialsDialog", { static: true }) invalidSerialsDialog: InvalidSerialsComponent;
  headerString = '';
  saveBtnSubscription: any;
  addSerialCaption = 'Desc_Add';
  saveSubject: Subject<void> = new Subject<void>();
  addSerialForm: FormGroup = new FormGroup({});
  forceSingleSerials = false;
  serialTrimStartLength = 0;
  serialTrimEndLength = 0;
  blockManualInsertionForBatchNo = false;
  addedSerials = [];
  invalidSerials = [];
  temp_addedSerials = [];
  singleSerial = 2;
  rangeSerial = 1;
  isSingle = true;
  isGenerate = false;
  isAddOption=true;
  isRemoveOption=false;
  removeOption=1;
  showSerialsAsRange=true;
  addedSerialsCount=0;

  //navLinks: any[];
  options:any[];
  navBarSelectedIndex: number = 0;
  optionSelectedIndex:number=0;
  dateFormat: string;
  defaultSerialScanningType=2;
  expiryDateProperties: IDateTimePickerProperties = {
    label: 'Desc_ExpiryDate',
    formControlName: 'expiryDate',
    isCalendarOnly: true,
    labelHidden: true
  };
  productionDateProperties: IDateTimePickerProperties = {
    label: 'Desc_ProductionDate',
    formControlName: 'productionDate',
    isCalendarOnly: true,
    labelHidden: true
  };
  productionDate: DateTimeModel = new DateTimeModel();
  desiredExpiryDate: DateTimeModel = new DateTimeModel();
  batchNo: string = '';
  enableBatch = false;
  invalidSerialsDataSource: SharedTableResult = {
    data: [],
    totalItems: 0
  };
 
  isBackToStoreOffload = false;
  constructor(
    public coreSession: CoreSession,
    private translateService: TranslateService,
    private sessionData: SessionDataProvider,
    private serialsService: SerialsService,
    private datepipe: DatePipe) { }

  ngOnInit() {
    
    this.enableBatch = this.serialsProperties.packObj.enableBatchForSerial && (this.isInputVoucher() || this.isAggregate());
    this.saveBtn();
    this.fillConfigurationValues();
    this.initForm();
    this.prepareNavigation();

    //this.navLinks[0].isActive = true;
    this.options[0].isActive=true;
    if (!this.serialsProperties.transactionRecallId || this.serialsProperties.transactionRecallId === null) {
      this.serialsProperties.transactionRecallId = -1;
    }
    if (!this.serialsProperties.transactionReadingId || this.serialsProperties.transactionReadingId === null) {
      this.serialsProperties.transactionReadingId = -1;
    }

    if(this.serialsProperties.packObj.isFOCItem){
      if (
        this.serialsProperties.packObj.addedFOCSerials &&
        this.serialsProperties.packObj.addedFOCSerials.length > 0
      ) {
        this.addedSerials = this.serialsProperties.packObj.addedFOCSerials;
        this.fillAddedSerialsCount(null);
      }
    }else{
      if (
        this.serialsProperties.packObj.addedSerials &&
        this.serialsProperties.packObj.addedSerials.length > 0
      ) {
        this.addedSerials = this.serialsProperties.packObj.addedSerials;
        this.fillAddedSerialsCount(null);
      }
    }
    if(this.formProcessMode == FormProcessMode.BackToStoreOffload)
    {
      this.isBackToStoreOffload = true;
    }
  }

  ngOnChanges() {
    //   this.fillInvalidDataSource();
  }
  prepareNavigation() {
    //   this.navLinks = [
    //     {
    //       label: 'Desc_Added_Serials',
    //       index: 0,
    //       isActive: false,
    //       hidden: false
    //     },
    //     {
    //       label: 'Desc_Invalid_Serials',
    //       index: 1,
    //       isActive: false,
    //       hidden: !this.invalidSerials || this.invalidSerials.length === 0
    //     }
    //   ];
    this.options = [
      {
        label: 'Desc_Add_Serials',
        index: 0,
        isActive: false,
        hidden: false
      },
      {
        label: 'Desc_Remove_Serials',
        index: 1,
        isActive: false,
        hidden: false
      }
    ];
  }
  ChangeOption(index: number) {
    this.options.forEach(a => a.isActive = false);
    this.optionSelectedIndex = index;
    this.options[index].isActive = true;
    if(this.optionSelectedIndex==0){
      this.isAddOption=true;
      this.isRemoveOption=false;
      this.addSerialCaption='Desc_Add';
    }else{
      this.isAddOption=false;
      this.isRemoveOption=true;
      this.addSerialCaption='Desc_Remove';
    }
  }
  // ChangeDataView(index: number) {
  //   this.navLinks.forEach(a => a.isActive = false);
  //   this.navBarSelectedIndex = index;
  //   this.navLinks[index].isActive = true;
  // }
  fillConfigurationValues() {

    this.forceSingleSerials = this.sessionData.getConfigurationValue(ConstantConfigurations.ForceSingleSerials).toLowerCase() === "true";
   if(this.serialsProperties.packObj.packHasTrimSerialRule){
    this.serialTrimStartLength = this.serialsProperties.packObj.packTrimSerialStartDigits;
    this.serialTrimEndLength =this.serialsProperties.packObj.packTrimSerialEndDigits;
   }else{
    this.serialTrimStartLength = +this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTrimStartLength);
    this.serialTrimEndLength = +this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTrimEndLength);
   }
   
    this.blockManualInsertionForBatchNo = this.sessionData.getConfigurationValue(ConstantConfigurations.BlockManuallyBatchInsertion).toLowerCase() === "true";
    this.dateFormat = this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
  if(+this.sessionData.getConfigurationValue(ConstantConfigurations.DefaultSerialScanningType)===1 || +this.sessionData.getConfigurationValue(ConstantConfigurations.DefaultSerialScanningType)===2)
    this.defaultSerialScanningType=+this.sessionData.getConfigurationValue(ConstantConfigurations.DefaultSerialScanningType);
    console.log('this.defaultSerialScanningType');
    console.log(this.defaultSerialScanningType);
  
  }
  initForm() {
    if (this.serialsProperties.itemFormProcessMode === FormProcessMode.SerialsAggregation) {
      this.headerString = this.translateService.instant('Desc_Aggregate_Serials').toUpperCase() + ': ' + this.serialsProperties.packObj.itemCode + ' - ' + this.serialsProperties.packObj.itemName + ' (' + this.serialsProperties.packObj.uom + ')';
    } else {
      this.headerString = this.translateService.instant('Desc_Add_Serials').toUpperCase() + ': ' + this.serialsProperties.packObj.itemCode + ' - ' + this.serialsProperties.packObj.itemName + ' (' + this.serialsProperties.packObj.uom + ')';
    }
if(this.defaultSerialScanningType===this.singleSerial){
  this.isSingle=true;
}else{
  this.isSingle=false;
}
    this.addSerialForm = new FormGroup({
      inlineRadioOptions: new FormControl(this.defaultSerialScanningType.toString()),
      fromSerial: new FormControl('', Validators.required),
      toSerial: new FormControl('', (!this.isSingle ? Validators.required : null)),
      itemCodeName: new FormControl({ value: this.serialsProperties.packObj.itemCode + ' - ' + this.serialsProperties.packObj.itemName, disabled: true }),
      batchNo: new FormControl(
        {
          value: this.batchNo.replace("'", "''").toUpperCase(),
          disabled:
            this.blockManualInsertionForBatchNo ||
            this.serialsProperties.packObj.batchIncludedInTemplate,
        },
        this.enableBatch &&
          !this.serialsProperties.packObj.batchIncludedInTemplate
          ? Validators.required
          : null
      ),
      expiryDate: new FormControl(
        {
          value: this.desiredExpiryDate.date,
          disabled: this.serialsProperties.packObj.expiryIncludedInTemplate,
        },
        this.enableBatch &&
          !this.serialsProperties.packObj.expiryIncludedInTemplate
          ? Validators.required
          : null
      ),
      productionDate: new FormControl(
        {
          value: this.productionDate.date,
          disabled: !this.serialsProperties.packObj
            .productionIncludedInTemplate,
        },
        this.serialsProperties.packObj.productionIncludedInTemplate
          ? Validators.required
          : null
      ),
      reqQuantity: new FormControl(
        "",
        this.isGenerate ? Validators.required : null
      ),
    });
    if(this.isSingle)
    this.onSingleSerialClicked();
    else
    this.onRangeSerialClicked();
  }
  onSingleSerialClicked() {
    this.fromSerialInput.setValue('');
    this.toSerialInput.setValue('');
    this.fromSerialInput.enable();
    this.toSerialInput.disable();
    if (this.expiryDateInput && this.serialsProperties.packObj.expiryIncludedInTemplate) {
      this.expiryDateInput.setValue(null);
      this.expiryDateInput.disable();
      this.expiryDateInput.setValidators([Validators.nullValidator]);
      this.expiryDateInput.updateValueAndValidity();
    }

    if (this.batchNoInput && this.serialsProperties.packObj.batchIncludedInTemplate) {
      this.batchNoInput.setValue(null);
      this.batchNoInput.disable();
      this.batchNoInput.setValidators([Validators.nullValidator]);
      this.batchNoInput.updateValueAndValidity();
    }
    if (this.productionDateInput && this.serialsProperties.packObj.productionIncludedInTemplate) {
      this.productionDateInput.setValidators([Validators.nullValidator]);
      this.productionDateInput.updateValueAndValidity();
    }
    if (this.reqQuantityInput && this.serialsProperties.packObj.templateId > 0) {
      this.reqQuantityInput.setValidators([Validators.nullValidator]);
      this.reqQuantityInput.updateValueAndValidity();
    }
    this.addSerialCaption = 'Desc_Add';
    this.isGenerate = false;
    this.isSingle = true;
  }
  onRangeSerialClicked() {
    this.fromSerialInput.enable({ onlySelf: true, emitEvent: false });
    this.fromSerialInput.setValidators([Validators.required]);
    this.toSerialInput.enable({ onlySelf: true, emitEvent: false });
    this.toSerialInput.setValidators([Validators.required]);
    this.toSerialInput.updateValueAndValidity();
    if (this.expiryDateInput && this.serialsProperties.packObj.expiryIncludedInTemplate) {
      this.expiryDateInput.setValue(null);
      this.expiryDateInput.disable();
      this.expiryDateInput.setValidators(null);
      this.expiryDateInput.updateValueAndValidity();
    }

    if (this.batchNoInput && this.serialsProperties.packObj.batchIncludedInTemplate) {
      this.batchNoInput.setValue(null);
      this.batchNoInput.disable();
      this.batchNoInput.setValidators(null);
      this.batchNoInput.updateValueAndValidity();
    }
    if (this.productionDateInput && this.serialsProperties.packObj.productionIncludedInTemplate) {
      this.productionDateInput.setValidators([Validators.nullValidator]);
      this.productionDateInput.updateValueAndValidity();
    }
    if (this.reqQuantityInput && this.serialsProperties.packObj.templateId > 0) {
      this.reqQuantityInput.setValidators([Validators.nullValidator]);
      this.reqQuantityInput.updateValueAndValidity();
    }
    this.addSerialCaption = 'Desc_Add';
    this.isGenerate = false;
    this.isSingle = false;
  }
  onGenerateSerialClicked() {
    this.fromSerialInput.setValue(null);
    this.toSerialInput.setValue(null);
    this.fromSerialInput.disable();
    this.toSerialInput.disable();
    this.reqQuantityInput.enable({ onlySelf: true, emitEvent: false });
    this.reqQuantityInput.setValidators([Validators.required]);

    if (this.productionDateInput && this.serialsProperties.packObj.productionIncludedInTemplate) {
      this.productionDateInput.setValidators([Validators.required]);
      this.productionDateInput.updateValueAndValidity();
    }

    if (this.expiryDateInput && this.expiryDateInput.disabled && this.serialsProperties.packObj.expiryIncludedInTemplate) {
      this.expiryDateInput.setValue(null);
      this.expiryDateInput.enable({ onlySelf: true, emitEvent: false });
      this.expiryDateInput.setValidators([Validators.required]);
      this.expiryDateInput.updateValueAndValidity();
    }

    if (this.batchNoInput && this.batchNoInput.disabled && this.serialsProperties.packObj.batchIncludedInTemplate) {
      this.batchNoInput.setValue(null);
      this.batchNoInput.enable({ onlySelf: true, emitEvent: false });
      this.batchNoInput.setValidators([Validators.required]);
      this.batchNoInput.updateValueAndValidity();
    }
    this.addSerialCaption = 'Desc_Generate';
    this.isGenerate = true;
  }
  fillInvalidDataSource() {
    // this.invalidSerialsDataSource = {
    //   data: [],
    //   totalItems: 0
    // }
    if (this.invalidSerials && this.invalidSerials.length > 0) {
    //   this.navLinks[1].hidden = false;

     //  this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption),this.translateService.instant(ConstantMessages.Desc_Some_Serials_Invalid));
      this.invalidSerials.forEach(serial => {
       
        if(serial.serialValidationErrors && serial.serialValidationErrors!=SerialValidationErrors.None)
        serial.errorString = this.serialsService.getErrorText(serial.serialValidationErrors);
      });
      // this.invalidSerialsDataSource = {
      //   data: this.invalidSerials,
      //   totalItems: this.invalidSerials.length
      // }
      setTimeout(() => this.invalidSerialsDialog.showDialog().then(
        (result) => {
          this.clearUI(false);
        }));
      
    }else{
      this.clearUI(false);
    }
  }
  
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

  saveBtn() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }
  onSaveClicked() {
    this.fillAddedSerialsCount(null);
    if (
      this.serialsProperties.quantityMustMatch &&
      this.serialsProperties.requiredQuantity > 0 &&
      this.addedSerialsCount != this.serialsProperties.requiredQuantity
    ) {
      this.coreSession.showError(
        this.translateService.instant(ConstantMessages.ErrorCaption),
        this.translateService.instant(
          ConstantMessages.Desc_Serials_Count_Not_Match_Req_Qnty
        )
      );
      return;
    } else if(this.isFromSSCC && this.serialsProperties.requiredQuantity > -1 && this.addedSerialsCount > this.serialsProperties.requiredQuantity){
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serials_Count_More_Than_Requested));
      return;
    } else {
      if (
        (!this.isInputVoucher() && !this.isAggregate()) ||
        this.isAllSerialsAdded(this.serialsProperties.packObj, "")
      ) {
        if (this.serialsProperties.packObj.isFOCItem) {
          this.serialsProperties.packObj.addedFOCSerials = this.addedSerials;
        } else {
          this.serialsProperties.packObj.addedSerials = this.addedSerials;
        }
        if (this.forceSingleSerials) {
          if (this.serialsProperties.packObj.isFOCItem) {
            this.serialsProperties.packObj.requiredFOCQty = this.addedSerialsCount;
          } else {
            this.serialsProperties.packObj.requiredQty = this.addedSerialsCount;
          }
        }
        this.onSaveCompleted.emit(this.serialsProperties.packObj);
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Some_Sub_Serials_Not_Added));
      }
    }
  }
  fillAddedSerialsCount(item: any) {

    if(item && item != null){
      let index = this.addedSerialsForAllPacks.findIndex((x) => x.fromSerial === item.fromSerial);
      if (index > -1) {
        this.addedSerialsForAllPacks.splice(index, 1);
      }
      this.deleteSerial(item.fromSerial);
    }

    this.addedSerialsCount = 0;
    if (this.showSerialsAsRange) {
      if (this.addedSerials && this.addedSerials.length > 0) {
        if (this.isInputVoucher() || this.isAggregate())
   this.addedSerials.filter(x => !x.parentSerial || x.parentSerial === '').forEach(serial => {
          this.addedSerialsCount +=serial.serialsCount;
        });
        else
        this.addedSerials.forEach(serial => {
          this.addedSerialsCount +=serial.serialsCount;
        });
      }
      
    }else{
      if(this.addedSerials && this.addedSerials.length>0){
        if (this.isInputVoucher() || this.isAggregate())
        this.addedSerialsCount  = this.addedSerials.filter(x => !x.parentSerial || x.parentSerial === '').length;
        else
        this.addedSerialsCount =this.addedSerials.length;
      }
    
    }
   
  }
  isValidParent(packObj: ItemPackModel, parentSerial: String) {
    let isValid = true;
    if (packObj.packQuantityInLevels > 1) {
      let parentSerials = this.addedSerials.filter(serial => serial.parentSerial.toLowerCase() === parentSerial.toLowerCase());
      if (parentSerials && parentSerials.length === packObj.packQuantityInLevels) {
        parentSerials.forEach(serial => {
          if (isValid) {
            isValid = this.isValidParent(packObj.childPack, serial.fromSerial);
          }
        });
      } else {
        isValid = false;
      }

    }
    return isValid;
  }
  isAllSerialsAdded(packObj: ItemPackModel, parentSerial: String) {
    let isValid = true;
    if (packObj.packQuantityInLevels > 1) {
      let parentSerials = this.addedSerials.filter(serial => serial.parentSerial.toLowerCase() === parentSerial.toLowerCase());
      if (parentSerials && parentSerials.length > 0) {
        parentSerials.forEach(serial => {
          if (isValid) {
            let childSerials = this.addedSerials.filter(childSerial => childSerial.parentSerial.toLowerCase() === serial.fromSerial.toLowerCase());
            if (childSerials && childSerials.length === packObj.packQuantityInLevels) {
              if (this.isAggregate())
                isValid = true;
              else
                isValid = this.isAllSerialsAdded(packObj.childPack, serial.fromSerial);
            } else {
              isValid = false;
            }
          }
        });
      } else {
        isValid = false;
      }

    }
    return isValid;
  }
  clearUI(isFromRemove) {
    this.fromSerialInput.setValue(null);
    this.toSerialInput.setValue(null);
    this.fromSerialInput.markAsUntouched();
    this.toSerialInput.markAsUntouched();
    this.fromSerialInput.updateValueAndValidity();
    this.toSerialInput.updateValueAndValidity();
    this.reqQuantityInput.setValue(null);
    this.reqQuantityInput.markAsUntouched();
    this.reqQuantityInput.updateValueAndValidity();
    this.productionDateInput.setValue(null);
    this.productionDateInput.markAsUntouched();
    this.productionDateInput.updateValueAndValidity();
    this.batchNoInput.setValue(null);
    this.batchNoInput.markAsUntouched();
    this.batchNoInput.updateValueAndValidity();
    this.expiryDateInput.setValue(null);
    this.expiryDateInput.markAsUntouched();
    this.expiryDateInput.updateValueAndValidity();
   if(!isFromRemove){
      if(this.fromSerialControl && this.fromSerialControl.nativeElement){
        this.fromSerialControl.nativeElement.focus();
      }
  }
 
   
  }
  onEnterClickedFromSerial() {
    if (this.isSingle) {
      if(this.showSerialsAsRange)
      this.onAddSerialsAsRange();
      else
      this.onAddSerials();
    } else {      
      // setTimeout(()=>{ // this will make the execution after the above boolean has changed
      //   this.toSerialControl.nativeElement.focus();
      // },0);  

      if(this.toSerialControl && this.toSerialControl.nativeElement){
        this.toSerialControl.nativeElement.focus();
      }
      //this.renderer.selectRootElement('#input').focus();
    }
  }
  onGenerateSerials() {
    if (this.addSerialForm.invalid) {
      this.coreSession.markFormGroupTouched(this.addSerialForm);
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return false;
    }
    this.coreSession.ModalLoading.Show();
    let batchNo, expiryDateModel;
    if (this.enableBatch) {
      expiryDateModel = this.desiredExpiryDate;
    } else {
      expiryDateModel = new DateTimeModel();
      expiryDateModel.date = this.coreSession.DefaultBatchNumber;
      expiryDateModel.day = this.coreSession.DefaultExpiryDate().getDay();
      expiryDateModel.month = this.coreSession.DefaultExpiryDate().getMonth() + 1;
      expiryDateModel.year = this.coreSession.DefaultExpiryDate().getFullYear();
    }
    if (this.enableBatch) {
      batchNo = this.batchNoInput.value;
    } else {
      batchNo = this.coreSession.DefaultBatchNumber;
    }
    let barcodeValues = {
      gtin: this.serialsProperties.packObj.gtin,
      productionDateModel: this.productionDate,
      expiryDateModel: expiryDateModel,
      batchNo: batchNo,
      gln: '0',
      quantity: this.reqQuantityInput.value,
      generateSubBarcodes: this.isInputVoucher() ? true : false,
      customerId:this.serialsProperties.customerId,
      outletId:this.serialsProperties.outletId
    };
    this.serialsService
      .generateSerialBarcode(this.serialsProperties.packObj, barcodeValues)
      .subscribe(
        (response: ResponseModel) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            if (
              response.data.addedSerials &&
              response.data.addedSerials.length > 0
            ) {
              response.data.addedSerials.forEach((serial) => {
                var temp_serialObj = {
                  formProcessMode: this.serialsProperties.itemFormProcessMode,
                  fromSerial: serial.fromSerial,
                  toSerial: serial.toSerial,
                  warehouseId: this.serialsProperties.warehouseId,
                  vehicleId: this.serialsProperties.vehicleId,
                  packId: serial.packId,
                  itemId: this.serialsProperties.packObj.itemId,
                  parentSerial: serial.parentSerial,
                  transactionId: this.serialsProperties.transactionId,
                  fullSerial: serial.fromSerial,
                  serialValidationErrors: SerialValidationErrors.None,
                  batchNo: serial.batchNo,
                  expiryDateModel: serial.expiryDateModel,
                  isFromGenerate: serial.isFromGenerate,
                  serialsCount: 1,
                  price: this.serialsProperties.packObj.price,
                  childPackPrice: this.serialsProperties.packObj.childPackPrice,
                  salesTransactionTypeId: this.serialsProperties.packObj
                    .salesTransactionTypeId,
                };

                this.addedSerials.push(temp_serialObj);
              });
            }
            this.fillAddedSerialsCount(null);
            this.clearUI(false);
            this.saveSubject.next();
          } else {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  onAddOrGenerateSerials() {
    if (this.isGenerate) {
      this.onGenerateSerials();
    } else {
      if(this.showSerialsAsRange)
      this.onAddSerialsAsRange();
      else
      this.onAddSerials();
    }
  }
  onAddSerials() {
    
    this.temp_addedSerials = [];
    this.invalidSerials = [];
   if (this.prepareAndValidateAddedSerialsInUI()) {
     if (this.temp_addedSerials && this.temp_addedSerials.length > 0) {
        if (this.serialsProperties.transactionRecallId != -1 && this.serialsProperties.packObj.recalledBySerial && this.serialsProperties.packObj.availableSerialsForItemRecalledBySerial != null && this.serialsProperties.packObj.availableSerialsForItemRecalledBySerial.length > 0) {
          this.validateSpecificRecallSerialsOffline();  // validate batch required by specific serials
        } else {
          this.validateSerialsOnLine();
        }
      } else {
        if (this.invalidSerials && this.invalidSerials.length > 0)
         // this.ChangeDataView(1);
      //  this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_All_Serials_Not_Valid));
        this.fillInvalidDataSource();
      }
    } else {
      if (this.invalidSerials && this.invalidSerials.length > 0)
       // this.ChangeDataView(1);
      this.fillInvalidDataSource();
    }
  }
  onAddSerialsAsRange() {

    if(this.isAddOption){
      this.temp_addedSerials = [];
      this.invalidSerials = [];
     // if (this.prepareAndValidateAddedSerialsInUI()) {
      //  if (this.temp_addedSerials && this.temp_addedSerials.length > 0) {
         if (this.serialsProperties.transactionRecallId != -1 && this.serialsProperties.packObj.recalledBySerial && this.serialsProperties.packObj.availableSerialsForItemRecalledBySerial != null && this.serialsProperties.packObj.availableSerialsForItemRecalledBySerial.length > 0) {
           if (this.prepareAndValidateAddedSerialsInUI()) {
       if (this.temp_addedSerials && this.temp_addedSerials.length > 0) {
          this.validateSpecificRecallSerialsOffline();  // validate batch required by specific serials
       }
      }else{
        if (this.invalidSerials && this.invalidSerials.length > 0)
           // this.ChangeDataView(1);
         // this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_All_Serials_Not_Valid));
          this.fillInvalidDataSource();
        
      }
        } else {
          if (this.addSerialForm.invalid) {
            this.coreSession.markFormGroupTouched(this.addSerialForm);
            this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
            return false;
          }
          if (this.fromSerialInput.value.length <= (this.serialTrimStartLength + this.serialTrimEndLength)) {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
            return false;
          }
            this.validateSerialsOnLineAsRange();
        }
     
    }else{
      
      if (this.addSerialForm.invalid) {
        this.coreSession.markFormGroupTouched(this.addSerialForm);
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
        return false;
      }
  
      // if (this.fromSerialInput.value.length <= (this.serialTrimStartLength + this.serialTrimEndLength)) {
      //   this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
      //   return false;
      // }
      if(this.isSingle){
        if(this.fromSerialControl && this.fromSerialControl.nativeElement){
          this.fromSerialControl.nativeElement.blur();
        }
      }else{
        if(this.toSerialControl && this.toSerialControl.nativeElement){
          this.toSerialControl.nativeElement.blur();
        }
      }

      this.coreSession.ModalDialog.ShowMessage(
        this.translateService.instant(
          ConstantMessages.Desc_Confirmation_Remove_Serials
        ),
        DialogMode.YesNo,
        this.translateService.instant(ConstantMessages.WarningCaption)
      ).then((result: DialogResult) => {
        if (result === DialogResult.Yes) {
          if (this.removeSerialFromAddSerials()) {
            this.clearUI(true);
          }
        }
      });
    }
  }
  removeSerialFromAddSerials() {
    var serial = this.getTrimmedSerialsAndValidate();
    if (serial == null) return false;
    var newSerial: any = {};
    var foundSerials = this.serialsService.getAllSerialsContainingSerial(
      serial,
      this.addedSerials
    );
    if (foundSerials !== null && foundSerials && foundSerials.length > 0) {
      for (var i = 0; i < foundSerials.length; i++) {
        let foundSerial = foundSerials[i];
        newSerial = this.coreSession.cloneObject(foundSerial);
        if (
          foundSerial.fromSerial >= serial.fromSerial &&
          foundSerial.toSerial <= serial.toSerial
        ) {
          this.removeParentSerials(foundSerial.fromSerial);
        } else if (
          foundSerial.fromSerial < serial.fromSerial &&
          foundSerial.toSerial > serial.toSerial
        ) {
          this.removeParentSerials(foundSerial.fromSerial);
          newSerial.toSerial = this.getPreviousSerial(serial.fromSerial);
          newSerial.serialsCount = this.getSerialCount(newSerial);
          this.addedSerials.push(newSerial);

          var newSerial2 = this.coreSession.cloneObject(foundSerial);
          newSerial2.fromSerial = this.getNextSerial(serial.toSerial);
          newSerial2.serialsCount = this.getSerialCount(newSerial2);
          this.addedSerials.push(newSerial2);
        } else {
          if (foundSerial.fromSerial < serial.fromSerial) {
            newSerial.toSerial = this.getPreviousSerial(serial.fromSerial);
          }
          if (foundSerial.toSerial > serial.toSerial) {
            newSerial.fromSerial = this.getNextSerial(serial.toSerial);
          }
          this.removeParentSerials(foundSerial.fromSerial);
          newSerial.serialsCount = this.getSerialCount(newSerial);
          this.addedSerials.push(newSerial);
        }
      }

      this.fillAddedSerialsCount(null);
      this.saveSubject.next();
      return true;
    } else {
      this.coreSession.showError(
        this.translateService.instant(ConstantMessages.ErrorCaption),
        this.translateService.instant(ConstantMessages.Desc_Serial_Not_Exist)
      );
      return false;
    }

  }
  getSerialCount(serialObj){
    var serialsCount=serialObj.serialsCount;
    var fromSerialParts=this.getSerialAllParts(serialObj.fromSerial);
    var toSerialParts=this.getSerialAllParts(serialObj.toSerial);
    if(fromSerialParts!==null && toSerialParts !==null){
      serialsCount=toSerialParts.serialNumberPart-fromSerialParts.serialNumberPart+1;
    }
    return serialsCount;
  }
  getNewSerial(serial, previous){
    let newSerial='', firstFullSerial = serial;
    // let fromSerialNumberPart = 0, fromSerialNumberLength = 0;
    // let fromSerialCharPart = '';
    
   var serialParts=this.getSerialAllParts(serial);
   if(serialParts===null){
     return false;
   }
        if (this.serialsProperties.packObj.templateId > 0 && !this.validateBarcodeWithTemplate(firstFullSerial)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Barcode_Not_Compatible_With_Template));
      return false;
    }
    let newNumber=serialParts.serialNumberPart;
    if(previous)
    newNumber=serialParts.serialNumberPart-1;
    else
    newNumber=serialParts.serialNumberPart+1;

    newSerial=serialParts.serialPartsTemp.startString + serialParts.serialChartPart + this.padLeft(newNumber + '', '0', serialParts.serialNumberLength) + serialParts.serialPartsTemp.endString
    return newSerial;
  }
  getSerialAllParts(serial){
    let fromSerialNumberPart = 0, fromSerialNumberLength = 0;
    let fromSerialCharPart = '';
    var fromSerialParts = this.trimSerialFromTemplate(serial);
    serial = fromSerialParts.trimmedSerial;
    var matches = serial.match(/\d+/g);
    var maxNumberLength=8;
    if (matches && matches.length > 0) {
      if(matches[matches.length - 1].length>maxNumberLength){
        var serialNumberPartAsString=matches[matches.length - 1];
        fromSerialNumberPart = +serialNumberPartAsString.substring(serialNumberPartAsString.length-maxNumberLength,serialNumberPartAsString.length);
        fromSerialNumberLength = maxNumberLength;
        if (serial.substring(serial.length - serialNumberPartAsString.length, serial.length) !== matches[matches.length - 1]) {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_Not_End_With_Numbers));
          return null;
        }
        fromSerialCharPart = serial.substring(0, serial.length - fromSerialNumberLength);
      }else{
        fromSerialNumberPart = Number(matches[matches.length - 1]);
        fromSerialNumberLength = matches[matches.length - 1].length;
        if (serial.substring(serial.length - fromSerialNumberLength, serial.length) !== matches[matches.length - 1]) {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_Not_End_With_Numbers));
          return null;
        }
        fromSerialCharPart = serial.substring(0, serial.length - fromSerialNumberLength);
      }
     
    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_Not_Contain_Numbers));
      return null;
    }
    var serialParts={
      serialChartPart:fromSerialCharPart,
      serialNumberPart:fromSerialNumberPart,
      serialNumberLength:fromSerialNumberLength,
      serialPartsTemp:fromSerialParts
    };
    return serialParts;
  }
  getNextSerial(serial){
    return this.getNewSerial(serial,false);
  }
  getPreviousSerial(serial){
    return this.getNewSerial(serial,true);
  }
  validateSerialsOnLine() {
    this.coreSession.ModalLoading.Show();
    // console.log(this.temp_addedSerials,"temp_addedSerials for push 3");
    // var serialObj=this.getSerialObj();
    // console.log(serialObj);
    
    this.serialsService.validateSerial(this.temp_addedSerials).subscribe(
      (response: ResponseModel) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.temp_addedSerials = response.data;
          if (this.temp_addedSerials && this.temp_addedSerials.length > 0) {
            this.temp_addedSerials.forEach((serial) => {
              if (
                serial.serialValidationErrors === SerialValidationErrors.None
              ) {
                if (this.serialsProperties.transactionRecallId == -1) {
                  if (this.serialsProperties.packObj.enableBatchForSerial) {
                    if (
                      this.serialsProperties.itemFormProcessMode ===
                      FormProcessMode.InputVoucher ||
                      this.serialsProperties.itemFormProcessMode ===
                      FormProcessMode.SerialsAggregation
                    ) {
                      serial.batchNo = this.batchNo;
                      serial.expiryDateModel = this.desiredExpiryDate;
                    }
                  } else {
                    serial.batchNo = this.coreSession.DefaultBatchNumber;
                    serial.expiryDateModel = new DateTimeModel();
                    serial.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
                    serial.expiryDateModel.day = this.coreSession
                      .DefaultExpiryDate()
                      .getDay();
                    serial.expiryDateModel.month =
                      this.coreSession.DefaultExpiryDate().getMonth() + 1;
                    serial.expiryDateModel.year = this.coreSession
                      .DefaultExpiryDate()
                      .getFullYear();
                  }
                }

                //  serial.packId=this.serialsProperties.packObj.packId;
                //serial.parentSerial='';
                if (this.isFromSSCC) {
                  let alreadyAddedSerials = this.addedSerials.filter(
                    (x) => !x.parentSerial || x.parentSerial === ""
                  );
                  let alreadyAddedSerialsCount = 0;
                  if (alreadyAddedSerials) {
                    alreadyAddedSerialsCount = alreadyAddedSerials.length;
                  }
                  if (
                    this.serialsProperties.packObj.originalQuantity <
                    alreadyAddedSerialsCount + 1 &&
                    this.serialsProperties.itemFormProcessMode ===
                    FormProcessMode.LoadRequest
                  ) {
                    this.coreSession.showWarrning(
                      this.translateService.instant(
                        ConstantMessages.WarningCaption
                      ),
                      this.translateService.instant(
                        ConstantMessages.RequierdMoreThanRequested
                      )
                    );
                    return;
                  }
              }
              serial.serialNumberStatus = this.serialsProperties.newSerialStatus;
              serial.serialsCount=1;
              if (this.isInputVoucher()) {
                this.addedSerials.push(serial);
              } else if (this.checkIfSerialParentNotIsAdded(serial)) {
                this.addedSerials.push(serial);
              }
             // this.ChangeDataView(0);
             }
            else {
              this.invalidSerials.push(serial);
            }

          });
        }
         
          
          // if (this.invalidSerials && this.invalidSerials.length > 0) {
          //   this.ChangeDataView(1);
          //   this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Some_Serials_Not_Valid));
          // }
          if (this.isInputVoucher() && this.serialsProperties.packObj.autoGenerateSubSerials && !(this.serialsProperties.packObj.templateId > 0)) {

            this.removeAllInvalidParents();
          }

          this.fillAddedSerialsCount(null);
          this.saveSubject.next();
          
          this.fillInvalidDataSource();

        } else {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption),response.message);
        }
      }, (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption),this.translateService.instant(ConstantMessages.ErrorHappened));
      });
  }
  validateSerialsOnLineAsRange() {
    this.coreSession.ModalLoading.Show();
    var serialObj=this.getSerialObj();   
    var trimmedSerialObj=this.getTrimmedSerials();
    if(trimmedSerialObj===null){
      this.coreSession.ModalLoading.Hide();
      return false;
    }
    if (this.checkIfSerialAlreadyAddedForRanges(trimmedSerialObj)) {
      this.serialsService.validateAndSeparateSerials(serialObj).subscribe(
        (response: ResponseModel) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.temp_addedSerials = response.data.serialsList;
            this.invalidSerials= response.data.invalidSerials;
            if (this.temp_addedSerials && this.temp_addedSerials.length > 0) {
              this.temp_addedSerials.forEach((serial) => {
                if (
                  serial.serialValidationErrors === SerialValidationErrors.None
                ) {
                  if (this.serialsProperties.transactionRecallId == -1) {

                    if (this.serialsProperties.packObj.enableBatchForSerial) {
                      if (
                        this.serialsProperties.itemFormProcessMode ===
                        FormProcessMode.InputVoucher ||
                        this.serialsProperties.itemFormProcessMode ===
                        FormProcessMode.SerialsAggregation
                      ) {
                        if (
                          this.serialsProperties.packObj.templateId > 0 &&
                          this.serialsProperties.packObj
                            .expiryIncludedInTemplate
                        ) {
                        } else {
                          serial.batchNo = this.batchNo;
                          serial.expiryDateModel = this.desiredExpiryDate;
                        }
                      }
                    } else {
                      serial.batchNo = this.coreSession.DefaultBatchNumber;
                      serial.expiryDateModel = new DateTimeModel();
                      serial.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
                      serial.expiryDateModel.day = this.coreSession
                        .DefaultExpiryDate()
                        .getDay();
                      serial.expiryDateModel.month =
                        this.coreSession.DefaultExpiryDate().getMonth() + 1;
                      serial.expiryDateModel.year = this.coreSession
                        .DefaultExpiryDate()
                        .getFullYear();
                    }
                  }

                  //  serial.packId=this.serialsProperties.packObj.packId;
                  //serial.parentSerial='';
                  if (this.isFromSSCC) {
                    let alreadyAddedSerials = this.addedSerials.filter(
                      (x) => !x.parentSerial || x.parentSerial === ""
                    );
                    let alreadyAddedSerialsCount = 0;
                    if (alreadyAddedSerials) {
                      alreadyAddedSerialsCount = alreadyAddedSerials.length;
                    }
                    if (
                      this.serialsProperties.packObj.originalQuantity <
                      alreadyAddedSerialsCount + 1 &&
                      this.serialsProperties.itemFormProcessMode ===
                      FormProcessMode.LoadRequest
                    ) {
                      this.coreSession.showWarrning(
                        this.translateService.instant(
                          ConstantMessages.WarningCaption
                        ),
                        this.translateService.instant(
                          ConstantMessages.RequierdMoreThanRequested
                        )
                      );
                      return;
                    }
                    serial.ssccBarcode = this.serialsProperties.ssccBarcode;
                  }
                  serial.serialNumberStatus = this.serialsProperties.newSerialStatus;
                  if (this.isInputVoucher() ) {
                    this.addedSerials.push(serial);
                  } else if (this.checkIfSerialParentNotIsAdded(serial)) {
                    this.addedSerials.push(serial);
                  }
                  //this.ChangeDataView(0);
                }
                // else {
                //   this.invalidSerials.push(serial);
                // }
              });
            }

            // if (this.invalidSerials && this.invalidSerials.length > 0) {
            //   this.ChangeDataView(1);
            //   this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Some_Serials_Not_Valid));
            // }
            if (
              this.isInputVoucher() &&
              this.serialsProperties.packObj.autoGenerateSubSerials &&
              !(this.serialsProperties.packObj.templateId > 0)
            ) {
              this.removeAllInvalidParents();
            }
            this.fillInvalidDataSource();
            // this.clearUI();
            this.fillAddedSerialsCount(null);
            this.saveSubject.next();
          } else {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
    } else {
      //serial already added
      this.coreSession.ModalLoading.Hide();
        if (this.invalidSerials && this.invalidSerials.length > 0)
          //  this.ChangeDataView(1);
          //this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_All_Serials_Not_Valid));
          this.fillInvalidDataSource();
        
      
    }
  }
  validateSpecificRecallSerialsOffline() {
    this.temp_addedSerials.forEach(scannedSerial => {
      var serial = null;
      serial = this.serialsProperties.packObj.availableSerialsForItemRecalledBySerial.find(x => x.fromSerial === scannedSerial.fromSerial);
      if (serial) {
        serial.serialNumberStatus = this.serialsProperties.newSerialStatus;
        this.addedSerials.push(serial);
       // this.ChangeDataView(0);
      } else {
        scannedSerial.serialValidationErrors = SerialValidationErrors.SerialNotDefinedOverRecall;
        this.invalidSerials.push(scannedSerial);
      }
    });
    if (this.invalidSerials && this.invalidSerials.length > 0) {
     // this.ChangeDataView(1);
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Some_Serials_Not_Valid));
    }
    this.fillInvalidDataSource();
    //this.clearUI();
    this.saveSubject.next();
  }
  removeAllInvalidParents() {
    let parentSerials = this.addedSerials.filter(serial => !serial.parentSerial || serial.parentSerial === '');
    if (parentSerials && parentSerials.length > 0) {
      parentSerials.forEach((serial) => {
        if (
          this.serialsProperties.packObj &&
          !this.isValidParent(this.serialsProperties.packObj, serial.fromSerial)
        ) {
          serial.serialValidationErrors =
            SerialValidationErrors.OneOrMoreSubSerialNotValid;
            this.invalidSerials.push(serial);
          this.removeParentSerials(serial.fromSerial);
        }
      });
    }
  }
  removeParentSerials(parentSerial: string) {
    let childSerials = this.addedSerials.filter(serial => serial.parentSerial === parentSerial);
    if (childSerials && childSerials.length > 0) {
      childSerials.forEach(sub => {
        this.removeParentSerials(sub.fromSerial);
      });
      this.deleteSerial(parentSerial);
    } else {
      this.deleteSerial(parentSerial);
    }
  }

  deleteSerial(serial: string) {
    let index = this.addedSerials.findIndex(x => x.fromSerial === serial);
    if (index > -1) {
      this.addedSerials.splice(index, 1);
    }
  }
  autoGenerateSubSerials() {
    let clonedAddedSerials = cloneDeep(this.temp_addedSerials);
    clonedAddedSerials.filter(x => !x.parentSerial || x.parentSerial === '').forEach(serial => {
      this.generateSerials(this.serialsProperties.packObj, serial.fromSerial);
    });
  }
  generateSerials(packObj: ItemPackModel, parentSerial: string) {
    if (packObj && packObj.childPack && packObj.packQuantityInLevels > 1) {
      let length = (packObj.packQuantityInLevels.toString()).length;

      for (var i = 1; i <= packObj.packQuantityInLevels; i++) {
        var temp_serialObj = {
          formProcessMode: this.serialsProperties.itemFormProcessMode,
          fromSerial: parentSerial + this.padLeft(i + '', '0', length),
          toSerial: parentSerial + this.padLeft(i + '', '0', length),
          warehouseId: this.serialsProperties.warehouseId,
          vehicleId: this.serialsProperties.vehicleId,
          parentSerial: parentSerial,
          serialValidationErrors: SerialValidationErrors.None,
          batchNo: this.batchNoInput.value,
          expiryDateModel: this.desiredExpiryDate,
          packId: packObj.childPack.packId,
          itemId: packObj.itemId,
          price:this.serialsProperties.packObj.price,
          childPackPrice:this.serialsProperties.packObj.childPackPrice,
          salesTransactionTypeId:this.serialsProperties.packObj.salesTransactionTypeId
        };
        if (this.checkIfSerialAlreadyAdded(temp_serialObj)) {
          if (packObj.childPack.packQuantityInLevels > 1) {
            this.generateSerials(packObj.childPack, temp_serialObj.fromSerial);
          }
        }

      }

    }
  }
  prepareAndValidateAddedSerialsInUI() {
    let isValid = false;
    if (this.addSerialForm.invalid) {
      this.coreSession.markFormGroupTouched(this.addSerialForm);
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return false;
    }
    if (this.forceSingleSerials) {

      if (this.isSingle) {
        isValid = this.handleSingleSerial();

      } else {
        isValid = this.handleDivideRangeSerial();

      }

    }
    if (isValid && this.temp_addedSerials && this.temp_addedSerials.length > 0) {

      if (this.serialsProperties.packObj.packQuantityInLevels > 1 && this.serialsProperties.packObj.autoGenerateSubSerials && !(this.serialsProperties.packObj.templateId > 0) && this.isInputVoucher()) {
        this.autoGenerateSubSerials();
      }
    }
    return isValid;
  }
  getSerialObj(){
    var fromSerial = this.fromSerialInput.value;
    var toSerial=this.toSerialInput.value;
    if(this.isSingle){
      toSerial=fromSerial;
    }
    var temp_serialObj = {
      formProcessMode: this.serialsProperties.itemFormProcessMode,
      fromSerial: fromSerial,
      toSerial:toSerial,
      warehouseId: this.serialsProperties.warehouseId,
      vehicleId: this.serialsProperties.vehicleId,
      packId: this.serialsProperties.packObj.packId,
      itemId: this.serialsProperties.packObj.itemId,
      itemCode:this.serialsProperties.packObj.itemCode,
      uom:this.serialsProperties.packObj.uom,
      parentSerial: '',
      fullSerial: toSerial,
      transactionId: this.serialsProperties.transactionId,
      serialValidationErrors: SerialValidationErrors.None,
      price: this.serialsProperties.packObj.price,
      childPackPrice: this.serialsProperties.packObj.childPackPrice,
      salesTransactionTypeId: this.serialsProperties.packObj.isFOCItem ?SalesTransactionTypes.FOC.valueOf() :this.serialsProperties.packObj
        .salesTransactionTypeId,
      isReserved: false,
      recallId: -1,
      transactionRecallId: this.serialsProperties.transactionRecallId,
      transactionReadingId:this.serialsProperties.transactionReadingId,
      batchNo: null, 
      expiryDateModel: null,
      ssccBarcode: this.serialsProperties.ssccBarcode,
      divisionId: -1,
      customerId: this.serialsProperties.customerId,
      outletId: this.serialsProperties.outletId,
      itemNameCode: this.serialsProperties.packObj.itemCode + " - " + this.serialsProperties.packObj.itemName + ' (' + this.serialsProperties.packObj.uom + ')',
    };
    if (this.serialsProperties.packObj.enableBatchForSerial) {
      if (
        this.serialsProperties.itemFormProcessMode ===
        FormProcessMode.InputVoucher ||
        this.serialsProperties.itemFormProcessMode ===
        FormProcessMode.SerialsAggregation
      ) {
        temp_serialObj.batchNo = this.batchNoInput.value;
        temp_serialObj.expiryDateModel = this.desiredExpiryDate;
      }
    } else {
      temp_serialObj.batchNo = this.coreSession.DefaultBatchNumber;
      temp_serialObj.expiryDateModel = new DateTimeModel();
      temp_serialObj.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
      temp_serialObj.expiryDateModel.day = this.coreSession.DefaultExpiryDate().getDay();
      temp_serialObj.expiryDateModel.month = this.coreSession.DefaultExpiryDate().getMonth() + 1;
      temp_serialObj.expiryDateModel.year = this.coreSession.DefaultExpiryDate().getFullYear();
    }
return temp_serialObj;
  }
  handleSingleSerial() {
    
    if (this.fromSerialInput.value.length < (this.serialTrimStartLength + this.serialTrimEndLength)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
      return false;
    }
    var fromSerial = this.fromSerialInput.value.substring(this.serialTrimStartLength, this.fromSerialInput.value.length);
    fromSerial = fromSerial.substring(0, fromSerial.length - this.serialTrimEndLength);
    var fullSerial = fromSerial;
    //  fromSerial=this.trimSerialFromTemplate(fromSerial).trimmedSerial;
    var temp_serialObj = {
      formProcessMode: this.serialsProperties.itemFormProcessMode,
      fromSerial: fromSerial,
      toSerial: fromSerial,
      warehouseId: this.serialsProperties.warehouseId,
      vehicleId: this.serialsProperties.vehicleId,
      packId: this.serialsProperties.packObj.packId,
      itemId: this.serialsProperties.packObj.itemId,
      parentSerial: '',
      transactionId: this.serialsProperties.transactionId,
      fullSerial: fullSerial,
      serialValidationErrors: SerialValidationErrors.None,
      price:this.serialsProperties.packObj.price,
      childPackPrice:this.serialsProperties.packObj.childPackPrice,
      salesTransactionTypeId:this.serialsProperties.packObj.salesTransactionTypeId,
      isReserved: false,
      recallId: -1,
      transactionRecallId: this.serialsProperties.transactionRecallId,
      transactionReadingId: this.serialsProperties.transactionReadingId,
      batchNo: null, // this value will set in back end when validate serials online
      expiryDateModel: null, // this value will set in back end when validate serials online
      uom: this.serialsProperties.packObj.uom,
      itemCodeName:
        this.serialsProperties.packObj.itemName +
        " - " +
        this.serialsProperties.packObj.itemCode,
        itemNameCode: this.serialsProperties.packObj.itemCode + " - " + this.serialsProperties.packObj.itemName + ' (' + this.serialsProperties.packObj.uom + ')',
      ssccBarcode: this.serialsProperties.ssccBarcode,
    };
    
    if (this.serialsProperties.transactionRecallId != -1) {
      // these value must not re-set in back end, scanned serial must have same batch & expiry of selected pack obj 
      temp_serialObj.batchNo = this.serialsProperties.packObj.batchNo;
      temp_serialObj.expiryDateModel = this.serialsProperties.packObj.expiryDateModel;
    }

    if (
      this.serialsProperties.packObj.templateId > 0 &&
      !this.validateBarcodeWithTemplate(fullSerial)
    ) {
      temp_serialObj.serialValidationErrors =
        SerialValidationErrors.BarcodeNotCompatibleWithTemplate;
        this.invalidSerials.push(temp_serialObj);
      return false;
    } else
      return this.checkIfSerialAlreadyAdded(temp_serialObj);
  }
  handleDivideRangeSerial() {
    let fromSerial = '', toSerial = '', firstFullSerial = '';
    let fromSerialNumberPart = 0, toSerialNumberPart = 0, fromSerialNumberLength = 0, toSerialNumberLength = 0;
    let fromSerialCharPart = '', toSerialCharPart = '';
    if (this.fromSerialInput.value.length != this.toSerialInput.value.length) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Not_Equals));
      return false;
    }
    if (this.fromSerialInput.value.length < (this.serialTrimStartLength + this.serialTrimEndLength)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
      return false;
    }
    firstFullSerial = fromSerial = this.fromSerialInput.value.substring(this.serialTrimStartLength, this.fromSerialInput.value.length);
    fromSerial = fromSerial.substring(0, fromSerial.length - this.serialTrimEndLength);
    var fromSerialParts = this.trimSerialFromTemplate(fromSerial);
    fromSerial = fromSerialParts.trimmedSerial;


    toSerial = this.toSerialInput.value.substring(this.serialTrimStartLength, this.toSerialInput.value.length);
    toSerial = toSerial.substring(0, toSerial.length - this.serialTrimEndLength);
    var toSerialParts = this.trimSerialFromTemplate(toSerial);
    toSerial = toSerialParts.trimmedSerial;

    if ((fromSerialParts.startString !== toSerialParts.startString) || (fromSerialParts.endString !== toSerialParts.endString)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_To_Serial_Template_Fields_Not_Match));
      return false;
    }
    var matches = fromSerial.match(/\d+/g);
    if (matches && matches.length > 0) {
      fromSerialNumberPart = +matches[matches.length - 1];
      fromSerialNumberLength = matches[matches.length - 1].length;
      if (fromSerial.substring(fromSerial.length - fromSerialNumberLength, fromSerial.length) !== matches[matches.length - 1]) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_Not_End_With_Numbers));
        return false;
      }
      fromSerialCharPart = fromSerial.substring(0, fromSerial.length - fromSerialNumberLength);
    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_Not_Contain_Numbers));
      return false;
    }
    var matches = toSerial.match(/\d+/g);
    if (matches && matches.length > 0) {
      toSerialNumberPart = +matches[matches.length - 1];
      toSerialNumberLength = matches[matches.length - 1].length;
      if (toSerial.substring(toSerial.length - toSerialNumberLength, toSerial.length) !== matches[matches.length - 1]) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_To_Serial_Not_End_With_Numbers));
        return false;
      }
      toSerialCharPart = toSerial.substring(0, toSerial.length - toSerialNumberLength);

    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_To_Serial_Not_Contain_Numbers));
      return false;
    }
    if (this.serialsProperties.packObj.templateId > 0 && !this.validateBarcodeWithTemplate(firstFullSerial)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Barcode_Not_Compatible_With_Template));
      return false;
    }
    if (fromSerialNumberLength === toSerialNumberLength && fromSerialCharPart === toSerialCharPart && fromSerialNumberPart <= toSerialNumberPart) {
      for (var i = fromSerialNumberPart; i <= toSerialNumberPart; i++) {
        var temp_serialObj = {
          formProcessMode: this.serialsProperties.itemFormProcessMode,
          fromSerial: fromSerialParts.startString + fromSerialCharPart + this.padLeft(i + '', '0', fromSerialNumberLength) + fromSerialParts.endString,
          toSerial: fromSerialParts.startString + fromSerialCharPart + this.padLeft(i + '', '0', fromSerialNumberLength) + fromSerialParts.endString,
          warehouseId: this.serialsProperties.warehouseId,
          vehicleId: this.serialsProperties.vehicleId,
          packId: this.serialsProperties.packObj.packId,
          itemId: this.serialsProperties.packObj.itemId,
          parentSerial: '',
          fullSerial: fromSerialParts.startString + fromSerialCharPart + this.padLeft(i + '', '0', fromSerialNumberLength) + fromSerialParts.endString,
          transactionId: this.serialsProperties.transactionId,
          serialValidationErrors: SerialValidationErrors.None,
          price:this.serialsProperties.packObj.price,
          childPackPrice:this.serialsProperties.packObj.childPackPrice,
          salesTransactionTypeId:this.serialsProperties.packObj.salesTransactionTypeId,
          isReserved: false,
          recallId: -1,
          transactionRecallId: this.serialsProperties.transactionRecallId,
          transactionReadingId:this.serialsProperties.transactionReadingId,
          batchNo: null, // this value will set in back end when validate serials online
          expiryDateModel: null, // this value will set in back end when validate serials online
          ssccBarcode: this.serialsProperties.ssccBarcode,
          itemNameCode: this.serialsProperties.packObj.itemCode + " - " + this.serialsProperties.packObj.itemName + ' (' + this.serialsProperties.packObj.uom + ')',

        };

        if (this.serialsProperties.transactionRecallId != -1) {
          // these value must not re-set in back end, scanned serial must have same batch & expiry of selected pack obj 
          temp_serialObj.batchNo = this.serialsProperties.packObj.batchNo;
          temp_serialObj.expiryDateModel = this.serialsProperties.packObj.expiryDateModel;
        }
        this.checkIfSerialAlreadyAdded(temp_serialObj);
      }
      return true;
    }
    else if (fromSerialNumberLength != toSerialNumberLength) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Length_Of_Numbers_Part_Not_Match));
      return false;
    }
    else if (fromSerialCharPart != toSerialCharPart) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Character_Part_Not_Match));
      return false;
    }
    else if (fromSerialNumberPart > toSerialNumberPart) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_Less_Than_To_Serial));
      return false;
    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Range_Serial_Not_Valid));
      return false;
    }
  }
  validateGTINInTemplate(fullSerial) {
    let trimmedGTIN = '-1';
    if (this.serialsProperties.packObj.templateGTINIndecies && this.serialsProperties.packObj.templateGTINIndecies.length === 2) {
      trimmedGTIN = fullSerial.substring(this.serialsProperties.packObj.templateGTINIndecies[0], this.serialsProperties.packObj.templateGTINIndecies[1] + 1);

    }
    if (trimmedGTIN !== '-1' && trimmedGTIN !== this.serialsProperties.packObj.gtin) {
      return false;
    }
    return true;
  }
  validateProductionDateInTemplate(fullSerial) {
    if (this.serialsProperties.packObj.productionIncludedInTemplate) {
      let dateTrimmed = this.trimProductionDateFromTemplate(fullSerial);
      if (dateTrimmed != '-1' && dateTrimmed != '') {
        let dateFromFormat = this.getDateAsString(dateTrimmed);
        if (!dateFromFormat || dateFromFormat === '') return false;
        let date = this.coreSession.validatedate(dateFromFormat, this.dateFormat);
        if (date != null)
          this.productionDate = this.coreSession.getDateTimeModel(date);
        else
          return false;
      }
    } else {
      return true;
    }

    return true;
  }
  fillBatchAndExpiryFromTemplate(fullSerial) {
    if (this.serialsProperties.packObj.enableBatchForSerial) {
      if (this.serialsProperties.packObj.expiryIncludedInTemplate) {
        let expiryDateTrimmed = this.trimExpiryDateFromTemplate(fullSerial);
        if (expiryDateTrimmed != '-1' && expiryDateTrimmed != '') {
          let dateFromFormat = this.getDateAsString(expiryDateTrimmed);
          if (!dateFromFormat || dateFromFormat === '') return false;
          let date = this.coreSession.validatedate(dateFromFormat, this.dateFormat);
          if (date != null)
            this.desiredExpiryDate = this.coreSession.getDateTimeModel(date);
          else
            return false;
        }
      }
    }
    if (this.serialsProperties.packObj.enableBatchForSerial) {
      if (this.serialsProperties.packObj.batchIncludedInTemplate) {
        let batch = this.trimBatchNoFromTemplate(fullSerial);
        if (batch != '-1')
          this.batchNo = batch;
        else
          return false;
      }
      else {
        if (this.enableBatch) {
          this.batchNo = this.batchNoInput.value;
        } else {
          this.batchNo = this.coreSession.DefaultBatchNumber;
        }
      }
    }
    return true;
  }
  validateBarcodeWithTemplate(fullBarcode) {
    if (fullBarcode.length != this.serialsProperties.packObj.totalFieldsLengthOfItemTemplate) {
      return false;
    }
    if (!this.fillBatchAndExpiryFromTemplate(fullBarcode)) {

      return false;
    }
    if (!this.validateGTINInTemplate(fullBarcode)) {

      return false;
    }
    if (!this.validateProductionDateInTemplate(fullBarcode)) {

      return false;
    }
    return true;
  }
  getDateAsString(trimmedDate) {
    let returnedDate = '';
    let dateFormat = this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
    if (trimmedDate && trimmedDate.length == dateFormat.length) {
      // returnedDate = trimmedDate.substring(dateFormat.indexOf('dd', 0), dateFormat.indexOf('dd', 0) + 2);
      // returnedDate = returnedDate + '/' + trimmedDate.substring(dateFormat.indexOf('MM', 0), dateFormat.indexOf('MM', 0) + 2);
      // returnedDate = returnedDate + '/' + trimmedDate.substring(dateFormat.indexOf('yyyy', 0), dateFormat.indexOf('yyyy', 0) + 4);
      returnedDate = trimmedDate.toString();
    }
    return returnedDate;
  }
  trimSerialFromTemplate(serial) {
    var trimmedSerial = serial;
    var startString = '';
    var endString = '';
    if (this.serialsProperties.packObj.templateId > 0 && this.serialsProperties.packObj.templateSerialsIndecies && this.serialsProperties.packObj.templateSerialsIndecies.length === 2) {
      trimmedSerial = serial.substring(this.serialsProperties.packObj.templateSerialsIndecies[0], this.serialsProperties.packObj.templateSerialsIndecies[1] + 1);
      startString = serial.substring(0, this.serialsProperties.packObj.templateSerialsIndecies[0]);
      endString = serial.substring(this.serialsProperties.packObj.templateSerialsIndecies[1] + 1, serial.length);
    }
    return {
      trimmedSerial: trimmedSerial,
      startString: startString,
      endString: endString
    };
  }

  trimBatchNoFromTemplate(serial) {
    var trimmedBatch = '-1';
    if (this.serialsProperties.packObj.templateId > 0 && this.serialsProperties.packObj.templateBatchNoIndecies && this.serialsProperties.packObj.templateBatchNoIndecies.length === 2) {
      trimmedBatch = serial.substring(this.serialsProperties.packObj.templateBatchNoIndecies[0], this.serialsProperties.packObj.templateBatchNoIndecies[1] + 1);
    }
    return trimmedBatch;
  }
  trimExpiryDateFromTemplate(serial) {
    let trimmedExpiry = '-1';
    if (this.serialsProperties.packObj.templateId > 0 && this.serialsProperties.packObj.templateExpiryDateIndecies && this.serialsProperties.packObj.templateExpiryDateIndecies.length === 2) {
      trimmedExpiry = serial.substring(this.serialsProperties.packObj.templateExpiryDateIndecies[0], this.serialsProperties.packObj.templateExpiryDateIndecies[1] + 1);
    }
    return trimmedExpiry;
  }
  trimProductionDateFromTemplate(serial) {
    let trimmedDate = '-1';
    if (this.serialsProperties.packObj.templateId > 0 && this.serialsProperties.packObj.templateProductionDateIndecies && this.serialsProperties.packObj.templateProductionDateIndecies.length === 2) {
      trimmedDate = serial.substring(this.serialsProperties.packObj.templateProductionDateIndecies[0], this.serialsProperties.packObj.templateProductionDateIndecies[1] + 1);
    }
    return trimmedDate;
  }
  checkIfSerialParentNotIsAdded(serial) {
    var notFound = true;
    if (serial.parentsList && serial.parentsList.length > 0) {
      
      serial.parentsList.forEach(parent => {
        var index = this.addedSerialsForAllPacks.findIndex(x => (x.fromSerial.toLowerCase() === parent.toLowerCase()));
        if (index != -1) {
          notFound = false;
          if (this.addedSerialsForAllPacks[index].itemId === this.serialsProperties.packObj.itemId)
            serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
          else
            serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBeforeForAnotherItem;

          this.invalidSerials.push(serial);
          return false;
        }
      });
    }
    return notFound;

  }
  checkIfSerialAlreadyAdded(serial) {
    if (this.addedSerials.findIndex(x => x.fromSerial.toLowerCase() === serial.fromSerial.toLowerCase()) === -1) {
      var index = this.addedSerialsForAllPacks.filter(serial=> serial.itemId !== this.serialsProperties.packObj.itemId && serial.packId!==this.serialsProperties.packObj.packId).findIndex(x => x.fromSerial.toLowerCase() === serial.fromSerial.toLowerCase() || (x.parentSerial && x.parentSerial.toLowerCase() === serial.fromSerial.toLowerCase()));
      if (index === -1) {
        this.temp_addedSerials.push(serial);
        return true;
      } else {

        if (this.addedSerialsForAllPacks.filter(serial=> serial.itemId !== this.serialsProperties.packObj.itemId && serial.packId!==this.serialsProperties.packObj.packId)[index].itemId === this.serialsProperties.packObj.itemId)
          serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
        else
          serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBeforeForAnotherItem;

        this.invalidSerials.push(serial);
        return false;
      }
    } else {
      serial.serialValidationErrors =
        SerialValidationErrors.SerialEnteredBefore;
        this.invalidSerials.push(serial);
      return false;
    }
  }
  getTrimmedSerialsAndValidate(){
    if (this.fromSerialInput.value.length < (this.serialTrimStartLength + this.serialTrimEndLength)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
      return null;
    }
       //Apply trim configuration and trim over pack for checking if serial already exist
    var fromSerial = this.fromSerialInput.value.substring(this.serialTrimStartLength, this.fromSerialInput.value.length);
    fromSerial = fromSerial.substring(0, fromSerial.length - this.serialTrimEndLength);
    //fromSerial = fromSerial.substring(this.serialsProperties.packObj.packTrimSerialStartDigits, fromSerial.length);
    //fromSerial = fromSerial.substring(0, fromSerial.length - this.serialsProperties.packObj.packTrimSerialEndDigits);
var toSerial=fromSerial;
if(!this.isSingle){
  if (this.fromSerialInput.value.length != this.toSerialInput.value.length) {
    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Not_Equals));
    return null;
  }

      toSerial = this.toSerialInput.value.substring(
        this.serialTrimStartLength,
        this.toSerialInput.value.length
      );
      toSerial = toSerial.substring(
        0,
        toSerial.length - this.serialTrimEndLength
      );
      // toSerial = toSerial.substring(this.serialsProperties.packObj.packTrimSerialStartDigits, toSerial.length);
      //toSerial = toSerial.substring(0, toSerial.length - this.serialsProperties.packObj.packTrimSerialEndDigits);
      var fromSerialParts = this.getSerialAllParts(fromSerial);
      var toSerialParts = this.getSerialAllParts(toSerial);
      if (fromSerialParts != null && toSerialParts != null) {
        if (
          fromSerialParts.serialPartsTemp.startString !==
          toSerialParts.serialPartsTemp.startString ||
          fromSerialParts.serialPartsTemp.endString !==
          toSerialParts.serialPartsTemp.endString
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(
              ConstantMessages.Desc_From_Serial_To_Serial_Template_Fields_Not_Match
            )
          );
          return null;
        }

        if (
          fromSerialParts.serialNumberLength ===
          toSerialParts.serialNumberLength &&
          fromSerialParts.serialChartPart === toSerialParts.serialChartPart &&
          fromSerialParts.serialNumberPart <= toSerialParts.serialNumberPart
        ) {
        } else if (
          fromSerialParts.serialNumberLength != toSerialParts.serialNumberLength
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(
              ConstantMessages.Desc_Length_Of_Numbers_Part_Not_Match
            )
          );
          return null;
        } else if (
          fromSerialParts.serialChartPart != toSerialParts.serialChartPart
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(
              ConstantMessages.Desc_Character_Part_Not_Match
            )
          );
          return null;
        } else if (
          fromSerialParts.serialNumberPart > toSerialParts.serialNumberPart
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(
              ConstantMessages.Desc_From_Serial_Less_Than_To_Serial
            )
          );
          return null;
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(
              ConstantMessages.Desc_Range_Serial_Not_Valid
            )
          );
          return null;
        }
      }
    }

    let serial: any = {};
    serial.fromSerial = fromSerial;
    serial.toSerial = toSerial;
    serial.itemNameCode = this.serialsProperties.packObj.itemCode + " - " + this.serialsProperties.packObj.itemName + ' (' + this.serialsProperties.packObj.uom + ')';
    return serial;
  }
  getTrimmedSerials(){
    if (this.fromSerialInput.value.length < (this.serialTrimStartLength + this.serialTrimEndLength)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
      return null;
    }
       //Apply trim configuration and trim over pack for checking if serial already exist
    var fromSerial = this.fromSerialInput.value.substring(this.serialTrimStartLength, this.fromSerialInput.value.length);
    fromSerial = fromSerial.substring(0, fromSerial.length - this.serialTrimEndLength);
    //fromSerial = fromSerial.substring(this.serialsProperties.packObj.packTrimSerialStartDigits, fromSerial.length);
    //fromSerial = fromSerial.substring(0, fromSerial.length - this.serialsProperties.packObj.packTrimSerialEndDigits);
var toSerial=fromSerial;
if(!this.isSingle){
  if (this.fromSerialInput.value.length != this.toSerialInput.value.length) {
    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Not_Equals));
    return null;
  }

  toSerial = this.toSerialInput.value.substring(this.serialTrimStartLength, this.toSerialInput.value.length);
  toSerial = toSerial.substring(0, toSerial.length - this.serialTrimEndLength);
 // toSerial = toSerial.substring(this.serialsProperties.packObj.packTrimSerialStartDigits, toSerial.length);
  //toSerial = toSerial.substring(0, toSerial.length - this.serialsProperties.packObj.packTrimSerialEndDigits);
  
 
}

    let serial: any = {};
    serial.fromSerial = fromSerial;
    serial.toSerial = toSerial;
    serial.itemNameCode = this.serialsProperties.packObj.itemCode + " - " + this.serialsProperties.packObj.itemName + ' (' + this.serialsProperties.packObj.uom + ')';
    return serial;
  }
  checkIfSerialAlreadyAddedForRanges(serial) {
   
    if (this.serialsService.checkIfSerialsListContainsAnyPartOfSerialRange(serial,this.addedSerials) === -1) {
      //var index = this.addedSerialsForAllPacks.findIndex(x => x.fromSerial.toLowerCase() === serial.fromSerial.toLowerCase() || (x.parentSerial && x.parentSerial.toLowerCase() === serial.fromSerial.toLowerCase()));
      var index = this.serialsService.checkIfSerialsListContainsAnyPartOfSerialRange(serial, this.addedSerialsForAllPacks.filter(serial => serial.itemId !== this.serialsProperties.packObj.itemId && serial.packId !== this.serialsProperties.packObj.packId));
      if (index === -1) {
        return true;
      }
      else {
        if (serial.fromSerial === serial.toSerial) {
          serial.serialsCount = 1;
          if (
            this.addedSerialsForAllPacks[index].itemId === this.serialsProperties.packObj.itemId
          )
            serial.serialValidationErrors =
              SerialValidationErrors.SerialEnteredBefore;
          else
            serial.serialValidationErrors =
              SerialValidationErrors.SerialEnteredBeforeForAnotherItem;
        } else {
          var fromSerialParts=this.getSerialAllParts(serial.fromSerial);
          var toSerialParts=this.getSerialAllParts(serial.toSerial);
          if(fromSerialParts!=null && toSerialParts!=null
            && fromSerialParts.serialNumberPart && toSerialParts.serialNumberPart
            && fromSerialParts.serialNumberPart < toSerialParts.serialNumberPart){
            serial.serialsCount=toSerialParts.serialNumberPart-fromSerialParts.serialNumberPart+1;
          }else{
            serial.serialsCount=1;
          }
          if (
            this.addedSerialsForAllPacks[index].itemId === this.serialsProperties.packObj.itemId
          )
            serial.serialValidationErrors =
              SerialValidationErrors.SerialsEnteredBefore;
          else
            serial.serialValidationErrors =
              SerialValidationErrors.SerialsEnteredBeforeForAnotherItem;
        }

        this.invalidSerials.push(serial);
        return false;
      }
    } else {
      if (serial.fromSerial === serial.toSerial)
      {
        serial.serialValidationErrors =
          SerialValidationErrors.SerialEnteredBefore;
        serial.serialsCount=1;
      }
      else{
        var fromSerialParts=this.getSerialAllParts(serial.fromSerial);
        var toSerialParts=this.getSerialAllParts(serial.toSerial);
        if(fromSerialParts!=null && toSerialParts!=null
          && fromSerialParts.serialNumberPart && toSerialParts.serialNumberPart
          && fromSerialParts.serialNumberPart < toSerialParts.serialNumberPart){
          serial.serialsCount=toSerialParts.serialNumberPart-fromSerialParts.serialNumberPart+1;
        }else{
          serial.serialsCount=1;
        }
        serial.serialValidationErrors =
          SerialValidationErrors.SerialsEnteredBefore;
      }


      this.invalidSerials.push(serial);
      return false;
    }
  }
  
  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
  }
  onAddingSubSerials(data: any) {
    this.addedSerials.push(data.addedSerials);
    this.invalidSerials.push(data.invalid);

    this.fillInvalidDataSource();
    if (data.invalid && data.invalid.length > 0) {
     // this.ChangeDataView(1);
      //this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Some_Serials_Not_Valid));
    }
  }
  onProductionDateSelected(date) {
    this.productionDate = date;
  }
  onDesiredExpiryDateSelected(date) {
    this.desiredExpiryDate = date;
    let batchNoFormat = this.sessionData.getConfigurationValue(ConstantConfigurations.BatchNumberFormat);
    if (batchNoFormat && batchNoFormat != '' && batchNoFormat != "No Format" && batchNoFormat != "NoFormat") {
      this.batchNo = this.datepipe.transform(this.desiredExpiryDate.date, batchNoFormat);
    }
    else {
      this.batchNo = this.datepipe.transform(this.desiredExpiryDate.date, this.coreSession.BatchNoFormat);
    }
    this.batchNoInput.setValue(this.batchNo);
  }
  isInputVoucher() {
    return this.serialsProperties.itemFormProcessMode === FormProcessMode.InputVoucher;
  }
  isAggregate() {
    return this.serialsProperties.itemFormProcessMode === FormProcessMode.SerialsAggregation;
  }
  // get serialNumberInput() {
  //   return this.addSerialForm.get('serialNumber');
  // }
  get fromSerialInput() {
    return this.addSerialForm.get('fromSerial');
  }
  get toSerialInput() {
    return this.addSerialForm.get('toSerial');
  }
  get batchNoInput() {
    return this.addSerialForm.get('batchNo');
  }
  get expiryDateInput() {
    return this.addSerialForm.get('expiryDate');
  }
  get productionDateInput() {
    return this.addSerialForm.get('productionDate');
  }
  get reqQuantityInput() {
    return this.addSerialForm.get('reqQuantity');
  }
}
