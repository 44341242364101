import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PaymentTermModel } from '../../../../shared/models/customer/payment-term.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PaymentTermTypes } from '../../../../shared/models/enums/payment-term-types.enum';
import { PeriodTypes } from '../../../../shared/models/enums/period-types.enum';
import { CoreSession } from '../../../../core/core.session';
import { Observable } from 'rxjs';
import { CustomerService } from '../../../../shared/services/data-definition/customer/customers.service';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../../shared/models/constants/constant-message';

@Component({
  selector: 'app-payment-term-entry',
  templateUrl: './payment-term-entry.component.html',
  styleUrls: ['./payment-term-entry.component.css']
})
export class PaymentTermEntryComponent implements OnInit {
isSimple=true;
@Output() onAddingNewPaymentTerm=new EventEmitter();
paymentForm:FormGroup;
@Input() isEditMode=false;
@Input() isViewMode=false;
@Input() saveEvent: Observable<void>;
saveBtnSubscription: any;
periodTypesDataSource:any[]=[];
paymentTermTypesDataSource:any[]=[];
selectedPaymentTermType:number=PaymentTermTypes.SimplePeriod.valueOf();
isTypeLoading=false;
  constructor(private customerService:CustomerService,
    private coreSession:CoreSession,
    private translateService: TranslateService,) { }

  ngOnInit() {
    this.subscribeSaveClick();
    this.paymentForm=new FormGroup({
      simpleData: new FormGroup({
      simplePeriodWidth: new FormControl(
        { value: "", disabled: this.isViewMode },
        this.isEditMode ? null : Validators.required
      )
    }) ,
      complexData: new FormGroup({
        complexPaymentDay: new FormControl(
        { value: "", disabled: this.isViewMode },
        this.isEditMode ? null : Validators.required,
      ),  gracePeriodWidth: new FormControl(
        { value: null, disabled: this.isViewMode },
        this.isEditMode ? null : Validators.required
      ),complexPeriodType:new FormControl (
        { value: "Month", disabled: true },
        this.isEditMode ? null : Validators.required )
    }
      ),periodTypeId: new FormControl (
        { value: null, disabled: this.isViewMode },
        this.isEditMode ? null : Validators.required
      )
    });

    this.populatePaymentTermTypes();
    this.populatePeriodTypes();
  }
  populatePaymentTermTypes(){
    this.isTypeLoading = true;
    this.customerService.getPaymentTermTypes().subscribe(result => {
      this.paymentTermTypesDataSource = result.data;
      this.isTypeLoading = false;

    });
  }
  populatePeriodTypes(){
    this.customerService.getPeriodTypes().subscribe(result => {
      this.periodTypesDataSource = result.data;
    });
  }
  onChangeSelectedPaymentType(cleareValue : boolean){
    if(this.selectedPaymentTermType== PaymentTermTypes.SimplePeriod.valueOf())
    this.isSimple=true;
    else
    this.isSimple=false;

    if(cleareValue)
    {
      this.paymentForm.get('periodTypeId').setValue(null)
    }
  }
  addNew(){

    var paymentTerm =new  PaymentTermModel();
    var periodDescription='';
    var index=this.coreSession.GetIndexForList(this.periodTypesDataSource,'periodTypeId',this.paymentForm.value.periodTypeId);
    if(index>=0){
      periodDescription=this.periodTypesDataSource[index]['periodTypeDescription'];
    }else{
      return;
    }

    if(!this.isSimple)
    {
      let complexPaymentData =Number(this.paymentForm.get('complexData').get('complexPaymentDay').value);
      if(complexPaymentData <= 0)
      {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant('Desc_Complex_Payment_Day_Should_Be_More_than_zero'));
        return;
      }
    }

    if(this.isSimple){
      paymentTerm.paymentTermTypeId=PaymentTermTypes.SimplePeriod.valueOf();
      paymentTerm.simplePeriodId=+this.paymentForm.value.periodTypeId;
      paymentTerm.simplePeriodWidth=+this.paymentForm.value.simpleData.simplePeriodWidth;
      paymentTerm.paymentTermDescription="Every "+  paymentTerm.simplePeriodWidth + " "+periodDescription;
    }else{
      paymentTerm.paymentTermTypeId=PaymentTermTypes.ComplexPeriod.valueOf();
      paymentTerm.complexPeriodId=+PeriodTypes.Month.valueOf();
      paymentTerm.complexPeriodWidth=+this.paymentForm.value.complexData.complexPaymentDay;
      paymentTerm.gracePeriodTypeId=+this.paymentForm.value.periodTypeId;
      paymentTerm.gracePeriod=+this.paymentForm.value.complexData.gracePeriodWidth;
      paymentTerm.paymentTermDescription=paymentTerm.complexPeriodWidth+" "+"Of the Month"+" "+"and a Grace period :"+" "+paymentTerm.gracePeriod
      +" "+periodDescription;
    }

        this.customerService.InsertNewPaymentTerm(paymentTerm).subscribe(result => {
          paymentTerm=result.data;
          this.onAddingNewPaymentTerm.emit(paymentTerm.paymentTermId);
  });
  }
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.saveEvent.subscribe(() => {
      this.addNew();
    });
  }
}
