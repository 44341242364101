import { Component, OnInit } from '@angular/core';
import { CoreSession } from '../../core/core.session';

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.css']
})
export class Home2Component implements OnInit {

  constructor(private coreSession: CoreSession) {
  }

  ngOnInit() {
    this.coreSession.SetTitle('Home2');
  }

  onClick() {
    this.coreSession.ModalLoading.Show();
    this.coreSession.SetDelay(3000).then(
      (res) => {
        this.coreSession.ModalLoading.Hide();
      }
    )
  }

}
