import { Subject } from "rxjs/internal/Subject";
import { Injectable } from "@angular/core";
import { NavigationData } from "../../shared/models/navigation-data/navigation-data.model";

@Injectable({ providedIn: "root" })
export class NavBarService {
    SettingsClicked = new Subject<boolean>();
    MinimizedClicked = new Subject<boolean>();
    ReloadSidebar = new Subject<boolean>();
    ActiveSidebar = new Subject<any>();
    NavigationChanged = new Subject<NavigationData[]>()
    GeneralSerachChanged = new Subject();
    public searchMenuURL: string;

    setGeneralSerachPattern() {
        this.GeneralSerachChanged.next();
    }
    setNavigation(navigationData) {
        this.NavigationChanged.next(navigationData);
    }
    setIsMiniMized(minimized) {
        this.MinimizedClicked.next(minimized);
    }

    setReloadSidebar() {
        this.ReloadSidebar.next();
    }

    setSettingClicked(setting) {
        this.SettingsClicked.next(setting);
    }

    setActiveSidebar(menuUrl) {
        this.ActiveSidebar.next(menuUrl);
    }
}
