import { Injectable, Inject } from '@angular/core';

import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseModel } from '../../../../shared/models/api-models/api-models';
import { CoreSession } from '../../../../core/core.session';
@Injectable({
  providedIn: 'root'
})
export class TerritoryService {

  httpOptions;

  private url = '';
  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Territory';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }
  getFreeTerritoryList(territoryFilter: any) {
    const urlValue: string = this.url + '/GetFreeTerritoryList';
    return this.http.post<ResponseModel>(urlValue, territoryFilter).map(response => {
      return response.data;
    });
  }
  getMasterTerritoryList() {
    const urlValue: string = this.url + '/GetMasterTerritoryList';
    return this.http.post<ResponseModel>(urlValue, "").map(response => {
      return response.data;
    });
  }
  saveTerritory(territory: any, isEditMode: boolean) {
    const urlValue: string = this.url + (isEditMode ? '/UpdateTerritory' : '/InsertTerritory');
    return this.http.post(urlValue, JSON.stringify(territory), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getAllTerritories(territoryFilter) {
    const urlValue: string = this.url + '/GetAllTerritories';
    return this.http.post<ResponseModel>(urlValue, territoryFilter, this.httpOptions).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }
  getRoutes(routeFilter) {
    const urlValue: string = this.url + '/GetRoutes';
    return this.http.post<ResponseModel>(urlValue, routeFilter).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }

  getRoutesTerritoryList(territoryFilter: any) {
    const urlValue: string = this.url + '/GetRoutesTerritory';
    return this.http.post(urlValue, JSON.stringify(territoryFilter), this.httpOptions).map(
      response => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }

  getTerritoriesListSharedTable(territoryFilter: any) {
    const urlValue: string = this.url + '/GetTerritoriesListSharedTable';
    return this.http.post(urlValue, JSON.stringify(territoryFilter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  updateTerritotyActiveStatus(filter) {
    const urlValue: string = this.url + '/UpdateTerritotyActiveStatus';


    return this.http.post(urlValue, filter).map(
      (response) => {
        let result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  isValidToDeleteRoute(routeId: number) {
    const urlValue: string = this.url + '/IsValidToRemoveRoute';
    return this.http.post(urlValue, routeId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getTerritoryData(territoryId: number) {
    const urlValue = this.url + '/GetTerritoryData';
    return this.http
      .post<ResponseModel>(urlValue, territoryId)
      .map(response => {
        return response;
      });
  }
}
