export enum WebFormType {
    List = 1,
    Entry = 2
}
export enum WebFormColumnType {
    Text = 1,
    Integer = 2,
    NumberInDigitsFormat = 3,
    NumberInStockDigitsFormat = 4,
    Date = 5,
    DateTime = 6,
    Boolean = 7
}
export enum WebFormFilterType {
    Text = 1,
    Date = 2,
    DateRange = 3,
    DropDown = 4,
    MultiSelectDropDown = 5,
    Boolean = 6
}
export enum WebFormControlType {
    Text = 1,
    DescriptionText = 2,
    Integer = 3,
    NumberOfDigits = 4,
    NumberOfStockDigits = 5,
    Date = 6,
    DateRange = 7,
    DropDown = 8,
    MultiSelectDropDown = 9,
    Boolean = 10,
    SCOPE_IDENTITY = 11,
    Percentage = 12,
    DisabledText = 13
}
export enum WebFormValidation {
    None = 0,
    Required = 1,
    Unique = 2,
    RequiredUnique = 3,
    RequiredNonZero = 4,
    Email = 5,
    RequiredEmail = 6
}
export enum WebBuiltInFilters {
    None = 0,
    Employee = 1,
    Organization = 2,
    Bank = 3
}