
export enum SalesTransactionTypes
    {
        Sales = 1,
        FOC = 2,
        Sample = 3,
        Promotion = 4,
        None = 5,
        FOCReturn = 6,
        Bundle = 7,
        FreeReturn = 8,
        FreeReturnAsDiscount = 9,
    }