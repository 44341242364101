import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject } from "rxjs";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";

@Injectable({
  providedIn: "root",
})
export class ItemGroupService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "ItemGroup";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
  InsertOrUpdateItemGroup(itemGroup: FormData, isEditMode: boolean) {
    const urlValue = this.url + (isEditMode ? "/UpdateItemGroup" : "/InsertItemGroup");
    return this.http.post<ResponseModel>(urlValue, itemGroup)
      .map(response => {
        return response;
      })
  }

  getAllItemGroupLazyLoading(filter: any) {
    const urlValue: string = this.url + '/GetAllItemGroupLazyLoading';
    return this.http.post<ResponseModel>(urlValue, filter);
  }

  getItemGroupDataInEdit(itemGroupId: number) {
    const urlValue: string = this.url + "/GetItemGroupInEdit";

    return this.http.post<ResponseModel>(urlValue, itemGroupId).map(
      response => {
        return response;
      });
  }
  deleteItemGroupData(itemGroupID: number) {
    const urlValue: string = this.url + "/DeleteItemGroupData";
    return this.http.post<ResponseModel>(urlValue, itemGroupID).map(
      response => {
        return response;
      });
  }

}
