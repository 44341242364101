export enum SerialValidationErrors {
    None = 0,
    NotFoundInDB,
    NotInVehicle,
    NotInWarehouse,
    NotLoadedToVehicle,
    NotLoadedToMainWarehouse,
    SerialsBelongToAnotherPack,
    AlreadyLoadedToVehicle,
    AlreadyAddedInSystem,
    AlreadySoldToCustomer,
    NotSoldToCustomer,
    NotSoldToThisCustomer,
    SerialBelongToZeroPricePack,
    SerialEnteredBefore,
    SerialEnteredBeforeForAnotherItem,
    OneOrMoreSubSerialNotValid,
    SerialTransferedOutInAnotherTransaction,
    ParentOfThisSerialAddedInThisTransferOut,
    BarcodeNotCompatibleWithTemplate,
    BarcodeOfSubSerialNotCompatibleWithParent,
    SubSerialHasDifferentBatchOrExpiry,
    SubSerialAlreadyAggregated,
    ReservedSerial,
    SerialNotDefinedOverRecall,
    SerialLinkedToSSCC,
    SerialsEnteredBefore,
    SerialsEnteredBeforeForAnotherItem,
    BlockedSerial
}
