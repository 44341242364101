import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { PriceService } from '../../../services/data-definition/prices-and-offers/price.service';

@Component({
  selector: 'app-stock-status-filter',
  templateUrl: './stock-status-filter.component.html',
  styleUrls: ['./stock-status-filter.component.css']
})
export class StockStatusFilterComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Output() stockStatusSelected = new EventEmitter();

  stockStatusDataSource: any[];
  isRequired: boolean = false;
  isStockStatusLoading: boolean = false;
  constructor(private priceService: PriceService) {
   }

  ngOnInit(): void {
    if(this.parentForm.get('stockStatusId').validator != null){
      const validator = this.parentForm.get('stockStatusId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateStockStatusesList();
  }

  populateStockStatusesList() {
    this.isStockStatusLoading = true;
    this.priceService.getStockStatuses().subscribe(result => {
      this.stockStatusDataSource = result.data;
      this.isStockStatusLoading = false;
    }, (error) => {
      this.isStockStatusLoading = false;
    });
  }

  onChange(selectedStockStatus:any){
    this.stockStatusSelected.emit(selectedStockStatus);
  }

}
