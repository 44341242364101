import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CoreSession } from "../../../../core/core.session";
import { WarehouseTransaction } from "../../../models/warehouse-transactions/warehouse-transaction.model";
import { FormProcessMode } from "../../../models/enums/form-process-mode.enum";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { TranslateService } from "@ngx-translate/core";
import { ItemPackModel } from "../../../models/Item/item.model";
import * as cloneDeep from "lodash/cloneDeep";
import { SSCCGenerationService } from "../sscc-generation.service";
import { HttpErrorResponse } from "@angular/common/http";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import { WarehouseTransactionStatus } from "../../../models/enums/warehouse-transaction-status.enum";
import { ResponseModel } from "../../../models/api-models/api-models";
import { DialogMode } from "../../../models/enums/dialog-mode.enum";
import { VehicleOperationService } from "../../../../content/vehicle-operation/vehicle-operation.service";
import { WarehouseTransactionOperation } from "../../../models/enums/warehouse-transaction-operation.enum";
import {
  OrderListFilter,
  warehouseFilter,
} from "../../../models/shared-table/custom-list.interface";
import { OrderService } from "../../../../content/order/order.service";
import { OrderOperation } from "../../../models/enums/order-operation .enum";
import { OrderStatus } from "../../../models/enums/order-status.enum";
import { OrderModule } from "../../../../content/order/order.module";
import { SalesTransactionTypes } from "../../../models/enums/sales-transaction-types";
import { TransactionMainTypes } from "../../../models/enums/transaction.main.types.enum";

@Component({
  selector: "app-sscc-details",
  templateUrl: "./sscc-details.component.html",
  styleUrls: ["./sscc-details.component.scss"],
})
export class SsccDetailsComponent implements OnInit {
  @Input() isEditMode = false;
  @Input() isViewMode = false;
  @Input() warehouseTransaction: WarehouseTransaction;
  @Input() orderObj: any;
  @Input() ssccObject: any = {};
  @Input() fromMainSSCCList = false;
  @Input() isFromDelivery = false;
  @Input() isFromPartialDelivery = false;
  @Input() showTax = false;
  @Input() showRetailTax = false;
  @Output("onSaveComplete") onSaveComplete = new EventEmitter();
  ssccDetailsForm: FormGroup;
  isLoading = true;
  isBarcodeGenerated = false;
  showItems = false;
  selectedDivisionId = -1;
  selectedWarehouseId = -1;
  selectedRefWarehouseId = -1;
  stockWarehouseId = -1;
  selectedTransactionId = "";
  selectedOrderId = "";

  WHTransactionstatus = WarehouseTransactionStatus.None;

  disableHeader = false;
  isExecuteMode = true;
  transactionDataSource: any[];
  ordersDataSource: any[];
  query: warehouseFilter = {
    customListFilter: {
      searchFilter: "",
      page: 0,
      pageSize: this.coreSession.pageSize,
    },
    employeeId: -1,
    refWarehouseId: -1,
    warehouseId: -1,
    transactionDate: null,
    getAllData: true,
    processMode: FormProcessMode.None,
    warehouseTransactionStatusId: WarehouseTransactionStatus.Approved.valueOf(),
  };
  orderQuery: OrderListFilter = {
    customListFilter: {
      page: 0,
      pageSize: this.coreSession.pageSize,
    },
    orderOperation: OrderOperation.GetAssigned,
    //orderStatus: OrderStatus.Assigned,
    customerId: -1,
    outletId: -1,
    employeeId: -1,
    driverId: -1,
    getAllData: true,
  };
  modes = {
    none: 0,
    warehouseTransaction: 1,
    order: 2,
    warehouse: 3,
  };
  selectedMode = this.modes.none;
  formProcessMode: FormProcessMode = FormProcessMode.LoadRequest;
  transactionMainType = TransactionMainTypes.WarehouseTransaction;
  ssccTotals = [];
  constructor(
    public coreSession: CoreSession,
    private translateService: TranslateService,
    private ssccGenerateService: SSCCGenerationService,
    private sessionData: SessionDataProvider,
    private vehicleOperationService: VehicleOperationService,
    private orderService: OrderService
  ) {}

  ngOnInit() {
    this.isLoading = false;
    this.initForm();

    if (this.isEditMode) {
      this.ssccBarcodeInput.setValue(this.ssccObject.ssccBarcode);
      this.isBarcodeGenerated = true;
      if (this.fromMainSSCCList) this.fillDataInEditMode();
      else {
        this.showItems = true;
        this.fillData(this.modes.none);
      }
    } else {
      this.onGenerateBarcodeClicked();
      if (this.fromMainSSCCList) {
        this.getWarehouseTransactions();
        this.getOrders();
      } else {
        this.showItems = true;
        this.fillData(this.modes.none);
      }
      if (!this.ssccObject) this.ssccObject = {};
      if (!this.ssccObject.details) this.ssccObject.details = [];
    }
    //  this.fillSSCCAllItems();
  }
  fillDataInEditMode() {
    if (this.ssccObject) {
      if (this.ssccObject.transactionId) {
        this.selectedMode = this.modes.warehouseTransaction;
        this.formProcessMode = FormProcessMode.LoadRequest;
        this.getWarehouseTransactions();
        //
      } else if (this.ssccObject.orderId) {
        this.selectedMode = this.modes.order;
        this.formProcessMode = FormProcessMode.Delivery;
        this.getOrders();
      } else {
        this.selectedMode = this.modes.warehouse;

        this.formProcessMode = FormProcessMode.OutputVoucher;
        this.warehouseIdInput.setValue(this.ssccObject.warehouseId);
        if (this.isDivisionLevel()) {
          this.divisionIdInput.setValue(this.ssccObject.divisionId);
        }

        this.fillData(this.modes.warehouse);
        this.showItems = true;
      }
      this.disableAllControls();
    }
  }
  fillData(mode) {
    this.selectedMode = mode;
    if (this.fromMainSSCCList) {
      if (
        mode === this.modes.warehouseTransaction &&
        this.warehouseTransaction
      ) {
        this.selectedRefWarehouseId = this.warehouseTransaction.refWarehouseId;
        this.stockWarehouseId = this.warehouseTransaction.refWarehouseId;
        this.selectedDivisionId = this.warehouseTransaction.divisionId;
        this.selectedTransactionId = this.warehouseTransaction.transactionId;
        this.selectedWarehouseId = this.warehouseTransaction.warehouseId;
        this.WHTransactionstatus = this.warehouseTransaction.whTransactionStatusId;
        this.formProcessMode = FormProcessMode.LoadRequest;
      } else if (mode === this.modes.order && this.orderObj) {
        this.selectedRefWarehouseId = -1;
        this.stockWarehouseId = this.orderObj.warehouseId;
        this.selectedDivisionId = this.orderObj.divisionId;
        this.selectedTransactionId = "";
        this.selectedWarehouseId = this.orderObj.warehouseId;
        this.selectedOrderId = this.orderObj.orderId;
        this.isExecuteMode = false;
        this.formProcessMode = FormProcessMode.Delivery;
        this.transactionMainType = TransactionMainTypes.CustomerTransaction;
      } else if (mode === this.modes.warehouse) {
        this.formProcessMode = FormProcessMode.OutputVoucher;
        this.stockWarehouseId = this.warehouseIdInput.value;
        this.selectedWarehouseId = this.warehouseIdInput.value;
        this.isExecuteMode = false;
      }
    } else if (this.isFromPartialDelivery || this.isFromDelivery) {
      if (this.orderObj) {
        this.selectedRefWarehouseId = -1;
        this.stockWarehouseId = this.orderObj.warehouseId;
        this.selectedDivisionId = this.orderObj.divisionId;
        this.selectedTransactionId = "";
        this.selectedWarehouseId = this.orderObj.warehouseId;
        this.selectedOrderId = this.orderObj.orderId;
        this.isExecuteMode = false;
        if (this.isFromDelivery)
          this.formProcessMode = FormProcessMode.Delivery;
        else this.formProcessMode = FormProcessMode.PartialDelivery;

        this.transactionMainType = TransactionMainTypes.CustomerTransaction;
      }
    } else {
      if (this.warehouseTransaction) {
        this.selectedRefWarehouseId = this.warehouseTransaction.refWarehouseId;
        this.stockWarehouseId = this.warehouseTransaction.refWarehouseId;
        this.selectedDivisionId = this.warehouseTransaction.divisionId;
        this.selectedTransactionId = this.warehouseTransaction.transactionId;
        this.selectedWarehouseId = this.warehouseTransaction.warehouseId;
        this.WHTransactionstatus = this.warehouseTransaction.whTransactionStatusId;
      }
    }
  }
  initForm() {
    this.ssccDetailsForm = new FormGroup({
      ssccBarcode: new FormControl({ value: null, disabled: true }),
      transactionId: new FormControl({
        value: null,
        disabled:
          this.selectedMode != this.modes.none &&
          this.selectedMode != this.modes.warehouseTransaction,
      }),
      orderId: new FormControl({
        value: null,
        disabled:
          this.selectedMode != this.modes.none &&
          this.selectedMode != this.modes.order,
      }),
      warehouseId: new FormControl({
        value:
          this.selectedWarehouseId === -1 ? null : this.selectedWarehouseId,
        disabled:
          this.selectedMode != this.modes.none &&
          this.selectedMode != this.modes.warehouse,
      }),
      divisionId: new FormControl({
        value: null,
        disabled:
          this.selectedMode != this.modes.none &&
          this.selectedMode != this.modes.warehouse,
      }),
    });
  }
  onSubmitHeaderDataClicked() {
    this.coreSession.markFormGroupTouched(this.ssccDetailsForm);
    if (this.selectedMode === this.modes.none || this.ssccDetailsForm.invalid) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgFillMandatory)
      );
      return false;
    }
    if (this.selectedMode === this.modes.order) {
      this.getOrderData();
    } else if (this.selectedMode === this.modes.warehouseTransaction) {
      this.getWarehouseTransactionData();
    } else {
      this.fillData(this.modes.warehouse);
      this.showItems = true;
    }

    this.isLoading = false;
    this.disableAllControls();
    // if (this.isDivisionLevel())
    //   this.warehouseTransaction.divisionId = this.selectedDivisionId;
    // else
    //   this.warehouseTransaction.divisionId = -1;
  }
  isDivisionLevel() {
    return (
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.ControlTransactionOnDivisionLevel
        )
        .toLowerCase() === "true" &&
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.ShowDivisionSelectionOnWHTransaction
        )
        .toLowerCase() === "true"
    );
  }
  onChangeDivision(division: any) {
    if (!division) {
      this.selectedDivisionId = undefined;
      if (!this.selectedWarehouseId)
        this.disableEnableControls(this.modes.none);
    } else {
      this.selectedDivisionId = division.divisionId;
      this.disableEnableControls(this.modes.warehouse);
    }
  }
  onWarehouseDataChanged(warehouseId: number) {
    this.selectedWarehouseId = warehouseId;
    if (!this.orderObj) {
      if (
        !warehouseId &&
        (!this.selectedDivisionId || !this.isDivisionLevel())
      ) {
        this.disableEnableControls(this.modes.none);
      } else {
        this.disableEnableControls(this.modes.warehouse);
      }
    } else {
      this.orderObj.warehouseId = this.selectedWarehouseId;
    }
  }
  onTransactionIdChanged(warehouseTransactionObj: WarehouseTransaction) {
    this.warehouseTransaction = warehouseTransactionObj;
    if (warehouseTransactionObj)
      this.disableEnableControls(this.modes.warehouseTransaction);
    else this.disableEnableControls(this.modes.none);
  }
  onOrderIdChanged(order: any) {
    this.orderObj = order;

    if (order) {
      this.selectedOrderId = order.orderId;
      this.checkIfWhTransactionHasAddedSSCC(order);
    } else this.disableEnableControls(this.modes.none);
  }
  checkIfWhTransactionHasAddedSSCC(order) {
    this.coreSession.ModalLoading.Show();
    this.ssccGenerateService
      .CheckIfTransactionOrOrderHasAddedSSCC("", order.orderId)
      .subscribe(
        (response: ResponseModel) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            if (response.data.ssccCount > 0) {
              this.orderObj.warehouseId = response.data.warehouseId;
              this.selectedWarehouseId = response.data.warehouseId;
            }
            this.disableEnableControls(this.modes.order);
          } else {
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  fillSSCCAllItems(transactionItems) {
    var newItem: ItemPackModel;
    if (transactionItems) {
      transactionItems.forEach((item) => {
        var index = -1;
        if (this.ssccObject.details)
          index = this.ssccObject.details.findIndex(
            (a) =>
              a.packId === item.packId &&
              a.batchNo === item.batchNo &&
              a.expiryDateModel.year === item.expiryDateModel.year &&
              a.expiryDateModel.month === item.expiryDateModel.month &&
              a.expiryDateModel.day === item.expiryDateModel.day &&
              a.salesTransactionTypeId === item.salesTransactionTypeId
          );
        else this.ssccObject.details = [];

        if (index === -1) {
          newItem = cloneDeep(item);
          newItem.requiredQty = 0;
          newItem.selectedBatches = [];
          if (this.selectedMode !== this.modes.order) {
            newItem.salesTransactionTypeId = SalesTransactionTypes.None;
          }
          this.ssccObject.details.push(newItem);
        } else {
          if (this.selectedMode === this.modes.order) {
            this.ssccObject.details[index].price = item.price;
            this.ssccObject.details[index].tax = item.tax;
            this.ssccObject.details[index].discount = item.discount;
            this.ssccObject.details[index].discountTypeId = item.discountTypeId;
            this.ssccObject.details[index].salesTransactionTypeId =
              item.salesTransactionTypeId;
          } else {
            this.ssccObject.details[index].salesTransactionTypeId =
              SalesTransactionTypes.None;
          }

          // this.ssccObject.details[index].itemStockBatches=cloneDeep(this.ssccObject.details[index].selectedBatches);
        }
      });
      if (this.selectedMode !== this.modes.warehouse)
        this.ssccObject.details = this.ssccObject.details.filter(
          (a) => a.originalQuantity > 0
        );
    }
  }
  onSaveClicked() {
    if (!this.validateBeforeSave()) {
      return;
    }
    this.coreSession.ModalLoading.Show();
    this.fillSSCCObject();
    if (this.isEditMode) this.updateSSCCData();
    else this.saveSSCCData();
  }
  fillSSCCObject() {
    this.ssccObject.ssccBarcode = this.ssccBarcodeInput.value;
    switch (this.selectedMode) {
      case this.modes.warehouseTransaction:
        this.ssccObject.transactionId = this.warehouseTransaction.transactionId;
        this.ssccObject.orderId = this.warehouseTransaction.sourceTransactionId;
        this.ssccObject.warehouseId = this.warehouseTransaction.refWarehouseId;
        this.ssccObject.divisionId = this.warehouseTransaction.divisionId;
        break;
      case this.modes.order:
        this.ssccObject.transactionId = "";
        this.ssccObject.orderId = this.orderObj.orderId;
        this.ssccObject.warehouseId = this.orderObj.warehouseId;
        this.ssccObject.divisionId = this.orderObj.divisionId;
        break;
      case this.modes.warehouse:
        this.ssccObject.transactionId = "";
        this.ssccObject.orderId = "";
        this.ssccObject.warehouseId = this.warehouseIdInput.value;
        //this.ssccObject.refWarehouseId=this.warehouseTransaction.refWarehouseId;
        if (this.isDivisionLevel())
          this.ssccObject.divisionId = this.divisionIdInput.value;
        else this.ssccObject.divisionId = -1;
        break;
      case this.modes.none:
        if (this.isFromDelivery || this.isFromPartialDelivery) {
          if (this.orderObj) {
            this.ssccObject.transactionId = "";
            this.ssccObject.orderId = this.orderObj.orderId;
            this.ssccObject.warehouseId = this.orderObj.warehouseId;
            this.ssccObject.divisionId = this.orderObj.divisionId;
          }
        } else if (this.warehouseTransaction) {
          this.ssccObject.transactionId = this.warehouseTransaction.transactionId;
          this.ssccObject.orderId = this.warehouseTransaction.sourceTransactionId;
          this.ssccObject.warehouseId = this.warehouseTransaction.refWarehouseId;
          this.ssccObject.divisionId = this.warehouseTransaction.divisionId;
        }
        break;
    }
  }
  validateBeforeSave() {
    var isValid = true;
    if (this.ssccObject) {
      if (
        !this.ssccObject.details ||
        this.ssccObject.details.length === 0 ||
        this.ssccObject.details.findIndex((pack) => pack.requiredQty > 0) === -1
      ) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(ConstantMessages.MsgSelectItem)
        );
        isValid = false;
      } else if (
        !this.fromMainSSCCList &&
        this.ssccObject.details.findIndex(
          (pack) => pack.requiredQty > pack.originalQuantity
        ) !== -1
      ) {
        this.coreSession.showWarrning(
          this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(
            ConstantMessages.RequierdMoreThanRequested
          )
        );
        isValid = false;
      }
    } else {
      isValid = false;
    }
    return isValid;
  }
  saveSSCCData() {
    this.ssccGenerateService.saveSSCCData(this.ssccObject).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.coreSession.showSuccess(
            this.translateService.instant(ConstantMessages.SuccessCaption),
            response.message
          );
          // this.updateOriginalQuantity();
          this.onSaveComplete.emit(this.ssccObject.ssccBarcode);
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  updateSSCCData() {
    this.ssccGenerateService.updateSSCCData(this.ssccObject).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.coreSession.showSuccess(
            this.translateService.instant(ConstantMessages.SuccessCaption),
            response.message
          );
          //this.updateOriginalQuantity();
          this.onSaveComplete.emit(this.ssccObject.ssccBarcode);
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  // updateOriginalQuantity(){
  //   this.ssccObject.details.filter(pack=>pack.requiredQty>0).forEach(pack => {
  //    let packIndex=this.warehouseTransaction.addedItems.findIndex(whPack=>whPack.packId===pack.packId);
  //    if(packIndex!=-1){
  //     this.warehouseTransaction.addedItems[packIndex].originalQuantity -=pack.requiredQty;
  //    }
  //   });
  //   }
  onGenerateBarcodeClicked() {
    this.isLoading = true;
    this.coreSession.ModalLoading.Show();
    this.ssccGenerateService.generateSSCCBarcode().subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.ssccBarcodeInput.setValue(response.data);
          this.isBarcodeGenerated = true;
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showWarrning(
            this.translateService.instant(ConstantMessages.WarningCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  getWarehouseTransactions() {
    this.coreSession.ModalLoading.Show();
    // this.query.onlyGetLinkedToOrder=true;
    this.vehicleOperationService
      .getWarehouseTransactions(
        WarehouseTransactionStatus.Approved,
        this.query,
        WarehouseTransactionOperation.LoadRequest
      )
      .subscribe(
        (response: ResponseModel) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.transactionDataSource = response.data.data;
            if (this.isEditMode) {
              this.selectedTransactionId = this.ssccObject.transactionId;
              this.transactionIdInput.setValue(this.selectedTransactionId);
              var index = this.transactionDataSource.findIndex(
                (whtran) => whtran.transactionId === this.selectedTransactionId
              );
              if (index !== -1) {
                this.warehouseTransaction = this.transactionDataSource[index];
                this.getWarehouseTransactionData();
              }
            }
          } else {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  getOrders() {
    this.coreSession.ModalLoading.Show();
    this.orderService.getOrders(this.orderQuery).subscribe(
      (response: ResponseModel) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.ordersDataSource = response.data.data;
          if (this.isEditMode) {
            this.selectedOrderId = this.ssccObject.orderId;
            this.orderIdInput.setValue(this.selectedOrderId);
            var index = this.ordersDataSource.findIndex(
              (order) => order.orderId === this.selectedOrderId
            );
            if (index !== -1) {
              this.orderObj = this.ordersDataSource[index];
              this.getOrderData();
            }
          }
        } else {
          this.coreSession.showWarrning(
            this.translateService.instant(ConstantMessages.WarningCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }

  getWarehouseTransactionData() {
    if (!this.warehouseTransaction) {
      this.fillData(this.modes.none);
      return;
    }
    this.coreSession.ModalLoading.Show();
    this.vehicleOperationService
      .getWarehouseTransactionData(this.warehouseTransaction.signature)
      .subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.warehouseTransaction = response.data;

            this.fillData(this.modes.warehouseTransaction);
            this.fillFromPreviousSSCC(this.warehouseTransaction.addedItems);
            // this.fillSSCCAllItems( this.warehouseTransaction.addedItems);
            this.showItems = true;
          } else {
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();

          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  getOrderData() {
    this.coreSession.ModalLoading.Show();
    let query: OrderListFilter = {};
    query.orderId = this.selectedOrderId;
    // this.orderIdInput.setValue(this.selectedOrderId);
    // query.isViewMode = true;
    this.orderService.getOrder(query).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.orderObj = response.data;
          if (this.isEditMode) {
            this.orderObj.warehouseId = this.ssccObject.warehouseId;
          } else {
            this.orderObj.warehouseId = this.selectedWarehouseId;
          }

          this.fillData(this.modes.order);
          this.fillFromPreviousSSCC(this.orderObj.allItems);
          //this.fillSSCCAllItems(this.orderObj.allItems);
          this.showItems = true;
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  disableAllControls() {
    this.disableHeader = true;
    this.transactionIdInput.disable({ onlySelf: true, emitEvent: false });
    this.orderIdInput.disable({ onlySelf: true, emitEvent: false });
    this.warehouseIdInput.disable({ onlySelf: true, emitEvent: false });
    if (this.isDivisionLevel())
      this.divisionIdInput.disable({ onlySelf: true, emitEvent: false });
  }
  disableEnableControls(selectedMode) {
    this.selectedMode = selectedMode;
    switch (selectedMode) {
      case this.modes.none:
        this.orderObj = undefined;
        this.warehouseTransaction = undefined;
        this.selectedDivisionId = -1;
        this.selectedWarehouseId = -1;
        this.transactionIdInput.enable({ onlySelf: true, emitEvent: false });
        this.orderIdInput.enable({ onlySelf: true, emitEvent: false });
        this.warehouseIdInput.enable({ onlySelf: true, emitEvent: false });
        if (this.isDivisionLevel())
          this.divisionIdInput.enable({ onlySelf: true, emitEvent: false });
        break;
      case this.modes.warehouseTransaction:
        this.orderObj = undefined;
        this.selectedDivisionId = -1;
        this.selectedWarehouseId = -1;
        this.transactionIdInput.enable({ onlySelf: true, emitEvent: false });
        this.orderIdInput.disable({ onlySelf: true, emitEvent: false });
        this.warehouseIdInput.disable({ onlySelf: true, emitEvent: false });
        if (this.isDivisionLevel())
          this.divisionIdInput.disable({ onlySelf: true, emitEvent: false });
        break;
      case this.modes.order:
        this.warehouseTransaction = undefined;
        //this.selectedDivisionId=-1;
        //this.selectedWarehouseId=-1;
        this.transactionIdInput.disable({ onlySelf: true, emitEvent: false });
        this.orderIdInput.enable({ onlySelf: true, emitEvent: false });
        if (this.orderObj.warehouseId && this.orderObj.warehouseId > 0) {
          this.warehouseIdInput.disable({ onlySelf: true, emitEvent: false });
          this.warehouseIdInput.setValue(this.orderObj.warehouseId);
        } else {
          this.warehouseIdInput.enable({ onlySelf: true, emitEvent: false });
        }
        this.warehouseIdInput.setValidators([Validators.required]);
        this.warehouseIdInput.updateValueAndValidity();
        if (this.isDivisionLevel()) {
          this.divisionIdInput.enable({ onlySelf: true, emitEvent: false });
          this.divisionIdInput.setValue(this.orderObj.divisionId);
        }

        break;
      case this.modes.warehouse:
        this.orderObj = undefined;
        this.warehouseTransaction = undefined;
        this.transactionIdInput.disable({ onlySelf: true, emitEvent: false });
        this.orderIdInput.disable({ onlySelf: true, emitEvent: false });
        this.warehouseIdInput.enable({ onlySelf: true, emitEvent: false });
        if (this.isDivisionLevel())
          this.divisionIdInput.enable({ onlySelf: true, emitEvent: false });
        break;
    }
  }
  fillFromPreviousSSCC(addedItems) {
    this.isLoading = true;
    this.coreSession.ModalLoading.Show();
    var orderId = "",
      transactionId = "";
    if (this.selectedMode === this.modes.order) {
      orderId = this.orderObj.orderId;
    } else if (this.selectedMode === this.modes.warehouseTransaction) {
      transactionId = this.warehouseTransaction.transactionId;
    } else {
      return;
    }

    this.ssccGenerateService
      .getSSCCDetailsTotals(orderId, transactionId)
      .subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.ssccTotals = response.data;
            this.setOriginalValues(addedItems);
            this.fillSSCCAllItems(addedItems);
            this.isLoading = false;
          } else {
            this.coreSession.showWarrning(
              this.translateService.instant(ConstantMessages.WarningCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  setOriginalValues(addedItems) {
    if (addedItems) {
      addedItems.forEach((pack) => {
        if (this.ssccTotals && this.ssccTotals.length > 0) {
          let packIndex = this.ssccTotals.findIndex(
            (whPack) =>
              whPack.packId === pack.packId &&
              whPack.salesTransactionTypeId === pack.salesTransactionTypeId
          );
          if (packIndex != -1) {
            pack.originalQuantity =
              pack.requiredQty - this.ssccTotals[packIndex].requiredQty;
          } else {
            pack.originalQuantity = pack.requiredQty;
          }
        } else {
          pack.originalQuantity = pack.requiredQty;
        }
      });
    }
  }
  get ssccBarcodeInput() {
    return this.ssccDetailsForm.get("ssccBarcode");
  }
  get transactionIdInput() {
    return this.ssccDetailsForm.get("transactionId");
  }
  get orderIdInput() {
    return this.ssccDetailsForm.get("orderId");
  }
  get warehouseIdInput() {
    return this.ssccDetailsForm.get("warehouseId");
  }
  get divisionIdInput() {
    return this.ssccDetailsForm.get("divisionId");
  }
}
