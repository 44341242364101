import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild
} from "@angular/core";
import { PromotionTypes } from "../../../models/enums/promotion-types.enum";
import { CoreSession } from "../../../../core/core.session";
import { TranslateService } from "@ngx-translate/core";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { PackGroupItemsComponent } from "../pack-group-items/pack-group-items.component";
import * as cloneDeep from "lodash/cloneDeep";
import { SerialsPropertiesInterface } from '../../Serials/serials-properties.interface';
import { Observable, Subject } from 'rxjs';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { PromotionOutputDetailTypes } from "../../../models/enums/promotion-output-detail-types.enum";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";
import { CalculationService } from '../../../services/calculations.service';
import { PromotionInputDetailTypes } from "../../../models/enums/promotion-input-detail-types.enum";
import { SalesTransactionTypes } from "../../../models/enums/sales-transaction-types";

@Component({
  selector: "app-take-promotion",
  templateUrl: "./take-promotion.component.html",
  styleUrls: ["./take-promotion.component.css"]
})
export class TakePromotionComponent implements OnInit, OnChanges, OnDestroy {
  @Output()
  takePromotion: EventEmitter<any> = new EventEmitter();

  @Output()
  cancelPromotion = new EventEmitter();

  @Input() promotions: any[] = []; // Get Current Level Promotion
  @Input() promotionsLevels: any[] = []; // Get All Promotion Levels
  @Input() transaction: any = null;
  @Input() formProcessMode = FormProcessMode.PartialDelivery;
  @ViewChild("selectPackGroupItems", { static: true })
  selectPackGroupItems: PackGroupItemsComponent;
  includeTax = false;
  numberOfStockDigitsFormat: number = 0;
  promoWithItemSelection: any[] = [];  // Get Current Item Level Promotion
  currentLevelPromoWithItemSelection: any[] = []; // Get All Item Promotion Levels
  isPercentageChecked: boolean = true;
  isAmountChecked: boolean = false;
  totalAdded = 0;
  totalAchieved = 0;
  isOringvalid = false;
  lastInputvalue = 0;
  lastOutputRepeatCount = 0;
  oringValidationMsg = '';
  currentPromoInSelection: any = {};
  required = 0;
  pageIndex = 0;
  pageNumber = 1;
  lastPromotion: boolean = false; // To check If there is a next level or last level
  currentLevelId = 1;
  nextLevelId = 1;
  @Input() nextEvent: Observable<void>;
  nextSubscription: any;
  promotionCalculationSequence = 0;
  isfirstTimeOpen = true;
  soldItems: any[] = [];

  constructor(
    public coreSession: CoreSession,
    private translateService: TranslateService,
    private sessionData: SessionDataProvider,
    private calculationService: CalculationService
  ) { }

  ngOnInit() { 
    this.subscribeNextClick();
    if (this.transaction.soldItems != null && this.transaction.soldItems.length > 0) {
      this.soldItems = this.transaction.soldItems.filter(x => x.salesTransactionTypeId != SalesTransactionTypes.Promotion.valueOf() && x.salesTransactionTypeId != SalesTransactionTypes.FOC.valueOf())
    }
    this.numberOfStockDigitsFormat = +this.sessionData.getConfigurationValue(ConstantConfigurations.NumberOfStockDigits);
    this.promotionCalculationSequence = +this.sessionData.getConfigurationValue(ConstantConfigurations.PromotionCalculationSequence);
    this.promotions.forEach(promotion => {
      if (promotion.promotionType == PromotionTypes.StepsValues && promotion.allowOring) {
        // user should choose repeat count .
        promotion.promotionOutputs.forEach(output => {
          output.promotionOptionDetails[0].repeatCount = 0;
          output.relatedToTargetOption.originalRepeatCount = output.relatedToTargetOption.repeatCount;
        });
        promotion.totalAdded = 0;
        promotion.totalAchieved = 0;
        promotion.lastInputvalue = 0;
        promotion.lastOutputRepeatCount = 0;
      }
    });
    if (this.isfirstTimeOpen) {
      this.isfirstTimeOpen = false;
    }
  }

  ngOnChanges() {
    this.promotions.forEach(promotion => {
      this.preparePromotionToView(promotion);
    });
  }

  ngOnDestroy() {
    this.nextSubscription.unsubscribe();
  }
  subscribeNextClick() {
    this.nextSubscription = this.nextEvent.subscribe(() => {
      this.onNextClicked();
    });
  }
  preparePromotionToView(promotion: any) {
    var isAnyOptionSelected = false;
    if (promotion.promotionOutputs && promotion.promotionOutputs.lenght > 0) {
      if (promotion.promotionOutputs.findIndex(x => x.isSelected) >= 0) {
        isAnyOptionSelected = true;
      };
    }

    switch (promotion.promotionType) {
      case PromotionTypes.FixedValues:
        if (promotion.promotionOutputs.length > 1) {
          promotion.showSelection = true;
        }
        if (this.isfirstTimeOpen || !isAnyOptionSelected) {
          promotion.promotionOutputs[0].isSelected = true;
        }
        break;
      case PromotionTypes.RangeValues:
        promotion.promotionOutputs.forEach(output => {
          output.isSelected = true;
        });
        break;
      case PromotionTypes.StepsValues:
        promotion.showStepsRepeat = true;
        promotion.promotionOutputs.forEach(output => {
          output.isSelected = true;
        });
        break;
      case PromotionTypes.TargetPromotion:
        if (this.isfirstTimeOpen || !isAnyOptionSelected) {
          promotion.promotionOutputs[0].isSelected = true;
        }
        break;
      case PromotionTypes.DynamicTargetPromotion:
        if (this.isfirstTimeOpen || !isAnyOptionSelected) {
          promotion.promotionOutputs[0].isSelected = true;
        }
        break;

    }

    if (promotion.isRepeated) {
      promotion.showRepeatCount = true;
    }

    if (promotion.promotionType === PromotionTypes.StepsValues) {
      promotion.andORGet = this.translateService.instant("Desc_And_Get");
    } else {
      promotion.andORGet = this.translateService.instant("Desc_Or_Get");
    }
  }

  onOptionSelected(promoOutput: any, promotion: any) {
    if (promoOutput.isSelected) {
      return false;
    }
    promotion.promotionOutputs.forEach(output => {
      if (output.promotionOptionId !== promoOutput.promotionOptionId) {
        output.isSelected = false;
      }
    });
    promoOutput.isSelected = !promoOutput.isSelected;
  }
  onTypeSelected(type: any, promotion: any) {

    // if (type == 'Percentage') {
    promotion.isPercentageChecked = !promotion.isPercentageChecked;
    // promotion.isAmountChecked = !promotion.isAmountChecked;


    if (promotion.isPercentageChecked) {
      promotion.promotionOutputs.forEach(output => {
        output.takeMode = 0;
      }); // takePercentage =0 , takeAmount =1
    }
    else {
      promotion.promotionOutputs.forEach(output => {
        output.takeMode = 1;
      });
    }
  }
  getNumberOnStockDigitFormat(numToRound: number) {
    return parseFloat(this.calculationService.formatNumeric(numToRound, this.numberOfStockDigitsFormat));
  }
  validatePromotions(): boolean {
    // Check Selection in Fixed Promotion and ranges
    let isValid = true;
    this.promotions.forEach(promotion => {
      if (promotion.promotionType !== PromotionTypes.StepsValues) {
        if (
          promotion.promotionOutputs.filter(a => a.isSelected === true)
            .length === 0
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.SetPromotionOption)
          );
          isValid = false;
        }
      }
      else if (promotion.allowOring) {
        if (this.isOringvalid) {
          if (!promotion.totalAdded || promotion.totalAdded == 0) {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              this.translateService.instant(ConstantMessages.CompleteTheBenefit) + ' ' + this.translateService.instant(ConstantMessages.Promotion) + ' (' + promotion.promotionName + ')'
            );
            isValid = false;
          }
          else if (promotion.totalAdded > promotion.totalAchieved) {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              this.translateService.instant(ConstantMessages.AddedMoreThanSatisfication) + ' ' + this.translateService.instant(ConstantMessages.Promotion) + ' (' + promotion.promotionName + ')'
            );
            isValid = false;
          }
          else {
            if ((promotion.totalAchieved - promotion.totalAdded) >= promotion.lastInputvalue) {
              if (promotion.maxLimit > 0) {

                promotion.promotionOutputs.forEach(output => {
                  if (output.promotionOptionDetails[0].repeatCount < (promotion.maxLimit - output.relatedToTargetOption.originalRepeatCount) &&
                    ((promotion.totalAchieved - promotion.totalAdded) >= output.relatedToTargetOption.optionTargetValue)) {
                    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.CompleteTheBenefit) + ' ' + this.translateService.instant(ConstantMessages.Promotion) + ' (' + promotion.promotionName + ')');
                    isValid = false;
                  }
                });
              }
              else {
                this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.CompleteTheBenefit) + ' ' + this.translateService.instant(ConstantMessages.Promotion) + ' (' + promotion.promotionName + ')');
                isValid = false;
              }
            }
          }
          if (isValid) {
            promotion.promotionOutputs.forEach(output => {
              if (output.promotionOptionDetails[0].repeatCount == 0) {
                output.isSelected = false;
              } else {
                output.relatedToTargetOption.repeatCount = output.promotionOptionDetails[0].repeatCount;
              }
            });
          }
        }
        else {
          isValid = false;
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.AddedMoreThanSatisfication) + ' ' + this.translateService.instant(ConstantMessages.Promotion) + ' (' + promotion.promotionName + ')'
          );
        }
      }
    });
    return isValid;
  }

  onTakePromotionClicked() {
    if (this.promotionCalculationSequence != 3) {
      this.promoWithItemSelection = [];
    }
    this.currentLevelPromoWithItemSelection = [];
    if (this.validatePromotions() === true) {
      this.fillPromotionNeedItemSelection();
      this.currentLevelId = this.promotionsLevels[this.pageIndex] != null ? this.promotionsLevels[this.pageIndex].levelId : 1;
      this.currentLevelPromoWithItemSelection = this.promoWithItemSelection.filter(x => x.levelId == this.currentLevelId);
      if (this.currentLevelPromoWithItemSelection && this.currentLevelPromoWithItemSelection.length > 0) {
        this.openSelection();
      } else {
        if (this.pageIndex < (this.promotionsLevels.length - 1)) {
          this.lastPromotion = false;
          this.nextLevelId = this.promotionsLevels[this.pageIndex + 1] != null ? this.promotionsLevels[this.pageIndex + 1].levelId : 1;
        }
        else if (this.transaction.onlyProductDiscount && this.transaction.isThereAnyProductDiscountDefined) {
          this.lastPromotion = false;
          this.promoWithItemSelection = [];
        }
        else {
          this.lastPromotion = true;
          this.nextLevelId = 1
        }

        let promotions = this.promotions;
        let lastPromotion = this.lastPromotion;
        let currentLevelId = this.currentLevelId;
        let nextLevelId = this.nextLevelId;
        this.takePromotion.emit({ promotions, lastPromotion, currentLevelId, nextLevelId });
      }
    }
  }

  onCancelPromotionClicked() {
    this.cancelPromotion.emit();
  }

  addPromoWithItemSelection(data) {
    let index = this.promoWithItemSelection.findIndex(
      i =>
        i.promotionId === data.promotionId &&
        i.promotionOptionDetailId === data.promotionOptionDetailId &&
        i.outputOptionDetailTypeId === data.outputOptionDetailTypeId
    );
    if (index < 0) {
      this.promoWithItemSelection.push(data);
    }
  }

  removePromoWithItemSelection(data, closeDialog) {
    let index = this.promoWithItemSelection.findIndex(
      i =>
        i.promotionId === data.promotionId &&
        i.promotionOptionDetailId === data.promotionOptionDetailId &&
        i.outputOptionDetailTypeId === data.outputOptionDetailTypeId
    );
    if (index >= 0) {
      this.promoWithItemSelection.splice(index, 1);
    }
    if (closeDialog)
      this.selectPackGroupItems.Close();
  }
  fillPromotionNeedItemSelection() {
    this.required = 0;
    if (this.transaction.outlet.taxable && this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTax).toLowerCase() === "true"
      && this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTaxOverFreeItems).toLowerCase() === "true") {
      this.includeTax = true;
    }
    for (var promo of this.promotions) {
      let selectedPromotions = promo.promotionOutputs.filter(
        a => a.isSelected === true
      );
      for (var output of selectedPromotions) {
        for (var detail of output.promotionOptionDetails) {
          if (detail.outputOptionDetailTypeId == PromotionOutputDetailTypes.SummationOverGroup) {
            let benefitValue = detail.value;
            let sumOfOptionsValues = 0;
            if (promo.relatedToTarget) {

              if (promo.allowOring) {
                benefitValue = output.relatedToTargetOption.benefit * output.promotionOptionDetails[0].repeatCount;
                sumOfOptionsValues = output.relatedToTargetOption.benefit * output.promotionOptionDetails[0].repeatCount;
              }
              else {
                benefitValue = output.relatedToTargetOption.benefit;
                sumOfOptionsValues = output.relatedToTargetOption.benefit;
              }
            }
            // This case for steps promotion in pack group selection
            let data = {
              promotionId: promo.promotionId,
              promotionType: promo.promotionType,
              promotonRepeatCount: promo.newRepeatCount,
              isRepeated: promo.isRepeated,
              promotionOptionId: output.promotionOptionId,
              promotionOptionDetailId: detail.promotionOptionDetailId,
              value: benefitValue,
              required: benefitValue,
              packGroupId: detail.packGroupId,
              sumOfOptionsValues: sumOfOptionsValues,
              outputOptionDetailTypeId: detail.outputOptionDetailTypeId,
              packGroupQuantity: detail.packGroupQuantity,
              defindPackGroupQuantities: detail.defindPackGroupQuantities,
              customerId: -1,
              outletId: -1,
              levelId: promo.sequence
            };

            if (promo.promotionType === PromotionTypes.StepsValues && !promo.relatedToTarget) {
              data.sumOfOptionsValues = detail.value * detail.repeatCount;

              let index = this.promoWithItemSelection.findIndex(
                i =>
                  i.promotionId === promo.promotionId &&
                  i.packGroupId === detail.packGroupId
              );

              if (index === -1) {
                this.addPromoWithItemSelection(data);
              } else {
                this.promoWithItemSelection[index].sumOfOptionsValues +=
                  data.sumOfOptionsValues;
                output.isSelected = false;
              }
            } else {
              this.addPromoWithItemSelection(data);
            }
          } else if (detail.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsOnInvoicePercentage
            || detail.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsWithCertainValue) {
            // This case for steps promotion in pack group selection
            if (this.sessionData.getConfigurationValue(ConstantConfigurations.InvoiceValueConsideredInPromotionBenefits).toLowerCase() == '0'
              && detail.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsOnInvoicePercentage) {
              this.required = ((this.transaction.netTotal - this.transaction.tax) * detail.value) / 100;
            } else if (this.sessionData.getConfigurationValue(ConstantConfigurations.InvoiceValueConsideredInPromotionBenefits).toLowerCase() == '1'
              && detail.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsOnInvoicePercentage) {
              this.required = (this.transaction.grossTotal * detail.value) / 100;
            } else if (detail.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsWithCertainValue) {
              this.required = detail.value;
            }

            let data = {
              promotionId: promo.promotionId,
              promotionType: promo.promotionType,
              promotonRepeatCount: promo.newRepeatCount,
              isRepeated: promo.isRepeated,
              promotionOptionId: output.promotionOptionId,
              promotionOptionDetailId: detail.promotionOptionDetailId,
              value: detail.value,
              required: this.required,
              packGroupId: detail.packGroupId,
              sumOfOptionsValues: 0,
              outputOptionDetailTypeId: detail.outputOptionDetailTypeId,
              packGroupQuantity: detail.packGroupQuantity,
              defindPackGroupQuantities: detail.defindPackGroupQuantities,
              customerId: this.transaction.customerId ? this.transaction.customerId : -1,
              outletId: this.transaction.outletId ? this.transaction.outletId : -1,
              includeTax: this.includeTax,
              levelId: promo.sequence
            };

            if (promo.promotionType === PromotionTypes.StepsValues) {
              data.sumOfOptionsValues = detail.value * detail.repeatCount;

              let index = this.promoWithItemSelection.findIndex(
                i =>
                  i.promotionId === promo.promotionId &&
                  i.packGroupId === detail.packGroupId
              );

              if (index === -1) {
                this.addPromoWithItemSelection(data);
              } else {
                this.promoWithItemSelection[index].sumOfOptionsValues +=
                  data.sumOfOptionsValues;
                output.isSelected = false;
              }
            } else {
              this.addPromoWithItemSelection(data);
            }
          } else if (detail.outputOptionDetailTypeId == 10) {

          }
          // the following type is for related to target promotions , so we should read the detail value from benefit value which coming from the procedure calculations .
          else if (detail.outputOptionDetailTypeId == PromotionOutputDetailTypes.DiscountPercentageOrQtyOverGroupWithSameAmount) {
            let BenefitPackGroupTotal = 0;
            if (this.sessionData.getConfigurationValue(ConstantConfigurations.InvoiceValueConsideredInPromotionBenefits).toLowerCase() == '1') {
              if (this.sessionData.getConfigurationValue(ConstantConfigurations.AllowConversionInPromotion).toLowerCase() === "true") {
                this.soldItems.forEach(element => {
                  if (detail.packGroupDetails.filter(x => x.itemId == element.itemId).length > 0) {
                    BenefitPackGroupTotal += element.grossTotal;
                  }
                });
              } else {
                this.soldItems.forEach(element => {
                  if (detail.packGroupDetails.filter(x => x.packId == element.packId).length > 0) {
                    BenefitPackGroupTotal += element.grossTotal;
                  }
                });
              }
            } else {
              if (this.sessionData.getConfigurationValue(ConstantConfigurations.AllowConversionInPromotion).toLowerCase() === "true") {
                this.soldItems.forEach(element => {
                  if (detail.packGroupDetails.filter(x => x.itemId == element.itemId)) {
                    BenefitPackGroupTotal += element.netTotal - element.tax;
                  }
                });
              } else {
                this.soldItems.forEach(element => {
                  if (detail.packGroupDetails.filter(x => x.packId == element.packId)) {
                    BenefitPackGroupTotal += element.netTotal - element.tax;
                  }
                });
              }
            }
            let benefitValue = promo.allowOring ? (output.relatedToTargetOption.benefit * output.promotionOptionDetails[0].repeatCount) : output.relatedToTargetOption.benefit;
            // if (this.sessionData.getConfigurationValue(ConstantConfigurations.InvoiceValueConsideredInPromotionBenefits).toLowerCase() == '0') {
            this.required = (BenefitPackGroupTotal * benefitValue) / 100;
            // }
            // else if (this.sessionData.getConfigurationValue(ConstantConfigurations.InvoiceValueConsideredInPromotionBenefits).toLowerCase() == '1') {
            //   this.required = (this.transaction.grossTotal * benefitValue) / 100;// should be gross for benefit pack group
            // }
            let data = {
              promotionId: promo.promotionId,
              promotionType: promo.promotionType,
              promotonRepeatCount: promo.newRepeatCount,
              isRepeated: promo.isRepeated,
              promotionOptionId: output.promotionOptionId,
              promotionOptionDetailId: detail.promotionOptionDetailId,
              value: benefitValue,
              required: this.required,
              packGroupId: detail.packGroupId,
              sumOfOptionsValues: this.required,
              outputOptionDetailTypeId: detail.outputOptionDetailTypeId,
              packGroupQuantity: detail.packGroupQuantity,
              defindPackGroupQuantities: detail.defindPackGroupQuantities,
              customerId: this.transaction.customerId ? this.transaction.customerId : -1,
              outletId: this.transaction.outletId ? this.transaction.outletId : -1,
              includeTax: this.includeTax,
              levelId: promo.sequence
            };
            if (output.takeMode == 1) {
              this.addPromoWithItemSelection(data);
            }
            else {
              this.removePromoWithItemSelection(data, false);
            }
          }
          if (detail.outputOptionDetailTypeId == PromotionOutputDetailTypes.OpenSteps) {
            let data = {
              promotionId: promo.promotionId,
              promotionType: promo.promotionType,
              promotonRepeatCount: promo.newRepeatCount,
              isRepeated: promo.isRepeated,
              promotionOptionId: output.promotionOptionId,
              promotionOptionDetailId: detail.promotionOptionDetailId,
              value: detail.value,
              // required:this.getNumberOnStockDigitFormat((promo.totalQuantityfromPackGroup / promo.targetBenefitQuantity) * detail.value),
              required: parseFloat(this.calculationService.formatNumeric(((promo.totalQuantityfromPackGroup / promo.targetBenefitQuantity) * detail.value), 3)),
              packGroupId: detail.packGroupId,
              sumOfOptionsValues: 0,
              outputOptionDetailTypeId: detail.outputOptionDetailTypeId,
              packGroupQuantity: detail.packGroupQuantity,
              defindPackGroupQuantities: detail.defindPackGroupQuantities,
              customerId: -1,
              outletId: -1,
              levelId: promo.sequence
            };
            this.addPromoWithItemSelection(data);
          }
          if (detail.outputOptionDetailTypeId == PromotionOutputDetailTypes.DynamicTargetSummationOverGroup) {
            let data = {
              promotionId: promo.promotionId,
              promotionType: promo.promotionType,
              promotonRepeatCount: promo.newRepeatCount,
              isRepeated: promo.isRepeated,
              promotionOptionId: output.promotionOptionId,
              promotionOptionDetailId: detail.promotionOptionDetailId,
              value: detail.value,
              // required:this.getNumberOnStockDigitFormat((promo.totalQuantityfromPackGroup / promo.targetBenefitQuantity) * detail.value),
              required: (parseFloat(this.calculationService.formatNumeric(((promo.totalQuantityfromPackGroup / promo.targetBenefitQuantity) * detail.value), 3)) - promo.benefitValue),
              packGroupId: detail.packGroupId,
              sumOfOptionsValues: 0,
              outputOptionDetailTypeId: detail.outputOptionDetailTypeId,
              packGroupQuantity: detail.packGroupQuantity,
              defindPackGroupQuantities: detail.defindPackGroupQuantities,
              customerId: -1,
              outletId: -1,
              levelId: promo.sequence
            };
            let originalBenefitValue = this.getNumberOnStockDigitFormat(((promo.totalQuantityfromPackGroup / promo.targetBenefitQuantity) * detail.value));
            //if (detail.packGroupQuantity == 1) {
            //  originalBenefitValue = parseFloat(this.calculationService.formatNumeric(originalBenefitValue, 0));
            //}
            this.addPromoWithItemSelection(data);
          }
        }
      }
    }
    if (this.promoWithItemSelection != null && this.promoWithItemSelection.length > 0)
      this.promoWithItemSelection = this.promoWithItemSelection.sort((a, b) => (a.promotionId > b.promotionId) ? 1 : -1);
    (this.promoWithItemSelection);
  }

  openSelection() {
    if (this.currentLevelPromoWithItemSelection && this.currentLevelPromoWithItemSelection.length > 0) {
      this.currentPromoInSelection = cloneDeep(this.currentLevelPromoWithItemSelection[0]);
      if (this.transaction.isThereAnyProductDiscountDefined && this.currentPromoInSelection.outputOptionDetailTypeId == PromotionOutputDetailTypes.DiscountPercentageOrQtyOverGroupWithSameAmount)
        this.currentPromoInSelection.includeTax = true;
      if (this.currentPromoInSelection.outputOptionDetailTypeId == PromotionOutputDetailTypes.SummationOverGroup
        || this.currentPromoInSelection.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsOnInvoicePercentage
        || this.currentPromoInSelection.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsWithCertainValue
        || this.currentPromoInSelection.outputOptionDetailTypeId == PromotionOutputDetailTypes.OpenSteps
        || this.currentPromoInSelection.outputOptionDetailTypeId == PromotionOutputDetailTypes.DynamicTargetSummationOverGroup
        || (this.currentPromoInSelection.outputOptionDetailTypeId == PromotionOutputDetailTypes.DiscountPercentageOrQtyOverGroupWithSameAmount)) {
        this.openSelectPackGroupItems();
      }
    }
  }

  openSelectPackGroupItems() {
    this.selectPackGroupItems.Show().then(result => {
      if (result !== 0) {
      }
    });
  }

  onItemsSelected(selectedItems) {
    this.promotions
      .filter(
        promotion =>
          promotion.promotionId ===
          this.currentPromoInSelection.promotionId
      )[0]
      .promotionOutputs.filter(
        output =>
          output.promotionOptionId ===
          this.currentPromoInSelection.promotionOptionId
      )[0]
      .promotionOptionDetails.filter(
        detail =>
          detail.promotionOptionDetailId ===
          this.currentPromoInSelection.promotionOptionDetailId
      )[0].selectedPromotedItems = selectedItems;


    this.currentLevelPromoWithItemSelection.shift();
    if (this.currentLevelPromoWithItemSelection.length > 0) {
      this.openSelection();
    } else {
      let showItemSelection = false;
      if (this.pageIndex < (this.promotionsLevels.length - 1)) {
        this.lastPromotion = false;
        this.nextLevelId = ((this.promotionsLevels.filter((x, index) => index == this.pageIndex + 1)) != null && (this.promotionsLevels.filter((x, index) => index == this.pageIndex + 1))[0] != null) ? (this.promotionsLevels.filter((x, index) => index == this.pageIndex + 1))[0].levelId : 1;
      }
      else if (this.transaction.onlyProductDiscount && this.transaction.isThereAnyProductDiscountDefined) {
        this.lastPromotion = false;
        this.promoWithItemSelection = [];
      }
      else {
        this.lastPromotion = true;
        this.nextLevelId = 1;
      }
      this.currentLevelId = ((this.promotionsLevels.filter((x, index) => index == this.pageIndex)) != null && (this.promotionsLevels.filter((x, index) => index == this.pageIndex))[0] != null) ? (this.promotionsLevels.filter((x, index) => index == this.pageIndex))[0].levelId : 1;
      let promotions = this.promotions;
      let lastPromotion = this.lastPromotion;
      let currentLevelId = this.currentLevelId;
      let nextLevelId = this.nextLevelId;
      this.takePromotion.emit({ promotions, lastPromotion, showItemSelection, currentLevelId, nextLevelId });
      this.selectPackGroupItems.Close();
    }
  }
  onNextClicked() {
    this.pageIndex = this.pageIndex + 1;
    this.pageNumber = this.pageNumber + 1;
  }

  onItemsRemoved(selectedItems) {
    this.removePromoWithItemSelection(selectedItems, true);
  }
  onChangeRepeatCount(repeatCount, promotion, promotionOutput: any) {
    this.isOringvalid = true;
    var lastInput: any = -1;
    var lastOutput: any = -1;
    var maxValue = parseInt(((promotionOutput.relatedToTargetOption.remainingValue) / promotionOutput.relatedToTargetOption.optionTargetValue).toString());
    if (promotion.maxLimit > 0 && maxValue > (promotion.maxLimit - promotionOutput.relatedToTargetOption.originalRepeatCount)) {
      maxValue = promotion.maxLimit - promotionOutput.relatedToTargetOption.originalRepeatCount;
      promotion.totalAchieved = promotionOutput.relatedToTargetOption.remainingValue;
    }
    else {
      promotion.totalAchieved = promotionOutput.relatedToTargetOption.remainingValue;
    }
    lastInput = promotion.promotionInputs[promotion.promotionInputs.length - 1];// last input
    promotion.lastInputvalue = lastInput.promotionOptionDetails[0].value;

    lastOutput = promotion.promotionOutputs[promotion.promotionOutputs.length - 1];// last output
    promotion.lastOutputRepeatCount = lastOutput.relatedToTargetOption.originalRepeatCount;

    promotion.totalAdded = 0;

    if ((promotion.maxLimit > 0 && repeatCount.target.value > maxValue) || ((promotionOutput.relatedToTargetOption.optionTargetValue * repeatCount.target.value) > (promotionOutput.relatedToTargetOption.remainingValue))) {
      this.isOringvalid = false;
      this.coreSession.showError(
        this.translateService.instant(ConstantMessages.ErrorCaption),
        this.translateService.instant(ConstantMessages.YouCanAdd) + ' ' + maxValue + ' ' + this.translateService.instant(ConstantMessages.FromThisOption)
      );
    } else {
      promotionOutput.promotionOptionDetails[0].repeatCount = (repeatCount.target.value && repeatCount.target.value.toString() != "") ? parseInt(repeatCount.target.value.toString()) : 0;
    }
    promotion.promotionOutputs.forEach(Output => {
      promotion.totalAdded += Output.promotionOptionDetails[0].repeatCount * Output.relatedToTargetOption.optionTargetValue;
      if (lastOutput.promotionOptionDetails[0].promotionOptionDetailId == Output.promotionOptionDetails[0].promotionOptionDetailId
        && lastOutput.promotionOptionDetails[0].promotionOptionDetailTypeId == Output.promotionOptionDetails[0].promotionOptionDetailTypeId
        && lastOutput.promotionOptionDetails[0].referenceOptionId == Output.promotionOptionDetails[0].referenceOptionId) {
        promotion.lastOutputRepeatCount += Output.promotionOptionDetails[0].repeatCount;
      }
    });
  }
}
