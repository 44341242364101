import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { ResponseModel } from "../../shared/models/api-models/api-models";
import { OperatorForLogin } from "../../shared/models/operator/operator.model";
import { ConstantStorage } from "../../shared/models/constants/constant-stroage";
import { CoreSession } from "../../core/core.session";

@Injectable()
export class PrivacyPolicyService {
  private url = "";
  private httpOptions;
  languageId = 1;
  deviceInfo: string;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "Privacy/";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };
  }
  setPrivacyAsRead() {
    const urlValue: string = this.url + "SetPrivacyAsRead";
    return this.http.post(urlValue, null, this.httpOptions);
  }
  checkPrivacyPolicy(empId) {
    const urlValue: string = this.url + "CheckPrivacyPolicy";
    return this.http.post(urlValue, empId);
  }
}
