import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { PaymentTermModel } from '../../../../models/customer/payment-term.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { CustomerService } from '../../../../services/data-definition/customer/customers.service';
import { SharedDialogComponent } from '../../../shared-dialog/shared-dialog.component';

@Component({
  selector: 'app-payment-term-filter',
  templateUrl: './payment-term-filter.component.html',
  styleUrls: ['./payment-term-filter.component.css']
})
export class PaymentTermFilterComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("templateEntry", { static: true }) templateEntry: SharedDialogComponent;
  isClassLoading = false;
  lastAddedPaymentTermId:number;
  paymentTermDataSource: PaymentTermModel[] = [];
  refreshPaymentTermSubject: Subscription;
  @Input() parentForm: FormGroup;
  @Input() disableControl=false;
  saveSubject: Subject<void> = new Subject<void>();
  addPaymentTerm=false;
  isRequired = false;
  @Output() onPaymentTermSelected=new EventEmitter();
  @Output() afterAddingNewPaymentTermEvenet = new EventEmitter();
  @Input() showAddNew = true;
  @Input() events: Observable<void>;
  constructor(private customerService:CustomerService) { }
  ngOnChanges() {
    //   if(this.disableControl)
    //   this.parentForm.get('paymentTermId').disable();
    //  else
    //  this.parentForm.get('paymentTermId').enable();
  }
  ngOnInit() {

    if(this.parentForm.get('paymentTermId').validator != null){
      const validator = this.parentForm.get('paymentTermId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateClassesList();
    this.lastAddedPaymentTermId=this.parentForm.value.paymentTermId == null ? -1 : this.parentForm.value.paymentTermId;
    if (this.events) {
      this.refreshPaymentTermSubject = this.events.subscribe(() => {
        this.populateClassesList();
      });
    }

    //   if(this.disableControl)
    //   this.parentForm.get('paymentTermId').disable();
    //  else
    //  this.parentForm.get('paymentTermId').enable();

  }
  ngOnDestroy() {
    if(this.refreshPaymentTermSubject){
      this.refreshPaymentTermSubject.unsubscribe();
    }
  }

  populateClassesList() {
    this.isClassLoading = true;
    this.customerService.getPaymentTerms().subscribe(result => {
      this.paymentTermDataSource = result.data;
      this.isClassLoading = false;
    });
  }
  onPaymentTermDataChanged(paymentTerm){
    this.onPaymentTermSelected.emit(paymentTerm);
  }
  onAddingNewPaymentTerm(paymentTermId:number){

    this.populateClassesList();
    this.lastAddedPaymentTermId=paymentTermId;
    this.parentForm.get('paymentTermId').setValue(paymentTermId);
    this.addPaymentTerm = false;
    this.afterAddingNewPaymentTerm();
    this.onCloseClicked();
  }

  afterAddingNewPaymentTerm() {
    this.afterAddingNewPaymentTermEvenet.emit();
  }

  onSavePaymentTermClicked() {
    this.onSaveClicked();
  }
  onSaveClicked() {
    this.saveSubject.next();
  }
  onCloseClicked() {
    this.templateEntry.Close();
  }
  showPaymentTermModal() {
    this.templateEntry.Show(true).then(
      (res) => {
      }
    );
  }

  onDialogResult(event) {
    if (event && event.saveCLicked) {
      this.onSavePaymentTermClicked();
    }
  }

}
