import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../../shared/models/api-models/api-models';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private url = "";
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.url = baseUrl + "Currency";
  }

  getAllCurrencies(organizationId: number) {
    const urlValue: string = this.url + '/GetAllCurrencies';
    return this.http.post<ResponseModel>(urlValue, organizationId).map(response => {
      return <any>response.data;
    });
  }

  getBaseCurrency() {
    const urlValue = this.url + "/GetBaseCurrency";
    return this.http.post<ResponseModel>(urlValue, "")
      .map(response => {
        return response;
      });
  }
}
