import { Component, EventEmitter, Pipe, Directive, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { DisplayUnitService } from '../../../../../content/master-data-definition/display-unit/display-unit.service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-display-unit-group-filter',
  templateUrl: './display-unit-group-filter.component.html',
  styleUrls: ['./display-unit-group-filter.component.css']
})
export class DisplayUnitGroupFilterComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() addNewGroup = false;
  @Input() showSubFilter = true;
  @Input() horizontal = true;
  @Input() showSubAlways = false;
  @Input() showAddNew: boolean;
  @Output() selectedDisplayMasterGroup = new EventEmitter();
  @Output() selectedDisplaySubGroup = new EventEmitter();
  @Output() addNewDisplayUnitMasterGroup = new EventEmitter();
  @Output() addNewDisplayUnitSubGroup = new EventEmitter();

  displayUnitMasterGroupDataSource: any[];
  isMasterLoading = false;
  isMasterRequired = false;

  displayUnitSubGroupDataSource: any[];
  displayUnitSubGroupCash: any[] = null;
  displayUnitSubGroupCashTemp: any[] = null;
  isSubLoading = false;
  isSubRequired = false;
  // Add New Parametars

  constructor(private DisplayUnitService: DisplayUnitService) { }

  ngOnInit() {
    this.displayUnitSubGroupCash = null;
    this.displayUnitSubGroupDataSource = [];
    this.displayUnitSubGroupCashTemp = [];
    this.populateDisplayUnitMasterGroupList();
    if (this.parentForm.get('displayUnitMasterGroup').value != null) {
      this.populateDisplayUnitSubGroupList(this.parentForm.get('displayUnitMasterGroup'));
      //this.selectedDisplaySubGroup.displayUnitSubGroupID = 1;
    } else if (this.showSubFilter && !this.showSubAlways) {
      this.populateDisplayUnitSubGroupList(null);
    } else if (this.showSubAlways) {
      this.populateDisplayUnitSubGroupListAlways();
    }
    if (this.parentForm.get('displayUnitMasterGroup').validator != null) {
      const validator = this.parentForm.get('displayUnitMasterGroup').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isMasterRequired = true;
      }
    }
    if (this.showSubFilter) {
      if (this.parentForm.get('displayUnitSubGroup').validator != null) {
        const validator = this.parentForm.get('displayUnitSubGroup').validator({} as AbstractControl);
        if (validator && validator.required) {
          this.isSubRequired = true;
        }
      }
    }
  }
  ngOnDestroy() {

  }


  populateDisplayUnitMasterGroupList() {
    this.isMasterLoading = true;
    this.DisplayUnitService.getDisplayUnitMasterGroup().subscribe(result => {
      this.displayUnitMasterGroupDataSource = result;
      this.isMasterLoading = false;
    });
  }

  populateDisplayUnitSubGroupListAlways() {
    this.displayUnitSubGroupDataSource = [];
    this.displayUnitSubGroupCashTemp = [];
    this.isSubRequired = true;
    if (this.displayUnitSubGroupCash == null) {
      this.displayUnitSubGroupCash = [];
      this.DisplayUnitService.getDisplayUnitSubGroup().subscribe(result => {
        result.forEach((element) => {
          this.displayUnitSubGroupCash.push(element);
        });
        this.isSubRequired = false;
        this.displayUnitSubGroupDataSource = this.displayUnitSubGroupCash;
      });
    } else {
      this.displayUnitSubGroupDataSource = this.displayUnitSubGroupCash;
    }
  }

  getDisplayUnitSubGroupFromDB(displayUnitMasterGroupID: any) {
    this.isSubRequired = true;
    this.displayUnitSubGroupCash = [];
    this.displayUnitSubGroupCashTemp = [];
    this.DisplayUnitService.getDisplayUnitSubGroup().subscribe(result => {
      result.forEach((element) => {
        this.displayUnitSubGroupCash.push(element);
        if (displayUnitMasterGroupID != null) {
          if (element.displayUnitMasterGroupID == displayUnitMasterGroupID.value) {
            this.displayUnitSubGroupCashTemp.push(element);
          }
        }
      });
      this.isSubRequired = false;
      this.displayUnitSubGroupDataSource = this.displayUnitSubGroupCashTemp;
    });
  }

  populateDisplayUnitSubGroupList(displayUnitMasterGroupID: any) {

    this.displayUnitSubGroupDataSource = [];
    this.displayUnitSubGroupCashTemp = [];
    if (this.displayUnitSubGroupCash == null) {
      this.getDisplayUnitSubGroupFromDB(displayUnitMasterGroupID);
    } else {
      this.displayUnitSubGroupCash.forEach((element) => {
        if (element.displayUnitMasterGroupID == displayUnitMasterGroupID) {
          this.displayUnitSubGroupCashTemp.push(element);
        }
      });
      this.displayUnitSubGroupDataSource = this.displayUnitSubGroupCashTemp;
    }

  }

  onChangeDisplayUnitMasterGroup(selectedDisplayMasterGroup: any) {
    if (this.showSubFilter) {
      this.parentForm.get('displayUnitSubGroup').reset();
      this.displayUnitSubGroupDataSource = [];
      if (this.showSubAlways) {
        this.populateDisplayUnitSubGroupListAlways();
      }
    }
    this.selectedDisplayMasterGroup.emit(selectedDisplayMasterGroup);
    if (selectedDisplayMasterGroup != null && this.showSubFilter) {
      this.populateDisplayUnitSubGroupList(selectedDisplayMasterGroup.displayUnitMasterGroupID);
    }
  }

  onChangeDisplayUnitSubGroup(selectedDisplaySubGroup: any) {
    this.selectedDisplaySubGroup.emit(selectedDisplaySubGroup);
  }

  //#Save Master region
  onAddNewDisplayUnitMasterGroup(addNewDisplayUnitMasterGroup: any) {
    this.addNewDisplayUnitMasterGroup.emit(addNewDisplayUnitMasterGroup);
  }

  public afterSaveMasterGroup(master) {
    this.displayUnitMasterGroupDataSource.push(master);
    var orginalList = cloneDeep(this.displayUnitMasterGroupDataSource);
    this.displayUnitMasterGroupDataSource = [];
    this.displayUnitMasterGroupDataSource = orginalList;
    //this.populateDisplayUnitMasterGroupList();

    this.parentForm.get('displayUnitMasterGroup').setValue(master.displayUnitMasterGroupID);
    this.onChangeDisplayUnitMasterGroup(master);
  }
  //#endregion

  //#Save Sub region
  onAddNewDisplayUnitSubGroup(addNewDisplayUnitSubGroup: any) {
    this.addNewDisplayUnitSubGroup.emit(addNewDisplayUnitSubGroup);
  }
  public afterSaveSubGroup(Sub) {
    this.displayUnitSubGroupCash.push(Sub);
    var orginalList = cloneDeep(this.displayUnitSubGroupCash);
    this.displayUnitSubGroupCash = [];
    this.displayUnitSubGroupCash = orginalList;

    this.populateDisplayUnitSubGroupList(Sub.displayUnitMasterGroupID);
    this.parentForm.get('displayUnitMasterGroup').setValue(Sub.displayUnitMasterGroupID);
    this.parentForm.get('displayUnitSubGroup').setValue(Sub.displayUnitSubGroupID);
  }
  //#endregion

}
