import { Component, OnInit, Input, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { EchoService } from '../../../../echo/echo.service';
import { DivisionService } from '../../../services/data-definition/product/division.service';

@Component({
  selector: 'app-division-filter',
  templateUrl: './division-filter.component.html',
  styleUrls: ['./division-filter.component.css']
})
export class DivisionFilterComponent implements OnInit {
  @Input() currentFormControlName = 'divisionId'
  @Input() parentForm: FormGroup;
  @Output() onDivisionIdCaptionChanged = new EventEmitter();
  @Output() onDivisionSelected = new EventEmitter();
  @Input() emitIdAndCaption = false;
  @Input() hideLabel = false;
  @Input() selectedEmployeeId = -1;
  @Input() isCustomerApp: false;
  @Input() skipSelectDivisionByDefault: boolean = false;
  divisionDataSource: any[];
  selectedDivisionId: number;
  isDivisionLoading = false;
  isRequired = false;

  constructor(
    private divisionService: DivisionService,
    private echoService: EchoService
  ) { }

  ngOnInit() {
    if (this.parentForm.get(this.currentFormControlName).validator != null) {
      const validator = this.parentForm.get(this.currentFormControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }

  }
  ngOnChanges() {
    this.populateDivisionList();
  }

  populateDivisionList() {
    this.isDivisionLoading = true;
    if (this.isCustomerApp) {
      this.echoService.getEchoDivisions().subscribe(result => {
        this.divisionDataSource = result.data;
        this.isDivisionLoading = false;
      });
    } else {
      this.divisionService.getAllDivisions(this.selectedEmployeeId).subscribe(result => {
        this.divisionDataSource = result.data;
        this.isDivisionLoading = false;
        if (this.divisionDataSource != null && this.divisionDataSource.length === 1 && !this.skipSelectDivisionByDefault) {
          this.parentForm.get(this.currentFormControlName).setValue(this.divisionDataSource[0].divisionId)
          this.onDivisionDataChanged(this.divisionDataSource[0]);
        }
      });
    }
  }
  onDivisionDataChanged(division: any) {
    this.selectedDivisionId = this.parentForm.value.divisionId;

    if (!this.emitIdAndCaption)
      this.onDivisionSelected.emit(division);
    else {
      let event = {
        id: this.selectedDivisionId,
        caption: division.divisionCodeName
      }
      this.onDivisionIdCaptionChanged.emit(event);
    }

  }
}
