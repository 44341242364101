import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationData } from '../../../shared/models/navigation-data/navigation-data.model';
import { CoreSession } from '../../core.session';
import { NavBarService } from '../../sidebar/sidebar.service';

@Component({
  selector: 'app-navigation-tree',
  templateUrl: './navigation-tree.component.html',
  styleUrls: ['./navigation-tree.component.css']
})
export class NavigationTreeComponent implements OnInit, OnDestroy {

  navigationSubscription: any;
  navigationData: NavigationData[] = [];
  navigationData_New: NavigationData[] = [];
  hideData = true;
  delay = 200;
  constructor(private navService: NavBarService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.subscribeChangeNavigation();
  }
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  subscribeChangeNavigation() {
    this.navigationSubscription = this.navService.NavigationChanged.subscribe(
      (data: NavigationData[]) => {
        this.delay = 50;
        setTimeout(() => {
          this.navigationData = data;
        }, this.delay);
      });
  }
  onChangeNavigation(url) {
    if (url != undefined) {
      this.router.navigateByUrl(url);
    }
  }
}
