import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";
import { WarehouseService } from "../../../services/data-definition/warehouse-and-vehicle/warehouse.service";

@Component({
  selector: "app-warehouse-filter",
  templateUrl: "./warehouse-filter.component.html",
  styleUrls: ["./warehouse-filter.component.css"],
})
export class WarehouseFilterComponent implements OnInit {
  //#region [Declarations]
  @Input() parentForm: FormGroup;
  @Input() currentStockMode: false;
  @Input() filterOnVehicle: boolean;
  @Input() vehicleId: number;
  @Input() organizationId: number = -1;
  @Input() filterWarehouseId: number = -1;
  @Input() isTransferIn = false;
  @Input() isTransferOut = false;
  @Input() getTrucks = false;
  @Input() getDamagedOnly = false;
  @Input() includeDamaged = false;
  @Input() selectFirstOption = false;
  @Input() getStockCountingTaskWarehouse = false;
  @Input() getCompanyWarehousesOnly = false;
  @Input() isExchangeInvoice = false;
  @Input() hideLabel = false;
  @Output()
  warehouseSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() warehouseObjectSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() truckSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() offLoadMode: boolean = false;

  @Input() warehouseDataSource: any[];
  @Input() getDataSourceFromInput = false;
  selectedWarehouseId: number = -1;
  isWarehouseLoading = false;
  currentFormControlName = "warehouseId";
  warehouseLabel = "Desc_Warehouse";
  isRequired = false;

  //#endregion
  //#region [Events]
  constructor(private warehouseService: WarehouseService) { }

  ngOnInit() {
    if (this.getStockCountingTaskWarehouse) {
      this.warehouseLabel = "Desc_Warehouse";
      this.currentFormControlName = "stockCountingTaskWarehouse";
    }
    if (this.getDamagedOnly) {
      this.warehouseLabel = "Desc_Destination_Warehouse";
      this.currentFormControlName = "refWarehouseId";
    }
    if (this.isTransferIn) {
      this.currentFormControlName = "refWarehouseId";
      this.warehouseLabel = "Desc_Source_Warehouse";
    } else if (this.isTransferOut) {
      this.currentFormControlName = "refWarehouseId";
      this.warehouseLabel = "Desc_Destination_Warehouse";
    }
    if (this.getTrucks) {
      this.currentFormControlName = "truckId";
      this.warehouseLabel = "Desc_Truck";
    }
    if (this.getCompanyWarehousesOnly) {
      this.warehouseLabel = "Desc_Company_Warehouse";
      this.currentFormControlName = "companyWarehouse";
    }
    if (this.isExchangeInvoice) {
      this.currentFormControlName = "refWarehouseId";
      this.warehouseLabel = "Desc_Destination_Warehouse";
    }
    if (this.parentForm.get(this.currentFormControlName).validator != null) {
      const validator = this.parentForm
        .get(this.currentFormControlName)
        .validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    //this.populateWarehouseList();
  }
  ngOnChanges() {
    if (this.getDamagedOnly) {
      this.currentFormControlName = "refWarehouseId";
    }
    this.selectedWarehouseId = this.parentForm.get(this.currentFormControlName)
      ? this.parentForm.get(this.currentFormControlName).value
      : -1;
    this.populateWarehouseList();
  }
  onWarehouseDataChanged(event: any) {
    this.selectedWarehouseId = this.parentForm.get(
      this.currentFormControlName
    ).value;
    if (this.getTrucks) {
      this.truckSelected.emit(event);
    } else {
      this.warehouseSelected.emit(this.selectedWarehouseId);
      this.warehouseObjectSelected.emit(event);
    }
    // this.warehouseService.(this.selectedWarehouseId);
  }

  //#endregion

  //#region [Methods]
  populateWarehouseList() {
    //    this.parentForm.get(this.currentFormControlName).reset();
    if (this.getDamagedOnly) {
      this.isWarehouseLoading = true;
      this.warehouseService.getDamagedWarehouses().subscribe((result) => {
        this.warehouseDataSource = result;
        this.isWarehouseLoading = false;
      });
    } else {
      if (this.isTransferIn) {
        this.isWarehouseLoading = true;
        this.warehouseService
          .getSourceWarehouses(this.filterWarehouseId)
          .subscribe((result) => {
            this.warehouseDataSource = result;
            this.isWarehouseLoading = false;
          });
      } else if (this.isTransferOut) {
        this.isWarehouseLoading = true;
        this.warehouseService
          .getDestinationWarehouses(this.filterWarehouseId)
          .subscribe((result) => {
            this.warehouseDataSource = result;
            this.isWarehouseLoading = false;
          });
      } else if (this.getTrucks) {
        this.isWarehouseLoading = true;
        this.warehouseService.getAllTrucks().subscribe((result) => {
          this.warehouseDataSource = result;
          this.isWarehouseLoading = false;
        });
      } else {
        let warehouseFilter = {
          vehicleId: -1,
          organizationId: this.organizationId,
          currentStockMode: false,
          includeDamaged: this.includeDamaged,
          offLoadMode: this.offLoadMode,
          getCompanyWarehousesOnly: this.getCompanyWarehousesOnly
        };
        if (
          this.currentStockMode != false &&
          this.currentStockMode != undefined
        ) {
          warehouseFilter.currentStockMode = this.currentStockMode;
        }
        if (
          this.filterOnVehicle &&
          this.vehicleId != null &&
          this.vehicleId != -1
        ) {
          warehouseFilter.vehicleId = this.vehicleId;
        }
        this.isWarehouseLoading = true;
        if (!this.getDataSourceFromInput) {
          this.warehouseService
            .getWarehouses(warehouseFilter)
            .subscribe((result) => {
              this.warehouseDataSource = result;
              if (
                this.filterOnVehicle &&
                this.vehicleId != null &&
                this.vehicleId != -1 &&
                this.warehouseDataSource.length > 0
              ) {
                this.parentForm
                  .get(this.currentFormControlName)
                  .setValue(this.warehouseDataSource[0].warehouseId);
                if (
                  this.filterWarehouseId > 0 &&
                  !this.isTransferIn &&
                  !this.isTransferOut
                ) {
                  this.parentForm
                    .get(this.currentFormControlName)
                    .setValue(this.filterWarehouseId);
                }

                this.onWarehouseDataChanged(null);
              }
              if (this.selectFirstOption) {
                this.parentForm
                  .get(this.currentFormControlName)
                  .setValue(this.warehouseDataSource[0].warehouseId);
              }

              this.isWarehouseLoading = false;
            });
        } else {
          if (
            this.filterOnVehicle &&
            this.vehicleId != null &&
            this.vehicleId != -1 &&
            this.warehouseDataSource.length > 0
          ) {
            this.parentForm
              .get(this.currentFormControlName)
              .setValue(this.warehouseDataSource[0].warehouseId);
            if (
              this.filterWarehouseId > 0 &&
              !this.isTransferIn &&
              !this.isTransferOut
            ) {
              this.parentForm
                .get(this.currentFormControlName)
                .setValue(this.filterWarehouseId);
            }

            this.onWarehouseDataChanged(null);
          }
          if (this.selectFirstOption) {
            this.parentForm
              .get(this.currentFormControlName)
              .setValue(this.warehouseDataSource[0].warehouseId);
          }
          this.isWarehouseLoading = false;
        }
      }
    }
  }

  //#endregion
}
