import { Component, EventEmitter, Pipe, Directive, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DisplayUnitService } from '../../../../../content/master-data-definition/display-unit/display-unit.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-display-unit-status',
  templateUrl: './display-unit-status.component.html',
  styleUrls: ['./display-unit-status.component.css']
})
export class DisplayUnitStatusComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Output() selectedDisplayUnitStatus = new EventEmitter();
  @Output() addNewDisplayUnitStatus = new EventEmitter();
  @Input() displayUnitStatusDataSource: any[];
  @Input() addNewStatus: boolean = false;
  @Input() showAddNew: boolean;




  isStatusLoading = false;
  isRequired = false;
  resetSubscription: any;

  constructor(private displayUnitService: DisplayUnitService) { }

  ngOnInit() {
    if (this.parentForm.get('displayUnitStatus').validator != null) {
      const validator = this.parentForm.get('displayUnitStatus').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateDisplayUnitStatusList();
  }
  ngOnDestroy() {

  }
  onChangeDisplayUnitStatus(selectedDisplayUnitStatus: any) {
    this.selectedDisplayUnitStatus.emit(selectedDisplayUnitStatus);
  }
  onAddNewDisplayUnitStatus(addNewDisplayUnitStatus: any) {
    this.addNewDisplayUnitStatus.emit(addNewDisplayUnitStatus);
  }

  public afterSaveStatus(status: any): void {
    this.displayUnitStatusDataSource.push(status);
    var orginalList = cloneDeep(this.displayUnitStatusDataSource);
    this.displayUnitStatusDataSource = [];
    this.displayUnitStatusDataSource = orginalList;

    this.parentForm.get('displayUnitStatus').setValue(status.displayUnitStatusID);
  }

  populateDisplayUnitStatusList() {
    this.isStatusLoading = true;
    this.displayUnitService.getDisplayUnitStatus().subscribe(result => {
      this.displayUnitStatusDataSource = result;
      this.isStatusLoading = false;
    });
  }

}
