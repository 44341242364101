import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { FormProcessMode } from "../../../models/enums/form-process-mode.enum";
import { FormControl, FormGroup, NgModel, Validators } from "@angular/forms";
import { CoreSession } from "../../../../core/core.session";
import { TranslateService } from "@ngx-translate/core";
import { EmployeeTypes } from "../../../models/enums/employee-types.enum";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { ItemPackModel } from "../../../models/Item/item.model";
import * as cloneDeep from 'lodash/cloneDeep';
import { DialogResult } from "../../../models/enums/dialog-result.enum";
import { DialogMode } from "../../../models/enums/dialog-mode.enum";
import { TabHeadingDirective } from 'ngx-bootstrap/tabs/public_api';
import { CalculationService } from '../../../services/calculations.service';
import { SharedDialogComponent } from "../../shared-dialog/shared-dialog.component";
import { ItemService } from "../../../services/data-definition/product/item.service";
// import { ItemService } from "../../items/item.service";
import { OrderService } from "../../../../content/order/order.service";
import { HttpErrorResponse } from "@angular/common/http";
import { TransationCommonData } from "../../../models/transaction/transaction-common-data";
import { NgbModal, NgbModalConfig, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MenuActions } from "../../../models/enums/menu-actions-enum";
import { ConstantURLs } from "../../../models/constants/constant-URL";

@Component({
  selector: "app-batch-selection",
  templateUrl: "./batch-selection.component.html",
  styleUrls: ["./batch-selection.component.css"]
})
export class BatchSelectionComponent implements OnInit, OnDestroy {
  @ViewChild('templateEntry', { static: true }) templateEntry: SharedDialogComponent;
  @ViewChild('returnFromInvoiceDialog', { static: true }) returnFromInvoiceDialog: SharedDialogComponent;
  @Input() detailsWarehouseId = -1;
  @Input() enableBatchInsertion = false;
  @Input() checkForAvailableBatchLevel = false;
  @Input() blockIfExceedAvailable = false;
  @Input() confirmationIfExceedAvailable = false;
  @Input() openedFromItemScreen = false;
  @Input() isFromSSCC = false;
  @Input() events: Observable<void>;

  @Input() formProcessMode: FormProcessMode;

  @Input() packObject: any;
  @Input() isTruck = false;
  @Input() addNewItem = false;
  @Input() selectedTrip: any;
  @Input() selectBatchPerInvoice: boolean = false;
  @Input() forceReturnWithSoldPrice: boolean = false;
  @Input() allowPartialDeliveryInProcessReturn: boolean = false;
  @Input() customerId: number = -1;
  @Input() outletId: number = -1;
  @Input() isDamagedWHSelected: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();
  @Output() onSaveReturnBatchesComplete = new EventEmitter();

  itemsDataSource: ItemPackModel[] = [];
  isItemsLoading = false;
  selectedPackId: string;
  saveBtnSubscription: any;
  showAvailableQyantity = false;
  showRequiredQyantity = false;
  showRequestedQyantity = false;
  showAddFromInvoice = false;
  @Input() showVehicleQTY = false;
  @Input() showMWHQty = false;
  @Input() showPendingQty = false;
  @Input() showReturnReason = false;
  @Input() allowRetailTaxOnItems = false;
  saveNewBatchSubject: Subject<void> = new Subject<void>();
  addNewBatch: boolean = false;
  showRequestedQuantityForSSCC = false;
  limitQuantityToOriginal = false;
  filledQtyColor = '#f8f9fa'; // filled qty color
  noColor = "#FFF"; // no color
  exceedAvlColor = "#FFE4B5";  // exceed available qty color
  nonMandatoryClmWidth = 0;
  notMandatoryClmsCount = 0;
  batchWidth = 0;
  expiryWidth = 0;
  enableAddItemByLiters = false;
  selectedBatchObj: any;
  itemStockBatches: any[] = [];
  packStatusForm: FormGroup;
  packStatusDataSource: any[] = [];
  isPackStatusLoading: boolean = false;
  isReturnReasonLoading: boolean = false;
  returnReasonDataSource: any[] = [];
  copyOfItemStockBatches: any[] = [];
  disableAddBatchesFrmInvBtn: boolean = true;
  selectedPackStatusObj: any;
  selectedPackStatusId: number = -1;
  selectedReturnReasonId: number = -1;
  showBatchLevelWarning: boolean = false;
  isProcessMode: boolean = false;
  copyOfBatchInvoices: any[] = [];
  copyOfReturnFromInvoices: any[] = [];
  hideDeleteAction: boolean = false;
  constructor(
    public coreSession: CoreSession,
    private translateService: TranslateService,
    public sessionData: SessionDataProvider,
    public calculationService: CalculationService,
    private itemService: ItemService
  ) { }

  ngOnInit() {
    if (this.formProcessMode === FormProcessMode.ProcessReturn) {
      this.isProcessMode = true;
      if (!this.allowPartialDeliveryInProcessReturn) {
        this.hideDeleteAction = true;
      }
    }
    this.initForm();
    this.fillScreenByProcessMood();
    if (this.selectBatchPerInvoice) {
      if (!this.packObject.packStatusId || this.packObject.packStatusId < 0) {
        this.fillPackStatusDS();
      }
      this.fillUIFromPack();
    }
    if (this.isFromSSCC && (this.formProcessMode === FormProcessMode.LoadRequest || this.formProcessMode === FormProcessMode.Delivery || this.formProcessMode === FormProcessMode.PartialDelivery)) {
      this.showRequestedQuantityForSSCC = true;
      this.limitQuantityToOriginal = true;
    }
    if (this.formProcessMode === FormProcessMode.Delivery || this.formProcessMode === FormProcessMode.PartialDelivery) {
      this.fillPackRequiredQty();
      this.showRequestedQuantityForSSCC = true;
      this.limitQuantityToOriginal = true;
    }
    this.saveBtn();
    if (!this.addNewItem) {

      this.prepareQuantityToView();
    } else {
      this.packObject = {};
      this.itemsDataSource = this.selectedTrip.additionalItems;

    }

    var totalWidth = 100;
    if (this.showMWHQty) this.notMandatoryClmsCount += 1;
    if (this.showVehicleQTY) this.notMandatoryClmsCount += 1;
    if (this.showPendingQty) this.notMandatoryClmsCount += 1;
    if (this.showAvailableQyantity) this.notMandatoryClmsCount += 1;
    if (this.showRequiredQyantity) this.notMandatoryClmsCount += 1;
    if (this.showAddFromInvoice) this.notMandatoryClmsCount += 1;
    if (this.notMandatoryClmsCount > 2) {
      this.batchWidth = 12;
      this.expiryWidth = 14;
    } else {
      this.batchWidth = 20;
      this.expiryWidth = 20;
    }
    totalWidth -= (this.batchWidth + this.expiryWidth);
    this.nonMandatoryClmWidth = Math.ceil(totalWidth / this.notMandatoryClmsCount);
    if (this.formProcessMode === FormProcessMode.InputVoucher || this.formProcessMode === FormProcessMode.OutputVoucher
      || this.formProcessMode === FormProcessMode.LoadRequest || this.formProcessMode === FormProcessMode.OffLoad) {
      this.enableAddItemByLiters = true;
    }
  }

  fillUIFromPack() {
    if (this.packObject.packStatusId > 0) {
      this.packStatusForm.get('packStatusId').markAsTouched();
      this.packStatusForm.get('returnReasonId').markAsTouched();
      this.packStatusForm.updateValueAndValidity();
    }

    if (this.packObject.returnFromInvoiceAvailableBatchList && this.packObject.returnFromInvoiceAvailableBatchList.length > 0) {
      this.copyOfReturnFromInvoices = cloneDeep(this.packObject.returnFromInvoiceAvailableBatchList);
      this.itemStockBatches = [];
      var batchInvoices = [];
      var requiredQty = 0

      this.packObject.returnFromInvoiceAvailableBatchList.forEach(invbatch => {
        if (invbatch.requiredQuantity * this.packObject.piecesInPack > invbatch.availableQtyFromInvoice) invbatch.invalid = true;
        var idx = this.itemStockBatches.findIndex(batch => batch.batchNo === invbatch.batchNo &&
          batch.expiryDateModel.year == invbatch.expiryDateModel.year &&
          batch.expiryDateModel.month == invbatch.expiryDateModel.month &&
          batch.expiryDateModel.day == invbatch.expiryDateModel.day);
        if (idx > -1) {
          this.itemStockBatches[idx].batchInvoices.push(invbatch);
          this.itemStockBatches[idx].availableQtyInPcs += invbatch.availableQtyFromInvoice;
          this.itemStockBatches[idx].requiredQty += invbatch.requiredQuantity;
          this.itemStockBatches[idx].requiredQty = this.itemStockBatches[idx].requiredQty === 0 ?
            null : this.itemStockBatches[idx].requiredQty;

        } else {
          // batch not added
          batchInvoices = [];
          batchInvoices.push(invbatch);
          var batchObj = {
            batchNo: invbatch.batchNo,
            expiryDateModel: invbatch.expiryDateModel,
            batchInvoices: batchInvoices,
            requiredQty: invbatch.requiredQuantity === 0 ? null : 0 + invbatch.requiredQuantity,
            availableQtyInPcs: 0 + invbatch.availableQtyFromInvoice,
          }
          this.itemStockBatches.push(batchObj);
        }
      });
    }
    // Show Invalid Batches on UI.
    this.itemStockBatches.forEach(batch => {
      if (batch.batchInvoices.filter(inv => inv.invalid === true).length > 0) {
        batch.backGroundColor = this.exceedAvlColor;
      }
    });
    // cache original available data.
    this.packObject.itemStockBatches = this.itemStockBatches;
    this.copyOfItemStockBatches = cloneDeep(this.packObject.itemStockBatches);

    // If item has packStatusId > -1 Filter batches on that pack status, else display empty list until user
    // selects a packStatus.
    if (this.packObject.packStatusId && this.packObject.packStatusId > -1) {
      this.selectedPackStatusId = this.packObject.packStatusId;
      this.selectedReturnReasonId = this.packObject.returnReasonId;
      this.fillPackStatusDS();
      this.disableAddBatchesFrmInvBtn = false;
      this.packStatusForm.get('packStatusId').setValue(this.packObject.packStatusId);
      this.fillReturnReasonDS(this.packObject.packStatusId, this.packObject.returnReasonId);
    } else {
      this.packObject.itemStockBatches = [];

    }
  }

  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

  saveBtn() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }
  onChangeItem(selectedItem: any) {
    this.packObject = cloneDeep(selectedItem);
    this.packObject.selectedBatches = cloneDeep(selectedItem.itemStockBatches);

  }
  onDeleteBatch(addedBatch: any) {
    if (this.selectBatchPerInvoice) {
      this.deleteReturnBatch(addedBatch);
    } else {
      if (addedBatch.isNewBatch) {
        let index = this.packObject.itemStockBatches.findIndex(
          i =>
            i.itemId === addedBatch.itemId &&
            i.batchNo === addedBatch.batchNo &&
            i.expiryDateModel.year == addedBatch.expiryDateModel.year &&
            i.expiryDateModel.month == addedBatch.expiryDateModel.month &&
            i.expiryDateModel.day == addedBatch.expiryDateModel.day
        );
        let selectedBatchesIndex = this.packObject.selectedBatches.findIndex(
          i =>
            i.itemId === addedBatch.itemId &&
            i.batchNo === addedBatch.batchNo &&
            i.expiryDateModel.year == addedBatch.expiryDateModel.year &&
            i.expiryDateModel.month == addedBatch.expiryDateModel.month &&
            i.expiryDateModel.day == addedBatch.expiryDateModel.day
        );
        if (index !== -1) {
          this.packObject.itemStockBatches.splice(index, 1);
        }
        if (selectedBatchesIndex !== -1) {
          this.packObject.selectedBatches.splice(selectedBatchesIndex, 1);
        }
        this.fillPackRequiredQty();
      }
    }
  }

  updateValuesBeforeSave() {
    if (this.packObject.requiredQty === 0) {
      this.packObject.requiredQty = null;
    }
    this.packObject.itemStockBatches.forEach(batch => {
      batch.allowDeleteNewBatch = false;
      if (batch.requiredQty === null) {
        batch.requiredQty = 0;
      }
    });
  }

  preparePackObjInReturn() {
    if (this.selectedPackStatusId <= 0) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgSelectPackStatus)
      );
      return;
    }
    if (this.showReturnReason && this.selectedReturnReasonId <= 0) {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgSelectReturnReason)
      );
      return;
    }

    //Update total availableQty :
    this.updatePackObjectQuantity();
    // this.packObject.requiredQty = 0;
    // this.packObject.availableQtyInPcs = 0;

    // //...
    // this.packObject.itemStockBatches.forEach(batch => {
    //   this.reflectChangesOnPackObj(batch.batchInvoices);
    // });
    // //...

    // this.packObject.returnFromInvoiceAvailableBatchList.forEach(inv => {
    //   if(!inv.requiredQuantity) {inv.requiredQuantity = 0;}
    //   this.packObject.requiredQty += inv.requiredQuantity;
    // });

    // this.packObject.returnReasonId = this.selectedReturnReasonId;
    // this.packObject.packStatusId = this.selectedPackStatusId;

    // // fill packObject with default expiry date and batch no for now.
    // // when preparing items for saving each pack will take batch no/expiry date of its selected batches.
    // this.packObject.batchNo = this.coreSession.DefaultBatchNumber;
    // this.packObject.expiryDate = this.coreSession.DefaultExpiryDate();
    // this.packObject.expiryDateModel = this.coreSession.getDateTimeModel(this.packObject.expiryDate);
    this.onSaveReturnBatchesComplete.emit(this.packObject);
  }
  updatePackObjectQuantity() {
    this.packObject.requiredQty = 0;
    this.packObject.availableQtyInPcs = 0;

    //...
    this.packObject.itemStockBatches.forEach(batch => {
      this.reflectChangesOnPackObj(batch.batchInvoices);
    });
    //...

    // this.packObject.returnFromInvoiceAvailableBatchList.forEach(inv => {
    //   if(!inv.requiredQuantity) {inv.requiredQuantity = 0;}
    //   this.packObject.requiredQty += inv.requiredQuantity;
    // });

    this.packObject.returnReasonId = this.selectedReturnReasonId;
    this.packObject.packStatusId = this.selectedPackStatusId;

    // fill packObject with default expiry date and batch no for now.
    // when preparing items for saving each pack will take batch no/expiry date of its selected batches.
    this.packObject.batchNo = this.coreSession.DefaultBatchNumber;
    this.packObject.expiryDate = this.coreSession.DefaultExpiryDate();
    this.packObject.expiryDateModel = this.coreSession.getDateTimeModel(this.packObject.expiryDate);
  }

  // recalculatePromotionBenefitForReturns(packObject) {
  //   var responseData = [];
  //   var transactionCommonData = new TransationCommonData();
  //   transactionCommonData.soldItems = [];
  //   transactionCommonData.soldItems.push(packObject);
  //   transactionCommonData.customerId = this.customerId;
  //   transactionCommonData.outletId = this.outletId;

  //   this.coreSession.ModalLoading.Show();
  //   this.orderService.RecalculateBenefitInReturn(transactionCommonData).subscribe((response)=>{
  //     this.coreSession.ModalLoading.Hide();
  //     if(response.status != null && response.status >= 0) {
  //       // two promotions on same transactions.
  //       var TestPromotion = {
  //         oldBenefit: 25,
  //         newBenefit: 20,
  //         packId: 194,
  //         packGroupId: -1,
  //         transactionIds: ['OINV-soo01-000005','OINV-soo01-000004'], // Transaction included in this benefit.
  //         promotionOutputDetail: 3,
  //         promotionCode: 'Promo1'
  //       }
  //       var TestPromotion2 = {
  //         oldBenefit: 20,
  //         newBenefit: 10,
  //         packId: 194,
  //         packGroupId: -1,
  //         transactionIds: ['OINV-soo01-000005','OINV-soo01-000004'], // Transaction included in this benefit.
  //         promotionOutputDetail: 3,
  //         promotionCode: 'Promo2'
  //       }
  //       responseData.push(TestPromotion,TestPromotion2);
  //       this.initBenefitTypeForm();


  //       responseData.forEach(
  //         (promo) => {
  //           // If benefit type is [Item Quantity].
  //           if (promo.promotionOutputDetail === 3) {
  //           // 1. Benefit is a specific PACK (not pack group).
  //             if(promo.packId > -1 && promo.packGroupId === -1) {
  //               this.handleBenefitPackLevel(promo,packObject);
  //             } else if (promo.packId === -1 && promo.packGroupId > -1) {
  //               this.handleBenefitPackGroupLevel(promo);
  //             }
  //           } else if (promo.promotionOutputDetail === 1) {
  //           // If benefit type is Discount Amount.
  //           }
  //         }
  //       );

  //     } else {
  //       this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), ConstantMessages.ErrorHappened);
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     this.coreSession.ModalLoading.Hide();
  //     this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), error.message);
  //   }
  //   );
  // }

  // handleBenefitPackLevel(promotion,packObject) {
  //   if(promotion.oldBenefit > promotion.newBenefit) {
  //     var toBeReturnedQty = promotion.oldBenefit - promotion.newBenefit;
  //     // 1.1: Benefit pack is the same pack being returned:
  //     if(promotion.packId === packObject.packId) {
  //     // Find total available quantity after selecting return values (requiredQuantity) from each transaction included in promotion.
  //        var rtnsFromTxsIncludedInBenefit = this.selectedBatchObj.batchInvoices.filter(x =>
  //           promotion.transactionIds.includes(x.transactionId));

  //           var totalAvlQty = 0;
  //           var totalRequiredQty = 0;
  //           rtnsFromTxsIncludedInBenefit.map(x => {
  //             totalAvlQty += x.availableQtyFromInvoice;
  //             totalRequiredQty += x.requiredQuantity
  //           });
  //           // check if total avl quantity from benefit pack is greater than the to be returned benefit quantity.
  //           var totalAvlQtyAfterReturn = (totalAvlQty/packObject.piecesInPack) - totalRequiredQty;
  //           if (totalAvlQtyAfterReturn >= toBeReturnedQty) {
  //             // Benefit value is available, give the user the following options.
  //               // 1. Return item quantity benefit as items from invoices included in promotion.
  //               // 2. Return item quantity benefit as discount.
  //               this.UIPromotions.push(promotion);
  //           } else {
  //             // Benefit value is not available => apply discount.

  //           }
  //     }
  //     // 1.2: Benefit pack is not the same pack being returned:
  //     else if (promotion.packId !== packObject.packId) {

  //     }
  //   }
  // }

  onSaveClicked() {
    // #region [save for items screen]
    if (this.openedFromItemScreen) {
      if (this.selectBatchPerInvoice) {
        this.preparePackObjInReturn();
      } else {
        if (this.formProcessMode === FormProcessMode.Delivery && !this.isFromSSCC) {
          if (this.packObject.originalQuantity != this.packObject.requiredQty) {
            this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.Desc_Applied_Quantity_Not_Match_Req_Qnty));
            return;
          }
        }
        if (!this.checkForAvailableBatchLevel) {
          this.updateValuesBeforeSave();
          this.onSaveCompleted.emit(this.packObject);
        } else {
          var isExceed = false;
          this.packObject.selectedBatches.forEach(batch => {
            // check for availability for non new added batches
            if (!batch.isNewBatch && batch.availableQtyInPcs < batch.requiredQty * this.packObject.piecesInPack) {
              isExceed = true;
            }
          })
          if (isExceed) {
            if (this.blockIfExceedAvailable) {
              this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgExceedAvailableQtyBlock));
              return;
            } else if (this.confirmationIfExceedAvailable) {
              this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgExceedAvailableQtyConfirmation), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
                (res: DialogResult) => {
                  if (res === DialogResult.Yes) {
                    this.updateValuesBeforeSave();
                    this.onSaveCompleted.emit(this.packObject);
                  }
                });
            }
          } else {
            this.updateValuesBeforeSave();
            this.onSaveCompleted.emit(this.packObject);
          }
        }
      }
    }
    // #endregion
    else {
      switch (this.formProcessMode) {
        case FormProcessMode.Checkout:
          if (this.noBatchSelected()) {
            this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.SelectBatch));
            return;
          }
          if (
            this.coreSession.CurrentOperator.employeeTypeId ==
            EmployeeTypes.Picker &&
            this.requierdMoreThanRequested()
          ) {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.RequierdMoreThanRequested));
            return;
          }
          break;

        case FormProcessMode.CheckIn:
          if (!this.packObject) return;
          // if (this.noBatchSelectedInCheckIn()) {
          //   this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.SelectBatch));
          //   return;
          // }
          break;
      }

      this.updateValuesBeforeSave();
      this.onSaveCompleted.emit(this.packObject);
    }
  }

  noBatchSelected(): boolean {
    if (this.packObject.selectedBatches.length == 0) {
      return true;
    } else {
      return false;
    }
  }
  noBatchSelectedInCheckIn(): boolean {
    if (this.packObject.selectedBatches.length == 0 || this.packObject.selectedBatches.findIndex(batch => batch.requiredQty > 0) === -1) {
      return true;
    } else {
      return false;
    }
  }
  requierdMoreThanRequested(): boolean {
    if (this.packObject.requiredQty > this.packObject.requestedQuantity) {
      return true;
    } else {
      return false;
    }
  }

  isOperationCheckin() {
    return this.formProcessMode == FormProcessMode.CheckIn;
  }
  fillScreenByProcessMood() {
    if (this.openedFromItemScreen) {
      if (this.checkForAvailableBatchLevel) {
        this.showAvailableQyantity = true;
      }
      this.showRequiredQyantity = true;
      if (this.selectBatchPerInvoice) {
        this.showAddFromInvoice = true;
      }
    } else {
      switch (this.formProcessMode) {
        case FormProcessMode.Checkout:
          this.showAvailableQyantity = true;
          this.showRequiredQyantity = true;

          if (
            this.coreSession.CurrentOperator.employeeTypeId ==
            EmployeeTypes.Picker || this.coreSession.CurrentOperator.employeeTypeId ==
            EmployeeTypes.StoreKeeper
          ) {
            this.showRequestedQyantity = true;
          }
          break;
        case FormProcessMode.CheckIn:
          this.showAvailableQyantity = false;
          this.showRequiredQyantity = false;
          let numberOfWarehouseChecksInCheckIn = this.sessionData.getConfigurationValue(
            ConstantConfigurations.NumberOfWarehouseChecksInCheckIn
          );
          if (
            this.coreSession.CurrentOperator.employeeTypeId ==
            EmployeeTypes.Picker || this.coreSession.CurrentOperator.employeeTypeId ==
            EmployeeTypes.StoreKeeper ||
            (+numberOfWarehouseChecksInCheckIn == 1 &&
              this.coreSession.CurrentOperator.employeeTypeId ===
              EmployeeTypes.Foreman)
          ) {
            this.showRequestedQyantity = true;
          }
          if (
            this.sessionData
              .getConfigurationValue(ConstantConfigurations.ShowQuantityInLoadIn)
              .toLowerCase() === "true"
          ) {
            this.showAvailableQyantity = true;
            this.showRequiredQyantity = true;
          }
          if (this.isTruck) {
            this.showAvailableQyantity = false;
          }
          break;
      }
    }
  }
  disableQtyInput() {
    if ((this.packObject.requiredByLiter && this.enableAddItemByLiters) || this.selectBatchPerInvoice) {
      return true;
    } else {
      return false;
    }
  }
  prepareQuantityToView() {
    this.packObject.itemStockBatches.forEach(batch => {
      if (batch.requiredQty === 0 && !this.isOperationCheckin()) {
        batch.requiredQty = null;
        batch.backGroundColor = this.noColor;
      } else if (batch.requiredQty > 0) {
        if (batch.availableQtyInPcs < batch.requiredQty * this.packObject.piecesInPack) {
          if (this.checkForAvailableBatchLevel) {
            batch.backGroundColor = this.exceedAvlColor;
          }
        }
        else {
          batch.backGroundColor = this.filledQtyColor;
        }
      }
    });
  }
  validateSSCC(batch: any) {
    if (this.limitQuantityToOriginal) {
      if (this.packObject.originalQuantity < this.packObject.requiredQty) {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.RequierdMoreThanRequested));
        batch.requiredQty = 0;
        this.fillPackRequiredQty();
        return;
      }
    }
  }
  validateQuantity(batch: any) {
    // check if item exist in selected items

    let index = this.packObject.selectedBatches.findIndex(
      i =>
        i.itemId === batch.itemId &&
        i.batchNo === batch.batchNo &&
        i.expiryDateModel.year == batch.expiryDateModel.year &&
        i.expiryDateModel.month == batch.expiryDateModel.month &&
        i.expiryDateModel.day == batch.expiryDateModel.day
    );
    if (this.limitQuantityToOriginal) {
      if (this.packObject.originalQuantity < this.getTotalRequiredOfOtherQuantities(index) + batch.requiredQty) {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.RequierdMoreThanRequested));
        batch.requiredQty = 0;
        this.fillPackRequiredQty();
        return;
      }
    }
    if (batch.requiredQty <= 0 && !this.isOperationCheckin()) {
      this.removeInValidNumberFromUI(index, batch);
      this.fillPackRequiredQty();
      return;
    }
    if (batch.requiredQty <= 0 && this.isOperationCheckin()) {
      this.packObject.selectedBatches[index].requiredQty = 0;
      batch.backGroundColor = this.noColor;
      this.removeInValidNumberFromUI(index, batch);
      this.fillPackRequiredQty();
      return;
    }

    batch.backGroundColor = this.filledQtyColor;

    // Check Available Quantity
    if (this.formProcessMode === FormProcessMode.Checkout) {
      let availableQty = batch.availableQtyInPcs / this.packObject.piecesInPack;
      if (batch.requiredQty > availableQty) {
        this.removeInValidNumberFromUI(index, batch);
        this.fillPackRequiredQty();
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.RequiredMoreThanAvailable));
        return;
      }
    } else if (this.openedFromItemScreen && this.checkForAvailableBatchLevel) {
      // Check Available Quantity
      if (batch.availableQtyInPcs < batch.requiredQty * this.packObject.piecesInPack && !batch.isNewBatch) {
        batch.backGroundColor = this.exceedAvlColor;
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.RequiredMoreThanAvailable));
      }
    }

    // Then Quantity is valid and add batch to selected batches

    //for delivery set batch calculation data
    if (this.formProcessMode === FormProcessMode.Delivery || this.formProcessMode === FormProcessMode.PartialDelivery) {
      this.setBatchTotals(batch);
    }
    if (index === -1) {
      this.packObject.selectedBatches.push(batch);
    } else {
      this.packObject.selectedBatches[index].requiredQty = batch.requiredQty;
    }
    this.fillPackRequiredQty();
  }
  setBatchTotals(batch) {
    batch.price = this.packObject.price;
    batch.tax = this.packObject.tax;
    batch.discount = this.packObject.discount;
    batch.retailValue = this.packObject.retailValue;
    batch.promotedDiscount = this.packObject.promotedDiscount;
    batch.discountTypeId = this.packObject.discountTypeId;
    this.calculationService.calculatePackTotal(batch);
  }
  removeInValidNumberFromUI(index: number, batch: any) {
    batch.backGroundColor = this.noColor;
    batch.requiredQty = null;
    // Remove From Selected Batches
    if (index != -1) {
      this.packObject.selectedBatches.splice(index, 1);
    }
  }

  fillPackRequiredQty() {
    this.packObject.requiredQty = 0;
    this.packObject.selectedBatches.forEach(batch => {
      this.packObject.requiredQty += +batch.requiredQty;
    });
  }
  getTotalRequiredOfOtherQuantities(batchIndex: number) {
    var total = 0;

    if (batchIndex !== -1) {
      for (var index = 0; index < this.packObject.selectedBatches.length; index++) {
        if (batchIndex !== index)
          total += +this.packObject.selectedBatches[index].requiredQty;
      }
    }
    else {
      if (this.packObject.selectedBatches) {
        this.packObject.selectedBatches.forEach(batch => {
          total += batch.requiredQty;
        });
      }

    }

    return total;
  }

  clearField(batch) {
    batch.requiredQty = null;
  }

  onAddBatch() {
    this.templateEntry.Show(true).then(
      (res) => {
        if (res == DialogResult.Save) {
          this.afterSaveNewBatch();
        }
      }
    );
  }

  onSaveBatchClicked() {
    this.saveNewBatchSubject.next();
  }
  afterSaveNewBatch() {
    this.addNewBatch = false;
    this.templateEntry.Close();
    this.fillPackRequiredQty();
  }
  onCloseNewBatchClicked() {
    this.addNewBatch = false;
  }
  onDialogResult(event) {
    if (event && event.saveCLicked) {
      this.onSaveBatchClicked();
    }
  }
  // Return from invoice:

  initForm() {
    this.packStatusForm = new FormGroup({
      packStatusId: new FormControl(null, Validators.required),
      returnReasonId: new FormControl(null, this.showReturnReason ? Validators.required : Validators.nullValidator),
    });
    if (!this.allowPartialDeliveryInProcessReturn && this.formProcessMode === FormProcessMode.ProcessReturn) {
      this.packStatusForm.get('packStatusId').disable();
      this.packStatusForm.get('returnReasonId').disable();
    }
  }

  fillPackStatusDS() {
    this.isPackStatusLoading = true;
    this.itemService.getPackStatuses(this.isDamagedWHSelected).subscribe((response) => {
      this.isPackStatusLoading = false;
      if (response.status != null && response.status >= 0) {
        this.packStatusDataSource = response.data;
        // if edit mode true, get packstatus Of packObj and filter batchesList based on the selected packstatus.
        if (this.packObject.packStatusId > 0) {
          this.selectedPackStatusObj =
            this.packStatusDataSource.filter(ds => ds.packStatusId === this.packObject.packStatusId)[0];

          this.filterBatchesFromPackStatus(this.selectedPackStatusObj);
        }
      } else {
        this.packStatusDataSource = [];
      }
    });
  }

  filterBatchesFromPackStatus(selectedPackStatusObj: any) {
    var currentDate = this.coreSession.getDateTimeModel(new Date());

    if (selectedPackStatusObj && selectedPackStatusObj != undefined && selectedPackStatusObj.packStatusId > 0) {
      if (selectedPackStatusObj.reSellable === 1) {
        var resellableBatches = this.copyOfItemStockBatches.filter(b =>
          b.expiryDateModel.year > currentDate.year ||
          (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month > currentDate.month) ||
          (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month === currentDate.month && b.expiryDateModel.day > currentDate.day)
        );
        this.packObject.itemStockBatches = resellableBatches;
      } else if (selectedPackStatusObj.expired === 1) {
        var expiredBatches = this.copyOfItemStockBatches.filter(b =>
          b.expiryDateModel.year < currentDate.year ||
          (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month < currentDate.month) ||
          (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month === currentDate.month && b.expiryDateModel.day < currentDate.day) ||
          (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month === currentDate.month && b.expiryDateModel.day === currentDate.day)
        );
        this.packObject.itemStockBatches = expiredBatches;
      } else {
        this.packObject.itemStockBatches = cloneDeep(this.copyOfItemStockBatches);
      }
    }
  }

  fillReturnReasonDS(selectedPackStatusId, selectedReturnReasonId?: number) {
    this.itemService.getReturnReasons(selectedPackStatusId).subscribe(response => {
      if (response && response.status != null && response.status >= 0) {
        this.returnReasonDataSource = response.data;
        // If packObj.ReturnReasonId > 0, fill UI.
        if (selectedReturnReasonId && selectedReturnReasonId > -1) {
          this.selectedReturnReasonId = selectedReturnReasonId;
          this.packStatusForm.get('returnReasonId').setValue(selectedReturnReasonId);
        } else {
          // If packObj.ReturnReasonId === -1, fill default value IF [showReturnReason] configuration value is true;
          var defaultReturnReason = this.returnReasonDataSource.filter(rr => rr.isDefault === 1);
          if (defaultReturnReason.length > 0 && this.showReturnReason) {
            this.packStatusForm.get('returnReasonId').setValue(defaultReturnReason[0].returnReasonId);
            this.selectedReturnReasonId = defaultReturnReason[0].returnReasonId;
          }
        }
      } else {
        this.returnReasonDataSource = [];
      }
    });
  }

  onPackStatusSelected(packStatus) {
    if (packStatus && packStatus !== undefined) {
      // Check if user added at least one batch to show warning.
      var showWarning = this.packObject.itemStockBatches.filter(x => x.requiredQty > 0).length > 0;
      if (showWarning) {
        this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgSelectedBatchesWillBeDeleted), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
          (res: DialogResult) => {
            if (res == DialogResult.Yes) {
              this.clearPackObjectQtys();
              this.afterSelectingPackStatus(packStatus);
            }
            else {
              this.packStatusForm.get('packStatusId').setValue(this.selectedPackStatusId);
              this.packStatusForm.get('returnReasonId').setValue(this.selectedReturnReasonId);
              return;
            }
          });
      } else {
        this.afterSelectingPackStatus(packStatus);
      }
    } else {
      var showWarning = this.packObject.itemStockBatches.filter(x => x.requiredQty > 0).length > 0;
      if (showWarning) {
        this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgSelectedBatchesWillBeDeleted), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
          (res: DialogResult) => {
            if (res == DialogResult.Yes) {
              // Clear All Data.
              this.fillReturnReasonDS(-1, -1);

              this.disableAddBatchesFrmInvBtn = true;
              this.packObject.itemStockBatches = [];
              this.packObject.requiredQty = 0;
              this.packObject.returnFromInvoiceAvailableBatchList.forEach(b => {
                b.requiredQuantity = 0;
                b.invalid = false;
              });
              this.copyOfItemStockBatches.forEach(b => {
                b.requiredQty = 0;
                b.backGroundColor = this.noColor;
                b.batchInvoices.forEach(i => {
                  i.requiredQuantity = 0;
                  i.invalid = false;
                });
              });

              this.selectedPackStatusId = -1;
              this.selectedReturnReasonId = -1;
              this.packStatusForm.get('packStatusId').setValue(null);
              this.packStatusForm.get('returnReasonId').setValue(null);
            }
            else {
              this.packStatusForm.get('packStatusId').setValue(this.selectedPackStatusId);
              this.packStatusForm.get('returnReasonId').setValue(this.selectedReturnReasonId);
              return;
            }
          }
        );
      } else {
        this.fillReturnReasonDS(-1, -1);
        this.selectedReturnReasonId = -1;
        this.selectedPackStatusId = -1;
        this.packStatusForm.get('returnReasonId').setValue(null);
        this.packObject.requiredQty = 0;
        this.packObject.itemStockBatches = [];
        this.copyOfItemStockBatches.forEach(b => {
          b.requiredQty = 0;
          b.batchInvoices.forEach(i => {
            i.requiredQuantity = 0;
          });
        });
      }
    }
  }

  afterSelectingPackStatus(packStatus) {
    this.packStatusForm.get('returnReasonId').setValue(null);
    this.selectedReturnReasonId = -1;
    this.fillReturnReasonDS(packStatus.packStatusId, -1);
    this.selectedPackStatusId = packStatus.packStatusId;
    var currentDate = this.coreSession.getDateTimeModel(new Date());
    // Prevent user from adding batches before selecting packstatus.
    this.disableAddBatchesFrmInvBtn = false;
    if (packStatus.reSellable === 1) {
      var resellableBatches = this.copyOfItemStockBatches.filter(b =>
        b.expiryDateModel.year > currentDate.year ||
        (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month > currentDate.month) ||
        (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month === currentDate.month && b.expiryDateModel.day > currentDate.day)
      );
      this.packObject.itemStockBatches = resellableBatches;
    } else if (packStatus.expired === 1) {
      var expiredBatches = this.copyOfItemStockBatches.filter(b =>
        b.expiryDateModel.year < currentDate.year ||
        (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month < currentDate.month) ||
        (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month === currentDate.month && b.expiryDateModel.day < currentDate.day) ||
        (b.expiryDateModel.year === currentDate.year && b.expiryDateModel.month === currentDate.month && b.expiryDateModel.day === currentDate.day)
      );
      this.packObject.itemStockBatches = expiredBatches;
    } else {
      this.packObject.itemStockBatches = cloneDeep(this.copyOfItemStockBatches);
    }
  }

  clearPackObjectQtys() {
    this.packObject.requiredQty = 0;

    this.packObject.returnFromInvoiceAvailableBatchList.forEach(inv => {
      inv.requiredQuantity = 0;
      inv.invalid = false;
    });

    this.copyOfItemStockBatches.forEach(batch => {
      batch.requiredQty = 0;
      batch.backGroundColor = this.noColor
      batch.batchInvoices.forEach(i => { i.requiredQuantity = 0; i.invalid = false });
    });

    this.packObject.itemStockBatches = cloneDeep(this.copyOfItemStockBatches);
  }

  onReturnReasonSelected(returnReason) {
    this.selectedReturnReasonId = -1;
    if (returnReason && returnReason != undefined) {
      this.selectedReturnReasonId = returnReason.returnReasonId;
    }
    // returnReasonId
  }

  onAddFromInvoiceClicked(batch) {
    this.copyOfBatchInvoices = cloneDeep(batch.batchInvoices);

    batch.backGroundColor = batch.requiredQty > 0 ?
      this.filledQtyColor : this.noColor;


    var i = this.packObject.itemStockBatches.findIndex(b => b.batchNo === batch.batchNo &&
      b.expiryDateModel.year === batch.expiryDateModel.year &&
      b.expiryDateModel.month === batch.expiryDateModel.month &&
      b.expiryDateModel.day === batch.expiryDateModel.day);
    if (i > -1) {
      this.packObject.itemStockBatches[i].backGroundColor = this.packObject.itemStockBatches[i].requiredQty > 0 ?
        this.filledQtyColor : this.noColor;
    }
    this.selectedBatchObj = batch;
    this.selectedBatchObj.batchInvoices.forEach(x => {

      if (!x.requiredQuantity || x.requiredQuantity === 0) {
        x.requiredQuantity = null;
      }
    });
    this.returnFromInvoiceDialog.Show(true).then(
      (res) => {
        if (res == DialogResult.Save) {
          // this.afterQtyFromInvoiceSaved();
        }
      }
    );
  }

  afterQtyFromInvoiceSaved() {
    this.returnFromInvoiceDialog.Close();
  }

  validateQuantityForReturn(pack, batch: any, invBatch: any, index: number) {
    // invBatch.backGroundColor = this.exceedAvlColor;
    invBatch.invalid = false;
    invBatch.backGroundColor = this.noColor;
    if (invBatch.requiredQuantity > invBatch.availableQtyFromInvoice / pack.piecesInPack) {
      invBatch.backGroundColor = this.exceedAvlColor;
      setTimeout(() => {
        invBatch.requiredQuantity = null;
        invBatch.backGroundColor = this.noColor;
      }, 50);
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.RequiredMoreThanAvailable));
      return;
    }
    // var i = this.packObject.returnFromInvoiceAvailableBatchList.findIndex(inv => inv.transactionId === invBatch.transactionId && inv.batchNo === invBatch.batchNo);
    // if(i > -1) {
    //   this.packObject.returnFromInvoiceAvailableBatchList[i].requiredQuantity = invBatch.requiredQuantity;
    // }
  }

  onInvoiceDialogResult(event) {
    if (event && event.saveCLicked) {
      this.reflectInvoicesQuantityAfterSave();
      this.returnFromInvoiceDialog.Close();
      //promotion => execute first thing
      // var packObj = cloneDeep(this.packObject);
      // // Create temporary packObj to send to recalculatePromotionBenefitForReturns();
      // var packObj = cloneDeep(this.packObject);
      // var packTotal = 0;
      // var obj = cloneDeep(this.selectedBatchObj.batchInvoices);
      // var i = packObj.itemStockBatches.findIndex(b => b.batchNo === this.selectedBatchObj.batchNo);
      // packTotal = packObj.itemStockBatches[i].requiredQty ?
      //   packTotal -= packObj.itemStockBatches[i].requiredQty :
      //   0;
      // packObj.itemStockBatches[i].requiredQty = 0;
      // packObj.itemStockBatches[i] = cloneDeep(this.selectedBatchObj);
      // packObj.itemStockBatches[i].batchInvoices.forEach(inv => {
      //   if(!inv.requiredQuantity) {inv.requiredQuantity = 0;}
      //   packObj.itemStockBatches[i].requiredQty +=  inv.requiredQuantity;
      //   packTotal += inv.requiredQuantity;
      // });
      // if(packTotal) {packObj.requiredQty += packTotal;}

      // packObj.returnFromInvoiceAvailableBatchList.forEach(inv => {
      //   inv.invalid = false;
      // });

      // packObj.batchNo = this.selectedBatchObj.batchNo;
      // packObj.expiryDateModel = this.selectedBatchObj.expiryDateModel;
      // packObj.expiryDatel = this.selectedBatchObj.expiryDateModel.date;
      // packObj.itemStockBatches = [];
      // packObj.returnFromInvoiceAvailableBatchList = obj.filter(x=>x.requiredQuantity && x.requiredQuantity > 0);
      // this.recalculatePromotionBenefitForReturns(packObj);
      //promotion

    }
  }

  reflectInvoicesQuantityAfterSave() {
    var packTotal = 0;

    var obj = cloneDeep(this.selectedBatchObj.batchInvoices);
    // find index of selected batch in packObjBatches
    var i = this.packObject.itemStockBatches.findIndex(b => b.batchNo === this.selectedBatchObj.batchNo &&
      b.expiryDateModel.year === this.selectedBatchObj.expiryDateModel.year &&
      b.expiryDateModel.month === this.selectedBatchObj.expiryDateModel.month &&
      b.expiryDateModel.day === this.selectedBatchObj.expiryDateModel.day);
    // Deduct current batch total from pack total.
    packTotal = this.packObject.itemStockBatches[i].requiredQty ?
      packTotal -= this.packObject.itemStockBatches[i].requiredQty :
      0;
    // recalculate batch total from added invoices.
    this.packObject.itemStockBatches[i].requiredQty = 0;
    this.packObject.itemStockBatches[i] = cloneDeep(this.selectedBatchObj);
    this.packObject.itemStockBatches[i].batchInvoices.forEach(inv => {
      if (!inv.requiredQuantity) { inv.requiredQuantity = 0; }
      this.packObject.itemStockBatches[i].requiredQty += inv.requiredQuantity;
      packTotal += inv.requiredQuantity;
    });
    // Recalculate Pack Total with current batch.
    if (packTotal) { this.packObject.requiredQty += packTotal; }

    this.packObject.returnFromInvoiceAvailableBatchList.forEach(inv => {
      inv.invalid = false;
    });

    // cache itemStockBatches
    this.copyOfItemStockBatches = cloneDeep(this.packObject.itemStockBatches);
  }

  onInvoiceDialogCancel() {
    var originalQtys = cloneDeep(this.copyOfBatchInvoices);
    if (this.selectedBatchObj && this.selectedBatchObj.batchInvoices) {
      this.selectedBatchObj.batchInvoices = originalQtys;
    }
  }

  reflectChangesOnPackObj(batchInvoices) {
    batchInvoices.forEach(inv => {
      var i = this.packObject.returnFromInvoiceAvailableBatchList.findIndex(x =>
        x.transactionId === inv.transactionId &&
        x.batchNo === inv.batchNo &&
        x.expiryDateModel.day === inv.expiryDateModel.day &&
        x.expiryDateModel.month === inv.expiryDateModel.month &&
        x.expiryDateModel.year === inv.expiryDateModel.year
      );
      if (i > -1) {
        this.packObject.returnFromInvoiceAvailableBatchList[i].requiredQuantity = inv.requiredQuantity;
        if (!this.packObject.returnFromInvoiceAvailableBatchList[i].requiredQuantity) { this.packObject.returnFromInvoiceAvailableBatchList[i].requiredQuantity = 0; }
        this.packObject.requiredQty += inv.requiredQuantity;
      }
    });
  }

  deleteReturnBatch(deletedBatch) {
    var idx = this.packObject.itemStockBatches.findIndex(b => b.batchNo === deletedBatch.batchNo &&
      b.expiryDateModel.year === deletedBatch.expiryDateModel.year &&
      b.expiryDateModel.month === deletedBatch.expiryDateModel.month &&
      b.expiryDateModel.day === deletedBatch.expiryDateModel.day);
    if (idx > -1) {
      this.packObject.itemStockBatches[idx].batchInvoices.forEach(inv => {
        inv.requiredQuantity = 0;
      });
      this.packObject.requiredQty -= this.packObject.itemStockBatches[idx].requiredQty;
      this.packObject.itemStockBatches[idx].requiredQty = 0;
    }
  }
}
