import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CoreSession } from '../../../../core/core.session';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { SerialsPropertiesInterface } from '../serials-properties.interface';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeModel } from '../../../models/dateTimeModel/date-time.model';
import { ItemPackModel } from '../../../models/Item/item.model';
import { DialogMode } from '../../../models/enums/dialog-mode.enum';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import * as cloneDeep from "lodash/cloneDeep";
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AddSubSerialsComponent } from '../add-sub-serials/add-sub-serials.component';
import { SharedDialogComponent } from '../../shared-dialog/shared-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-serial-table',
  templateUrl: './serial-table.component.html',
  styleUrls: ['./serial-table.component.css']
})
export class SerialTableComponent implements OnInit {
  //#region [Outputs]
  addedData: any;
  @Output() onAddingSubSerials = new EventEmitter();
  @Output() onDeleteSerial = new EventEmitter();
  @Input() serialProperties: SerialsPropertiesInterface;
  //#endregion
  //#region [Inputs]
  @Input('formProcessMode') formProcessMode: FormProcessMode;
  @Input() addedSerials = [];
  @Input() invalidSerials = [];
  @Input() addedSerialsForAllPacks = [];
  batchNo = '';
  expiryDateModel: DateTimeModel;
  @Output() subSerialAdded: EventEmitter<any> = new EventEmitter();
  @Input() events: Observable<void>;
  saveBtnSubscription: any;
  saveSubject: Subject<void> = new Subject<void>();
  addSubSerial = false;
  parentSerial = '';
  requiredQuantityForSubSerials = 0;
  paddingValue = 28;
  enableBatch = false;
  showSerialsAsRange=true;
  subSerialProperties: SerialsPropertiesInterface;
  isBackToStoreOffload = false;
  form: FormGroup;
  tempSerials = [];
  //mainSequence=0;
  //#endregion
 
  @ViewChild('addSubSerialsDialog', { static: true }) addSubSerialsDialog: SharedDialogComponent;
  //#region [Life Cycle Hooks]
  mainWarehouse = true;

  constructor(public coreSession: CoreSession,
    private translateService: TranslateService,
    public sessionData: SessionDataProvider,
    private itemService: ItemService) {
  }

  ngOnInit() {
    this.saveBtn();
    this.addedData = [];
    this.enableBatch = this.serialProperties.packObj.enableBatchForSerial;// && this.serialProperties.itemFormProcessMode=== FormProcessMode.InputVoucher;
    if (this.addedSerials && this.addedSerials.length > 0)
      this.convertDataSource();

      this.form = new FormGroup({
        mainWarehouse: new FormControl(true),
        })

    if (this.formProcessMode == FormProcessMode.BackToStoreOffload) {
      this.isBackToStoreOffload = true;
    }
  }

  convertDataSource() {


    this.addedData = [];
    if (this.addedSerials && this.addedSerials.length > 0) {
      if (this.isInputVoucher() || this.isAggregate()) {
        this.addedSerials.filter(x => !x.parentSerial || x.parentSerial === '').forEach(serial => {
          let copyOfSerial = cloneDeep(serial);
          copyOfSerial.childs = [];
          copyOfSerial.showDetails = true;
          this.addedData.push(copyOfSerial);
        });
        let todoList = cloneDeep(this.addedSerials);//this.coreSession.cloneObject(this.addedSerials);

        // for (let x = 0; x < this.addedSerials.length; x++) {
        todoList.forEach(r => {
          let parent = this.FindParent(this.addedData, r);
          if (parent) {
            if (!parent.childs) {
              parent.childs = [];
            }
            if (parent.childs.findIndex(child => child.fromSerial === r.fromSerial) === -1) {
              r.showDetails = true;
              parent.childs.push(r);
            }

            todoList = todoList.filter(el => el !== r);
          }
        });
      } else {
        this.addedSerials.forEach(serial => {
          if (this.isBackToStoreOffload) {

            if(!serial.toMainWarehouse && !serial.toHelloJordan)
            {
            if (this.form.get('mainWarehouse').value) {
              serial.toMainWarehouse = true;
              serial.toHelloJordan = false;
            } else {
              serial.toMainWarehouse = false;
              serial.toHelloJordan = true;
            }
          }
          }
          let copyOfSerial = cloneDeep(serial);
          copyOfSerial.childs = [];
          copyOfSerial.showDetails = true;
          this.addedData.push(copyOfSerial);
        });
      }

    }

  }
  FindParent(list, el) {
    if (list && list.length > 0) {
      let res = list.find(x => x.fromSerial === el.parentSerial);
      if (res) {
        return res;
      } else {
        let _res = undefined;

        list.forEach(xchild => {
          if (!_res) {
            _res = this.FindParent(xchild.childs, el);
          }
          if (_res)
            return _res;
        });


        return _res
      }
    } else {
      return undefined;
    }
  }
  ngOnChanges() {

    // if(this.addedSerials)
    // this.convertDataSource();
  }

  subSerialsAdded(data: any) {
    this.onAddingSubSerials.emit(data);

  }
  showSubSerialsDialog(parentSerial: any, subPackObj: any) {
    this.parentSerial = parentSerial.fromSerial;
    this.batchNo = parentSerial.batchNo;
    this.expiryDateModel = parentSerial.expiryDateModel;
    this.requiredQuantityForSubSerials = subPackObj.packQuantityInLevels;

    this.subSerialProperties = this.coreSession.cloneObject(this.serialProperties);
      this.fillPackTemplateSerialIndex(subPackObj.childPack);
    subPackObj.childPack.batchNo = this.batchNo;
    subPackObj.childPack.expiryDateModel = this.expiryDateModel;
    subPackObj.childPack.enableBatchForSerial = this.subSerialProperties.packObj.enableBatchForSerial;
    this.subSerialProperties.packObj = subPackObj.childPack;
    // this.addSubSerial = true;
    this.addSubSerialsDialog.Show(true).then(
      (res) => {
        console.log(res);
      }
    );
  }
    fillPackTemplateSerialIndex(childPack: ItemPackModel) {
        if (childPack.templateId && childPack.templateId > 0) {
            this.coreSession.ModalLoading.Show();
            this.itemService.getFieldsStartAndEndIndexTemplate(childPack).subscribe(
                (response) => {
                    this.coreSession.ModalLoading.Hide();
                    if (response.status != null && response.status >= 0) {
                      childPack.productionIncludedInTemplate = response.data.productionIncludedInTemplate;
                      childPack.totalFieldsLengthOfItemTemplate = response.data.totalFieldsLengthOfItemTemplate;
                      childPack.templateSerialsIndecies = response.data.templateSerialsIndecies;
                      childPack.templateBatchNoIndecies = response.data.templateBatchNoIndecies;
                      childPack.templateExpiryDateIndecies = response.data.templateExpiryDateIndecies;
                      childPack.templateGTINIndecies = response.data.templateGTINIndecies;
                      childPack.templateProductionDateIndecies = response.data.templateProductionDateIndecies;
                        this.checkIfBatchAndExpiryInItemTemplate(childPack);
                    } else {
                        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
                    }
                }, (error: HttpErrorResponse) => {
                    this.coreSession.ModalLoading.Hide();
                    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
                }
            );
        } 
    }
    checkIfBatchAndExpiryInItemTemplate(childPack: ItemPackModel) {
      if (childPack.templateId > 0 && childPack.templateBatchNoIndecies && childPack.templateBatchNoIndecies.length === 2) {
        childPack.batchIncludedInTemplate = true;
        childPack.batchNo = '';
      }
      if (childPack.templateId > 0 && childPack.templateExpiryDateIndecies && childPack.templateExpiryDateIndecies.length === 2) {
        childPack.expiryIncludedInTemplate = true;
        childPack.expiryDateModel = null;
      }
    }
  onSaveSubSerial() {
    //this.saveSubject.next();
    this.addSubSerial = false;
  }
  onClose() {
    this.addSubSerial = false;
  }

  addingSubSerials(data: any, index: number) {
    // this.subSerialAdded.emit(data);

    data.addedSerials.forEach(sub => {
      sub.batchNo = this.batchNo;
      sub.expiryDateModel = this.expiryDateModel;
      this.addedSerials.push(sub);
    });
    // data.invalidSerials.forEach(sub => {
    //   this.invalidSerials.push(sub);
    // });
    // if (data.invalidSerials && data.invalidSerials.length > 0) {

    //   this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Some_Serials_Not_Valid));
    // }
    this.convertDataSource();
  }
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

  saveBtn() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }
  onSaveClicked() {
    this.convertDataSource();

  }
  groupedClicked(item: any) {
    item.showDetails = !item.showDetails;
  }
  removeSerial(item: any) {

    if (!item.childs || item.childs.length === 0) {
      this.coreSession.ModalDialog.ShowMessage(
        this.translateService.instant(ConstantMessages.MsgDeleteConfirmation),
        DialogMode.YesNo,
        this.translateService.instant(ConstantMessages.WarningCaption)
      ).then((result: DialogResult) => {
        if (result === DialogResult.Yes) {
          this.deleteSerial(item.fromSerial);
          this.convertDataSource();
          this.onDeleteSerial.emit(item);
        }
      });
    } else {
      this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.Desc_Confirmation_Delete_Serial_And_Sub), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption))
        .then(
          (result: DialogResult) => {
            if (result === DialogResult.Yes) {
              if (this.isInputVoucher())
                this.removeParentSerials(item.fromSerial);
              else
                this.deleteSerial(item.fromSerial);

          this.convertDataSource();
          this.onDeleteSerial.emit(item);
        }
      });
    }
  }
  removeParentSerials(parentSerial: string) {
    let childSerials = this.addedSerials.filter(serial => serial.parentSerial === parentSerial);
    if (childSerials && childSerials.length > 0) {
      childSerials.forEach(sub => {
        this.removeParentSerials(sub.fromSerial);
      });
      this.deleteSerial(parentSerial);
    } else {
      this.deleteSerial(parentSerial);
    }
  }
  deleteSerial(serial: string) {
    let index = this.addedSerials.findIndex(x => x.fromSerial === serial);
    if (index > -1) {
      this.addedSerials.splice(index, 1);
    }
  }
  onAddSubSerialsClicked($event){
    this.saveSubject.next();
  }
  isInputVoucher() {
    return this.serialProperties.itemFormProcessMode === FormProcessMode.InputVoucher;
  }
  isAggregate() {
    return this.serialProperties.itemFormProcessMode === FormProcessMode.SerialsAggregation;
  }
  getBackGroundColor(sequence){
    if(sequence===1){
      return 'transparent';
    }else{
      return '#E6E6FA';//'#DCDCDC';
    }
  
  }
  getMarginForMinusPlus(sequence:number){
    if(sequence===1){
return '5px';
    }else{
      return ''+ (10*sequence)+'px';
    }
    
  }
  getMarginLeft(sequence){
    
    switch (sequence){
      case 1:
return '-3px';
        break
        case 2:
          return '-16px';
          break;
          case 3:
            return '-30px';
            break;
            case 4:
              return '-42px';
            default:
              return ''+((sequence*-3)-(10*(sequence-1)))+'px';
            break;
    }
  }
  
  getMarginRight(sequence){
    switch (sequence){
      case 1:
        return '-4px';
        break
        case 2:
          return '-8px';
          break;
          case 3:
            return '-10px';
            break;
            case 4:
              return '-14px';
            default:
               return ''+((sequence*-4)+2)+'px';
    }
  }
  getPaddingValue(sequence) {
    return 28 + (7 * (sequence - 1)) + 'px';
  }
  getPaddingValueForAddSub(sequence, packObj: ItemPackModel) {
    let addedValue = 0;
    return 9 * (sequence - 1) + addedValue + 'px';
  }

  checkMainWarehouse(event: any, objData: any) {
    if (objData && objData != undefined && event != undefined) {

      let rowIndex = this.addedSerials.findIndex(x=>x.fromSerial === objData.fromSerial);
      if (event.currentTarget.checked) {
        this.addedSerials[rowIndex].toMainWarehouse = true;
        objData.toMainWarehouse = true;
        this.addedSerials[rowIndex].toHelloJordan = false;
        objData.toHelloJordan = false;
      } else {
        this.addedSerials[rowIndex].toMainWarehouse = false;
        objData.toMainWarehouse = false;
        this.addedSerials[rowIndex].toHelloJordan = true;
        objData.toHelloJordan = true;
      }
    }

  }

  checkHelloJordan(event: any, objData: any) {
    if (objData && objData != undefined && event != undefined) {

      let rowIndex = this.addedSerials.findIndex(x=>x.fromSerial === objData.fromSerial);
      if (event.currentTarget.checked) {
        this.addedSerials[rowIndex].toMainWarehouse = false;
        objData.toMainWarehouse = false;
        this.addedSerials[rowIndex].toHelloJordan = true;
        objData.toHelloJordan = true;
      } else {
        this.addedSerials[rowIndex].toMainWarehouse = true;
        objData.toMainWarehouse = true;
        this.addedSerials[rowIndex].toHelloJordan = false;
        objData.toHelloJordan = false;
      }
    }

  }

  checkAllMainWarehouse() {
    if (this.addedSerials && this.addedSerials.length > 0) {
      this.addedSerials.forEach(serial => {
        serial.toMainWarehouse = true;
        serial.toHelloJordan = false;
      });
      this.convertDataSource();
    }

  }

  checkAllHelloJordan() {
    if (this.addedSerials && this.addedSerials.length > 0) {
      this.addedSerials.forEach(serial => {
        serial.toMainWarehouse = false;
        serial.toHelloJordan = true;
      });
      this.convertDataSource();
    }
  }



  //#endregion

}

