import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SerialValidationErrors } from '../../../models/enums/serial-validation-errors.enum';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { Observable, Subject } from 'rxjs';
import { SerialsPropertiesInterface } from '../serials-properties.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IDateTimePickerProperties } from '../../../models/dateTimeModel/date-time-properties.interface';
import { DateTimeModel } from '../../../models/dateTimeModel/date-time.model';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { SerialsService } from '../serials.service';
import { DatePipe } from '@angular/common';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { ItemPackModel } from '../../../models/Item/item.model';
import { ResponseModel } from '../../../models/api-models/api-models';
import { DialogMode } from '../../../models/enums/dialog-mode.enum';
import { HttpErrorResponse } from '@angular/common/http';
import * as cloneDeep from "lodash/cloneDeep";
import { RowOperation } from '../../../models/enums/shared-table-operation.enum';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
// import { GS1Reader } from '../../../../content/proofs/proofs-review/proofs-review-list/proofs-review-list.component';
@Component({
  selector: 'app-add-items-by-serial',
  templateUrl: './add-items-by-serial.component.html',
  styleUrls: ['./add-items-by-serial.component.css'],
  providers: [DatePipe]
})
export class AddItemsBySerialComponent implements OnInit, OnChanges {


  showSubSerials = false;
  @Input() events: Observable<void>;
  @Input() addedSerialsForAllPacks = [];
  @Input() formMode: FormProcessMode;
  @Input() tempObj: any;
  @Input() serialProperties: SerialsPropertiesInterface;
  @Input() selectedDivisionId: number = -1;
  @Output() onSaveCompleted = new EventEmitter();
  @Output() allAddedSerials = [];
  @ViewChild("toSerialControl", { static: true }) toSerialControl: ElementRef;
  headerString = '';
  saveBtnSubscription: any;
  addSerialCaption = 'Desc_Add';
  saveSubject: Subject<void> = new Subject<void>();
  addSerialForm: FormGroup = new FormGroup({});
  forceSingleSerials = false;
  serialTrimStartLength = 0;
  serialTrimEndLength = 0;
  blockManualInsertionForBatchNo = false;
  addedSerials = [];
  invalidSerials = [];
  temp_addedSerials = [];
  singleSerial = 1;
  rangeSerial = 2;
  isSingle = true;
  isGenerate = false;
  navLinks: any[];
  navBarSelectedIndex: number = 0;
  dateFormat: string;

  addedSerialsDataSourceTable: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  addedSerialsTableData: ITableProperties = {
    pageSize: 10,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    isMultiSelection: false,
    rowOperations: [{
      operation: RowOperation.delete,
      title: 'Desc_Delete',
      icon: 'fa fa-close',
      color: '#f1685e',
    }],
    multiSelectionOperations: [],
    columns: [
      {
        title: 'Desc_Item_Name',
        key: 'itemNameCode',
        isSortable: true,
        width: '33%'
      },
      {
        title: 'Desc_UOM',
        key: 'uom',
        isSortable: true,
        width: '33%',
      },
      {
        title: 'Desc_Serial_No',
        key: 'fromSerial',
        isSortable: true,
        width: '33%',
      }
    ]
  };

  expiryDateProperties: IDateTimePickerProperties = {
    label: 'Desc_ExpiryDate',
    formControlName: 'expiryDate',
    isCalendarOnly: true,
    labelHidden: true
  };
  productionDateProperties: IDateTimePickerProperties = {
    label: 'Desc_ProductionDate',
    formControlName: 'productionDate',
    isCalendarOnly: true,
    labelHidden: true
  };
  productionDate: DateTimeModel = new DateTimeModel();
  desiredExpiryDate: DateTimeModel = new DateTimeModel();
  batchNo: string = '';
  invalidSerialsDataSource: SharedTableResult = {
    data: [],
    totalItems: 0
  };
  tableData: ITableProperties = {
    pageSize: 10,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    isMultiSelection: false,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      { title: 'Desc_Serial_No', key: 'fromSerial', isSortable: false, width: '50%' },
      { title: 'Desc_Error_Caption', key: 'errorText', isSortable: false, width: '50%' },

    ]
  };
  constructor(public coreSession: CoreSession,
    private translateService: TranslateService,
    private sessionData: SessionDataProvider,
    private serialsService: SerialsService,
    private datepipe: DatePipe) { }

  ngOnInit() {
    // console.log(this.serialProperties, "this.serialProperties");
    //console.log(this.serialProperties.packObj, "this.serialProperties.packObj");
    //console.log(this.serialProperties.packObj.addedSerials, "this.serialProperties.packObj.addedSerials");
    if (this.serialProperties
      && this.serialProperties.packObjects
      && this.serialProperties.packObjects.length > 0) {
      this.serialProperties.packObjects.forEach(x => {
        // console.log(x,"adding");
        
        if (x.addedSerials && x.addedSerials.length > 0) {
          //console.log(x.addedSerials, "x.added");
          x.addedSerials.forEach(serial => {
            this.addedSerials.push(serial);
          });
        }
      });

      //console.log(this.addedSerials, "should be adding..");
      this.addedSerialsDataSourceTable = {
        data: this.addedSerials,
        totalItems: this.addedSerials.length
      }

    }
    //console.log("IM HERE KAMAN WITH FORM MODE :D", this.formMode + '---' + this.serialProperties.warehouseId + '---' + this.serialProperties.vehicleId);
    this.saveBtn();
    this.initForm();
    this.fillConfigurationValues();
    this.prepareNavigation();
    this.navLinks[0].isActive = true;

  }
  ngOnChanges() {
    this.fillInvalidDataSource();
  }
  prepareNavigation() {
    this.navLinks = [
      {
        label: 'Desc_Added_Serials',
        index: 0,
        isActive: false,
        hidden: false
      },
      {
        label: 'Desc_Invalid_Serials',
        index: 1,
        isActive: false,
        hidden: false
      }
    ];
  }
  onRowClicked(event: any, type: number) {
    switch (event.operation) {
      case RowOperation.delete://type 1 employee, type 2 customer
      this.onDeleteSerials(event);
      case RowOperation.rowClick:

        break;
    }
  }
  onDeleteSerials(selectedSerial: any) {
    this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgDeleteConfirmation), DialogMode.YesNo
      , this.translateService.instant(ConstantMessages.Delete))
      .then(
        (res: DialogResult) => {
          if (res === DialogResult.Yes) {
            //console.log(this.addedSerials[0].fromSerial, "added serials before delete...");
            //console.log(selectedSerial.object.fromSerial, "selected serial value");
            
            let index = this.addedSerials.findIndex(x => x.fromSerial === selectedSerial.object.fromSerial);
            if(index >= 0){
              this.addedSerials.splice(index, 1);
            }

            this.addedSerialsDataSourceTable = {
              data: this.addedSerials,
              totalItems: this.addedSerials.length
            }
          }
        });
  }
  ChangeDataView(index: number) {
    this.navLinks.forEach(a => a.isActive = false);
    this.navBarSelectedIndex = index;
    this.navLinks[index].isActive = true;
  }
  fillConfigurationValues() {
    this.forceSingleSerials = this.sessionData.getConfigurationValue(ConstantConfigurations.ForceSingleSerials).toLowerCase() === "true";
    this.serialTrimStartLength = +this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTrimStartLength);
    this.serialTrimEndLength = +this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTrimEndLength);
    this.blockManualInsertionForBatchNo = this.sessionData.getConfigurationValue(ConstantConfigurations.BlockManuallyBatchInsertion).toLowerCase() === "true";
    this.dateFormat = this.sessionData.SerialDateFormat;
  }
  initForm() {

    this.headerString = this.translateService.instant('Desc_Add_Serials').toUpperCase();

    this.addSerialForm = new FormGroup({
      inlineRadioOptions: new FormControl('1'),
      fromSerial: new FormControl('', Validators.required),
      toSerial: new FormControl('', (!this.isSingle ? Validators.required : null)),
      reqQuantity: new FormControl('', (this.isGenerate ? Validators.required : null))
    });
    this.onSingleSerialClicked();
  }
  onSingleSerialClicked() {
    this.fromSerialInput.setValue('');
    this.toSerialInput.setValue('');
    this.fromSerialInput.enable();
    this.toSerialInput.disable();

    this.addSerialCaption = 'Desc_Add';
    this.isGenerate = false;
    this.isSingle = true;
  }
  onRangeSerialClicked() {
    this.fromSerialInput.enable({ onlySelf: true, emitEvent: false });
    this.fromSerialInput.setValidators([Validators.required]);
    this.toSerialInput.enable({ onlySelf: true, emitEvent: false });
    this.toSerialInput.setValidators([Validators.required]);

    this.addSerialCaption = 'Desc_Add';
    this.isGenerate = false;
    this.isSingle = false;
  }

  fillInvalidDataSource() {
    this.invalidSerialsDataSource = {
      data: [],
      totalItems: 0
    }
    if (this.invalidSerials && this.invalidSerials.length > 0 && this.navLinks && this.navLinks.length == 2) {
      this.navLinks[1].hidden = false;

      // this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption),this.translateService.instant(ConstantMessages.Desc_Some_Serials_Invalid));
      this.invalidSerials.forEach(serial => {
        serial.errorText = this.serialsService.getErrorText(serial.serialValidationErrors);
      });
      this.invalidSerialsDataSource = {
        data: this.invalidSerials,
        totalItems: this.invalidSerials.length
      }
    }
  }

  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

  saveBtn() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }
  onSaveClicked() {
    this.onSaveCompleted.emit(this.addedSerials);
  }
  isValidParent(packObj: ItemPackModel, parentSerial: String) {
    let isValid = true;
    if (packObj.packQuantityInLevels > 1) {
      let parentSerials = this.addedSerials.filter(serial => serial.parentSerial.toLowerCase() === parentSerial.toLowerCase());
      if (parentSerials && parentSerials.length === packObj.packQuantityInLevels) {
        parentSerials.forEach(serial => {
          if (isValid) {
            isValid = this.isValidParent(packObj.childPack, serial.fromSerial);
          }
        });
      } else {
        isValid = false;
      }

    }
    return isValid;
  }
  isAllSerialsAdded(packObj: ItemPackModel, parentSerial: String) {
    let isValid = true;
    if (packObj.packQuantityInLevels > 1) {
      let parentSerials = this.addedSerials.filter(serial => serial.parentSerial.toLowerCase() === parentSerial.toLowerCase());
      if (parentSerials && parentSerials.length > 0) {
        parentSerials.forEach(serial => {
          if (isValid) {
            let childSerials = this.addedSerials.filter(childSerial => childSerial.parentSerial.toLowerCase() === serial.fromSerial.toLowerCase());
            if (childSerials && childSerials.length === packObj.packQuantityInLevels) {
              isValid = this.isAllSerialsAdded(packObj.childPack, serial.fromSerial);
            } else {
              isValid = false;
            }
          }
        });
      } else {
        isValid = false;
      }

    }
    return isValid;
  }
  clearUI() {
    this.fromSerialInput.setValue(null);
    this.toSerialInput.setValue(null);
    this.fromSerialInput.markAsUntouched();
    this.toSerialInput.markAsUntouched();
    this.fromSerialInput.updateValueAndValidity();
    this.toSerialInput.updateValueAndValidity();
    this.reqQuantityInput.setValue(null);
    this.reqQuantityInput.markAsUntouched();
    this.reqQuantityInput.updateValueAndValidity();
  }
  onEnterClickedFromSerial() {
    // this.addGS1Serial();
    if (this.isSingle) {
      this.onAddSerials();
    } else {
      this.toSerialControl.nativeElement.focus();
      //this.renderer.selectRootElement('#input').focus();
    }
  }

  onAddSerials() {
    this.temp_addedSerials = [];
    this.invalidSerials = [];
    if (this.prepareAndValidateAddedSerialsInUI()) {
      //console.log("first validation : Success");
      //console.log(this.temp_addedSerials, "Serials Check");

      if (this.temp_addedSerials && this.temp_addedSerials.length > 0) {
        //console.log(this.temp_addedSerials, "addedSerials!");
        this.coreSession.ModalLoading.Show();
        this.serialsService.validateSerial(this.temp_addedSerials).subscribe(
          (response: ResponseModel) => {
            //console.log("success after validation");

            this.coreSession.ModalLoading.Hide();
            if (response.status != null && response.status >= 0) {
              this.temp_addedSerials = response.data;
              this.temp_addedSerials.forEach(serial => {
                if (serial.serialValidationErrors === SerialValidationErrors.None) {
                  //console.log("checking parent...");

                  if (this.serialLevelsValidation(serial)) {
                    serial.serialsCount=1;
                    this.addedSerials.push(serial);
                    //console.log(this.addedSerials, "Added Serials");
                    this.addedSerialsDataSourceTable = {
                      data: this.addedSerials,
                      totalItems: this.addedSerials.length
                    }
                  }
                  //console.log("after if statement");

                  this.ChangeDataView(0);
                } else {
                  //console.log("adding invalid serials...");

                  this.invalidSerials.push(serial);
                }
              });
              if (this.invalidSerials && this.invalidSerials.length > 0) {
                this.ChangeDataView(1);
                this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Some_Serials_Not_Valid));
              }

              this.fillInvalidDataSource();
              this.clearUI();
              this.saveSubject.next();

            } else {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
            }
          }, (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
          });
      } else {
        //console.log("second validation : Failed");
        if (this.invalidSerials && this.invalidSerials.length > 0)
          this.ChangeDataView(1);
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_All_Serials_Not_Valid));
        this.fillInvalidDataSource();
      }
    } else {
      //console.log("first validation : Failed");
      if (this.invalidSerials && this.invalidSerials.length > 0)
        this.ChangeDataView(1);
      this.fillInvalidDataSource();

    }
  }

  removeParentSerials(parentSerial: string) {
    let childSerials = this.addedSerials.filter(serial => serial.parentSerial === parentSerial);
    if (childSerials && childSerials.length > 0) {
      childSerials.forEach(sub => {
        this.removeParentSerials(sub.fromSerial);
      });
      this.deleteSerial(parentSerial);
    } else {
      this.deleteSerial(parentSerial);
    }
  }
  deleteSerial(serial: string) {
    let index = this.addedSerials.findIndex(x => x.fromSerial === serial);
    if (index > -1) {
      this.addedSerials.splice(index, 1);
    }
  }

  prepareAndValidateAddedSerialsInUI() {
    let isValid = false;
    if (this.addSerialForm.invalid) {

      this.coreSession.markFormGroupTouched(this.addSerialForm);
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return false;
    }
    if (this.forceSingleSerials) {
      if (this.isSingle) {
        isValid = this.handleSingleSerial();
      } else {
        isValid = this.handleDivideRangeSerial();

      }

    }
    return isValid;
  }
  handleSingleSerial() {
    if (this.fromSerialInput.value.length < (this.serialTrimStartLength + this.serialTrimEndLength)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
      return false;
    }
    var fromSerial = this.fromSerialInput.value.substring(this.serialTrimStartLength, this.fromSerialInput.value.length);
    fromSerial = fromSerial.substring(0, fromSerial.length - this.serialTrimEndLength);
    var fullSerial = fromSerial;
    var temp_serialObj = {
      fromSerial: fromSerial,
      toSerial: fromSerial,
      selectedDivisionId: this.selectedDivisionId,
      parentSerial: '',
      fullSerial: fullSerial,
      serialValidationErrors: SerialValidationErrors.None,
      transactionId: this.serialProperties.transactionId,
      formProcessMode: this.formMode,
      warehouseId: this.serialProperties.warehouseId,
      vehicleId: this.serialProperties.vehicleId,
      price:this.serialProperties.packObj.price,
      childPackPrice:this.serialProperties.packObj.childPackPrice,
      salesTransactionTypeId:this.serialProperties.packObj.salesTransactionTypeId,
      isAddBySerial: true,
      ssccBarcode:this.serialProperties.ssccBarcode
    };

    if (!this.validateBarcodeWithTemplate(fullSerial)) {
      temp_serialObj.serialValidationErrors = SerialValidationErrors.BarcodeNotCompatibleWithTemplate;
      this.invalidSerials.push(temp_serialObj);
      return false;
    } else
      return this.serialLevelsValidation(temp_serialObj);
  }
  handleDivideRangeSerial() {
    let fromSerial = '', toSerial = '', firstFullSerial = '';
    let fromSerialNumberPart = 0, toSerialNumberPart = 0, fromSerialNumberLength = 0, toSerialNumberLength = 0;
    let fromSerialCharPart = '', toSerialCharPart = '';
    if (this.fromSerialInput.value.length != this.toSerialInput.value.length) {
      //console.log("Error 1");

      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Not_Equals));
      return false;
    }
    if (this.fromSerialInput.value.length < (this.serialTrimStartLength + this.serialTrimEndLength)) {
      //console.log("Error 2");
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
      return false;
    }
    //console.log("check 0");
    fromSerial = this.fromSerialInput.value.substring(this.serialTrimStartLength, this.fromSerialInput.value.length);
    fromSerial = fromSerial.substring(0, fromSerial.length - this.serialTrimEndLength);
    firstFullSerial = fromSerial ;
    var fromSerialParts = this.trimSerialFromTemplate(fromSerial);
    fromSerial = fromSerialParts.trimmedSerial;
    //console.log("check 1");

    toSerial = this.toSerialInput.value.substring(this.serialTrimStartLength, this.toSerialInput.value.length);
    toSerial = toSerial.substring(0, toSerial.length - this.serialTrimEndLength);
    var toSerialParts = this.trimSerialFromTemplate(toSerial);
    toSerial = toSerialParts.trimmedSerial;
    //console.log("check 2");
    if ((fromSerialParts.startString !== toSerialParts.startString) || (fromSerialParts.endString !== toSerialParts.endString)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_To_Serial_Template_Fields_Not_Match));
      return false;
    }
    var matches = fromSerial.match(/\d+/g);
    if (matches && matches.length > 0) {
      fromSerialNumberPart = +matches[matches.length - 1];
      fromSerialNumberLength = matches[matches.length - 1].length;
      if (fromSerial.substring(fromSerial.length - fromSerialNumberLength, fromSerial.length) !== matches[matches.length - 1]) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_Not_End_With_Numbers));
        return false;
      }
      fromSerialCharPart = fromSerial.substring(0, fromSerial.length - fromSerialNumberLength);
    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_From_Serial_Not_Contain_Numbers));
      return false;
    }
    //console.log("check 3");
    var matches = toSerial.match(/\d+/g);
    if (matches && matches.length > 0) {
      toSerialNumberPart = +matches[matches.length - 1];
      toSerialNumberLength = matches[matches.length - 1].length;
      if (toSerial.substring(toSerial.length - toSerialNumberLength, toSerial.length) !== matches[matches.length - 1]) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_To_Serial_Not_End_With_Numbers));
        return false;
      }
      toSerialCharPart = toSerial.substring(0, toSerial.length - toSerialNumberLength);

    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_To_Serial_Not_Contain_Numbers));
      return false;
    }
    if (fromSerialNumberLength === toSerialNumberLength && fromSerialCharPart === toSerialCharPart && fromSerialNumberPart <= toSerialNumberPart) {
      for (var i = fromSerialNumberPart; i <= toSerialNumberPart; i++) {
        var temp_serialObj = {
          fromSerial: fromSerialParts.startString + fromSerialCharPart + this.padLeft(i + '', '0', fromSerialNumberLength) + fromSerialParts.endString,
          toSerial: fromSerialParts.startString + fromSerialCharPart + this.padLeft(i + '', '0', fromSerialNumberLength) + fromSerialParts.endString,
          parentSerial: '',
          fullSerial: fromSerialParts.startString + fromSerialCharPart + this.padLeft(i + '', '0', fromSerialNumberLength) + fromSerialParts.endString,
          serialValidationErrors: SerialValidationErrors.None,
          formProcessMode: this.formMode,
          transactionId: this.serialProperties.transactionId,
          warehouseId: this.serialProperties.warehouseId,
          vehicleId: this.serialProperties.vehicleId,
          price:this.serialProperties.packObj.price,
      childPackPrice:this.serialProperties.packObj.childPackPrice,
      salesTransactionTypeId:this.serialProperties.packObj.salesTransactionTypeId,
          isAddBySerial: true,
          selectedDivisionId: this.selectedDivisionId,
          ssccBarcode:this.serialProperties.ssccBarcode
        };
        this.checkIfSerialAlreadyAdded(temp_serialObj);
      }
    }
    //console.log("check 4");
    return true;
  }
  validateGTINInTemplate(fullSerial) {
    return true;
  }
  validateProductionDateInTemplate(fullSerial) {
    return true;
  }
  fillBatchAndExpiryFromTemplate(fullSerial) {
    return true;
  }
  validateBarcodeWithTemplate(fullBarcode) {
    if (!this.fillBatchAndExpiryFromTemplate(fullBarcode)) {
      return false;
    }
    if (!this.validateGTINInTemplate(fullBarcode)) {
      return false;
    }
    if (!this.validateProductionDateInTemplate(fullBarcode)) {
      return false;
    }
    return true;
  }
  getDateAsString(trimmedDate) {
    let returnedDate = '';
    let dateFormat = this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
    if (trimmedDate && trimmedDate.length == dateFormat.length) {
      // returnedDate = trimmedDate.substring(dateFormat.indexOf('dd', 0), dateFormat.indexOf('dd', 0) + 2);
      // returnedDate = returnedDate + '/' + trimmedDate.substring(dateFormat.indexOf('MM', 0), dateFormat.indexOf('MM', 0) + 2);
      // returnedDate = returnedDate + '/' + trimmedDate.substring(dateFormat.indexOf('yyyy', 0), dateFormat.indexOf('yyyy', 0) + 4);
      returnedDate = trimmedDate.toString();
    }
    return returnedDate;
  }
  trimSerialFromTemplate(serial) {
    var trimmedSerial = serial;
    var startString = '';
    var endString = '';
    return {
      trimmedSerial: trimmedSerial,
      startString: startString,
      endString: endString
    };
  }

  serialLevelsValidation(serial) {
    let notFound = true;
    let index = -1;
    //console.log("if parent");
    if (!this.addedSerials || this.addedSerials.length <= 0) {
//      console.log("push 1");
      
      this.temp_addedSerials.push(serial);
      return true;
    }
    if (serial.parentsList && serial.parentsList.length > 0) {

      serial.parentsList.forEach(parent => {
        var index = this.addedSerials.findIndex(x => (x.fromSerial.toLowerCase() === parent.toLowerCase()));
        if (index != -1) {
          notFound = false;
          if (this.addedSerials[index].itemId === serial.itemId)
            serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
          else
            serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBeforeForAnotherItem;
          this.invalidSerials.push(serial);
          return false;
        } else {
//          console.log("push 2");
          this.temp_addedSerials.push(serial);
        }
      });
    } else {
      //console.log("else not parent");
      //console.log(this.addedSerials, "added serials");
      //console.log(serial, "serial");
      //console.log(serial.parentSerial);

      index = this.addedSerials.findIndex(x => x.fromSerial.toLowerCase() === serial.fromSerial.toLowerCase() || (x.parentSerial && x.parentSerial.toLowerCase() === serial.fromSerial.toLowerCase()));
      if (index != -1 && serial.itemId) {
        notFound = false;
        //console.log(this.addedSerials, "check item in added serials..");
        //console.log(index, "index");
        //console.log(serial, "check item in serial..");
        if (this.addedSerials[index].itemId === serial.itemId)
          serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
        else
          serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBeforeForAnotherItem;

        this.invalidSerials.push(serial);
        return false
      } else {
//        console.log("push 3");
        this.temp_addedSerials.push(serial);
      }
    }
    return notFound;
  }

  // addGS1Serial(){
  //   var code = '0100000SM-30043636000123231715011010AB-123';

  //   // Call the constructor from the global scope
  //   //var gs1js = require('gs1js/GS1Reader');
  //   var myReader = new GS1Reader(code);
  //   console.log(myReader.getApplicationIdentifiers(), "app id");
  //   console.log(myReader.getApplicationIdentifierById('01'), "app id");
    
  //   // Read the found AIs
  //   var AIs = myReader.getApplicationIdentifiers();

  //   let itemCode = myReader.getApplicationIdentifierById('01');
  //   let serialNo = myReader.getApplicationIdentifierById('21');
  //   let expiryDate = myReader.getApplicationIdentifierById('17');
  //   let uom = myReader.getApplicationIdentifierById('');
  // }

  checkIfSerialParentNotIsAdded(serial) {
    var notFound = true;
    if (serial.parentsList && serial.parentsList.length > 0) {
      //console.log(serial, "serial");
      //console.log(this.addedSerialsForAllPacks, "addedSerialsForAllPacks");
      //console.log(this.addedSerials, "addedSerials");
      //console.log(this.serialProperties, "serial prop.");

      serial.parentsList.forEach(parent => {
        var index = this.addedSerials.findIndex(x => (x.fromSerial.toLowerCase() === parent.toLowerCase()));
        if (index != -1) {
          notFound = false;
          if (this.addedSerials[index].itemId === serial.itemId)
            serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
          else
            serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBeforeForAnotherItem;
          this.invalidSerials.push(serial);
          return false;
        }
      });
    }
    return notFound;

  }
  checkIfSerialAlreadyAdded(serial) {
    if (this.addedSerials.findIndex(x => x.fromSerial.toLowerCase() === serial.fromSerial.toLowerCase()) === -1) {
      var index = this.addedSerials.findIndex(x => x.fromSerial.toLowerCase() === serial.fromSerial.toLowerCase() || (x.parentSerial && x.parentSerial.toLowerCase() === serial.fromSerial.toLowerCase()));
      if (index === -1) {
//        console.log("push 4");
        this.temp_addedSerials.push(serial);
        return true;
      } else {
        if (this.addedSerials[index].itemId === serial.itemId)
          serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
        else
          serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBeforeForAnotherItem;

        this.invalidSerials.push(serial);
        return false;
      }

    }
    else {
      serial.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
      this.invalidSerials.push(serial);
      return false;
    }
  }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
  }
  onAddingSubSerials(data: any) {
    this.addedSerials.push(data.addedSerials);
    this.invalidSerials.push(data.invalid);

    this.fillInvalidDataSource();
    if (data.invalid && data.invalid.length > 0) {
      this.ChangeDataView(1);
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Some_Serials_Not_Valid));
    }
  }
  onProductionDateSelected(date) {
    this.productionDate = date;
  }
  onDesiredExpiryDateSelected(date) {
    this.desiredExpiryDate = date;
    let batchNoFormat = this.sessionData.getConfigurationValue(ConstantConfigurations.BatchNumberFormat);
    if (batchNoFormat && batchNoFormat != '' && batchNoFormat != "No Format" && batchNoFormat != "NoFormat") {
      this.batchNo = this.datepipe.transform(this.desiredExpiryDate.date, batchNoFormat);
    }
    else {
      this.batchNo = this.datepipe.transform(this.desiredExpiryDate.date, this.coreSession.BatchNoFormat);
    }
    this.batchNoInput.setValue(this.batchNo);
  }
  // get serialNumberInput() {
  //   return this.addSerialForm.get('serialNumber');
  // }
  get fromSerialInput() {
    return this.addSerialForm.get('fromSerial');
  }
  get toSerialInput() {
    return this.addSerialForm.get('toSerial');
  }
  get batchNoInput() {
    return this.addSerialForm.get('batchNo');
  }
  get expiryDateInput() {
    return this.addSerialForm.get('expiryDate');
  }
  get productionDateInput() {
    return this.addSerialForm.get('productionDate');
  }
  get reqQuantityInput() {
    return this.addSerialForm.get('reqQuantity');
  }
}
