import { Injectable, Inject } from '@angular/core';
import { Component, Directive, Pipe, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';



import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from '../../../core/core.session';
import { ResponseModel } from '../../../shared/models/api-models/api-models';

@Injectable({
  providedIn: 'root'
})
export class DisplayUnitService {

  httpOptions;

  private url = '';
  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'DisplayUnit';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  getAllDisplayUnit() {
    const urlValue: string = this.url + '/GetAllDisplayUnit';
    return this.http.get<ResponseModel>(urlValue).map(
      (response) => {
        return response;
      });
  }

  getDisplayUnitType() {
    const urlValue: string = this.url + '/GetAllDisplayUnitType';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }

  saveDisplayUnitType(type: FormData) {
    const urlValue = this.url + "/InsertDisplayUnitType";
    return this.http.post<ResponseModel>(urlValue, type).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return response;
      }
    );
  }

  getDisplayUnitListSharedTable(displayUnitFilter: any) {
    const urlValue: string = this.url + '/GetDisplayUnitListSharedTable';
    return this.http.post(urlValue, JSON.stringify(displayUnitFilter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  getDisplayUnitStatus() {
    const urlValue: string = this.url + '/GetAllDisplayUnitStatus';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }

  // getDisplayUnitTemplateFilter() {
  //   const urlValue: string = this.url + '/GetDisplayUnitTemplateFilter';
  //   return this.http.get<ResponseModel>(urlValue).map(response => {
  //     return response.data;
  //   });
  // }

  getDisplayUnitMasterGroup() {
    const urlValue: string = this.url + '/GetAllDisplayUnitMasterGroup';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }

  getDisplayUnitSubGroup() {
    const urlValue: string = this.url + '/GetAllDisplayUnitSubGroup';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }

  // saveDisplayUnit(displayUnit: any) {
  //   const urlValue: string = this.url + '/SaveDisplayUnit';
  //   return this.http.post(urlValue, JSON.stringify(displayUnit), this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }

  saveDisplayUnit(display: FormData) {
    const urlValue = this.url + "/InsertDisplayUnit";
    return this.http.post<ResponseModel>(urlValue, display).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return response;
      }
    );
  }

  getDisplayUnitListFilter() {
    const urlValue: string = this.url + '/GetDisplayUnitListFilter';
    return this.http.get<ResponseModel>
      (urlValue).map(
        (response) => {
          return response;
        }
      );
  }

  deleteDisplayUnit(displayUnitID: number) {
    const urlValue: string = this.url + '/DeleteDisplayUnit';
    return this.http.post(urlValue, displayUnitID, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  getDisplayUnitData(displayUnitId: number) {
    const urlValue = this.url + '/GetDisplayUnitByID';
    return this.http
      .post<ResponseModel>(urlValue, displayUnitId)
      .map(response => {
        return response;
      });
  }

  saveDisplayUnitStatus(status: any) {
    const urlValue: string = this.url + '/InsertDisplayUnitStatus';
    (urlValue);
    return this.http.post(urlValue, JSON.stringify(status), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  saveDisplayUnitMasterGroup(masterGroup: any) {
    const urlValue: string = this.url + '/InsertDisplayUnitMasterGroup';
    (urlValue);
    return this.http.post(urlValue, JSON.stringify(masterGroup), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  saveDisplayUnitSubGroup(subGroup: any) {
    const urlValue: string = this.url + '/InsertDisplayUnitSubGroup';
    (urlValue);
    return this.http.post(urlValue, JSON.stringify(subGroup), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  // getStockCountingResultSharedTable(stockCountingResultFilter: any) {
  //   const urlValue: string = this.url + '/GetStockCountingResultSharedTable';
  //   return this.http.post(urlValue, JSON.stringify(stockCountingResultFilter), this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }

  // getDisplayUnitTemplateSharedTable(displayUnitTemplateFilter: any) {
  //   const urlValue: string = this.url + '/GetDisplayUnitTemplateSharedTable';
  //   return this.http.post(urlValue, JSON.stringify(displayUnitTemplateFilter), this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }

  // getDisplayUnitTemplateById(templateId: number) {
  //   const urlValue = this.url + '/GetDisplayUnitTemplateByID';
  //   return this.http
  //     .post<ResponseModel>(urlValue, templateId)
  //     .map(response => {
  //       return response;
  //     });
  // }

  // saveDisplayUnitTemplate(template: FormData) {
  //   const urlValue = this.url + "/InsertDisplayUnitTemplate";
  //   return this.http.post<ResponseModel>(urlValue, template).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return response;
  //     }
  //   );
  // }

  // deleteDisplayUnitTemplate(displayUnitID: number) {
  //   const urlValue: string = this.url + '/DeleteDisplayUnitTemplate';
  //   return this.http.post(urlValue, displayUnitID, this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }

}
