import { CoreSession } from './../../../../core/core.session';
import { FormMode } from './../../../models/enums/form-mode.enum';
import { Component, OnInit, ViewChild, Input, ElementRef, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedItemsListFilterComponent } from '../shared-items-list-filter/shared-items-list-filter.component';
import { ItemPackModel } from '../../../models/Item/item.model';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { ISharedItemsProperties } from '../shared-items-properties.interface';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { ResponseModel } from '../../../models/api-models/api-models';
import { DialogMode } from '../../../models/enums/dialog-mode.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { IDateTimePickerProperties } from '../../../models/dateTimeModel/date-time-properties.interface';
import { DateTimeModel } from '../../../models/dateTimeModel/date-time.model';
import { RowOperation } from '../../../models/enums/shared-table-operation.enum';
import { PriceModel } from '../../../models/Item/price.model';
import { GeneralFilter } from '../../../models/general/generalFilter.model';
import { CalculationService } from '../../../services/calculations.service';
import { TotalsModel } from '../../../models/Item/totals.model';
import { SalesTransactionTypes } from '../../../models/enums/sales-transaction-types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-shared-item",
  templateUrl: "./shared-item.component.html",
  styleUrls: ["./shared-item.component.css"],
})
export class SharedItemComponent implements OnInit, OnChanges {
  //#region [DECLARATIONS]

  // Inputs Variables
  @Input("disabled") disabled = false;
  @Input("Totals") totals: TotalsModel;
  @Input("GetPrices") getPrices: boolean;
  @Input("ItemsDetails") itemFormProperties: ISharedItemsProperties = {
    addedItemList: [],
    promotedItemList: [],
    generalFilter: new GeneralFilter(),
  };
  // DOM Components
  @ViewChild("itemsFilter", { static: true })
  itemsFilter: SharedItemsListFilterComponent;
  @ViewChild("searchPanel", { static: true }) searchPanel: ElementRef;
  @ViewChild("quantityInput", { static: true }) quantityInput: ElementRef;

  // Local Variables
  imagePath = "";
  filterString = "";
  public itemDetailsForm: FormGroup;
  public itemsTotalsForm: FormGroup;
  selectedPack: ItemPackModel = new ItemPackModel();
  isPackSelected = false;
  formMode: FormMode = FormMode.Add;
  public btnSubmitItemCaption = "Desc_Add";
  searchFilter = "";
  packIdString = "packId";
  selectedPackName = "";

  // Added Items Table
  listOfDataSources: SharedTableResult[] = [];
  soldItemDataSource: SharedTableResult = {
    totalItems: 0,
    data: [],
  };
  promotedItemDataSource: SharedTableResult = {
    totalItems: 0,
    data: [],
    background: "#ff9e80",
  };
  TabelProperties: ITableProperties = {
    pageSize: 25,
    showPaginator: false,
    showSearch: false,
    isOnline: false,
    rowOperations: [
      {
        operation: RowOperation.delete,
        title: "Desc_Delete",
        icon: "fa fa-close",
        color: "#f1685e",
      },
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_Item_Name",
        key: "itemName",
        isSortable: true,
        width: "20%",
      },
      {
        title: "Desc_Item_Code",
        key: "itemCode",
        isSortable: true,
        width: "20%",
      },
      { title: "Desc_UOM", key: "uom", isSortable: false, width: "15%" },
      {
        title: "Desc_Quantity",
        key: "requiredQty",
        isSortable: false,
        width: "15%",
      },
      { title: "Desc_Price", key: "price", isSortable: false, width: "15%" },
      { title: "Desc_Total", key: "netTotal", isSortable: false, width: "15%" },
    ],
  };
  addedItemsList: ItemPackModel[];
  promotedItemsList: ItemPackModel[];

  defaultBatchAndExpiry = false;
  showExpiryDate = false;
  batchNoModel: string;
  expiryDateModel: DateTimeModel;
  expiryDateProperties: IDateTimePickerProperties = {
    label: "Desc_ExpiryDate",
    formControlName: "expiryDate",
    isCalendarOnly: true,
  };

  packPriceList: PriceModel[];
  numberOfDigits = 2;
  numberOfStockDigits = 2;
  allowEditPrice = true;
  allowEditTax = true;
  allowEditDiscount = true;

  //#endregion

  //#region [INITIALIZATION]

  constructor(
    private itemService: ItemService,
    private coreSession: CoreSession,
    private calculationService: CalculationService,
    private translateService: TranslateService
  ) {}
  ngOnInit() {
    this.updateImageUrl();
    /*  this.numberOfDigits = Number(this.coreSession.getConfigurationValue(ConfigurationConstant.NumberOfDigits));
     this.numberOfStockDigits = Number(this.coreSession.getConfigurationValue(ConfigurationConstant.NumberOfStockDigits));
     (this.numberOfDigits);
     (this.numberOfStockDigits); */
    this.searchPanel.nativeElement.focus();
    this.defaultBatchAndExpiry = true;
    if (
      this.itemFormProperties.generalFilter.formProcessMode ===
        FormProcessMode.Sales ||
      this.itemFormProperties.generalFilter.formProcessMode ===
        FormProcessMode.Orders ||
      this.itemFormProperties.generalFilter.formProcessMode ===
        FormProcessMode.MoveOrder
    ) {
      this.showExpiryDate = false;
    } else {
      this.showExpiryDate = true;
    }
    this.btnSubmitItemCaption = "Desc_Add";
    this.isPackSelected = false;
    this.buildUIControls();
    this.initializeItemsDataSource();
  }
  ngOnChanges() {
    this.initializeItemsDataSource();
    this.searchPanel.nativeElement.focus();
    if (
      this.getPrices &&
      this.itemFormProperties &&
      this.itemFormProperties.generalFilter &&
      this.itemFormProperties.generalFilter.getPrices &&
      this.itemFormProperties.generalFilter.customerId !== undefined &&
      this.itemFormProperties.generalFilter.outletId !== undefined
    ) {
      // Cash Prices from back end if not is view mode
      if (!this.disabled) {
        this.getPriceList();
      }
      this.TabelProperties.columns = [
        {
          title: "Desc_Item_Name",
          key: "itemName",
          isSortable: true,
          width: "20%",
        },
        {
          title: "Desc_Item_Code",
          key: "itemCode",
          isSortable: true,
          width: "20%",
        },
        { title: "Desc_UOM", key: "uom", isSortable: false, width: "15%" },
        {
          title: "Desc_Quantity",
          key: "requiredQty",
          isSortable: false,
          width: "15%",
        },
        { title: "Desc_Price", key: "price", isSortable: false, width: "15%" },
        {
          title: "Desc_Total",
          key: "netTotal",
          isSortable: false,
          width: "15%",
        },
      ];
      if (
        this.itemFormProperties.addedItemList &&
        this.itemFormProperties.addedItemList !== undefined &&
        this.itemFormProperties.addedItemList.length > 0
      ) {
        this.FillTotalsForEditAndViewModes();
      }
    } else {
      this.TabelProperties.columns = [
        {
          title: "Desc_Item_Name",
          key: "itemName",
          isSortable: true,
          width: "30%",
        },
        {
          title: "Desc_Item_Code",
          key: "itemCode",
          isSortable: true,
          width: "30%",
        },
        { title: "Desc_UOM", key: "uom", isSortable: false, width: "20%" },
        {
          title: "Desc_Quantity",
          key: "requiredQty",
          isSortable: false,
          width: "20%",
        },
      ];
    }
  }
  initializeItemsDataSource() {
    this.addedItemsList = this.itemFormProperties.addedItemList;
    this.promotedItemsList = this.itemFormProperties.promotedItemList;
    this.listOfDataSources = [];
    if (this.addedItemsList) {
      this.soldItemDataSource = {
        totalItems: this.addedItemsList.length,
        data: this.addedItemsList,
      };
    }
    if (this.promotedItemsList) {
      this.promotedItemDataSource = {
        totalItems: this.promotedItemsList.length,
        data: this.promotedItemsList,
        background: "#ffd0b0",
      };
    }
    this.listOfDataSources.push(this.soldItemDataSource);
    this.listOfDataSources.push(this.promotedItemDataSource);
  }
  //#endregion

  //#region [METHODS]

  buildUIControls() {
    // Initiate Form Data
    this.expiryDateModel = new DateTimeModel();
    if (this.defaultBatchAndExpiry) {
      this.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
      this.expiryDateModel.day = this.coreSession.DefaultExpiryDate().getDay();
      this.expiryDateModel.month =
        this.coreSession.DefaultExpiryDate().getMonth() + 1;
      this.expiryDateModel.year = this.coreSession
        .DefaultExpiryDate()
        .getFullYear();
      this.batchNoModel = this.coreSession.DefaultBatchNumber;
    }
    this.itemDetailsForm = new FormGroup({
      quantity: new FormControl(0, Validators.required),
      price: new FormControl(0, Validators.required),
      discount: new FormControl(0, Validators.required),
      discountType: new FormControl(1, Validators.required),
      tax: new FormControl(0, Validators.required),
      batchNo: new FormControl(
        {
          value: this.batchNoModel,
          disabled: this.defaultBatchAndExpiry,
        },
        Validators.required
      ),
      expiryDate: new FormControl(
        {
          value: this.expiryDateModel.date,
          disabled: this.defaultBatchAndExpiry,
        },
        Validators.required
      ),
      availableQty: new FormControl({
        value: 0,
        disabled: true,
      }),
      // Item Totals
      // itemNet: new FormControl(0, Validators.required),
      // itemGross: new FormControl(0, Validators.required)
    });

    // Filling Totals form
    this.itemsTotalsForm = new FormGroup({
      netTotal: new FormControl(0, Validators.required),
      grossTotal: new FormControl(0, Validators.required),
      taxTotal: new FormControl(0, Validators.required),
      discountTotal: new FormControl(0, Validators.required),
      itemsCount: new FormControl(0, Validators.required),
    });
  }
  validateUI() {
    if (
      this.selectedPack &&
      this.selectedPack !== undefined &&
      this.selectedPack != null
    ) {
      if (
        this.quantity.value === undefined ||
        this.quantity.value === "" ||
        this.quantity.value == null ||
        parseFloat(this.quantity.value) <= 0
      ) {
        this.quantity.setValue(null);
        this.quantity.markAsTouched();
        this.quantityInput.nativeElement.focus();
        return false;
      }
      const insertedQtyInPCs =
        parseFloat(this.quantity.value) * this.selectedPack.piecesInPack;

      // Check stock QTY
      const LimitBackEndSalesToWarehouseStock = true;
      if (
        this.itemFormProperties.generalFilter.formProcessMode ===
          FormProcessMode.Sales ||
        this.itemFormProperties.generalFilter.formProcessMode ===
          FormProcessMode.Orders
      ) {
        if (parseFloat(this.price.value) <= 0) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(ConstantMessages.InvalidZeroPrice)
          );
          return false;
        }
        if (
          LimitBackEndSalesToWarehouseStock &&
          this.itemFormProperties.generalFilter.formProcessMode ===
            FormProcessMode.Sales &&
          this.selectedPack.usedQtyPCs + insertedQtyInPCs >
            this.selectedPack.availableQtyInPcs
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(
              ConstantMessages.QuantityInsertedExceedStock
            )
          );
          this.quantityInput.nativeElement.focus();
          this.quantityInput.nativeElement.select();
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }
  fillItemUI() {
    if (this.selectedPack && this.selectedPack !== undefined) {
      this.quantityInput.nativeElement.focus();
      this.isPackSelected = true;
      this.imagePath =
        this.coreSession.baseURL +
        "assets/img/items-img/" +
        this.selectedPack.itemId +
        ".png";
      if (this.formMode === FormMode.Add) {
        this.quantity.setValue(
          this.calculationService.getNumberOnStockDigitFormat(
            this.selectedPack.requiredQty
          )
        );
        this.quantityInput.nativeElement.select();
      } else {
        this.quantity.setValue(
          this.calculationService.getNumberOnDigitFormat(
            this.selectedPack.requiredQty
          )
        );
      }

      // get available qty
      this.selectedPack.usedQtyPCs = 0;
      const packsFromSameItem = this.addedItemsList.filter(
        (x) =>
          x.itemId === this.selectedPack.itemId &&
          x.packId !== this.selectedPack.packId
      );
      if (
        packsFromSameItem &&
        packsFromSameItem !== undefined &&
        packsFromSameItem.length > 0
      ) {
        for (let i = 0; i < packsFromSameItem.length; i++) {
          const pack = packsFromSameItem[i];
          this.selectedPack.usedQtyPCs =
            this.selectedPack.usedQtyPCs + pack.requiredQty * pack.piecesInPack;
        }
      }
      let packAvailable = this.selectedPack.availableQtyInPcs;
      if (
        this.itemFormProperties.generalFilter.formProcessMode ==
          FormProcessMode.TransferIn.valueOf() ||
        this.formMode == FormProcessMode.InputVoucher.valueOf()
      ) {
        packAvailable =
          this.selectedPack.availableQtyInPcs / this.selectedPack.piecesInPack;
      } else {
        packAvailable =
          (this.selectedPack.availableQtyInPcs - this.selectedPack.usedQtyPCs) /
          this.selectedPack.piecesInPack;
      }

      this.availableQty.setValue(
        this.calculationService.getNumberOnDigitFormat(packAvailable)
      );
      this.price.setValue(
        this.calculationService.getNumberOnDigitFormat(this.selectedPack.price)
      );
      this.tax.setValue(
        this.calculationService.getNumberOnDigitFormat(this.selectedPack.tax)
      );
      this.discount.setValue(
        this.calculationService.getNumberOnDigitFormat(
          this.selectedPack.discount
        )
      );
      this.discountType.setValue(this.selectedPack.discountTypeId);
      /* this.itemNet.setValue(this.calculationService.getFormattedNumber(this.selectedPack.netTotal, this.numberOfDigits));
      this.itemGross.setValue(this.calculationService.getFormattedNumber(this.selectedPack.grossTotal, this.numberOfDigits)); */

      // If Default Always Set The Batch & Expiry To Default Values
      if (this.defaultBatchAndExpiry) {
        this.selectedPack.batchNo = this.coreSession.DefaultBatchNumber;
        this.selectedPack.expiryDateModel = new DateTimeModel();
        this.fillExpiryDateWhenDefault();
        this.selectedPack.expiryDateModel = this.expiryDateModel;
        this.selectedPack.expiryDateModel.date =
          this.coreSession.DefaultBatchNumber;

        // this.selectedPack.expiryDateModel.month=this.coreSession.DefaultExpiryDate().getMonth()+1;
        // this.selectedPack.expiryDateModel.year=this.coreSession.DefaultExpiryDate().getFullYear();
      }
      this.batchNo.setValue(this.selectedPack.batchNo);
      if (this.selectedPack.expiryDateModel.date) {
        this.expiryDate.setValue(this.selectedPack.expiryDateModel.date);
      } else {
        this.expiryDate.setValue(this.coreSession.DefaultBatchNumber);
      }
      this.selectedPackName = this.itemString(this.selectedPack);
    } else {
      this.updateImageUrl();
    }
  }
  itemString(item: ItemPackModel) {
    return item.itemCode + " - " + item.itemName + " [" + item.uom + "]";
  }
  openSearchModal() {
    this.itemsFilter.Show().then((itemSelected) => {
      this.fillSearchedItem(itemSelected);
    });
  }
  updateImageUrl() {
    this.imagePath = this.coreSession.baseURL + "assets/img/noProduct.jpg";
  }
  clearUI() {
    this.expiryDateModel = new DateTimeModel();
    if (this.defaultBatchAndExpiry) {
      this.fillExpiryDateWhenDefault();
      this.expiryDateProperties.dateValue = this.expiryDateModel;
      this.batchNoModel = this.coreSession.DefaultBatchNumber;
    } else {
      this.batchNoModel = "";
    }
    this.formMode = FormMode.Add;
    this.searchFilter = "";
    this.btnSubmitItemCaption = "Desc_Add";
    this.selectedPack = new ItemPackModel();
    this.isPackSelected = false;
    this.quantity.setValue(0);
    this.price.setValue(0);
    this.discount.setValue(0);
    this.discountType.setValue(1);
    this.tax.setValue(0);
    this.batchNo.setValue(null);
    this.expiryDate.setValue(null);
    this.availableQty.setValue(0);
    /* this.itemNet.setValue(0);
    this.itemGross.setValue(0); */
    this.searchPanel.nativeElement.focus();
  }
  fillExpiryDateWhenDefault() {
    this.expiryDateModel.day = this.coreSession.DefaultExpiryDate().getDay();
    this.expiryDateModel.month =
      this.coreSession.DefaultExpiryDate().getMonth() + 1;
    this.expiryDateModel.year = this.coreSession
      .DefaultExpiryDate()
      .getFullYear();
    this.expiryDateModel.date = new Date(
      this.expiryDateModel.year,
      this.expiryDateModel.month,
      this.expiryDateModel.day
    );
  }
  getSearchedItem() {
    if (this.searchFilter && this.searchFilter.length > 0) {
      /* if (this.disabled) {
        this.localSearch();
      } else { */
      this.onlineSearch();
      // }
    }
  }
  onlineSearch() {
    this.itemFormProperties.generalFilter.customListFilter = {
      pageSize: 1,
      searchFilter: this.searchFilter,
      page: 0,
    };
    this.coreSession.ModalLoading.Show();
    this.itemService
      .getSearchedItem(this.itemFormProperties.generalFilter)
      .subscribe(
        (response: ResponseModel) => {
          this.coreSession.ModalLoading.Hide();
          this.searchFilter = "";
          if (response.status != null && response.status >= 0) {
            if (
              response.data &&
              response.data !== undefined &&
              response.data.packId > 0
            ) {
              this.fillSearchedItem(response.data);
            } else {
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.WarningCaption),
                this.translateService.instant(ConstantMessages.MsgNoItemsFound)
              );
            }
          } else {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              response.message
            );
            this.clearUI();
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
          this.clearUI();
        }
      );
  }
  localSearch() {
    for (const row of this.addedItemsList) {
      if (
        row.itemName
          .toLowerCase()
          .includes(this.searchFilter.toLowerCase().trim()) ||
        row.itemCode
          .toLowerCase()
          .includes(this.searchFilter.toLowerCase().trim()) ||
        row.uom
          .toLowerCase()
          .includes(this.searchFilter.toLowerCase().trim()) ||
        row.divisionName
          .toLowerCase()
          .includes(this.searchFilter.toLowerCase().trim())
      ) {
        this.fillSearchedItem(row);
      } else {
        this.clearUI();
      }
    }
  }

  getPriceList() {
    this.coreSession.ModalLoading.Show();
    this.itemService
      .getPriceList(this.itemFormProperties.generalFilter)
      .subscribe(
        (response: ResponseModel) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.packPriceList = response.data;
          } else {
            this.coreSession.showError(
              this.translateService.instant(ConstantMessages.ErrorCaption),
              response.message
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      );
  }
  fillSearchedItem(foundItem: ItemPackModel) {
    if (foundItem && foundItem !== undefined && foundItem.packId > 0) {
      this.clearUI();
      const index = this.coreSession.GetIndexForList(
        this.addedItemsList,
        this.packIdString,
        foundItem.packId
      );
      if (index !== -1) {
        this.selectedPack = this.coreSession.cloneObject(
          this.addedItemsList[index]
        );
        this.formMode = FormMode.Edit;
        this.btnSubmitItemCaption = "Desc_Edit";
      } else {
        this.formMode = FormMode.Add;
        this.btnSubmitItemCaption = "Desc_Add";
        this.selectedPack = this.coreSession.cloneObject(foundItem);
        //  get price, Tax from cashed price list
        if (this.packPriceList != null && this.packPriceList.length > 0) {
          const priceListIndex = this.packPriceList.findIndex(
            (x) => x.packId === this.selectedPack.packId
          );
          if (priceListIndex > -1) {
            this.selectedPack.price = this.packPriceList[priceListIndex].price;
            this.selectedPack.tax = this.packPriceList[priceListIndex].tax;
            this.selectedPack.basePrice =
              this.packPriceList[priceListIndex].price;
            this.selectedPack.baseTax = this.packPriceList[priceListIndex].tax;
          } else {
            this.selectedPack.price = 0;
            this.selectedPack.tax = 0;
            this.selectedPack.basePrice = 0;
            this.selectedPack.baseTax = 0;
          }
        }
      }
      this.fillItemUI();
    }
  }
  isAddFormMode() {
    return this.formMode === FormMode.Add;
  }

  //#endregion

  //#region [EVENTS]

  onAddClicked() {
    if (this.disabled) return;
    if (
      this.itemDetailsForm.valid &&
      this.isPackSelected &&
      this.validateUI()
    ) {
      const insertedQty = parseFloat(this.quantity.value);
      if (this.formMode === FormMode.Edit) {
        this.selectedPack.isCalculated = false;
        // deduct old pack total from totals
        if (this.getPrices) {
          this.calculationService.updateTotals(
            this.totals,
            this.selectedPack,
            false
          );
        }
      }
      if (this.allowEditPrice) {
        this.selectedPack.price = parseFloat(this.price.value);
      }
      if (this.allowEditTax) {
        this.selectedPack.tax = parseFloat(this.tax.value);
      }
      if (this.allowEditDiscount) {
        this.selectedPack.discountTypeId = this.discountType.value;
        this.selectedPack.discount = parseFloat(this.discount.value);
      }
      this.selectedPack.requiredQty = insertedQty;
      this.selectedPack.batchNo = this.batchNo.value;
      this.selectedPack.expiryDateModel = this.expiryDateModel;
      if (this.getPrices) {
        this.calculationService.calculatePackTotal(this.selectedPack);
      }
      if (
        this.itemFormProperties.generalFilter &&
        (this.itemFormProperties.generalFilter.formProcessMode ===
          FormProcessMode.Sales ||
          this.itemFormProperties.generalFilter.formProcessMode ===
            FormProcessMode.Orders)
      ) {
        this.selectedPack.salesTransactionTypeId = SalesTransactionTypes.Sales;
      }
      if (this.formMode === FormMode.Add) {
        this.addedItemsList.push(
          this.coreSession.cloneObject(this.selectedPack)
        );
      } else {
        const index = this.coreSession.GetIndexForList(
          this.addedItemsList,
          this.packIdString,
          this.selectedPack.packId
        );
        this.addedItemsList[index] = this.coreSession.cloneObject(
          this.selectedPack
        );
        this.itemFormProperties.addedItemList[index] =
          this.coreSession.cloneObject(this.selectedPack);
      }
      if (this.getPrices) {
        this.calculationService.updateTotals(
          this.totals,
          this.selectedPack,
          true
        );
        this.totals.itemsCount = this.calculationService.fillItemsCount(
          this.addedItemsList
        );
        this.FillTotalsUI();
      }
      this.clearUI();
    }
  }
  onClearClicked() {
    this.clearUI();
  }
  onRowClicked(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        this.onItemSelectedForDelete(event.object);
        break;
      case RowOperation.rowClick:
        if (
          event.object.salesTransactionTypeId ===
            SalesTransactionTypes.Promotion ||
          event.object.salesTransactionTypeId === SalesTransactionTypes.FOC
        ) {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.WarningCaption),
            this.translateService.instant(ConstantMessages.MsgCantEditFreeItem)
          );
          return;
        }
        this.onItemSelectedForEdit(event.object);
        break;
    }
  }
  onItemSelectedForEdit(itemSelected: ItemPackModel) {
    this.selectedPack = this.coreSession.cloneObject(itemSelected);
    this.formMode = FormMode.Edit;
    this.btnSubmitItemCaption = "Desc_Edit";
    this.fillItemUI();
  }
  onItemSelectedForDelete(selectedItemPack: ItemPackModel) {
    this.coreSession.ModalDialog.ShowMessage(
      this.translateService.instant(ConstantMessages.MsgDeleteConfirmation) +
        this.itemString(selectedItemPack) +
        "?",
      DialogMode.YesNo
    ).then((res: DialogResult) => {
      if (res === DialogResult.Yes) {
        const index = this.coreSession.GetIndexForList(
          this.addedItemsList,
          this.packIdString,
          selectedItemPack.packId
        );
        this.addedItemsList.splice(index, 1);
        if (this.getPrices) {
          if (this.addedItemsList.length === 0) {
            this.totals = new TotalsModel();
          } else {
            this.selectedPack.usedQtyPCs =
              this.selectedPack.usedQtyPCs -
              this.selectedPack.requiredQty * this.selectedPack.piecesInPack;
            this.calculationService.updateTotals(
              this.totals,
              selectedItemPack,
              false
            );
            this.totals.itemsCount = this.calculationService.fillItemsCount(
              this.addedItemsList
            );
            this.FillTotalsUI();
          }
        }
        if (
          this.selectedPack != null &&
          this.selectedPack !== undefined &&
          this.selectedPack.packId === selectedItemPack.packId &&
          this.selectedPack.batchNo === selectedItemPack.batchNo
        ) {
          this.clearUI();
        }
      }
    });
  }
  onExpiryDateSelected(date) {
    this.expiryDateModel = date;
  }

  FillTotalsUI() {
    if (this.totals && this.totals !== undefined) {
      this.netTotal.setValue(
        this.calculationService.getNumberOnDigitFormat(this.totals.netTotal)
      );
      this.grossTotal.setValue(
        this.calculationService.getNumberOnDigitFormat(this.totals.grossTotal)
      );
      this.taxTotal.setValue(
        this.calculationService.getNumberOnDigitFormat(this.totals.taxTotal)
      );
      this.discountTotal.setValue(
        this.calculationService.getNumberOnDigitFormat(
          this.totals.discountTotal
        )
      );
      this.itemsCount.setValue(
        this.calculationService.getNumberOnDigitFormat(this.totals.itemsCount)
      );
    }
  }

  FillTotalsForEditAndViewModes() {
    this.totals = new TotalsModel();
    this.itemFormProperties.addedItemList.forEach((pack) => {
      this.calculationService.calculatePackTotal(pack);
      this.calculationService.updateTotals(this.totals, pack, true);
      this.totals.itemsCount++;
    });

    if (this.itemFormProperties.promotedItemList.length > 0) {
      this.itemFormProperties.promotedItemList.forEach((pack) => {
        this.calculationService.calculateFreePackTotal(pack);
        this.calculationService.updateTotals(this.totals, pack, true);
        this.totals.itemsCount++;
      });
    }
    this.FillTotalsUI();
  }

  //#endregion

  //#region [PROPERTIES]

  // itemDetailsForm
  get quantity() {
    return this.itemDetailsForm.get("quantity");
  }
  get price() {
    return this.itemDetailsForm.get("price");
  }
  get discount() {
    return this.itemDetailsForm.get("discount");
  }
  get discountType() {
    return this.itemDetailsForm.get("discountType");
  }
  get tax() {
    return this.itemDetailsForm.get("tax");
  }
  get batchNo() {
    return this.itemDetailsForm.get("batchNo");
  }
  get expiryDate() {
    return this.itemDetailsForm.get("expiryDate");
  }
  get availableQty() {
    return this.itemDetailsForm.get("availableQty");
  }
  /* get itemNet() {
    return this.itemDetailsForm.get('itemNet');
  }
  get itemGross() {
    return this.itemDetailsForm.get('itemGross');
  } */

  // itemsTotalsForm
  get netTotal() {
    return this.itemsTotalsForm.get("netTotal");
  }
  get grossTotal() {
    return this.itemsTotalsForm.get("grossTotal");
  }
  get taxTotal() {
    return this.itemsTotalsForm.get("taxTotal");
  }
  get discountTotal() {
    return this.itemsTotalsForm.get("discountTotal");
  }
  get itemsCount() {
    return this.itemsTotalsForm.get("itemsCount");
  }

  //#endregion
}
