import { EventEmitter, Inject, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { ResponseModel } from "../../shared/models/api-models/api-models";
import { ChattingUser } from "../../shared/models/chatting/chatting-user.model";
import { ChattingHistoryModel } from "../../shared/models/chatting/message.model";
import { CoreSession } from "../../core/core.session";
import { ChattingConnectionStatus } from "../../shared/models/enums/chatting/chatting-connection-status.enum";
@Injectable({
  providedIn: "root",
})
export class ChattingService {
  automaticReconnect = false;
  messageReceived = new EventEmitter<ChattingHistoryModel>();
  messageRead = new EventEmitter<ChattingHistoryModel>();
  broadcastReceived = new EventEmitter<ChattingHistoryModel>();
  chattingUsersUpdated = new EventEmitter<ChattingUser>();
  connectionEstablished = new EventEmitter<Boolean>();

  connectionIsEstablished = false;
  _hubConnection: HubConnection;
  connecting: boolean = false;
  selectedChatUserFromNotifications: number = -1;

  currentChattingUser;
  isChattingScreenOpen = new EventEmitter<Boolean>();
  securityGroupPrivilegeChanged = new EventEmitter<string>();
  url = "";
  httpOptions;

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    public coreSession: CoreSession
  ) {
    this.url = baseUrl + "Chatting";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };
  }

  sendMessage(message: ChattingHistoryModel) {
    this._hubConnection.invoke("NewMessage", message);
  }

  sendBroadcast(broadcastList: ChattingHistoryModel[]) {
    this._hubConnection.invoke("NewBroadCast", broadcastList);
  }

  setMessageAsRead(message: ChattingHistoryModel) {
    this._hubConnection.invoke("MessageRead", message);
  }

  createConnection() {
    // console.log('createConnection')
    if (!this._hubConnection) {
      var messageHubURL =
        document.getElementsByTagName("base")[0].href + "MessageHub";
      this._hubConnection = new HubConnectionBuilder()
        .withUrl(messageHubURL)
        .build();
      this._hubConnection.serverTimeoutInMilliseconds = 100000;
      this._hubConnection.keepAliveIntervalInMilliseconds = 50000;
    }
  }

  startConnection(notifyUsers: boolean = false) {
    notifyUsers = notifyUsers ? notifyUsers : false;
    // console.log('startConnection');

    this.connecting = true;
    this._hubConnection
      .start()
      .then(() => {
        // console.log('connection - started')
        //..
        if(notifyUsers) {
          this.notifyChattingUsersWithNewLogin();
        }
        //..
        this.connecting = false;
        this.connectionIsEstablished = true;
        this.automaticReconnect = true;

        this.connectionEstablished.emit(true);
      }, () => {
        this.connecting = false;
        this.connectionIsEstablished = false;
        // this.automaticReconnect = false;
      }
      ).catch((err) => {
        this.connecting = false;
        setTimeout(function () {
          if (this._hubConnection && this._hubConnection.connectionState == 0) { //connectionState == 0 [Disconnected]
            this.startConnection();
          } else {
          }
        }, 5000);
      });
  }
  notifyChattingUsersWithNewLogin(){
    var user: ChattingUser = new ChattingUser();
    user.userId = this.coreSession.CurrentOperator.employeeId;
    user.userName = this.coreSession.CurrentOperator.employeeName;
    user.addRemoveAssignment = false;
    user.isBOUser = true;
    user.status = ChattingConnectionStatus.Online;
    user.chattingConnectionStatusValue =
      ChattingConnectionStatus.Online.valueOf();
    this.updateChattingUsers(
      user,
      this.coreSession.selectedLanguageId
    ).subscribe(
      (response) => {        /*console.log('users notified on login') */  },
      (error: HttpErrorResponse) => {
      }
    );
  }
  registerOnServerEvents() {
    // console.log('registerOnServerEvents')
    this._hubConnection.on("MessageReceived", (data: any) => {
      this.messageReceived.emit(data);
    });

    this._hubConnection.on("MessageRead", (data: any) => {
      this.messageRead.emit(data);
    });

    this._hubConnection.on("BroadCastReceived", (data: any) => {
      this.broadcastReceived.emit(data);
    });

    this._hubConnection.on("UpdateChattingUsers", (data: any) => {
      this.chattingUsersUpdated.emit(data);
    });

    this._hubConnection.onclose((error) => {
      
      // console.log('_hubConnection.onClose')
      this.connectionIsEstablished = false;
      if (this.automaticReconnect && !this.connecting) {
        // console.log('_hubConnection.onClose - resetConnection')

        this.resetConnection();
      }
    });
  }

  unregisterOnServerEvents() {
    // console.log('unregisterOnServerEvents')
    this._hubConnection.off("MessageReceived");
    this._hubConnection.off("MessageRead");

    this._hubConnection.off("BroadCastReceived");

    this._hubConnection.off("UpdateChattingUsers");
    this._hubConnection = null;
  }

  resetConnection() {
    // console.log('resetConnection');
    
    if (this._hubConnection) {
      // console.log('resetConnection- for existing hub');
      if (!this.connectionIsEstablished) {
        this.startConnection();
        // this.registerOnServerEvents();
      }
    } else {
      // console.log('resetConnection- for non-existing hub');
      this.createConnection();
      this.registerOnServerEvents();
      this.startConnection();
    }
  }

  closeConnection(notifyUsers: boolean = false) {
    
    // console.log('closeConnection');
    // return new Promise((resolve, reject) => {
      if (this._hubConnection) {
        this.automaticReconnect = false;
        this._hubConnection
          .stop()
          .then(() => {
            // console.log('connection closed')
            // console.log('automaticReconnect ? ' ,this.automaticReconnect);
            if (this._hubConnection) {
              this.unregisterOnServerEvents();
            }
          })
          .catch(() => { /*console.log('error closing connection')*/ });
      } else {
      }
    // });
    
  }
  setChattingScreenStatus(isOpen: boolean) {
    this.isChattingScreenOpen.emit(isOpen);
  }

  onSecurityGroupPrivilegeChange() {
    this.securityGroupPrivilegeChanged.emit();
  }

  setSelectedChatUserFromNotifications(selectedUserId) {
    this.selectedChatUserFromNotifications = selectedUserId;
  }

  getSelectedChatUserFromNotifications() {
    return this.selectedChatUserFromNotifications;
  }

  setCurrentChattingUser(user) {
    this.currentChattingUser = user;
  }

  public getCurrentChattingUser() {
    return this.currentChattingUser;
  }

  getUploadedFOUsersInChattingAssignment(employeeId: number, languageId: number) {
    let filter = {
      employeeId: employeeId,
      languageId: languageId
    }
    const urlValue = this.url + "/GetUploadedFOUsersInChattingAssignment";
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      return response;
    });
  }

  getAvailableUsersForChatting(employeeId: number, languageId: number) {
    let filter = {
      employeeId: employeeId,
      languageId: languageId
    }
    const urlValue = this.url + "/GetAvailableUsersForChatting";
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      return response;
    });
  }

  getChattingUserData(userId: number, languageId: number) {
    let filter = {
      employeeId: userId,
      languageId: languageId
    }
    const urlValue = this.url + "/GetChattingUserData";
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      return response;
    });
  }

  getChattingHistory(chatInfo, languageId: Number) {
    const urlValue: string = this.url + "/GetChattingHistory";
    return this.http
      .post<ResponseModel>(urlValue + "?languageId=" + languageId, chatInfo)
      .map((response) => {
        return response;
      });
  }

  SendMessage(message, languageId: number) {
    const urlValue: string = this.url + "/ReceiveMessage";
    return this.http
      .post<ResponseModel>(urlValue + "?languageId=" + languageId, message)
      .map((response) => {
        return response;
      });
  }

  SendBroadcast(broadcastList) {
    const urlValue: string = this.url + "/SendBroadcast";
    return this.http
      .post<ResponseModel>(urlValue, broadcastList)
      .map((response) => {
        return response;
      });
  }

  setMessagesAsRead(messages, languageId: number) {
    const urlValue: string = this.url + "/SetMessagesAsRead";
    return this.http
      .post<ResponseModel>(urlValue + "?languageId=" + languageId, messages)
      .map((response) => {
        return response;
      });
  }

  getEmployeeUnreadMessages(employeeId: number, languageId: number) {
    let filter = {
      employeeId: employeeId,
      languageId: languageId
    }
    const urlValue: string = this.url + "/GetEmployeeUnreadMessages";
    return this.http
      .post<ResponseModel>(urlValue, filter)
      .map((response) => {
        return response;
      });
  }

  updateChattingUsers(user, languageId: number) {
    const urlValue: string = this.url + "/InformChattingUsers";
    return this.http
      .post<ResponseModel>(urlValue + "?languageId=" + languageId, user)
      .map((response) => {
        return response;
      });
  }

  checkInactiveEmployeeChattingHistory(
    inactiveUserIds: number[],
    employeeId: number,
    languageId: number
  ) {
    const urlValue: string = this.url + "/CheckInactiveEmployeeChattingHistory";
    return this.http
      .post<ResponseModel>(
        urlValue + "?employeeId=" + employeeId + "&languageId=" + languageId,
        inactiveUserIds
      )
      .map((response) => {
        return response;
      });
  }

  checkForInternetConnection() {
    const urlValue: string = this.url + "/CheckForChattingAvailability";
    return this.http.post<ResponseModel>(urlValue, null).map((response) => {
      return response;
    });
  }

  isConnectionEstablished(): boolean {
    this.connectionIsEstablished = this.connectionIsEstablished
      ? this.connectionIsEstablished
      : false;
    return this.connectionIsEstablished;
  }

//   FIELDS [HubConnection Class Docs]
// Connected	1	
// The hub connection is connected.

// Connecting	2	
// The hub connection is connecting.

// Disconnected	0	
// The hub connection is disconnected.

// Reconnecting	3	
// The hub connection is reconnecting.
}
