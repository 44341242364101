import { Injectable, Inject } from '@angular/core';

import { ResponseModel } from '../../shared/models/api-models/api-models';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs';
import { CoreSession } from '../../core/core.session';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  minimizedEmployeeSidebar = new Subject<boolean>();
  employeeChanged = new Subject();
  employeeId: number;

  private url = '';
  httpOptions;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Employee';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }
  getALLEmployees(organizationIdsVal: string) {
    const urlValue: string = this.url + '/GetOrganizationEmployees';
    return this.http.post<ResponseModel>(urlValue, JSON.stringify(organizationIdsVal), this.httpOptions)
      .map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );

  }

  getEmployeeLoadVehicle(vehicleId: number, organizationId) {
    const urlValue: string = this.url + '/GetEmployeeLoadingVehicle';
    let employeeVehicle = {
      vehicleId: vehicleId,
      organizationId: organizationId
    }
    return this.http.post<ResponseModel>(urlValue, employeeVehicle)
      .map(
        (response) => {
          return <any>response.data;
        }
      );
  }
  getWarehouseStoreKeeper(warehouseId: number, organizationId) {
    const urlValue: string = this.url + '/getWarehouseStoreKeeper';
    let warehouseStoreKeeper = {
      warehouseId: warehouseId ? warehouseId : -1,
      organizationId: organizationId ? organizationId : -1
    }
    return this.http
      .post<ResponseModel>(
        urlValue, warehouseStoreKeeper
      )
      .map(
        (response) => {
          return <any>response.data;
        }
      );
  }
  checkIfEmployeeAccountExist(employeeId: number) {
    const urlValue = this.url + '/CheckEmployeeAccount';
    return this.http.post<ResponseModel>(urlValue, employeeId).map(response => {
      return response;
    });
  }
  getParentMenus() {
    const urlValue: string = this.url + '/GetParentMenus';
    return this.http.get(urlValue).map(
      (response) => {
        let result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getLanding() {
    const urlValue: string = this.url + '/GetLandingMenu';
    return this.http.get(urlValue).map(
      (response) => {
        let result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  uploadDataFromProfile(oldPassword: string, newPassword: string, menuId: string, isAllowToChangePassword: boolean) {
    const urlValue: string = this.url + '/UpdateOperatorDataFromProfile';
    let operatorObj = {
      'oldPassword': oldPassword,
      'newPassword': newPassword,
      'landingMenuId': menuId,
      'allowToChangePassword': isAllowToChangePassword
    };
    return this.http.post<ResponseModel>(urlValue, operatorObj)
      .map((response) => {
        let res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  uploadImage(img: File) {
    const urlValue: string = this.url + '/UploadImage';
    var formData: FormData = new FormData();
    formData.append("image", img, img.name);

    return this.http.post<ResponseModel>(urlValue, formData)
      .map((response) => {
        let res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  getNotConnectedHelpersOrDrivers(employeeFilter: any) {
    const urlValue: string = this.url + '/GetNotConnectedHelpersOrDrivers';
    return this.http.post<ResponseModel>(urlValue, employeeFilter).map(response => {
      return response.data;
    });
  }
  getRegions() {
    const urlValue: string = this.url + '/GetRegions';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }

  getEmployeeTypes() {
    const urlValue: string = this.url + '/GetEmployeeTypes';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }
  getRelatedEmployees(employeeFilter: any) {
    const urlValue: string = this.url + '/GetRelatedEmployees';
    return this.http.post<ResponseModel>(urlValue, employeeFilter).map(response => {
      return response.data;
    });
  }
  saveEmployee(employee: any, isNewEmp: boolean) {
    const urlValue: string = this.url + (isNewEmp ? '/InsertEmployee' : '/UpdateEmployee');
    return this.http.post(urlValue, JSON.stringify(employee), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  validateEmployeeToSave(employee: any) {
    const urlValue: string = this.url + '/ValidateEmployeeToSave';
    return this.http.post(urlValue, JSON.stringify(employee), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getEmployeesList(employeeFilter: any) {
    const urlValue: string = this.url + '/GetEmployeesList';
    return this.http.post(urlValue, JSON.stringify(employeeFilter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  updaEmployeeActiveStatus(filter) {
    const urlValue: string = this.url + '/UpdateEmployeeActiveStatus';
    return this.http.post(urlValue, filter).map(
      (response) => {
        let result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getEmployeeData(employeeIdSignature: any) {
    const urlValue = this.url + '/GetEmployeeData';
    let employee = {
      employeeIdSignature: employeeIdSignature
    }
    return this.http
      .post<ResponseModel>(urlValue, employee)
      .map(response => {
        return response;
      });
  }
  getEmployeeProfileData() {
    const urlValue = this.url + '/GetEmployeeProfileData';
    return this.http
      .get<ResponseModel>(urlValue)
      .map(response => {
        return response;
      });
  }

  getEmployeesFilterData(filter) {
    const urlValue: string = this.url + '/GetEmployeesFilterData';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  getCustomerRouteEmployees(filter) {
    const urlValue: string = this.url + '/GetCustomerRouteEmployees';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  getAllowChangePassword(operatorId: any) {
    const urlValue: string = this.url + '/AllowChangePassword';
    return this.http.post<ResponseModel>(urlValue, operatorId).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }

  setIsMiniMized(minimized) {
    this.minimizedEmployeeSidebar.next(minimized);
  }
  getAllInspectors(organizationIds: string) {
    const urlValue: string = this.url + '/GetAllInspectors';
    let inspector = {
      organizationIds: organizationIds
    }
    return this.http.post<ResponseModel>(urlValue, inspector).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }
  getAllSurveyEmployees(organizationIds: string) {
    const urlValue: string = this.url + '/GetAllSurveyEmployees';
    let surveyEmployee = {
      organizationIds: organizationIds
    }
    return this.http.post<ResponseModel>(urlValue, surveyEmployee).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }
  fillSecurityGroupsList(organizationIds: string) {
    const urlValue: string = this.url + '/FillSecurityGroupsList';
    let securityGroup = {
      organizationIds: organizationIds
    }
    return this.http.post<ResponseModel>(urlValue, securityGroup).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }
  getAllSecurityGroupsList() {
    const urlValue: string = this.url + '/GetAllSecurityGroupsList';
    return this.http.get(urlValue).map(
      (response) => {
        let result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
}
