export class TotalsModel {
    netTotal: number;
    grossTotal: number;
    taxTotal: number;
    // exciseTaxTotal : number;
    retailTaxTotal: number;
    discountTotal: number;
    itemsCount: number;
    promotedDiscountTotal: number;
    itemsQtyTotal: number;
    returnItemQtyTotal: number;

    constructor() {
        this.netTotal = 0;
        this.grossTotal = 0;
        this.taxTotal = 0;
        // this.exciseTaxTotal =0;
        this.retailTaxTotal = 0;
        this.discountTotal = 0;
        this.promotedDiscountTotal = 0;
        this.itemsCount = 0;
        this.itemsQtyTotal = 0;
        this.returnItemQtyTotal = 0;
    }
}
