import { TextLanguage } from "../general/textLanguage.model";

export class EmailTemplateModel {
    public emailTempId: number;
    public emailTypeId: number;
    public name: string;
    public description: string;
    public status: string;
    public serverAddress: string;
    public emailDestination: string;
    public emailPort: number;
    public email: string;
    public password: string;
    public subject: string;
    public body: string;
    public enableSSL: boolean;
    public isActive: boolean;
    public descriptionLanguages: TextLanguage[];
    public subjectLanguages: TextLanguage[];
    public bodyLanguages: TextLanguage[];
    public documentTypeId: number;

    constructor() {
        this.documentTypeId = -1;
        this.emailTempId = -1;
        this.emailTypeId = -1;
        this.emailPort = null;
        this.email = '';
        this.description = '';
        this.password = '';
        this.name = '';
        this.subject = '';
        this.body = '';
        this.serverAddress = '';
        this.emailDestination = '';
        this.enableSSL = false;
        this.isActive = true;
    }
}
export class EmailModel {
    public emailTempId: number;
    public subject: string;
    public body: string;
    public senderEmail: string;
    public toEmail: string;
    constructor() {
        this.emailTempId = -1;
        this.subject = 'Subject';
        this.senderEmail = 'Sender';
        this.toEmail = 'To';
        this.body = 'Body';
    }
}
export class EmailFileModel {
    public index: number;
    public name: string;
    public shortName: string;
    public file: any;
    public path: any;
    public pathFile: any;
    public isImage: boolean;
    constructor() {
    }
}
