import { PaymentTermTypes } from "../enums/payment-term-types.enum";

export class PaymentTermModel {
    paymentTermId:number;
    paymentTermTypeId:PaymentTermTypes;
    paymentTermDescription:string;
    simplePeriodWidth:number;
    simplePeriodId:number;
    complexPeriodWidth:number;
    complexPeriodId:number;
    gracePeriod:number;
    gracePeriodTypeId:number;

}
