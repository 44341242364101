import { Injectable, Inject } from "@angular/core";
import { ResponseModel } from "../shared/models/api-models/api-models";
import { HttpClient } from "@angular/common/http";
import { ConfigurationModel } from "../shared/models/general/configuration.model";
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConstantStorage } from "../shared/models/constants/constant-stroage";
import { MenuModel } from "../shared/models/menu/menu.model";
import { NavBarService } from "./sidebar/sidebar.service";
import { ConstantConfigurations } from '../shared/models/constants/constant-configuration';
import * as crypto from 'crypto';
import { AESEncryptionService } from "../shared/services/EncrDecrService.service";
import { OperatorModel } from "../shared/models/operator/operator.model";

@Injectable({
  providedIn: "root"
})
export class SessionDataProvider {
  private url = "";
  private sessionLanguages: any[] = [];
  public GeneralConfigurations: ConfigurationModel[];
  public DivisionConfigurations: ConfigurationModel[];
  public allMenusAvailable: MenuModel[] = [];
  public addedMenus: MenuModel[] = [];
  languageId = 1;
  public DateFormat = "dd/MM/yyyy";
  public DateTimeFormat = "dd/MM/yyyy hh:mm a";
  public SerialDateFormat = "dd/MM/yyyy";
  public TimeFormat = "hh:mm a";

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private navBar: NavBarService,
    private secService: AESEncryptionService) {
    this.url = baseUrl;
  }

  getSessionLanguages() {
    return this.sessionLanguages;
  }
  getActiveLanguages() {
    return this.sessionLanguages.filter(language => language.active);
  }

  loadSessionData() {
    this.loadSessionLanguges();
  }

  loadSessionLanguges() {
    const urlValue: string = this.url + "Login/GetAllLanguages";
    return new Promise((resolve, reject) => {
      this.http
        .get<ResponseModel>(urlValue)
        .map(response => {
          return response;
        })
        .subscribe(res => {
          this.sessionLanguages = res.data;
          // this.loadSessionConfigurations();
          resolve(true);
        });
    });
  }
  getBaseCurrency() {
    const urlValue = this.url + "Currency/GetBaseCurrency";
    return this.http
      .post<ResponseModel>(urlValue, "")
      .map(response => {
        return response;
      });
  }
  getExternalURLs() {
    const urlValue = this.url + "Login/GetExternalURLs";
    return this.http
      .get<ResponseModel>(urlValue).map(response => {
        return response;
      });
  }

  loadSessionOperatorData() {
    if (this.isAuthenticated()) {
      this.languageId = +localStorage.getItem(ConstantStorage.LanguageId);
      const urlValue: string = this.url + "Employee/FillOperatorData";
      return new Promise((resolve, reject) => {
        this.http
          .post<ResponseModel>(urlValue, this.languageId)
          .map(response => {
            return response;
          })
          .subscribe(res => {
            let encryptedEmployeeId = this.getEncryptedEmployeeIdFromLocalStorage();
            let employeeId = this.getEmployeeIdFromLocalStorage();
            let decryptedData = this.secService.decryptData(res.data, employeeId);
            let decryptedDataModel = JSON.parse(decryptedData);
            res.data = decryptedDataModel;
            var isValid = false;
            if (res && res.data) {
              if (encryptedEmployeeId) {
                if (encryptedEmployeeId === res.data.encryptedEmployeeId) {
                  isValid = true;
                }
              }
            }
            if (isValid) {
              this.GeneralConfigurations = res.data.configurationsList;
              this.DivisionConfigurations = res.data.configurationsListOnDivisionLevel;
              this.addedMenus = res.data.addedList;
              this.allMenusAvailable = res.data.menusList;
              this.navBar.setReloadSidebar();
              if (this.getConfigurationValue(ConstantConfigurations.DateFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateFormat).trim() != '') {
                let format = this.getConfigurationValue(ConstantConfigurations.DateFormat);
                this.DateFormat = format;
              }
              if (this.getConfigurationValue(ConstantConfigurations.DateTimeFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateTimeFormat).trim() != '') {
                let format = this.getConfigurationValue(ConstantConfigurations.DateTimeFormat);
                this.DateTimeFormat = format;
              }
              if (this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat) != null && this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat).trim() != '') {
                let format = this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
                this.SerialDateFormat = format;
              }
              resolve(true);
            }
          });
      });
    }
  }

  loadSessionConfigurationsAfterUpdateConfig() {
    if (this.isAuthenticated()) {
      this.languageId = +localStorage.getItem(ConstantStorage.LanguageId);
      const urlValue: string = this.url + "Employee/FillOperatorConfigurations";
      return new Promise((resolve, reject) => {
        this.http
          .get<ResponseModel>(urlValue)
          .map(response => {
            return response;
          })
          .subscribe(res => {
            let encryptedEmployeeId = this.getEncryptedEmployeeIdFromLocalStorage();
            let employeeId = this.getEmployeeIdFromLocalStorage();
            let decryptedData = this.secService.decryptData(res.data, employeeId);
            let decryptedDataModel = JSON.parse(decryptedData);
            res.data = decryptedDataModel;
            var isValid = false;
            if (res && res.data) {
              if (encryptedEmployeeId) {
                if (encryptedEmployeeId === res.data.encryptedEmployeeId) {
                  isValid = true;
                }
              }
            }
            if (isValid) {
              this.GeneralConfigurations = res.data.configurationsList;
              this.DivisionConfigurations = res.data.configurationsListOnDivisionLevel;
              if (this.getConfigurationValue(ConstantConfigurations.DateFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateFormat).trim() != '') {
                let format = this.getConfigurationValue(ConstantConfigurations.DateFormat);
                this.DateFormat = format;
              }
              if (this.getConfigurationValue(ConstantConfigurations.DateTimeFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateTimeFormat).trim() != '') {
                let format = this.getConfigurationValue(ConstantConfigurations.DateTimeFormat);
                this.DateTimeFormat = format;
              }
              if (this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat) != null && this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat).trim() != '') {
                let format = this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
                this.SerialDateFormat = format;
              }
            }
          });
      });
    }
  }

  getEmployeeIdFromLocalStorage() {
    let employeeId = -1;
    let operator = localStorage.getItem(ConstantStorage.CurrentOperator);
    if (operator) {
      var currentOperator: OperatorModel = JSON.parse(operator);

      if (currentOperator.employeeId >= 0) {
        employeeId = currentOperator.employeeId;
      }
    }
    return employeeId;
  }
  getEncryptedEmployeeIdFromLocalStorage() {
    let encryptedEmployeeId = '';
    let operator = localStorage.getItem(ConstantStorage.CurrentOperator);
    if (operator) {
      var currentOperator: OperatorModel = JSON.parse(operator);
      if (currentOperator.encryptedEmployeeId) {
        encryptedEmployeeId = currentOperator.encryptedEmployeeId;
      }
    }
    return encryptedEmployeeId;
  }
  public getConfigurationValue(keyName: string): string {
    if (this.GeneralConfigurations) {
      const config = this.GeneralConfigurations.find(
        a => a.keyName === keyName
      );
      if (config && config.keyValue) {
        return config.keyValue;
      } else {
        return '';
      }
    } else {
      return "";
    }
  }

  public isAuthenticated(): boolean {
    const jwtHelper = new JwtHelperService();
    const token = localStorage.getItem(ConstantStorage.TokenName);
    return !jwtHelper.isTokenExpired(token);
  }


}
