import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DisplayUnitService } from '../../../../content/master-data-definition/display-unit/display-unit.service';
import { CoreSession } from '../../../../core/core.session';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';

@Component({
  selector: 'app-select-display-unit',
  templateUrl: './select-display-unit.component.html',
  styleUrls: ['./select-display-unit.component.css']
})
export class SelectDisplayUnitComponent implements OnInit {

  @ViewChild('selectDisplayUnitDialog', { static: true }) selectDisplayUnitDialog: any;
  OkResult: DialogResult = DialogResult.Ok;

  @Input() currentId = 'displayUnitSelection';
  @Input() addedDisplayUnitList: any[] = [];
  @Input() oraganizationIds: string = '';
  selectedOraganizationIds: string = '';

  public displayUnitFilterForm: FormGroup;

  sharedTabelData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    isOnline: true,
    showSearch: true,
    // showSearchBtn: true,
    isMultiSelection: true,
    showEditButton: false,
    rowOperations: [],
    multiSelectionOperations: [],
    specificActionWhenCheckAll: true,
    specificActionWhenCheckRow: true,
    columns: [
      { title: 'Desc_Display_Unit', key: 'displayUnitName', isSortable: true, width: '50%' },
      { title: 'Desc_Serial_Number', key: 'serialNumber', isSortable: true, width: '50%' },
    ]
  };

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  displayUnitFilter: any = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    statusID: null,
    typeID: null,
    masterGroupID: null,
    subGroupID: null,
    organizationId: null,
    selectedOraganizationIds: '',
  };

  constructor(
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private displayUnitService: DisplayUnitService) {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }

  ngOnInit() {
    this.initFilterForm();
  }
  ngOnDestroy() {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }

  initFilterForm() {
    this.displayUnitFilterForm = new FormGroup({
      organizationId: new FormControl(),
      displayUnitTypeId: new FormControl(),
      displayUnitStatus: new FormControl(),
      displayUnitMasterGroup: new FormControl(),
      displayUnitSubGroup: new FormControl()
    });
  }

  reflectAddedBeforeDisplayUnit(dataSource) {
    if (dataSource && dataSource.data && dataSource.data.length > 0) {
      dataSource.data.forEach(newDisplayUnit => {
        if (this.addedDisplayUnitList && this.addedDisplayUnitList.length > 0) {
          var index = this.addedDisplayUnitList.findIndex(addedDisplayUnit => addedDisplayUnit.displayUnitID === newDisplayUnit.displayUnitID
            && addedDisplayUnit.serialNumber === newDisplayUnit.serialNumber);
          if (index >= 0) {
            newDisplayUnit.isChecked = true;
            newDisplayUnit = this.addedDisplayUnitList[index];
          }
        }
      })
    }
    this.dataSource = {
      totalItems: dataSource.totalItems,
      data: dataSource.data
    }
  }


  onFindDisplayUnit() {
    this.populateDisplayUnit(null);
  }

  onClearFilters() {
    this.displayUnitFilterForm.reset();
  }

  onCheckRowOperation(displayUnitObj) {
    let index = -1;
    index = this.addedDisplayUnitList.findIndex(addedDisplayUnit => addedDisplayUnit.displayUnitID === displayUnitObj.displayUnitID
      && addedDisplayUnit.serialNumber === displayUnitObj.serialNumber);
    if (displayUnitObj.isChecked) {
      if (index < 0) {
        this.addedDisplayUnitList.push(displayUnitObj);
      }
    } else {
      if (index >= 0) {
        this.addedDisplayUnitList.splice(index, 1);
      }
    }
  }


  onCheckAllOperation(checkAll: boolean) {
    let index = -1;
    this.dataSource.data.forEach(displayUnitObj => {
      index = this.addedDisplayUnitList.findIndex(addedDisplayUnit => addedDisplayUnit.displayUnitID === displayUnitObj.displayUnitID
        && addedDisplayUnit.serialNumber === displayUnitObj.serialNumber);
      if (index >= 0 && !checkAll) {
        this.addedDisplayUnitList.splice(index, 1);
      } else if (index < 0 && checkAll) {
        this.addedDisplayUnitList.push(displayUnitObj);
      }
    })
  }

  onTableFilterChanged(query: any) {
    this.displayUnitFilter.customListFilter = query;
    this.populateDisplayUnit(null);
  }

  public showDisplayUnitDialog(selectedDisplayUnit: any[], organizationIds: any) {
    this.selectedOraganizationIds = organizationIds;
    this.displayUnitFilter.customListFilter = {
      searchFilter: '',
      page: 0,
      pageSize: 100
    };
    this.displayUnitFilterForm.reset();
    this.populateDisplayUnit(selectedDisplayUnit);
    return this.modalService.open(this.selectDisplayUnitDialog, { centered: true, size: 'lg' }).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          return this.addedDisplayUnitList;
        } else {
          return -1;
        }
      });
  }

  populateDisplayUnit(selectedDisplayUnit: any[]) {
    this.displayUnitFilter.statusID = this.displayUnitFilterForm.get('displayUnitStatus').value == null ? -1 : this.displayUnitFilterForm.get('displayUnitStatus').value,
      this.displayUnitFilter.typeID = this.displayUnitFilterForm.get('displayUnitTypeId').value == null ? -1 : this.displayUnitFilterForm.get('displayUnitTypeId').value,
      this.displayUnitFilter.masterGroupID = this.displayUnitFilterForm.get('displayUnitMasterGroup').value == null ? -1 : this.displayUnitFilterForm.get('displayUnitMasterGroup').value,
      this.displayUnitFilter.subGroupID = this.displayUnitFilterForm.get('displayUnitSubGroup').value == null ? -1 : this.displayUnitFilterForm.get('displayUnitSubGroup').value,
      this.displayUnitFilter.organizationId = this.displayUnitFilterForm.get('organizationId').value == null ? -1 : this.displayUnitFilterForm.get('organizationId').value,
      this.displayUnitFilter.selectedOraganizationIds = this.selectedOraganizationIds ? this.selectedOraganizationIds.toString() : '';

    this.coreSession.ModalLoading.Show();
    this.displayUnitService.getDisplayUnitListSharedTable(this.displayUnitFilter).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.dataSource = {
          totalItems: response.data.length,
          data: response.data
        }
        this.reflectAddedBeforeDisplayUnit(response.data);
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
      }
    });

  }
}
