import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";

@Injectable({
  providedIn: "root",
})
export class DivisionService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "Division";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
  AllowToDeleteDivision(divisionId: number) {
    const urlValue = this.url + '/AllowToDeleteDivision';
    return this.http.post<ResponseModel>(urlValue, divisionId).map(response => {
      return response;
    });
  }
  DeleteDivision(divisionId: number, expirationDate: any) {
    const urlValue = this.url + '/DeleteDivision';
    let division = {
      divisionId: divisionId,
      expirationDate: expirationDate
    }
    return this.http.post<ResponseModel>(urlValue, division).map(response => {
      return response;
    });
  }

  getDivisionData(divisionId: number) {
    const urlValue: string = this.url + '/GetDivisionData';
    return this.http.post<ResponseModel>(urlValue, divisionId).pipe(map(
      response => {
        return response;
      }));
  }

  getAllDivisions(selectedEmployeeId) {
    const urlValue: string = this.url + '/GetAllDivisions';
    return this.http.post(urlValue, selectedEmployeeId).pipe(map(
      response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }));
  }

  checkIfDivisionCodeExist(code: string, divisionId: number) {
    const urlValue = this.url + "/CheckDivisionCodeExist";
    let divisionCode = {
      divisionId: divisionId,
      divisionCode: code
    }
    return this.http.post<ResponseModel>(urlValue, divisionCode).pipe(
      map(response => {
        return response;
      })
    );
  }

  editDivision(division: any) {
    const urlValue = this.url + "/UpdateDivision";
    return this.http.post<ResponseModel>(urlValue, division).pipe(
      map(response => {
        return response;
      })
    );
  }

  saveDivision(division: any) {
    const urlValue = this.url + "/InsertDivision";
    return this.http.post<ResponseModel>(urlValue, division).pipe(
      map(response => {
        return response;
      })
    );
  }

  getEmpDivision(filter: any) {
    const urlValue: string = this.url + '/GetDivisions';

    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response.data;
      });
  }
  getDivisionsUnderOrgAccess() {
    return this.getEmployeeDivisions(false);
  }
  getDivisionsUnderEmployeeAccess() {
    return this.getEmployeeDivisions(true);
  }
  getEmployeeDivisions(checkEmployeeAccess: boolean) {
    const urlValue: string = this.url + '/GetDivisionsUnderOrgAccess';
    let employeeDivisons = {
      employeeAccess: checkEmployeeAccess
    }
    return this.http.post<ResponseModel>(urlValue, employeeDivisons).map(response => {
      return <any>response.data;
    });
  }

  toQueryString(obj: any) {
    const parts = [];
    // tslint:disable-next-line:forin
    for (const property in obj) {
      const value = obj[property];
      if (value != null && value !== undefined) {
        parts.push(
          encodeURIComponent(property) + '=' + encodeURIComponent(value)
        );
      }
    }
    return parts.join('&');
  }

}
