import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { CoreSession } from "../../core/core.session";

@Component({
  selector: "app-about-sonic",
  templateUrl: "./about-sonic.component.html",
  styleUrls: ["./about-sonic.component.css"],
})
export class AboutSonicComponent implements OnInit {
  @ViewChild("content", { static: true }) content: any;
  appVersion = "1.0.0.0.0";
  DBVersion = "1.0.0.0";
  currentYear = "";
  backgroundPath = "";

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    public coreSession: CoreSession
  ) {
    config.backdrop = true;
    this.backgroundPath = "url('" + this.coreSession.baseURL + "assets/img/dark_bg.png')";
  }

  ngOnInit() {
    this.appVersion = this.coreSession.cachedGeneralData.assemblyVersionNumber;
    this.DBVersion = this.coreSession.cachedGeneralData.databaseVersion;
    this.currentYear = new Date().getFullYear().toString();
  }

  public Show() {
    const modalOpened = this.modalService.open(this.content, {
      centered: true,
    });
    return modalOpened.result.then(
      (result) => {
        return result;
      },
      (reason) => {
        return undefined;
      }
    );
  }
}
