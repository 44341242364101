import { DateTimeModel } from "../dateTimeModel/date-time.model";

export class ItemStockBatches{
    itemName : string;
    itemCode : string;
    uom : string;
    expiryDateModel : DateTimeModel;
    expiryDateToView : any;
    batchNoToView:any;
    itemId : number;
    packId : number;
    packStatusId : number;
    batchNo : string;
    availableQtyInPcs : number;
    actualAvailableQtyInPcs : number;
    foremanCount : number;
    requiredQty : number;
    isNewBatch : number;
    //added to handle delivery batch selection
    price:number;
    discount:number;
    tax:number;
    priceString:string;
    calculatedTax:number;
    calculatedDiscount:number;
    calculatedDiscountTotal:number;
    calculatedRetailTax:number;
    netTotal:number;
    calculatedPromotedDiscount:number;
    grossTotal:number;
    isCalculated:boolean;
    retailValue:number;
    promotedDiscount:number;
}