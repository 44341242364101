export enum RowOperation {
  delete = 1,
  approve,
  rowClick,
  rowCheck,
  invoiceOrder,
  EmployeeLevel,
  SecurityGroupLevel,
  OrganizationLevel,
  DivisionLevel,
  CustomerLevel,
  ChannelLevel,
  CustomerGroupLevel,
  VisitPattern,
  ViewCustomers,
  Deactivate,
  Activate,
  Enable,
  Disable,
  Configure,
  View,
  delivery,
  partialDelivery,
  Clone,
  Select,
  ManageCustomers,
  Adjust,
  Redeem,
  Close,
  Execute,
  UnSelect,
  Bounce,
  Dispatch,
  Endorse,
  collect,
  Verify,
  edit,
  Reverse,
  Print,
  add,
  Confirm,
  Resend,
  Cancel,
  Offload,
  PartialOffload,
  Process,
  addQtyRange,
  reject,
  sendNotification,
  PostPayment,
  Stock,
  Settle,
  downLoad,
}

export enum MultiSelectionOperation {
  delete = 1,
  approve,
  collect,
  generate,
  add,
  resend,
  PostPayment,
  sendLoadRequest
}
