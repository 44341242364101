import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ITableProperties } from '../../../../shared/models/shared-table/table-properties.interface';
import { CoreSession } from '../../../../core/core.session';
import { ItemService } from '../../../../shared/services/data-definition/product/item.service';
import { ConstantURLs } from '../../../../shared/models/constants/constant-URL';
import { MenuActions } from '../../../../shared/models/enums/menu-actions-enum';
import { RowOperation } from '../../../../shared/models/enums/shared-table-operation.enum';
import { DialogMode } from '../../../../shared/models/enums/dialog-mode.enum';
import { ConstantMessages } from '../../../../shared/models/constants/constant-message';
import { DialogResult } from '../../../../shared/models/enums/dialog-result.enum';
import { SharedTableResult } from '../../../../shared/models/shared-table/shared-table-result.interface';
import { Router } from '@angular/router';
import { ItemListType } from '../../../../shared/models/enums/itemListType';
import { FormGroup, FormControl } from '@angular/forms';
import { IDateTimePickerProperties } from '../../../../shared/models/dateTimeModel/date-time-properties.interface';
import { DateTimeModel } from '../../../../shared/models/dateTimeModel/date-time.model';
import { EmployeeItemListService } from '../employee-item-list.service';
import { CustomerItemListService } from '../customer-item-list.service';
import { CustomerOOSListService } from '../customer-oos-list.service';

@Component({
  selector: 'app-items-lists-list',
  templateUrl: './items-lists-list.component.html',
  styleUrls: ['./items-lists-list.component.css']
})
export class ItemsListsListComponent implements OnInit {
  @Input() oosListMode = false;
  showSlider = false;
  isEditMode = false;
  isViewMode = false;
  fromToDate: DateTimeModel[];
  itemListFilterForm: FormGroup;
  menuURL = "";
  itemListObject: any;
  @Input() TemplateType: number = -1;
  DateModel: any;
  DateProperties: IDateTimePickerProperties = {
    label: 'Desc_Date',
    formControlName: 'Date',
    isCalendarOnly: true,
    isRange: true
  };
  itemListType: number = -1;
  saveSubject: Subject<void> = new Subject<void>();
  TabelData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: false,
    showSearch: true,
    isOnline: false,
    showSearchBtn: true,
    showNewBtn: false,
    rowOperations: [
      // {
      //   operation: RowOperation.delete,
      //   title: "Desc_Delete",
      //   icon: "fa fa-close",
      //   color: "#f1685e"
      // }
    ],
    multiSelectionOperations: [],
    columns: []
  };
  itemListFilter: any = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: 15
    },
    customerId: -1,
    outletId: -1,
    groupId: -1,
    employeeId: -1,
    organizationId: -1,
    fromToDate: [],
    OOSMode: false
  };
  constructor(private coreSession: CoreSession,
    private itemService: ItemService,
    private employeeItemListService: EmployeeItemListService,
    private customerItemListService: CustomerItemListService,
    private customerOOSListService: CustomerOOSListService,
    private translateService: TranslateService,
    private router: Router) { }

  ngOnInit() {

    this.initFilterForm();

    // the piece of code Will Execute When oosListMode = true
    // this cutsomize for (OOS List) screen
    if (this.oosListMode) {
      this.coreSession.SetTitle('OOS List');
      this.TabelData.columns = [];
      this.TabelData.columns.push({ title: "Desc_Item_List_Code", key: "itemListCode", isSortable: true, width: "20%" });
      this.TabelData.columns.push({ title: "Desc_Item_List_Name", key: "itemListName", isSortable: true, width: "20%" });
      this.TabelData.columns.push({ title: 'Desc_Start_Date', key: 'startDate', isExpiryDate: true, isSortable: true, width: '20%' });
      this.TabelData.columns.push({ title: 'Desc_End_Date', key: 'endDate', isExpiryDate: true, isSortable: true, width: '20%' });
      this.TabelData.columns.push({ title: 'Desc_Organization', key: 'organizationCodeName', isSortable: true, width: '20%' });

      this.menuURL = ConstantURLs.CustomerOosListURL;
      this.TabelData.showNewBtn = this.coreSession.checkActivitiesAvailability(this.menuURL, MenuActions.Add);
      this.itemListType = ItemListType.ItemListCustomer.valueOf();
      this.populateItemsLists(this.itemListType);
      this.addActions();

    } else {
      this.coreSession.SetTitle('Items List');
      if (this.router.url.includes(ConstantURLs.employeeItemsListURL)) {
        this.itemListType = ItemListType.ItemListEmployee.valueOf();
        this.TabelData.columns = [];
        this.TabelData.columns.push({ title: "Desc_Item_List_Code", key: "itemListCode", isSortable: true, width: "50%" });
        this.TabelData.columns.push({ title: "Desc_Item_List_Name", key: "itemListName", isSortable: true, width: "50%" });
        this.menuURL = ConstantURLs.employeeItemsListURL;
      }
      else if (this.router.url.includes(ConstantURLs.customerItemsListURL)) {
        this.itemListType = ItemListType.ItemListCustomer.valueOf();
        this.TabelData.columns = [];
        this.TabelData.columns.push({ title: "Desc_Item_List_Code", key: "itemListCode", isSortable: true, width: "30%" });
        this.TabelData.columns.push({ title: "Desc_Item_List_Name", key: "itemListName", isSortable: true, width: "30%" });
        this.TabelData.columns.push({ title: 'Desc_Organization', key: 'organizationCodeName', isSortable: true, width: '30%' });
        this.menuURL = ConstantURLs.customerItemsListURL;
      }
      this.TabelData.showNewBtn = this.coreSession.checkActivitiesAvailability(this.menuURL, MenuActions.Add);
      this.populateItemsLists(this.itemListType);
      this.addActions();

    }
  }

  initFilterForm() {
    this.itemListFilterForm = new FormGroup({
      employeeId: new FormControl(),
      customerId: new FormControl(),
      outletId: new FormControl(),
      customerGroupId: new FormControl(),
      Date: new FormControl([null, null]),
      organizationId: new FormControl(),
    })
  }
  onReset() {
    this.itemListFilterForm.reset();
    this.itemListFilter.fromToDate = [];
  }
  onFindButtonClicked() {
    this.itemListFilter.customListFilter.page = 0;
    this.populateItemsLists(this.itemListType);
  }
  populateItemsLists(itemListType) {
    this.itemListFilter.employeeId = this.itemListFilterForm.get('employeeId').value == null ? -1 : this.itemListFilterForm.get('employeeId').value;
    this.itemListFilter.customerId = this.itemListFilterForm.get('customerId').value == null ? -1 : this.itemListFilterForm.get('customerId').value;
    this.itemListFilter.outletId = this.itemListFilterForm.get('outletId').value == null ? -1 : this.itemListFilterForm.get('outletId').value;
    this.itemListFilter.groupId = this.itemListFilterForm.get('customerGroupId').value == null ? -1 : this.itemListFilterForm.get('customerGroupId').value;
    this.itemListFilter.organizationId = this.itemListFilterForm.get('organizationId').value == null ? -1 : this.itemListFilterForm.get('organizationId').value;
    if (this.oosListMode) {
      this.itemListFilter.OOSMode = true;
      // this.itemListFilter.Date = this.itemListFilterForm.get('Date').value == null ? -1 : this.itemListFilterForm.get('Date').value;
    }
    this.coreSession.ModalLoading.Show();
    this.itemService.getItemsLists(this.itemListFilter, itemListType).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.dataSource = {
            data: response.data,
            totalItems: response.data.length
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      });
  }


  addActions() {

    if (this.coreSession.checkActivitiesAvailability(this.menuURL, MenuActions.Delete)) {
      this.TabelData.rowOperations.push(
        {
          operation: RowOperation.delete,
          title: 'Desc_Delete',
          icon: 'fa fa-close',
          color: '#f1685e'
        });
    }

    if (this.coreSession.checkActivitiesAvailability(this.menuURL, MenuActions.Edit)) {
      this.TabelData.rowOperations.push({
        operation: RowOperation.edit,
        title: "Desc_Edit",
        icon: "fa fa-pencil",
        color: "#12344d"
      });
    }
    if (this.coreSession.checkActivitiesAvailability(this.menuURL, MenuActions.View)) {
      this.TabelData.rowOperations.push(
        {
          operation: RowOperation.View,
          title: "Desc_View",
          icon: "fa fa-eye",
          color: "#12344d"
        });
    }

  }

  onDeleteSuccessResult(response: any) {
    this.coreSession.ModalLoading.Hide();
    if (response.status != null && response.status >= 0) {
      this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), this.translateService.instant(ConstantMessages.MsgDeletedSuccessfully));
      this.populateItemsLists(this.itemListType);
    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
    }
  }

  onDeleteFailureResult(error: any) {
    this.coreSession.ModalLoading.Hide();
    this.coreSession.showError("", ConstantMessages.ErrorHappened);
  }

  onDeleteClicked(event: any) {
    this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgDeleteConfirmation), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
      (result: DialogResult) => {
        if (result === DialogResult.Yes) {
          this.coreSession.ModalLoading.Show();
          if (this.itemListType === ItemListType.ItemListCustomer) {
            if (this.oosListMode) {
              this.customerOOSListService
                .deleteItemList(event.object.itemListId, this.itemListType, this.oosListMode)//here
                .subscribe(
                  response => {
                    this.onDeleteSuccessResult(response);
                  },
                  error => {
                    this.onDeleteFailureResult(error);
                  }
                );
            } else {
              this.customerItemListService
                .deleteItemList(event.object.itemListId, this.itemListType, this.oosListMode)//here
                .subscribe(
                  response => {
                    this.onDeleteSuccessResult(response);
                  },
                  error => {
                    this.onDeleteFailureResult(error);
                  }
                );
            }
          } else {
            this.employeeItemListService
              .deleteItemList(event.object.itemListId, this.itemListType, this.oosListMode)//here
              .subscribe(
                response => {
                  this.onDeleteSuccessResult(response);
                },
                error => {
                  this.onDeleteFailureResult(error);
                }
              );
          }

        }
      });
  }

  onRowOperation(event: any) {

    switch (event.operation) {
      case RowOperation.edit:
        if (this.coreSession.checkActivitiesAvailability(this.menuURL, MenuActions.Edit)) {
          this.getItemListData(event.object, this.itemListType, this.oosListMode);
          this.isViewMode = false;
        }
        break;
      case RowOperation.delete:
        this.onDeleteClicked(event);
        break;
      case RowOperation.View:
        if (this.coreSession.checkActivitiesAvailability(this.menuURL, MenuActions.View)) {
          this.getItemListData(event.object, this.itemListType, this.oosListMode);
          this.isViewMode = true;
        }
        break;

    }
  }

  getItemListData(itemList: any, itemListType: number, oosListMode: boolean) {
    this.coreSession.ModalLoading.Show();
    this.itemService.getItemsListData(itemList.itemListId, itemListType, oosListMode).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.itemListObject = response.data;
          this.itemListObject.itemListName = itemList.itemListName;
          this.itemListObject.itemListCode = itemList.itemListCode;
          this.itemListObject.itemListId = itemList.itemListId;
          this.itemListObject.itemListType = itemList.itemListType;
          this.itemListObject.startDate = itemList.startDate;
          this.itemListObject.endDate = itemList.endDate;
          this.itemListObject.languageDescriptions = response.data.languageDescriptions;
          this.itemListObject.appliedChannels = response.data.appliedChannels;
          this.itemListObject.appliedGroups = response.data.appliedGroups;
          this.itemListObject.appliedEmployees = response.data.appliedEmployees;
          this.itemListObject.appliedCustomers = !response.data.appliedCustomers ? [] : response.data.appliedCustomers;
          this.itemListObject.appliedSecurityGroups = !response.data.appliedSecurityGroups ? [] : response.data.appliedSecurityGroups;
          this.itemListObject.items = response.data.items;
          this.itemListObject.allCustomers = response.data.allCustomers;
          this.itemListObject.allEmployees = response.data.allEmployees;
          this.itemListObject.allItems = response.data.allItems;
          this.itemListObject.organizationsList = response.data.organizationsList;
          this.isEditMode = true;
          this.showSlider = true;
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }

  openSliderToEdit(itemList: any) {
    this.isEditMode = true;
    this.showSlider = true;
    this.isViewMode = false;
    this.itemListObject = itemList;
  }

  afterSaveItem() {
    this.closeSlider();
    this.populateItemsLists(this.itemListType);
  }

  openSliderToAdd() {
    this.isEditMode = false;
    this.showSlider = true;
    this.isViewMode = false;
    this.itemListObject = undefined;
  }

  onSaveClick() {
    this.saveSubject.next();
  }

  closeSlider() {
    this.showSlider = false;
  }

  onTransactionDateSelected(date) {
    this.itemListFilter.fromToDate = date;
  }

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

}
