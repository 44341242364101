import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-side-filter-template',
  templateUrl: './side-filter-template.component.html',
  styleUrls: ['./side-filter-template.component.css']
})
export class SideFilterTemplateComponent implements OnInit {

  @Input('bodyTemplet') bodyTemplet: TemplateRef<any>;
  @Output('onResetClicked') onResetClicked = new EventEmitter();
  @Output('onFindClicked') onFindClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onFindClick() {
    this.onFindClicked.emit();
  }

  onReset() {
    this.onResetClicked.emit();
  }

}
