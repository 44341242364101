import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CoreSession } from '../../../../core/core.session';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { ItemService } from '../../../services/data-definition/product/item.service';

@Component({
  selector: 'app-view-items',
  templateUrl: './view-items.component.html',
  styleUrls: ['./view-items.component.css']
})
export class ViewItemsComponent implements OnInit {


  @ViewChild('viewItemsDialog', { static: true }) viewItemsDialog: any;
  OkResult: DialogResult = DialogResult.Ok;
  @Input() isFillPacks: false;
  @Input() addedItemsList: any[] = [];
  query: any;
  divisionId = -1;
  labelItems = "";


  public itemFilterForm: FormGroup;

  sharedTabelData: ITableProperties = {
    pageSize: 50,
    showPaginator: true,
    isOnline: false,
    showSearch: true,
    // showSearchBtn: true,
    isMultiSelection: false,
    showEditButton: false,
    rowOperations: [],
    multiSelectionOperations: [],
    specificActionWhenCheckAll: true,
    specificActionWhenCheckRow: true,
    columns: [

    ]
  };
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  itemFilter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    divisionId: this.divisionId,
    itemCategoryId: -1,
    brandId: -1,
    itemIdsList: [],
    excludedItemIdsList: [],
    itemGroupMode: false,
    selectedEmployeeId: -1,
    isContractedFoc: false
  };
  refreshTable = false;
  constructor(
    private coreSession: CoreSession,
    private itemService: ItemService,
    private translateService: TranslateService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private sessionData: SessionDataProvider) {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }
  ngOnInit() {

    if (this.isFillPacks) {
      this.sharedTabelData.columns.push({ title: 'Desc_Packs', key: 'itemCodeName', isSortable: true, width: '90%' })
      this.labelItems = 'Desc_Select_Pack';
    } else {
      this.sharedTabelData.columns.push({ title: 'Desc_Items', key: 'itemCodeName', isSortable: true, width: '90%' })
      this.labelItems = 'Desc_Selected_Items';
    }
  }
  ngOnDestroy() {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }

  public showDialog() {
    this.coreSession.SetTitle('Select Items');
    this.dataSource = {
      data: this.addedItemsList,
      totalItems: this.addedItemsList.length
    }
    return this.modalService.open(this.viewItemsDialog, { centered: true, size: 'lg' }).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          return this.addedItemsList;
        } else {
          return -1;
        }
      });
  }
}
