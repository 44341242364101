import { Directive, HostListener, Input } from "@angular/core";
import { SessionDataProvider } from "../../core/session-data-provider.service";
import { ConstantConfigurations } from "../models/constants/constant-configuration";

@Directive({
  selector: "[numberOfStockDigits]"
})
export class NumberOfStockDigits {
  @Input() isPromotionsPrerequisite: boolean = false;

  maxlength : number = 9;
  constructor(private data: SessionDataProvider) {}

  numberOfDigits = +this.data.getConfigurationValue(
    ConstantConfigurations.NumberOfStockDigits
  );

  allowFractionsInQuantity = this.data.getConfigurationValue(
    ConstantConfigurations.AllowFractionsInQuantity
    ).toLowerCase() === "true";

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }
  @HostListener("keydown", ["$event"]) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    if ((this.numberOfDigits === 0 || (!this.allowFractionsInQuantity && !this.isPromotionsPrerequisite))
    && e.key === "."  ) {
      return false;
    }
    // Prevent Multiple [.] in Numbers
    const index = event.srcElement.value.indexOf(".");
    const listValue = event.srcElement.value.toString().split(".");
    if(listValue.length == 2){
      this.maxlength += this.numberOfDigits;
    }

    if (e.key === "." && index > -1) {
      return false;
    }
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Check Number Of Digit Format ex--> Number of Digit is 2 not 3
    if (
      index > -1 &&
      event.srcElement.value.length - index > this.numberOfDigits
    ) {
      return false;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }

    if(this.numberOfDigits <= 0){ // numberOfDigits equal zero
      if(event.srcElement.value.toString().length >= this.maxlength){
        return false;
      }
     }else{ // numberOfDigits > zero
       if(index != -1){// if contain [.]
        this.maxlength += this.numberOfDigits;
        if(listValue[0].length >= this.maxlength){
          return false;
         }
       }else{// if not contain [.]
          this.maxlength = 8;
          if(event.srcElement.value.toString().length >= this.maxlength){
            return false;
          }
       }
     }
  }
}
