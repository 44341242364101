import { CoreSession } from "../../../../core/core.session";
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ResponseModel } from "../../../models/api-models/api-models";
import { GeneralFilter } from "../../../models/general/generalFilter.model";
import { Subject, Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { SalesRestrictionModel } from "../../../models/salesRestriction/sales-restriction.model";
import { SalesRestrictionFilters } from '../../../models/salesRestriction/sales-restriction-filter.model';
import { ItemPackModel } from '../../../models/Item/item.model';

@Injectable({
  providedIn: "root"
})
export class ItemService {

  minimizedItemSidebar = new Subject<boolean>();

  private url = "";
  private httpOptions;
  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.url = baseUrl + "Item";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token"
      })
    };
  }
  getPriceList(filter: GeneralFilter) {
    const urlValue: string = this.url + "/GetPriceList";
    return this.http
      .post<ResponseModel>(
        urlValue, filter)
      .map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  getAllItems(itemsFilter: GeneralFilter) {
    const urlValue: string = this.url + "/GetAllItems";
    return this.http.post<ResponseModel>(
      urlValue, itemsFilter).map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  getAllItemsLazyLoading(filter: any) {
    const urlValue: string = this.url + '/GetAllItemsLazyLoading';
    return this.http.post<ResponseModel>(urlValue, filter);
  }


  GetAllItemsWeb(itemFilter: any) {
    const urlValue: string = this.url + '/GetAllItemsWeb';
    (urlValue);
    return this.http.post(urlValue, JSON.stringify(itemFilter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getSearchedItem(itemsFilter: GeneralFilter) {
    const urlValue: string = this.url + "/GetSearchedItem";
    return this.http
      .post<ResponseModel>(
        urlValue, itemsFilter)
      .map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }
  fillItemsImages() {
    const urlValue: string = this.url + "/FillItemImages";
    return this.http.post(urlValue, null).map(response => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  setIsMiniMized(minimized) {
    this.minimizedItemSidebar.next(minimized);
  }

  getAllActiveItems() {
    const urlValue: string = this.url + "/GetAllActiveItems";
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }

  // New Items APIs By Hijazi
  getAllItemsNew(filter: any) {
    const urlValue = this.url + "/GetAllItemsNew";
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      })
  }
  initiateSearchList() {
    const urlValue = this.url + "/InitiateSearchList";
    return this.http.get<ResponseModel>(urlValue)
      .map(response => {
        return response;
      })
  }
  GetAllPacks() {
    const urlValue = this.url + "/GetAllPacks";
    return this.http.get<ResponseModel>(urlValue)
      .map(response => {
        return response;
      })
  }


  getGroupPacks(groupId: any) {
    const urlValue = this.url + "/getGroupPacks";
    return this.http.post<ResponseModel>(urlValue, groupId)
      .map(response => {
        return response;
      })
  }
  getPacksHierarchy(pack: ItemPackModel, customerId: number, outletId: number) {
    const urlValue = this.url + "/GetPacksHierarchy";
    let filter = {
      piecesInPack: pack.piecesInPack,
      itemId: pack.itemId,
      customerId: customerId,
      outletId: outletId
    }
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      });
  }
  getFieldsStartAndEndIndexTemplate(pack: ItemPackModel) {
    const urlValue = this.url + "/GetFieldsStartAndEndIndexTemplate";
    let templateId = pack.templateId;
    return this.http.post<ResponseModel>(urlValue, templateId)
      .map(response => {
        return response;
      });
  }
  getDivisionCategories(divisionId: number) {
    const urlValue: string = this.url + '/GetDivisionCategories';
    return this.http.post<ResponseModel>(urlValue, divisionId).pipe(map(
      response => {

        return response;
      }));
  }



  getItemsLists(filter: any, itemListType: number) {
    const urlValue: string = this.url + '/getItemsLists';
    let item = {
      filter: filter,
      itemListTypeId: itemListType
    }
    return this.http.post<ResponseModel>(urlValue, item).pipe(map(
      response => {
        return response;
      }));
  }

  // deleteItemList(itemListId: any , itemListType : number,oosListMode:boolean) {
  //   const urlValue = this.url + "/DeleteItemList?itemListId=" + itemListId +"&ItemListType=" + itemListType+"&oosListMode="+ oosListMode;
  //   return this.http.get<ResponseModel>(urlValue).pipe(
  //     map(response => {
  //       return response;
  //     })
  //   );
  // }
  getItemsListData(itemListId: number, itemListType: number, oosListMode: boolean) {
    const urlValue: string = this.url + "/getItemsListData";
    let itemData = {
      itemListId: itemListId,
      itemListTypeId: itemListType,
      oOSListMode: oosListMode
    }
    return this.http.post<ResponseModel>(urlValue, itemData).pipe(map(
      response => {
        return response;
      }));
  }

  getAllEmployeesForPlan() {
    const urlValue: string = this.url + "/GetAllEmployeesForPlan";
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response;
    });
  }

  toQueryString(obj: any) {
    const parts = [];
    // tslint:disable-next-line:forin
    for (const property in obj) {
      const value = obj[property];
      if (value != null && value !== undefined) {
        parts.push(
          encodeURIComponent(property) + '=' + encodeURIComponent(value)
        );
      }
    }
    return parts.join('&');
  }

  getPackItemsWithPrice(filter: any) {
    const urlValue = this.url + "/GetPackItemsWithPrice"
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      })
  }

  getItemModel() {

    const urlValue = this.url + '/GetItemModel';
    return this.http.get<ResponseModel>(urlValue).pipe(
      map(response => {
        return response;
      })
    );
  }
  getItemTypes() {
    const urlValue = this.url + '/GetItemTypes';
    return this.http.get<ResponseModel>(urlValue).pipe(
      map(response => {
        return response;
      })
    );
  }

  getItemsListSharedTable(filter) {
    const urlValue: string = this.url + "/GetItemsListSharedTable";
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }
  GetAllItemsForItemsLists(divisionId: number, brandId: number, categoryId: number, paginateSetting: any) {
    const urlValue: string = this.url + "/GetAllItemsForItemsLists";
    let allItems = {
      divisionId: divisionId,
      brandId: brandId,
      itemCategoryId: categoryId,
      paginateSetting: paginateSetting
    }
    //let itemFilters = '?divisionId=' + divisionId + '&brandId=' + brandId + '&categoryId=' + categoryId + '&paginateSetting=' + JSON.stringify(paginateSetting);
    return this.http.post<ResponseModel>(
      urlValue, allItems).map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }
  deleteItem(itemId: any) {
    const urlValue = this.url + "/DeleteItem?itemId=" + itemId;
    return this.http.get<ResponseModel>(urlValue).pipe(
      map(response => {
        return response;
      })
    );
  }
  isPackValidToDelete(packId: number) {
    const urlValue = this.url + '/IsPackValidToRemove';
    return this.http.post<ResponseModel>(urlValue, packId).map(response => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }
  isSecondaryPackValidToDelete(secondaryPackId: any) {
    const urlValue: string = this.url + '/IsSecondaryPackValidToRemove';
    return this.http.post<ResponseModel>(urlValue, secondaryPackId).map(
      response => {
        return response;
      });
  }
  saveItem(itemData: FormData, isEditMode: boolean) {
    const urlValue = this.url + (isEditMode ? "/UpdateItem" : "/InsertItem");
    return this.http.post<ResponseModel>(urlValue, itemData)
      .map(response => {
        return response;
      })
  }
  // saveItemListData(itemListData: FormData) {
  //   const urlValue = this.url + "/saveItemList";
  //   return this.http.post<ResponseModel>(urlValue, itemListData)
  //     .map(response => {
  //       return response;
  //     })
  // }

  getPackType() {
    const urlValue = this.url + '/GetPackTypesList';
    return this.http.get<ResponseModel>(urlValue)
      .map(response => {
        return response;
      });
  }
  savePackType(packType) {
    const urlValue = this.url + "/InsertOrUpdatePackType";
    return this.http.post<ResponseModel>(urlValue, packType)
      .map(response => {
        return response;
      })
  }
  getItemData(itemId: any) {
    const urlValue = this.url + '/GetItemData';
    return this.http.post<ResponseModel>(urlValue, itemId).map(response => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }
  deactivateItem(itemId: number) {
    const urlValue: string = this.url + '/UpdateItemFromActiveToInactive';
    return this.http.post(urlValue, itemId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  activateItem(itemId: number) {
    const urlValue: string = this.url + '/UpdateItemFromInactiveToActive';
    return this.http.post(urlValue, itemId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }


  getItemsTemplate() {
    const urlValue: string = this.url + '/GetItemsTemplate';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }
  getIOTSensors() {
    const urlValue: string = this.url + '/GetIOTSensors';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getAllVendors(IncludeInactive: boolean) {
    const urlValue: string = this.url + '/GetAllVendors';
    let filter = {
      includeInactive: IncludeInactive
    }
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return <any>response.data;
    });
  }

  /* public requestDataFromMultipleSources(itemId: any): Observable<any[]> {
    const urlValue1: string = this.url + '/GetIOTSensors';
    const urlValue2: string = this.url + '/GetItemData';
    let response1 = this.http.get<ResponseModel>(urlValue1);
    let response2 = this.http.post<ResponseModel>(urlValue2, itemId);
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([response1, response2]);
  } */


  checkIfItemSerialsAvailable() {
    const urlValue = this.url + "/CheckIfItemSerialsAvailable";
    return this.http.get<ResponseModel>(urlValue).pipe(
      map(response => {
        return response;
      })
    );
  }
  getSecondaryPacks(itemId: number) {
    const urlValue: string = this.url + '/GetSecondaryPacks';
    return this.http.post<ResponseModel>(urlValue, itemId).pipe(map(
      response => {
        return response;
      }));
  }

  getCompetitorsList() {
    const urlValue = this.url + '/GetCompetitorsList';
    return this.http.get<ResponseModel>(urlValue).pipe(
      map(response => {
        return response;
      })
    );
  }

  getAvailableCompetitorItemList(itemId: number) {
    const urlValue = this.url + '/GetAvailableCompetitorItemList';
    if (!itemId) {
      itemId = -1;
    }
    return this.http.post<ResponseModel>(urlValue, itemId).pipe(
      map(response => {
        return response;
      })
    );
  }

  fillDataInItemExtraInfo() {
    const urlValue: string = this.url + '/fillDataInItemExtraInfo';
    return this.http.get<ResponseModel>(urlValue).pipe(map(
      response => {
        return response;
      }));
  }

  GetItemSerialOperations(data: any) {
    const urlValue: string = this.url + "/GetItemSerialOperations";
    return this.http.post<ResponseModel>(urlValue, data).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  UpdateSerialOperations(serialOperations: any) {
    const urlValue = this.url + '/UpdateItemSerialOperations';
    return this.http.post<ResponseModel>(urlValue, serialOperations).map(response => {
      return response; 0
    });
  }

  checkIfItemsWithUpperLimitAvailable() {
    const urlValue = this.url + '/CheckIfItemsWithUpperLimitAvailable';
    return this.http.get<ResponseModel>(urlValue).pipe(
      map(response => {
        return response;
      })
    );
  }

  getVehicleStockWithSerial(vehicleId: number) {
    const urlValue = this.url + '/GetVehicleStockWithSerial';
    return this.http.post<ResponseModel>(urlValue, vehicleId).pipe(
      map(response => {
        return response;
      })
    );
  }

  getReturnReasons(packStatusId: number) {
    const urlValue: string = this.url + "/GetReturnReasons";
    return this.http.post<ResponseModel>(
      urlValue, packStatusId).map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  getPackStatuses(getNonResellableStatuses) {
    const urlValue: string = this.url + "/GetPackStatuses?getNonResellableStatuses=" + getNonResellableStatuses;
    return this.http.get<ResponseModel>(
      urlValue).map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }
  getAllMasterGroup() {
    const urlValue: string = this.url + "/GetAllMasterGroup";
    return this.http.get<ResponseModel>(
      urlValue).map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  getReturnOrderItemsBatchesList(filter: any) {
    const urlValue = this.url + "/GetReturnOrderItemsBatchesList";
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      });
  }

  updateCustomerReturnBatches(order: any) {
    const urlValue = this.url + '/RecalculateCustomerReturnBatches';
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }

  fillCustomerBatchesForBenefitItems(filter: any) {
    const urlValue = this.url + '/fillCustomerBatchesForBenefitItems';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    });
  }
  getPackTypeOfPackGroup() {
    const urlValue = this.url + '/GetPackType';
    return this.http.get<ResponseModel>(urlValue)
      .map(response => {
        return response;
      });
  }
  getKPIPackTypes() {
    const urlValue: string = this.url + '/GetKPIPackTypes';
    return this.http.get<ResponseModel>(urlValue)
      .map(response => {
        return response;
      });
  }
}


