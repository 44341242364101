import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ResponseModel } from "../../../shared/models/api-models/api-models";
import { EmailTemplateModel } from "../../../shared/models/email/email.model";
import { CoreSession } from '../../../core/core.session';


@Injectable({
  providedIn: 'root'
})
export class DynamicTemplateService {
  private url = '';
  httpOptions;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private coreSession: CoreSession) {
    this.url = baseUrl + 'DynamicTemplate';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  getDynamicTemplates(dynamicTemplateEmailTypes) {
    const urlValue = this.url + "/GetDynamicTemplates";
    return this.http.post<ResponseModel>(urlValue, dynamicTemplateEmailTypes)
      .map(response => {
        return response;
      });
  }

  getDynamicTemplateData(templateId) {
    const urlValue = this.url + "/GetDynamicTemplateData";
    return this.http.post<ResponseModel>(urlValue, templateId)
      .map(response => {
        return response;
      });
  }

  saveDynamicTemplate(dynamicTempObj) {
    const urlValue = this.url + "/InsertDynamicTemplate";
    return this.http.post<ResponseModel>(urlValue, dynamicTempObj)
      .map(response => {
        return response;
      });
  }

  getDynamicTemplateEmailTypes(emailTypeId: number) {
    const urlValue = this.url + "/GetDynamicTemplateEmailTypes";
    return this.http.post<ResponseModel>(urlValue, emailTypeId)
      .map(response => {
        return response;
      });
  }

  GetDynamicTemplateReportFiles(transactionTypeId: number) {
    const urlValue = this.url + "/GetDynamicTemplateReportFiles";
    return this.http.post<ResponseModel>(urlValue, transactionTypeId)
      .map(response => {
        return response;
      });
  }

  updateDynamicTemplateActiveStatus(dynamicTempObj) {
    const urlValue = this.url + "/UpdateDynamicTemplateActiveStatus";
    return this.http.post<ResponseModel>(urlValue, dynamicTempObj)
      .map(response => {
        return response;
      });
  }
}