
export class CustomerClassModel {
    customerClassId: number;
    customerClassName: string;
    customerClassCodeName: string;
    customerClassCode: string;
}
export class CustomerSubClassModel {
    customerSubClassId: number;
    customerSubClassName: string;
    customerSubClassCodeName: string;
    customerSubClassCode: string;
}

export class CustomerTemplateModel {
    templateName: string;
    newCustomerTemplateId: number;
    defaultCustomerTypeId: number;
    paymentTermId: number;
    priceListId: number;
    promotionGroupId: number;
    defaultGroupId: number;
    discountGroupId: number;
    creditLimit: number;
    displayFields: any;
    generalFields: any;
    addressFields: any;
    detailsFields: any;
    generalMandatoryFields: any;
    detailsMandatoryFields: any;
    addressMandatoryFields: any;
    displayMandatoryFields: any;
    generalTabEnabled: boolean;
    displayTabEnabled: boolean;
    addressTabEnabled: boolean;
    detailsTabEnabled: boolean;
    isEditMode:boolean;
    priceList: string;
    promotionGroup: string;
    discountGroup: string;
    defaultCustomerType: string;
    divisionsCredits: any;
    assignedRoutes:any[];
    CustomerTemplateModel() {
        this.templateName = "";
        this.priceList= "";
        this.promotionGroup= "";
        this.discountGroup= "";
        this.defaultCustomerType= "";
        this.newCustomerTemplateId = 0;
        this.defaultCustomerTypeId = 0;
        this.priceListId = -1;
        this.paymentTermId = -1;
        this.defaultGroupId = -1;
        this.promotionGroupId = -1;
        this.discountGroupId = -1;
        this.creditLimit = -1;
        this.displayFields= [];
        this. generalFields= [];
        this.addressFields= [];
        this.detailsFields= [];
        this.generalMandatoryFields= [];
        this.detailsMandatoryFields= [];
        this.addressMandatoryFields= [];
        this.displayMandatoryFields= [];
        this.generalTabEnabled= false;
        this.displayTabEnabled= false;
        this.addressTabEnabled= false;
        this.detailsTabEnabled= false;
        this.isEditMode =false;
        this.divisionsCredits = [] ;
        this.assignedRoutes = [];
    }
}