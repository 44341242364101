import { CoreSession } from "./../../../core/core.session";
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ResponseModel } from "../../models/api-models/api-models";
import { GeneralFilter } from "../../models/general/generalFilter.model";
import { Subject } from "rxjs";
import { WarehouseTransaction } from "../../models/warehouse-transactions/warehouse-transaction.model";

@Injectable({
  providedIn: "root"
})
export class WarehouseStockService {

  minimizedItemSidebar = new Subject<boolean>();

  private url = "";
  private httpOptions;
  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.url = baseUrl + "WarehouseStock";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token"
      })
    };
  }

  getItemAvailableStock(filter : any) {
    const urlValue = this.url + "/GetItemAvailableStock?";
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      })
  }
  isLoadOffloadExceedAvailableStock(warehouseTransaction : WarehouseTransaction) {
    const urlValue = this.url + "/IsLoadOffloadExceedAvailableStock?";
    return this.http.post<ResponseModel>(urlValue, warehouseTransaction)
      .map(response => {
        return response;
      })
  }
  callReplaceWhTransactionBatchesProcedure(warehouseTransaction : WarehouseTransaction) {
    const urlValue = this.url + "/CallReplaceWhTransactionBatchesProcedure?";
    return this.http.post<ResponseModel>(urlValue, warehouseTransaction)
      .map(response => {
        return response;
      })
  }
  getAvailableQtyFromSpecificBatch(addedBatch) {
    const urlValue: string = this.url + '/GetAvailableQtyFromSpecificBatch';
    return this.http.post(urlValue, JSON.stringify(addedBatch), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
}
