import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseModel } from '../../../models/api-models/api-models';
import { CoreSession } from '../../../../core/core.session';
@Injectable({
  providedIn: 'root'
})
export class KeyLimitService {
  private url = '';
  private httpOptions;

  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl + 'KeyLimit';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  getRequestedKeyLimitData(keyLimit) {
    const urlValue = this.url + '/GetRequestedKeyLimitData';
    return this.http.post<ResponseModel>(urlValue, keyLimit).map(response => {
      return response;
    });
  }

  InsertKeyLimit(keyLimit) {
    const urlValue = this.url + '/InsertKeyLimit';
    return this.http.post<ResponseModel>(urlValue, keyLimit).map(response => {
      return response;
    });
  }
  ApproveKeyLimit(key) {
    const urlValue = this.url + '/ApproveKeyLimit';
    return this.http.post<ResponseModel>(urlValue, key).map(response => {
      return response;
    });
  }

  RejectKeyLimit(key) {
    const urlValue = this.url + '/RejectKeyLimit';
    return this.http.post<ResponseModel>(urlValue, key).map(response => {
      return response;
    });
  }
}
