import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CoreSession } from '../../../../core/core.session';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { EmployeeKeyFilter } from '../../../models/employee-keys/employee-keys';
import { ApprovalRangeStatus } from '../../../models/enums/approval-range-status.enum';
import { DialogMode } from '../../../models/enums/dialog-mode.enum';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { KeyLimitService } from './key-limit.service';

@Component({
  selector: 'app-key-limit-dialog',
  templateUrl: './key-limit-dialog.component.html',
  styleUrls: ['./key-limit-dialog.component.css']
})
export class KeyLimitDialogComponent implements OnInit {
  @ViewChild('discountKeyModal', { static: true }) discountKeyModal: any;
  IshiddenButton: boolean;
  keyStatus = '';
  colorStatus = '';
  OkResult: DialogResult = DialogResult.Ok;
  RejectResult: DialogResult = DialogResult.No;
  keyLimit: any;
  @Input() keyLimitObject: any = null;

  query: EmployeeKeyFilter = {
    page: 0,
    pageSize: 20
  };

  constructor(
    public coreSession: CoreSession,
    private modalService: NgbModal,
    public sessionData: SessionDataProvider,
    private config: NgbModalConfig,
    private translateService: TranslateService,
    private keyLimitService: KeyLimitService
  ) {
  }

  ngOnInit() {
  }

  public Show(notification) {

    this.keyLimitObject = notification
    if (this.keyLimitObject != null)
      this.getKeyLimit();
  }
  ngOnDestroy() {
    if (this.modalService) {
      this.modalService.dismissAll();
    }
    this.keyLimitObject = null;
  }

  public openKeyDialog() {
    if (this.keyLimit.statusId != ApprovalRangeStatus.Pending) {
      this.IshiddenButton = true;
    } else {
      this.IshiddenButton = false;
    }
    if (!this.IshiddenButton) {
      this.CheckNewRequestedValue();
    }

    const modalOpened = this.modalService.open(this.discountKeyModal, {
      centered: true
    });
    modalOpened.result.then(
      result => {
        if (result == DialogResult.Ok) {
          this.isValidToApprove();
        } else if (result == DialogResult.No) {
          this.rejectKeyLimit();
        }
        this.keyLimit = {};
        this.config.backdrop = true;
        return result;
      },
      reason => {
        return undefined;
      }
    );

  }

  getKeyLimit() {
    this.keyLimit = new Object();
    this.keyLimit.employeeName = this.keyLimitObject.senderDescription;
    this.keyLimit.messageID = this.keyLimitObject.notificationId;

    this.coreSession.ModalLoading.Show();
    this.keyLimitService.getRequestedKeyLimitData(this.keyLimit).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.keyLimit = response.data;
          this.openKeyDialog();
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }


  rejectKeyLimit() {

    this.keyLimit.newLowerRange = +this.keyLimit.oldLowerRange;
    this.keyLimit.newUpperRange = +this.keyLimit.oldUpperRange;

    this.keyLimitService.RejectKeyLimit(this.keyLimit).subscribe(
      response => {
        if (response.status != null && response.status >= 0) {

        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }

  ApproveKeyLimit(key) {
     
    this.keyLimitService.ApproveKeyLimit(key).subscribe(
      response => {
        if (response.status != null && response.status >= 0) {

        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
    this.modalService.dismissAll()
  }
  isValidToApprove() {
    this.keyLimit.newLowerRange = +this.keyLimit.newLowerRange;
    this.keyLimit.newUpperRange = +this.keyLimit.newUpperRange;
    if (this.keyLimit.newUpperRange >= this.keyLimit.newLowerRange) {
      if (this.keyLimit.newRequestedValue < this.keyLimit.newLowerRange || this.keyLimit.newRequestedValue > this.keyLimit.newUpperRange) {
        this.getOutOfRange(this.keyLimit);
      } else {
        this.ApproveKeyLimit(this.keyLimit);
      }
    }
    else {
      let errorMsg = this.translateService.instant(ConstantMessages.MsgNewRangeFromLessThanTo);
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), errorMsg);
      return false;
    }
  }

  getOutOfRange(keyLimit) {
    this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgOutOfRange), DialogMode.YesNo).then(
      (res: DialogResult) => {
        if (res === DialogResult.Yes) {
          this.ApproveKeyLimit(keyLimit);
        }
      });
  }
  CheckNewRequestedValue() {
    if (this.keyLimit.newRequestedValue < this.keyLimit.oldLowerRange) {
      this.keyLimit.newLowerRange = this.keyLimit.newRequestedValue;
      this.keyLimit.newUpperRange = this.keyLimit.oldUpperRange;
    }
    if (this.keyLimit.newRequestedValue > this.keyLimit.oldUpperRange) {
      this.keyLimit.newUpperRange = this.keyLimit.newRequestedValue;
      this.keyLimit.newLowerRange = this.keyLimit.oldLowerRange;
    }
    if (this.keyLimit.oldLowerRange == this.keyLimit.oldUpperRange) {
      this.keyLimit.newUpperRange = this.keyLimit.newRequestedValue;
      this.keyLimit.newLowerRange = this.keyLimit.newRequestedValue;
    }
  }

  setColor() {

    switch (this.keyLimit.statusId) {
      case 2:
        this.keyStatus = 'Desc_Sent';
        this.colorStatus = 'label-green'
        break;
      case 3:
        this.keyStatus = 'Desc_Reject';
        this.colorStatus = 'label-red'
        break;
      case 4:
        this.keyStatus = 'Desc_Timed_Out';
        this.colorStatus = 'label-yellow'
        break;
    }

  }
}
