import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, OnChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PromotionGroupsService } from '../../../services/data-definition/prices-and-offers/promotion-groups.service';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { Observable } from 'rxjs-compat';

@Component({
  selector: 'app-select-promotions',
  templateUrl: './select-promotions.component.html',
  styleUrls: ['./select-promotions.component.css']
})
export class SelectPromotionsComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('selectPromotionDialog', { static: true }) selectPromotionDialog: any;
  OkResult: DialogResult = DialogResult.Ok;

  @Input() currentId = 'promotionSelection';
  @Input() addedPromotionsList: any[] = [];
  @Input() groupId: number = -1;
  @Input() customerId: number = -1;
  @Output() saveEvent = new EventEmitter();

  query;
  public promotionFilterForm: FormGroup;
  allowPromotionPriority = false;
  showSelectPromotionsDialog: boolean = false;

  sharedTabelData: ITableProperties = {
    pageSize: 100,
    showPaginator: true,
    isOnline: true,
    showSearch: true,
    // showSearchBtn: true,
    isMultiSelection: true,
    showEditButton: false,
    rowOperations: [],
    multiSelectionOperations: [],
    specificActionWhenCheckAll: true,
    specificActionWhenCheckRow: true,
    columns: [
      { title: 'Desc_Promotion', key: 'promotionName', isSortable: true, width: '20%' },
      { title: 'Desc_Code', key: 'promotionCode', isSortable: true, width: '20%' },
      { title: 'Desc_Start_Date', key: 'startDate', isSortable: true, width: '20%', isExpiryDate: true },
      { title: 'Desc_End_Date', key: 'endDate', isSortable: true, width: '20%', isExpiryDate: true }
    ]
  };
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  customersFilter: any = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: 100
    },
    customerGroupId: -1,
    customerId: -1
  }

  constructor(
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private promotionGroupsService: PromotionGroupsService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private sessionData: SessionDataProvider
  ) {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }
  ngOnInit() {
    this.allowPromotionPriority = this.sessionData.getConfigurationValue(ConstantConfigurations.ApplyPriorityOnPromotionGroups).toLowerCase() === "true";
    if (this.allowPromotionPriority) {
      this.sharedTabelData.columns.push({
        title: 'Desc_Priority',
        key: 'priority',
        isSortable: true,
        width: '20%',
        //isEditablePerRow: true,
        isEditable: true,
        disableInputBasedOnProperty: true,
        disableInputPropertyName: 'disableValuesUsed',
        zeroDigits: true
      });
      // this.subscribeSaveClick()
    }
  }
  ngOnChanges() {
    this.initFilterForm();
  }
  ngOnDestroy() {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }
  initFilterForm() {
    this.promotionFilterForm = new FormGroup({
      customerGroupId: new FormControl(null),
      customerId: new FormControl(null),
      outletId: new FormControl(null),
    });
  }
  populatePromotions() {
    this.customersFilter.customerId = (!this.customerIdInput.value || this.customerIdInput.value === null) ? -1 : this.customerIdInput.value;
    this.customersFilter.customerGroupId = (!this.customerGroupInput.value || this.customerGroupInput.value === null) ? -1 : this.customerGroupInput.value;
    this.coreSession.ModalLoading.Show();
    this.promotionGroupsService.GetUnAssigmentPromotions(this.customersFilter).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.reflectAddedBeforePromotions(response.data);
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
    });
  }
  reflectAddedBeforePromotions(dataSource) {
    if (dataSource && dataSource.data && dataSource.data.length > 0) {
      dataSource.data.forEach(newPromotion => {
        var index = this.addedPromotionsList.findIndex(addedPromotion => addedPromotion.promotionId === newPromotion.promotionId);
        if (index >= 0) {
          newPromotion.isChecked = true;
          newPromotion.disableValuesUsed = false;
          newPromotion.priority = +this.addedPromotionsList[index].priority
          newPromotion = this.addedPromotionsList[index];
        }
      })
    }
    this.dataSource = {
      totalItems: dataSource.totalItems,
      data: dataSource.data
    }
  }
  onSaveClick() {
    if (this.validatePromotions()) {
      this.saveEvent.emit(this.addedPromotionsList);
    }
  }
  validatePromotions() {

    let isValid = true;
    if (!this.addedPromotionsList || (this.addedPromotionsList && this.addedPromotionsList.findIndex(p => p.isChecked == true) == -1)) {
      let errorMsg = this.translateService.instant(ConstantMessages.MsgSelectPromotion);
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), errorMsg);
      isValid = false;
    }
    if (this.allowPromotionPriority) {
      this.addedPromotionsList.forEach(promotion => {
        promotion.priority = +promotion.priority;
        if (promotion.priority == 0 && promotion.isChecked == true) {
          let errorMsg = promotion.promotionName + ':' + this.translateService.instant(ConstantMessages.MsgRequiredPriority);
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), errorMsg);
          isValid = false;
        }
      });
    }
    return isValid;
  }
  public closeSelectPromotionsDialog() {
    this.modalService.dismissAll()
    this.showSelectPromotionsDialog = false;
  }
  get customerGroupInput() {
    return this.promotionFilterForm.get('customerGroupId');
  }
  get customerIdInput() {
    return this.promotionFilterForm.get('customerId');
  }
  onFindCustomers() {
    this.populatePromotions();
  }
  onClearFilters() {
    this.promotionFilterForm.reset();
  }
  onCheckRowOperation(promotion) {
    var index = this.addedPromotionsList.findIndex(x => x.promotionId === promotion.promotionId);
    if (promotion.isChecked) {
      if (index < 0) {
        this.addedPromotionsList.push(promotion);
        promotion.disableValuesUsed = false;
      }
    } else {
      if (index >= 0) {
        promotion.disableValuesUsed = true;
        this.addedPromotionsList.splice(index, 1);
      }
    }
  }
  onCheckAllOperation(checkAll: boolean) {
    this.dataSource.data.forEach(promotion => {
      var index = this.addedPromotionsList.findIndex(x => x.promotionId === promotion.promotionId);
      if (index >= 0 && !checkAll) {
        promotion.disableValuesUsed = true;
        this.addedPromotionsList.splice(index, 1);
      } else if (index < 0 && checkAll) {
        promotion.disableValuesUsed = false;
        this.addedPromotionsList.push(promotion);
      }
    })
  }
  onTableFilterChanged(query: any) {
    this.customersFilter.customListFilter = query;
    this.query = query;
    this.populatePromotions();
  }
  onValueChanges(event: any) {
    let changedValue = event.object;
    let priority = +changedValue.priority
    if (!this.addedPromotionsList) { this.addedPromotionsList = []; }
    let index = this.addedPromotionsList.findIndex(a => a.promotionId === changedValue.promotionId);
    if (index > -1) {
      this.addedPromotionsList[index].priority = priority;
    } else {
      this.addedPromotionsList[index].priority = priority;
    }
  }
  public showDialog() {
    this.sharedTabelData.columns = [
      { title: 'Desc_Promotion', key: 'promotionName', isSortable: true, width: '20%' },
      { title: 'Desc_Code', key: 'promotionCode', isSortable: true, width: '20%' },
      { title: 'Desc_Start_Date', key: 'startDate', isSortable: true, width: '20%', isExpiryDate: true },
      { title: 'Desc_End_Date', key: 'endDate', isSortable: true, width: '20%', isExpiryDate: true }
    ];
    this.allowPromotionPriority = this.sessionData.getConfigurationValue(ConstantConfigurations.ApplyPriorityOnPromotionGroups).toLowerCase() === "true";
    if (this.allowPromotionPriority) {
      this.sharedTabelData.columns.push({
        title: 'Desc_Priority',
        key: 'priority',
        isSortable: true,
        width: '20%',
        // isEditablePerRow: true,
        isEditable: true,
        disableInputBasedOnProperty: true,
        disableInputPropertyName: 'disableValuesUsed',
        zeroDigits: true
      });
    }
    //this.selectedOraganizationIds = organizationIds;
    this.coreSession.SetTitle('Select Promotions');
    this.showSelectPromotionsDialog = true;
    this.customersFilter.customListFilter = {
      searchFilter: '',
      page: 0,
      pageSize: 100
    };
    this.promotionFilterForm.reset();

    this.customerGroupInput.setValue(this.groupId && this.groupId != -1 ? this.groupId : null);
    this.customerIdInput.setValue(this.customerId && this.customerId != -1 ? this.customerId : null);


    this.populatePromotions();
    return this.modalService.open(this.selectPromotionDialog, { centered: true, size: 'xl' }).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          return this.addedPromotionsList;
        } else {
          return -1;
        }
      });
  }

}
