import { GeolocationService } from './../../../services/geolocation.service';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { GeographicalLocationHierarchyLevel } from '../../../models/enums/geographical-location-hierarchy-Level';

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.css']
})
export class LocationFilterComponent implements OnInit, OnChanges {
  // locationFilterForm: FormGroup;
  countriesList: any[] = [];
  statesList: any[] = [];
  citiesList: any[] = [];
  areasList: any[] = [];
  streetsList: any[] = [];
  selectedCountry = -1;
  selectedState = -1;
  selectedCity = -1;
  selectedArea = -1;
  selectedStreet = -1;
  @Input() hideCountry: boolean;
  @Input() hideCity: boolean;
  @Input() hideState: boolean;
  @Input() hideArea: boolean;
  @Input() hideStreet: boolean;
  @Input() CountryID = -1;
  @Input() CityID = -1;
  @Input() StateID = -1;
  @Input() AreaID = -1;
  @Input() StreetID = -1;
  @Input() isEditMode: boolean;
  @Input() rowClass: string;
  @Input() parentForm: FormGroup;
  @Output() areaSelected = new EventEmitter<{ countryID: any, stateID: any, cityID: any, areaID: any, streetID: any }>();
  constructor(private locationService: GeolocationService,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private sessionData: SessionDataProvider,) { }
  ngOnInit() {


    
    switch (this.geographicalLocationHierarchyLevel()) {
      case GeographicalLocationHierarchyLevel.Country:
        this.GetCountries();
        this.GetStates();
        this.GetCities();
        this.GetAreas();
        this.GetStreets();
        break;
      case GeographicalLocationHierarchyLevel.State:
        this.GetStates();
        this.GetCities();
        this.GetAreas();
        this.GetStreets();
        break;
      case GeographicalLocationHierarchyLevel.City:
        this.GetCities();
        this.GetAreas();
        this.GetStreets();
        break;
      case GeographicalLocationHierarchyLevel.Area:
        this.GetAreas();
        this.GetStreets();
        break;
      case GeographicalLocationHierarchyLevel.Street:
        this.GetStreets();
        break;
    }

  }
  ngOnChanges() {


    
    this.selectedCountry = this.CountryID ? this.CountryID : -1;
    this.selectedState = this.StateID ? this.StateID : -1;
    this.selectedCity = this.CityID ? this.CityID : -1;
    this.selectedArea = this.AreaID ? this.AreaID : -1;
    this.selectedStreet = this.StreetID ? this.StreetID : -1;
    if (this.isEditMode) {
      // if (this.parentForm.get('country') && this.CountryID < 0 && this.StateID < 0 && this.CityID < 0 && this.AreaID < 0 && this.StreetID < 0) {
      //   this.parentForm.get('country').setValue(null);
      //   this.selectedCountry = -1;
      //   this.ClearDropdowns('country');
      // }
      switch (this.geographicalLocationHierarchyLevel()) {
        case GeographicalLocationHierarchyLevel.Country:
          this.GetCountries();
          this.GetStates();
          this.GetCities();
          this.GetAreas();
          this.GetStreets();
          break;
        case GeographicalLocationHierarchyLevel.State:
          this.GetStates();
          this.GetCities();
          this.GetAreas();
          this.GetStreets();
          break;
        case GeographicalLocationHierarchyLevel.City:
          this.GetCities();
          this.GetAreas();
          this.GetStreets();
          break;
        case GeographicalLocationHierarchyLevel.Area:
          this.GetAreas();
          this.GetStreets();
          break;
        case GeographicalLocationHierarchyLevel.Street:
          this.GetStreets();
          break;
      }
    }
  }
  OnCountryChange(country: any) {
    this.ClearDropdowns('country');
    let removeSelected = country == undefined;
    if (country !== undefined) {
      this.selectedCountry = country.countryId;
    } else {
      this.selectedCountry = -1;
      this.selectedState = -1;
      this.selectedCity = -1;
      this.selectedArea = -1;
      this.selectedStreet = -1;
    }

    if (!this.hideState) {
      this.GetStates(removeSelected);
    }
    if (!this.hideCity) {
      this.GetCities(removeSelected);
    }
    if(!this.hideArea) {
      this.GetAreas(removeSelected);
    }
    if(!this.hideStreet) {
      this.GetStreets(removeSelected);
    }
    this.onApproveLocation();
  }
  OnStateChange(state: any) {
    this.ClearDropdowns('state');
    let removeSelected = state == undefined;
    if (state !== undefined) {
      this.selectedState = state.stateId;
    } else {
      this.selectedState = -1;
      this.selectedCity = -1;
      this.selectedArea = -1;
      this.selectedStreet = -1;
    }
    if (!this.hideCity) {
      this.GetCities(removeSelected);
    }
    if (!this.hideArea) {
      this.GetAreas(removeSelected);
    }
    if (!this.hideStreet) {
      this.GetStreets(removeSelected);
    }
    this.onApproveLocation();
  }
  OnCityChange(city: any) {
    this.ClearDropdowns('city');
    let removeSelected = city == undefined;
    if (city !== undefined) {
      this.selectedCity = city.cityId;
    } else {
      this.selectedCity = -1;
      this.selectedArea = -1;
      this.selectedStreet = -1;
    }

    if (!this.hideArea) {
      this.GetAreas(removeSelected);
    }
    if (!this.hideStreet) {
      this.GetStreets(removeSelected);
    }
    this.onApproveLocation();
  }
  OnAreaChange(area: any) {
    this.ClearDropdowns('area');
    let removeSelected = area == undefined;
    if (area !== undefined) {
      this.selectedArea = area.areaId;
    } else {
      this.selectedArea = -1;
      this.selectedStreet = -1;
    }
    if (!this.hideStreet) {
      this.GetStreets(removeSelected);
    }
    this.onApproveLocation();
  }

  OnStreetChange(street: any) {
    if (this.parentForm.get('streetName')) {
      if (street){
        this.parentForm.get('streetName').setValue(street.streetName);
        this.parentForm.get('street').setValue(street.streetId);
      }else{
        this.parentForm.get('streetName').setValue('');
        this.parentForm.get('street').setValue(-1);
      }
    }
    if (street !== undefined) {
      this.selectedStreet = street.streetId;
      this.onApproveLocation();
    } else {
      this.selectedStreet = -1;
    }
    this.onApproveLocation();
  }
  GetCountries() {
    this.coreSession.ModalLoading.Show();
    this.locationService.GetCountries().subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.countriesList = response.data;
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  GetStates(removeSelected: boolean = false) {

    this.coreSession.ModalLoading.Show();
    let selectedCountry = this.getSelectedCountry(removeSelected);
    this.locationService.GetStates(selectedCountry).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.statesList = response.data;
          this.onApproveLocation();
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  GetCities(removeSelected: boolean = false) {
    
    this.coreSession.ModalLoading.Show();
    let selectedCountry = this.getSelectedCountry(removeSelected);
    let selectedState = this.getSelectedState(removeSelected);
    this.locationService.GetCities(selectedCountry, selectedState).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.citiesList = response.data;
          this.onApproveLocation();
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  GetAreas(removeSelected: boolean = false) {
    this.coreSession.ModalLoading.Show();
    let selectedCountry = this.getSelectedCountry(removeSelected);
    let selectedState = this.getSelectedState(removeSelected);
    let selectedCity =  this.getSelectedCity(removeSelected);
    this.locationService.GetAreas(selectedCountry, selectedState, selectedCity).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.areasList = response.data;
          this.onApproveLocation();
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  GetStreets(removeSelected: boolean = false) {
    this.coreSession.ModalLoading.Show();
    let selectedCountry = this.getSelectedCountry(removeSelected);
    let selectedState = this.getSelectedState(removeSelected);
    let selectedCity = this.getSelectedCity(removeSelected);
    let selectedArea = this.getSelectedArea(removeSelected);
    this.locationService.GetStreets(selectedCountry,selectedState,selectedCity,selectedArea).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.streetsList = response.data;
          this.onApproveLocation();
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  onApproveLocation() {
    this.areaSelected.emit({
      countryID: this.selectedCountry,
      stateID: this.selectedState,
      cityID: this.selectedCity,
      areaID: this.selectedArea,
      streetID: this.selectedStreet
    });
  }
  ClearDropdowns(controlName: string) {
    switch (controlName) {
      case 'country':
        if (!this.hideState)
          this.parentForm.get('state').setValue(null);
        this.parentForm.get('city').setValue(null);
        this.parentForm.get('area').setValue(null);
        if (!this.hideStreet)
          this.parentForm.get('street').setValue(null);
        this.selectedArea = -1;
        this.selectedState = -1;
        this.selectedCity = -1;
        this.statesList = [];
        this.citiesList = [];
        this.areasList = [];
        break;
      case 'state':
        this.parentForm.get('city').setValue(null);
        this.parentForm.get('area').setValue(null);
        if (!this.hideStreet)
          this.parentForm.get('street').setValue(null);
        this.selectedArea = -1;
        this.selectedCity = -1;
        this.citiesList = [];
        this.areasList = [];
        break;
      case 'city':
        this.parentForm.get('area').setValue(null);
        if (!this.hideStreet)
          this.parentForm.get('street').setValue(null);
        this.selectedArea = -1;
        this.selectedStreet = -1;
        this.areasList = [];
        this.streetsList = [];
        break;
      case 'area':
        if (!this.hideStreet)
          this.parentForm.get('street').setValue(null);
        this.selectedStreet = -1;
        this.streetsList = [];
        break;
    }

  }

  getSelectedCountry(removeSelected: boolean){
    if (this.geographicalLocationHierarchyLevel() == GeographicalLocationHierarchyLevel.Country) {
      if (this.isEditMode && removeSelected){
        return -1;
      } else {
        return this.selectedCountry;
      }
    }else if (this.selectedCountry > 0) {
      return this.selectedCountry;
    } else {
      return -1;
    }
  }
  getSelectedState(removeSelected: boolean) {
    if (this.geographicalLocationHierarchyLevel() == GeographicalLocationHierarchyLevel.State) {
      if (this.isEditMode && removeSelected) {
        return -1;
      } else {
        return this.selectedState;
      }
    } else if (this.selectedState > 0) {
      return this.selectedState;
    } else {
      return -1;
    }
  }
  getSelectedCity(removeSelected: boolean) {
    if (this.geographicalLocationHierarchyLevel() == GeographicalLocationHierarchyLevel.City) {
      if (this.isEditMode && removeSelected) {
        return -1;
      } else {
        return this.selectedCity;
      }
    } else if (this.selectedCity > 0) {
      return this.selectedCity;
    } else {
      return -1;
    }
  }
  getSelectedArea(removeSelected: boolean) {
    if (this.geographicalLocationHierarchyLevel() == GeographicalLocationHierarchyLevel.Area) {
      if (this.isEditMode && removeSelected) {
        return -1;
      } else {
        return this.selectedArea;
      }
    } else if (this.selectedArea > 0) {
      return this.selectedArea;
    } else {
      return -1;
    }
  }
  geographicalLocationHierarchyLevel() : number{
    return +this.sessionData.getConfigurationValue(ConstantConfigurations.GeographicalLocationHierarchyLevel).toLowerCase();
  }
}
