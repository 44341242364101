import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CoreSession } from '../../../core/core.session';
import { ResponseModel } from '../../models/api-models/api-models';
import { ItemPackModel } from '../../models/Item/item.model';
import { map } from 'rxjs/operators';
import { SerialValidationErrors } from '../../models/enums/serial-validation-errors.enum';
import { ConstantMessages } from '../../models/constants/constant-message';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: "root"
})
export class SerialsService {

  private url = "";
  private httpOptions;
  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject("BASE_URL") baseUrl: string,
    private translateService: TranslateService) {
    this.url = baseUrl + "Serials";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token"
      })
    };
  }
  generateSerialBarcode(item: ItemPackModel, barcodeValues: any) {
    const urlValue: string = this.url + "/GenerateSerialBarcode";
    let itemPk = {
      itemId: item.itemId,
      packId: item.packId,
      piecesInPack: item.piecesInPack,
      barcodeValues: barcodeValues
    }
    return this.http.post<ResponseModel>(urlValue, itemPk)
      .map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  getTrackingReportData(filter: any) {
    const urlValue: string = this.url + "/getTrackingReportData";
    return this.http.post<ResponseModel>(urlValue, filter).pipe(map(
      response => {
        return response;
      }));
  }
  getSerialTrackingHistory(filter: any) {
    const urlValue: string = this.url + "/getSerialTrackingHistory";
    return this.http.post<ResponseModel>(urlValue, filter).pipe(map(
      response => {
        return response;
      }));
  }
  validateSerial(serialCurrentStatusList: any[]) {
    const urlValue: string = this.url + "/ValidateSerial";
    return this.http
      .post<ResponseModel>(
        urlValue, serialCurrentStatusList
      )
      .map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }
  validateAndSeparateSerials(serialObj: any) {
    const urlValue: string = this.url + "/ValidateAndSeparateSerials";

    return this.http.post<ResponseModel>(
      urlValue
      , serialObj)
      .map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });


  }

  getErrorText(error: SerialValidationErrors) {
    switch (error) {
      case SerialValidationErrors.AlreadyAddedInSystem:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Entered_Before);
        break;
      case SerialValidationErrors.NotLoadedToMainWarehouse:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Not_In_Main_Warehouse);
        break;
      case SerialValidationErrors.SerialEnteredBefore:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Entered_Before);
        break;
      case SerialValidationErrors.SerialEnteredBeforeForAnotherItem:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Entered_For_Another_Item);
        break;
      case SerialValidationErrors.SerialsEnteredBefore:
        return this.translateService.instant(ConstantMessages.Desc_Serials_Entered_Before);
        break;
      case SerialValidationErrors.SerialsEnteredBeforeForAnotherItem:
        return this.translateService.instant(ConstantMessages.Desc_Serials_Entered_For_Another_Item);
        break;
      case SerialValidationErrors.OneOrMoreSubSerialNotValid:
        return this.translateService.instant(ConstantMessages.Desc_One_Or_More_Sub_Serials_Not_Valid);
        break;
      case SerialValidationErrors.NotInWarehouse:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Not_In_Main_Warehouse);
        break;
      case SerialValidationErrors.NotFoundInDB:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Not_Exist);
        break;
      case SerialValidationErrors.SerialsBelongToAnotherPack:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Belong_To_Another_Pack);
        break;
      case SerialValidationErrors.NotInVehicle:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Not_In_Vehicle);
        break;
      case SerialValidationErrors.SerialTransferedOutInAnotherTransaction:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Transfered_Out_In_Another_Transaction);
        break;
      case SerialValidationErrors.ParentOfThisSerialAddedInThisTransferOut:
        return this.translateService.instant(ConstantMessages.Desc_Parent_Of_This_Serial_Added_In_This_Transfer_Out);
        break;
      case SerialValidationErrors.BarcodeNotCompatibleWithTemplate:
        return this.translateService.instant(ConstantMessages.Desc_Barcode_Not_Compatible_With_Template);
        break;
      case SerialValidationErrors.BarcodeOfSubSerialNotCompatibleWithParent:
        return this.translateService.instant(ConstantMessages.Desc_Barcode_Of_Sub_Not_Compatible_With_Parent);
        break;
      case SerialValidationErrors.SubSerialHasDifferentBatchOrExpiry:
        return this.translateService.instant(ConstantMessages.Desc_Sub_Serial_Has_Different_Batch_Or_Expiry);
        break;
      case SerialValidationErrors.SubSerialAlreadyAggregated:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Already_Aggregated);
        break;
      case SerialValidationErrors.ReservedSerial:
        return this.translateService.instant(ConstantMessages.Desc_Reserved_Serial);
        break;
      case SerialValidationErrors.SerialNotDefinedOverRecall:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Not_Defined_Over_Recall);
        break;
      case SerialValidationErrors.SerialLinkedToSSCC:
        return this.translateService.instant(ConstantMessages.Desc_Serial_Linked_To_SSCC);
        break;
      case SerialValidationErrors.BlockedSerial:
        return this.translateService.instant(ConstantMessages.DescBlockedSerial);
        break;
    }
  }
  checkIfSerialsListContainsAnyPartOfSerialRange(serialObj: any, serialsList: any[]) {
    var index = -1;
    if (!serialsList || serialsList == null) {
      return -1;
    }
    if (serialObj.toSerial==='' || serialObj.fromSerial==serialObj.toSerial)
    index = serialsList.findIndex(serial => (serialObj.fromSerial.length == serial.fromSerial.length && (serialObj.fromSerial.toLowerCase() >= serial.fromSerial.toLowerCase() && serialObj.fromSerial.toLowerCase() <= serial.toSerial.toLowerCase())));
else
    index = serialsList.findIndex(serial => serialObj.fromSerial.length == serial.fromSerial.length && ((serialObj.fromSerial.toLowerCase() >= serial.fromSerial.toLowerCase() && serialObj.fromSerial.toLowerCase() <= serial.toSerial.toLowerCase())
                      || (serialObj.toSerial.toLowerCase() >= serial.fromSerial.toLowerCase() && serialObj.toSerial.toLowerCase() <= serial.toSerial.toLowerCase())
                      || (serialObj.fromSerial.toLowerCase() <= serial.fromSerial.toLowerCase() && serialObj.toSerial.toLowerCase() >= serial.toSerial.toLowerCase())));
 return index;
                    }

getAllSerialsContainingSerial(serialObj:any,serialsList:any[]){
  var foundSerials=[];
  if(!serialsList || serialsList==null){
    return null;
  }
  if (serialObj.toSerial==='' || serialObj.fromSerial==serialObj.toSerial)
  foundSerials = serialsList.filter(serial => (serialObj.fromSerial.length == serial.fromSerial.length && (serialObj.fromSerial.toLowerCase() >= serial.fromSerial.toLowerCase() && serialObj.fromSerial.toLowerCase() <= serial.toSerial.toLowerCase())));
else
foundSerials = serialsList.filter(serial => serialObj.fromSerial.length == serial.fromSerial.length && ((serialObj.fromSerial.toLowerCase() >= serial.fromSerial.toLowerCase() && serialObj.fromSerial.toLowerCase() <= serial.toSerial.toLowerCase())
                    || (serialObj.toSerial.toLowerCase() >= serial.fromSerial.toLowerCase() && serialObj.toSerial.toLowerCase() <= serial.toSerial.toLowerCase())
                    || (serialObj.fromSerial.toLowerCase() <= serial.fromSerial.toLowerCase() && serialObj.toSerial.toLowerCase() >= serial.toSerial.toLowerCase())));
return foundSerials;
 }

}