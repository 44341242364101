import { Component, EventEmitter, Pipe, Directive, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { DisplayUnitService } from '../../../../../content/master-data-definition/display-unit/display-unit.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { CoreSession } from '../../../../../core/core.session';

@Component({
  selector: 'app-display-unit-type',
  templateUrl: './display-unit-type.component.html',
  styleUrls: ['./display-unit-type.component.css']
})
export class DisplayUnitTypeComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() displayUnitTypeDataSource: any[];
  @Output() selectedDisplayUnitType = new EventEmitter();
  @Output() addNewDisplayUnitType = new EventEmitter();

  @Input() addNewType: boolean = false;
  @Input() showAddNew: boolean;

  isTypesLoading = false;
  isRequired = false;


  constructor(private displayUnitService: DisplayUnitService,
    private coreSession: CoreSession) { }

  ngOnInit() {
    if (this.parentForm.get('displayUnitTypeId').validator != null) {
      const validator = this.parentForm.get('displayUnitTypeId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateDisplayUnitTypeList();
  }
  ngOnDestroy() {

  }
  onChangeDisplayUnitType(selectedDisplayUnitType: any) {
    this.selectedDisplayUnitType.emit(selectedDisplayUnitType);
  }

  //#Tyaperegion
  public afterSaveDisplayUnitType(type: any) {
    this.displayUnitTypeDataSource.push(type);
    var orginalList = cloneDeep(this.displayUnitTypeDataSource);
    this.displayUnitTypeDataSource = [];
    this.displayUnitTypeDataSource = orginalList;

    this.parentForm.get('displayUnitTypeId').setValue(type.displayUnitTypeID);
  }

  onAddNewDisplayUnitType(addNewDisplayUnitType) {
    this.addNewDisplayUnitType.emit(addNewDisplayUnitType);
  }


  populateDisplayUnitTypeList() {
    this.isTypesLoading = true;
    this.displayUnitService.getDisplayUnitType().subscribe(result => {
      this.displayUnitTypeDataSource = result;
      this.isTypesLoading = false;
    });
  }
  //#endregion

}
