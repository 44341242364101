import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-modal-loading',
  templateUrl: './modal-loading.component.html',
  styleUrls: ['./modal-loading.component.css']
})
export class ModalLoadingComponent implements OnInit {

  //#region [DECLARATIONS]

  public visible = false;
  public visibleAnimate = false;
  public isMinimized = true;
  //myClass = '';
  //#endregion

  //#region [INITIALIZED]

  constructor(private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
  }

  //#endregion

  //#region [METHODS]

  public Show(): void {
    //this.myClass = 'fixedSpinner';
    this.spinner.show();
    /*  this.visible = true;
     setTimeout(() => this.visibleAnimate = true, 100); */
  }

  public Hide(): void {
   // this.myClass = '';
    this.spinner.hide();
    /* this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 100); */
  }

  //#endregion

}
