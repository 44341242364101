import { mustSellItem } from './../../models/customer/mustSellItem';
import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { CoreSession } from '../../../core/core.session';
import { ConstantMessages } from '../../models/constants/constant-message';
import { ImportDataTypes } from '../../models/import/ImportDataTypes';
import { ResponseModel } from '../../models/api-models/api-models';
import { ImportService } from '../../services/import.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogResult } from '../../models/enums/dialog-result.enum';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataTypes } from '../../models/enums/data-types.enum';
import { FormProcessMode } from '../../models/enums/form-process-mode.enum';

@Component({
    selector: 'app-import-dialog',
    templateUrl: './import-dialog.component.html',
    styleUrls: ['./import-dialog.component.css']
})
export class ImportDialogComponent implements OnInit {

    //#region [DECLARATIONS]
    @Input() entryFormObj: any = {};
    @Output() onImportCompleted = new EventEmitter();
    dataType: ImportDataTypes;
    ExcelFile;
    uploadedFileName = 'Desc_No_File_Uploaded';
    sampleName = '';
    hideImportButton: boolean = true;
    hideUploadDownload: boolean = false;
    showResult: boolean = false;
    title = '';
    excelFile;
    @ViewChild('content', { static: true }) content: any;
    form: FormGroup;
    importHeader: string = 'header';
    importNote1: string = 'Desc_Import_note1';
    importedRecordsCount: string = '';
    notImportedRecordsCount: string = '';
    //#endregion

    //#region [INITIALIZED]

    constructor(private modalService: NgbModal,
        private importService: ImportService,
        public coreSession: CoreSession,
        private translateService: TranslateService) {
    }
    ngOnInit() {
        this.initForm();
    }
    initForm() {
        this.form = new FormGroup({
        });
    }
    //#endregion

    //#region [EVENTS]

  onUploadExcelFile(file) {

    this.hideImportButton = false;
    let This = this;
    let files = file.srcElement.files;
    if (files.length > 0) {
      let file: File = files[0];
      if (
        file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      ) {
        this.coreSession.ModalLoading.Show();
        This.uploadedFileName = file.name;
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('importDataTypeId', JSON.stringify(this.dataType.valueOf()));
        this.ExcelFile = formData;
        let oFReader = new FileReader();
        oFReader.onloadend = function () {
          This.hideImportButton = false;
        };


        oFReader.readAsText(file);
        this.coreSession.ModalLoading.Hide();
      }
      else {
        this.coreSession.ModalLoading.Hide();
        this.uploadedFileName = this.translateService.instant(ConstantMessages.Desc_No_File_Uploaded);
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.Desc_Invalid_Excel_File));
      }
    }
  }
  onImportClicked(modal) {
    this.callImportAPI(this.ExcelFile, modal);
  }
    onSampleClicked() {
        if (this.dataType === ImportDataTypes.AppData) {
            this.coreSession.ModalLoading.Show();
            this.importService.downloadDynamicExcelTemplate(this.entryFormObj.entryFormId, this.entryFormObj.menuName).subscribe((response) => {
                this.coreSession.ModalLoading.Hide();
                return;
            }, (error) => {
                this.coreSession.ModalLoading.Hide();
                this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
                return;
            });
        } else {
            switch (this.dataType) {
                case ImportDataTypes.Items:
                    this.sampleName = 'ItemsTemplate.xlsx';
                    break;
                case ImportDataTypes.Employees:
                    this.sampleName = 'EmployeesTemplate.xlsx';
                    break;
                case ImportDataTypes.Customers:
                    this.sampleName = 'CustomersTemplate.xlsx';
                    break;
                case ImportDataTypes.DisplayUnit:
                    this.sampleName = 'DisplayUnitTemplate.xlsx';
                    break;
                case ImportDataTypes.BackToStoreReset:
                    this.sampleName = 'SerialsTemplate-BackToStoreReset.xlsx';
                    break;
                case ImportDataTypes.UnlockedSerial:
                    this.sampleName = 'SerialsTemplate.xlsx';
                    break;
                case ImportDataTypes.ItemsPricesWithQtyRange:
                    this.sampleName = 'ItemsPricesTemplateWithQtyRangeTemplate.xlsx';
                    break;
                case ImportDataTypes.ItemsPricesWithoutQtyRange:
                    this.sampleName = 'ItemsPricesTemplateWithoutQtyRangeTemplate.xlsx';
                    break;
                case ImportDataTypes.KPIs:
                    this.sampleName = 'KPIsTemplate.xlsx';
                    break;
                case ImportDataTypes.CommissionCalc:
                    this.sampleName = 'CommissionCalcTemplate.xlsx'
                    break;
                case ImportDataTypes.ShopContractTargets:
                    this.sampleName = 'ShopContractsTemplate.xlsx';
                    break;

                case ImportDataTypes.MustSellItems:
                    this.sampleName = 'MustSellItemsTemplate.xlsx'
                    break;
            }
            this.coreSession.ModalLoading.Show();
            this.importService.downloadFile(this.dataType, this.sampleName).subscribe((response) => {
                this.coreSession.ModalLoading.Hide();
            }, (error) => {
                this.coreSession.ModalLoading.Hide();
                this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
            });
        }
    }
  public showDialog(importDataType: ImportDataTypes) {
    this.hideUploadDownload = false;
    this.ExcelFile = new FormData();
    this.uploadedFileName = this.translateService.instant(ConstantMessages.Desc_No_File_Uploaded);
    this.showResult = false;
    this.importedRecordsCount = '';
    this.notImportedRecordsCount = '';
    this.dataType = importDataType;
    switch (this.dataType) {
      case ImportDataTypes.Items:
        this.title = 'Desc_Items';
        break;
      case ImportDataTypes.Employees:
        this.title = 'Desc_Employees';
        break;
      case ImportDataTypes.Customers:
        this.title = 'Desc_Customers';
        break;
      case ImportDataTypes.DisplayUnit:
        this.title = 'Desc_Display_Unit';
        break;
      case ImportDataTypes.BackToStoreReset:
        this.title = ConstantMessages.DescBlockedSerial;
        break;
      case ImportDataTypes.UnlockedSerial:
        this.title = 'Desc_Unlocked_Serial';
        break;
      case ImportDataTypes.ItemsPricesWithQtyRange:
        this.title = 'Desc_Items_Prices';
        break;
      case ImportDataTypes.ItemsPricesWithoutQtyRange:
        this.title = 'Desc_Items_Prices';
        break;
      case ImportDataTypes.KPIs:
        this.title = 'Desc_KPI'
        break;
      case ImportDataTypes.CommissionCalc:
        this.title = 'Desc_CommissionCalc'
        break;
      case ImportDataTypes.ShopContractTargets:
        this.title = 'Desc_Shop_Contract_Targets'
        break;
      case ImportDataTypes.MustSellItems:
        this.title = 'Desc_Must_Sell_Item'
        break;
case ImportDataTypes.AppData:
        this.title = this.entryFormObj && this.entryFormObj.webFormDescription ? this.entryFormObj.webFormDescription : ''
        break;
    }
    this.importHeader = this.translateService.instant(ConstantMessages.Desc_Import) + ' ' + this.translateService.instant(this.title);

        return this.modalService.open(this.content, { centered: true }).result.then(
            (result) => {
                if (result === DialogResult.Ok) {
                    return 1;
                } else {
                    return -1;
                }
            });
  }
    closeDialog() {
        this.modalService.dismissAll();
    }
    //#endregion

    //#region [METHODS]

    callImportAPI(excelFile, modal) {  
        if (+excelFile.get('importDataTypeId') == ImportDataTypes.CommissionCalc.valueOf()) {
            this.onImportCompleted.emit(excelFile);
            return;
        }


    if(+excelFile.get('importDataTypeId') == ImportDataTypes.UnlockedSerial.valueOf()
        || +excelFile.get('importDataTypeId') == ImportDataTypes.BackToStoreReset.valueOf()){
            
            excelFile.append('jsonString',JSON.stringify(''));
            excelFile.append('importWithoutSaving',false);

            if(+excelFile.get('importDataTypeId') == ImportDataTypes.UnlockedSerial.valueOf()){
                excelFile.append('formProcessMode',FormProcessMode.UnlockSerials);
            }else if(+excelFile.get('importDataTypeId') == ImportDataTypes.BackToStoreReset.valueOf())
            {
                excelFile.append('formProcessMode',FormProcessMode.BackToStoreReset);
            }
            this.coreSession.ModalLoading.Show();
            this.importService.ImportFileForSerials(excelFile).subscribe((response: ResponseModel) => {
                this.coreSession.ModalLoading.Hide();
                if (response.status != null && response.status >= 0) {
                    if (response.data.isValid) {
                        this.showResult = true;
                        this.importedRecordsCount = this.translateService.instant(ConstantMessages.Desc_Imported_Records_Count).replace('{0}', response.data.importedRecordsCount);
                        this.notImportedRecordsCount = this.translateService.instant(ConstantMessages.Desc_Not_Imported_Records_Count).replace('{0}', response.data.notImportedRecordsCount);
                        if (response.data.isEmptyExcelSheet)
                            this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.Desc_Empty_Excel_Sheet));
                        else
                            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), this.translateService.instant(ConstantMessages.Desc_Imported_Successfully));
                        this.onImportCompleted.emit(response.data);
                    }
                    else {
                        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Excel_File_Schema_Not_Match));
                    }
                } else if (response.data != null) {
                    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_All_Serials_Not_Valid));
                    //this.onShowInvalid.emit(response.data);
                }
                else {
                    this.coreSession.ModalLoading.Hide();
                    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
                }
            }, (error) => {
                this.coreSession.ModalLoading.Hide();
                this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
            });


    }
    else {
      excelFile.append('entryFormId', this.dataType == ImportDataTypes.AppData && this.entryFormObj ? this.entryFormObj.entryFormId : -1);

      this.coreSession.ModalLoading.Show();
      this.importService.importFiles(excelFile).subscribe((response: ResponseModel) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data.isValid) {
            if (this.dataType.valueOf() == ImportDataTypes.KPIs.valueOf()) {
              this.hideUploadDownload = true;
            }
            this.hideImportButton = true;
            this.showResult = true;
            this.importedRecordsCount = this.translateService.instant(ConstantMessages.Desc_Imported_Records_Count).replace('{0}', response.data.importedRecordsCount);
            this.notImportedRecordsCount = this.translateService.instant(ConstantMessages.Desc_Not_Imported_Records_Count).replace('{0}', response.data.notImportedRecordsCount);
            if (response.data.importedRecordsCount > 0) {
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), this.translateService.instant(ConstantMessages.Desc_Imported_Successfully));
            }
            if (this.dataType == ImportDataTypes.ItemsPricesWithQtyRange || this.dataType == ImportDataTypes.ItemsPricesWithoutQtyRange || this.dataType == ImportDataTypes.ShopContractTargets || this.dataType == ImportDataTypes.MustSellItems || this.dataType == ImportDataTypes.KPIs
              || this.dataType == ImportDataTypes.AppData) {
              this.onImportCompleted.emit(response.data);
            } else {
              this.onImportCompleted.emit();
            }
          }
          else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Excel_File_Schema_Not_Match));
          }
        }
        else {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      }, (error) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      });
    }
  }
  //#endregion
}
