import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject } from "rxjs";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BrandService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "Brand";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
  getAllBrands() {
    const urlValue: string = this.url + '/GetAllBrands';
    return this.http.post<ResponseModel>(urlValue, "").pipe(map(
      response => {

        return response;
      }));
  }

  saveBrand(brand: FormData) {
    const urlValue = this.url + "/InsertBrand";
    return this.http.post<ResponseModel>(urlValue, brand)
      .map(response => {
        return response;
      })
  }
  editBrand(brand: FormData) {
    const urlValue = this.url + "/UpdateBrand";
    return this.http.post<ResponseModel>(urlValue, brand)
      .map(response => {
        return response;
      })
  }

  getItemBrand() {
    const urlValue = this.url + '/GetItemBrand';
    return this.http.post<ResponseModel>(urlValue, "")
      .map(response => {
        return response;
      });
  }

  allowToDeleteBrand(brandId: number) {
    const urlValue = this.url + '/AllowToRemoveBrand';
    return this.http.post(urlValue, brandId).map(response => {
      const res = new ResponseModel();
      res.data = response['data'];
      res.message = response['message'];
      res.status = response['status'];
      return res;
    });
  }
  deleteBrand(brandId) {
    const urlValue = this.url + '/DeleteBrand';

    return this.http.post(urlValue, brandId).map(response => {
      const res = new ResponseModel();
      res.data = response['data'];
      res.message = response['message'];
      res.status = response['status'];
      return res;
    });
  }

}
