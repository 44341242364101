import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-price',
  templateUrl: './edit-price.component.html',
  styleUrls: ['./edit-price.component.css']
})
export class EditPriceComponent implements OnInit {

  @Input() editedItem: any;
  @Input() checkPriceLimit: false;

  constructor() { }

  ngOnInit() {
  }

}
