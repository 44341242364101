export enum WarehouseTransactionStatus {
  None = 0,
  Pending = 1,
  Approved = 2,
  Canceled = 3,
  Executed = 4,
  Loaded = 5,
  Offloaded = 6,
  ReadyToExecute = 7,
  ExecutedInERP = 8,
  ToBeModifiedInFO = 9,
  Uploaded = 10,
  InTransit = 11,
  Closed = 12,
  Accepted = 13,
}
