import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject } from "rxjs";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PriceService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "Price";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  getPriceLists(filter: any) {
    const urlValue: string = this.url + '/GetPriceLists';
    return this.http.post<ResponseModel>(urlValue, filter).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }

  getPriceListData(priceListId: number) {
    const urlValue: string = this.url + '/GetPriceListData';
    return this.http.post<ResponseModel>(urlValue,
      priceListId).map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }

  getPriceListHeader(priceListId: number) {
    const urlValue: string = this.url + '/GetPriceListHeader';
    return this.http.post<ResponseModel>(urlValue,
      priceListId).map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }

  getPriceListOriginalData(priceListId: number) {
    const urlValue: string = this.url + '/GetPriceListOriginalData';
    return this.http.post<ResponseModel>(urlValue,
      priceListId).map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }

  getPriceListAppliedLists(priceListId: number) {
    const urlValue: string = this.url + '/GetPriceListAppliedLists';
    return this.http.post<ResponseModel>(urlValue,
      priceListId).map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }

  getPriceListOriginalAppliedLists(priceListId: number) {
    const urlValue: string = this.url + '/GetPriceListOriginalAppliedLists';
    return this.http.post<ResponseModel>(urlValue,
      priceListId).map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }

  getPriceListOriginalHeader(priceListId: number) {
    const urlValue: string = this.url + '/GetPriceListOriginalHeader';
    return this.http.post<ResponseModel>(urlValue,
      priceListId).map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }

  getPriceListTypes() {
    const urlValue: string = this.url + '/GetPriceListTypes';
    return this.http.get<ResponseModel>(urlValue).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }

  getStockStatuses() {
    const urlValue: string = this.url + '/GetStockStatuses';
    return this.http.post<ResponseModel>(urlValue,
      null).map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }

  getSalesOrderTypes() {
    const urlValue: string = this.url + '/GetSalesOrderTypes';
    return this.http.post<ResponseModel>(urlValue,
      null).map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }
  
  insertQuantityRange(quantityRange: any) {
    const urlValue: string = this.url + '/InsertQuantityRange';
    return this.http.post(urlValue, JSON.stringify(quantityRange), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  savePriceList(priceListObj: any, isEditMode: boolean) {
    const urlValue = this.url + (isEditMode ? '/UpdatePriceList' : '/InsertPriceList');
    return this.http
      .post<ResponseModel>(urlValue, priceListObj)
      // '?priceListId=' +          priceListId,'')
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  deletePriceList(price: any) {

    const urlValue = this.url + '/DeletePriceList';
    return this.http
      .post<ResponseModel>(urlValue, price)
      // '?priceListId=' +          priceListId,'')
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
}
