import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateTimeModel } from '../../../models/dateTimeModel/date-time.model';
import { IDateTimePickerProperties } from '../../../models/dateTimeModel/date-time-properties.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WarehouseTransactionStatus } from '../../../models/enums/warehouse-transaction-status.enum';
import { CoreSession } from '../../../../core/core.session';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { StockCountingTaskStatus } from '../../../models/enums/stock-counting-task-status.enum';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderService } from '../../../../content/order/order.service';
import { OrderTypes } from '../../../models/enums/order-types.enum';
import { DynamicTemplateEmailTypes } from '../../../models/enums/dynamic-template-email-types.enum';
import { DynamicTemplateService } from '../../../../content/admin-settings/dynamic-templates/dynamic-template.service';

@Component({
  selector: 'app-main-side-filter',
  templateUrl: './main-side-filter.component.html',
  styleUrls: ['./main-side-filter.component.css']
})
export class MainSideFilterComponent implements OnInit {
  @Input() formProcessMode: FormProcessMode = FormProcessMode.LoadRequest;
  @Input('stockCountingTaskCode') stockCountingTaskCode = false;
  @Input('stockCountingTaskCodeLabel') stockCountingTaskCodeLabel = 'Desc_Stock_Counting_Task_Code';
  @Input() isWarehouseOperation = false;
  @Input('viewDivision') viewDivision = false;
  @Input('viewItem') viewItem = false;
  @Input('viewCategory') viewCategory = false;
  @Input('viewVehicle') viewVehicle = false;
  @Input('viewWarehouse') viewWarehouse = false;
  @Input('isWarehouseMandatory') isWarehouseMandatory = false;
  @Input('viewEmployee') viewEmployee = false
  @Input('viewEmployeeBasedOnSelectedSecurityGroup') viewEmployeeBasedOnSelectedSecurityGroup = false;
  @Input('viewDriver') viewDriver = false;
  @Input('viewId') viewId = false;
  @Input('viewCustomer') viewCustomer = false;
  @Input('viewOrganization') viewOrganization = false;
  @Input('viewCustomerGroup') viewCustomerGroup = false;
  @Input('viewTransactionType') viewTransactionType = false;
  @Input('viewType') viewType = false;
  @Input('viewOutlet') viewOutlet = true;
  @Input('showNewCustomerOnly') showNewCustomerOnly = false;
  @Input('viewDate') viewDate = false;
  @Input('viewSecondDate') viewSecondDate = false;
  @Input('viewThirdDate') viewThirdDate = false;
  @Input('isRangeDate') isRangeDate = false;
  @Input('isCustomerTransactions') isCustomerTransactions = false;
  @Input('isRangeSecondDate') isRangeSecondDate = false;
  @Input('isRangeThirdDate') isRangeThirdDate = false;
  @Input('viewRoute') viewRoute = false;
  @Input('idName') idName = 'Desc_Transaction_ID';
  @Input('dateLabelName') dateLabelName = 'Desc_Transaction_Date';
  @Input('date2LabelName') date2LabelName = 'Desc_Date';
  @Input('date3LabelName') date3LabelName = 'Desc_Date';
  @Input('isCustomerMandatory') isCustomerMandatory = false;
  @Input('enableSelectAllOutlets') enableSelectAllOutlets = false;
  @Input('viewTransactionStatus') viewTransactionStatus = false;
  @Input('viewstockCountingTasksStatus') viewstockCountingTasksStatus = false;
  @Input('viewOnlyTransHaveRemaining') viewOnlyTransHaveRemaining = false;
  @Input('viewCheckBoxFilter') viewCheckboxFilter = false;
  @Input('viewCustomerTransactionType') viewCustomerTransactionType = false;
  @Input('checkBoxDescription') checkboxDescription = 'Desc_Include_Inactive';
  @Input('typeDescription') typeDescription = 'Desc_Include_Inactive';
  @Input('types') types = [];
  @Input('employeeTypes') employeeTypes = [];
  @Input('transactionTypes') transactionTypes = [];
  @Input('employeeLabelText') employeeLabelText = 'Desc_Employee'
  @Input('driverLabelText') driverLabelText = 'Desc_Driver'
  @Input('stockCountingTaskMode') stockCountingTaskMode = false;
  @Input('showIncludeTripOperationsToggle') showIncludeTripOperationsToggle = false;
  @Input('showVoidedFilter') showVoidedFilter = false;
  @Input('showIncludeCashPaymentFilter') showIncludeCashPaymentFilter = false;
  @Input('isCollectInvoice') isCollectInvoice = false;
  @Input('viewOrderType') viewOrderType: boolean = false;
  @Input('outletPayInvoiceMode') outletPayInvoiceMode: boolean = false;
  @Input('orderTypeDS') orderTypeDS: any[] = [];
  @Input('orderTypeId') orderTypeId: number = -1;
  @Input('defaultOrderTypeId') defaultOrderTypeId: number = -1;
  @Input('transactionTypeDS') transactionTypeDS: any[] = [];
  @Input('transactionDate') transactionDate: any = null;
  @Input('maxDate') maxDate: any = null;
  @Input('viewSecurityGroup') viewSecurityGroup = false;
  @Input('desiredDeliveryDate') desiredDeliveryDate: DateTimeModel[];
  @Input('viewTerritory') viewTerritory = false;
  @Input('isFromCollection') isFromCollection = false;
  @Output('onFindButtonClicked')
  onFindButtonClicked = new EventEmitter();
  @Input('includeDamagedInWHFilter') includeDamagedInWHFilter = false;

  resetValues: EventEmitter<boolean> = new EventEmitter();

  allFilters: any = {};
  filterForm: FormGroup;
  checkBoxValue: boolean;
  divisionId: number;
  packId: number;
  categoryId: number;
  vehicleId: number;
  warehouseId: number;
  employeeId: number;
  driverId: number;
  transactionId: string;
  customerId: number;
  outletId: number;
  newCustomerOnly: boolean;
  customerGroupId: number;
  transactionTypeId: number;
  organizationId: number;
  secondDate: any;
  thirdDate: any;
  warehouseTransactionStatusId: number;
  stockCountingTasksStatusId: number;
  showTransWithRemaining: boolean;
  securityGroupId: number = -1;
  territoryId: number = -1;
  securityGroupIdEmitter = new EventEmitter();
  transactionDateProperties: IDateTimePickerProperties = {
    label: 'Desc_Transaction_Date',
    formControlName: 'transactionDate',
    isCalendarOnly: true,
    isRange: this.isRangeDate
  };
  secondDateProperties: IDateTimePickerProperties = {
    label: 'Desc_Date',
    formControlName: 'secondDate',
    isCalendarOnly: true,
    isRange: this.isRangeSecondDate
  };
  thirdDateProperties: IDateTimePickerProperties = {
    label: 'Desc_Date',
    formControlName: 'thirdDate',
    isCalendarOnly: true,
    isRange: this.isRangeThirdDate
  };
  fromToDate?: DateTimeModel[];
  constructor(private coreSession: CoreSession,
    private translateService: TranslateService,
    private orderService: OrderService,
    private dynamicTemplateService: DynamicTemplateService) {
  }
  ngOnInit() {
    if (this.viewOrderType) {
      this.fillOrderTypeDataSource();
    }
    if (this.viewCustomerTransactionType) {
      this.fillTransactionTypeDataSource();
    }
    if (this.maxDate != null) {
      this.transactionDateProperties.max = this.maxDate;
    }
    this.transactionDateProperties.label = this.dateLabelName;
    this.secondDateProperties.label = this.date2LabelName;
    this.thirdDateProperties.label = this.date3LabelName;
    this.filterForm = new FormGroup({
      'allData': new FormGroup({
        transactionId: new FormControl(this.transactionId),
        divisionId: new FormControl(this.divisionId),
        packId: new FormControl(this.packId),
        itemCategoryId: new FormControl(this.categoryId),
        vehicleId: new FormControl(this.vehicleId),
        employeeId: new FormControl(this.employeeId),
        driverId: new FormControl(this.driverId),
        warehouseId: new FormControl(this.warehouseId, this.isWarehouseMandatory ? Validators.required : null),
        transactionDate: new FormControl(this.transactionDate),
        secondDate: new FormControl(this.secondDate),
        thirdDate: new FormControl(this.thirdDate),
        showTransWithRemaining: new FormControl(this.showTransWithRemaining),
        customerId: new FormControl(this.customerId, this.isCustomerMandatory ? Validators.required : null),
        outletId: new FormControl(this.outletId, this.isCustomerMandatory ? Validators.required : null),
        newCustomerOnly: new FormControl(this.newCustomerOnly),
        customerGroupId: new FormControl(this.customerGroupId),
        warehouseTransactionStatusId: new FormControl(this.warehouseTransactionStatusId, Validators.required),
        stockCountingTasksStatusId: new FormControl(this.stockCountingTasksStatusId, null),
        checkBoxValue: new FormControl(this.checkBoxValue),
        stockCountingTaskCode: new FormControl(null),
        includeTripOperations: new FormControl(false),
        transactionTypeId: new FormControl(this.transactionTypeId),
        includeVoided: new FormControl(false),
        organizationId: new FormControl(this.organizationId),
        reportTypeId: new FormControl(null),
        routeId: new FormControl(null),
        orderTypeId: new FormControl(this.orderTypeId && this.orderTypeId > 0 ? this.orderTypeId : null),
        types: new FormControl(this.viewType && this.types.length > 0 ? this.types : null),
        securityGroupId: new FormControl(null),
        includeCashPayment: new FormControl(false),
        territoryId: new FormControl(null),
      })
    });
    if (this.viewDate && this.isRangeDate) {
      this.transactionDateProperties.isRange = true;
      if (this.transactionDate != null && this.transactionDate[0] != null && this.transactionDate[1] != null)
        this.filterForm.get('allData').get('transactionDate').setValue([this.transactionDate[0].date, this.transactionDate[1].date]);
      if (this.isCustomerTransactions) {
        this.setDate();
      }
    }
    if (this.viewSecondDate && this.isRangeSecondDate) {
      this.secondDateProperties.isRange = true;
      if (this.desiredDeliveryDate != null && this.desiredDeliveryDate.length == 2 && this.desiredDeliveryDate[0] != null && this.desiredDeliveryDate[1] != null)
        this.filterForm.get('allData').get('secondDate').setValue([new Date(this.desiredDeliveryDate[0].year, this.desiredDeliveryDate[0].month - 1, this.desiredDeliveryDate[0].day, 0, 0, 0),
        new Date(this.desiredDeliveryDate[1].year, this.desiredDeliveryDate[1].month - 1, this.desiredDeliveryDate[1].day, 0, 0, 0)]);
    }

    if (this.viewThirdDate && this.isRangeThirdDate) {
      this.thirdDateProperties.isRange = true;
    }
  }
  setDate() {
    this.filterForm.get("allData").get("transactionDate").setValue([new Date(), new Date()]);
  }
  onReset() {
    this.resetValues.emit(true);
    this.allFilters = {};
    this.transactionDate = null;
    this.secondDate = null;
    this.thirdDate = null;
    if (!this.viewTransactionStatus)
      this.filterForm.reset();
    else {
      if (this.formProcessMode == FormProcessMode.QualityControl) {
        this.warehouseTransactionStatusId = WarehouseTransactionStatus.Approved.valueOf();
      }
      else {
        this.warehouseTransactionStatusId = WarehouseTransactionStatus.Pending.valueOf();
      }
      this.stockCountingTasksStatusId = StockCountingTaskStatus.Pending.valueOf();
      this.filterForm = new FormGroup({
        'allData': new FormGroup({
          transactionId: new FormControl(null),
          divisionId: new FormControl(null),
          itemId: new FormControl(null),
          vehicleId: new FormControl(null),
          securityGroupId: new FormControl(null),
          employeeId: new FormControl(null),
          driverId: new FormControl(null),
          warehouseId: new FormControl(null),
          transactionDate: new FormControl(null),
          secondDate: new FormControl(null),
          thirdDate: new FormControl(null),
          customerId: new FormControl(null),
          outletId: new FormControl(null),
          newCustomerOnly: new FormControl(false),
          customerGroupId: new FormControl(null),
          showTransWithRemaining: new FormControl(null),
          warehouseTransactionStatusId: new FormControl(this.warehouseTransactionStatusId, Validators.required),
          stockCountingTasksStatusId: new FormControl(this.stockCountingTasksStatusId, Validators.required),
          checkBoxValue: new FormControl(null),
          includeTripOperations: new FormControl(false),
          transactionTypeId: new FormControl(),
          orderTypeId: new FormControl(null),
          types: new FormControl(null),
          routeId: new FormControl(null),
          territoryId: new FormControl(null),
        })
      });
      if (this.formProcessMode == FormProcessMode.QualityControl) {
        this.allFilters.warehouseTransactionStatusId = WarehouseTransactionStatus.Approved.valueOf();
      }
      else {
        this.allFilters.warehouseTransactionStatusId = WarehouseTransactionStatus.Pending.valueOf();

      }
      this.allFilters.stockCountingTasksStatusId = WarehouseTransactionStatus.Pending.valueOf();
    }
    if (this.isCustomerTransactions) {
      this.setDate();
    }
    this.securityGroupIdEmitter.emit(-1);
  }

  onTransactionDateSelected(date) {
    this.transactionDate = date;
  }

  onSecondDateSelected(date) {
    this.secondDate = date;
  }
  onThirdDateSelected(date) {
    this.thirdDate = date;
  }

  onChangeCustomerOutlet(event) {
    if (this.isCollectInvoice) {
      if (event != undefined) {
        if (event.allowPayAllOutlets) {
          this.enableSelectAllOutlets = true;
        } else {
          this.enableSelectAllOutlets = false;
        }
      }
    }
  }

  onSecurityGroupSelected(event) {
    if (event) {
      this.securityGroupId = event.id;
      this.securityGroupIdEmitter.emit(this.securityGroupId);
    } else {
      this.securityGroupId = -1;
      this.securityGroupIdEmitter.emit(this.securityGroupId);
    }

  }
  fillDateTimeModel(dateTime) {
    let dateTimeModel = new DateTimeModel();
    dateTimeModel.year = dateTime.getFullYear();
    dateTimeModel.month = dateTime.getMonth() + 1;
    dateTimeModel.day = dateTime.getDate();
    dateTimeModel.hour = dateTime.getHours();
    dateTimeModel.minute = dateTime.getMinutes();
    dateTimeModel.date = dateTime;
    return dateTimeModel;
  }
  onFindClicked() {
    if (this.isWarehouseMandatory) {
      this.filterForm.get("allData").get("warehouseId").markAsTouched();
      if (this.filterForm.get("allData").get("warehouseId").invalid) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
        return;
      }
    }
    if (!this.filterForm.get("allData").get("orderTypeId").value && this.defaultOrderTypeId > 0) {
      this.filterForm.get("allData").get("orderTypeId").setValue(this.defaultOrderTypeId);
    }
    if ((!this.secondDate || this.secondDate == null) && this.isRangeSecondDate && this.filterForm.value.allData.secondDate) {
      this.secondDate = [];
      this.secondDate[0] = this.fillDateTimeModel(this.filterForm.value.allData.secondDate[0]);
      this.secondDate[1] = this.fillDateTimeModel(this.filterForm.value.allData.secondDate[1]);
    }
    this.allFilters = {
      divisionId: this.filterForm.value.allData.divisionId === null || this.filterForm.value.allData.divisionId === '' ? -1 : this.filterForm.value.allData.divisionId,
      packId: this.filterForm.value.allData.packId === null || this.filterForm.value.allData.packId === '' ? -1 : this.filterForm.value.allData.packId,
      itemCategoryId: this.filterForm.value.allData.itemCategoryId === null || this.filterForm.value.allData.itemCategoryId === '' ? -1 : this.filterForm.value.allData.itemCategoryId,
      vehicleId: this.filterForm.value.allData.vehicleId === null || this.filterForm.value.allData.vehicleId === '' ? -1 : this.filterForm.value.allData.vehicleId,
      warehouseId: this.filterForm.value.allData.warehouseId === null || this.filterForm.value.allData.warehouseId === '' ? -1 : this.filterForm.value.allData.warehouseId,
      employeeId: this.filterForm.value.allData.employeeId === null || this.filterForm.value.allData.employeeId === '' ? -1 : this.filterForm.value.allData.employeeId,
      driverId: this.filterForm.value.allData.driverId === null || this.filterForm.value.allData.driverId === '' ? -1 : this.filterForm.value.allData.driverId,
      transactionId: this.filterForm.value.allData.transactionId === null ? '' : this.filterForm.value.allData.transactionId,
      customerId: this.filterForm.value.allData.customerId === null || this.filterForm.value.allData.customerId === '' ? -1 : this.filterForm.value.allData.customerId,
      outletId: this.filterForm.value.allData.outletId === null || this.filterForm.value.allData.outletId === '' ? ((this.outletPayInvoiceMode && !this.enableSelectAllOutlets)) ? 0 :
        ((this.enableSelectAllOutlets ? 0 : -1)) : this.filterForm.value.allData.outletId,
      newCustomerOnly: this.filterForm.value.allData.newCustomerOnly === null || false ? false : this.filterForm.value.allData.newCustomerOnly,
      customerGroupId: this.filterForm.value.allData.customerGroupId === null || this.filterForm.value.allData.customerGroupId === '' ? -1 : this.filterForm.value.allData.customerGroupId,
      transactionDate: this.transactionDate,
      secondDate: this.secondDate,
      thirdDate: this.thirdDate,
      showTransWithRemaining: this.filterForm.value.allData.showTransWithRemaining === null ? false : this.filterForm.value.allData.showTransWithRemaining,
      warehouseTransactionStatusId: this.filterForm.value.allData.warehouseTransactionStatusId === null || this.filterForm.value.allData.warehouseTransactionStatusId === '' ? -1 : this.filterForm.value.allData.warehouseTransactionStatusId,
      stockCountingTasksStatusId: this.filterForm.value.allData.stockCountingTasksStatusId === null || this.filterForm.value.allData.stockCountingTasksStatusId === '' ? -1 : this.filterForm.value.allData.stockCountingTasksStatusId,
      checkBoxValue: this.filterForm.value.allData.checkBoxValue === null || this.filterForm.value.allData.checkBoxValue === '' ? false : this.filterForm.value.allData.checkBoxValue,
      stockCountingTaskCode: this.filterForm.value.allData.stockCountingTaskCode === null || this.filterForm.value.allData.stockCountingTaskCode === '' ? -1 : this.filterForm.value.allData.stockCountingTaskCode,
      includeTripOperations: this.filterForm.value.allData.includeTripOperations,
      transactionTypeId: this.filterForm.value.allData.transactionTypeId === null || this.filterForm.value.allData.transactionTypeId === '' ? -1 : this.filterForm.value.allData.transactionTypeId,
      includeVoided: this.filterForm.value.allData.includeVoided === null || false ? false : this.filterForm.value.allData.includeVoided,
      organizationId: this.filterForm.value.allData.organizationId === null || this.filterForm.value.allData.organizationId === '' ? -1 : this.filterForm.value.allData.organizationId,
      reportTypeId: this.filterForm.value.allData.reportTypeId === null || this.filterForm.value.allData.reportTypeId === '' ? -1 : this.filterForm.value.allData.reportTypeId,
      routeId: this.filterForm.value.allData.routeId === null || this.filterForm.value.allData.routeId === '' ? -1 : this.filterForm.value.allData.routeId,
      territoryId: this.filterForm.value.allData.territoryId === null || this.filterForm.value.allData.territoryId === '' ? -1 : this.filterForm.value.allData.territoryId,
      orderTypeId: this.filterForm.value.allData.orderTypeId === null || this.filterForm.value.allData.orderTypeId === '' ? -1 : this.filterForm.value.allData.orderTypeId,
      typeValue: this.filterForm.value.allData.types === null || this.filterForm.value.allData.types === '' ? -1 : this.filterForm.value.allData.types,
      securityGroupId: this.filterForm.value.allData.securityGroupId === null || this.filterForm.value.allData.securityGroupId === '' ? -1 : this.filterForm.value.allData.securityGroupId,
      includeCashPayment: this.filterForm.value.allData.includeCashPayment === null || false ? false : this.filterForm.value.allData.includeCashPayment,
    };
    this.onFindButtonClicked.emit(this.allFilters);
  }

  fillOrderTypeDataSource() {
    var filter = [];
    filter.push(OrderTypes.Sales.valueOf());
    filter.push(OrderTypes.Return.valueOf());

    this.orderService.getOrderTypes(filter).subscribe(response => {
      if (response.status != null && response.status >= 0) {
        this.orderTypeDS = response.data;
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
    }
    );
  }
  fillTransactionTypeDataSource() {
    this.dynamicTemplateService.getDynamicTemplateEmailTypes(DynamicTemplateEmailTypes.Customer.valueOf()).subscribe(
      (response) => {
        if (response.status !== null && response.status >= 0) {
          this.transactionTypeDS = response.data;
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            this.translateService.instant(ConstantMessages.ErrorHappened)
          );
        }
      }, (error: HttpErrorResponse) => {
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    )
  }
}
