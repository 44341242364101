import { NgModule, ErrorHandler } from "@angular/core";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { MainComponent } from "./main/main.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { HomeComponent } from "../content/home/home.component";
import { AppRoutingModule } from "../app-routing.module";
import { CommonModule, DecimalPipe } from "@angular/common";
import { ContentComponent } from "./content/content.component";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "../shared/shared.module";
import { Home2Component } from "../content/home2/home2.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { LoginComponent } from "../content/login/login.component";
import { HomeLayoutComponent } from "./layout/home-layout.component";
import { LoginLayoutComponent } from "./layout/login-layout.component";
import { AuthGuard } from "./auth/auth.gurad";
import { AuthService } from "./auth/auth.service";
import { AuthErrorHandler } from "./auth/auth-error-handler";
import { LoginService } from "../content/login/login.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpClientInterceptor } from "./auth/auth-request";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { DropdownDirective } from "../shared/directives/dropdown.directive";
import { MatIconModule } from "@angular/material/icon";
import { MyProfileComponent } from "../content/my-profile/my-profile.component";
import { ImageService } from "../shared/services/image.service";
import { AboutSonicComponent } from "../content/about-sonic/about-sonic.component";
import { TranslateModule } from "@ngx-translate/core";
import { ChangeLanguageComponent } from "../content/admin-settings/change-language/change-language.component";
import { AvatarModule } from "ngx-avatar";
import { SetupWizardComponent } from "../content/admin-settings/setup/setup-wizard/setup-wizard.component";
import { NoMenusAvailableComponent } from "../content/no-menus-available/no-menus-available.component";
import { NavigationTreeComponent } from "./header/navigation-tree/navigation-tree.component";
import { GeneralSearchResultComponent } from "./header/general-search-result/general-search-result.component";
import { EmployeeKeyDialogComponent } from "../content/employee-operation/employee-key-dialog/employee-key-dialog.component";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { MatListModule } from "@angular/material/list";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { PrivacyPolicyService } from "./privacy-policy/privacy-policy.service";
import { KeyLimitDialogComponent } from "../shared/components/import-dialog/key-limit/key-limit-dialog.component";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MainComponent,
    SidebarComponent,
    HomeComponent,
    ContentComponent,
    Home2Component,
    HomeLayoutComponent,
    LoginComponent,
    LoginLayoutComponent,
    MyProfileComponent,
    NoMenusAvailableComponent,
    AboutSonicComponent,
    SetupWizardComponent,
    NavigationTreeComponent,
    GeneralSearchResultComponent,
    EmployeeKeyDialogComponent,
    PrivacyPolicyComponent,
    KeyLimitDialogComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    MatIconModule,
    TranslateModule.forChild(),
    NgbTooltipModule,
    AvatarModule,
    NgOptionHighlightModule,
    MatListModule,
  ],
  exports: [MainComponent, DropdownDirective],
  providers: [
    LoginService,
    PrivacyPolicyService,
    AuthGuard,
    AuthService,
    DecimalPipe,
    ImageService,
    { provide: ErrorHandler, useClass: AuthErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule { }
