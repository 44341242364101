import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, OnChanges, ElementRef } from '@angular/core';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { SerialsService } from '../serials.service';
import { SerialsPropertiesInterface } from '../serials-properties.interface';
import { ResponseModel } from '../../../models/api-models/api-models';
import { SerialValidationErrors } from '../../../models/enums/serial-validation-errors.enum';
import { DialogMode } from '../../../models/enums/dialog-mode.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { DateTimeModel } from '../../../models/dateTimeModel/date-time.model';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { InvalidSerialsComponent } from '../invalid-serials/invalid-serials.component';

@Component({
  selector: 'app-add-sub-serials',
  templateUrl: './add-sub-serials.component.html',
  styleUrls: ['./add-sub-serials.component.css']
})
export class AddSubSerialsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() parentSerial = '';
  @Input() parentBatchNo = '';
  @Input() parentExpiryDate;
  @Input() requiredQuantity = 0;
  @Input() serialsProperties: SerialsPropertiesInterface;
  @Input() saveEvent: Observable<void>;
  @Input() allAddedSerials = [];
  @Input() addedSerialsForAllPacks = [];
  @Output() onAddingSubSerials = new EventEmitter();
  @ViewChild("toSerialControl", { static: true }) toSerialControl: ElementRef;
  @ViewChild("invalidSerialsDialog", { static: true }) invalidSerialsDialog: InvalidSerialsComponent;
  
  saveBtnSubscription: any;
  temp_addedSerials = [];
  addedSerials = [];
  invalidSerials = [];
  numberOfAddedSerials = 0;
  isSingle = true;
  addedSerialsList = [];
  forceSingleSerials = false;
  serialTrimStartLength = 0;
  serialTrimEndLength = 0;
  productionDate: DateTimeModel = new DateTimeModel();
  desiredExpiryDate: DateTimeModel = new DateTimeModel();
  batchNo: string = '';
  enableBatch = false;
  addSerialForm: FormGroup = new FormGroup({});
  constructor(
    public coreSession: CoreSession,
    private translateService: TranslateService,
    private config: NgbModalConfig,
    private serialsService: SerialsService,
    private sessionData: SessionDataProvider

  ) {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }
  dateFormat: string;
  ngOnInit() {
    this.numberOfAddedSerials = 0;
    //this.addedSerials=this.allAddedSerials.filter(serial=>serial.parentSerial===this.parentSerial);
    this.fillConfigurationValues();
    this.subscribeSaveClick();
    this.initForm();
    this.findNumberOfAddedSerials();
  }
  ngOnChanges() {
    this.numberOfAddedSerials = 0;
    this.findNumberOfAddedSerials();
  }
  findNumberOfAddedSerials() {
    if (this.forceSingleSerials) {
      let allChildSerials = this.allAddedSerials.filter(serial => serial.parentSerial === this.parentSerial);
      if (allChildSerials) {
        this.numberOfAddedSerials = allChildSerials.length;
      }
    }
  }
  initForm() {

    this.addSerialForm = new FormGroup({
      inlineRadioOptions: new FormControl('1'),
      fromSerial: new FormControl('', (!this.isSingle ? Validators.required : null)),
      toSerial: new FormControl('', (!this.isSingle ? Validators.required : null)),
      uom: new FormControl({ value: this.serialsProperties.packObj.uom, disabled: true }),
      parentSerial: new FormControl({ value: this.parentSerial, disabled: true }),
      requiredQnty: new FormControl({ value: this.requiredQuantity, disabled: true }),
    });
    this.onSingleSerialClicked();
  }

  fillConfigurationValues() {
    this.forceSingleSerials = this.sessionData.getConfigurationValue(ConstantConfigurations.ForceSingleSerials).toLowerCase() === "true";
    if(this.serialsProperties.packObj.packHasTrimSerialRule){
      this.serialTrimStartLength = this.serialsProperties.packObj.packTrimSerialStartDigits;
      this.serialTrimEndLength =this.serialsProperties.packObj.packTrimSerialEndDigits;
     }else{
      this.serialTrimStartLength = +this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTrimStartLength);
      this.serialTrimEndLength = +this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTrimEndLength);
     }
   
    this.dateFormat = this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
  }
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.saveEvent.subscribe(() => {
      this.onAddSerials();
    });
  }
  onSingleSerialClicked() {
    this.fromSerialInput.enable({ onlySelf: true, emitEvent: false });
    this.fromSerialInput.setValidators([Validators.required]);
    this.toSerialInput.setValue('');
    this.toSerialInput.disable();
    this.isSingle = true;
  }
  onRangeSerialClicked() {
    this.fromSerialInput.enable({ onlySelf: true, emitEvent: false });
    this.fromSerialInput.setValidators([Validators.required]);
    this.toSerialInput.enable({ onlySelf: true, emitEvent: false });
    this.toSerialInput.setValidators([Validators.required]);
    this.isSingle = false;
  }
  onSaveClicked() {
    let data = {
      addedSerials: this.addedSerials,
      invalidSerials: this.invalidSerials
    }
    this.onAddingSubSerials.emit(data);
  }

  get fromSerialInput() {
    return this.addSerialForm.get('fromSerial');
  }
  get toSerialInput() {
    return this.addSerialForm.get('toSerial');
  }
  onCancelAddSerials() {
    this.fromSerialInput.setValue(null);
    this.toSerialInput.setValue(null);
  }
  onEnterClickedFromSerial() {
    if (this.isSingle) {
      this.onAddSerials();
    } else {
      this.toSerialControl.nativeElement.focus();
      //this.renderer.selectRootElement('#input').focus();
    }
  }
  onAddSerials() {
    let someSerialsInvalid = false;
    this.addedSerials = [];
    this.invalidSerials = [];
    this.temp_addedSerials = [];
    if (this.prepareAndValidateAddedSerialsInUI()) {
      if (this.temp_addedSerials && this.temp_addedSerials.length > 0) {
        this.coreSession.ModalLoading.Show();
        this.serialsService.validateSerial(this.temp_addedSerials).subscribe(
          (response: ResponseModel) => {
            this.addedSerials = [];
            this.invalidSerials = [];
            this.coreSession.ModalLoading.Hide();
            if (response.status != null && response.status >= 0) {
              this.temp_addedSerials = response.data;
              this.temp_addedSerials.forEach(serial => {
                if (serial.serialValidationErrors == SerialValidationErrors.None) {
                  serial.parentSerial = this.parentSerial;
                  serial.packId = this.serialsProperties.packObj.packId;
                  serial.itemId = this.serialsProperties.packObj.itemId;
                  serial.serialsCount=1;
                    serial.serialNumberStatus = this.serialsProperties.newSerialStatus;
                  if (this.serialsProperties.packObj.templateId > 0) {

                    serial.batchNo = this.batchNo
                    serial.expiryDateModel = this.desiredExpiryDate;
                  }
                  else {
                    serial.batchNo = this.serialsProperties.packObj.batchNo;
                    serial.expiryDateModel = this.serialsProperties.packObj.expiryDateModel;
                  }
                  if (this.serialsProperties.packObj.templateId > 0) {
                    if (serial.batchNo === this.parentBatchNo && serial.expiryDateModel.day === this.parentExpiryDate.day
                      && serial.expiryDateModel.month === this.parentExpiryDate.month && serial.expiryDateModel.year === this.parentExpiryDate.year)
                      this.addedSerials.push(serial);
                    else {
                      serial.serialValidationErrors = SerialValidationErrors.SubSerialHasDifferentBatchOrExpiry;
                      someSerialsInvalid = true;
                      this.invalidSerials.push(serial);
                    }
                  } else {
                    this.addedSerials.push(serial);
                  }

                } else {
                  someSerialsInvalid = true;
                  this.invalidSerials.push(serial);
                }
              });
              if (someSerialsInvalid) {
                this.fillInvalidDataSource();
                // this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption),this.translateService.instant(ConstantMessages.Desc_Some_Serials_Not_Valid));
              }
            } else {
              this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
            }
            this.onSaveClicked();
            this.findNumberOfAddedSerials();
          }, (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
          });
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_All_Serials_Not_Valid));
        this.onSaveClicked();
        this.findNumberOfAddedSerials();
      }
      this.clearUI();

    } else {
      this.onSaveClicked();
      this.findNumberOfAddedSerials();
    }

  }
  fillInvalidDataSource() {
    // this.invalidSerialsDataSource = {
    //   data: [],
    //   totalItems: 0
    // }
    if (this.invalidSerials && this.invalidSerials.length > 0) {
    //   this.navLinks[1].hidden = false;

     //  this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption),this.translateService.instant(ConstantMessages.Desc_Some_Serials_Invalid));
      this.invalidSerials.forEach(serial => {
       
        if(serial.serialValidationErrors && serial.serialValidationErrors!=SerialValidationErrors.None)
        serial.errorString = this.serialsService.getErrorText(serial.serialValidationErrors);
      });
      // this.invalidSerialsDataSource = {
      //   data: this.invalidSerials,
      //   totalItems: this.invalidSerials.length
      // }
      setTimeout(() => this.invalidSerialsDialog.showDialog().then(
        (result) => {
          this.clearUI();
        }));
      
    }else{
      this.clearUI();
    }
  }
  prepareAndValidateAddedSerialsInUI() {
    if (this.addSerialForm.invalid) {
      this.coreSession.markFormGroupTouched(this.addSerialForm);
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return false;
    }
    if (this.forceSingleSerials) {

      if (this.isSingle) {
        return this.handleSingleSerial();

      } else {
        return this.handleDivideRangeSerial();
      }
    }
    return true;
  }

  validateBarcodeWithTemplate(fullBarcode) {
    if (fullBarcode.length != this.serialsProperties.packObj.totalFieldsLengthOfItemTemplate) {
      return false;
    }
    if (!this.fillBatchAndExpiryFromTemplate(fullBarcode)) {

      return false;
    }
    if (!this.validateGTINInTemplate(fullBarcode)) {

      return false;
    }
    if (!this.validateProductionDateInTemplate(fullBarcode)) {

      return false;
    }
    return true;
  }
  validateGTINInTemplate(fullSerial) {
    let trimmedGTIN = '-1';
    if (this.serialsProperties.packObj.templateGTINIndecies && this.serialsProperties.packObj.templateGTINIndecies.length === 2) {
      trimmedGTIN = fullSerial.substring(this.serialsProperties.packObj.templateGTINIndecies[0], this.serialsProperties.packObj.templateGTINIndecies[1] + 1);

    }
    if (trimmedGTIN !== '-1' && trimmedGTIN !== this.serialsProperties.packObj.gtin) {
      return false;
    }
    return true;
  }
  validateProductionDateInTemplate(fullSerial) {
    if (this.serialsProperties.packObj.productionIncludedInTemplate) {
      let dateTrimmed = this.trimProductionDateFromTemplate(fullSerial);
      if (dateTrimmed != '-1' && dateTrimmed != '') {
        let dateFromFormat = this.getDateAsString(dateTrimmed);
        if (!dateFromFormat || dateFromFormat === '') return false;
        let date = this.coreSession.validatedate(dateFromFormat, this.dateFormat);
        if (date != null)
          this.productionDate = this.coreSession.getDateTimeModel(date);
        else
          return false;
      }
    } else {
      return true;
    }

    return true;
  }
  fillBatchAndExpiryFromTemplate(fullSerial) {
    if (this.serialsProperties.packObj.enableBatchForSerial) {
      if (this.serialsProperties.packObj.expiryIncludedInTemplate) {
        let expiryDateTrimmed = this.trimExpiryDateFromTemplate(fullSerial);
        if (expiryDateTrimmed != '-1' && expiryDateTrimmed != '') {
          let dateFromFormat = this.getDateAsString(expiryDateTrimmed);
          if (!dateFromFormat || dateFromFormat === '') return false;
          let date = this.coreSession.validatedate(dateFromFormat, this.dateFormat);
          if (date != null)
            this.desiredExpiryDate = this.coreSession.getDateTimeModel(date);
          else
            return false;
        }
      }
      else
        this.desiredExpiryDate = this.serialsProperties.packObj.expiryDateModel;
    }
    if (this.serialsProperties.packObj.enableBatchForSerial) {
      if (this.serialsProperties.packObj.batchIncludedInTemplate) {
        let batch = this.trimBatchNoFromTemplate(fullSerial);
        if (batch != '-1')
          this.batchNo = batch;
        else
          return false;
      }
      else
        this.batchNo = this.serialsProperties.packObj.batchNo;
    }
    return true;
  }
  getDateAsString(trimmedDate) {
    let returnedDate = '';
    let dateFormat = this.sessionData.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
    if (trimmedDate && trimmedDate.length == dateFormat.length) {
      returnedDate = trimmedDate.toString();
    }
    return returnedDate;
  }
  trimSerialFromTemplate(serial) {
    var trimmedSerial = serial;
    var startString = '';
    var endString = '';
    if (this.serialsProperties.packObj.templateId > 0 && this.serialsProperties.packObj.templateSerialsIndecies && this.serialsProperties.packObj.templateSerialsIndecies.length === 2) {
      trimmedSerial = serial.substring(this.serialsProperties.packObj.templateSerialsIndecies[0], this.serialsProperties.packObj.templateSerialsIndecies[1] + 1);
      startString = serial.substring(0, this.serialsProperties.packObj.templateSerialsIndecies[0]);
      endString = serial.substring(this.serialsProperties.packObj.templateSerialsIndecies[1] + 1, serial.length);
    }
    return {
      trimmedSerial: trimmedSerial,
      startString: startString,
      endString: endString
    };
  }

  trimBatchNoFromTemplate(serial) {
    var trimmedBatch = '-1';
    if (this.serialsProperties.packObj.templateId > 0 && this.serialsProperties.packObj.templateBatchNoIndecies && this.serialsProperties.packObj.templateBatchNoIndecies.length === 2) {
      trimmedBatch = serial.substring(this.serialsProperties.packObj.templateBatchNoIndecies[0], this.serialsProperties.packObj.templateBatchNoIndecies[1] + 1);
    }
    return trimmedBatch;
  }
  trimExpiryDateFromTemplate(serial) {
    let trimmedExpiry = '-1';
    if (this.serialsProperties.packObj.templateId > 0 && this.serialsProperties.packObj.templateExpiryDateIndecies && this.serialsProperties.packObj.templateExpiryDateIndecies.length === 2) {
      trimmedExpiry = serial.substring(this.serialsProperties.packObj.templateExpiryDateIndecies[0], this.serialsProperties.packObj.templateExpiryDateIndecies[1] + 1);
    }
    return trimmedExpiry;
  }
  trimProductionDateFromTemplate(serial) {
    let trimmedDate = '-1';
    if (this.serialsProperties.packObj.templateId > 0 && this.serialsProperties.packObj.templateProductionDateIndecies && this.serialsProperties.packObj.templateProductionDateIndecies.length === 2) {
      trimmedDate = serial.substring(this.serialsProperties.packObj.templateProductionDateIndecies[0], this.serialsProperties.packObj.templateProductionDateIndecies[1] + 1);
    }
    return trimmedDate;
  }
  handleSingleSerial() {
    if (this.fromSerialInput.value.length < (this.serialTrimStartLength + this.serialTrimEndLength)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
      return false;
    }
    if (this.numberOfAddedSerials + 1 > this.requiredQuantity) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serials_Count_More_Than_Requested));
      return false;
    }

    var fromSerial = this.fromSerialInput.value.substring(this.serialTrimStartLength, this.fromSerialInput.value.length);
    fromSerial = fromSerial.substring(0, fromSerial.length - this.serialTrimEndLength);
    var fullSerial = fromSerial;
    var temp_serialObj = {
      formProcessMode: this.serialsProperties.itemFormProcessMode,
      fromSerial: fromSerial,
      toSerial: fromSerial,
      warehouseId: this.serialsProperties.warehouseId,
      vehicleId: this.serialsProperties.vehicleId,
      parentSerial: this.parentSerial,
      packId: this.serialsProperties.packObj.packId,
      fullSerial: fullSerial,
      serialValidationErrors: SerialValidationErrors.None,
      price: this.serialsProperties.packObj.price,
      childPackPrice: this.serialsProperties.packObj.childPackPrice,
      salesTransactionTypeId: this.serialsProperties.packObj.salesTransactionTypeId
    };
    if (this.serialsProperties.packObj.templateId > 0 && !this.validateBarcodeWithTemplate(fullSerial)) {
      temp_serialObj.serialValidationErrors = SerialValidationErrors.BarcodeNotCompatibleWithTemplate;
      this.invalidSerials.push(temp_serialObj);
      return false;
    }
    if (this.allAddedSerials.findIndex(serial => serial.fromSerial === temp_serialObj.fromSerial) === -1) {
      var index = this.addedSerialsForAllPacks.filter(serial => serial.itemId !== this.serialsProperties.packObj.itemId && serial.packId !== this.serialsProperties.packObj.packId).findIndex(serial => serial.fromSerial === temp_serialObj.fromSerial);
      if (index === -1) {
        this.temp_addedSerials.push(temp_serialObj);
        return true;
      } else {
        if (this.addedSerialsForAllPacks[index].itemId === this.serialsProperties.packObj.itemId)
          temp_serialObj.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
        else
          temp_serialObj.serialValidationErrors = SerialValidationErrors.SerialEnteredBeforeForAnotherItem;
        this.invalidSerials.push(temp_serialObj);
        return false;
      }

    }
    else {
      temp_serialObj.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
      this.invalidSerials.push(temp_serialObj);
      return false;
    }

  }
  handleDivideRangeSerial() {
    let fromSerial = '', toSerial = '', fullSerial = '';
    let fromSerialNumberPart = 0, toSerialNumberPart = 0, fromSerialNumberLength = 0, toSerialNumberLength = 0;
    let fromSerialCharPart = '', toSerialCharPart = '';
    if (this.fromSerialInput.value.length != this.toSerialInput.value.length) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Not_Equals));
      return false;
    }
    if (this.fromSerialInput.value.length < (this.serialTrimStartLength + this.serialTrimEndLength)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serial_Digits_Less_Than_Trim_Digits));
      return false;
    }
    fromSerial = this.fromSerialInput.value.substring(this.serialTrimStartLength, this.fromSerialInput.value.length);
    fromSerial = fromSerial.substring(0, fromSerial.length - this.serialTrimEndLength);
    fullSerial = fromSerial;
    var fromSerialParts = this.trimSerialFromTemplate(fromSerial);
    fromSerial = fromSerialParts.trimmedSerial;

    toSerial = this.toSerialInput.value.substring(this.serialTrimStartLength, this.toSerialInput.value.length);
    toSerial = toSerial.substring(0, toSerial.length - this.serialTrimEndLength);
    var toSerialParts = this.trimSerialFromTemplate(toSerial);
    toSerial = toSerialParts.trimmedSerial;

    if (fromSerialParts.startString !== toSerialParts.startString || fromSerialParts.endString !== toSerialParts.endString) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Range_Serial_Not_Valid));
      return false;
    }
    var matches = fromSerial.match(/\d+/g);
    if (matches && matches.length > 0) {
      fromSerialNumberPart = +matches[matches.length - 1];
      fromSerialNumberLength = matches[matches.length - 1].length;
      if (fromSerial.substring(fromSerial.length - fromSerialNumberLength, fromSerial.length) !== matches[matches.length - 1]) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Range_Serial_Not_Valid));
        return false;
      }
      fromSerialCharPart = fromSerial.substring(0, fromSerial.length - fromSerialNumberLength)
    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Range_Serial_Not_Valid));
      return false;
    }
    var matches = toSerial.match(/\d+/g);
    if (matches && matches.length > 0) {
      toSerialNumberPart = +matches[matches.length - 1];
      toSerialNumberLength = matches[matches.length - 1].length;
      if (toSerial.substring(toSerial.length - toSerialNumberLength, toSerial.length) !== matches[matches.length - 1]) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Range_Serial_Not_Valid));
        return false;
      }
      toSerialCharPart = toSerial.substring(0, toSerial.length - toSerialNumberLength)
    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Range_Serial_Not_Valid));
      return false;
    }
    if (fromSerialNumberLength === toSerialNumberLength && fromSerialCharPart === toSerialCharPart && fromSerialNumberPart <= toSerialNumberPart) {
      if (this.numberOfAddedSerials + toSerialNumberPart - fromSerialNumberPart + 1 > this.requiredQuantity) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Serials_Count_More_Than_Requested));
        return false;
      }
      if (this.serialsProperties.packObj.templateId > 0 && !this.validateBarcodeWithTemplate(fullSerial)) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Barcode_Not_Compatible_With_Template));
        return false;
      }
      for (var i = fromSerialNumberPart; i <= toSerialNumberPart; i++) {
        var temp_serialObj = {
          formProcessMode: this.serialsProperties.itemFormProcessMode,
          fromSerial: fromSerialParts.startString + fromSerialCharPart + this.padLeft(i + '', '0', fromSerialNumberLength) + fromSerialParts.endString,
          toSerial: fromSerialParts.startString + fromSerialCharPart + this.padLeft(i + '', '0', fromSerialNumberLength) + fromSerialParts.endString,
          warehouseId: this.serialsProperties.warehouseId,
          vehicleId: this.serialsProperties.vehicleId,
          parentSerial: this.parentSerial,
          packId: this.serialsProperties.packObj.packId,
          fullSerial: fromSerialParts.startString + fromSerialCharPart + this.padLeft(i + '', '0', fromSerialNumberLength) + fromSerialParts.endString,
          serialValidationErrors: SerialValidationErrors.None,
          price: this.serialsProperties.packObj.price,
          childPackPrice: this.serialsProperties.packObj.childPackPrice,
          salesTransactionTypeId: this.serialsProperties.packObj.salesTransactionTypeId
        };
        if (this.allAddedSerials.findIndex(serial => serial.fromSerial === temp_serialObj.fromSerial) === -1) {
          var index = this.addedSerialsForAllPacks.filter(serial => serial.itemId !== this.serialsProperties.packObj.itemId && serial.packId !== this.serialsProperties.packObj.packId).findIndex(serial => serial.fromSerial === temp_serialObj.fromSerial);
          if (index === -1) {
            this.temp_addedSerials.push(temp_serialObj);

          } else {
            if (this.addedSerialsForAllPacks[index].itemId === this.serialsProperties.packObj.itemId)
              temp_serialObj.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
            else
              temp_serialObj.serialValidationErrors = SerialValidationErrors.SerialEnteredBeforeForAnotherItem;
            this.invalidSerials.push(temp_serialObj);
          }
        }
        else {
          temp_serialObj.serialValidationErrors = SerialValidationErrors.SerialEnteredBefore;
          this.invalidSerials.push(temp_serialObj);
        }
      }
      return true;
    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Range_Serial_Not_Valid));
      return false;
    }
  }
  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
  }
  clearUI() {
    this.fromSerialInput.setValue(null);
    this.toSerialInput.setValue(null);
    this.fromSerialInput.markAsUntouched();
    this.toSerialInput.markAsUntouched();
    this.fromSerialInput.updateValueAndValidity();
    this.toSerialInput.updateValueAndValidity();
  }
}
