import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { CoreSession } from "../../../../core/core.session";
import { TranslateService } from "@ngx-translate/core";
import { ResponseModel } from "../../../models/api-models/api-models";


@Injectable({
  providedIn: "root",
})
export class PromotionGroupsService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession,
    private translateService: TranslateService
  ) {
    this.url = baseUrl + "PromotionGroups";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
  getPromotionGroupsList(filter: any) {
    const urlValue = this.url + '/GetPromotionGroupsList';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    });
  }
  UpdatePromotionGroup(promotionGroupObject: any) {
    const urlValue: string = this.url + "/UpdatePromotionGroup";
    return this.http.post<ResponseModel>(urlValue, promotionGroupObject).map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }
  InsertPromotionGroup(promotionGroupObject: any) {
    const urlValue: string = this.url + "/InsertPromotionGroup";
    return this.http.post<ResponseModel>(urlValue, promotionGroupObject).map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }
  getPromotionGroupData(promotionGroupId: number) {
    const urlValue: string = this.url + "/GetPromotionGroupData";

    return this.http.post<ResponseModel>(urlValue, promotionGroupId).map(
      response => {
        return response;
      });
  }
  getAssingedPromotionGroup() {
    const urlValue = this.url + "/GetAssingedPromotionGroup";
    return this.http.get<ResponseModel>(urlValue).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }
  deletePromotionGroupData(promotionGroupId: number) {
    const urlValue: string = this.url + "/DeletePromotionGroupData";
    return this.http.post<ResponseModel>(urlValue, promotionGroupId).map(
      response => {
        return response;
      });
  }
  deletePromotionAssignment(promotionId: number) {
    const urlValue: string = this.url + "/DeletePromotionAssignment";
    return this.http.post<ResponseModel>(urlValue, promotionId).map(
      response => {
        return response;
      });
  }
  GetUnAssigmentPromotions(promotionFilter: any) {
    const urlValue: string = this.url + '/GetUnAssigmentPromotions';
    return this.http.post<ResponseModel>(urlValue, promotionFilter)
      .map(
        (response) => {
          const result = new ResponseModel();
          result.data = response['data'];
          result.message = response['message'];
          result.status = response['status'];
          return result;
        });
  }
}
