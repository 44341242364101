import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { CustomerHierarchyService } from '../../services/data-definition/customer/customer-hierarchy.service';
import { CustomerService } from '../../services/data-definition/customer/customers.service';
import { MasterDataService } from '../../../content/master-data-definition/master-data.service';
import { CoreSession } from '../../../core/core.session';
import { SessionDataProvider } from '../../../core/session-data-provider.service';
import { ConstantConfigurations } from '../../models/constants/constant-configuration';
import { ConstantMessages } from '../../models/constants/constant-message';
import { CustomersHierarchyLevel } from '../../models/enums/customers-hierarchy-level';
import { DialogMode } from '../../models/enums/dialog-mode.enum';
import { DialogResult } from '../../models/enums/dialog-result.enum';
import { GeographicalLocationHierarchyLevel } from '../../models/enums/geographical-location-hierarchy-Level';
import { ITextInput } from '../../models/shared-table/custom-list.interface';
import { GeolocationService } from '../../services/geolocation.service';

@Component({
  selector: 'app-tree-hierarchy-entry',
  templateUrl: './tree-hierarchy-entry.component.html',
  styleUrls: ['./tree-hierarchy-entry.component.css']
})
export class TreeHierarchyEntryComponent implements OnInit {

  saveBtnSubscription: any;
  @Input() isEditMode = false;
  @Input() saveEvent: Observable<void>;
  @Input() entryFormType: any;
  @Input() nodeData: any;
  @Output() onAddingSuccessfully = new EventEmitter();
  @Input() isLocationMode = false;
  parentForm: FormGroup;
  nameLabel = '';
  codeLabel = '';
  isOrganizationLoading: boolean = false;
  organizationsList: any[] = [];
  showOrganization = false;
  deleteCustomersFromGroup = false;
  nameFieldProp: ITextInput = {
    formControlName: "name",
    placeHolder: this.nameLabel,
    label: this.nameLabel,
    isRequierd: true
  };

  otherLanguages: any[] = [];

  constructor(
    private masterDataService: MasterDataService,
    private customerService: CustomerService,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private sessionData: SessionDataProvider,
    private locationService: GeolocationService,
    private customerHierarchyService: CustomerHierarchyService,
  ) { }

  ngOnInit() {
    this.subscribeSaveClick();
    if (this.entryFormType == CustomersHierarchyLevel.Group && !this.isLocationMode) {
      this.showOrganization = true;
      this.fillOrganizationList()
    }
    this.setLabelsControls();
    this.initializeForm();


    if (this.isEditMode) {
      let rowIndex = this.nodeData.descriptions.findIndex(x => x.languageId === this.coreSession.selectedLanguageId);
      if (rowIndex >= 0) {
        this.parentForm.controls.name.setValue(this.nodeData.descriptions[rowIndex].description);
      }
      this.parentForm.controls.code.setValue(this.nodeData.nodeCode);
      if (this.showOrganization) {
        this.parentForm.controls.organizationId.setValue(this.nodeData.organizationId != -1 ? this.nodeData.organizationId : null);
      }
    }
    if (!this.forceOrganizationFilterForCustomerGroups())
      this.showOrganization = false;


  }

  fillOrganizationList() {
    this.organizationsList = [];
    this.isOrganizationLoading = true;
    this.masterDataService.getOrganizationList().subscribe(result => {
      this.organizationsList = result;
      this.isOrganizationLoading = false;
      let indexRow = this.organizationsList.findIndex(x => x.organizationId == this.nodeData.organizationId);
      if (indexRow < 0 && this.isEditMode) {
        this.showOrganization = false;
      }
    });
  }

  onOtherLanguagesTextSubmitted(event) {
    this.otherLanguages = event;
  }

  setLabelsControls() {
    if (!this.isLocationMode) {
      switch (this.entryFormType) {
        case CustomersHierarchyLevel.Channel:
          this.nameFieldProp.placeHolder = this.nameFieldProp.label = 'Desc_Channel_Name';
          this.codeLabel = 'Desc_Channel_Code';
          break;
        case CustomersHierarchyLevel.SubChannel:
          this.nameFieldProp.placeHolder = this.nameFieldProp.label = 'Desc_Sub_Channel_Name';
          this.codeLabel = 'Desc_Sub_Channel_Code';
          break;
        case CustomersHierarchyLevel.Group:
          this.nameFieldProp.placeHolder = this.nameFieldProp.label = 'Desc_Group_Name';
          this.codeLabel = 'Desc_Group_Code';
          this.showOrganization = true;
          break;
      }
    } else {
      switch (this.entryFormType) {
        case GeographicalLocationHierarchyLevel.Country:
          this.codeLabel = 'Desc_Geographical_Location_Code_Five';
          this.nameFieldProp.placeHolder = this.nameFieldProp.label = 'Desc_Geographical_Location_Name_Five';
          break;
        case GeographicalLocationHierarchyLevel.State:
          this.codeLabel = 'Desc_Geographical_Location_Code_Four';
          this.nameFieldProp.placeHolder = this.nameFieldProp.label = 'Desc_Geographical_Location_Name_Four';
          break;
        case GeographicalLocationHierarchyLevel.City:
          this.codeLabel = 'Desc_Geographical_Location_Code_Three';
          this.nameFieldProp.placeHolder = this.nameFieldProp.label = 'Desc_Geographical_Location_Name_Three';
          break;
        case GeographicalLocationHierarchyLevel.Area:
          this.codeLabel = 'Desc_Geographical_Location_Code_Two';
          this.nameFieldProp.placeHolder = this.nameFieldProp.label = 'Desc_Geographical_Location_Name_Two';
          break;
        case GeographicalLocationHierarchyLevel.Street:
          this.codeLabel = 'Desc_Geographical_Location_Code_One';
          this.nameFieldProp.placeHolder = this.nameFieldProp.label = 'Desc_Geographical_Location_Name_One';
          break;
      }
    }
  }

  initializeForm() {
    this.parentForm = new FormGroup({
      name: new FormControl("", Validators.required),
      code: new FormControl("", Validators.required),
      organizationId: new FormControl(null, this.showOrganization && this.forceOrganizationFilterForCustomerGroups() && !this.isLocationMode ? Validators.required : null),
    });
  }

  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.saveEvent.subscribe(() => {
      this.onSaveClicked();
    });
  }

  onSaveClicked() {
    if (this.parentForm.valid) {
      if (this.isLocationMode) {
        this.saveUpdateLocationHierarchyData();
      } else {
        this.saveUpdateCustomersHierarchyData();
      }
    } else {
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgFillMandatory));
      this.parentForm.markAllAsTouched()
    }
  }

  fillGroup() {
    let result = -1;

    if (this.entryFormType == CustomersHierarchyLevel.Group) {
      result = this.isEditMode ? this.nodeData.nodeId : -1
    }
    return result;
  }

  fillChannel() {
    let result = -1;
    if (this.entryFormType == CustomersHierarchyLevel.Channel) {
      result = this.isEditMode ? this.nodeData.nodeId : -1;
    } else if (this.entryFormType == CustomersHierarchyLevel.Group) {
      result = this.isEditMode ? this.nodeData.channelId : this.nodeData.parentId;
    } else if (this.entryFormType == CustomersHierarchyLevel.SubChannel) {
      result = this.isEditMode ? this.nodeData.parentId : this.nodeData.nodeId;
    }

    return result;
  }

  fillSubChannel() {
    let result = -1;
    if (this.entryFormType == CustomersHierarchyLevel.SubChannel) {
      result = this.isEditMode ? this.nodeData.nodeId : -1;
    } else if (this.entryFormType == CustomersHierarchyLevel.Group) {
      result = this.isEditMode ? this.nodeData.parentId : this.nodeData.nodeId;
    }
    return result;
  }

  fillDescriptions() {
    let descriptions = this.otherLanguages;
    let oldValue = descriptions.findIndex(x => x.languageId === this.coreSession.selectedLanguageId);
    if (oldValue >= 0) descriptions.splice(oldValue, 1);
    if (descriptions.findIndex(x => x.languageId === this.coreSession.selectedLanguageId) < 0) {
      descriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        description: this.parentForm.controls.name.value
      });
    }
    return descriptions;
  }

  saveUpdateCustomersHierarchyData() {

    var customersHierarchyData =
    {
      channelId: this.fillChannel(),
      subChannelId: this.fillSubChannel(),
      groupId: this.fillGroup(),
      code: this.parentForm.controls.code.value,
      organizationId: this.parentForm.controls.organizationId.value != null ? this.parentForm.controls.organizationId.value : -1,
      customersHierarchyLevel: this.entryFormType,
      isEditMode: this.isEditMode,
      descriptions: this.fillDescriptions(),
      deleteCustomersFromGroup: this.deleteCustomersFromGroup
    }
    this.coreSession.ModalLoading.Show();
    this.customerHierarchyService.saveUpdateCustomersHierarchyData(customersHierarchyData, this.isEditMode).subscribe(
      result => {
        this.coreSession.ModalLoading.Hide();
        if (result.status != null && result.status >= 0) {
          this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
          this.onAddingSuccessfully.emit();
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), result.message);
        }
      },
      error => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }

  saveUpdateLocationHierarchyData() {
    var locationHierarchyData =
    {
      countryId: this.fillCountry(),
      stateId: this.fillState(),
      cityId: this.fillCity(),
      areaId: this.fillArea(),
      streetId: this.isEditMode ? this.nodeData.nodeId : -1,
      code: this.parentForm.controls.code.value,
      geographicalLocationHierarchyLevel: this.entryFormType,
      isEditMode: this.isEditMode,
      nodeId: this.isEditMode ? this.nodeData.nodeId : -1,
      descriptions: this.fillDescriptions()
    }
    this.coreSession.ModalLoading.Show();
    this.locationService.saveUpdateLocationHierarchyData(locationHierarchyData).subscribe(
      result => {
        this.coreSession.ModalLoading.Hide();
        if (result.status != null && result.status >= 0) {
          this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
          if (this.isEditMode && result.data != null) {

            if (locationHierarchyData.geographicalLocationHierarchyLevel == GeographicalLocationHierarchyLevel.State) {
              result.data.isStateLevel = true;
            } else {
              result.data.isStateLevel = false;
            }
            this.onAddingSuccessfully.emit(result.data);
          }
          else
            this.onAddingSuccessfully.emit(this.nodeData);
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), result.message);
        }
      },
      error => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }

  fillCountry() {

    let result = -1;
    if ((this.isEditMode && this.entryFormType == GeographicalLocationHierarchyLevel.Country)) {
      result = this.nodeData.nodeId;
    } else {
      result = this.nodeData.countryId != undefined ? this.nodeData.countryId : this.nodeData.nodeId;
    }
    return result;
  }

  fillState() {

    let result = -1;
    if ((this.isEditMode && this.entryFormType == GeographicalLocationHierarchyLevel.State)) {
      result = this.nodeData.nodeId;
    } else {
      result = this.nodeData.stateId != undefined ? this.nodeData.stateId : this.nodeData.nodeId;
    }
    return result;
  }

  fillCity() {

    let result = -1;
    if ((this.isEditMode && this.entryFormType == GeographicalLocationHierarchyLevel.City)) {
      result = this.nodeData.nodeId;
    } else {
      result = this.nodeData.cityId != undefined ? this.nodeData.cityId : this.nodeData.nodeId;
    }
    return result;
  }

  fillArea() {

    let result = -1;
    if ((this.isEditMode && this.entryFormType == GeographicalLocationHierarchyLevel.Area)) {
      result = this.nodeData.nodeId;
    } else {
      result = this.nodeData.areaId != undefined ? this.nodeData.areaId : this.nodeData.nodeId;
    }
    return result;
  }

  forceOrganizationFilterForCustomerGroups() {
    return this.sessionData.getConfigurationValue(ConstantConfigurations.ForceOrganizationFilterForCustomerGroups).toLowerCase() === "true"
  }

  onChangeSelectedOrg(event: any) {

    if (event && this.isEditMode && this.showOrganization && this.forceCustomerOrganizationSelection()) {
      this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgConfirmationChangeOrgByGroup), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
        (result: DialogResult) => {
          if (result === DialogResult.Yes) {
            this.deleteCustomersFromGroup = true;
          } else {
            this.deleteCustomersFromGroup = false;
            this.parentForm.controls.organizationId.setValue(this.nodeData.organizationId != -1 ? this.nodeData.organizationId : null);
          }
        });
    }
  }

  forceCustomerOrganizationSelection() {
    return this.sessionData.getConfigurationValue(ConstantConfigurations.ForceCustomerOrganizationSelection).toLowerCase() === "true";
  }


}
