import { Component, OnInit, Output, EventEmitter, ViewChild, Input, ElementRef } from '@angular/core';
import { ImportDataTypes } from '../../../models/import/ImportDataTypes';
import { FormGroup } from '@angular/forms';
import { ImportService } from '../../../services/import.service';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { ResponseModel } from '../../../models/api-models/api-models';
import { Observable } from 'rxjs';
import { WarehouseTransaction } from '../../../models/warehouse-transactions/warehouse-transaction.model';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { InputVouchersService } from '../../../services/warehouse-operation/input-vouchers.service';
import { OutputVouchersService } from '../../../services/warehouse-operation/output-vouchers.service';
import { TransferInService } from '../../../services/warehouse-operation/transfer-in.service';
import { TransferOutService } from '../../../services/warehouse-operation/transfer-out.service';
import { LoadRequestService } from '../../../services/vehicle-operation/load-request.service';
import { OffloadRequestService } from '../../../services/vehicle-operation/offload-request.service';

@Component({
  selector: 'app-import-serials-from-excel',
  templateUrl: './import-serials-from-excel.component.html',
  styleUrls: ['./import-serials-from-excel.component.css']
})
export class ImportSerialsFromExcelComponent implements OnInit {
  //#region [DECLARATIONS]

  @Output('OnSaveCompleted') saveCompleted = new EventEmitter();
  @Output('onShowInvalid') onShowInvalid = new EventEmitter();
  @Input() warehouseTransaction: WarehouseTransaction;
  @Input() formProcessMode = FormProcessMode.InputVoucher;
  @Input() order: any;
  @Input() salesTransaction: any;
  @Input() importWithoutSaving: any;
  @Input() isFOCMode: boolean = false;
  // @Input() events: Observable<void>;
  // saveBtnSubscription: any;
  dataType: ImportDataTypes = ImportDataTypes.Serials;
  ExcelFile;
  uploadedFileName = 'Desc_No_File_Uploaded';
  sampleName = '';
  enableImportButton: boolean = false;
  showResult: boolean = false;
  title = '';
  excelFile;
  @ViewChild('content', { static: true }) content: any;
  form: FormGroup;
  importHeader: string = 'header';
  importNote1: string = 'Desc_Import_note1';
  importedRecordsCount: string = '';
  notImportedRecordsCount: string = '';
  @ViewChild("fileInput", { static: true })
  public fileInput: ElementRef;

  //#endregion

  constructor(private importService: ImportService,
    public coreSession: CoreSession,
    private translateService: TranslateService,
    private inputVouchersService: InputVouchersService,
    private outputVouchersService: OutputVouchersService,
    private transferInService: TransferInService,
    private transferOutService: TransferOutService,
    private loadRequestService: LoadRequestService,
    private offloadRequestService: OffloadRequestService) { }

  ngOnInit() {
    if (this.formProcessMode === FormProcessMode.InputVoucher) {
      this.dataType = ImportDataTypes.Serials_InputVoucher;
    } else if (this.formProcessMode === FormProcessMode.BackToStoreOffload) {
      this.dataType = ImportDataTypes.BackToStoreOffload;
    } else if (this.formProcessMode === FormProcessMode.Sales && this.isFOCMode)
      this.dataType = ImportDataTypes.SalesIfFOC;
    else {
      this.dataType = ImportDataTypes.Serials;
    }
    this.uploadedFileName = this.translateService.instant(ConstantMessages.Desc_No_File_Uploaded);
  }
  // ngOnDestroy() {
  //   this.saveBtnSubscription.unsubscribe();
  // }

  // saveBtn() {
  //   this.saveBtnSubscription = this.events.subscribe(() => {
  //     this.onImportClicked();
  //   });
  // }
  //#region [EVENTS]

  onUploadExcelFile(file) {
    this.enableImportButton = false;
    let This = this;
    let files = file.srcElement.files;
    if (files.length > 0) {
      let file: File = files[0];
      if (
        file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      ) {
        this.coreSession.ModalLoading.Show();
        This.uploadedFileName = file.name;
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        this.ExcelFile = formData;
        let oFReader = new FileReader();
        oFReader.onloadend = function () {
          This.enableImportButton = true;
        };
        oFReader.readAsText(file);
        this.coreSession.ModalLoading.Hide();
      }
      else {
        this.coreSession.ModalLoading.Hide();
        this.uploadedFileName = this.translateService.instant(ConstantMessages.Desc_No_File_Uploaded);
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.Desc_Invalid_Excel_File));
      }
    }
  }
  onImportClicked() {
    if (this.enableImportButton) {
      if (this.fileInput.nativeElement.value == '') {
        this.uploadedFileName = this.translateService.instant(ConstantMessages.Desc_No_File_Uploaded);
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.Desc_No_File_Uploaded));
        return;
      }
      else {
        this.callImportAPI(this.ExcelFile);
        this.fileInput.nativeElement.value = '';
      }
      this.uploadedFileName = this.translateService.instant(ConstantMessages.Desc_No_File_Uploaded);
    }
    else {
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.Desc_No_File_Uploaded));
    }
  }
  onSampleClicked() {
    if (this.formProcessMode === FormProcessMode.InputVoucher) {
      this.sampleName = 'SerialsTemplate-InputVoucher.xlsx';
    } else if (this.formProcessMode === FormProcessMode.BackToStoreOffload) {
      this.sampleName = 'SerialsTemplate-BackToStoreOffload.xlsx';
    } else if (this.formProcessMode === FormProcessMode.Sales && this.isFOCMode) {
      this.sampleName = 'SerialsTemplate-Invoice.xlsx';
    } else {
      this.sampleName = 'SerialsTemplate.xlsx';
    }

    this.coreSession.ModalLoading.Show();
    this.importService.downloadFile(this.dataType, this.sampleName).subscribe((response) => {
      this.coreSession.ModalLoading.Hide();
    }, (error) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
    });
  }

  //#endregion

  //#region [METHODS]

    callImportAPI(excelFile) {
        this.coreSession.ModalLoading.Show();
        if (excelFile != null) {
            if (this.formProcessMode === FormProcessMode.PartialDelivery || this.formProcessMode === FormProcessMode.Delivery)
                excelFile.append('jsonString', JSON.stringify(this.order));
            else if (this.formProcessMode === FormProcessMode.Sales) 
                excelFile.append('jsonString', JSON.stringify(this.salesTransaction));
            else
                excelFile.append('jsonString', JSON.stringify(this.warehouseTransaction));

      excelFile.append('formProcessMode', this.formProcessMode);
      excelFile.append('importWithoutSaving', this.importWithoutSaving)
    }


    if (this.formProcessMode === FormProcessMode.InputVoucher) {
      this.inputVouchersService.importFileForSerials(excelFile).subscribe((response: ResponseModel) => {
        this.onSuccessImportFileForSerials(response);
      }, (error) => {
        this.onErrorImportFileForSerials();
      });

    } else if (this.formProcessMode === FormProcessMode.OutputVoucher) {
      this.outputVouchersService.importFileForSerials(excelFile).subscribe((response: ResponseModel) => {
        this.onSuccessImportFileForSerials(response);
      }, (error) => {
        this.onErrorImportFileForSerials();
      });
    } else if (this.formProcessMode === FormProcessMode.TransferIn) {
      this.transferInService.importFileForSerials(excelFile).subscribe((response: ResponseModel) => {
        this.onSuccessImportFileForSerials(response);
      }, (error) => {
        this.onErrorImportFileForSerials();
      });
    } else if (this.formProcessMode === FormProcessMode.TransferOut) {
      this.transferOutService.importFileForSerials(excelFile).subscribe((response: ResponseModel) => {
        this.onSuccessImportFileForSerials(response);
      }, (error) => {
        this.onErrorImportFileForSerials();
      });
    } else if (this.formProcessMode === FormProcessMode.LoadRequest) {
      this.loadRequestService.importFileForSerials(excelFile).subscribe((response: ResponseModel) => {
        this.onSuccessImportFileForSerials(response);
      }, (error) => {
        this.onErrorImportFileForSerials();
      });
    } else if (this.formProcessMode === FormProcessMode.OffLoad) {
      this.offloadRequestService.importFileForSerials(excelFile).subscribe((response: ResponseModel) => {
        this.onSuccessImportFileForSerials(response);
      }, (error) => {
        this.onErrorImportFileForSerials();
      });
    } else {
      this.importService.ImportFileForSerials(excelFile).subscribe((response: ResponseModel) => {
        this.onSuccessImportFileForSerials(response);
      }, (error) => {
        this.onErrorImportFileForSerials();
      });
    }
  }

  onSuccessImportFileForSerials(response) {
    this.coreSession.ModalLoading.Hide();
    if (response.status != null && response.status >= 0) {
      if (response.data.isValid) {
        this.showResult = true;
        this.importedRecordsCount = this.translateService.instant(ConstantMessages.Desc_Imported_Records_Count).replace('{0}', response.data.importedRecordsCount);
        this.notImportedRecordsCount = this.translateService.instant(ConstantMessages.Desc_Not_Imported_Records_Count).replace('{0}', response.data.notImportedRecordsCount);
        if (response.data.isEmptyExcelSheet)
          this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.Desc_Empty_Excel_Sheet));
        else
          this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), this.translateService.instant(ConstantMessages.Desc_Imported_Successfully));
        this.saveCompleted.emit(response.data);
      }
      else {
        if (this.translateService.instant(ConstantMessages.Desc_All_Serials_Not_Valid) === response.message) {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_All_Serials_Not_Valid));
          this.onShowInvalid.emit(response.data.invalidSerials);
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Excel_File_Schema_Not_Match));
        }
      }
    } else if (response.data != null) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_All_Serials_Not_Valid));
      this.onShowInvalid.emit(response.data);
    }
    else {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
    }
  }
  onErrorImportFileForSerials() {
    this.coreSession.ModalLoading.Hide();
    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
  }
  //#endregion
}
