import { Component, Input, OnInit } from '@angular/core';
import { CoreSession } from '../../../core/core.session';
import { IDisplayMultiText } from '../../models/display-multi-text/diplay-multi-text.interface';

@Component({
  selector: 'app-display-multi-text',
  templateUrl: './display-multi-text.component.html',
  styleUrls: ['./display-multi-text.component.css']
})
export class DisplayMultiTextComponent implements OnInit {

  @Input() data:any =[];
  @Input() displayMultiTextSettings:any={} ;
 
  isAvailable: boolean= false; 
  isVisibleList: any=[];
  reminder: number = 0;
  
  constructor(public coreSession: CoreSession) { }

  ngOnInit() {
    this.sortArray();
  }
  sortArray(){
  this.data = this.data.sort((a, b) => (a.sequence > b.sequence) ? 1  : (a.sequence === b.sequence) ? ((a.description > b.description) ? 1 : -1) : -1 );

    this.isVisibleList = this.data.filter(x => x.isVisible == true).sort((a, b) => (a.sequence > b.sequence) ? 1  : (a.sequence === b.sequence) ? ((a.description > b.description) ? 1 : -1) : -1 );;
   
   this.reminder = this.isVisibleList.length - this.displayMultiTextSettings.showLimit;
    if(this.isVisibleList.length == 0){
      this.isAvailable =true;
    }else{
      this.isAvailable =false;
    }
  }


}
