import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../models/api-models/api-models';
import { TransationCommonData } from '../models/transaction/transaction-common-data';
import { CoreSession } from '../../core/core.session';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  private url = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Validation';
  }

  checkHeaderData(transCommData: TransationCommonData) {
    const urlValue = this.url + '/CheckHeaderData';
    return this.http
      .post<ResponseModel>(urlValue, transCommData)
      .map(
        (response) => {
          return response;
        }
      );
  }
  checkCostPrice(transCommData: TransationCommonData) {
    const urlValue = this.url + '/CheckCostPrice';
    return this.http
      .post<ResponseModel>(urlValue, transCommData)
      .map(
        (response) => {
          return response;
        }
      );
  }
  getMaxDocumentSequence(documentSequenceFilter: any) {
    const urlValue = this.url + '/GetMaxDocumentSequence';
    return this.http
      .post<ResponseModel>(urlValue, documentSequenceFilter)
      .map(
        (response) => {
          return response;
        }
      );
  }
  checkAccountAndDocumentSequence(documentSequenceFilter: any) {
    const urlValue = this.url + '/CheckAccountAndDocumentSequence';
    return this.http
      .post<ResponseModel>(urlValue, documentSequenceFilter)
      .map(
        (response) => {
          return response;
        }
      );
  }

  checkOutPromotions(transactionCommonData: TransationCommonData) {
    const urlValue = this.url + '/CheckOutPromotions';
    return this.http
      .post<ResponseModel>(urlValue, transactionCommonData)
      .map(
        (response) => {
          return response;
        }
      );
  }

  takePromotions(transactionCommonData: TransationCommonData) {
    const urlValue = this.url + '/TakePromotions';
    return this.http
      .post<ResponseModel>(urlValue, transactionCommonData)
      .map(
        (response) => {
          return response;
        }
      );
  }
  recalculateAfterProductDiscountPromotions(transactionCommonData: TransationCommonData) {
    const urlValue = this.url + '/RecalculateAfterProductDiscountPromotions';
    return this.http
      .post<ResponseModel>(urlValue, transactionCommonData)
      .map(
        (response) => {
          return response;
        }
      );
  }
}
