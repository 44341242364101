import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TransactionService } from '../../../../content/customer-operation/transactions/transaction.service';
import { CoreSession } from '../../../../core/core.session';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { CalculationService } from '../../../services/calculations.service';
import { ItemService } from '../../../services/data-definition/product/item.service';

@Component({
  selector: 'app-select-secondary-pack',
  templateUrl: './select-secondary-pack.component.html',
  styleUrls: ['./select-secondary-pack.component.scss']
})
export class SelectSecondaryPackComponent implements OnInit{
  @Input() events: Observable<void>;
  @Input() itemId:number;
  @Input() packId:number;
  @Input() price:number;
  @Input() isWeightPriced=false;
  @Input() requiredQuantity:number;
  @Input() selectedSecondaryPack:any;
  @Input() secondaryPacksList:any[]=[];

  @Output() onSaveCompleted = new EventEmitter();
  saveBtnSubscription: any;

  secondaryPackForm:FormGroup= new FormGroup({});
  isSecondaryPacksLoading=false;
  isEditMode=false;
  useWeightPriceInOrder=false;
  constructor(private itemService:ItemService,
    private coreSession:CoreSession,
    private calculationService:CalculationService,
    private sessionData: SessionDataProvider,
    private translateService:TranslateService) { }

  ngOnInit() {
    this.useWeightPriceInOrder=this.sessionData.getConfigurationValue(ConstantConfigurations.UseWeightPriceInOrder).toLowerCase()==='true';
    this.initForm();
    if(this.selectedSecondaryPack && this.selectedSecondaryPack.secondaryPackId>0){
      this.isEditMode=true;
    }

    this.saveBtn();
    //this.populateSecondaryPacks();
  }
//   ngAfterViewChecked(){
//     this.cdRef.detectChanges();
//   }
//   ngAfterContentChecked() : void {
//     this.cdRef.detectChanges();
// }
  saveBtn() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }
  initForm(){
    this.secondaryPackForm=new FormGroup({
      secondaryPackId: new FormControl({ value: this.selectedSecondaryPack && this.selectedSecondaryPack.secondaryPackId>0 ?this.selectedSecondaryPack.secondaryPackId:null, disabled: false },  Validators.required),
      reqQuantity:new FormControl({value:this.selectedSecondaryPack && this.selectedSecondaryPack.secondaryPackId>0?this.selectedSecondaryPack.requiredQuantity:null,disabled:false},Validators.required),
      secondaryQuantity:new FormControl({value:this.selectedSecondaryPack && this.selectedSecondaryPack.secondaryPackId>0?this.selectedSecondaryPack.secondaryQuantity:null,disabled:true}),
      modifiedPrice:new FormControl({value:this.selectedSecondaryPack && this.selectedSecondaryPack.secondaryPackId>0?this.selectedSecondaryPack.modifiedPrice:null,disabled:true}),

    });
  }
  // setDataInEditMode(){
  //   this.reqQuantityInput.setValue(this.selectedSecondaryPack.requiredQuantity);
  //   this.secondaryQuantityInput.setValue(this.selectedSecondaryPack.secondaryQuantity);
  //   this.modifiedPriceInput.setValue(this.selectedSecondaryPack.modifiedPrice);
  // }
  onSecondaryPackSelected(selectedSecondary){

    if(selectedSecondary){
      if(!this.selectedSecondaryPack)this.selectedSecondaryPack={};
      this.selectedSecondaryPack.secondaryPackId=selectedSecondary.secondaryPackId;
      this.selectedSecondaryPack.quantity=selectedSecondary.quantity;
      this.selectedSecondaryPack.packTypeId=selectedSecondary.packTypeId;

      this.selectedSecondaryPack.packId=selectedSecondary.packId;
      this.selectedSecondaryPack.itemId=selectedSecondary.itemId;
      if(!this.isEditMode || !this.useWeightPriceInOrder)
      this.selectedSecondaryPack.originalPrice=this.price;
if(this.useWeightPriceInOrder && this.isWeightPriced){
  this.selectedSecondaryPack.modifiedPrice= this.calculationService.getNumberOnDigitFormat(this.selectedSecondaryPack.quantity * this.selectedSecondaryPack.originalPrice);
}else{
  this.selectedSecondaryPack.modifiedPrice=this.price;
}

      this.selectedSecondaryPack.secondaryQuantity= this.calculationService.getNumberOnStockDigitFormat(this.selectedSecondaryPack.quantity * this.selectedSecondaryPack.requiredQuantity);

    }
    this.modifiedPriceInput.setValue(this.selectedSecondaryPack.modifiedPrice);
    this.secondaryQuantityInput.setValue(this.selectedSecondaryPack.secondaryQuantity);
  }
  setSecondaryPackQuantity(){
    if(this.reqQuantityInput.value && this.reqQuantityInput.value!=''){
      this.selectedSecondaryPack.requiredQuantity=+this.reqQuantityInput.value;
    }  else{
      this.selectedSecondaryPack.requiredQuantity=0;
    }
    this.selectedSecondaryPack.secondaryQuantity= this.calculationService.getNumberOnStockDigitFormat(this.selectedSecondaryPack.quantity * this.selectedSecondaryPack.requiredQuantity);
 this.secondaryQuantityInput.setValue(this.selectedSecondaryPack.secondaryQuantity);

  }
populateSecondaryPacks(){
  this.coreSession.ModalLoading.Show();
  //this.isSecondaryPacksLoading = true;
  this.itemService.getSecondaryPacks(this.itemId).subscribe(
    (result) => {
      this.secondaryPacksList = result.data;
      //this.isSecondaryPacksLoading = false;
      this.coreSession.ModalLoading.Hide();
    }

  );
}
onSaveClicked() {
  this.coreSession.markFormGroupTouched(this.secondaryPackForm);
  if (this.secondaryPackForm.invalid) {
    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
    return;
  }
  this.selectedSecondaryPack.packId=this.packId;
  this.selectedSecondaryPack.itemId=this.itemId;
  this.onSaveCompleted.emit(this.selectedSecondaryPack);
}
get reqQuantityInput() {
  return this.secondaryPackForm.get('reqQuantity');
}
get secondaryQuantityInput() {
  return this.secondaryPackForm.get('secondaryQuantity');
}
get modifiedPriceInput() {
  return this.secondaryPackForm.get('modifiedPrice');
}
}
