import { Injectable, Inject } from '@angular/core';
import {
  WarehouseTransactionStatusModel,
  WarehouseTransaction
} from '../../shared/models/warehouse-transactions/warehouse-transaction.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from '../../core/core.session';
import { ResponseModel } from '../../shared/models/api-models/api-models';
import { WarehouseTransactionOperation } from '../../shared/models/enums/warehouse-transaction-operation.enum';
import { warehouseFilter } from '../../shared/models/shared-table/custom-list.interface';
import { FormProcessMode } from '../../shared/models/enums/form-process-mode.enum';

@Injectable({
  providedIn: 'root'
})
export class VehicleOperationService {
  vehicleId: number;
  private url = '';
  private httpOptions;
  public warehouseTransactionStatuses: WarehouseTransactionStatusModel[];
  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl + 'WarehouseTransaction';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  SaveWarehouseOperation(warehouseTransaction: WarehouseTransaction) {
    const urlValue: string = this.url + '/InsertWarehouseOperation';
    // const data = JSON.stringify(warehouseTransaction);
    // return this.http.post(urlValue, warehouseTransaction);
    return this.http
      .post<ResponseModel>(urlValue, warehouseTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  ValidateMoveOrder(
    whTransaction: WarehouseTransaction) {
    const urlValue: string = this.url + '/ValidateMoveOrder';

    return this.http
      .post<ResponseModel>(urlValue, whTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  UpdateWarehouseTransactionStatus(
    warehouseTransaction: WarehouseTransaction,
    newStatusId: number
  ) {
    const urlValue: string = this.url + '/UpdateWarehouseTransactionStatus';
    // const data = JSON.stringify(warehouseTransaction);
    warehouseTransaction.newStatusId = newStatusId;
    return this.http
      .post<ResponseModel>(urlValue, warehouseTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  UpdateWarehouseTransactionsListStatus(
    warehouseTransactions: WarehouseTransaction[],
    newStatusId: number
  ) {
    const urlValue: string =
      this.url + '/UpdateWarehouseTransactionsListStatus';
    // const data = JSON.stringify(warehouseTransaction);
    const updatedWarehouseTransactions = {
      warehouseTransactions: warehouseTransactions,
      newStatusId: newStatusId
    };
    return this.http
      .post<ResponseModel>(urlValue, updatedWarehouseTransactions)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  getWarehouseTransactions(statusId: number, filter: warehouseFilter, warehouseOperation: WarehouseTransactionOperation) {

    const urlValue: string = this.url + '/GetWarehouseTransactions';
    let warehouse = {
      wHTransactionStatusId: statusId,
      wHTransactionOperationId: warehouseOperation,
      warehouseFilter: filter
    }
    return this.http
      .post<ResponseModel>(
        urlValue, warehouse
      )
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  getWarehouseTransactionStatuses(formProcessMode: FormProcessMode) {
    const urlValue: string = this.url + '/GetWarehouseTransactionStatuses';
    return this.http.post<ResponseModel>(urlValue, formProcessMode).map(response => {
      const res = new ResponseModel();
      res.data = response['data'];
      this.warehouseTransactionStatuses = res.data;
      res.message = response['message'];
      res.status = response['status'];
      return res;
    });
  }
  getSelectedWarehouseStatusModel(warehouseTransactionStatusId: number) {
    const index = this.coreSession.GetIndexForList(
      this.warehouseTransactionStatuses,
      'warehouseTransactionStatusId',
      warehouseTransactionStatusId
    );
    if (index !== -1) {
      return this.warehouseTransactionStatuses[index];
    }
  }
  getWarehouseTransactionData(signature: string, isView?, isResendToHelloJordanMode?) {
    if (isView === undefined) {
      isView = false;
    }
    const urlValue: string = this.url + '/GetWarehouseTransactionData';
    let warehouse = {
      signature: signature,
      isView: isView,
      isResendToHelloJordanMode: isResendToHelloJordanMode
    }
    return this.http
      .post<ResponseModel>(urlValue, warehouse)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  getAddedItemsForFullyOffload(warehouseId) {
    const urlValue: string = this.url + '/GetAddedItemsForFullyOffload';
    return this.http
      .post<ResponseModel>(urlValue, warehouseId)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  checkIfWarehouseTransactionHasSerialItems(signature: string) {
    const urlValue: string = this.url + '/CheckIfWarehouseTransactionHasSerialItems';
    let warehouseTH = {
      signature: signature
    }
    return this.http
      .post<ResponseModel>(urlValue, warehouseTH)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  isValidToEditLoadOffLoadRequest(whTransactionModel: any) {
    const urlValue: string = this.url + '/IsValidToEditLoadOffLoadRequest';
    return this.http.post<ResponseModel>(urlValue, whTransactionModel)
      .map(response => {
        return response;
      });
  }

  getSelectedTransferOutDetailsToCreateTransaferIn(warehouseTransaction: WarehouseTransaction) {
    const urlValue: string = this.url + '/GetSelectedTransferOutDetailsToCreateTransaferIn';
    return this.http.post<ResponseModel>(urlValue, warehouseTransaction).map(response => {
      const res = new ResponseModel();
      res.data = response['data'];
      res.message = response['message'];
      res.status = response['status'];
      return res;
    });
  }
  getOutputVoucherIdsToTransferIn(sourceWarehouseId: number, destinationWarehouseId: number, divisionId: number) {
    const urlValue: string = this.url + '/GetOutputVoucherIdsToTransferIn';
    let wHTransaction = {
      sourceWarehouseId: sourceWarehouseId,
      destinationWarehouseId: destinationWarehouseId,
      divisionId: divisionId
    }
    return this.http
      .post<ResponseModel>(urlValue, wHTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  checkIfVehicleUploaded(vehicleId: number) {
    const urlValue = this.url + '/CheckIfVehicleUploaded';
    return this.http
      .post<ResponseModel>(urlValue, vehicleId)
      .map(response => {
        return response;
      });
  }

  // GetPurchaseOrders(
  //   statusId: number,
  //   filter: warehouseFilter,
  // ) {

  //   const urlValue: string = this.url + '/GetPurchaseOrders';
  //   let wHTransaction = {
  //     wHTransactionStatusId: statusId,
  //     warehouseFilter: filter
  //   }

  //   return this.http
  //     .post<ResponseModel>(
  //       urlValue, wHTransaction
  //     )
  //     .map(response => {
  //       const res = new ResponseModel();
  //       res.data = response['data'];
  //       res.message = response['message'];
  //       res.status = response['status'];
  //       return res;
  //     });
  // }
  // getPurchaseOrderData(purchaseOrderId: string) {
  //   const urlValue: string = this.url + '/GetPurchaseOrderData';
  //   let purchaseOrder = {
  //     purchaseOrderId: purchaseOrderId
  //   }
  //   return this.http
  //     .post<ResponseModel>(urlValue, purchaseOrder)
  //     .map(response => {
  //       const res = new ResponseModel();
  //       res.data = response['data'];
  //       res.message = response['message'];
  //       res.status = response['status'];
  //       return res;
  //     });
  // }
  // savePurchaseOrder(purchaseOrderObj: any) {
  //   const urlValue: string = this.url + '/InsertPurchaseOrder';
  //   // const data = JSON.stringify(warehouseTransaction);
  //   // return this.http.post(urlValue, warehouseTransaction);
  //   return this.http
  //     .post<ResponseModel>(urlValue, purchaseOrderObj)
  //     .map(response => {
  //       const res = new ResponseModel();
  //       res.data = response['data'];
  //       res.message = response['message'];
  //       res.status = response['status'];
  //       return res;
  //     });
  // }
  saveQualityControlTransferOut(warehouseTransaction: WarehouseTransaction) {
    const urlValue: string = this.url + '/ExecuteTransferOutQualityControlTransactions';
    return this.http
      .post<ResponseModel>(urlValue, warehouseTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  // cancelPurchaseOrder(purchaseOrderId: string) {
  //   const urlValue: string = this.url + '/CancelPurchaseOrder';

  //   return this.http
  //     .post<ResponseModel>(urlValue + "?purchaseOrderId=" + purchaseOrderId, '')
  //     .map(response => {
  //       const res = new ResponseModel();
  //       res.data = response['data'];
  //       res.message = response['message'];
  //       res.status = response['status'];
  //       return res;
  //     });
  // }
  checkEmployeeAccount(employeeId: number, divisionId: number) {
    const urlValue = this.url + '/CheckEmployeeAccount';
    let generalFilter = {
      employeeId: employeeId,
      divisionId: divisionId
    }
    return this.http
      .post<ResponseModel>(urlValue, generalFilter)
      .map(response => {
        return response;
      });
  }
  checkVehicleAccount(vehicleId: number) {
    const urlValue = this.url + '/CheckVehicleAccount';
    return this.http
      .post<ResponseModel>(urlValue, vehicleId)
      .map(response => {
        return response;
      });
  }
  fillGeneralLoadInfo(vehicleId: number, employeeId: number, divisionId: number, transactionId: string, isExecuteMode: boolean) {
    const urlValue = this.url + '/FillGeneralLoadInfo';
    let generalFilter = {
      vehicleId: vehicleId,
      employeeId: employeeId,
      divisionId: divisionId,
      transactionId: transactionId,
      isExecuteMode: isExecuteMode
    }
    return this.http
      .post<ResponseModel>(urlValue, generalFilter)
      .map(response => {
        return response;
      });
  }
  toQueryString(obj: any) {
    const parts = [];
    // tslint:disable-next-line:forin
    for (const property in obj) {
      const value = obj[property];
      if (property !== 'customListFilter') {
        // && property !== 'transactionDate' && property !='date'
        if (value !== null && value !== undefined) {
          parts.push(
            encodeURIComponent(property) + '=' + encodeURIComponent(value)
          );
        }
      }
    }
    return parts.join('&');
  }

  ApproveHelloToJordan(whTransactionId: string) {
    const urlValue: string = this.url + '/ApproveHelloToJordan';
    let whTransaction = {
      whTransactionId: whTransactionId
    }
    return this.http
      .post<ResponseModel>(urlValue, whTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  CancelResendHelloJordan(signature: string) {
    const urlValue: string = this.url + '/CancelResendHelloToJordan';
    let wHTransaction = {
      signature: signature
    }
    return this.http
      .post<ResponseModel>(urlValue, wHTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  isValidToAffectInWarehouseTransaction(signature: string, formProcessMode: FormProcessMode) {
    const urlValue: string = this.url + '/IsValidToAffectInWarehouseTransaction';
    let wHTransaction = {
      signature: signature,
      formProcessModeId: formProcessMode.valueOf()
    }
    return this.http.post<ResponseModel>(
      urlValue, wHTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  offloadManuallyVehicle(vehicles: any[]) {
    const urlValue: string = this.url + "/OffloadManuallyVehicle";
    return this.http.post<ResponseModel>(urlValue, vehicles).map(response => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  isValidToOffloadManuallyVehicle(vehicles: any[]) {
    const urlValue: string = this.url + "/IsValidToOffloadManuallyVehicle";
    return this.http.post<ResponseModel>(urlValue, vehicles).map(response => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }
}
