import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { EchoService } from '../../../../echo/echo.service';
import { BrandService } from '../../../services/data-definition/product/brand.service';
import { ItemService } from '../../../services/data-definition/product/item.service';

@Component({
  selector: 'app-brand-filter',
  templateUrl: './brand-filter.component.html',
  styleUrls: ['./brand-filter.component.css']
})
export class BrandFilterComponent implements OnInit {
  @Input() currentFormControlName = 'brandId'
  @Input() parentForm: FormGroup;
  @Output() onBrandIdCaptionChanged = new EventEmitter();
  @Output() onBrandSelected=new EventEmitter();
  @Input() emitIdAndCaption=false;
  @Input() hideLabel=false;
  @Input() isCustomerApp=false;
  @Input() divisionId:number = -1;
  @Input() itemCategoryId:number = -1;

  brandDataSource: any[];
  selectedBrandId: number;
  isBrandLoading = false;
  isRequired = false;

  constructor( private itemService: ItemService,
    private brandService: BrandService,
    private echoService: EchoService) { }

  ngOnInit() {
    if(this.parentForm.get(this.currentFormControlName).validator != null){
      const validator = this.parentForm.get(this.currentFormControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateBrandList();
  }

  ngOnChanges() {
    this.populateBrandList();
  }

  populateBrandList() {
    this.isBrandLoading = true;
    if(this.isCustomerApp){

      this.echoService.getEchoBrands(
        this.divisionId? this.divisionId: -1,
        this.itemCategoryId? this.itemCategoryId: -1
      ).subscribe(result => {
        this.brandDataSource = result.data;
        this.isBrandLoading = false;
      });
    }else{
      this.brandService.getAllBrands().subscribe(result => {
        this.brandDataSource = result.data;
        this.isBrandLoading = false;
      });
    }
  }
  onBrandDataChanged(brand:any) {
    this.selectedBrandId = this.parentForm.value.brandID;
    if(!this.emitIdAndCaption)
    this.onBrandSelected.emit(brand);
    else{
      let event={
        id:this.selectedBrandId,
        caption:brand.brandName
      }
      this.onBrandIdCaptionChanged.emit(event);
    }
  }
}
