import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, Input, OnChanges, OnDestroy, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { constants } from "os";
import { Subject } from "rxjs";
import { AuthService } from "../../../../core/auth/auth.service";
import { CoreSession } from "../../../../core/core.session";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";
import { NavBarService } from "../../../../core/sidebar/sidebar.service";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { ConstantStorage } from "../../../models/constants/constant-stroage";
import { ConstantURLs } from "../../../models/constants/constant-URL";
import { IDateTimePickerProperties } from "../../../models/dateTimeModel/date-time-properties.interface";
import { DialogMode } from "../../../models/enums/dialog-mode.enum";
import { DialogResult } from "../../../models/enums/dialog-result.enum";
import { MenuActions } from "../../../models/enums/menu-actions-enum";
import { RowOperation } from "../../../models/enums/shared-table-operation.enum";
import { WebBuiltInFilters, WebFormControlType } from "../../../models/enums/web-form.enum";
import { WebFormColumnType } from "../../../models/enums/web-form.enum";
import { ImportDataTypes } from "../../../models/import/ImportDataTypes";
import { NavigationData } from "../../../models/navigation-data/navigation-data.model";
import { CustomListFilter } from "../../../models/shared-table/custom-list.interface";
import { SharedTableResult } from "../../../models/shared-table/shared-table-result.interface";
import { ITableProperties } from "../../../models/shared-table/table-properties.interface";
import { ImportedExcelErrorsDialogComponent } from "../../imported-excel-errors-dialog/imported-excel-errors-dialog.component";
import { WebFormService } from "../web-form.service";

@Component({
  selector: "app-web-form-list",
  templateUrl: "./web-form-list.component.html",
  styleUrls: ["./web-form-list.component.css"],
})
export class WebFormListComponent implements OnInit, OnDestroy {
  @ViewChild("importedExcelErrorsDialogComponent", { static: true }) importedExcelErrorsDialogComponent: ImportedExcelErrorsDialogComponent;
  @Input() mainModuleUrl = null;
  @Input() mainModuleName = null;
  url: string = "";
  filterForm: FormGroup;
  listForm: any = null;
  selectedObject: any = null;
  entryForm: any = null;
  saveSubject: Subject<void> = new Subject<void>();
  isEditMode = false;
  isViewMode = false;
  isAddMode = false;
  showEntrySlider = false;
  isLoading = false;
  selectedOrganizationId = null;
  excludedOrganizationId = -1;
  TabelData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    isOnline: true,
    showSearch: true,
    showSearchBtn: false,
    showNewBtn: false,
    isMultiSelection: false,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [],
  };
  dateProperties: IDateTimePickerProperties = {
    label: "",
    formControlName: "",
    isCalendarOnly: true,
    isRange: true,
  };
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: [],
  };
  sharedTableFilter: CustomListFilter = {
    searchFilter: "",
    page: 0,
    pageSize: this.coreSession.pageSize,
  };
  navLinks: any[];

  //#region [Import App Data]
  importDataType: ImportDataTypes = ImportDataTypes.AppData;
  entryFormObj: any = {};
  importedExcelErrors: any[] = [];
  //#endregion

  constructor(
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private webFormService: WebFormService,
    private route: ActivatedRoute,
    private router: Router,
    private navService: NavBarService,
    private authService: AuthService,
    private sessionData: SessionDataProvider
  ) { }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.url = this.route.snapshot.paramMap.get("id");
      this.coreSession.ModalLoading.Show();
      // if can auth = false then navigate to master data home screen
      this.isLoading = true;
      this.resetData();
      this.prepareListScreen();
    });
  }
  ngOnDestroy() {
    // this.navService.setNavigation([]);
  }
  setNavigation() {
    let navigationList: NavigationData[] = [];
    navigationList.push({
      Name: this.mainModuleName,
      URL: this.mainModuleUrl,
    });

    if (this.mainModuleUrl == ConstantURLs.dataDefinitionURL) {
      navigationList.push({
        Name: "Desc_Master_Data",
        URL: ConstantURLs.dataDefinitionURL + "/" + ConstantURLs.masterURL,
      });
    }

    navigationList.push({
      Name: this.listForm.menuName,
      URL: undefined,
    });
    this.navService.setNavigation(navigationList);
  }
  resetData() {
    this.TabelData.columns = [];
    this.TabelData.rowOperations = [];
    this.listForm = null;
    this.filterForm = null;
    this.closeEntrySlider();
    this.sharedTableFilter = {
      searchFilter: "",
      page: 0,
      pageSize: this.coreSession.pageSize,
    };
  }
  prepareNavigation() {
    this.navLinks = [
      {
        label: this.listForm.menuName,
        index: 0,
        isActive: true,
      },
    ];
  }
  prepareListScreen() {
    // this.coreSession.ModalLoading.Show();
    var filter = {
      uRL: this.url,
      form: null,
      sharedTableFilter: this.sharedTableFilter,
    };
    this.webFormService.initializeWebFormList(filter).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        this.isLoading = false;
        if (response.status != null && response.status >= 0 && response.data) {
          this.listForm = response.data;
          this.entryFormObj = {
            entryFormId: this.listForm.entryFormId,
            menuName: this.listForm.menuName.replaceAll(" ", ""),
            webFormDescription: this.translateService.instant(this.listForm.webFormDescription)
          };
          if (this.listForm.webFormName.toLowerCase() == "currencylist" && this.sessionData.getConfigurationValue(ConstantConfigurations.ApplyCurrencyPerOrganization).toLowerCase() === "false") {
            this.listForm.columns.find(x => x.columnName.toLowerCase() == 'organizationcodename').isVisible = false;
          }
          if (this.listForm.webFormName.toLowerCase() == "currencyratelist" && this.sessionData.getConfigurationValue(ConstantConfigurations.ApplyCurrencyPerOrganization).toLowerCase() === "false") {
            this.listForm.columns.find(x => x.columnName.toLowerCase() == 'organizationcodename').isVisible = false;
          }
          this.prepareNavigation();
          this.setNavigation();
          this.fillActions();
          if (this.listForm.filters != null && this.listForm.filters.length > 0) {
            this.initFilterForm();
          }
          this.TabelData.showSearchBtn = this.listForm.showFilterButton;
          this.TabelData.columns = this.listForm.columns.filter(
            (c) => c.isVisible
          );
          if (
            this.listForm.sharedTableDataSource &&
            this.listForm.sharedTableDataSource.totalItems > 0
          ) {
            this.dataSource = {
              totalItems: this.listForm.sharedTableDataSource.totalItems,
              data: this.listForm.sharedTableDataSource.data,
            };
          } else {
            this.dataSource = {
              totalItems: 0,
              data: [],
            };
          }
          this.listForm.sharedTableDataSource = null; // no need to cash it
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.isLoading = false;
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  getWebFormListData() {
    var filter = {
      form: this.listForm,
      sharedTableFilter: this.sharedTableFilter,
    };
    this.coreSession.ModalLoading.Show();
    this.webFormService.getWebFormListData(filter).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.dataSource = {
            totalItems: response.data.totalItems,
            data: response.data.data,
          };
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  initFilterForm() {
    this.filterForm = new FormGroup({});
    this.listForm.filters.forEach((filter) => {
      if (filter.filterTypeId === WebFormControlType.DateRange) {
        var dateProperties: IDateTimePickerProperties = {
          label: filter.description,
          formControlName: filter.formControlName,
          isCalendarOnly: true,
          isRange: true,
        };
        this.dateProperties = dateProperties;
      }

      if (filter.filterTypeId != WebFormControlType.DateRange) {
        this.filterForm.addControl(
          filter.formControlName,
          new FormControl()
        );
      }
      else {
        if (filter.defaultValue != null && filter.defaultValue != "") {
          this.TabelData.openFilterDirectly = true;
          this.filterForm.addControl(
            filter.formControlName,
            new FormControl([filter.selectedDateModelValue[0].date, filter.selectedDateModelValue[1].date])
          );
        } else {
          this.filterForm.addControl(
            filter.formControlName,
            new FormControl([null, null])
          );
        }
      }
    });
  }
  fillActions() {
    this.TabelData.showImport = this.listForm.showImportButton;
    if (
      this.listForm.entryFormId &&
      this.listForm.entryFormId > 0 &&
      this.coreSession.checkActivitiesAvailability(this.url, MenuActions.Add)
    ) {
      this.TabelData.showNewBtn = true;
    }
    if (
      this.coreSession.checkActivitiesAvailability(this.url, MenuActions.Delete)
    ) {
      this.TabelData.rowOperations.push({
        operation: RowOperation.delete,
        title: "Desc_Delete",
        icon: "fa fa-close",
        color: "#f1685e",
      });
    }
    if (
      this.coreSession.checkActivitiesAvailability(this.url, MenuActions.Edit)
    ) {
      this.TabelData.rowOperations.push({
        operation: RowOperation.edit,
        title: "Desc_Edit",
        icon: "fa fa-pencil",
        color: "#12344d",
      });
    }
    if (
      this.coreSession.checkActivitiesAvailability(this.url, MenuActions.View)
    ) {
      this.TabelData.rowOperations.push({
        operation: RowOperation.View,
        title: "Desc_View",
        icon: "fa fa-eye",
        color: "#12344d",
      });
    }
    if (
      this.coreSession.checkActivitiesAvailability(
        this.url,
        MenuActions.Approve
      )
    ) {
      this.TabelData.rowOperations.push({
        operation: RowOperation.approve,
        title: "Desc_Approve",
        icon: "fa fa-check",
        color: "#02b875",
      });
    }
    if (
      this.coreSession.checkActivitiesAvailability(
        this.url,
        MenuActions.Confirm
      )
    ) {
      this.TabelData.rowOperations.push({
        operation: RowOperation.Confirm,
        title: "Desc_Confirm",
        icon: "fa fa-check",
        color: "#02b875",
      });
    }
    if (
      this.coreSession.checkActivitiesAvailability(this.url, MenuActions.Cancel)
    ) {
      this.TabelData.rowOperations.push({
        operation: RowOperation.Cancel,
        title: "Desc_Cancel",
        icon: "fa fa-close",
        color: "#f1685e",
      });
    }
    if (
      this.coreSession.checkActivitiesAvailability(
        this.url,
        MenuActions.Execute
      )
    ) {
      this.TabelData.rowOperations.push({
        operation: RowOperation.Execute,
        title: "Desc_Vehilce_Execute",
        icon: "fa fa-cogs",
        color: "#12344d",
      });
    }
  }
  onRowOperation(event: any) {
    this.selectedObject = event.object;
    switch (event.operation) {
      case RowOperation.edit:
        this.openEntrySlider(false, true, false);
        break;
      case RowOperation.View:
        this.openEntrySlider(false, false, true);
        break;
      case RowOperation.delete:
        this.performActionProcedure(
          MenuActions.Delete,
          ConstantMessages.MsgDeleteConfirmation
        );
        break;
      case RowOperation.approve:
        this.performActionProcedure(
          MenuActions.Approve,
          ConstantMessages.MsgApproveConfirmation
        );
        break;
      case RowOperation.Execute:
        this.performActionProcedure(
          MenuActions.Execute,
          ConstantMessages.MsgExecuteConfirmation
        );
        break;
      case RowOperation.Confirm:
        this.performActionProcedure(
          MenuActions.Confirm,
          ConstantMessages.MsgConfirmConfirmation
        );
        break;
      case RowOperation.Cancel:
        this.performActionProcedure(
          MenuActions.Cancel,
          ConstantMessages.MsgCancelConfirmation
        );
        break;
    }
  }
  getActionProcedureName(action: MenuActions): string {
    var procedureName = null;
    var index = this.listForm.actionProcedures.findIndex(
      (x) => x.actionId === action
    );
    if (index >= 0) {
      procedureName = this.listForm.actionProcedures[index].procedureName;
    }
    return procedureName;
  }
  getPrimaryKeysList(getDBColumnName: boolean) {
    var primaryKeyColumnList: any[] = [];
    if (this.listForm.columns.findIndex((c) => c.isPrimary) >= 0) {
      var primaryClms = this.listForm.columns.filter((c) => c.isPrimary);
      for (let index = 0; index < primaryClms.length; index++) {
        primaryKeyColumnList.push({
          columnName: getDBColumnName
            ? primaryClms[index].columnName
            : primaryClms[index].entryColumnName, // clm names accoring to db or according to entry query
          columnTypeId: primaryClms[index].columnTypeId,
          value: this.selectedObject[primaryClms[index].columnName] + "",
        });
      }
    }
    return primaryKeyColumnList;
  }
  performActionProcedure(action: MenuActions, message) {
    var procedureName = this.getActionProcedureName(action);
    var primaryKeyColumnList = this.getPrimaryKeysList(true); // DB Column Name
    if (procedureName != null && primaryKeyColumnList != null) {
      this.coreSession.ModalDialog.ShowMessage(
        this.translateService.instant(message),
        DialogMode.YesNo,
        this.translateService.instant(ConstantMessages.WarningCaption)
      ).then((res: DialogResult) => {
        if (res === DialogResult.Yes) {
          this.coreSession.ModalLoading.Show();
          var filter = {
            primaryKeyColumnList: primaryKeyColumnList,
            procedureName: procedureName,
            expirationDate: this.authService.getTokenExpirationDate(),
            actionId: action,
            webMenuId: this.listForm.webMenuId
          };
          this.webFormService.performActionProcedure(filter).subscribe(
            (response) => {
              this.coreSession.ModalLoading.Hide();
              if (response.status != null && response.status >= 0) {
                if (response.data.isValid) {
                  // Success and valid

                  if (filter.procedureName == 'SP_Web_DeleteOrganization') {
                    localStorage.setItem(ConstantStorage.TokenName, response.data.tokenString);
                    localStorage.setItem(ConstantStorage.CurrentOperator, JSON.stringify(response.data.currentOperator));
                  }
                  this.coreSession.showSuccess(
                    this.translateService.instant(
                      ConstantMessages.SuccessCaption
                    ),
                    response.message
                  );
                  this.getWebFormListData();
                } else {
                  // Success and invalid
                  this.coreSession.showWarrning(
                    this.translateService.instant(
                      ConstantMessages.WarningCaption
                    ),
                    response.message
                  );
                }
              } else {
                // not Success
                this.coreSession.showError(
                  this.translateService.instant(ConstantMessages.ErrorCaption),
                  response.message
                );
              }
            },
            (error: HttpErrorResponse) => {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showError(
                this.translateService.instant(ConstantMessages.ErrorCaption),
                this.translateService.instant(ConstantMessages.ErrorHappened)
              );
            }
          );
        }
      });
    }
  }
  openEntrySlider(isAdd, isEdit, isView) {
    var primaryKeyColumnList: any[] = [];
    if (isEdit || isView) {
      primaryKeyColumnList = this.getPrimaryKeysList(false); // entry Column name
    }
    this.coreSession.ModalLoading.Show();
    var filter = {
      webFormId: this.listForm.entryFormId,
      primaryKeyColumnList: primaryKeyColumnList,
      getSavedDetails: isEdit || isView,
      webMenuURL: this.url
    };
    this.webFormService.initializeWebFormEntry(filter).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.entryForm = response.data;
          this.isAddMode = isAdd;
          this.isEditMode = isEdit;
          this.isViewMode = isView;
          this.showEntrySlider = true;
          this.entryForm.parentFormWebMenuId = this.listForm.webMenuId;
          this.entryForm.webMenuURL = this.url;
        } else {
          this.coreSession.showError(
            this.translateService.instant(ConstantMessages.ErrorCaption),
            response.message
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(
          this.translateService.instant(ConstantMessages.ErrorCaption),
          this.translateService.instant(ConstantMessages.ErrorHappened)
        );
      }
    );
  }
  closeEntrySlider() {
    this.isAddMode = false;
    this.isEditMode = false;
    this.isViewMode = false;
    this.selectedObject = null;
    this.entryForm = null;
    this.showEntrySlider = false;
    this.coreSession.SetTitle("master data :" + this.url);
  }
  onSaveSliderClick() {
    this.saveSubject.next();
  }
  afterSaveSlider() {
    this.closeEntrySlider();
    this.getWebFormListData();
  }
  onTableFilterChanged(query: any) {
    this.sharedTableFilter = query;
    this.getWebFormListData();
  }
  onResetFilter() {
    this.listForm.filters.forEach((filter) => {
      filter.selectedDateModelValue = [];
    });
    this.filterForm.reset();
  }
  onFilterFindButtonClicked() {
    this.fillFormValues()
    this.sharedTableFilter.page = 0;
    this.getWebFormListData();
  }
  onChangeDate(selectedControl, event) {
    selectedControl.selectedDateModelValue = event;
  }
  fillFormValues() {
    this.listForm.filters.forEach((filter) => {
      if (filter.filterTypeId === WebFormControlType.Boolean) {
        filter.selectedValue = this.filterForm.get(filter.formControlName)
          .value
          ? "1"
          : "";
      }
      else if (filter.filterTypeId != WebFormControlType.DateRange) {
        filter.selectedValue = this.filterForm.get(filter.formControlName)
          .value
          ? this.filterForm.get(filter.formControlName).value.toString()
          : "";
      } else {
        filter.selectedValue = "";
      }
    });
  }

  //#region [Import from excel]
  importComplete(event) {
    debugger
    this.getWebFormListData();
    this.importedExcelErrors = event.importedExcelErrors ? event.importedExcelErrors : [] ;
    if (event.importedExcelErrors && event.importedExcelErrors.length > 0) {
      this.importedExcelErrorsDialogComponent.showDialog().then(
        (result) => {

        });
    }
  }
  //#endregion
}
