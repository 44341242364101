import { Component, OnInit } from '@angular/core';
import { NavBarService } from './sidebar.service';
import { ConstantURLs } from '../../shared/models/constants/constant-URL';
import { CoreSession } from '../core.session';
import { MenuModel } from '../../shared/models/menu/menu.model';
import { OperatorModel } from '../../shared/models/operator/operator.model';
import { SessionDataProvider } from '../session-data-provider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public isMinimized = true;
  public urls;
  public currentOperator: OperatorModel;
  public menus: MenuModel[] = [];
  public active: false;
  collapsingArray;
  indexCounter: number;
  currenctIndex;
  open = false;
  navLinks: any[] = [];
  urlValue: string = "";
  backgroundPath = '';
  constructor(private navService: NavBarService, public coreSession: CoreSession,
    private sessionDataProvider: SessionDataProvider, private router: Router) {
    this.urls = ConstantURLs;
    this.backgroundPath = "url('" + this.coreSession.baseURL + "assets/img/dark_bg.png')";
  }
  ngOnInit() {
    this.prepareNavigation();
    this.currentOperator = this.coreSession.CurrentOperator;
    this.navService.MinimizedClicked.subscribe(
      (isMin: boolean) => {
        this.isMinimized = isMin;
      }
    );

    this.navService.ReloadSidebar.subscribe(
      () => {
        this.prepareNavigation();
      });
    this.prepareNavigation();
    this.navService.ActiveSidebar.subscribe(
      (menuUrl: string) => {

        this.navLinks.forEach(a => {
          a.isActive = false;
        });

        for (let i = 0; i < this.navLinks.length; i++) {          
          if (this.navLinks[i].link.split("/")[1].includes(menuUrl)) {                        
            this.navLinks[i].isActive = true;
          }
        }
      }
    );
  }


  onclick(index: number) {
    this.currenctIndex = this.collapsingArray[index];
    this.collapsingArray.fill(true);
    this.collapsingArray[index] = !this.currenctIndex;
  }
  prepareNavigation() {
    this.currentOperator = this.coreSession.CurrentOperator;
    this.indexCounter = 0;

    this.navLinks = [];
    for (let menu of this.sessionDataProvider.allMenusAvailable) {
      // loop on web parent menus (sidebar menus)
      if (!menu.menuId.includes('-') && menu.isWebMenu) {
        this.indexCounter = this.indexCounter + 1;
        let index = this.sessionDataProvider.allMenusAvailable.findIndex(x => x.parentMenuId === menu.menuId);
        // data definitions && settings && ROI : no redirect on any child
        if (menu.menuId === "100" || menu.menuId === '900' || menu.menuId === '1800' || !this.sessionDataProvider.allMenusAvailable[index]) {
          
          this.urlValue = menu.url;
          if (this.coreSession.CurrentOperator.landingMenu &&
            this.coreSession.CurrentOperator.landingMenu.url === menu.url) {
            this.coreSession.CurrentOperator.landingMenu.url = this.urlValue;
          }
        } else {
          if (menu.menuId == '2300' || menu.menuId == '500')
            this.urlValue = menu.url;
          else
            this.urlValue = menu.url + '/' + this.sessionDataProvider.allMenusAvailable[index].url;

          if (this.coreSession.CurrentOperator.landingMenu &&
            this.coreSession.CurrentOperator.landingMenu.url === menu.url) {
            this.coreSession.CurrentOperator.landingMenu.url = this.urlValue;
          }
        }
        let newName = {
          label: menu.menuName,
          menuId: menu.menuId,
          link: '/' + this.urlValue,
          icon: menu.icon,
          index: this.indexCounter,
          isActive: false
        };
        this.navLinks.push(newName);
      }
    }
    for (let i = 0; i < this.navLinks.length; i++) {      
      if (this.navLinks[i].link.split('/')[1].includes(this.router.url.split('/')[1])) {        
        this.navLinks[i].isActive = true;
      }
    }
  }

}
