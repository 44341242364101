import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ResponseModel } from "../models/api-models/api-models";

@Injectable({
  providedIn: "root",
})
export class ImageService {
  private url = "";
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.url = baseUrl + "Image";
  }

  uploadImage(img: File) {
    const urlValue: string = this.url + "/UploadImage";
    const formData: FormData = new FormData();
    formData.append("image", img, img.name);
    return this.http.post<ResponseModel>(urlValue, formData).map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }
}
