import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-vendor-filter',
  templateUrl: './vendor-filter.component.html',
  styleUrls: ['./vendor-filter.component.css']
})
export class VendorFilterComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() IncludeInactive: boolean = true;
  selectedVendorId: number;
  vendorDataSource: any[];
  isVendorLoading = false;
  isRequired = false;
  @Output() vendorSelected=new EventEmitter();
  constructor(private itemService: ItemService) { }
  ngOnInit() {
    if(this.parentForm.get('vendorId').validator != null){
      const validator = this.parentForm.get('vendorId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }

    this.populateVendorList();
  }


  populateVendorList() {

    this.isVendorLoading = true;
    this.itemService.getAllVendors(this.IncludeInactive).subscribe(result => {
      this.vendorDataSource = result;

      if(this.vendorDataSource != null && this.vendorDataSource.length > 0 && this.parentForm.value.vendorId === -1 || this.parentForm.value.vendorId === null || this.parentForm.value.vendorId === ''){
        if(this.vendorDataSource.find(vendor => vendor.base === true) != null){
          let selectedVendor=this.vendorDataSource.find(vendor => vendor.base === true);
          this.selectedVendorId = selectedVendor.vendorId;
          this.parentForm.get('vendorId').setValue(this.selectedVendorId);
          this.vendorSelected.emit(selectedVendor);
        }
      }
      this.isVendorLoading = false;
    });

}
  onVendorDataChanged($event) {
    this.selectedVendorId = this.parentForm.value.vendorId;
    this.vendorSelected.emit($event);

  }

}
