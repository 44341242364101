import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject } from "rxjs";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PackGroupService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "PackGroup";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
  AddEditPackGroup(object: any, isEditMode: boolean) {
    const urlValue = this.url + (isEditMode ? "/UpdatePackGroup" : "/InsertPackGroup");
    return this.http.post<ResponseModel>(urlValue, object)
      .map(response => {
        return response;
      })
  }

  getPackGroups(filter) {
    const urlValue: string = this.url + '/getPackGroups';
    return this.http.post<ResponseModel>(urlValue, filter).pipe(map(
      response => {
        return response;
      }));
  }
  getPackGroupsListSharedTable(filter) {
    const urlValue: string = this.url + '/GetPackGroupsListSharedTable';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    })
  }
  getPackGroupsDescriptions(packGroupId) {
    const urlValue: string = this.url + '/GetPackGroupsDescriptions';
    return this.http.post<ResponseModel>(urlValue, packGroupId).pipe(map(
      response => {
        return response;
      }));
  }

  deletePackGroup(groupId: any) {
    const urlValue = this.url + "/deletePackGroup";
    return this.http.post<ResponseModel>(urlValue, groupId).pipe(
      map(response => {
        return response;
      })
    );
  }

  getPackGroupItems(packGroupId, getAllItemPacks) {
    let filter = {
      packGroupId: packGroupId,
      getAllItemPacks: getAllItemPacks
    }
    const urlValue = this.url + '/GetPackGroupItems';
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      });
  }

  getPackGroupsItemDefinition() {
    const urlValue: string = this.url + '/GetPackGroupsItemDefinition';
    return this.http.get<ResponseModel>(urlValue).pipe(map(
      response => {
        return response;
      }));
  }
}
