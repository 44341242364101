import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { SetupService } from "../../content/admin-settings/setup/setup.service";

@Injectable()
export class SettedUpResolver implements Resolve<boolean>{

    constructor(
        private setupService: SetupService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.setupService.IsSettedUp();
    }
}