import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { MasterDataService } from '../../../../content/master-data-definition/master-data.service';
import { PricesAndOffersService } from '../../../../content/master-data-definition/prices-and-offers-management/prices-and-offers.service';
import { PriceService } from '../../../services/data-definition/prices-and-offers/price.service';

@Component({
  selector: 'app-price-type-filter',
  templateUrl: './price-type-filter.component.html',
  styleUrls: ['./price-type-filter.component.css']
})
export class PriceTypeFilterComponent implements OnInit {
  @Input() excludedPriceTypeId = -1;
  @Input() currentFormControlName = 'priceListTypeId';
  @Input() hideLabel = false;
  @Input() parentForm: FormGroup;
  @Input() emitIdAndCaption = false;
  @Output() selectedPriceType = new EventEmitter();
  @Output()
  onChangeIdCaptionPriceType = new EventEmitter();
  priceTypeList: any[];
  isPriceTypeLoading = false;
  isRequired = false;

  constructor(private priceService: PriceService) { }

  ngOnInit() {
    if (this.parentForm.get(this.currentFormControlName).validator != null) {
      const validator = this.parentForm.get(this.currentFormControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
  }

  ngOnChanges() {
    this.populatePriceTypeList();
  }

  populatePriceTypeList() {
    this.isPriceTypeLoading = true;
    this.priceService.getPriceListTypes().subscribe(result => {
      this.priceTypeList = result.data;
      this.isPriceTypeLoading = false;
    });
  }
  onChangePriceType(selectedPriceType: any) {
    if (!this.emitIdAndCaption)
      this.selectedPriceType.emit(selectedPriceType);
    else {
      let event = {
        id: selectedPriceType.priceListTypeId,
        caption: selectedPriceType.priceListTypeName
      }
      this.onChangeIdCaptionPriceType.emit(event);
    }
  }

}
