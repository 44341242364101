import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../../shared/models/api-models/api-models";

@Injectable({
  providedIn: "root"
})
export class EmployeeDeviceService {

  minimizedItemSidebar = new Subject<boolean>();

  private url = "";
  private httpOptions;
  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.url = baseUrl + "EmployeeDevice";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token"
      })
    };
  }
  getDevicesList(filter: any) {
    const urlValue: string = this.url + '/GetDevicesList';
    return this.http.post<ResponseModel>(urlValue, filter).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }

  deleteDevice(deviceSerial: any) {
    const urlValue: string = this.url + '/DeleteDevice';
    return this.http.post<ResponseModel>(urlValue + '?deviceSerial=' + deviceSerial, "").map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }

  activateDeactivateDevice(deviceSerial: any, isActivate: boolean) {
    const urlValue: string = this.url + '/UpdateEmployeeDeviceActivation';
    return this.http.post<ResponseModel>(urlValue + '?deviceSerial=' + deviceSerial +
      '&isActivate=' + isActivate, "").map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }
  getDeviceData(deviceSerial: any) {
    let device = {
      deviceSerial: deviceSerial
    }
    const urlValue: string = this.url + '/GetDeviceData';
    return this.http.post<ResponseModel>(urlValue, device).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }

  checkIfUniqueDeviceSerial(deviceSerial: any) {
    const urlValue: string = this.url + '/CheckIfUniqueDeviceSerial';
    return this.http.post<ResponseModel>(urlValue + '?deviceSerial=' + deviceSerial, "").map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }

  saveDevice(device: any, isEditeMode: boolean) {
    const urlValue: string = this.url + (isEditeMode ? '/UpdateDevice' : '/InsertDevice');
    return this.http.post(urlValue, JSON.stringify(device), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  getDevices(deviceFilter: any) {
    const urlValue: string = this.url + '/GetDevices';
    return this.http.post<ResponseModel>(urlValue, deviceFilter).map(response => {
      return response.data;
    });
  }
  checkIfDeviceLinkedToUploadedRoute(employeeId: any) {
    const urlValue: string = this.url + '/CheckIfDeviceLinkedToUploadedRoute';
    let employeeDevice = {
      employeeId: employeeId
    }
    return this.http.post<ResponseModel>(urlValue, employeeDevice).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }
}
