export enum DialogResult {
  None = 0,
  Yes,
  No,
  Ok,
  Save,
  Cancel,
  WithOffload,
  WithoutOffload
}
