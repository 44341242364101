import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreSession } from '../../core/core.session';
import { ResponseModel } from '../models/api-models/api-models';
import { GeneralFilter } from '../models/general/generalFilter.model';
@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private url = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Location';
  }
  GetCountries() {
    const urlValue = this.url + '/GetCountries';
    return this.http.post<ResponseModel>(urlValue, "")
      .map(response => {
        return response;
      });
  }
  GetStates(countryID: any) {
    let filter = {
      CountryId: countryID
    }
    const urlValue = this.url + '/GetStates';
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      });
  }
  GetCities(countryID: any, stateID: any) {
    let filter = {
      countryId: countryID,
      stateId: stateID
    }
    const urlValue = this.url + '/GetCities';
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      });
  }
  GetAreas(countryID: any, stateID: any, cityID: any) {
    let filter = {
      countryId: countryID,
      stateId: stateID,
      cityId: cityID
    }
    const urlValue = this.url + '/GetAreas';
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      });
  }
  GetStreets(countryID: any, stateID: any, cityID: any, areaID: any) {
    let filter = {
      countryId: countryID,
      stateId: stateID,
      cityId: cityID,
      areaId: areaID
    }
    const urlValue = this.url + '/GetStreets';
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        return response;
      });
  }

  getLocatoinLists() {
    const urlValue: string = this.url + "/GetLocatoinLists";
    return this.http.post<ResponseModel>(
      urlValue, "").map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  getGeoLocationHierarchyData(locationHierarchyFilter: any) {
    const urlValue: string = this.url + "/GetGeoLocationHierarchyData";
    return this.http.post<ResponseModel>(urlValue, locationHierarchyFilter).map(response => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  saveUpdateLocationHierarchyData(locationHierarchyData: any) {
    const urlValue = this.url + "/SaveUpdateLocationHierarchyData";
    return this.http.post<ResponseModel>(urlValue, locationHierarchyData)
      .map((response) => {
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }

  deletLocationHierarchyData(locationHierarchyData: any) {
    const urlValue = this.url + "/DeletLocationHierarchyData";
    return this.http.post<ResponseModel>(urlValue, locationHierarchyData)
      .map((response) => {
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }
}
