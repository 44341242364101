import { ChattingAssignmentStatus } from "../enums/chatting/chatting-assignment-status.enum";
import { ChattingConnectionStatus } from "../enums/chatting/chatting-connection-status.enum";

export class ChattingUser {
    userId : number;
    status : ChattingConnectionStatus;
    deviceSerial : string;
    userName : string;
    deviceToken : string;
    isBOUser : boolean;
    assignedBOUserId : number;
    addRemoveAssignment : boolean;
    assignmentStatus : ChattingAssignmentStatus;
    chattingConnectionStatusValue?: number;
    chattingAssignmentStatusValue?: number;

    constructor() {
        this.userId = -1;
        this.status = -1;
        this.deviceSerial = '';
        this.userName = '';
        this.deviceToken = '';
        this.isBOUser = false;
        this.assignedBOUserId = -1;
        this.addRemoveAssignment = false;
        this.assignmentStatus = -1;
    }
}