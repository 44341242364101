import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseModel } from '../shared/models/api-models/api-models';

@Injectable({
    providedIn: 'root'
})
export class EchoService {

    httpOptions;
    private url = '';
    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.url = baseUrl + 'Echo';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    saveCustomerProperties(customerProperties) {
        const urlValue = this.url + "/InsertCustomerProperties";
        return this.http.post(urlValue, customerProperties).map((response) => {
            const res = new ResponseModel();
            res.data = response['data'];
            res.message = response['message'];
            res.status = response['status'];
            return res;
        });
    }
    getContactUsList(filter) {
        const urlValue: string = this.url + '/GetContactUsList';
        return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).map(
          (response) => {
            const result = new ResponseModel();
            result.data = response['data'];
            result.message = response['message'];
            result.status = response['status'];
            return result;
          }
        );
    }
    getCustomerProperties() {
        const urlValue: string = this.url + '/GetCustomerProperties';
        return this.http.get<ResponseModel>(urlValue).map(
            (response) => {
                const res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            }
        );
    }
    saveBanner(brand: FormData) {
        const urlValue = this.url + "/InsertBanner";
        return this.http.post<ResponseModel>(urlValue, brand)
            .map(response => {
                return response;
            })
    }
    getBanners() {
        const urlValue = this.url + "/getBanners";
        return this.http.get<ResponseModel>(urlValue).map(
            (response) => {
                const res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            }
        );
    }
    deleteBanner(fileName) {
        const urlValue = this.url + "/DeleteBanner";
        return this.http.get(urlValue + '?fileName=' + fileName).map((response) => {
            const res = new ResponseModel();
            res.data = response['data'];
            res.message = response['message'];
            res.status = response['status'];
            return res;
        });
    }
    
    saveFeedbackReply(feedBackReply: any) {
        const urlValue: string = this.url + '/InsertFeedbackReply';

        return this.http.post(urlValue, JSON.stringify(feedBackReply), this.httpOptions).map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        );
    }

    getEchoItems() {
        const urlValue = this.url + "/GetEchoItems";
        return this.http.get<ResponseModel>(urlValue).map(
            (response) => {
                const res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            }
        );
    }

    saveEchoItems(packList: any) {
        const urlValue = this.url + "/InsertEchoItems";
        return this.http.post(urlValue, JSON.stringify(packList), this.httpOptions).map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            });
    } 

    getEchoDivisions() {
        const urlValue = this.url + "/GetEchoDivisions";
        return this.http.get<ResponseModel>(urlValue).map(
            (response) => {
                const res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            }
        );
    }

    getEchoCategories(divisionId: number) {
        const urlValue = this.url + "/GetEchoCategories";
        return this.http.get<ResponseModel>(urlValue + '?DivisionId=' + divisionId).map(
            (response) => {
                const res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            }
        );
    }

    getEchoBrands(divisionId: number, itemCategoryId: number) {
        const urlValue = this.url + "/GetEchoBrands";
        return this.http.get<ResponseModel>(
            urlValue + "?divisionId=" + divisionId + "&itemCategoryId="+itemCategoryId).map(
            (response) => {
                const res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            }
        );
    }

    getEchoFirstOrderItems(itemFilter: any, getAddedItems?:boolean) {
        getAddedItems = getAddedItems? true: false;
        const urlValue: string = this.url + '/GetEchoFirstOrderItems';
        return this.http.get<ResponseModel>(urlValue + "?itemsFilter=" + JSON.stringify(itemFilter)
        + "&getAddedItems=" + getAddedItems).map(
            (response) => {
                const res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            }
        );
    }

    insertEchoFirstOrderItems(addedFirstOrderFreeItems: any[]) {
        const urlValue: string = this.url + '/InsertEchoFirstOrderItems';
        return this.http.post(urlValue, addedFirstOrderFreeItems).map(
            (response) => {
                const res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            }
        );
      }
}
