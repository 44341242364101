import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  @Input('bodyTemplet') bodyTemplet: TemplateRef<any>;
  @Input('width') width: string;
  @Input('showFooter') showFooter: boolean = true;
  @Input('saveString') saveString: string = 'Desc_Save';
  @Input('saveButtonIcon') saveButtonIcon:string='fa fa-floppy-o';
  @Input('showHeader') showHeader: boolean = false;
  @Input('headerString') headerString: string = '';
  @Input ('hasInternalFooter') hasInternalFooter=false;
  @Output('closeSlider') closeSlider = new EventEmitter();
  @Output('saveClicked') saveClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  onCloseSlider() {
    this.closeSlider.emit();
  }

  onSaveClicked() {
    this.saveClicked.emit();
  }

}
