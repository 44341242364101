import { Component, OnInit } from '@angular/core';
import { CoreSession } from '../core.session';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(public coreSession: CoreSession) { }
test = `<strong class="header">TERMS & CONDITIONS:</strong> 
 <p>Welcome to SONIC, These terms and conditions outline the rules and regulations for the use of SONIC solution. By accessing this Solution we assume you accept these terms and conditions in full. Do not continue to use SONIC if you do not accept all of the terms and conditions stated on this page. The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements:</p><ul><li> "USER", "You" and "Your" refers to you, the person using this application and accepting the Company's terms and conditions.</li><li>"The solution", "Ourselves", "We", "Our" and "Us", refers to SONIC.</li><li>"Party", "Parties", or "Us", refers to both the User and SONIC, or either the User or SONIC.</li></ul><p>All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the User in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the User's needs in respect of provision of the SONIC's stated services/products, in accordance with and subject to, prevailing law.</p><p>Of United Arab Emirates. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p><p>License Unless otherwise stated, InCube and/or it's licensors own the intellectual property rights for all material on SONIC. All intellectual property rights are reserved.</p><strong class="header">You must not:</strong><ul><li>Republish material from SONIC.</li><li>Sell, rent or sub-license material from SONIC.</li><li>Reproduce, duplicate or copy material from SONIC.</li><li>Redistribute content from SONIC (unless content is specifically made for redistribution)</li></ul>`;
  ngOnInit() {
  }

}
