import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { PriceService } from '../../../services/data-definition/prices-and-offers/price.service';

@Component({
  selector: 'app-sales-order-type-filter',
  templateUrl: './sales-order-type-filter.component.html',
  styleUrls: ['./sales-order-type-filter.component.css']
})
export class SalesOrderTypeFilterComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Output() salesOrderTypeSelected = new EventEmitter();

  salesOrderTypeDataSource: any[];
  isRequired: boolean = false;
  isSalesOrderTypeLoading: boolean = false;
  constructor(private priceService: PriceService) {
  }
  ngOnInit(): void {
    if(this.parentForm.get('salesOrderTypeId').validator != null){
      const validator = this.parentForm.get('salesOrderTypeId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateSalesOrderTypesList();
  }

  populateSalesOrderTypesList() {
    this.isSalesOrderTypeLoading = true;
    this.priceService.getSalesOrderTypes().subscribe(result => {
      this.salesOrderTypeDataSource = result.data;
      this.isSalesOrderTypeLoading = false;
    }, (error) => {
      this.isSalesOrderTypeLoading = false;
    });
  }

  
  onChange(selectedSalesOrderType:any){
    this.salesOrderTypeSelected.emit(selectedSalesOrderType);
  }

}
