import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject } from "rxjs";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";

@Injectable({
  providedIn: "root",
})
export class CustomerHierarchyService {
  customerChanged = new Subject();

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "CustomerHierarchy";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  deleteCustomersHierarchyData(CustomersHierarchyData: any) {
    const urlValue = this.url + "/DeleteCustomersHierarchyData";
    return this.http.post<ResponseModel>(urlValue, CustomersHierarchyData)
      .map((response) => {
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }

  saveUpdateCustomersHierarchyData(CustomersHierarchyData: any, isEditMode: boolean) {
    const urlValue = this.url + (isEditMode ? "/UpdateCustomersHierarchyData" : "/InsertCustomersHierarchyData");
    return this.http.post<ResponseModel>(urlValue, CustomersHierarchyData)
      .map((response) => {
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }

  saveCustomersGroup(customers: any, isEditMode: boolean) {
    const urlValue = this.url + (isEditMode ? "/UpdateCustomersGroup" : "/InsertCustomersGroup");
    return this.http.post<ResponseModel>(urlValue, customers)
      .map((response) => {
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }

  getCustomerHierarchyData() {
    const urlValue = this.url + "/GetCustomerHierarchyData";
    return this.http
      .post<ResponseModel>(urlValue, {})
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }
}
