import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { CoreSession } from "../../../core/core.session";
import { ResponseModel } from "../../../shared/models/api-models/api-models";

@Injectable({
    providedIn: "root"
})

// hai el service elak enta, o lazem ykon elha controller elha l7alha bl back end, elo case b methos kahhale
export class KPIService {
    minimizedItemSidebar = new Subject<boolean>();
    private url = "";
    private httpOptions;
    constructor(
        private http: HttpClient,
        private coreSession: CoreSession,
        @Inject("BASE_URL") baseUrl: string
    ) {
        this.url = baseUrl + "KPI";
        this.httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "my-auth-token"
            })
        };
    }
    getKPITypes() {
        const urlValue: string = this.url + "/GetKPITypes";
        return this.http.get<ResponseModel>(urlValue).map(response => {
            return response.data;
        });
    }
    fillScreenData() {
        const urlValue: string = this.url + "/GetKPITypeList";
        return this.http.get<ResponseModel>(urlValue).map(response => {
            return response.data;
        });
    }
    getKPISubTypeDynamicList(typeId, subTypeId) {
        const urlValue: string = this.url + "/GetKPISubTypeDynamicList?typeId=" + typeId + "&subTypeId=" + subTypeId;
        return this.http.get<ResponseModel>(urlValue).map(response => {
            return response.data;
        });
    }
    InserKPI(kpi) {

        const urlValue: string = this.url + '/InserKPI';
        (urlValue);
        return this.http.post(urlValue, JSON.stringify(kpi), this.httpOptions).map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        );
    }
    UpdateKPI(kpi) {

        const urlValue: string = this.url + '/UpdateKPI';
        (urlValue);
        return this.http.post(urlValue, JSON.stringify(kpi), this.httpOptions).map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        );
    }
    activateKPI(KPIId) {
        const urlValue = this.url + '/UpdateFromDeactiveToActivateKPI';
        return this.http.post<ResponseModel>(urlValue, KPIId).map(
          (response) => {
            return response;
          });
      }
    
      deactivateKPI(KPIId) {
        const urlValue = this.url + '/UpdateFromActivateToDeactivateKPI';
        return this.http.post<ResponseModel>(urlValue, KPIId).map(
          (response) => {
            return response;
          });
      }
    getKPIList(filter) {
        const urlValue: string = this.url + '/GetKPIList';        
        return this.http.post(urlValue, filter, this.httpOptions).map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        );
    }
    GetKPIAssignmentTypes()
    {
        const urlValue: string = this.url + "/GetKPIAssignmentTypes";
        return this.http.get<ResponseModel>(urlValue).map(response => {
            return response.data;
        });
    }
    GetKPIPeriodTypes()
    {
        const urlValue: string = this.url + "/GetKPIPeriodTypes";
        return this.http.get<ResponseModel>(urlValue).map(response => {
            return response.data;
        });
    }
    GetKPIData(kpiID) {
        const urlValue: string = this.url + "/GetKPIData?kpiID=" + kpiID;
        return this.http.get<ResponseModel>(urlValue).map(response => {
            return response.data;
        });
    }
    DeleteKPI(kpiID) {
        const urlValue: string = this.url + "/DeleteKPI?kpiID=" + kpiID;
        return this.http.get<ResponseModel>(urlValue).map(response => {
            return response.data;
        });
    }
}