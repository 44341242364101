import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Subject, Observable } from "rxjs";
import { CustomerModel } from "../../../models/customer/customer.model";
import { CustomerGroupModel } from "../../../models/customer/customer.group.model";
import { PaymentTermModel } from "../../../models/customer/payment-term.model";
import {
  CustomerClassModel,
  CustomerSubClassModel,
} from "../../../models/customer/customer-class.model";
import { ResponseModel } from "../../../models/api-models/api-models";
import {
  CustomerOutletModel,
  CustomerOutletContactModel,
} from "../../../models/customer/outlet.model";
import { GeneralFilter } from "../../../models/general/generalFilter.model";
import { CoreSession } from "../../../../core/core.session";
import { CustomerMappingFilter } from "../../../models/customer/customer-mapping-filter.model";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  customerChanged = new Subject();

  private url = "";
  private customerId: number;
  private outletId: number;
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "Customer";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
  //#region [OutletAttachments]
  saveOutletAttachments(formData: FormData) {
    return this.http
      .post(this.url + "/InsertOutletAttachments", formData)
      .map((response: Response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }
  downloadOutletAttachments(
    outletAttachmentPath: string,
    fileName: string,
    fileType: string
  ): Observable<any> {
    let fileExtension = fileType;
    const urlValue =
      this.url +
      "/DownloadOutletAttachments?filePath=" +
      outletAttachmentPath +
      "&fileName=" +
      fileName;
    return this.http.post(urlValue, "", { responseType: "blob" }).map((res) => {
      var blob = new Blob([res], { type: fileExtension });
      return blob;
    });
  }
  //#endregion
  getCustomers(filter: any) {
    const urlValue: string = this.url + "/GetCustomers";
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getALLCustomers() {
    const urlValue: string = this.url + "/GetAllCustomers";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getCustomerOutlets(
    customerId: number,
    territoryId: number,
    generateMoreInfoLink: boolean,
    dashboardId: number,
    excludeOnHold: boolean,
    showCustomersHavePendingInvoices: boolean
  ) {
    const urlValue: string = this.url + "/GetCustomerOutlets";
    let filter: GeneralFilter = {
      customerId: customerId,
      territoryId: territoryId,
      generateMoreInfoLink: generateMoreInfoLink,
      moreInfoDashboardId: dashboardId,
      excludeOnHold: excludeOnHold,
      isFromCollection: showCustomersHavePendingInvoices
    };
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      return <CustomerOutletModel[]>response.data;
    });
  }

  toQueryString(obj: any) {
    const parts = [];
    // tslint:disable-next-line:forin
    for (const property in obj) {
      const value = obj[property];
      if (value != null && value !== undefined) {
        parts.push(
          encodeURIComponent(property) + "=" + encodeURIComponent(value)
        );
      }
    }
    return parts.join("&");
  }

  InsertNewCustomer(customerObj: CustomerModel) {
    const urlValue: string = this.url + "/InsertNewCustomer";
    return this.http
      .post<ResponseModel>(urlValue, customerObj)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  UpdateCustomer(customerObj: CustomerModel) {
    const urlValue: string = this.url + "/UpdateCustomer";
    return this.http.post<ResponseModel>(urlValue, customerObj)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  InsertNewCustomerOutlet(outletObj: CustomerOutletModel) {
    outletObj;
    //outletObj=new CustomerOutletModel();
    // (outletObj);
    const urlValue: string = this.url + "/InsertNewCustomerOutlet";

    return this.http
      .post<ResponseModel>(urlValue, outletObj)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  InsertNewCustomerGroup(groupObj: CustomerGroupModel) {
    const urlValue: string = this.url + "/InsertNewCustomerGroup";

    return this.http.post<ResponseModel>(urlValue, groupObj).map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  InsertNewPaymentTerm(paymentTermObj: PaymentTermModel) {
    const urlValue: string = this.url + "/InsertNewPaymentTerm";

    return this.http
      .post<ResponseModel>(urlValue, paymentTermObj)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  UpdateCustomerGroup(groupObj: CustomerGroupModel) {
    const urlValue: string = this.url + "/UpdateCustomerGroup";

    return this.http.post<ResponseModel>(urlValue, groupObj).map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getCustomerData(customerId, showInActive: boolean) {
    const urlValue: string = this.url + "/GetCustomerData";
    let filter: GeneralFilter = {
      customerId: customerId,
      showInActive: showInActive,
    }
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  deleteCustomer(customerId: number) {
    const urlValue = this.url + "/DeleteCustomerData";
    return this.http
      .post<ResponseModel>(urlValue + "?customerId=" + customerId, "")
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  isValidToDeleteCustomer(customerId: number) {
    const urlValue: string = this.url + "/IsValidToRemoveCustomer";
    let filter: GeneralFilter = {
      customerId: customerId,
    }
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  isValidToDeleteCustomerOutlet(customerId: string, outletId: string) {
    const urlValue: string = this.url + "/IsValidToRemoveCustomerOutlet";
    let filter: GeneralFilter = {
      customerId: +customerId,
      outletId: +outletId,
    }
    return this.http.post<ResponseModel>(urlValue, filter)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  getOutletData(customerId: number, outletId: number, hasPrivilegeOnPaymentTermDivision, hasPrivilegeOnOnHoldDivision) {
    const urlValue: string = this.url + "/GetOutletData";
    let filter: GeneralFilter = {
      customerId: customerId,
      outletId: outletId,
      hasPrivilegeOnPaymentTermDivision: hasPrivilegeOnPaymentTermDivision,
      hasPrivilegeOnOnHoldDivision: hasPrivilegeOnOnHoldDivision,
    }
    return this.http.post<ResponseModel>(urlValue, filter)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  getCustomerTypes() {
    const urlValue: string = this.url + "/GetCustomerTypes";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getAllFilteredCustomerGroups(channelId, subChannelId, organizationIds) {
    let filter: GeneralFilter = {
      channelId: channelId,
      subChannelId: subChannelId,
      organizationIds: organizationIds
    }
    const urlValue: string = this.url + "/GetAllCustomerGroups";
    return this.http.post<ResponseModel>(urlValue, filter)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }
  getAllCustomerGroups(organizationIds: string) {
    return this.getAllFilteredCustomerGroups(-1, -1, organizationIds);
  }
  getCustomerClasses() {
    const urlValue: string = this.url + "/GetCustomerClasses";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getCustomerAllChannels() {
    const urlValue: string = this.url + "/GetAllChannels";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getCustomerAllSubChannels(channelId: number) {
    const urlValue: string = this.url + "/GetAllSubChannels";
    let filter: GeneralFilter = {
      channelId: channelId
    }
    return this.http.post<ResponseModel>(urlValue, filter)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  getCustomerSubClasses() {
    const urlValue: string = this.url + "/GetCustomerSubClasses";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getPaymentTerms() {
    const urlValue: string = this.url + "/GetPaymentTerms";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getPaymentTermTypes() {
    const urlValue: string = this.url + "/GetPaymentTermTypes";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getPeriodTypes() {
    const urlValue: string = this.url + "/GetPeriodTypes";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  InsertNewCustomerClass(customerClass: CustomerClassModel) {
    const urlValue: string = this.url + "/InsertNewCustomerClass";

    return this.http
      .post<ResponseModel>(urlValue, customerClass)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  InsertNewCustomerSubClass(customerSubClass: CustomerSubClassModel) {
    const urlValue: string = this.url + "/InsertNewCustomerSubClass";

    return this.http
      .post<ResponseModel>(urlValue, customerSubClass)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  GetAllCustomerChannels(groupId: any) {
    if (groupId == null) {
      groupId = -1;
    }
    let filter: GeneralFilter = {
      groupId: +groupId,
    }
    const urlValue: string =
      this.url + "/GetAllCustomerChannels";
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      return <any>response.data;
    });
  }

  getAllEchoCustomers() {
    const urlValue: string = this.url + "/GetAllEchoCustomers";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      return <any>response.data;
    });
  }

  getAllEchoCustomerGroups() {
    const urlValue: string = this.url + "/GetAllEchoCustomerGroups";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  GetAllEchoCustomerChannels() {
    const urlValue: string = this.url + "/GetAllEchoCustomerChannels";
    return this.http.post<ResponseModel>(urlValue, "").map((response) => {
      return <any>response.data;
    });
  }

  validateOutletToSave(customerOutlet) {
    const urlValue: string = this.url + "/ValidateOutletToSave";
    return this.http
      .post<ResponseModel>(urlValue, customerOutlet)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  getDivisionsCredits(CustomerTemplateId: number) {
    const urlValue: string = this.url + "/GetDivisionsCredits";
    let filter: GeneralFilter = {
      templateId: CustomerTemplateId,
    }
    return this.http.post<ResponseModel>(urlValue, filter)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  InitializeCustomerTemplateForm(obj: any) {
    const urlValue: string = this.url + "/InitializeCustomerTemplateForm";
    return this.http.post<ResponseModel>(urlValue, obj).map((response) => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  getCustomerLazyLoading(filter: any) {
    const urlValue: string = this.url + "/GetCustomerLazyLoading";
    return this.http
      .post<ResponseModel>(urlValue, filter)
      .map((response) => {
        return response;
      });
  }

  // getCustomerLazyLoading2(filter: any) {
  //   const urlValue: string = this.url + '/GetCustomerLazyLoading';
  //   return this.http.get<ResponseModel>(urlValue + '?' + this.toQueryString(filter.customListFilter) +
  //   "&" + this.toQueryString(filter));
  // }

  getOutletsLazyLoading(filter: any, groupId: number, territoryId: number, classId: number, isWitGetTerritories) {
    // let customerFilters = "&groupId=" + groupId + "&territoryId=" + territoryId + "&classId=" + classId + "&isWitGetTerritories=" + isWitGetTerritories;
    filter.groupId = groupId;
    filter.territoryId = groupId;
    filter.classId = groupId;
    filter.isWitGetTerritories = true;
    const urlValue: string = this.url + "/GetOutletsLazyLoading";
    return this.http.post<ResponseModel>(urlValue, filter)
      .map((response) => {
        return response;
      });
  }
  getOutletsListSharedTable(outletFilter) {
    const urlValue: string = this.url + "/GetOutletsListSharedTable";
    return this.http.post(urlValue, outletFilter).map((response) => {
      const result = new ResponseModel();
      result.data = response["data"];
      result.message = response["message"];
      result.status = response["status"];
      return result;
    });
  }
  getCustomersListSharedTable(outletFilter) {
    const urlValue: string = this.url + "/GetCustomersListSharedTable";
    return this.http.post(urlValue, outletFilter).map((response) => {
      const result = new ResponseModel();
      result.data = response["data"];
      result.message = response["message"];
      result.status = response["status"];
      return result;
    });
  }
  getCustomerGroupsListSharedTable(customerGroupsFilter) {
    const urlValue: string = this.url + "/GetCustomerGroupsListSharedTable";
    return this.http.post(urlValue, customerGroupsFilter).map((response) => {
      const result = new ResponseModel();
      result.data = response["data"];
      result.message = response["message"];
      result.status = response["status"];
      return result;
    });
  }
  isCustomerHasUnpaidTransactions(filter: any) {
    const urlValue: string = this.url + "/IsCustomerHasUnpaidTransactions";
    return this.http.post<ResponseModel>(urlValue, filter)
      .map((response) => {
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }

  isValidToDeleteAccount(accountId: number) {
    const urlValue: string = this.url + "/IsValidToRemoveAccount";
    let filter: GeneralFilter = {
      accountId: accountId
    }
    return this.http.post<ResponseModel>(urlValue, filter)
      .map((response) => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  checkIfContactDataExists(contactData: CustomerOutletContactModel) {
    const urlValue = this.url + "/CheckIfContactDataExists";
    return this.http.post<ResponseModel>(urlValue, contactData)
      .map((response) => {
        return response;
      });
  }
  getCustomerOperatorsList(outletCode: string) {
    const urlValue: string = this.url + "/GetCustomerOperatorsList";
    let filter: GeneralFilter = {
      outletCode: outletCode
    }
    return this.http.post<ResponseModel>(urlValue, filter)
      .map((response) => {
        return response.data;
      });
  }

  DownloadFile(
    Id: string,
    fileName: string,
    fileType: string
  ): Observable<any> {
    let fileExtension = fileType;
    const urlValue =
      this.url + "/DownloadFile?Id=" + Id + "&fileName=" + fileName;

    return this.http.post(urlValue, "", { responseType: "blob" }).map((res) => {
      var blob = new Blob([res], { type: fileExtension });
      return blob;
    });
  }



  GetOutletImages(CustomerOutletId: string) {
    const urlValue: string = this.url + "/GetOutletImages";
    let filter: GeneralFilter = {
      customerOutletId: CustomerOutletId
    }
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      return response;
    });
  }

  // getPriceProtectionList(filter: any) {
  //   const urlValue: string = this.url + "/GetPriceProtectionList";
  //   return this.http.post<ResponseModel>(urlValue, filter)
  //     .map((response) => {
  //       const res = new ResponseModel();
  //       res.data = response["data"];
  //       res.message = response["message"];
  //       res.status = response["status"];
  //       return res;
  //     });
  // }
  // getPriceProtectionDetail(RequestID: string) {
  //   const urlValue: string = this.url + "/GetPriceProtectionData";
  //   let filter: GeneralFilter = {
  //     requestId: RequestID,
  //   }
  //   return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
  //     const res = new ResponseModel();
  //     res.data = response["data"];
  //     res.message = response["message"];
  //     res.status = response["status"];
  //     return res;
  //   });
  // }

  // updatePricesProtectionData(priceProtectionList: any[]) {
  //   const urlValue: string = this.url + "/UpdatePriceProtection";
  //   return this.http
  //     .post(urlValue, JSON.stringify(priceProtectionList), this.httpOptions)
  //     .map((response) => {
  //       const result = new ResponseModel();
  //       result.data = response["data"];
  //       result.message = response["message"];
  //       result.status = response["status"];
  //       return result;
  //     });
  // }

  // updateStatusForPriceProtection(request: any, statusId) {
  //   const urlValue: string = this.url + "/UpdateStatusForPriceProtection";
  //   return this.http
  //     .post(
  //       urlValue + "?statusId=" + statusId,
  //       JSON.stringify(request),
  //       this.httpOptions
  //     )
  //     .map((response) => {
  //       const result = new ResponseModel();
  //       result.data = response["data"];
  //       result.message = response["message"];
  //       result.status = response["status"];
  //       return result;
  //     });
  // }

  checkIfHereMapIsAvailable() {
    const urlValue: string = this.url + '/CheckMapsAvailability';
    return this.http.post<ResponseModel>(urlValue, null).map(response => {
      return response;
    });
  }

  getCustomerMappingList(filter: CustomerMappingFilter) {
    const urlValue: string = this.url + '/GetCustomerMappingList';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }
  getRouteCustomerMappingList(filter) {
    const urlValue: string = this.url + '/GetRouteCustomerMappingList';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }
  getRouteHistoryMappingList(filter) {
    const urlValue: string = this.url + '/GetRouteHistoryMappingList';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }
  getTerritoryCustomersMappingList(filter) {
    const urlValue: string = this.url + '/GetTerritoryCustomersMappingList';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }
  getEmployeeCustomersMappingList(filter) {
    const urlValue: string = this.url + '/GetEmployeeCustomersMappingList';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  getCustomerOutletMappingDetails(filter) {
    const urlValue: string = this.url + '/GetCustomerOutletMappingDetails';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }
  getRouteHistoryData(filter) {
    const urlValue: string = this.url + '/GetRouteHistoryData';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  checkCustomerGroupAchievementPromotions(groupIds: number[]) {
    const urlValue: string = this.url + '/CheckCustomerGroupAchievementPromotions';
    return this.http.post<ResponseModel>(urlValue, groupIds).map(response => {
      return response;
    });
  }
  getCustomerSubChannels(filter) {
    const urlValue: string = this.url + '/GetCustomerSubChannels';
    return this.http.post<ResponseModel>(urlValue, filter).map((response) => {
      return response;
    });
  }
  checkCustomerOutletDivisionIsExist() {
    const urlValue = this.url + "/CheckCustomerOutletDivisionIsExist";
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response;
    });
  }
}
