import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantURLs } from '../../../shared/models/constants/constant-URL';
import { MenuModel } from '../../../shared/models/menu/menu.model';
import { CoreSession } from '../../core.session';
import { SessionDataProvider } from '../../session-data-provider.service';
import { NavBarService } from '../../sidebar/sidebar.service';

@Component({
  selector: 'app-general-search-result',
  templateUrl: './general-search-result.component.html',
  styleUrls: ['./general-search-result.component.css']
})
export class GeneralSearchResultComponent implements OnInit {

  mainMenus: any[] = [];
  patternSubscription: any;
  pattern: string = null;
  searchCount = 0;
  constructor(private sessionDataProvider: SessionDataProvider,
    public coreSession: CoreSession,
    private router: Router,
    private navService: NavBarService) { }

  ngOnInit() {
    this.pattern = this.coreSession.generalSearchPattern;
    this.subscribeChangeSearchPattern();
    this.buildSearchedMenus();
  }
  ngOnDestroy() {
    if (this.patternSubscription) {
      this.patternSubscription.unsubscribe();
    }
  }
  subscribeChangeSearchPattern() {
    this.patternSubscription = this.navService.GeneralSerachChanged.subscribe(
      (pattern) => {
        setTimeout(() => {
          this.searchCount = 0;
          this.pattern = this.coreSession.generalSearchPattern;
          this.buildSearchedMenus();
        }, 50);
      });
  }
  buildSearchedMenus() {
    this.mainMenus = [];
    if (this.pattern == null || this.pattern.trim() == null || this.pattern == '' || this.pattern.trim() == '') {
      if (this.coreSession.CurrentOperator.landingMenu && this.coreSession.CurrentOperator.landingMenu.menuId !== '') {
        this.router.navigateByUrl('/' + this.coreSession.CurrentOperator.landingMenu.url);
      } else {
        if (this.sessionDataProvider.allMenusAvailable.filter(x => x.isWebMenu).length < 1) {
          this.router.navigateByUrl('/' + ConstantURLs.noMenusURL);
        }
      }
    } else {
      var splitedArr = this.pattern.toLowerCase().split(' ');
      if (splitedArr && splitedArr.length > 0) {
        this.mainMenus = this.sessionDataProvider.allMenusAvailable.filter(menu => menu.isWebMenu === true && menu.parentMenuId === '-1' && (menu.menuId == '500-001' || !menu.isWithInReportHierarchy ));
        if (this.mainMenus && this.mainMenus.length > 0) {
          for (var mainMenu of this.mainMenus) {
            mainMenu.childMenus = [];
            // check if main menu like the pattern
            var isValidMenu = false;
            for (var subPattern of splitedArr) {
              isValidMenu = mainMenu.menuName.toLowerCase().includes(subPattern.trim());
              if (!isValidMenu) {
                break;
              }
            }
            if (isValidMenu) {
              this.prepareMenuPathAndURL(mainMenu);
              mainMenu.childMenus.push(mainMenu);
              this.searchCount += 1;
            }

            // check if any sub menu like the pattern
            var subMenus = this.sessionDataProvider.allMenusAvailable.filter(menu => menu.isWebMenu === true && menu.parentMenuIdsList && menu.parentMenuIdsList.findIndex(id => id === mainMenu.menuId) >= 0 && (menu.menuId == '500-001' || !menu.isWithInReportHierarchy ));
            if (subMenus && subMenus.length > 0) {
              for (var menu of subMenus) {
                isValidMenu = false;
                for (var subPattern of splitedArr) {
                  isValidMenu = menu.menuName.toLowerCase().includes(subPattern.trim());
                  if (!isValidMenu) {
                    break;
                  }
                }
                if (isValidMenu) {
                  this.prepareMenuPathAndURL(menu);
                  mainMenu.childMenus.push(menu);
                  this.searchCount += 1;
                }
              }
            }
          }
        }
      }
      this.mainMenus = this.mainMenus.filter(x => x.childMenus != undefined && x.childMenus.length > 0);
    }
  }
  prepareMenuPathAndURL(menu: MenuModel) {
    var fullURLList = [];
    var fullNameList = [];
    if (menu.parentMenuIdsList && menu.parentMenuIdsList.length > 0) {
      for (let parentId of menu.parentMenuIdsList) {
        var indexOfParent = this.sessionDataProvider.allMenusAvailable.findIndex(x => x.menuId === parentId);
        if (indexOfParent >= 0) {
          if (this.sessionDataProvider.allMenusAvailable[indexOfParent].url != undefined && this.sessionDataProvider.allMenusAvailable[indexOfParent].url != '') {
            fullURLList.push(this.sessionDataProvider.allMenusAvailable[indexOfParent].url);
          }
          if (this.sessionDataProvider.allMenusAvailable[indexOfParent].menuName != undefined && this.sessionDataProvider.allMenusAvailable[indexOfParent].menuName != '') {
            fullNameList.push(this.sessionDataProvider.allMenusAvailable[indexOfParent].menuName);
          }
        }
      }
    }
    if (menu.menuName != undefined && menu.menuName != '') {
      fullNameList.push(menu.menuName);
    }
    if (menu.url != undefined && menu.url != '') {
      fullURLList.push(menu.url);
    }
    if (fullURLList.length > 0) {
      menu.fullPath = fullURLList.join('/');
    }
    if (fullNameList.length > 0) {
      menu.fullName = fullNameList.join(' > ');
    }
  }
  onChangeSeletedMenu(menu: MenuModel) {
    this.navService.searchMenuURL = menu.url;
    if (menu.fullPath != '') {
      this.router.navigateByUrl(menu.fullPath);
    }
  }
}
