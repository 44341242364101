import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { CustomListFilter } from '../../../models/shared-table/custom-list.interface';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ItemPackModel } from '../../../models/Item/item.model';
import { FormGroup, AbstractControl } from '@angular/forms';
import { GeneralFilter } from '../../../models/general/generalFilter.model';

interface ControlValueAccessor {
  writeValue(obj: any): void
  registerOnChange(fn: any): void
  registerOnTouched(fn: any): void

}
@Component({
  selector: 'app-items-lazy-loading-filter',
  templateUrl: './items-lazy-loading-filter.component.html',
  styleUrls: ['./items-lazy-loading-filter.component.css']
})

export class ItemsLazyLoadingFilterComponent implements OnInit,OnChanges {

  selectedItemObj: any;
  isItemsLoading=false;
  isRequired=false;
  itemsDataSource: any[] = [];
  @Input() currentFormControlName = 'packId';
  @Input() hiddenSelected = false;
  @Input() parentForm: FormGroup;
  @Input() filterByCategory: false;
  @Input() categoryIdFromCurrentStock: -1;
  @Input() divisionIdFromCurrentStock: -1;
  @Input() divisionId=-1;
  @Input() categoryId=-1;
  @Input() hideLabel=false;
  @Input() selectedPackId=-1;
  @Input() excludeCompetitorItem=false;
  @Output() selectedItem= new EventEmitter();
  itemsInput$ = new Subject<string>();
  itemFilter:GeneralFilter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: 15
    },
    divisionId:this.divisionId,
    itemCategoryId:this.categoryId,
    packId:this.selectedPackId,
    excludeCompetitorItem:this.excludeCompetitorItem
  };
  bufferSize = 200;
  constructor(private itemService: ItemService) { }

  ngOnInit() {
    if(this.parentForm.get(this.currentFormControlName).validator != null){
      const validator = this.parentForm.get(this.currentFormControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }

    this.populateAllItemsList();
    this.onItemSearch();
  }
ngOnChanges(){
  if(this.hiddenSelected)
  {
  this.parentForm.get(this.currentFormControlName).setValue(null);
  }
  this.itemFilter= {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: 15
    },
    divisionId:this.divisionId,
    itemCategoryId:this.categoryId,
    packId:this.selectedPackId
  };


  this.populateAllItemsList();

}
  onItemDataChanged($event) {
    this.itemFilter = {
      customListFilter: {
        searchFilter: "",
        pageSize: 200,
        page: 0,
      },
      territoryId: -1,
      routeId: -1,
    };
    if ($event) {
      this.selectedItemObj = $event;
    } else {
      this.selectedItemObj = undefined;
    }
this.selectedItem.emit(this.selectedItemObj);
  }
  fetchMoreItemsFromServer(term) {
    // this.itemFilter.customListFilter.searchFilter = term;
    this.itemFilter.customListFilter.page = this.itemsDataSource.length;
    this.populateAllItemsList();
  }

  populateAllItemsList() {

    if(this.selectedPackId){
      this.itemFilter.packId = this.selectedPackId;
    }else{
      this.itemFilter.packId = -1;
    }
    this.itemFilter.itemCategoryId = this.categoryId;
    this.itemFilter.divisionId=this.divisionId;
    this.itemFilter.izLazyLodingCombo = true;
    this.itemFilter.excludeCompetitorItem = this.excludeCompetitorItem;
    this.fillValuesCategoryIdAndDivisionId();

    this.isItemsLoading = true;
    this.itemService
      .getAllItemsLazyLoading(this.itemFilter)
      .subscribe(result => {
        if (result != undefined && result.data != undefined) {
          if(this.itemFilter.customListFilter.page==0){
            this.itemsDataSource = result.data.data;

          }else
          this.itemsDataSource = this.itemsDataSource.concat(
            result.data.data
          );
        }
        this.isItemsLoading = false;
      });
  }
  onItemSearchClear() {
    this.itemFilter.customListFilter.page = 0;
    this.itemFilter.customListFilter.searchFilter = null;
  }
  onItemSearch() {
    this.itemsInput$
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        switchMap(term => this.getItemSearchResultFromServer(term))
      )
      .subscribe(data => {
        this.itemsDataSource = data.slice(0, this.bufferSize);
      });
  }
  getItemSearchResultFromServer(term) {
    this.itemFilter.customListFilter.page = 0;
    this.itemsDataSource = null;
    this.itemFilter.customListFilter.searchFilter = term;
    return this.itemService
      .getAllItemsLazyLoading(this.itemFilter)
      .map(response => {
        return <ItemPackModel[]>response.data.data;
      });
  }

  fillValuesCategoryIdAndDivisionId()
  {
    if (this.categoryIdFromCurrentStock != -1 && this.categoryIdFromCurrentStock != null)
    {
      this.itemFilter.itemCategoryId = this.categoryIdFromCurrentStock ;
    }
    if (this.divisionIdFromCurrentStock != -1 && this.divisionIdFromCurrentStock != null)
    {
      this.itemFilter.divisionId = this.divisionIdFromCurrentStock ;
    }
  }

}
