import { Component, OnInit, Input, OnDestroy, ViewChild, OnChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { CustomerService } from '../../../services/data-definition/customer/customers.service';

@Component({
    selector: 'app-select-customer-groups',
    templateUrl: './select-customer-groups.component.html',
    styleUrls: ['./select-customer-groups.component.css']
})
export class SelectCustomerGroupsComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('selectCustomerGroupsDialog', { static: true }) selectCustomerGroupsDialog: any;
    OkResult: DialogResult = DialogResult.Ok;

    @Input() currentId = 'CustomerGroupsSelection';
    @Input() addedCustomerGroupsList: any[] = [];
    selectedOraganizationIds = '';
    @Input() channelId: number = -1;
    @Input() subChannelId: number = -1;

    query;
    public customerGroupsFilterForm: FormGroup;
    allowPromotionPriority = false;
    showSelectCustomerGroupsDialog: boolean = false;

    sharedTabelData: ITableProperties = {
        pageSize: 100,
        showPaginator: true,
        isOnline: true,
        showSearch: true,
        // showSearchBtn: true,
        isMultiSelection: true,
        showEditButton: false,
        rowOperations: [],
        multiSelectionOperations: [],
        specificActionWhenCheckAll: true,
        specificActionWhenCheckRow: true,
        columns: [
            { title: 'Desc_Groups', key: 'groupCodeName', isSortable: true, width: '100%' }
        ]
    };
    dataSource: SharedTableResult = {
        totalItems: 0,
        data: []
    };
    customerGroupsFilter: any = {
        customListFilter: {
            searchFilter: '',
            page: 0,
            pageSize: 100
        },
        channelId: -1,
        subChannelId: -1,
        organizationIds: ''
    }

    constructor(
        private coreSession: CoreSession,
        private translateService: TranslateService,
        private customerService: CustomerService,
        private config: NgbModalConfig,
        private modalService: NgbModal,
        private sessionData: SessionDataProvider
    ) {
        this.config.backdrop = true;
        this.config.keyboard = true;
    }
    ngOnInit() {
    }
    ngOnChanges() {
        this.initFilterForm();
    }
    ngOnDestroy() {
        this.config.backdrop = true;
        this.config.keyboard = true;
    }
    initFilterForm() {
        this.customerGroupsFilterForm = new FormGroup({
            channelId: new FormControl(null),
            subChannelId: new FormControl(null)
        });
    }
    populateCustomerGroups() {
        this.customerGroupsFilter.channelId = (!this.customerChannelInput.value || this.customerChannelInput.value === null) ? -1 : this.customerChannelInput.value;
        this.customerGroupsFilter.subChannelId = (!this.customerSubChannelInput.value || this.customerSubChannelInput.value === null) ? -1 : this.customerSubChannelInput.value;
        this.customerGroupsFilter.organizationIds = this.selectedOraganizationIds;
        this.customerGroupsFilter.customListFilter = this.query ? this.query : this.customerGroupsFilter.customListFilter;
        this.coreSession.ModalLoading.Show();
        this.customerService.getCustomerGroupsListSharedTable(this.customerGroupsFilter).subscribe(response => {
            this.coreSession.ModalLoading.Hide();
            if (response.status != null && response.status >= 0) {
                this.reflectAddedBeforeCustomerGroups(response.data);
            } else {
                this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
            }
        }, (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
        });
    }
    reflectAddedBeforeCustomerGroups(dataSource) {
        if (dataSource && dataSource.data && dataSource.data.length > 0) {
            dataSource.data.forEach(newCustomerGroups => {
                var index = this.addedCustomerGroupsList.findIndex(addedCustomerGroups => addedCustomerGroups.groupId === newCustomerGroups.groupId);
                if (index >= 0) {
                    newCustomerGroups.isChecked = true;
                    newCustomerGroups.disableValuesUsed = false;
                    newCustomerGroups = this.addedCustomerGroupsList[index];
                }
            })
        }
        this.dataSource = {
            totalItems: dataSource.totalItems,
            data: dataSource.data
        }
    }
    public closeSelectCustomerGroupsDialog() {
        this.modalService.dismissAll()
        this.showSelectCustomerGroupsDialog = false;
    }
    get customerChannelInput() {
        return this.customerGroupsFilterForm.get('channelId');
    }
    get customerSubChannelInput() {
        return this.customerGroupsFilterForm.get('subChannelId');
    }
    onFindCustomerGroups() {
        this.populateCustomerGroups();
    }
    onClearFilters() {
        this.customerGroupsFilterForm.reset();
    }
    onCheckRowOperation(customerGroups) {
        var index = this.addedCustomerGroupsList.findIndex(x => x.groupId === customerGroups.groupId);
        if (customerGroups.isChecked) {
            if (index < 0) {
                this.addedCustomerGroupsList.push(customerGroups);
                customerGroups.disableValuesUsed = false;
            }
        } else {
            if (index >= 0) {
                customerGroups.disableValuesUsed = true;
                this.addedCustomerGroupsList.splice(index, 1);
            }
        }
    }
    onCheckAllOperation(checkAll: boolean) {
        this.dataSource.data.forEach(customerGroups => {
            var index = this.addedCustomerGroupsList.findIndex(x => x.groupId === customerGroups.groupId);
            if (index >= 0 && !checkAll) {
                customerGroups.disableValuesUsed = true;
                this.addedCustomerGroupsList.splice(index, 1);
            } else if (index < 0 && checkAll) {
                customerGroups.disableValuesUsed = false;
                this.addedCustomerGroupsList.push(customerGroups);
            }
        })
    }
    onTableFilterChanged(query: any) {
        this.customerGroupsFilter.customListFilter = query;
        this.query = query;
        this.populateCustomerGroups();
    }
    onValueChanges(event: any) {
        let changedValue = event.object;
        let priority = +changedValue.priority
        if (!this.addedCustomerGroupsList) { this.addedCustomerGroupsList = []; }
        let index = this.addedCustomerGroupsList.findIndex(a => a.promotionId === changedValue.promotionId);
        if (index > -1) {
            this.addedCustomerGroupsList[index].priority = priority;
        } else {
            this.addedCustomerGroupsList[index].priority = priority;
        }
    }
    public showDialog(organizationIds: any) {
        debugger
        this.sharedTabelData.columns = [
            { title: 'Desc_Groups', key: 'groupCodeName', isSortable: true, width: '100%' }
        ];
        this.selectedOraganizationIds = organizationIds;
        this.coreSession.SetTitle('Select Groups');
        this.showSelectCustomerGroupsDialog = true;
        this.customerGroupsFilter.customListFilter = {
            searchFilter: '',
            page: 0,
            pageSize: 100
        };
        this.customerGroupsFilterForm.reset();

        this.customerChannelInput.setValue(this.channelId && this.channelId != -1 ? this.channelId : null);
        this.customerSubChannelInput.setValue(this.subChannelId && this.subChannelId != -1 ? this.subChannelId : null);


        this.populateCustomerGroups();
        return this.modalService.open(this.selectCustomerGroupsDialog, { centered: true, size: 'xl' }).result.then(
            (result) => {
                if (result === DialogResult.Ok) {
                    return this.addedCustomerGroupsList;
                } else {
                    return -1;
                }
            });
    }

}
