import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TerritoryService } from '../../../content/master-data-definition/employee-managment/territory/territory.service';
import { CoreSession } from '../../../core/core.session';
import { SessionDataProvider } from '../../../core/session-data-provider.service';
import { ConstantMessages } from '../../models/constants/constant-message';
import { DialogResult } from '../../models/enums/dialog-result.enum';
import { SharedTableResult } from '../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../models/shared-table/table-properties.interface';
import { ConstantConfigurations } from '../../models/constants/constant-configuration';

@Component({
  selector: 'app-select-territories',
  templateUrl: './select-territories.component.html',
  styleUrls: ['./select-territories.component.css']
})
export class SelectTerritoriesComponent implements OnInit {
  @ViewChild('selectTerritoriesDialog', { static: true }) selectTerritoriesDialog: any;
  OkResult: DialogResult = DialogResult.Ok;
  @Input() selectedEmployeeId: number = -1;
  @Input() addedTerritoriesList: any[] = [];
  @Input() currentId = 'customerSelection';
  @Input() customerAccounts: any[] = [];
  @Input() skipCheckCustomerAccounts: boolean = false;
  public filterForm: FormGroup;
  isTerritoryLoading = false;
  sharedTabelData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    isOnline: false,
    showSearch: true,
    // showSearchBtn: true,
    isMultiSelection: true,
    showEditButton: false,
    rowOperations: [],
    multiSelectionOperations: [],
    specificActionWhenCheckAll: true,
    specificActionWhenCheckRow: true,
    columns: [
      { title: 'Desc_Territory', key: 'territoryCodeName', isSortable: true, width: '50%' },
      { title: 'Desc_Route', key: 'routeName', isSortable: true, width: '50%' }
    ]
  };
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  filter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    employeeId: -1,
    territoryId: -1,
    organizationId: -1,
    CustomerAccountsOrganizationId: [],
    territoryIds: ""
  };
  ForceCustomerOrganizationSelection: boolean = false;
  activeAccountsList: any[];
  territoryDataSource: any[] = [];
  territoryIds: string;

  constructor(private coreSession: CoreSession,
    private modalService: NgbModal,
    private territoryService: TerritoryService,
    private translateService: TranslateService,
    private sessionData: SessionDataProvider,
    private config: NgbModalConfig) {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }

  ngOnInit() {
    this.ForceCustomerOrganizationSelection = this.sessionData.getConfigurationValue(ConstantConfigurations.ForceCustomerOrganizationSelection).toLowerCase() === "true";
    this.initFilterForm();
  }



  initFilterForm() {
    this.filterForm = new FormGroup({
      employeeId: new FormControl(),
      territoryId: new FormControl(),
    });
  }

  onTableFilterChanged(query: any) {
    this.filter.customListFilter = query;
    this.populateRoutesSharedTable();
  }

  populateTerritories() {
    if (this.ForceCustomerOrganizationSelection) {
      if (this.customerAccounts && this.customerAccounts.length > 0) {
        this.filter.CustomerAccountsOrganizationId = [];
        this.customerAccounts.forEach(acc => {
          this.filter.CustomerAccountsOrganizationId.push(acc.organizationId);
        });
      }
      else {

      }
    } else {
      this.filter.CustomerAccountsOrganizationId = [];
    }
    this.territoryDataSource = [];
    this.filter.organizationId = -1;
    this.filter.employeeId = this.filterForm.get('employeeId').value != null ? this.filterForm.get('employeeId').value : -1;
    this.filter.territoryIds = this.territoryIds;
    this.isTerritoryLoading = true;
    this.territoryService.getTerritoriesListSharedTable(this.filter).subscribe(response => {
      this.territoryDataSource = response.data && response.data.data? response.data.data : [];
      this.isTerritoryLoading = false;
    });
  }

  onEmployeeSelected($event) {
    this.filter.employeeId = $event ? $event.employeeId : -1;
    this.filterForm.get('territoryId').setValue(null);
    if (this.ForceCustomerOrganizationSelection) {
      if (this.customerAccounts && this.customerAccounts.length > 0) {
        this.populateTerritories();
      }
    } else {
      this.populateTerritories();
    }

  }

  onTerritorySelected(event) {
    //.........
  }


  showDialog(territoryIds: string) {
    this.territoryIds = territoryIds;
    this.coreSession.SetTitle('Select Territories');
    this.filter.customListFilter = {
      searchFilter: '',
      page: 0,
      pageSize: 50
    };
    this.filterForm.reset();
    if (this.ForceCustomerOrganizationSelection) {
      this.activeAccountsList = this.customerAccounts.filter(acc => acc.dataAction != 4);
      if (this.customerAccounts && this.customerAccounts.length > 0 && this.activeAccountsList.length > 0 || this.skipCheckCustomerAccounts) {
        this.populateTerritories();
        this.populateRoutesSharedTable();
      }
      else {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption),
          this.translateService.instant(ConstantMessages.MsgDefineOneCustomerAccountAtleast));
        this.filterForm.get('territoryId').disable();
        this.filterForm.get('employeeId').disable();
      }
    }
    else {
      this.populateRoutesSharedTable();
      this.populateTerritories();
    }
    return this.modalService.open(this.selectTerritoriesDialog, { centered: true, size: 'lg' }).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          return this.addedTerritoriesList;
        } else {
          return -1;
        }
      });

  }

  setAccountsData(customerAccounts: any[]) {
    this.customerAccounts = customerAccounts;
  }

  onCheckOperation(route: any) {
    let index = -1;
    index = this.addedTerritoriesList.findIndex(x => x.routeId === route.routeId);
    if (route.isChecked) {
      if (index < 0) {
        this.addedTerritoriesList.push(route);
      }
    } else {
      if (index >= 0) {
        this.addedTerritoriesList.splice(index, 1);
      }
    }
  }

  onCheckAllOperation(checkAll: boolean) {
    let index = -1;
    this.dataSource.data.forEach(item => {
      index = this.addedTerritoriesList.findIndex(x => x.routeId === item.routeId);
      if (index >= 0 && !checkAll) {
        this.addedTerritoriesList.splice(index, 1);
      } else if (index < 0 && checkAll) {
        this.addedTerritoriesList.push(item);
      }
    })
  }

  onClearFilters() {
    this.filterForm.reset();
  }

  onFindRoutes() {
    this.populateRoutesSharedTable();
  }

  populateRoutesSharedTable() {
    // this.ForceCustomerOrganizationSelection = this.sessionData.getConfigurationValue(ConstantConfigurations.ForceCustomerOrganizationSelection).toLowerCase() === "true";
    this.filter.organizationId = -1;
    this.filter.employeeId = this.filterForm.get('employeeId').value != null ? this.filterForm.get('employeeId').value : -1;
    this.filter.territoryId = this.filterForm.get('territoryId').value != null ? this.filterForm.get('territoryId').value : -1;
    if (this.ForceCustomerOrganizationSelection) {
      if (this.customerAccounts && this.customerAccounts.length > 0) {
        this.filter.CustomerAccountsOrganizationId = [];
        // this.customerAccounts.forEach(acc => {
        //   this.filter.CustomerAccountsOrganizationId.push(acc.organizationId);
        // });
        this.activeAccountsList.forEach(acc => {
          this.filter.CustomerAccountsOrganizationId.push(acc.organizationId);
        });
      } else {
        // Error No Accounts
      }
    } else {
      this.filter.CustomerAccountsOrganizationId = [];
    }
    this.territoryService.getRoutesTerritoryList(this.filter).subscribe(response => {
      if (response && response.data) {
        this.dataSource = {
          totalItems: response.data.length,
          data: response.data
        };
      }
      this.reflectIfAddedBefore(response);
    });
  }
  reflectIfAddedBefore(dataSource) {
    if (dataSource && dataSource.data && dataSource.data.length > 0) {
      dataSource.data.forEach(i => {
        if (this.addedTerritoriesList && this.addedTerritoriesList.length > 0) {
          var index = this.addedTerritoriesList.findIndex(x => x.routeId === i.routeId);
          if (index >= 0) {
            i.isChecked = true;
            i = this.addedTerritoriesList[index];
          }
        }
      })
    }
  }
  ngOnDestroy() {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }

}
