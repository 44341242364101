import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  OnChanges,
  EventEmitter,
  Output
} from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CoreSession } from "../../../../core/core.session";
import { TranslateService } from "@ngx-translate/core";
import { DialogResult } from "../../../models/enums/dialog-result.enum";
import { ItemService } from "../../../services/data-definition/product/item.service";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { DialogMode } from "../../../models/enums/dialog-mode.enum";
import { SharedTableResult } from "../../../models/shared-table/shared-table-result.interface";
import { ITableProperties } from "../../../models/shared-table/table-properties.interface";
import { PromotionTypes } from "../../../models/enums/promotion-types.enum";
import { PromotionOutputDetailTypes } from "../../../models/enums/promotion-output-detail-types.enum";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import { CalculationService } from '../../../services/calculations.service';
import { PackGroupService } from "../../../services/data-definition/product/pack-group.service";

@Component({
  selector: "app-pack-group-items",
  templateUrl: "./pack-group-items.component.html",
  styleUrls: ["./pack-group-items.component.css"]
})
export class PackGroupItemsComponent implements OnInit, OnDestroy, OnChanges {
  modalRef: NgbModalRef;

  @ViewChild("content", { static: true })
  content: any;

  @Output()
  takePackGroupItems = new EventEmitter();

  @Output()
  closePackGroupItems = new EventEmitter();

  @Input()
  data: any = {};

  selectedItems: any[] = [];
  packGroupName = "";
  neededQuantityOverGroup = 0;
  totalQuantity: number = 0;

  toleranceAmount = 0.0;
  tolerancePercentage = 0.0;
  addedAmount = false;
  includeTax = false;
  neededAmountOverGroup = 0;
  totalAmount: number = 0;
  showPackGroupName: boolean = true;

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  dataTableProperties: ITableProperties = {
    pageSize: 5,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    isMultiSelection: false,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: []
  };

  numberOfStockDigitsFormat: number = 0;
  numberOfDigitsFormat: number = 0;
  AllowFractionsInQuantity: boolean = false;
  applyRoundingPerLine: boolean = false;
  applyZATCARoundingRules: boolean = false;

  constructor(
    private modalService: NgbModal,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private itemService: ItemService,
    private packGroupService: PackGroupService,
    private sessionData: SessionDataProvider,
    private calculationService: CalculationService
  ) {
    this.numberOfDigitsFormat = +sessionData.getConfigurationValue(ConstantConfigurations.NumberOfDigits);
    this.numberOfStockDigitsFormat = +sessionData.getConfigurationValue(ConstantConfigurations.NumberOfStockDigits);
    this.applyRoundingPerLine = sessionData.getConfigurationValue(ConstantConfigurations.ApplyRoundingOnCalculations).toLowerCase() === "true";
    this.applyZATCARoundingRules = sessionData.getConfigurationValue(ConstantConfigurations.ApplyZATCARoundingRules).toLowerCase() === "true";
  }

  ngOnInit() {

    this.AllowFractionsInQuantity = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowFractionsInQuantity).toLowerCase() === "true"
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnChanges() {
    this.showPackGroupName = true;
    if (this.data) {
      if (this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsOnInvoicePercentage
        || this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsWithCertainValue
        || this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.DiscountPercentageOrQtyOverGroupWithSameAmount) {
        this.addedAmount = true;
      } else {
        this.addedAmount = false;
      }
      if (this.data.includeTax) {
        this.includeTax = true;
      } else {
        this.includeTax = false;
      }

      if (this.data.outputOptionDetailTypeId) {
        if (this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsWithCertainValue) {
          this.showPackGroupName = false;
        }
      }
    }

    if (this.addedAmount) {
      if (this.includeTax) {
        this.dataTableProperties.columns = [
          {
            title: "Desc_Item_Name",
            key: "itemName",
            isSortable: false,
            width: "20%"
          },
          {
            title: "Desc_Item_Code",
            key: "itemCode",
            isSortable: false,
            width: "20%"
          },
          {
            title: "Desc_UOM",
            key: "uom",
            isSortable: false,
            width: "20%"
          },
          {
            title: "Desc_Price",
            key: "price",
            isSortable: false,
            numberOfDigits: true,
            width: "10%"
          },
          {
            title: "Desc_Tax",
            key: "tax",
            isSortable: false,
            width: "10%"
          },
          {
            title: "Desc_Quantity",
            key: "requiredQty",
            isSortable: false,
            width: "20%",
            isEditable: true,
            numberOfStockDigits: true
          }
        ];
      } else {
        this.dataTableProperties.columns = [
          {
            title: "Desc_Item_Name",
            key: "itemName",
            isSortable: false,
            width: "20%"
          },
          {
            title: "Desc_Item_Code",
            key: "itemCode",
            isSortable: false,
            width: "20%"
          },
          {
            title: "Desc_UOM",
            key: "uom",
            isSortable: false,
            width: "20%"
          },
          {
            title: "Desc_Price",
            key: "price",
            isSortable: false,
            numberOfDigits: true,
            width: "20%"
          },
          {
            title: "Desc_Quantity",
            key: "requiredQty",
            isSortable: false,
            width: "20%",
            isEditable: true,
            numberOfStockDigits: true
          }
        ];
      }

    } else {
      this.dataTableProperties.columns = [
        {
          title: "Desc_Item_Name",
          key: "itemName",
          isSortable: false,
          width: "25%"
        },
        {
          title: "Desc_Item_Code",
          key: "itemCode",
          isSortable: false,
          width: "25%"
        },
        {
          title: "Desc_UOM",
          key: "uom",
          isSortable: false,
          width: "25%"
        },
        {
          title: "Desc_Quantity",
          key: "requiredQty",
          isSortable: false,
          width: "25%",
          isEditable: true,
          numberOfStockDigits: true
        }
      ]
    }

    if (this.data) {
      if (this.addedAmount) {
        this.checkNeededAmount();
        this.populateItemsListWithPrice(this.data.packGroupId);
      } else if (this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.SummationOverGroup || this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.OpenSteps || this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.DynamicTargetSummationOverGroup) {
        this.checkNeededQuantity();
        this.populateItemsList(this.data.packGroupId);
      } else {
        this.populateItemsList(this.data.packGroupId);
      }
      this.selectedItems = [];
      this.totalQuantity = 0;
      this.totalAmount = 0;
    }
  }

  populateItemsList(packGroupId) {
    this.coreSession.ModalLoading.Show();
    this.packGroupService.getPackGroupItems(packGroupId, true).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.packGroupName = response.data.packGroupNames;
          response.data.packGroupItems.forEach(element => {
            element.requiredQty = null;
          });
          this.dataSource = {
            data: response.data.packGroupItems,
            totalItems: response.data.packGroupItems.length
          };
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  populateItemsListWithPrice(packGroupId) {

    var filter = {
      packGroupId: packGroupId,
      customerId: this.data.customerId,
      outletId: this.data.outletId,
      includeTax: this.data.includeTax
    }
    if (this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.FreeItemsWithCertainValue) {
      filter.packGroupId = -1;
    }
    this.coreSession.ModalLoading.Show();
    this.itemService.getPackItemsWithPrice(filter).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.packGroupName = response.data.packGroupNames;
          response.data.packGroupItems.forEach(element => {
            element.requiredQty = null;
          });
          this.dataSource = {
            data: response.data.packGroupItems,
            totalItems: response.data.packGroupItems.length
          };
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }

  checkNeededAmount() {
    let repeatCount = 1;
    if (this.data.promotionType === PromotionTypes.StepsValues) {
      this.neededAmountOverGroup = this.data.sumOfOptionsValues;
    } else {
      if (this.data.isRepeated) {
        repeatCount = this.data.promotonRepeatCount;
      }
      this.neededAmountOverGroup = this.data.required * repeatCount;
    }
    this.neededAmountOverGroup = this.getNumberOnDigitFormat(this.neededAmountOverGroup);
  }

  checkNeededQuantity() {
    let repeatCount = 1;
    if (this.data.promotionType === PromotionTypes.StepsValues) {
      this.neededQuantityOverGroup = this.data.sumOfOptionsValues;
    } else if (this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.DynamicTargetSummationOverGroup || this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.OpenSteps) {
      if (this.data.defindPackGroupQuantities[0].qtyInPcs == 1)
        this.data.required = this.calculationService.formatNumeric(parseFloat(this.data.required), 0);
      this.neededQuantityOverGroup = this.data.required * repeatCount;
    }
    else {
      if (this.data.isRepeated) {
        repeatCount = this.data.promotonRepeatCount;
      }
      this.neededQuantityOverGroup = this.data.required * repeatCount;
    }
    this.neededQuantityOverGroup = this.getNumberOnStockDigitFormat(this.neededQuantityOverGroup);
  }

  onTakePackGroupItems() {
    if (this.addedAmount) {
      this.tolerancePercentage = parseFloat(this.sessionData.getConfigurationValue(ConstantConfigurations.ToleranceValueOverPromotionBenefit));
      if (this.tolerancePercentage > 0) {
        this.toleranceAmount = (this.neededAmountOverGroup * this.tolerancePercentage) / 100;
      }
      if (this.neededAmountOverGroup > (this.totalAmount + this.toleranceAmount)) {

        this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgAddEnoughAmount), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
          (res: DialogResult) => {
            if (res === DialogResult.Yes) {
              this.takePackGroupItems.emit(this.selectedItems);
              this.modalRef.close();
            }
          });

      } else if (this.neededAmountOverGroup < (this.totalAmount - this.toleranceAmount)) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgAddedQuantityMoreThanNeeded));
      } else {
        this.takePackGroupItems.emit(this.selectedItems);
        this.modalRef.close();
      }
    }
    else {
      if (this.neededQuantityOverGroup > this.totalQuantity && (this.getNumberOnDigitFormat((this.neededQuantityOverGroup - this.totalQuantity) * this.data.defindPackGroupQuantities[0].qtyInPcs) >= 1)) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgAddEnoughQuantity));
      } else if (this.neededQuantityOverGroup < this.totalQuantity) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgAddedQuantityMoreThanNeeded));
      } else {
        this.takePackGroupItems.emit(this.selectedItems);
        this.modalRef.close();
      }
    }
    // }else{
    //   if (this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.OpenSteps || this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.DynamicTargetSummationOverGroup || this.data.outputOptionDetailTypeId == PromotionOutputDetailTypes.SummationOverGroup) {
    //     let isValid:boolean = true;
    //     let QtyToCheck = this.totalQuantity - this.neededQuantityOverGroup;
    //     if (QtyToCheck < 0)
    //       QtyToCheck = QtyToCheck * -1;
    //     if (!this.AllowFractionsInQuantity) {
    //       // in case allow fraction is false and the diff between added qty is less than one , then accept the qty because user can't add pieces of pieces
    //       // ex : buy 5 -> get 2 and user added 8 --> then he should get 3.2  .. if user add 3 then accept it because he can't add 0.2
    //       // this case will solve just pcs--> pcs case
    //       if ((this.totalQuantity - this.neededQuantityOverGroup) == 0.5) {
    //         isValid = true;
    //       }
    //       else {
    //         if (parseFloat(this.calculationService.formatNumeric(QtyToCheck,0)) >= 1) // away from zero
    //         {
    //           isValid = false;
    //         }
    //       }
    //     }
    //     else {
    //       if (parseFloat(this.calculationService.formatNumeric(this.totalQuantity, this.getNumberOfDecimals(this.neededQuantityOverGroup.toString().trim()))) != this.neededQuantityOverGroup) {
    //         isValid = false;
    //       }
    //     }
    //     if (!isValid) {
    //       if (this.data.PackGroupQuantity == 1)
    //         this.coreSession.showError(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MessageYouCanAddItemsWithQuantityOnly).replace('{0}', parseFloat(this.calculationService.formatNumeric(this.neededQuantityOverGroup,0)).toString()));
    //       else
    //         this.coreSession.showError(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MessageYouCanAddItemsWithQuantityOnly).replace('{0}',this.getNumberOnStockDigitFormat(this.neededQuantityOverGroup).toString()));
    //       return;
    //     }
    //     else {
    //       this.takePackGroupItems.emit(this.selectedItems);
    //       this.modalRef.close();
    //     }
    //   } else {
    //     if (this.neededQuantityOverGroup > this.totalQuantity) {
    //       this.coreSession.showError(this.translateService.instant(ConstantMessages.WarningCaption),this.translateService.instant(ConstantMessages.MsgAddEnoughQuantity));
    //     } else if (this.neededQuantityOverGroup < this.totalQuantity) {
    //       this.coreSession.showError(this.translateService.instant(ConstantMessages.WarningCaption),this.translateService.instant(ConstantMessages.MsgAddedQuantityMoreThanNeeded));
    //     } else {
    //       this.takePackGroupItems.emit(this.selectedItems);
    //       this.modalRef.close();
    //     }
    //   }
    // }
  }
  onCloseDialog() {
    this.closePackGroupItems.emit(this.data);
    this.modalRef.dismiss();
  }
  getNumberOfDecimals(number: string) {
    /*
    for 2.5 it will return 1
    for 2 it will return 0
    for 2.55 it will return 2
     */
    let result = 0;
    let parts: string[] = (number.replace('.', ':')).split(':');
    if (parts.length > 1) {
      result = parts[1].length;
    }
    return result;
  }

  onItemQuantityChanged(event) {
    let quantity = +event.object.requiredQty;
    let index = this.selectedItems.findIndex(
      i => i.packId === event.object.packId
    );

    if (this.addedAmount) {

      if (quantity == 0) {
        event.object.requiredQty = null;
        if (index != -1) {
          this.selectedItems.splice(index, 1);
        }
      } else if (quantity > 0) {
        if (index === -1) {
          event.object.requiredQty = quantity;
          this.selectedItems.push(event.object);
        } else {
          this.selectedItems[index].requiredQty = quantity;
        }
      }
      this.updateTotalAmount();


    } else {

      if (quantity == 0) {
        event.object.requiredQty = null;
        if (index != -1) {
          this.selectedItems.splice(index, 1);
        }
      } else if (quantity > 0) {
        if (index === -1) {
          event.object.requiredQty = quantity;
          this.selectedItems.push(event.object);
        } else {
          this.selectedItems[index].requiredQty = quantity;
        }
      }
      this.updateTotalQuantity();
    }
  }

  getNumberOnDigitFormat(numToRound: number) {
    return parseFloat(this.calculationService.formatNumeric(numToRound, this.numberOfDigitsFormat));
  }
  getNumberOnStockDigitFormat(numToRound: number) {
    return parseFloat(this.calculationService.formatNumeric(numToRound, this.numberOfStockDigitsFormat));
  }
  updateTotalQuantity() {
    this.totalQuantity = 0;
    this.selectedItems.forEach(item => {
      this.totalQuantity +=
        (item.requiredQty * item.piecesInPack) /
        this.data.defindPackGroupQuantities.find(y => y.itemId == item.itemId).qtyInPcs;
    });
    this.totalQuantity = this.totalQuantity;
  }

  updateTotalAmount() {
    this.totalAmount = 0;
    var gross = 0;
    var tax = 0;
    if (this.includeTax) {
      this.selectedItems.forEach(item => {
        gross = item.requiredQty * item.price;
        if (this.applyRoundingPerLine) {
          gross = this.getNumberOnDigitFormat(gross);
        }
        tax = gross * (item.tax / 100);
        if (this.applyRoundingPerLine && !this.applyZATCARoundingRules) {
          tax = this.getNumberOnDigitFormat(tax);
        }
        this.totalAmount += gross + tax;
      });
    } else {
      this.selectedItems.forEach(item => {
        gross = item.requiredQty * item.price;
        if (this.applyRoundingPerLine) {
          gross = this.getNumberOnDigitFormat(gross);
        }
        this.totalAmount += gross;
      });
    }
    this.totalAmount = this.getNumberOnDigitFormat(this.totalAmount);
  }

  public Show() {
    this.modalRef = this.modalService.open(this.content, {
      centered: true,
      backdrop: 'static',
      size: "lg"
    });
    return this.modalRef.result.then(
      result => { },
      resaon => {
        return DialogResult.None;
      }
    );
  }
  public Close() {
    if (this.modalRef) {
      this.modalRef.dismiss('cancel click');
      this.modalRef.close();
    }
    //to be checked later
    this.modalService.dismissAll();
  }
}
