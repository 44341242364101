import { ItemListType } from './../../../../shared/models/enums/itemListType';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { debounceTime, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomerGroupModel } from '../../../../shared/models/customer/customer.group.model';
import { GeneralFilter } from '../../../../shared/models/general/generalFilter.model';
import { SharedTableResult } from '../../../../shared/models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../../shared/models/shared-table/table-properties.interface';
import { MultiSelectionOperation, RowOperation } from '../../../../shared/models/enums/shared-table-operation.enum';
import { ItemService } from '../../../../shared/services/data-definition/product/item.service';
import { MasterDataService } from '../../master-data.service';
import { CoreSession } from '../../../../core/core.session';
import { CustomerService } from '../../../../shared/services/data-definition/customer/customers.service';
import { ConstantMessages } from '../../../../shared/models/constants/constant-message';
import { ITextInput } from '../../../../shared/models/shared-table/custom-list.interface';
import * as cloneDeep from 'lodash/cloneDeep';
import { SelectCustomersComponent } from '../../../../shared/components/customers/select-customers/select-customers.component';
import { EmployeeService } from '../../../employee/employee.service';
import { DialogResult } from '../../../../shared/models/enums/dialog-result.enum';
import { DialogMode } from '../../../../shared/models/enums/dialog-mode.enum';
import { IDateTimePickerProperties } from '../../../../shared/models/dateTimeModel/date-time-properties.interface';
import { DateTimeModel } from '../../../../shared/models/dateTimeModel/date-time.model';
import { SelectItemsComponent } from '../../../../shared/components/items/select-items/select-items.component';
import { CommonButtonStyles } from '../../../../shared/models/enums/common-button-styles.enum';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ConstantConfigurations } from '../../../../shared/models/constants/constant-configuration';
import { EmployeeItemListService } from '../employee-item-list.service';
import { CustomerItemListService } from '../customer-item-list.service';
import { CustomerOOSListService } from '../customer-oos-list.service';
@Component({
  selector: 'app-items-lists-entry',
  templateUrl: './items-lists-entry.component.html',
  styleUrls: ['./items-lists-entry.component.css']
})
export class ItemsListsEntryComponent implements OnInit {
  //#region  [Declarations]
  @ViewChild("selectCustomerDialog", { static: true }) selectCustomerDialog: SelectCustomersComponent;
  //@Input('isRangeDate') isRangeDate = false;
  @Input() isEditMode = false;
  @Input() oosListMode = false;
  @Input() isViewMode = false;
  @Input() itemListObject: any = new Object();
  @Input() events: Observable<void>;
  @Output() onSaveCompleted = new EventEmitter();
  @Input() itemListType: any;
  itemListCustomerMode: boolean = false;
  itemListEmployeeMode: boolean = false;
  fromToDate: DateTimeModel[] = [];
  DateProperties: IDateTimePickerProperties = {
    label: 'Desc_Date',
    formControlName: 'fromToDate',
    isCalendarOnly: true,
    isRange: true
  };
  items: any = [];
  ngSelectList: any = [];

  isItemsLoading = false;
  index = 0;
  bufferSize = 200;
  otherLanguages: any[] = [];
  allChannelsList: any[] = [];
  allEmployeesList: any[] = [];
  isAllCustomersChecked: boolean = false;
  isAllEmployeesChecked: boolean = false;
  allGroupsList: CustomerGroupModel[] = [];
  copyOfAddedCustomers: any[] = [];
  addedCustomersList: any[] = [];
  selectedItems: any[];
  showSelectCustomersDialog = false;
  form: FormGroup;
  itemsInput$ = new Subject<string>();
  showAppliedToData: boolean = false;
  LoadOnScrol = true;
  itemFilter: GeneralFilter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: 30,
    }
  };

  organizationsList: any[] = [];
  organizationDropdownSettings = {
    singleSelection: false,
    idField: 'organizationId',
    textField: 'organizationCodeName',
    selectAllText: '',
    unSelectAllText: '',
    searchPlaceholderText: '',
    allowSearchFilter: true,
    itemsShowLimit: 3,
  };
  organizationIdsString: any = null;

  navLinks: any[];
  showCustomersData: boolean = false;
  showItemsData: boolean = false;

  @ViewChild("selectItemsDialog", { static: true }) selectItemsDialog: SelectItemsComponent;
  copyOfAddedItems: any[] = [];
  addedItemsList: any[] = [];
  showSelectItemsDialog = false;
  itemsSharedTabel: ITableProperties = {
    pageSize: 50,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    isMultiSelection: true,
    showEditButton: false,
    showNewBtn: true,
    newBtnCaption: 'Desc_Add',
    rowOperations: [
      {
        operation: RowOperation.delete,
        title: 'Desc_Delete',
        icon: 'fa fa-close',
        color: '#f1685e'
      }
    ],
    multiSelectionOperations: [{
      operation: MultiSelectionOperation.delete,
      title: 'Desc_Delete',
      icon: 'fa fa-close',
      color: '#f1685e',
      style: CommonButtonStyles.Negative.valueOf()
    }],
    columns: [
      { title: 'Desc_Items', key: 'itemCodeName', isSortable: true, width: '100%' }
    ]
  };
  itemsDataSource: SharedTableResult = {
    totalItems: 0,
    data: [],
  };

  selectAllText = this.translateService.instant(ConstantMessages.SelectAllCaption);
  unSelectAllText = this.translateService.instant(ConstantMessages.UnSelectAllCaption);
  searchPlaceholderText = this.translateService.instant(ConstantMessages.SearchCaption);


  name: ITextInput = { formControlName: "name", placeHolder: "Desc_Item_List_Name", label: "Desc_Item_List_Name", isRequierd: true };

  customersDataSource: SharedTableResult = {
    totalItems: 0,
    data: [],
  };

  TabelData: ITableProperties = {
    pageSize: 150,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    rowOperations: [
      {
        operation: RowOperation.delete,
        title: "Desc_Delete",
        icon: "fa fa-close",
        color: "#f1685e"
      }
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_Items",
        key: "itemCodeName",
        isSortable: true,
        width: "100%"
      }
    ]
  };

  customersSharedTabel: ITableProperties = {
    pageSize: 50,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    isMultiSelection: true,
    showEditButton: false,
    showNewBtn: true,
    newBtnCaption: 'Desc_Add',
    rowOperations: [
    ],
    multiSelectionOperations: [{
      operation: MultiSelectionOperation.delete,
      title: 'Desc_Delete',
      icon: 'fa fa-close',
      color: '#f1685e',
      style: CommonButtonStyles.Negative.valueOf()
    }],
    columns: [
      { title: 'Desc_Customer', key: 'customerCodeName', isSortable: true, width: '50%' },
      { title: 'Desc_Outlet', key: 'outletCodeName', isSortable: true, width: '50%' }
    ]
  };

  dropdownSettings = {
    singleSelection: false,
    idField: 'groupId',
    textField: 'groupName',
    selectAllText: this.selectAllText,
    unSelectAllText: this.unSelectAllText,
    position: 'top',
    allowSearchFilter: true,
    searchPlaceholderText: this.searchPlaceholderText,
    itemsShowLimit: 1
  };

  dropdownChannelSettings = {
    singleSelection: false,
    idField: 'channelSubChannelIds',
    textField: 'channelSubChannelDescriptions',
    selectAllText: this.selectAllText,
    unSelectAllText: this.unSelectAllText,
    position: 'top',
    allowSearchFilter: true,
    searchPlaceholderText: this.searchPlaceholderText,
    itemsShowLimit: 1
  };


  saveBtnSubscription: any;
  ForceCustomerOrganizationSelection = null;

  //#endregion


  //#region [Events Anf Functions]

  constructor(
    private employeeItemListService: EmployeeItemListService,
    private customerItemListService: CustomerItemListService,
    private customerOOSListService: CustomerOOSListService,
    private itemService: ItemService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private masterDataService: MasterDataService,
    private coreSession: CoreSession,
    private customerService: CustomerService,
    private employeeService: EmployeeService,
    private sessionData: SessionDataProvider,) {


  }

  ngOnInit() {
    this.ForceCustomerOrganizationSelection = this.sessionData.getConfigurationValue(ConstantConfigurations.ForceCustomerOrganizationSelection).toLowerCase() === "true"

    this.organizationDropdownSettings.selectAllText = this.translateService.instant(ConstantMessages.SelectAllCaption);
    this.organizationDropdownSettings.unSelectAllText = this.translateService.instant(ConstantMessages.UnSelectAllCaption);
    this.organizationDropdownSettings.searchPlaceholderText = this.translateService.instant(ConstantMessages.SearchCaption);
    if (this.isEditMode) {
      this.oosListMode ? this.coreSession.SetTitle('Edit OOS List') : this.coreSession.SetTitle('Edit Item List');
    } else {
      this.oosListMode ? this.coreSession.SetTitle('Add OOS List') : this.coreSession.SetTitle('Add Item List')
    }

    this.subscribeSaveClick();
    this.initForm();
    if (this.itemListType == ItemListType.ItemListCustomer.valueOf()) {
      this.itemListCustomerMode = true;
      this.populateGroupsList();
      this.populateChannelsSubChannelsList();
    } else if (this.itemListType == ItemListType.ItemListEmployee.valueOf()) {
      this.itemListEmployeeMode = true;
    }
    if (this.itemListType == ItemListType.ItemListCustomer.valueOf() || this.oosListMode) {
      this.fillOrganizations();
    }

    this.prepareNavigation();
    this.navLinks[0].isActive = true;

    if (this.isEditMode && this.itemListObject.itemListId != -1) {

      this.addedCustomersList = this.itemListObject.appliedCustomers;
      this.fillCustomersList();
      this.fillFormInEditMode();
      this.onCheckAllChange();

      if (this.forceOrganizationFilterForCustomerGroups()) {
        this.getSelectedOrganizationIdsAsString(null);
        this.populateGroupsList();
      }

    } else {
      this.itemListObject = {
        itemListId: -1,
        itemListType: this.itemListType,
        oosListMode: this.oosListMode
      }
      this.items = [];

    }
    this.customersSharedTabel.rowOperations.push({
      operation: RowOperation.delete,
      title: 'Desc_Delete',
      icon: 'fa fa-close',
      color: '#f1685e',
    });

    if (this.isViewMode && this.oosListMode) {
      this.isViewMode ? this.coreSession.SetTitle('View OOS List') : this.coreSession.SetTitle('View Item List');
      this.form.disable();
      this.customersSharedTabel.showNewBtn = false;
    }
  }

  populateItemListData() {
    this.coreSession.ModalLoading.Show();
    this.itemService.getItemsListData(this.itemListObject.itemListId, this.itemListType, this.oosListMode).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.itemListObject.languageDescriptions = response.data.languageDescriptions;
          this.itemListObject.appliedChannels = response.data.appliedChannels;
          this.itemListObject.appliedGroups = response.data.appliedGroups;
          this.itemListObject.appliedEmployees = response.data.appliedEmployees;
          this.itemListObject.appliedCustomers = !response.data.appliedCustomers ? [] : response.data.appliedCustomers;
          this.itemListObject.itemsId = response.data.itemsId;
          this.addedCustomersList = this.itemListObject.appliedCustomers;
          this.itemListObject.allCustomers = response.data.allCustomers;
          this.itemListObject.allEmployees = response.data.allEmployees;
          this.itemListObject.AllItems = response.data.AllItems;
          this.itemListObject.appliedOrganizations = response.data.appliedOrganizations;
          this.fillCustomersList();
          this.fillFormInEditMode();
          this.onCheckAllChange();

        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }

  initForm() {
    this.form = this.formBuilder.group({
      name: new FormControl(this.isEditMode ? this.itemListObject.itemName : null, Validators.required),
      itemListCode: new FormControl(this.isEditMode ? this.itemListObject.itemCode : null, Validators.required),
      itemId: new FormControl(),
      groupsControl: new FormControl([]),
      channelControl: new FormControl([]),
      itemsControl: new FormControl(),
      allCustomers: new FormControl(false),
      allEmployees: new FormControl(false),
      employeeControl: new FormControl(null, this.isAllEmployeesChecked || this.itemListType !== ItemListType.ItemListEmployee.valueOf() ? Validators.nullValidator : Validators.required),
      securityGroupIds: new FormControl(),
      fromToDate: new FormControl([], this.oosListMode ? Validators.required : null),
      organizationsList: new FormControl([], this.oosListMode || this.itemListType == ItemListType.ItemListCustomer ? Validators.required : null),
    });
  }

  onChangeDate(date: any[]) {
    if (date)
      this.fromToDate = date;
  }

  fillFormInEditMode() {

    this.itemListCodeControl.setValue(this.itemListObject.itemListCode);
    this.itemListNameControl.setValue(this.itemListObject.itemListName);

    this.organizationsListControl.setValue(this.itemListObject.appliedOrganizations)
    this.addedItemsList = this.itemListObject.items;
    this.itemsDataSource = {
      totalItems: this.addedItemsList.length,
      data: this.addedItemsList
    };
    if (this.itemListObject.allCustomers) {
      this.navLinks[1].isActive = true;
      this.navLinks[0].isActive = false;
      this.navLinks[0].disable = true;
      this.showCustomersData = false;
      this.showItemsData = true;
    }
    this.itemListAllEmployeesControl.setValue(this.itemListObject.allEmployees);
    this.itemListAllCustomersControl.setValue(this.itemListObject.allCustomers);

    if (this.itemListType == ItemListType.ItemListCustomer.valueOf()) {
      this.itemListGroupsControl.setValue(this.itemListObject.appliedGroups);
      this.itemListChannelControl.setValue(this.itemListObject.appliedChannels);
      this.itemListAllCustomersControl.setValue(this.itemListObject.allCustomers);
      this.itemListObject.itemListType = this.itemListType;
      if (this.oosListMode) {
        this.itemListEmployeeControl.setValue(this.itemListObject.appliedEmployees);
        this.securityGroupIdsControl.setValue(this.itemListObject.appliedSecurityGroups);
      }
    }
    else {
      this.itemListEmployeeControl.setValue(this.itemListObject.appliedEmployees);
      this.itemListAllEmployeesControl.setValue(this.itemListObject.allEmployees);
    }
    if (this.oosListMode)
      this.fromToDateControl.setValue([this.itemListObject.startDate, this.itemListObject.endDate]);
  }

  onOtherLanguagesTextSubmitted(event) {
    this.otherLanguages = event;
  }

  FillItemListObject() {

    this.itemListObject.itemListName = this.itemListNameControl.value;
    this.itemListObject.itemListCode = this.itemListCodeControl.value;
    this.itemListObject.appliedOrganizations = this.organizationsListControl.value;

    if (this.itemListType == ItemListType.ItemListCustomer.valueOf()) {
      this.itemListObject.appliedGroups = this.itemListGroupsControl.value ? this.itemListGroupsControl.value : [];
      this.itemListObject.appliedChannels = this.itemListChannelControl.value ? this.itemListChannelControl.value : [];
      this.itemListObject.appliedCustomers = this.addedCustomersList ? this.addedCustomersList : [];
      if (this.oosListMode) {
        if (this.fromToDate && this.fromToDate.length == 2) {
          this.itemListObject.startDateModel = this.fromToDate[0];
          this.itemListObject.endDateModel = this.fromToDate[1];
        }
        this.itemListObject.appliedEmployees = this.itemListEmployeeControl.value ? this.itemListEmployeeControl.value : [];
        this.itemListObject.appliedSecurityGroups = this.securityGroupIdsControl.value ? this.securityGroupIdsControl.value : [];
      }
    }
    else {
      this.itemListObject.appliedEmployees = this.itemListEmployeeControl.value ? this.itemListEmployeeControl.value : [];
    }

    this.itemListObject.allCustomers = this.itemListAllCustomersControl.value === null ? false : this.itemListAllCustomersControl.value;
    this.itemListObject.allEmployees = this.itemListAllEmployeesControl.value === null ? false : this.itemListAllEmployeesControl.value;
    let descriptions = this.otherLanguages;
    if (descriptions.findIndex(x => x.languageId === this.coreSession.selectedLanguageId) < 0) {
      descriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        description: this.form.value.name
      });
    }
    this.itemListObject.descriptionLanguages = descriptions;
  }

  onCheckAllChange() {
    if (this.itemListType == ItemListType.ItemListCustomer)
      this.isAllCustomersChecked = this.itemListAllCustomersControl.value;
    else {
      this.isAllEmployeesChecked = this.itemListAllEmployeesControl.value;
      if (!this.isAllEmployeesChecked)
        this.form.get("employeeControl").setValidators(Validators.required)
      else
        this.form.get("employeeControl").setValidators(Validators.nullValidator)

      this.form.get("employeeControl").updateValueAndValidity();
    }
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  onRowOperationForCustomers(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        this.onDeleteAppliedCustomer(event.object);
        break;
    }
  }

  fillItems() {
    let itemsId = [];
    this.addedItemsList.forEach(x => { itemsId.push(x.itemId) });
    this.itemListObject.itemsId = itemsId;
    this.itemListItemsControl.setValue(itemsId);
    this.itemListObject.AllItems = false;
  }

  onSaveClicked() {
    this.markAsTouched();
    this.fillItems();
    if (this.form.invalid) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }
    if (this.itemListItemsControl.value.length < 1) {
      this.navLinks[1].isActive = true;
      this.navLinks[0].isActive = false;
      this.showCustomersData = false;
      this.showItemsData = true;
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgSelectItem));
      return;
    }
    if (this.oosListMode) {
      var notAssignedToAnyCustomer = !this.isAllCustomersChecked && ((this.form.value.groupsControl == null || (this.form.value.groupsControl != null && this.form.value.groupsControl.length == 0)) && (this.form.value.channelControl == null || (this.form.value.channelControl != null && this.form.value.channelControl.length == 0))
        && (!this.addedCustomersList || this.addedCustomersList.length == 0));
      var notAssignedToAnyEmployee = this.form.value.employeeControl == null || (this.form.value.employeeControl != null && this.form.value.employeeControl.length == 0);
      var notAssignedToAnySecurityGroup = this.form.value.securityGroupIds == null || (this.form.value.securityGroupIds != null && this.form.value.securityGroupIds.length == 0);
      if (notAssignedToAnyCustomer && notAssignedToAnyEmployee && notAssignedToAnySecurityGroup) {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgAssignOOSItemList));
        return;
      }
    }
    else {
      if (this.itemListType == ItemListType.ItemListCustomer.valueOf()) {
        if (!this.isAllCustomersChecked && ((this.form.value.groupsControl == null || (this.form.value.groupsControl != null && this.form.value.groupsControl.length == 0)) && (this.form.value.channelControl == null || (this.form.value.channelControl != null && this.form.value.channelControl.length == 0)) && (this.customersDataSource.data == null || (this.customersDataSource.data != null && this.customersDataSource.data.length == 0)))) {
          this.navLinks[0].isActive = true;
          this.navLinks[1].isActive = false;
          this.showCustomersData = true;
          this.showItemsData = false;
          this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgApplyItemListToCustomer));
          return
        }
      }
      else {
        if (!this.isAllEmployeesChecked && ((this.form.value.employeeControl == null || (this.form.value.employeeControl != null && this.form.value.employeeControl.length == 0)))) {
          this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgApplyItemListToEmployee));
          return;
        }
      }
    }
    this.onItemListSaved();
  }

  onItemListSaved() {
    let codeFilter = {
      tableName: 'ItemsList',
      columnName: 'ItemListCode',
      insertedValue: this.form.value.itemListCode,
      excludedString: ' And ItemListID != ' + this.itemListObject.itemListId,
    }

    this.FillItemListObject();
    this.saveItemListToDB();

    // this.coreSession.ModalLoading.Show();
    // this.masterDataService.checkIfUniqueValue(codeFilter).subscribe(response => {
    //   this.coreSession.ModalLoading.Hide();
    //   if (response.status != null && response.status >= 0) {
    //     if (!response.data) {
    //       this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgUniqueCode));
    //       return;
    //     }
    //     else {

    //     }
    //   }
    //   else {
    //     this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
    //   }
    // }, (error: HttpErrorResponse) => {
    //   this.coreSession.ModalLoading.Hide();
    //   this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
    // });
  }

  ClearSelectedItem() {
    this.form.get("itemId").reset();
  }

  markAsTouched() {
    this.form.get("name").markAsTouched();
    this.form.get("itemListCode").markAsTouched();
    this.form.get("fromToDate").markAsTouched();
    this.form.get("organizationsList").markAsTouched();
    this.form.get("employeeControl").markAsTouched();
  }

  onSavingSuccessResult(result: any) {
    this.coreSession.ModalLoading.Hide();
    if (result.status != null && result.status >= 0) {
      if (!result.data.isValid) {
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), result.data.errorMessage);
      } else {
        this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
        this.onSaveCompleted.emit();
      }

    } else {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), result.message);
    }
  }

  onSavingFailureResult(error: any) {
    this.coreSession.ModalLoading.Hide();
    this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
  }

  saveItemListToDB() {
    var formData: FormData = new FormData();
    if (this.oosListMode) {
      this.itemListObject.oosListMode = true;
    }
    formData.append('itemList', JSON.stringify(this.itemListObject));
    this.coreSession.ModalLoading.Show();
    if (this.itemListType === ItemListType.ItemListCustomer) {
      if (this.oosListMode) {
        this.customerOOSListService.saveItemListData(formData, this.isEditMode).subscribe(
          result => {
            this.onSavingSuccessResult(result);
          },
          error => {
            this.onSavingFailureResult(error);
          }
        );
      } else {
        this.customerItemListService.saveItemListData(formData, this.isEditMode).subscribe(
          result => {
            this.onSavingSuccessResult(result);
          },
          error => {
            this.onSavingFailureResult(error);
          }
        );
      }
    } else {
      this.employeeItemListService.saveItemListData(formData, this.isEditMode).subscribe(
        result => {
          this.onSavingSuccessResult(result);
        },
        error => {
          this.onSavingFailureResult(error);
        }
      );
    }
  }

  populateChannelsSubChannelsList() {
    this.customerService.GetAllCustomerChannels(null).subscribe(result => {
      this.allChannelsList = result;

    });
  }

  populateGroupsList() {
    this.allGroupsList = [];
    if (this.forceOrganizationFilterForCustomerGroups()) {
      if (this.organizationIdsString != null) {
        this.customerService.getAllCustomerGroups(this.organizationIdsString).subscribe(result => {
          this.allGroupsList = result.data;
        });
      }
    } else {
      this.customerService.getAllCustomerGroups(this.organizationIdsString).subscribe(result => {
        this.allGroupsList = result.data;
      });
    }
  }

  fillCustomersList() {
    this.customersDataSource = {
      totalItems: this.addedCustomersList.length,
      data: this.addedCustomersList
    };
  }

  openSelectCustomersDialog() {
    this.form.get("organizationsList").markAsTouched();
    if (this.organizationsListControl.value <= 0) {
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.SelectOrganization));
      return;
    }
    this.getSelectedOrganizationIdsAsString(null);
    this.copyOfAddedCustomers = null;
    this.copyOfAddedCustomers = cloneDeep(this.addedCustomersList);
    this.showSelectCustomersDialog = true;
    this.selectCustomerDialog.showDialog(this.organizationIdsString).then(
      (result) => {
        if (result != -1) {
          this.afterAddingSelectedCustomers(result)
        } else {
          this.closeSelectCustomersDialog();
        }
      });
  }

  afterAddingSelectedCustomers(selectedCustomers) {
    this.addedCustomersList = selectedCustomers;
    this.fillCustomersList();
    this.closeSelectCustomersDialog();
  }

  closeSelectCustomersDialog() {
    this.showSelectCustomersDialog = false;
    if (this.isEditMode) {
      this.coreSession.SetTitle('Edit Item List');
    } else {
      this.coreSession.SetTitle('Add Item List');
    }
  }
  onDeleteAppliedCustomer(deletedOutlet: any) {
    var index = this.addedCustomersList.findIndex(x => x.customerId === deletedOutlet.customerId && x.outletId === deletedOutlet.outletId);
    if (index >= 0) {
      this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgDeleteConfirmation), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
        (res: DialogResult) => {
          if (res === DialogResult.Yes) {
            this.addedCustomersList.splice(index, 1);
            this.fillCustomersList();
          }
        });
    }
  }
  getSelectedOrganizationIdsAsString(organizationIds: any) {

    let organizationsList = organizationIds != null ? organizationIds : this.organizationsListControl.value;
    this.organizationIdsString = null;
    if (organizationsList && organizationsList.length > 0) {
      this.organizationIdsString = organizationsList.map(({ organizationId }) => organizationId).join(',');
    }
  }
  //#endregion

  //#region [Form Getters]

  get itemListCodeControl() {
    return this.form.get('itemListCode');
  }
  get itemListNameControl() {
    return this.form.get('name');
  }
  get itemListGroupsControl() {
    return this.form.get('groupsControl');
  }
  get itemListChannelControl() {
    return this.form.get('channelControl');
  }
  get itemListItemsControl() {
    return this.form.get('itemId');
  }
  get itemListAllCustomersControl() {
    return this.form.get('allCustomers');
  }
  get itemListAllEmployeesControl() {
    return this.form.get('allEmployees');
  }
  get itemListEmployeeControl() {
    return this.form.get('employeeControl');
  }
  get fromToDateControl() {
    return this.form.get('fromToDate');
  }
  get securityGroupIdsControl() {
    return this.form.get('securityGroupIds');
  }
  get organizationsListControl() {
    return this.form.get('organizationsList');
  }
  //#endregion


  showEntrySection(selectedIndex: number) {
    this.navLinks.forEach(a => a.isActive = false);
    this.navLinks[selectedIndex].isActive = true;
    if (selectedIndex == 0) {
      this.showCustomersData = true;
      this.showItemsData = false;
      this.populateChannelsSubChannelsList();
      this.populateGroupsList();
    } else {
      this.showCustomersData = false;
      this.showItemsData = true;
    }
  }

  prepareNavigation() {
    this.navLinks = [
      {
        label: 'Desc_Customers',
        index: 0,
        isActive: false
      },
      {
        label: 'Desc_Items',
        index: 1,
        isActive: false
      }
    ];

    this.showCustomersData = true;
    this.showItemsData = false;
  }

  onRowOperationForItems(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        this.onDeleteAppliedItems(event.object);
        break;
    }
  }

  onDeleteAppliedItems(deletedItem: any) {
    var index = this.addedItemsList.findIndex(x => x.itemId === deletedItem.itemId);
    if (index >= 0) {
      this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgDeleteConfirmation), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
        (res: DialogResult) => {
          if (res === DialogResult.Yes) {
            this.addedItemsList.splice(index, 1);
            this.fillItemsList();
          }
        });
    }
  }

  fillItemsList() {
    this.itemsDataSource = {
      totalItems: this.addedItemsList.length,
      data: this.addedItemsList
    };
  }

  fillOrganizations() {
    this.masterDataService.getOrganizationList().subscribe(result => {
      this.organizationsList = result;
    });
  }

  openSelectItemsDialog() {
    this.copyOfAddedItems = null;
    this.copyOfAddedItems = cloneDeep(this.addedItemsList);
    this.showSelectItemsDialog = true;
    this.selectItemsDialog.showDialog(null).then(
      (result) => {
        if (result != -1) {
          this.afterAddingSelectedItems(result)
        } else {
          this.closeSelectItemsDialog();
        }
      });
  }

  afterAddingSelectedItems(selectedItems) {

    this.addedItemsList = selectedItems;
    this.fillItemsList();
    this.closeSelectItemsDialog();
  }

  closeSelectItemsDialog() {
    this.showSelectItemsDialog = false;
    if (this.isEditMode) {
      this.coreSession.SetTitle('Edit Item List');
    } else {
      this.coreSession.SetTitle('Add Item List');
    }
  }

  disableCustomertab(event) {
    if (event && event.target.checked) {
      this.navLinks[1].isActive = true;
      this.navLinks[0].isActive = false;
      this.navLinks[0].disable = true;
      this.showCustomersData = false;
      this.showItemsData = true;
    } else {
      this.navLinks[1].isActive = true;
      this.navLinks[0].isActive = false;
      this.navLinks[0].disable = false;
      this.showCustomersData = false;
      this.showItemsData = true;
    }
  }

  onMultiSelectionOperationForItems(event: any) {

    switch (event.operation) {
      case MultiSelectionOperation.delete: {
        this.onDeleteSelectedAddedItems(event.object);
        break;
      }
    }
  }


  onDeleteSelectedAddedItems(selectedItemsList) {
    this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgDeleteConfirmation), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
      (res: DialogResult) => {
        if (res === DialogResult.Yes) {
          selectedItemsList.forEach(items => {
            var index = this.addedItemsList.findIndex(x => x.itemId === items.itemId);
            if (index >= 0) {
              this.addedItemsList.splice(index, 1);
            }
          });
        }
        this.fillItemsList();
      });
  }


  onMultiSelectionOperationForCustomers(event: any) {

    switch (event.operation) {
      case MultiSelectionOperation.delete: {
        this.onDeleteSelectedAddedCustomers(event.object);
        break;
      }
    }
  }

  onDeleteSelectedAddedCustomers(selectedCustomersList) {

    this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgDeleteConfirmation), DialogMode.YesNo, this.translateService.instant(ConstantMessages.WarningCaption)).then(
      (res: DialogResult) => {
        if (res === DialogResult.Yes) {
          selectedCustomersList.forEach(customer => {
            var index = this.addedCustomersList.findIndex(x => x.customerId === customer.customerId && x.outletId === customer.outletId);
            if (index >= 0) {
              this.addedCustomersList.splice(index, 1);
            }
          });
        }
        this.fillCustomersList();
      });
  }
  onChangeOrganizationSelection(isSelectAll: boolean, isDeSelectAll: boolean, event: any) {
    if (this.ForceCustomerOrganizationSelection &&
      (this.customersDataSource.totalItems > 0) ||
      (this.itemListChannelControl.value && this.itemListChannelControl.value.length > 0) ||
      (this.itemListGroupsControl.value && this.itemListGroupsControl.value.length > 0)) {
      this.addedCustomersList = [];
      this.itemListChannelControl.setValue([]);
      this.itemListGroupsControl.setValue([]);
      this.fillCustomersList();
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgChangeOrganizationRemoveApplyData));
    }

    if (this.forceOrganizationFilterForCustomerGroups()) {

      let organizations = isSelectAll ? event : isDeSelectAll ? -1 : event;
      this.getSelectedOrganizationIdsAsString(organizations);
      this.populateGroupsList();
    }


  }



  forceOrganizationFilterForCustomerGroups() {
    return this.sessionData.getConfigurationValue(ConstantConfigurations.ForceOrganizationFilterForCustomerGroups).toLowerCase() === "true"
  }

}
