import { MenuModel } from "../menu/menu.model";
import { WebApplication } from '../enums/web-application.enum';

export class OperatorModel {
    public encryptedOperatorId: string;
    public encryptedEmployeeId: string;

    public employeeId: number;
    public operatorId : number;

    public organizationId  : number;
    public supervisorId  : number;
    public helperId : number;
    public salesManagerId : number;
    public driverId  : number;
    public salesRepId : number;
    public organizationAccess : string;
    public employeeName: string;
    public employeeCode: string;
    public isLoggedIn: boolean;
    public email:string;
    public landingMenu:MenuModel;
    public employeeTypeId: number;
    public customerCodeName: string;
    public customerId: number;
    public outletId: number;
    //public imageFile:File;
    constructor() {
        this.isLoggedIn = false;
    }
}
export class OperatorForLogin {
    public username: string = '';
    public password: string = '';
    public languageId: number ;
    public deviceInfo: string = '';
    public RemoveCurrentOperatorSession = false;

}
export class CachedGeneralData{
   
    public assemblyVersionNumber:string='';
    public databaseVersion:string='';
    public webApplication : WebApplication
}