export enum EmployeeTypes
{
    Employee = 1,
    Salesperson= 2,
    Deliveryperson=3,
    Supervisor=4,
    StoreKeeper=5,
    Collector=6,
    Helper = 7,
    None = 8,
    SalesManager = 9,
    Presales = 10,
    SalesDeveloper=11,
    Driver=12,
    SalesRepresentative=13,
    VanInCharge=14, 
    Foreman = 15,
    Picker = 16,
    Merchandiser = 17 ,
    Cashier = 18,
    Inspector = 19
}