import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges, DoCheck, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { CoreSession } from '../../../core/core.session';
import { SettingsService } from '../settings.service';
import { SessionDataProvider } from '../../../core/session-data-provider.service';
import { NavBarService } from '../../../core/sidebar/sidebar.service';
import { FormMode } from '../../../shared/models/enums/form-mode.enum';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogResult } from '../../../shared/models/enums/dialog-result.enum';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.css']
})
export class ChangeLanguageComponent implements OnInit,OnChanges {
  @ViewChild('changeLanguageDialog', { static: true }) changeLanguageDialog: any;
  OkResult: DialogResult = DialogResult.Ok;
  @Input() langList: any[] = [];
  Languages: any[] = [];
  isReload = false;
  selectedLanguage: any;
  enableSubmit = false;
  constructor(
    public coreSession: CoreSession,
    private translate: TranslateService,
    private location: Location,
    private settingsService: SettingsService,
    private navService: NavBarService,
    private sessionDataProvider: SessionDataProvider,
    private modalService: NgbModal
  ) {}
  ngOnChanges() {
    this.Languages = [];
    this.langList.filter(a => a.active).forEach(lang => {
      this.Languages.push({
        name: lang.description,
        value: lang.code,
        isRTL: lang.rtl,
        isChecked: this.coreSession.selectedLanguageStr === lang.code,
        languageId: lang.languageId
      });
    });
  }

  ngOnInit() {
    this.coreSession.SetTitle('Change Language');
    this.sessionDataProvider.loadSessionLanguges();
    // this.langList = this.sessionDataProvider.getSessionLanguages().filter(a => a.active);
    this.langList.forEach(lang => {
      this.Languages.push({
        name: lang.description,
        value: lang.code,
        isRTL: lang.rtl,
        isChecked: this.coreSession.selectedLanguageStr === lang.code,
        languageId: lang.languageId
      });
    });
  }
  onChangeLanguage(language: any) {
    this.enableSubmit = true;
    this.selectedLanguage = language;
  }
  switchLanguage(language: string) {
    this.coreSession.ModalLoading.Show();
    this.coreSession.selectedLanguageStr = language;
    this.translate.use(language);
    this.settingsService.getMenusList(-1, FormMode.None).subscribe(result => {
      this.sessionDataProvider.allMenusAvailable = result.menusList;
      this.sessionDataProvider.addedMenus = result.addedList;
      this.navService.setReloadSidebar();
    });
    window.location.reload();
  }
  public showDialog() {
    return this.modalService.open(this.changeLanguageDialog,  { centered: true}).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          this.switchLanguage(this.selectedLanguage);
        } else {
          this.langList = [];
          return -1;
        }
      });
  }
}
