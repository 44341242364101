import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ResponseModel } from "../../shared/models/api-models/api-models";
import { OrderListFilter } from "../../shared/models/shared-table/custom-list.interface";

@Injectable({
  providedIn: "root"
})
export class OrderService {
  private url = "";
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.url = baseUrl + "Order";
  }

  getOrders(filter: OrderListFilter) {
    const urlValue = this.url + "/GetOrders";
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    });
  }

  getOrder(filter: OrderListFilter) {
    const urlValue = this.url + "/GetOrder";
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    });
  }

  // getInvoicedOrderForView(filter: OrderListFilter) {
  //   const urlValue = this.url + "/GetInvoicedOrderForView";
  //   return this.http.post<ResponseModel>(urlValue, filter).map(response => {
  //     return response;
  //   });
  // }

  getMaxOrderId() {
    const urlValue = this.url + "/GetMaxOrderId";
    return this.http.post<ResponseModel>(urlValue, null).map(response => {
      return response;
    });
  }
  // getRecurringChangedReason() {
  //   const urlValue = this.url + "/GetRecurringChangedReason";
  //   return this.http.post<ResponseModel>(urlValue, null).map(response => {
  //     return response;
  //   });
  // }
  updateOrder(order: any) {
    const urlValue = this.url + "/UpdateOrder";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }
  approveSelectedOrders(ordersList: any) {
    const urlValue = this.url + "/ApproveSelectedOrders";
    return this.http.post<ResponseModel>(urlValue, ordersList).map(response => {
      return response;
    });
  }

  // deleteMultiOrders(ordersList: any) {
  //   const urlValue = this.url + "/DeleteMultiOrders";
  //   return this.http.post<ResponseModel>(urlValue, ordersList).map(response => {
  //     return response;
  //   });
  // }

  isOrderValidToAssign(order: any) {
    const urlValue = this.url + "/IsOrderValidToAppoint";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }

  // isOrderValidToRemoveAssignment(order: any) {
  //   const urlValue = this.url + "/IsOrderValidToRemoveAssignment";
  //   return this.http.post<ResponseModel>(urlValue, order).map(response => {
  //     return response;
  //   });
  // }

  isOrderValidToInvoice(order: any) {
    const urlValue = this.url + "/IsOrderValidToBill";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }
  // isValidToVoidInvoice(order: any) {
  //   const urlValue = this.url + "/IsValidToRemoveOrderBill";
  //   return this.http.post<ResponseModel>(urlValue, order).map(response => {
  //     return response;
  //   });
  // }
  voidOrderInvoice(order: any) {
    const urlValue = this.url + "/VoidOrderInvoice";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }
  InvoiceOrder(order: any) {
    const urlValue = this.url + "/InvoiceOrder";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }
  handlePartialDelivery(order: any) {
    const urlValue = this.url + "/HandlePartialDelivery";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }
  assignOrderToDriver(orders: any) {
    const urlValue = this.url + "/AssignOrder";
    return this.http.post<ResponseModel>(urlValue, orders).map(response => {
      return response;
    });
  }

  removeOrderAssignment(orders: any) {
    const urlValue = this.url + "/RemoveAssignment";
    return this.http.post<ResponseModel>(urlValue, orders).map(response => {
      return response;
    });
  }

  sendLoadRequest(order: any) {
    const urlValue = this.url + "/SendOrderLoadRequest";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }

  rescheduleDeliveryDate(orders: any) {
    const urlValue = this.url + "/RescheduleDeliveryDate";
    return this.http.post<ResponseModel>(urlValue, orders).map(response => {
      return response;
    });
  }

  getDashboardData(isToday: boolean, isMonthly: boolean, isAnnual: boolean) {

    const urlValue =
      this.url +
      "/GetDashboardsData?isToday"
    return this.http.post<ResponseModel>(urlValue, { IsToday: isToday, IsMonthly: isMonthly, IsAnnual: isAnnual }).map(response => {
      return response;
    });
  }

  // saveOrder(order: any) {
  //   const urlValue = this.url + "/InsertOrder";
  //   return this.http.post<ResponseModel>(urlValue, order).map(response => {
  //     return response;
  //   });
  // }

  isValidOperation(order: any) {
    const urlValue = this.url + "/IsValidOperation";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }

  // checkOrderStatusBeforeAnyAction(order: any) {
  //   const urlValue = this.url + "/CheckOrderStatusBeforeAnyAction";
  //   return this.http.post<ResponseModel>(urlValue, order).map(response => {
  //     return response;
  //   });
  // }

  toQueryString(obj: any) {
    const parts = [];
    // tslint:disable-next-line:forin
    for (const property in obj) {
      const value = obj[property];
      if (property !== "customListFilter") {
        if (value != null && value !== undefined) {
          parts.push(
            encodeURIComponent(property) + "=" + encodeURIComponent(value)
          );
        }
      }
    }
    return parts.join("&");
  }
  // getRecurringOrderDetails(recurringObj: any) {
  //   const urlValue = this.url + "/GetRecurringOrderDetails";
  //   return this.http.post<ResponseModel>(urlValue, recurringObj).map(response => {
  //     return response;
  //   });
  // }

  // getRecurringOrdersList() {
  //   const urlValue = this.url + "/GetRecurringOrdersList";
  //   return this.http.post<ResponseModel>(urlValue, null).map(response => {
  //     return response;
  //   });
  // }
  // editRecurringOrder(recurringOrder: any) {
  //   const urlValue = this.url + "/UpdateRecurringOrder";
  //   return this.http.post<ResponseModel>(urlValue, recurringOrder).map(response => {
  //     return response;
  //   });
  // }

  getOrderTypes(orderTypesFilter: any[]) {
    const urlValue = this.url + "/GetOrderTypes";
    return this.http.post<ResponseModel>(urlValue, orderTypesFilter)
      .map(response => {
        return response;
      })
  }

  processRetunOrder(data: any) {
    const urlValue = this.url + "/ProcessRetunOrder";
    return this.http.post<ResponseModel>(urlValue, data).map(response => {
      return response;
    });
  }

  checkResellableItems(orderItemsPackStatuses: any[]) {
    const urlValue: string = this.url + "/CheckResellableItems";
    return this.http
      .post<ResponseModel>(urlValue, orderItemsPackStatuses)
      .map((response) => {
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      });
  }

  isReturnOrderValidToProcess(order: any) {
    const urlValue = this.url + "/IsReturnOrderValidToOperate";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }

  voidReturnOrderTransaction(order: any) {
    const urlValue = this.url + "/VoidReturnOrderTransaction";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }

  checkForPostedCustomerTransactions(order: any) {
    const urlValue = this.url + "/CheckForPostedCustomerTransactions";
    return this.http.post<ResponseModel>(urlValue, order).map(response => {
      return response;
    });
  }

  recalculateBenefitInReturn(returnCommonData: any) {
    const urlValue = this.url + "/RecalculateBenefitInReturn";
    return this.http.post<ResponseModel>(urlValue, returnCommonData).map(response => {
      return response;
    });
  }

  recalculateReturnOrderItemsWithBenefit(returnCommonData:any) {
    const urlValue = this.url + "/RecalculateReturnOrderItemsWithBenefit";
    return this.http.post<ResponseModel>(urlValue, returnCommonData).map(response => {
      return response;
    });
  }
}
