import { Component, OnInit, Input } from "@angular/core";
import { IOperationSummary } from "../../models/operation-summary/operation-summary-interface";
import { FormProcessMode } from "../../models/enums/form-process-mode.enum";
import { SharedTableResult } from "../../models/shared-table/shared-table-result.interface";
import { ITableProperties } from "../../models/shared-table/table-properties.interface";
import { ConstantConfigurations } from "../../models/constants/constant-configuration";
import { SessionDataProvider } from "../../../core/session-data-provider.service";
import { ConstantMessages } from "../../models/constants/constant-message";
import { CalculationService } from "../../services/calculations.service";
import { TranslateService } from "@ngx-translate/core";
import { SalesTransactionTypes } from "../../models/enums/sales-transaction-types";
import { OrderTypes } from "../../models/enums/order-types.enum";
import { ItemPackModel } from "../../models/Item/item.model";
import { TotalsModel } from "../../models/Item/totals.model";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-operation-summary",
  templateUrl: "./operation-summary.component.html",
  styleUrls: ["./operation-summary.component.css"],
})
export class OperationSummaryComponent implements OnInit {
  @Input("summaryData") summaryData: IOperationSummary;
  @Input() isQualityControlOffload = false;
  @Input('isCustomerTransactions') isCustomerTransactions: false;
  totals: TotalsModel = new TotalsModel();
  showTax = false;
  showRetailTax = false;
  isCustomerOperation = false;
  isVehicleOperation = false;
  isWarehouseOperation = false;
  isTransferOutOperation = false;
  isTransferIn = false;
  isInputVoucher = false;
  isOutputVoucher = false;
  isLoadRequest = false;
  isOffLoadRequest = false;
  isOrder = false;
  isInvoiceOrder = false;
  isRejectedOrder = false;
  isSales = false;
  isPurchaseOrder = false;
  isSSCC = false;
  isConsignmentOrder = false;
  isReturn = false;
  linkInputVoucherToVendor = false;
  allowSelectWarehouseInPO = false;
  LimitBOSalesToWarehouseStock = false;
  RestoreBOReturnToWarehouse = false;
  isROIScreen = false;
  isReceiveHelloJordan = false;
  isSerialReassignment = false;
  isPayment = false;
  isExchange = false;
  isReturnExchange = false;
  itemsDataSource: SharedTableResult = {
    totalItems: 0,
    data: [],
  };
  itemsDataSourceReturn: SharedTableResult = {
    totalItems: 0,
    data: [],
  };

  navLinks: any[];
  exchangeDataForm: FormGroup = new FormGroup({});
  navBarSelectedIndex: number = 0;
  TabelProperties: ITableProperties = {
    pageSize: 50,
    showPaginator: true,
    showSearch: false,
    isOnline: false,
    rowOperations: [],
    showEditButton: false,
    multiSelectionOperations: [],
    columns: [
      { title: "Desc_Item_Name", key: "itemName", isSortable: false },
      { title: "Desc_Item_Code", key: "itemCode", isSortable: false },
      { title: "Desc_UOM", key: "uom", isSortable: false },
    ],
  };
  TablePaymentProperties: ITableProperties = {
    pageSize: 50,
    showPaginator: true,
    showSearch: false,
    isOnline: false,
    rowOperations: [],
    showEditButton: false,
    multiSelectionOperations: [],
    columns: [
      { title: "Desc_invoice_number", key: "invoiceNo", isSortable: true },
      { title: "Desc_invoice_date", key: "transactionDate", isSortable: true, isDate: true },
      { title: "Desc_Payment_Type", key: "paymentType", isSortable: true },
      { title: "Desc_Cheque_No", key: "chequeNo", isSortable: false },
      { title: "Desc_Cheque_Date", key: "chequeDate", isSortable: true, isExpiryDate: true },
      { title: "Desc_Bank", key: "bankName", isSortable: true },
      { title: "Desc_Branch", key: "branchName", isSortable: true },
      { title: "Desc_Paid", key: "paidAmount", isSortable: false, numberOfDigits: true },
      { title: "Desc_Remaining", key: "remainingAmount", isSortable: false, numberOfDigits: true },
    ],
  };
  isFOCMode = false;
  itemsCount = 0;
  returnItemsCount = 0;
  restrictReturnFromInvoices: boolean = false;
  showTaxInWHOperations: boolean = false;
  constructor(
    public sessionData: SessionDataProvider,
    private calculationService: CalculationService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.showTaxInWHOperations = (
      (this.summaryData.formProcessMode === FormProcessMode.InputVoucher || this.summaryData.formProcessMode === FormProcessMode.OutputVoucher ||
      this.summaryData.formProcessMode === FormProcessMode.TransferIn || this.summaryData.formProcessMode === FormProcessMode.TransferOut ||
      this.summaryData.formProcessMode === FormProcessMode.LoadRequest || this.summaryData.formProcessMode === FormProcessMode.OffLoad) 
      && this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTaxInWarehouseOperations).toLowerCase() === "true" && 
      this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTax).toLowerCase() === "true");
      
    this.linkInputVoucherToVendor =
      this.sessionData
        .getConfigurationValue(ConstantConfigurations.LinkInputVoucherToVendor)
        .toLowerCase() === "true" &&
      this.summaryData.formProcessMode === FormProcessMode.InputVoucher;
    this.manageUI();
    this.initializeItemsDataSource();
    this.itemsCount = this.calculationService.fillItemsCount(this.itemsDataSource.data);
    this.totals.itemsQtyTotal = this.calculationService.fillItemsCount(this.itemsDataSource.data);
    var itemsQtyTotal = 0;
    let value = 0;
    if (!this.isPayment) {
      this.itemsDataSource.data.forEach((i) => {
        itemsQtyTotal = itemsQtyTotal + i.requiredQty;
        value += i.price * i.requiredQty;
      });
      this.totals.itemsQtyTotal = itemsQtyTotal;
    }
    this.returnItemsCount = this.calculationService.fillItemsCount(this.itemsDataSourceReturn.data);
    this.totals.returnItemQtyTotal = this.calculationService.fillItemsCount(this.itemsDataSourceReturn.data);
    var returnItemQtyTotal = 0;
    let returnValue = 0;
    if (!this.isPayment) {
      this.itemsDataSourceReturn.data.forEach((i) => {
        returnItemQtyTotal = returnItemQtyTotal + i.requiredQty;
        returnValue += i.price * i.requiredQty;
      });
      this.totals.returnItemQtyTotal = returnItemQtyTotal;
    }
    this.allowSelectWarehouseInPO =
      this.sessionData
        .getConfigurationValue(ConstantConfigurations.AllowSelectWarehouseInPO)
        .toLowerCase() === "true";
    this.LimitBOSalesToWarehouseStock =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.LimitBOSalesToWarehouseStock
        )
        .toLowerCase() === "true";
    this.restrictReturnFromInvoices =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.RestrictReturnFromInvoices
        )
        .toLowerCase() === "true";
    this.RestoreBOReturnToWarehouse = this.sessionData.getConfigurationValue(ConstantConfigurations.RestoreBOReturnToWarehouse).toLowerCase() === "true"
    this.isROIScreen =
      this.summaryData.formProcessMode === FormProcessMode.DamagedStock ||
      this.summaryData.formProcessMode ===
      FormProcessMode.ManufacturingDefects ||
      this.summaryData.formProcessMode === FormProcessMode.ROIIncomming ||
      this.summaryData.formProcessMode === FormProcessMode.ROIIncommingReturns;


    this.isFOCMode =
      this.sessionData.getConfigurationValue(ConstantConfigurations.AllowFOC).toLocaleLowerCase() === "true"
      && this.sessionData.getConfigurationValue(ConstantConfigurations.CheckFOCLimit).toLocaleLowerCase() === "false"
      && this.summaryData.formProcessMode === FormProcessMode.Orders;

    if (this.summaryData.operationData.orderTypeId === OrderTypes.Return.valueOf() &&
      this.restrictReturnFromInvoices) {
      this.summaryData.operationData.allowBatchesInItems = true;
    }
    if (this.isExchange) {
      this.prepareNavigation();
      this.navLinks[0].isActive = true;
    }
    if (this.isReturnExchange) {
      this.itemsCount = this.calculationService.fillItemsCount(this.itemsDataSourceReturn.data);
      this.totals.itemsQtyTotal = this.calculationService.fillItemsCount(this.itemsDataSourceReturn.data);
      var itemsQtyTotal = 0;
      let value = 0;
      this.itemsDataSourceReturn.data.forEach((i) => {
        itemsQtyTotal = itemsQtyTotal + i.requiredQty;
        value += i.price * i.requiredQty;
      });
      this.totals.itemsQtyTotal = itemsQtyTotal;
    }
    if (this.isWarehouseOperation || this.isVehicleOperation) {
      var netTotal = 0;
      var taxTotal = 0;
      this.itemsDataSource.data.forEach((i) => {
        i.netTotal = (i.price * i.requiredQty) * (1 + (i.tax / 100));
        netTotal += i.netTotal;
        taxTotal += i.calculatedTax;
      });
      this.summaryData.operationData.netTotal = netTotal;
      this.summaryData.operationData.tax = taxTotal;
    }
  }
  manageUI() {
    switch (this.summaryData.formProcessMode) {
      case FormProcessMode.ConsignmentOrder:
        this.isConsignmentOrder = true;
        this.isCustomerOperation = true;
        break;
      case FormProcessMode.Orders:
        this.isOrder = true;
        this.isCustomerOperation = true;
        break;
      case FormProcessMode.InvoiceOrder:
        this.isOrder = true;
        this.isCustomerOperation = true;
        this.isInvoiceOrder = true;
        break;
      case FormProcessMode.RejectedOrder:
        this.isOrder = true;
        this.isRejectedOrder = true;
        this.isCustomerOperation = true;
        break;
      case FormProcessMode.Sales:
        this.isSales = true;
        this.isCustomerOperation = true;
        break;
      case FormProcessMode.LoadRequest:
        this.isLoadRequest = true;
        this.isVehicleOperation = true;
        break;
      case FormProcessMode.OffLoad:
        this.isOffLoadRequest = true;
        this.isVehicleOperation = true;
        break;
      case FormProcessMode.InputVoucher:
        this.isInputVoucher = true;
        this.isWarehouseOperation = true;
        break;
      case FormProcessMode.OutputVoucher:
        this.isOutputVoucher = true;
        this.isWarehouseOperation = true;
        break;
      case FormProcessMode.TransferIn:
        this.isWarehouseOperation = true;
        this.isTransferIn = true;
        break;
      case FormProcessMode.TransferOut:
      case FormProcessMode.MoveOrder:
        this.isTransferOutOperation = true;
        this.isWarehouseOperation = true;
        break;
      case FormProcessMode.PurchaseOrder:
        this.isWarehouseOperation = false;
        this.isPurchaseOrder = true;
        break;
      case FormProcessMode.SSCCFromMainList:
        this.isSSCC = true;
        this.isWarehouseOperation = true;
        break;
      case FormProcessMode.DamagedStock:
      case FormProcessMode.ManufacturingDefects:
      case FormProcessMode.ROIIncomming:
      case FormProcessMode.ROIIncommingReturns:
        this.isROIScreen = true;
        break;
      case FormProcessMode.QualityControl:
        if (this.isQualityControlOffload) {
          this.isOffLoadRequest = true;
          this.isVehicleOperation = true;
        } else {
          this.isTransferOutOperation = true;
          this.isWarehouseOperation = true;
        }
        break;

      case FormProcessMode.SerialReassignment:
        this.isSerialReassignment = true;
        break;
      case FormProcessMode.ReceiveHelloJordan:
      case FormProcessMode.ResendHelloJordan:
        this.isReceiveHelloJordan = true;
        this.isVehicleOperation = true;
        break;
      case FormProcessMode.Payment:
        this.isPayment = true;
        break;
      case FormProcessMode.ExchangeSale:
        this.isExchange = true;
        this.isCustomerOperation = true;
        break;
      case FormProcessMode.Return:
        this.isReturn = true;
        this.isCustomerOperation = true;
        break;
    }
    if (this.isSerialReassignment) {
      this.TabelProperties.columns.push({
        title: "Desc_Serial_No",
        key: "fromSerial",
        isSortable: false,
      });
    }
    if (this.isWarehouseOperation || this.isVehicleOperation || this.isROIScreen) {
      this.summaryData.operationData.addedItems.forEach((i) => {
        i.priceString = this.calculationService
          .getNumberOnDigitFormat(i.price)
          .toString();
      });
      this.TabelProperties.columns.push({
        title: "Desc_Price",
        key: "priceString",
        isSortable: false,
      });
    }
    if (this.isCustomerOperation) {
      if (
        this.summaryData.operationData.isTaxeableOutlet &&
        this.sessionData
          .getConfigurationValue(ConstantConfigurations.AllowTax)
          .toLowerCase() === "true"
      ) {
        this.showTax = true;
        if (
          this.sessionData
            .getConfigurationValue(ConstantConfigurations.AllowRetailTaxOnItems)
            .toLowerCase() === "true"
        ) {
          this.showRetailTax = true;
        }
      }
      if (this.isConsignmentOrder) {
        // set price string according to sales type
        // calculate items total for order
        this.summaryData.operationData.itemsList.forEach((i) => {
          i.priceString = this.calculationService
            .getNumberOnDigitFormat(i.price)
            .toString();
        });
        this.TabelProperties.columns.push({
          title: "Desc_Price",
          key: "priceString",
          isSortable: false,
        });
        this.TabelProperties.columns.push({
          title: "Desc_Discount",
          key: "discount",
          isSortable: false,
          numberOfDigits: true,
        });

        if (this.showTax) {
          this.TabelProperties.columns.push({
            title: "Desc_Tax",
            key: "tax",
            isSortable: false,
            numberOfDigits: true,
          });
        }
        if (this.showRetailTax) {
          this.TabelProperties.columns.push({
            title: "Desc_Retail_Tax",
            key: "retailTax",
            isSortable: false,
            numberOfDigits: true,
          });
        }
      } else {
        // set price string according to sales type
        // calculate items total for order
        if (!this.isPayment) {
          this.summaryData.operationData.allItems.forEach((i) => {
            if (i.salesTransactionTypeId === SalesTransactionTypes.Sales ||
              (this.summaryData.operationData.orderTypeId === OrderTypes.Return.valueOf()
                && i.salesTransactionTypeId === SalesTransactionTypes.None)
            ) {
              i.priceString = this.calculationService
                .getNumberOnDigitFormat(i.price)
                .toString();
              //if (this.isOrder) {
              //  this.calculationService.calculatePackTotal(i);
              //}
            } else if (
              i.salesTransactionTypeId === SalesTransactionTypes.Promotion
            ) {
              i.priceString = this.translateService.instant(
                ConstantMessages.CaptionPromo
              );
            } else if (i.salesTransactionTypeId === SalesTransactionTypes.FOC && !this.isCustomerTransactions) {
              i.priceString = this.translateService.instant("Desc_FOC");
            } else {
              i.priceString = this.calculationService.getNumberOnDigitFormat(i.price).toString();
            }
          });
        }
        if (this.isInvoiceOrder) {
          this.TabelProperties.columns.push({
            title: "Desc_Order_Qty",
            key: "orderedQuantity",
            isSortable: false,
            numberOfStockDigits: true,
          });
          this.TabelProperties.columns.push({
            title: "Desc_Delivered_QTY",
            key: "requiredQty",
            isSortable: false,
            numberOfStockDigits: true,
          });
        } else {
          if (!this.isConsignmentOrder && !this.isSerialReassignment) {
            this.TabelProperties.columns.push({
              title: "Desc_Quantity",
              key: "requiredQty",
              isSortable: false,
              numberOfStockDigits: true,
            });
          }
        }
        if (this.isReturn || this.summaryData.operationData.allowBatchesInItems) {
          this.TabelProperties.columns.push({
            title: "Desc_BatchNo",
            key: "batchNo",
            isSortable: false,
          });
          this.TabelProperties.columns.push({
            title: "Desc_ExpiryDate",
            key: "expiryDate",
            isSortable: false,
            isExpiryDate: true,
          });
        }
        if (this.isReturn || (this.summaryData && this.summaryData.operationData && this.summaryData.operationData.showPackStatus)) {
          this.TabelProperties.columns.push({
            title: "Desc_Pack_Status",
            key: "packStatus",
            isSortable: false,
          });
        }
        this.TabelProperties.columns.push({
          title: "Desc_Price",
          key: "priceString",
          isSortable: false,
        });
        this.TabelProperties.columns.push({
          title: "Desc_Discount",
          key: "calculatedDiscountTotal",
          isSortable: false,
          numberOfDigits: true,
        });
        if (this.showTax) {
          this.TabelProperties.columns.push({
            title: "Desc_Tax",
            key: "calculatedTax",
            isSortable: false,
            numberOfDigits: true,
          });
          if (this.showRetailTax) {
            this.TabelProperties.columns.push({
              title: "Desc_Retail_Tax",
              key: "calculatedRetailTax",
              isSortable: false,
              numberOfDigits: true,
            });
          }
        }
        this.TabelProperties.columns.push({
          title: "Desc_Total",
          key: "netTotal",
          isSortable: false,
          numberOfDigits: true,
        });
      }
    } else {
      if (!this.isReceiveHelloJordan) {
        if (!this.isSerialReassignment) {
          this.TabelProperties.columns.push({
            title: "Desc_Quantity",
            key: "requiredQty",
            isSortable: false,
            numberOfStockDigits: true,
          });
        }

      } else {
        this.TabelProperties.columns.push({
          title: "Desc_Serial_No",
          key: "serial",
          isSortable: false
        });
      }
      if (this.isSSCC || this.summaryData.operationData.allowBatchesInItems) {
        this.TabelProperties.columns.push({
          title: "Desc_BatchNo",
          key: "batchNo",
          isSortable: false,
        });
        this.TabelProperties.columns.push({
          title: "Desc_ExpiryDate",
          key: "expiryDate",
          isSortable: false,
          isExpiryDate: true,
        });
      }
    }
    if((this.isROIScreen && this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTax).toLowerCase() === "true") ||
      this.showTaxInWHOperations) {
      this.TabelProperties.columns.push({
        title: "Desc_Tax",
        key: "calculatedTax",
        isSortable: false,
        numberOfDigits: true,
      });
    }
    if (this.isWarehouseOperation || this.isVehicleOperation || this.isROIScreen) {
      this.TabelProperties.columns.push({
        title: "Desc_Total",
        key: "netTotal",
        isSortable: false,
        numberOfDigits: true,
      });
    }
  }
  isDivisionLevel() {
    if (this.isCustomerOperation) {
      return (
        this.sessionData
          .getConfigurationValue(
            ConstantConfigurations.ControlTransactionOnDivisionLevel
          )
          .toLowerCase() === "true"
      );
    } else {
      return (
        this.sessionData
          .getConfigurationValue(
            ConstantConfigurations.ControlTransactionOnDivisionLevel
          )
          .toLowerCase() === "true" &&
        this.sessionData
          .getConfigurationValue(
            ConstantConfigurations.ShowDivisionSelectionOnWHTransaction
          )
          .toLowerCase() === "true"
      );
    }
  }
  initializeItemsDataSource() {
    if (this.isCustomerOperation) {
      if (this.isConsignmentOrder) {
        this.itemsDataSource = {
          totalItems: this.summaryData.operationData.itemsList.length,
          data: this.summaryData.operationData.itemsList,
        };
      } else {
        this.itemsDataSource = {
          totalItems: this.summaryData.operationData.allItems.length,
          data: this.summaryData.operationData.allItems,
        };
      }
      if (this.isExchange) {
        var salsList = this.summaryData.operationData.allItems.filter(x => x.salesTransactionTypeId != SalesTransactionTypes.None && x.salesTransactionTypeId != SalesTransactionTypes.FOCReturn);
        this.itemsDataSource = {
          totalItems: salsList.length,
          data: salsList,
        };
        this.itemsDataSourceReturn = {
          totalItems: this.summaryData.operationData.returnItems.length,
          data: this.summaryData.operationData.returnItems,
        };
      }
    } else if (this.isPurchaseOrder) {
      this.itemsDataSource = {
        totalItems: this.summaryData.operationData.items.length,
        data: this.summaryData.operationData.items,
      };
    } else if (this.isSSCC) {
      this.summaryData.operationData.details.forEach((item) => {
        if (item.hasSerialNumber) {
          item.batchNo = "";
          item.expiryDate = "";
        }
      });
      this.itemsDataSource = {
        totalItems: this.summaryData.operationData.details.length,
        data: this.summaryData.operationData.details,
      };
    } else if (this.isSerialReassignment) {
      this.itemsDataSource = {
        totalItems: this.summaryData.operationData.addedSerialCurrentStatus.length,
        data: this.summaryData.operationData.addedSerialCurrentStatus,
      };
    } else if (this.isPayment) {
      this.itemsDataSource = {
        totalItems: this.summaryData.operationData.length,
        data: this.summaryData.operationData,
      };
    }
    else {
      this.itemsDataSource = {
        totalItems: this.summaryData.operationData.addedItems.length,
        data: this.summaryData.operationData.addedItems,
      };
      //       this.summaryData.operationData.addedItems.forEach(item=>{
      // if(this.summaryData.operationData.recallId <= 0 && item.hasSerialNumber){
      //   item.batchNo='';
      //   item.expiryDate='';
      // };
      //       });
    }
  }
  ChangeDataView(index: number) {
    this.navLinks.forEach(a => a.isActive = false);
    this.navBarSelectedIndex = index;
    this.navLinks[index].isActive = true;
    if (index == 1) {
      this.isReturnExchange = true;
    }
    else {
      this.isReturnExchange = false;
    }
  }
  prepareNavigation() {
    this.navLinks = [
      {
        label: 'Desc_Sales',
        index: 0,
        isActive: false,
        hidden: false,
        disable: false
      },
      {
        label: 'Desc_Return',
        index: 1,
        isActive: false,
        hidden: false,
        disable: false
      },
    ]
  }
}
