import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreSession } from '../../../../core/core.session';
import { SharedTableResult } from '../../../../shared/models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../../shared/models/shared-table/table-properties.interface';

@Component({
  selector: 'app-comm-calc-imported-excel-errors-dialog',
  templateUrl: './comm-calc-imported-excel-errors-dialog.component.html',
  styleUrls: ['./comm-calc-imported-excel-errors-dialog.component.css']
})
export class CommCalcImportedExcelErrorsDialogComponent implements OnInit {

  @ViewChild('invalidCCExcelRowsDialog', { static: true }) invalidCCExcelRowsDialog: any;
  @Output('onCloseDialog') onCloseDialog = new EventEmitter();
  @Input() importedExcelErrors: any[] = [];;
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    isOnline: false,
    showSearch: false,
    showSearchBtn: false,
    showNewBtn: false,
    isMultiSelection: false,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      { title: "Desc_From", key: "fromDate", isSortable: true, width: "25%", isDate: true },
      { title: "Desc_To", key: "toDate", isSortable: true, width: "25%", isDate: true },
      { title: "Desc_KPIType", key: "kpiTypeDescription", isSortable: true, width: "25%" },
      { title: "Desc_KPISubType", key: "kpiSubTypeDescription", isSortable: true, width: "25%" },
      { title: "Desc_Description", key: "detailDescription", isSortable: true, width: "25%" },
      { title: "Desc_UOM", key: "packTypeName", isSortable: true, width: "25%" },
      { title: "Desc_Commission", key: "commissionName", isSortable: true, width: "25%" }
    ]
  };

  constructor(private coreSession: CoreSession,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.dataSource = {
      totalItems: this.importedExcelErrors.length,
      data: this.importedExcelErrors
    };
  }

  ngOnChanges(){
    if(this.invalidCCExcelRowsDialog){
      this.dataSource = null;
      this.dataSource = {
        totalItems: this.importedExcelErrors.length,
        data: this.importedExcelErrors
      };
    }
  }

  public showDialog() {
    this.dataSource = {
      data: this.importedExcelErrors,
      totalItems: this.importedExcelErrors.length
    }
    return this.modalService.open(this.invalidCCExcelRowsDialog, { centered: true, size: 'lg', backdrop: 'static' }).result.then(
      (result) => {
        return result;
      });

  }

  closeDialog() {
    this.onCloseDialog.emit();
  }


}
