import { Component, OnInit, Input, ViewChild, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreSession } from '../../../../core/core.session';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ImportService } from '../../../services/import.service';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invalid-serials',
  templateUrl: './invalid-serials.component.html',
  styleUrls: ['./invalid-serials.component.css']
})
export class InvalidSerialsComponent implements OnInit,OnChanges,OnDestroy {
@ViewChild('invalidSerialsDialog', { static: true }) invalidSerialsDialog: any;
@Input() invalidSerials: any[] = [];
@Output('onCloseDialog') onCloseDialog = new EventEmitter();
invalidSerialsDataSource: SharedTableResult = {
  totalItems: 0,
  data: []
};
tableData: ITableProperties = {
  pageSize: this.coreSession.pageSize,
  showPaginator: true,
  isOnline: false,
  showSearch: false,
  showSearchBtn: false,
  showNewBtn: false,
  isMultiSelection: false,

  rowOperations: [],
  multiSelectionOperations: [],
  columns: []
};
invalidSerialsCount=0;
@Input() showSerialsAsRange = true; // this flag is input and it always true except for recall operations it will false
  constructor(private config: NgbModalConfig,
    private modalService: NgbModal,
    private coreSession:CoreSession,
    private sessionData: SessionDataProvider,
    private importService : ImportService,
    private translateService : TranslateService) {

     }

  ngOnInit() {
    this.setTableProperties();
    this.invalidSerialsDataSource = {
      totalItems: this.invalidSerials.length,
      data: this.invalidSerials
    };
    this.fillInvalidSerialsCount();
    this.config.backdrop = true;
    this.config.keyboard = true;
  }
  setTableProperties(){
    if(!this.showSerialsAsRange){
      this.tableData.columns = [
        { title: 'Desc_Imported_Item', key: 'itemNameCode', isSortable: false, width: '20%' },
        { title: 'Desc_Serial_No', key: 'fromSerial', isSortable: false, width: '14%' },
        { title: 'Desc_Error_Caption', key: 'errorString', isSortable: false, width: '13%' },
        { title: 'Desc_Serial_Item', key: 'existingItem', isSortable: false, width: '20%' },
        { title: 'Desc_Status', key: 'serialStatusName', isSortable: false, width: '13%' },
        { title: 'Desc_Location', key: 'location', isSortable: false, width: '20%' }
      ];
    }else{
      this.tableData.columns = [
        { title: 'Desc_Imported_Item', key: 'itemNameCode', isSortable: false },
        { title: 'Desc_From', key: 'fromSerial', isSortable: false },
        { title: 'Desc_To', key: 'toSerial', isSortable: false },
        { title: 'Desc_Error_Caption', key: 'errorString', isSortable: false},
        { title: 'Desc_Serial_Item', key: 'existingItem', isSortable: false },
        { title: 'Desc_Status', key: 'serialStatusName', isSortable: false },
        { title: 'Desc_Location', key: 'location', isSortable: false }
      ];
    }
  }
  ngOnChanges(){
    if(this.invalidSerials){
      this.invalidSerialsDataSource = null;
      this.invalidSerialsDataSource = {
        totalItems: this.invalidSerials.length,
        data: this.invalidSerials
      };
      this.fillInvalidSerialsCount();
    }

  }
  ngOnDestroy() {
    this.config.backdrop = true;
    this.config.keyboard = true;

  }
  closeDialog(){
    this.onCloseDialog.emit();
  }
  public showDialog() {
    //this.coreSession.SetTitle('Invalid Serials');
    // this.invalidSerialsDataSource={
    //   data:this.invalidSerials,
    //   totalItems:this.invalidSerials.length
    // };
    return this.modalService.open(this.invalidSerialsDialog, { centered: true, size: 'xl',backdrop:'static' }).result.then(
      (result) => {
          return result;
      });
    
  }
  fillInvalidSerialsCount(){
    this.invalidSerialsCount=0;
    this.invalidSerials.forEach((serial) => {
      if(!serial.serialsCount || serial.serialsCount===0){
        serial.serialsCount=1;
      }
      this.invalidSerialsCount += serial.serialsCount;
    });
  }
  exportInvalidSerials(){
    var data : any = {
      invalidSerials : this.invalidSerials,
    }
    this.coreSession.ModalLoading.Show();
        this.importService.exportInvalidSerials(data).subscribe((response) => {
            this.coreSession.ModalLoading.Hide();
        }, (error) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
        });
  }
}
