import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy,
  NgZone,
  ElementRef,
} from "@angular/core";
import { ModalDialogComponent } from "../../shared/components/modal-dialog/modal-dialog.component";
import { Title } from "@angular/platform-browser";
import { CoreSession } from "../core.session";
import { AutoLogoutService } from "../../content/login/auto-logout.service";
import { SubscriptionLog } from "rxjs/internal/testing/SubscriptionLog";
import { Observable } from "rxjs-compat";
import { fromEvent } from "rxjs";
import { SharedDialogComponent } from "../../shared/components/shared-dialog/shared-dialog.component";
import { PrivacyPolicyService } from "../privacy-policy/privacy-policy.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ResponseModel } from "../../shared/models/api-models/api-models";
import { ConstantConfigurations } from "../../shared/models/constants/constant-configuration";
import { SessionDataProvider } from "../session-data-provider.service";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.css"],
  providers: [AutoLogoutService],
})
export class ContentComponent implements OnInit, OnDestroy {
  @ViewChild("dialogModal", { static: true }) dialogModal: ModalDialogComponent;
  @ViewChild("bodyContent", { static: true }) bodyContent: ElementRef;
  @ViewChild("privacyDialog", { static: true })
  privacyDialog: SharedDialogComponent;

  resizeObservable: Observable<Event>;
  constructor(
    private coreSession: CoreSession,
    private titleService: Title,
    private autoLogout: AutoLogoutService,
    private ngZone: NgZone,
    private privacyService: PrivacyPolicyService,
    private sessionDataProvider: SessionDataProvider
  ) { }

  @HostListener("document:keyup", ["$event"])
  @HostListener("document:click", ["$event"])
  @HostListener("document:wheel", ["$event"])
  resetTimer() {
    this.ngZone.runOutsideAngular(() => {
      this.autoLogout.resetLastAction();
    });
  }

  ngOnInit() {
    this.coreSession.ModalDialog = this.dialogModal;
    this.coreSession.TitleService = this.titleService;
    this.coreSession.SetTitle("Home");
    this.coreSession.contentHeight =
      this.bodyContent.nativeElement.offsetHeight;
    this.resizeObservable = fromEvent(window, "resize");
    this.resizeObservable.subscribe((evt) => {
      this.coreSession.contentHeight =
        this.bodyContent.nativeElement.offsetHeight;
    });
    if (
      !this.coreSession.isPrivacyLoaded &&
      this.sessionDataProvider.getConfigurationValue(
        ConstantConfigurations.ShowPrivacyPolicy
      )
    ) {
      this.privacyService
        .checkPrivacyPolicy(this.coreSession.CurrentOperator.employeeId)
        .subscribe(
          (result: ResponseModel) => {
            if (result.data) {
              this.coreSession.showPrivacypolicy = result.data.showPrivacy;
              this.coreSession.privacyText = result.data.privacyText;
              this.showPrivacyPolicy();
            }
          },
          (error: HttpErrorResponse) => { }
        );
    } else {
      this.coreSession.SetDelay(500).then((a) => {
        this.showPrivacyPolicy();
      });
    }
  }
  showPrivacyPolicy() {
    if (this.coreSession.showPrivacypolicy) {
      this.privacyDialog.Show(false).then((a) => {
        this.privacyService.setPrivacyAsRead().subscribe((aqq) => { });
      });
    }
  }
  ngOnDestroy() {
    this.autoLogout.stopInterval();
  }
  onDialogResult(event) {
    this.privacyDialog.Close();
  }
}
