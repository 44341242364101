export enum KPISubType {
  Items = 1,
  ItemGroups = 2,
  ItemCategory = 3,
  ItemBrand = 4,
  Division = 5,
  Invoices = 6,
  Dynamic = 7,
  ItemDistribution = 8,
  Pyramids = 9,
  ShelfShare = 10,
  NoOfInvoices = 11

}
