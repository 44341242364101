import { Pipe, PipeTransform } from '@angular/core';
import { SessionDataProvider } from '../../core/session-data-provider.service';

@Pipe({name: "formatTime"})
  export class FormatTimePipe implements PipeTransform {

    constructor(
        private sessionData: SessionDataProvider
      ) { }
    transform(value: number): string {
      const minutes: number = Math.floor(value / 60);
      return (
        ("00" + minutes).slice(-2) +
        ":" +
        ("00" + Math.floor(value - minutes * 60)).slice(-2)
      );
    }
  }