import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ResponseModel } from '../../models/api-models/api-models';
import { CoreSession } from '../../../core/core.session';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {
  private url = '';
  private httpOptions;
  constructor(private http: HttpClient,
    private coreSession: CoreSession,
    @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl + 'Commission/';

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }


  getCommissionList(filter) {
    const urlValue = this.url + 'GetCommissionList';
    return this.http.post<ResponseModel>(urlValue, filter).map(
      (response) => {
        return response;
      });
  }
  getCommissionData(commissionID) {
    const urlValue = this.url + 'GetCommissionData';
    return this.http.post<ResponseModel>(urlValue, commissionID).map(
      (response) => {
        return response;
      });
  }

  getCommissionModes() {
    const urlValue = this.url + 'GetCommissionModes';
    return this.http.post<ResponseModel>(urlValue, null).map(
      (response) => {
        return response;
      });
  }

  getCommissionTypes() {
    const urlValue = this.url + 'GetCommissionTypes';
    return this.http.post<ResponseModel>(urlValue, null).map(
      (response) => {
        return response;
      });
  }

  getCommissionAchievementTypes() {
    const urlValue = this.url + 'GetCommissionAchievementTypes';
    return this.http.post<ResponseModel>(urlValue, null).map(
      (response) => {
        return response;
      });
  }

  getRateTypes() {
    const urlValue = this.url + 'GetRateTypes';
    return this.http.post<ResponseModel>(urlValue, null).map(
      (response) => {
        return response;
      });
  }

  saveCommission(commissionObj, isEditMode) {
    const urlValue = this.url + (isEditMode ? 'UpdateCommission' : 'InsertCommission');
    return this.http.post<ResponseModel>(urlValue, commissionObj).map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  deleteCommission(commissionId) {
    const urlValue = this.url + 'DeleteCommission';
    return this.http.post<ResponseModel>(urlValue, commissionId).map(
      (response) => {
        return response;
      });
  }

  activateCommission(commissionId) {
    const urlValue = this.url + 'UpdateFromDeactiveToActivateCommission';
    return this.http.post<ResponseModel>(urlValue, commissionId).map(
      (response) => {
        return response;
      });
  }

  deactivateCommission(commissionId) {
    const urlValue = this.url + 'UpdateFromActivateToDeactivateCommission';
    return this.http.post<ResponseModel>(urlValue, commissionId).map(
      (response) => {
        return response;
      });
  }
}
