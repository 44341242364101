import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import * as jwt_decode from "jwt-decode";
import { CoreSession } from "../core.session";
import { ConstantStorage } from "../../shared/models/constants/constant-stroage";
import { ConstantURLs } from "../../shared/models/constants/constant-URL";
import { OperatorModel } from "../../shared/models/operator/operator.model";
import { SessionDataProvider } from "../session-data-provider.service";
import { NavBarService } from "../sidebar/sidebar.service";

@Injectable()
export class AuthGuard implements CanActivate {
  isMenuAvailable: Boolean;
  currentOperator: OperatorModel;
  splittedPath: string[];
  constructor(
    private router: Router,
    private coreSession: CoreSession,
    private sessionDataProvider: SessionDataProvider,
    private navService: NavBarService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.currentOperator = this.coreSession.CurrentOperator;

    this.checkIfMenuAvailable(state, next);

    if (
      !this.currentOperator ||
      !this.currentOperator.isLoggedIn ||
      this.isTokenExpired() ||
      !this.isMenuAvailable
    ) {
      if (this.isMenuAvailable) {
        localStorage.removeItem(ConstantStorage.TokenName);
        localStorage.removeItem(ConstantStorage.CurrentOperator);
        localStorage.removeItem(ConstantStorage.generalData);
        this.router.navigate(["/" + ConstantURLs.loginURL]);
      } else {
        this.router.navigate(["/"]);
      }
      return false;
    } else {
      return true;
    }
  }

  getToken(): string {
    return localStorage.getItem(ConstantStorage.TokenName);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) return null;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  canGoToRoute(user: string, id: string): boolean {
    return true;
  }

  checkIfMenuAvailable(
    state: RouterStateSnapshot,
    route: ActivatedRouteSnapshot
  ) {
    this.splittedPath = state.url.split("/");
    let originalMenuIndex = -1;
    if (
      this.splittedPath &&
      this.splittedPath.length >= 4 &&
      this.splittedPath[1].toLowerCase() === "roi" &&
      this.splittedPath[2].toLowerCase() === "stock" 
    ) {
      this.splittedPath = [
        "roi",
        "stock/" + this.splittedPath[3].toLowerCase(),
      ];
    }
    for (let menuPath of this.splittedPath) {
      if (menuPath !== "") {
        let menuIndex = this.sessionDataProvider.allMenusAvailable.findIndex(
          (x) => x.url === menuPath
        );
        if (menuIndex > -1) {
          originalMenuIndex = menuIndex;
        }
        if (
          menuPath.toLowerCase().trim().includes("home") ||
          menuIndex > -1 ||
          (this.sessionDataProvider.allMenusAvailable[originalMenuIndex] &&
            this.sessionDataProvider.allMenusAvailable[originalMenuIndex]
              .addedActionTypes &&
            this.sessionDataProvider.allMenusAvailable[
              originalMenuIndex
            ].addedActionTypes.find((x) => x.url === menuPath)) ||
            menuPath.includes(ConstantURLs.checkoutURL) ||
            menuPath.includes(ConstantURLs.checkInURL) ||
            menuPath === ConstantURLs.settlementURL ||
            menuPath.includes(ConstantURLs.assignBoxesURL) ||
            menuPath.includes(ConstantURLs.releaseBoxesURL)
        ) {
          this.navService.ActiveSidebar.next(this.splittedPath[1]);
          this.isMenuAvailable = true;
          if (
            menuPath.includes("edit") ||
            menuPath.includes(ConstantURLs.checkoutURL) ||
            menuPath.includes(ConstantURLs.checkInURL) ||
            menuPath === ConstantURLs.settlementURL ||
            menuPath.includes(ConstantURLs.assignBoxesURL) ||
            menuPath.includes(ConstantURLs.releaseBoxesURL)
          ) {
            return;
          }
        } else {
          this.isMenuAvailable = false;
          break;
        }
      }
    }
    return this.isMenuAvailable;
  }
}
