import { CoreSession } from './../../../../core/core.session';
import { Component, Injectable, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { ItemPackModel } from '../../../models/Item/item.model';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { CustomListFilter } from '../../../models/shared-table/custom-list.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ResponseModel } from '../../../models/api-models/api-models';
import { DialogMode } from '../../../models/enums/dialog-mode.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { RowOperation } from '../../../models/enums/shared-table-operation.enum';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { GeneralFilter } from '../../../models/general/generalFilter.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shared-items-list-filter',
  templateUrl: './shared-items-list-filter.component.html',
  styleUrls: ['./shared-items-list-filter.component.css'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class SharedItemsListFilterComponent {

  //#region [DECLARATIONS]

  @ViewChild('content', { static: true }) content: any;

  result: DialogResult = DialogResult.None;
  filterString = '';
  selectedPack: ItemPackModel = new ItemPackModel();

  // Item List Properties
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  TabelProperties: ITableProperties = {
    pageSize: 50,
    showPaginator: true,
    showSearch: true,
    isOnline: true,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      { title: 'Item Name', key: 'itemName', isSortable: true, width: '30%' },
      { title: 'Item Code', key: 'itemCode', isSortable: true, width: '30%' },
      { title: 'UOM', key: 'uom', isSortable: false, width: '20%' },
      { title: 'Division', key: 'divisionName', isSortable: false, width: '20%' }
    ]
  };

  @Input('GeneralFilter') generalFilter: GeneralFilter;

  //#endregion

  //#region [INITIALIZATION]

  constructor(private modalService: NgbModal,
    private itemService: ItemService,
    private coreSession: CoreSession,
    private translateService: TranslateService) {
  }
  ngOnInit() {
  }

  //#endregion

  //#region [METHODS]

  public Show() {
    this.generalFilter.customListFilter = {
      pageSize: this.TabelProperties.pageSize,
      searchFilter: '',
      page: 0,
    };
    const modalOpened = this.modalService.open(this.content, { centered: true, size: 'lg' });
    this.getItemsList();
    return modalOpened.result.then(
      (result) => {
        return result;
      }, (reason) => {
        return undefined;
      });
  }
  getItemsList() {
    // this.coreSession.ModalLoading.Show();
    this.itemService.getAllItems(this.generalFilter).subscribe(
      (response: ResponseModel) => {
        // this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data && response.data !== undefined) {
            this.dataSource = response.data;
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgNoItemsFound));
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      }, (error: HttpErrorResponse) => {
        // this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      });
  }

  //#endregion

  //#region [EVENTS]

  onSelectedItem(selectedPack: ItemPackModel) {
    this.selectedPack = selectedPack;
    this.modalService.dismissAll(this.selectedPack);
  }
  onTableFilterChanged(query: CustomListFilter) {
    this.generalFilter.customListFilter = query;
    this.getItemsList();
  }

  onRowClicked(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        break;
      case RowOperation.approve:
        break;
      case RowOperation.rowClick:
        this.content.close(event.object);
        break;

    }
  }
  //#endregion
}
