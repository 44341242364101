import { Component, OnInit } from '@angular/core';
import { ConstantURLs } from '../../../models/constants/constant-URL';

@Component({
  selector: 'app-web-form-list-roi',
  templateUrl: './web-form-list-roi.component.html',
  styleUrls: ['./web-form-list-roi.component.css']
})
export class WebFormListRoiComponent implements OnInit {
  mainUrl = ConstantURLs.roiMainURL;
  mainName = 'Desc_ROI';
  constructor() { }

  ngOnInit() {
  }

}
