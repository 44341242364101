import { ActionTypesModel } from './action-types.model';

export class MenuModel {
    menuId: string;
    parentMenuId: string;
    parentMenus: string;
    isParentMenu: boolean;
    isFavorite: boolean;
    hide: boolean = false;
    hideChild: boolean = false;
    url: string;
    icon: string;
    menuName: string;
    childMenus: MenuModel[];
    addUrl: string;
    editUrl: string;
    isAvailable: boolean = false;
    isHidden: boolean = false;
    actionTypes: ActionTypesModel[];
    addedActionTypes: ActionTypesModel[];
    isWebMenu: boolean = false;
    isSelectAllChecked: boolean = false;
    fullName: string = '';
    fullPath: string = '';
    parentMenuIdsList: string[];
    reportId: number = -1;
    isWithInReportHierarchy: boolean = false;
    gridReportURL : string = '';
    isReportFavorite: boolean;
}
