import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreSession } from '../../../../core/core.session';
import { ItemPackModel } from '../../../models/Item/item.model';
import { ItemService } from '../../../services/data-definition/product/item.service';
import * as cloneDeep from "lodash/cloneDeep";
import { ConstantMessages } from '../../../models/constants/constant-message';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { OrderStatus } from '../../../models/enums/order-status.enum';
import { SerialsPropertiesInterface } from '../../Serials/serials-properties.interface';
import { SerialStatus } from '../../../models/enums/SerialStatus';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-serialized-promoted-items',
  templateUrl: './serialized-promoted-items.component.html',
  styleUrls: ['./serialized-promoted-items.component.scss']
})
export class SerializedPromotedItemsComponent implements OnInit {
  @Input('EditedTransactionId') editedTransactionId = null;
  @Input('StockWarehouseId') stockWarehouseId = -1;
  @Input('DetailsWarehouseId') detailsWarehouseId = -1;
  @Input('CustomerId') customerId = -1;
  @Input('OutletId') outletId = -1;
  @Input('orderSignature') orderSignature = '';
  @Input('order') order:any;
  @Input('AddedItems') promoItems: ItemPackModel[] = [];
  @Input('ShowTax') showTax = false;
  @Input('ShowRetailTax') ShowRetailTax = false;
  @Input('ItemProcessMode') formProcessMode: FormProcessMode = FormProcessMode.Orders;
  @Input('readingId') readingId: number = -1;
@Input('addedSerialsForAllPacks') addedSerialsForAllPacks=[];

@Output('continueSaving') continueSaving = new EventEmitter();

saveSerialsSubject: Subject<void> = new Subject<void>();
  //promoItems:ItemPackModel[];
  selectedItemPackObject: any = {};
  showSerialsSlider=false;
  warehouseId: number = -1;
  vehicleId: number = -1;
  requiredQuantity: number = -1;
  quantityMustMatch: boolean = false;
  serialsProperties: SerialsPropertiesInterface;
  allAddedPackObjects: any[];
  itemImagePath = document.getElementsByTagName("base")[0].href +
    "Resources/items-img/";
  itemNoProductImagePath = document.getElementsByTagName("base")[0].href +
    "Resources/";
  constructor(public coreSession:CoreSession
    ,private itemService:ItemService
    ,private translateService: TranslateService) { }

  ngOnInit() {
    this.itemImagePath =
      document.getElementsByTagName("base")[0].href +
      "Resources/items-img/";
      this.promoItems.forEach(pack=>{
        if (pack.hasImage) {
        pack.imagePath = this.itemImagePath + pack.itemId + '.png';
      }
      else {
        pack.imagePath = this.itemNoProductImagePath + 'noProduct.jpg';
      }});

  }
  openSliderToAddSerials(selectedPack: ItemPackModel) {
    //   this.addedSerialsForAllPacks=this.addedSerialsForAllPacks.filter(serial=> serial.itemId !== selectedPack.itemId && serial.packId!==selectedPack.packId)

    // if(!this.addedSerialsForAllPacks)this.addedSerialsForAllPacks=[];
    if (selectedPack.packHierarchyFilled) {
      this.selectedItemPackObject = cloneDeep(selectedPack);
      this.fillPackTemplateSerialIndex(selectedPack);

    } else {

      if (selectedPack.hasSerialNumber && selectedPack.piecesInPack > 1) {
        this.coreSession.ModalLoading.Show();
        this.itemService.getPacksHierarchy(selectedPack, this.customerId, this.outletId).subscribe(
          (response) => {
            this.coreSession.ModalLoading.Hide();
            if (response.status != null && response.status >= 0) {
              selectedPack.childPack = response.data.childPack;
              selectedPack.childPackPrice = response.data.childPackPrice;
              selectedPack.packQuantityInLevels = response.data.packQuantityInLevels;
              selectedPack.packHierarchyFilled = true;
              this.selectedItemPackObject = cloneDeep(selectedPack);
              this.fillPackTemplateSerialIndex(selectedPack);
              // this.fillSerialProperties();
              //      this.showSerialsSlider=true;

            } else {
              this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
            }
          }, (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
          }
        );
      } else {
        selectedPack.packQuantityInLevels = 1;
        this.selectedItemPackObject = cloneDeep(selectedPack);
        this.fillPackTemplateSerialIndex(selectedPack);
        // this.fillSerialProperties();
        //   this.showSerialsSlider=true;
      }
    }
  }
  fillPackTemplateSerialIndex(selectedPack: ItemPackModel) {
    if (selectedPack.templateId && selectedPack.templateId > 0) {
      this.coreSession.ModalLoading.Show();
      this.itemService.getFieldsStartAndEndIndexTemplate(selectedPack).subscribe(
        (response) => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.selectedItemPackObject.productionIncludedInTemplate = response.data.productionIncludedInTemplate;
            this.selectedItemPackObject.totalFieldsLengthOfItemTemplate = response.data.totalFieldsLengthOfItemTemplate;
            this.selectedItemPackObject.templateSerialsIndecies = response.data.templateSerialsIndecies;
            this.selectedItemPackObject.templateBatchNoIndecies = response.data.templateBatchNoIndecies;
            this.selectedItemPackObject.templateExpiryDateIndecies = response.data.templateExpiryDateIndecies;
            this.selectedItemPackObject.templateGTINIndecies = response.data.templateGTINIndecies;
            this.selectedItemPackObject.templateProductionDateIndecies = response.data.templateProductionDateIndecies;
            this.checkIfBatchAndExpiryInItemTemplate();
            this.fillSerialProperties();
            this.showSerialsSlider = true;
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
          }
        }, (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
        }
      );
    } else {
      this.fillSerialProperties();
      this.showSerialsSlider = true;
    }
  }
  checkIfBatchAndExpiryInItemTemplate() {
    if (this.selectedItemPackObject.templateId > 0 && this.selectedItemPackObject.templateBatchNoIndecies && this.selectedItemPackObject.templateBatchNoIndecies.length === 2) {
      this.selectedItemPackObject.batchIncludedInTemplate = true;
    }
    if (this.selectedItemPackObject.templateId > 0 && this.selectedItemPackObject.templateExpiryDateIndecies && this.selectedItemPackObject.templateExpiryDateIndecies.length === 2) {
      this.selectedItemPackObject.expiryIncludedInTemplate = true;
    }
  }
  fillSerialProperties() {
    this.warehouseId = this.detailsWarehouseId;
    this.vehicleId = -1;
    let transactionId=this.editedTransactionId;

      this.requiredQuantity = this.selectedItemPackObject.requiredQty;
      this.quantityMustMatch = true;

    if(this.isPartialDelivery() && this.order.orderStatus!=OrderStatus.Delivered){
      if(this.order.warehouseTransactionId && this.order.warehouseTransactionId.length>0){
        this.warehouseId=this.order.referenceWarehouseId;
        this.vehicleId=this.order.warehouseId;
        transactionId=this.order.warehouseTransactionId;
      }


    }

    this.serialsProperties = {
      itemFormProcessMode: this.formProcessMode,
      packObj: this.selectedItemPackObject,
      packObjects: this.allAddedPackObjects,
      warehouseId: this.warehouseId,
      vehicleId: this.vehicleId,
      transactionId: transactionId,
      newSerialStatus: SerialStatus.SoldToCustomer,
        requiredQuantity: this.requiredQuantity,
        quantityMustMatch: this.quantityMustMatch,
        customerId: this.customerId,
        outletId: this.outletId,
        transactionReadingId: this.readingId,
        ssccBarcode:''
    };

  }
  onAfterSaveSerials(savedPack, isFromAddItemBySerial) {
    var originalPack;

     originalPack = this.promoItems.find(x => x.itemId === savedPack.itemId && x.packId === savedPack.packId && x.salesTransactionTypeId===savedPack.salesTransactionTypeId);

    if (originalPack) {
      this.addedSerialsForAllPacks = this.addedSerialsForAllPacks.filter(serial => serial.itemId !== savedPack.itemId && serial.packId !== savedPack.packId && serial.salesTransactionTypeId !=savedPack.salesTransactionTypeId);

      if (!this.addedSerialsForAllPacks) this.addedSerialsForAllPacks = [];

      if (savedPack.addedSerials && savedPack.addedSerials.length > 0) {
        savedPack.addedSerials.forEach(serial => {
          this.addedSerialsForAllPacks.push(serial);
        });
      }
      originalPack.addedSerials = savedPack.addedSerials;
      originalPack.requiredQty = savedPack.requiredQty;
      originalPack.expiryDateModel.date = this.coreSession.DefaultBatchNumber;
      originalPack.expiryDateModel.day = this.coreSession.DefaultExpiryDate().getDay();
      originalPack.expiryDateModel.month = this.coreSession.DefaultExpiryDate().getMonth() + 1;
      originalPack.expiryDateModel.year = this.coreSession.DefaultExpiryDate().getFullYear();
      originalPack.batchNo = this.coreSession.DefaultBatchNumber;
    } else {
      return;
    }
    if(!this.allAddedPackObjects){
      this.allAddedPackObjects = [];
    }
    if(!isFromAddItemBySerial){
     this.fillAddedSerialsInAllAddedPackObjects(savedPack);

    }

    this.showSerialsSlider = false;
  }
  fillAddedSerialsInAllAddedPackObjects(savedPack){
    let index =-1;
    if(!this.allAddedPackObjects)this.allAddedPackObjects=[];
     index = this.allAddedPackObjects.findIndex(x => x.packId === savedPack.packId && x.salesTransactionTypeId===savedPack.salesTransactionTypeId);

    if(index >= 0){
      let savedAddedSerialsList = [];
      savedAddedSerialsList = savedPack.addedSerials;
      savedPack.addedSerials.forEach(addedSavedPackSerials => {
        if(!this.allAddedPackObjects[index].addedSerials)this.allAddedPackObjects[index].addedSerials=[];
        let addedSerialsIndex = this.allAddedPackObjects[index].addedSerials.findIndex(x => x.fromSerial === addedSavedPackSerials.fromSerial);
        if(addedSerialsIndex < 0){
          this.allAddedPackObjects[index].addedSerials.push(addedSavedPackSerials);
        }
      });
    }else{
      this.allAddedPackObjects.push(savedPack);
    }
  }
  onContinueClicked(){
    if (!this.checkIfSerialsAdded()) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.Desc_Serials_Count_Not_Match_Req_Qnty));
      return;
    }
    this.continueSaving.emit();
  }
  checkIfSerialsAdded() {

    let allAdded = true;
    let serialsCount = 0;

    if (this.promoItems && this.promoItems.length > 0) {
      this.promoItems.forEach(item => {
        if (allAdded) {
          serialsCount = 0;
          if (item.addedSerials) {

              serialsCount = item.addedSerials.length;

          }
          if (!(allAdded && item.requiredQty === serialsCount)) {
            allAdded = false;
          }
        }
      });
    }
    return allAdded;
  }
  onSaveSerialsClick() {
    this.saveSerialsSubject.next();
  }
  closeSerialsSlider() {
    this.showSerialsSlider = false;
  }
  isDelivery() {
    return this.formProcessMode === FormProcessMode.Delivery;
  }
  isPartialDelivery() {
    return this.formProcessMode === FormProcessMode.PartialDelivery;
  }
}
