import { Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-transaction-type-filter',
  templateUrl: './transaction-type-filter.component.html',
  styleUrls: ['./transaction-type-filter.component.css']
})
export class TransactionTypeFilterComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() transactionTypes:[];
  @Output() ontransactionTypeSelected = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }
  onChangeTransactionType(selectedTransactionType) {
    this.ontransactionTypeSelected.emit(selectedTransactionType);
  }
}
