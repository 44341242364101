import { CoreSession } from "../../../core/core.session";
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { ResponseModel } from "../../../shared/models/api-models/api-models";
import { GeneralFilter } from "../../../shared/models/general/generalFilter.model";
import { ItemPackModel } from "../../../shared/models/Item/item.model";
import { SalesRestrictionFilters } from "../../../shared/models/salesRestriction/sales-restriction-filter.model";
import { SalesRestrictionModel } from "../../../shared/models/salesRestriction/sales-restriction.model";

@Injectable({
  providedIn: "root"
})
export class EmployeeItemListService {

  minimizedItemSidebar = new Subject<boolean>();

  private url = "";
  private httpOptions;
  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.url = baseUrl + "EmployeeItemList";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token"
      })
    };
  }



  getItemsLists(filter: any, itemListType: number) {
    const urlValue: string = this.url + '/getItemsLists';
    let item = {
      filter: filter,
      itemListTypeId: itemListType
    }
    return this.http.post<ResponseModel>(urlValue, item).pipe(map(
      response => {
        return response;
      }));
  }


  deleteItemList(itemListId: any, itemListType: number, oosListMode: boolean) {
    const urlValue = this.url + "/DeleteItemList"
    //?itemListId=" + itemListId +"&ItemListType=" + itemListType+"&oosListMode="+ oosListMode;
    const itemsListFilter = {
      itemListType,
      itemListId,
      oosListMode
    }
    return this.http.post<ResponseModel>(urlValue, JSON.stringify(itemsListFilter), this.httpOptions).pipe(
      map(response => {
        return response;
      })
    );
  }
  getItemsListData(itemListId: number, itemListType: number, oosListMode: boolean) {
    const urlValue: string = this.url + "/getItemsListData?itemListId=" + itemListId + "&ItemListType=" + itemListType + "&oosListMode=" + oosListMode;

    return this.http.get<ResponseModel>(urlValue).pipe(map(
      response => {
        return response;
      }));
  }

  GetAllItemsForItemsLists(divisionId: number, brandId: number, categoryId: number, paginateSetting: any) {
    const urlValue: string = this.url + "/GetAllItemsForItemsLists";
    let itemFilters = '?divisionId=' + divisionId + '&brandId=' + brandId + '&categoryId=' + categoryId + '&paginateSetting=' + JSON.stringify(paginateSetting);
    return this.http.get<ResponseModel>(
      urlValue + itemFilters).map(response => {
        const res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      });
  }

  saveItemListData(itemListData: FormData, isEditMode: boolean) {
    const urlValue = this.url + (isEditMode ? "/UpdateItemList" : "/InsertItemList");
    return this.http.post<ResponseModel>(urlValue, itemListData)
      .map(response => {
        return response;
      })
  }


  getAvailableCompetitorItemList(itemId: number) {
    const urlValue = this.url + '/GetAvailableCompetitorItemList?itemId=' + itemId;
    return this.http.get<ResponseModel>(urlValue).pipe(
      map(response => {
        return response;
      })
    );
  }
}
