import { WeekDayEnum } from '../enums/week-day.enum';

export class RouteWeekModel {
    routeId: number;
    weekNo: number;
    Sunday: boolean;
    Monday: boolean;
    Tuesday: boolean;
    Wednesday: boolean;
    Thursday: boolean;
    Friday: boolean;
    Saturday: boolean;

    constructor(routeId: number, weekNo: number) {
        this.routeId = routeId;
        this.weekNo = weekNo;
    }
}
export class WeeDaysPositions {
    SundayPosition: number;
    MondayPosition: number;
    TuesdayPosition: number;
    WednesdayPosition: number;
    ThursdayPosition: number;
    FridayPosition: number;
    SaturdayPosition: number;
    constructor() {

    }
}
