import { Component, OnInit, ViewChild, OnDestroy, Input, OnChanges, EventEmitter, Output } from "@angular/core";
import { NgbModalRef, NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { CoreSession } from "../../../../core/core.session";
import { TranslateService } from "@ngx-translate/core";
import { DialogResult } from "../../../models/enums/dialog-result.enum";
import { ItemService } from "../../../services/data-definition/product/item.service";
import { ConstantMessages } from "../../../models/constants/constant-message";
import { SharedTableResult } from "../../../models/shared-table/shared-table-result.interface";
import { ITableProperties } from "../../../models/shared-table/table-properties.interface";
import { PackGroupService } from "../../../services/data-definition/product/pack-group.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DiscountService } from "../../../services/data-definition/prices-and-offers/discount.service";
import { forkJoin } from "rxjs";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";

@Component({
  selector: 'app-fill-pack-group-items-quantity',
  templateUrl: './fill-pack-group-items-quantity.component.html',
  styleUrls: ['./fill-pack-group-items-quantity.component.css']
})
export class FillPackGroupItemsQuantityComponent implements OnInit, OnDestroy, OnChanges {


  // @ViewChild("content", { static: true }) content: any;
  modalRef: NgbModalRef;
  @ViewChild('fillPacks', { static: true }) fillPacks: any;
  OkResult: DialogResult = DialogResult.Ok;


  @Output() takePackGroupItems = new EventEmitter();
  @Input() isViewMode = false;
  @Input() isBundle = false;
  @Input() showSalesOrderInPackGroup = false;
  @Input() data: any = {
    oldPackGroupDetails: [],
    packGroupId: -1
  };
  groupDetailsList: any = [];
  salesOrderTypeList = [];
  considerStockStatusInOrderFlow: boolean = false;

  multiSelectDropdownSettings = {
    singleSelection: false,
    idField: 'salesOrderTypeId',
    textField: 'salesOrderTypeName',
    selectAllText: '',
    unSelectAllText: '',
    searchPlaceholderText: '',
    // allowSearchFilter: true,
    itemsShowLimit: 1,
  };
  groupDetailSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  packGroupName = '';
  dataTableProperties: ITableProperties = {
    pageSize: 15,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    isMultiSelection: false,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      { title: "Desc_Item_Name", key: "itemName", isSortable: true, width: "30%" },
      { title: "Desc_Item_Code", key: "itemCode", isSortable: true, width: "30%" },
      { title: "Desc_UOM", key: "uom", isSortable: false, width: "15%" },
      { title: "Desc_Quantity", key: "promotionValue", isSortable: true, width: "20%", isEditable: true, numberOfStockDigits: true, isPromotionPrerequisite: true }
    ]
  };
  packGroupDialogMode: any = {
    preRequisiteQty: 1,
    benefitDiscountPercentage: 2,
    benefitFixedPrice: 3,
    preRequisiteDifferentQtyOverGroupFromSalesOrderType: 4
  }
  selectedFillingMode = -1;

  constructor(
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private itemService: ItemService,
    private packGroupService: PackGroupService,
    private modalService: NgbModal,
    private discountService: DiscountService,
    private sessionData: SessionDataProvider
  ) {

  }

  ngOnInit() {
    this.multiSelectDropdownSettings.selectAllText = this.translateService.instant(ConstantMessages.SelectAllCaption);
    this.multiSelectDropdownSettings.unSelectAllText = this.translateService.instant(ConstantMessages.UnSelectAllCaption);
    this.multiSelectDropdownSettings.searchPlaceholderText = this.translateService.instant(ConstantMessages.SearchCaption);
    this.considerStockStatusInOrderFlow = this.sessionData.getConfigurationValue(ConstantConfigurations.ConsiderStockStatusInOrderFlow).toLowerCase() === "true";
    if (this.showSalesOrderInPackGroup && this.considerStockStatusInOrderFlow) {
      this.dataTableProperties.columns.push(
        { title: "Desc_Sales_Order_Type", key: "salesOrderTypeName", isSortable: false, width: "10%", isMultiSelect: true, isEditable: true }
      )
    }
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnChanges() {
    if (this.data && this.data.packGroupId && this.data.packGroupId > 0) {
      this.populatePackGroupDetailsList(this.data.packGroupId);
    }
  }

  populatePackGroupDetailsList(packGroupId) {
    this.coreSession.ModalLoading.Show();
    if (this.selectedFillingMode == this.packGroupDialogMode.preRequisiteDifferentQtyOverGroupFromSalesOrderType) {
      var packGroupsResponse = this.packGroupService.getPackGroupItems(packGroupId, false)
      var salesTypesResponse = this.discountService.getSalesOrderTypes();
      forkJoin(packGroupsResponse, salesTypesResponse).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response[0].status != null && response[0].status >= 0 && response[1].status != null && response[1].status >= 0) {
          this.packGroupName = response[0].data.packGroupNames;
          this.groupDetailsList = response[0].data.packGroupItems;
          this.salesOrderTypeList = response[1].data;
          response[0].data.packGroupItems.forEach(item => {
            item.promotionValue = 0;
          });
          this.groupDetailSource = {
            data: this.groupDetailsList,
            totalItems: this.groupDetailsList.length
          };
          this.reflectAddedBeforeDetails();
        } else {
          this.groupDetailSource = {
            data: [],
            totalItems: 0
          };
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
        }
      );
    } else {
      this.packGroupService.getPackGroupItems(packGroupId, false).subscribe(
        response => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.packGroupName = response.data.packGroupNames;
            this.groupDetailsList = response.data.packGroupItems;
            response.data.packGroupItems.forEach(item => {
              item.promotionValue = 0;
              item.salesOrderTypeName = null;
            });
            this.groupDetailSource = {
              data: this.groupDetailsList,
              totalItems: this.groupDetailsList.length
            };
            this.reflectAddedBeforeDetails();
          } else {
            this.groupDetailSource = {
              data: [],
              totalItems: 0
            };
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
          }
        },
        error => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
        }
      );
    }
  }
  reflectAddedBeforeDetails() {
    if (this.data.oldPackGroupDetails && this.data.oldPackGroupDetails.length > 0 && this.groupDetailsList.length > 0) {
      this.groupDetailsList.forEach(newDetail => {
        var index = this.data.oldPackGroupDetails.findIndex(addedDetail => addedDetail.packId === newDetail.packId);
        if (index >= 0) {
          newDetail.promotionValue = this.data.oldPackGroupDetails[index].promotionValue;
          var salesList = [];
          if (this.salesOrderTypeList && this.salesOrderTypeList.length > 0) {
            this.salesOrderTypeList.forEach(type => {
              if (this.data.oldPackGroupDetails[index].salesOrderTypeIDs != undefined) {
                if (this.data.oldPackGroupDetails[index].salesOrderTypeIDs.includes(type.salesOrderTypeId.toString())) {
                  salesList.push({ salesOrderTypeId: type.salesOrderTypeId, salesOrderTypeName: type.salesOrderTypeName });
                  newDetail.salesOrderTypeName = salesList

                }
              }
            })
          }
        }
      })
    }
  }
  onSaveButtonClick() {
    let isValid = true;

    // block saving when all packs have no quantity !
    var nonDefinedPacks = this.groupDetailsList.filter(x => x.promotionValue === null || x.promotionValue === '' || x.promotionValue === undefined || x.promotionValue === 0 || x.promotionValue === '0');
    if (nonDefinedPacks && nonDefinedPacks.length === this.groupDetailsList.length) {
      isValid = false;
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillOneValue));
      return;
    }
    if (this.selectedFillingMode === this.packGroupDialogMode.benefitDiscountPercentage && this.groupDetailsList.findIndex(x => x.promotionValue && +x.promotionValue > 100) >= 0) {
      isValid = false;
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgMaxDiscountPercentage));
      return;
    }
    var nonDefinedSalesOrder = this.groupDetailsList.filter(x => x.salesOrderTypeName == "" && x.promotionValue == 0);
    if (nonDefinedSalesOrder && nonDefinedSalesOrder.length === this.groupDetailsList.length && this.selectedFillingMode === this.packGroupDialogMode.preRequisiteDifferentQtyOverGroupFromSalesOrderType) {
      isValid = false;
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillSalesOrder));
      return;
    }
    var defineQtyNonDefineSalesOrder = this.groupDetailsList.filter(x => x.salesOrderTypeName == "" && x.promotionValue != 0);
    defineQtyNonDefineSalesOrder.forEach(i => {
      if (this.selectedFillingMode === this.packGroupDialogMode.preRequisiteDifferentQtyOverGroupFromSalesOrderType && defineQtyNonDefineSalesOrder && defineQtyNonDefineSalesOrder.length > 0) {
        isValid = false;
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillSalesOrder) + ' (' + i.itemName + ') ');
        return;
      }
    });
    var nonDefineQtyDefineSalesOrder = this.groupDetailsList.filter(x => x.salesOrderTypeName != "" && x.salesOrderTypeName != null && x.promotionValue == 0);
    nonDefineQtyDefineSalesOrder.forEach(i => {
      if (this.selectedFillingMode === this.packGroupDialogMode.preRequisiteDifferentQtyOverGroupFromSalesOrderType && nonDefineQtyDefineSalesOrder && nonDefineQtyDefineSalesOrder.length > 0) {
        isValid = false;
        this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgEnterQuantity) + ' (' + i.itemName + ') ');
        return;
      }
    });
    if (isValid) {
      this.groupDetailsList.forEach(x => {
        if (!x.promotionValue) {
          x.promotionValue = 0;
          x.salesOrderTypeName = null;
        }
        x.promotionValue = +x.promotionValue;

        // x.salesOrderTypeName = x.salesOrderTypeName;
        if (x.salesOrderTypeName != null) {
          var selectedSalesOrderTypeId = x.salesOrderTypeName.map(({ salesOrderTypeId }) => salesOrderTypeId);
          if (selectedSalesOrderTypeId && selectedSalesOrderTypeId.length > 0) {
            var selectedSalesOrderTypeId = selectedSalesOrderTypeId.join(',');
            // x.salesOrderTypeName = selectedSalesOrderTypeId
            x.salesOrderTypeIDs = selectedSalesOrderTypeId
          }
        }

      })
      this.takePackGroupItems.emit(this.groupDetailsList);
      this.modalRef.close();
    }
  }
  public showDialog(fillingModeId) {

    this.selectedFillingMode = fillingModeId;
    var index = this.dataTableProperties.columns.findIndex(x => x.key === 'promotionValue');
    if (index >= 0) {
      this.dataTableProperties.columns[index].numberOfStockDigits = false;
      this.dataTableProperties.columns[index].numberOfDigits = false;
      this.dataTableProperties.columns[index].isEditable = !this.isViewMode;
      if (this.selectedFillingMode == this.packGroupDialogMode.preRequisiteQty) {
        // fill pre-requisite quantities
        this.dataTableProperties.columns[index].title = 'Desc_Quantity'
        this.dataTableProperties.columns[index].numberOfStockDigits = true;
        this.dataTableProperties.columns[index].width = '20%';
      }
      else if (this.selectedFillingMode == this.packGroupDialogMode.benefitDiscountPercentage) {
        // fill pre-requisite quantities
        this.dataTableProperties.columns[index].title = 'Desc_Discount'
        this.dataTableProperties.columns[index].numberOfDigits = true;
      }
      else if (this.selectedFillingMode == this.packGroupDialogMode.benefitFixedPrice) {
        // fill pre-requisite quantities
        this.dataTableProperties.columns[index].title = 'Desc_Price'
        this.dataTableProperties.columns[index].numberOfDigits = true;
      }
      else if (this.selectedFillingMode == this.packGroupDialogMode.preRequisiteDifferentQtyOverGroupFromSalesOrderType) {
        // fill pre-requisite quantities
        this.dataTableProperties.columns[index].title = 'Desc_Quantity'
        this.dataTableProperties.columns[index].numberOfStockDigits = true;
        this.dataTableProperties.columns[index].width = '15%';
      }
    }
    var index = this.dataTableProperties.columns.findIndex(x => x.key === 'salesOrderTypeName');
    if (this.selectedFillingMode == this.packGroupDialogMode.preRequisiteDifferentQtyOverGroupFromSalesOrderType && index == -1) {
      this.dataTableProperties.columns.push({ title: "Desc_Sales_Order_Type", key: "salesOrderTypeName", isSortable: false, width: "20%", isMultiSelect: true, isEditable: true });
    }
    if (index >= 0) {
      this.dataTableProperties.columns[index].isMultiSelect = false;
      this.dataTableProperties.columns[index].isEditable = !this.isViewMode;
      if (this.selectedFillingMode == this.packGroupDialogMode.preRequisiteDifferentQtyOverGroupFromSalesOrderType) {
        // fill pre-requisite quantities
        this.dataTableProperties.columns[index].title = 'Desc_Sales_Order_Type'
        this.dataTableProperties.columns[index].isMultiSelect = true;
        this.dataTableProperties.columns[index].isEditable = true;
        this.dataTableProperties.columns[index].width = '20%';
      }
      if (this.selectedFillingMode == this.packGroupDialogMode.preRequisiteQty) {
        // fill pre-requisite quantities
        this.dataTableProperties.columns.splice(index, 1)
      }
    }
    var index = this.dataTableProperties.columns.findIndex(x => x.key === 'itemCode');
    if (index >= 0) {
      if (this.selectedFillingMode == this.packGroupDialogMode.preRequisiteDifferentQtyOverGroupFromSalesOrderType) {
        // fill pre-requisite quantities
        this.dataTableProperties.columns[index].width = '20%';
      } else {
        this.dataTableProperties.columns[index].width = '30%';
      }
    }
    this.coreSession.SetTitle('Fill Group Data');
    this.modalRef = this.modalService.open(this.fillPacks, {
      centered: true,
      size: "lg"
    });
    return this.modalRef.result.then(
      result => { },
      resaon => {
        return DialogResult.None;
      }
    );
    // return this.modalService.open(this.selectCustomerDialog, {centered: true,size: 'xl'}).result.then(
    //   (result) => {

    //   });
    // this.modalRef = this.modalService.open(this.content, {
    //   centered: true,
    //   size: "lg"
    // });
    // return this.modalRef.result.then(
    //   result => { },
    //   resaon => {
    //     return DialogResult.None;
    //   }
    // );
  }

}

