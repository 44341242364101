import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../models/constants/constant-message';

@Component({
  selector: 'app-items-multiselect-filter',
  templateUrl: './items-multiselect-filter.component.html',
  styleUrls: ['./items-multiselect-filter.component.css']
})
export class ItemsMultiselectFilterComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() divisionId: number = -1;
  @Input() brandId: number = -1;
  @Input() categoryId: number = -1;
  @Input() disabled: boolean = false;

  isItemsLoading: boolean = false;
  itemsDataSource: any[] = [];

  dropdownItemsSettings = {
    singleSelection: false,
    idField: 'itemId',
    textField: 'itemCodeName',
    selectAllText: '',
    unSelectAllText: '',
    position: 'top',
    allowSearchFilter: true,
    searchPlaceholderText: '',
    itemsShowLimit: 1
  };
  constructor(private itemService: ItemService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.dropdownItemsSettings.selectAllText = this.translateService.instant(ConstantMessages.SelectAllCaption);
    this.dropdownItemsSettings.unSelectAllText = this.translateService.instant(ConstantMessages.UnSelectAllCaption);
    this.dropdownItemsSettings.searchPlaceholderText = this.translateService.instant(ConstantMessages.SearchCaption);

    //this.populateAllItemsList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.itemsDataSource = [];
    this.populateAllItemsList();
  }
  populateAllItemsList() {
    this.isItemsLoading = true;
    this.itemService
      .GetAllItemsForItemsLists(this.divisionId, this.brandId, this.categoryId,null)
      .subscribe(result => {
        if (result != undefined && result.data != undefined) {
          this.itemsDataSource = result.data;
        }
        this.isItemsLoading = false;
      });
  }
}
