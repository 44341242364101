import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ResponseModel } from "../../../shared/models/api-models/api-models";

@Injectable({
  providedIn: "root"
})
export class SetupService {
  private url = "";

  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.url = baseUrl + "Setup/";
  }

  setupEnviroment(usernameVal: string, passwordVal: string) {
    const urlValue = this.url + "SetupEnviroment";
    let filter = {
      username: usernameVal,
      password: passwordVal
    }
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response;
    });
  }

  UpdateLicense(languageId) {
    const urlValue = this.url + "UpdateLicense";
    return this.http.post<ResponseModel>(urlValue, languageId).map(response => {
      return response;
    });
  }

  IsSettedUp() {
    const urlValue = this.url + "IsSettedIp";
    return this.http.post<ResponseModel>(urlValue, "").map(response => {
      return response.data;
    });
  }
}
