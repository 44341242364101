import { FormProcessMode } from '../enums/form-process-mode.enum';
import { CustomListFilter } from '../shared-table/custom-list.interface';
import { DateTimeModel } from '../dateTimeModel/date-time.model';
import { TransactionType } from '../enums/transaction-type.enum';

export class GeneralFilter {
    customerId?: number;
    outletId?: number;
    employeeId?: number;
    organizationId?: number;
    organizationIds?: string;
    divisionId?: number;
    channelId?: number;
    subChannelId?: number;
    templateId?: number;
    itemCategoryId?: number;
    warehouseId?: number;
    vehicleId?: number;
    dateModel?: DateTimeModel;
    toDate?: DateTimeModel;
    fromToDate?: DateTimeModel[];
    transactionId?: string;
    customerPaymentId?: string;
    formProcessMode?: FormProcessMode;
    transactionType?: TransactionType;
    customListFilter?: CustomListFilter;
    getPrices?: boolean
    code?: string;
    iCashTransactionType?: string;
    groupId?: number;
    channelSubChannel?: string;
    packGroupId?: number;
    itemId?: number;
    packId?: number;
    includeInactive?: boolean;
    allCustomersOnly?: boolean;
    excludeOnHold?: boolean;
    territoryId?: number;
    routeId?: number;
    filterOnRoute?: boolean;
    showTransWithRemaining?: boolean;
    showPacks?: boolean;
    generateMoreInfoLink?: boolean;
    moreInfoDashboardId?: number;
    izLazyLodingCombo?: boolean;
    includeVoided?: boolean;
    includeCashPayment?: boolean;
    newCustomerOnly?: boolean;
    isDefaultCustomer?: boolean;
    proofId?: number;
    excludeCompetitorItem?: boolean;
    getChattingCustomers?: boolean;
    checkBoxValue?: boolean;
    typeValue?: boolean;
    reportTypeId?: number;
    showInActive?: boolean;
    hasPrivilegeOnPaymentTermDivision?: boolean;
    hasPrivilegeOnOnHoldDivision?: boolean;
    accountId?: number;
    outletCode?: string;
    customerOutletId?: string;
    requestId?: string;
    promotionIdsString?: string;
    allCustomers?: boolean;
    isFromCollection?: boolean;
    public constructor() {
        this.getPrices = false;
        this.generateMoreInfoLink = false;
        this.excludeOnHold = false;
        this.isDefaultCustomer = false;
    }
}
