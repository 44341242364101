import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from '../../../core/core.session';
import { warehouseFilter } from '../../models/shared-table/custom-list.interface';
import { WarehouseTransactionOperation } from '../../models/enums/warehouse-transaction-operation.enum';
import { ResponseModel } from '../../models/api-models/api-models';
import { WarehouseTransaction } from '../../models/warehouse-transactions/warehouse-transaction.model';
import { SSCCStatuses } from '../../models/enums/sscc-statuses-enum';
import { FormProcessMode } from '../../models/enums/form-process-mode.enum';

@Injectable({
  providedIn: 'root'
})
export class SSCCGenerationService {
  httpOptions;
  private url = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'SSCCGeneration';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }
  getSSCCList(
    orderId: string,
    transactionId: string,
    filter: warehouseFilter
  ) {

    const urlValue: string = this.url + '/GetSSCCList';
    let sCCModel = {
      orderId: orderId,
      transactionId: transactionId,
      warehouseFilter: filter
    }
    return this.http
      .post<ResponseModel>(
        urlValue, sCCModel
      )
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  getSSCCDetailsTotals(
    orderId: string,
    transactionId: string
  ) {

    const urlValue: string = this.url + '/GetSSCCDetailsTotals';
    let sCCModel = {
      orderId: orderId,
      transactionId: transactionId
    }
    return this.http
      .post<ResponseModel>(
        urlValue, sCCModel
      )
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  getSSCCDetails(ssccBarcode: string, processMode: FormProcessMode, showForEdit: boolean) {

    const urlValue: string = this.url + '/GetSSCCDetails';
    let sCCModel = {
      sSCCBarcode: ssccBarcode,
      processMode: processMode,
      showForEdit: showForEdit
    }
    return this.http
      .post<ResponseModel>(
        urlValue, sCCModel
      )
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  cancelSSCC(ssccBarcode: string, processMode: FormProcessMode) {
    const urlValue = this.url + '/CancelSSCC';
    let sccModel = {
      sSCCBarcode: ssccBarcode,
      processMode: processMode
    }
    //JSON.stringify(ssccBarcode)
    return this.http
      .post<ResponseModel>(urlValue, sccModel)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  deleteSSCC(ssccBarcode: string) {
    const urlValue = this.url + '/DeleteSSCC';
    let sccModel = {
      sSCCBarcode: ssccBarcode
    }
    //JSON.stringify(ssccBarcode)
    return this.http
      .post<ResponseModel>(urlValue, sccModel)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  saveSSCCData(ssccObject: any) {
    const urlValue = this.url + '/InsertSSCCData';

    return this.http
      .post<ResponseModel>(urlValue, ssccObject)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  updateSSCCData(ssccObject: any) {
    const urlValue = this.url + '/UpdateSSCCData';
    return this.http
      .post<ResponseModel>(urlValue, ssccObject)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  getApprovedLoadRequests() {
    const urlValue: string = this.url + '/GetApprovedLoadRequests';

    return this.http
      .get<ResponseModel>(
        urlValue
      )
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  saveWarehouseOperationAfterGenerateSSCC(warehouseTransaction: WarehouseTransaction) {
    const urlValue = this.url + '/InsertWarehouseOperationAfterGenerateSSCC';
    return this.http
      .post<ResponseModel>(urlValue, warehouseTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  deliverOrderAfterGenerateSSCC(orderObj: any) {
    const urlValue = this.url + '/DeliverOrderAfterGenerateSSCC';
    return this.http
      .post<ResponseModel>(urlValue, orderObj)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  generateSSCCBarcode() {
    const urlValue = this.url + '/GenerateSCCBarcode';
    return this.http
      .get<ResponseModel>(urlValue)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  CheckIfTransactionOrOrderHasAddedSSCC(transactionId: string, orderId: string) {
    const urlValue: string = this.url + '/CheckIfTransactionOrOrderHasAddedSSCC';
    let sccModel = {
      transactionId: transactionId,
      orderId: orderId
    }
    return this.http
      .post<ResponseModel>(urlValue, sccModel)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
  addScannedSSCCToTransaction(transactionId: string, orderId: string, warehouseId: number, divisionId: number, barcode: string) {
    const urlValue: string = this.url + '/AddScannedSSCCToTransaction';
    let sccModel = {
      transactionId: transactionId,
      orderId: orderId,
      warehouseId: warehouseId,
      divisionId: divisionId,
      sSCCBarcode: barcode
    }
    return this.http
      .post<ResponseModel>(urlValue, sccModel)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
}
