import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ResponseModel } from "../../models/api-models/api-models";
import { CoreSession } from "../../../core/core.session";
import { warehouseFilter } from "../../models/shared-table/custom-list.interface";
import { WarehouseTransactionOperation } from "../../models/enums/warehouse-transaction-operation.enum";
import { WarehouseTransaction } from "../../models/warehouse-transactions/warehouse-transaction.model";
import { WarehouseTransactionStatus } from "../../models/enums/warehouse-transaction-status.enum";

@Injectable({
  providedIn: 'root'
})

export class OffloadRequestService {

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "OffloadRequest";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  UpdateWarehouseTransactionStatus(warehouseTransaction: WarehouseTransaction, newStatusId: number) {
    let actionName = ""
    if (newStatusId == WarehouseTransactionStatus.Approved.valueOf()) {
      actionName = "/ApproveVehicleTransactionStatus";
    } else if (newStatusId == WarehouseTransactionStatus.Canceled.valueOf()) {
      actionName = "/CancelVehicleTransactionStatus";
    } else if (newStatusId == WarehouseTransactionStatus.Executed.valueOf()) {
      actionName = "/ExecuteVehicleTransactionStatus";
    } else {
      actionName = "/UpdateVehicleTransactionStatus";
    }
    const urlValue: string = this.url + actionName;
    warehouseTransaction.newStatusId = newStatusId;
    return this.http
      .post<ResponseModel>(urlValue, warehouseTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }


  UpdateWarehouseTransactionsListStatus(warehouseTransactions: WarehouseTransaction[], newStatusId: number) {
    const urlValue: string = this.url + '/UpdateWarehouseTransactionsListStatus';

    const updatedWarehouseTransactions = {
      warehouseTransactions: warehouseTransactions,
      newStatusId: newStatusId
    };
    return this.http.post<ResponseModel>(urlValue, updatedWarehouseTransactions).map(response => {
      const res = new ResponseModel();
      res.data = response['data'];
      res.message = response['message'];
      res.status = response['status'];
      return res;
    });
  }

  SaveWarehouseOperation(warehouseTransaction: WarehouseTransaction, isEditMode: boolean) {
    const urlValue: string = this.url + (isEditMode ? '/UpdateWarehouseOperation' : '/InsertWarehouseOperation');
    return this.http
      .post<ResponseModel>(urlValue, warehouseTransaction)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  offloadManuallyVehicle(vehicles: any[]) {
    const urlValue: string = this.url + "/OffloadManuallyVehicle";
    return this.http.post<ResponseModel>(urlValue, vehicles).map(response => {
      const res = new ResponseModel();
      res.data = response["data"];
      res.message = response["message"];
      res.status = response["status"];
      return res;
    });
  }

  importFileForSerials(file: FormData) {
    return this.http.post(this.url + '/ExecuteDataFromImportedFile', file).map(
      (response: Response) => {
        let res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }
}
