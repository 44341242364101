import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { CustomerService } from '../../../../services/data-definition/customer/customers.service';

@Component({
  selector: 'app-customer-group-filter',
  templateUrl: './customer-group-filter.component.html',
  styleUrls: ['./customer-group-filter.component.css']
})
export class CustomerGroupFilterComponent implements OnInit {

  isClassLoading = false;
  customerGroupDataSource = [];
  @Input() parentForm: FormGroup;
  @Output() onChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onGroupIdCaptionChanged = new EventEmitter();
  @Input() emitIdAndCaption = false;
  @Input() channelId = - 1;
  @Input() subChannelId = -1;
  @Input() selectedOrgIds = "";
  @Input() groupId = -1;
  isRequired = false;
  constructor(private customerService: CustomerService) { }

  ngOnInit() {
    if (this.parentForm.get('customerGroupId').validator != null) {
      const validator = this.parentForm.get('customerGroupId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
  }
  ngOnChanges() {
    if (this.groupId && this.groupId > -1) {
      this.parentForm.get('customerGroupId').setValue(this.groupId);
      this.groupId = -1;
    } else {
      this.parentForm.get('customerGroupId').setValue(null);
    }
    this.populateGroupsList();
  }

  populateGroupsList() {
    this.isClassLoading = true;
    this.customerService.getAllFilteredCustomerGroups(this.channelId ? this.channelId : -1, this.subChannelId ? this.subChannelId : -1, this.selectedOrgIds).subscribe(result => {
      this.customerGroupDataSource = result.data;
      this.isClassLoading = false;
    });
  }
  onCustomerGroupChanged(group: any) {

    if (this.emitIdAndCaption) {

      let event = {
        id: this.parentForm.value.customerGroupId,
        caption: group.groupCodeName
      }
      this.onGroupIdCaptionChanged.emit(event);
    } else {
      this.onChange.emit(group);
    }


  }
}
