import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DisplayUnitService } from '../../../../../content/master-data-definition/display-unit/display-unit.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { DisplayUnitTemplateService } from '../../../../services/data-definition/display-unit/display-unit-template.service';

@Component({
  selector: 'app-display-unit-template',
  templateUrl: './display-unit-template.component.html',
  styleUrls: ['./display-unit-template.component.css']
})
export class DisplayUnitTemplateComponent implements OnInit, OnChanges {

  @Input() parentForm: FormGroup;
  @Input() displayUnitTypeId = null;
  displayUnitTemplateDataSource: any[];
  displayUnitTemplateCash: any[] = null;
  displayUnitTemplateCashTemp: any[] = null;

  isTemplateLoading = false;
  isRequired = false;
  resetSubscription: any;

  constructor(private displayUnitService: DisplayUnitService,
    private displayUnitTemplateService: DisplayUnitTemplateService) { }

  ngOnChanges() {
    if (this.displayUnitTypeId)
      this.populateDisplayUnitTemplateListOverTypeID();
  }
  ngOnInit() {
    if (this.parentForm.get('displayUnitTemplate').validator != null) {
      const validator = this.parentForm.get('displayUnitTemplate').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateDisplayUnitTemplateList();
  }
  ngOnDestroy() {

  }

  public afterSaveTemplate(template: any): void {
    this.displayUnitTemplateDataSource.push(template);
    var orginalList = cloneDeep(this.displayUnitTemplateDataSource);
    this.displayUnitTemplateDataSource = [];
    this.displayUnitTemplateDataSource = orginalList;

    this.parentForm.get('displayUnitTemplate').setValue(template.displayUnitTemplateID);
  }

  populateDisplayUnitTemplateList() {
    this.isTemplateLoading = true;
    this.displayUnitTemplateService.getDisplayUnitTemplateFilter().subscribe(result => {
      this.displayUnitTemplateDataSource = result;
      this.displayUnitTemplateCash = result;
      this.isTemplateLoading = false;
      this.populateDisplayUnitTemplateListOverTypeID();
    });
  }

  populateDisplayUnitTemplateListOverTypeID() {
    this.displayUnitTemplateCashTemp = [];
    if (this.displayUnitTemplateCash == null) {
      this.populateDisplayUnitTemplateList();
    } else {
      this.displayUnitTemplateCash.forEach((element) => {
        if (element.displayUnitTypeID == this.displayUnitTypeId) {
          this.displayUnitTemplateCashTemp.push(element);
        }
      });
      this.displayUnitTemplateDataSource = this.displayUnitTemplateCashTemp;
    }
  }

}