import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CustomerClassModel } from '../../../../models/customer/customer-class.model';
import { FormGroup } from '@angular/forms';
import { EntryFormTypes } from '../../../../models/enums/entry-form-types.enum';
import { Subject } from 'rxjs';
import { CustomerService } from '../../../../services/data-definition/customer/customers.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedDialogComponent } from '../../../shared-dialog/shared-dialog.component';


@Component({
  selector: 'app-customer-class-filter',
  templateUrl: './customer-class-filter.component.html',
  styleUrls: ['./customer-class-filter.component.css']
})
export class CustomerClassFilterComponent implements OnInit  {

  @ViewChild("templateEntry", { static: true }) templateEntry: SharedDialogComponent;
  isClassLoading = false;
  addClass=false;
  lastAddedCustomerClassId:number;
  entryFormType:EntryFormTypes=EntryFormTypes.CustomerClass;
  customerClassDataSource: CustomerClassModel[] = [];

  @Input() parentForm: FormGroup;
  @Input() isLabelHidden: boolean;
  @Input() isAddNewHidden: boolean;
  @Output() onChangeCustomerClass = new EventEmitter();
  saveSubject: Subject<void> = new Subject<void>();
  constructor(private customerService:CustomerService,private modalService: NgbModal) { }

  ngOnInit() {
    this.populateClassesList();
    this.lastAddedCustomerClassId=this.parentForm.value.customerClassId;
  }

  populateClassesList() {
    this.isClassLoading = true;
    this.customerService.getCustomerClasses().subscribe(result => {
      this.customerClassDataSource = result.data;
      // let newClass = new CustomerClassModel();
      // newClass.customerClassId=-1;
      // newClass.customerClassCodeName='New Class...';
      // this.customerClassDataSource.unshift(newClass);
      this.isClassLoading = false;
    });
  }
  onCustomerClassSelectionchanged(){
    this.onChangeCustomerClass.emit();
    // if(this.parentForm.value.customerClassId==-1){
    //   this.addClass=true;
    // }
  }
  onAddingNewCustomerClass(customerClassId:number){
    this.populateClassesList();
    this.lastAddedCustomerClassId=customerClassId;
    this.onCloseClassClicked();
  }

  onSaveClassClicked() {
    this.saveSubject.next();
  }
  onCloseClassClicked() {
    this.templateEntry.Close();
  }

  showClassModal() {
    this.templateEntry.Show(true).then(
      (res) => {
      }
    );
  }

  onDialogResult(event) {
    if (event && event.saveCLicked) {
      this.onSaveClassClicked();
    }
  }
}
