import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomerService } from '../../../../shared/services/data-definition/customer/customers.service';
import { EntryFormTypes } from '../../../../shared/models/enums/entry-form-types.enum';
import { CustomerClassModel, CustomerSubClassModel } from '../../../../shared/models/customer/customer-class.model';

@Component({
  selector: 'app-customer-class-entry',
  templateUrl: './customer-class-entry.component.html',
  styleUrls: ['./customer-class-entry.component.css']
})
export class CustomerClassEntryComponent implements OnInit {
  parentForm:FormGroup;
  @Input() isEditMode=false;
  @Input() isViewMode=false;
  @Input() entryFormType:EntryFormTypes;
  @Output() onAddingNewCustomerClass=new EventEmitter();
  constructor(private customerService:CustomerService) { }

  ngOnInit() {
    this.parentForm = new FormGroup({
      name: new FormControl(
        { value: "", disabled: this.isViewMode },
        this.isEditMode ? null : Validators.required
      ),code:new FormControl(
        { value: "", disabled: this.isViewMode },
        this.isEditMode ? null : Validators.required
      )
    });
  }
 addNewCustomerClass(){
  var customerClass =new  CustomerClassModel();
  customerClass.customerClassName=this.parentForm.value.customerClassName;
  customerClass.customerClassCode=this.parentForm.value.customerClassCode;
      this.customerService.InsertNewCustomerClass(customerClass).subscribe(result => {
        customerClass=result.data;
        this.onAddingNewCustomerClass.emit(customerClass.customerClassId);

      });
 }
 addNewCustomerSubClass(){
  var customerClass =new  CustomerSubClassModel();
  customerClass.customerSubClassName=this.parentForm.value.customerClassName;
  customerClass.customerSubClassCode=this.parentForm.value.customerClassCode;
      this.customerService.InsertNewCustomerSubClass(customerClass).subscribe(result => {
        customerClass=result.data;
        this.onAddingNewCustomerClass.emit(customerClass.customerSubClassId);

      });
 }
  addNew(){
switch(this.entryFormType){
  case EntryFormTypes.CustomerClass:
  this.addNewCustomerClass();
  break;
  case EntryFormTypes.CustomerSubClass:
  this.addNewCustomerSubClass();
  break;
}
   }
}
