import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CoreSession } from "../../../core/core.session";
import { ResponseModel } from "../../models/api-models/api-models";
import { WarehouseTransaction } from "../../models/warehouse-transactions/warehouse-transaction.model";

@Injectable({
    providedIn: 'root'
})

export class OutputVouchersService {

    private url = "";
    httpOptions;
    constructor(
        private http: HttpClient,
        @Inject("BASE_URL") baseUrl: string,
        private coreSession: CoreSession
    ) {
        this.url = baseUrl + "OutputVouchers";
        this.httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };
    }


    saveOutputVoucher(warehouseTransaction: WarehouseTransaction) {
        const urlValue: string = this.url + '/InsertOutputVoucher';
        return this.http
            .post<ResponseModel>(urlValue, warehouseTransaction)
            .map(response => {
                const res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            });
    }

    importFileForSerials(file: FormData) {
        return this.http.post(this.url + '/InsertDataFromImportedFile', file).map(
            (response: Response) => {
                let res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            });
    }

}
