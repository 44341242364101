import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';
import { ResponseModel } from '../models/api-models/api-models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ImportDataTypes } from '../models/import/ImportDataTypes';
import * as FileSaver from 'file-saver';
import { ImportModel } from '../models/import/ImportModel.model';
import { CoreSession } from '../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../models/constants/constant-message';

@Injectable({ providedIn: 'root' })
export class ImportService {
    private url = '';
    private httpOptions;
    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
        private coreSession: CoreSession,
        private translateService: TranslateService) {
        this.url = baseUrl + 'Import';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };
    }
    // downloadFile(data: ImportDataTypes, fileName: string) {
    //     return this.http.post(this.url + '/DownloadFile?fileType=' + data.valueOf(),'',
    //         { responseType: 'blob' })
    //         .map(
    //             (res) => {
    //                 var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //                 FileSaver.saveAs(blob, fileName);
    //                 return blob;
    //             });
    // }
    downloadFile(data: ImportDataTypes, fileName: string) {
   
        return this.http.post(this.url + '/DownloadFile?fileType=' + data.valueOf(), '',
            { responseType: 'blob' })
            .map(
                (res) => {
                    var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    FileSaver.saveAs(blob, fileName);
                    return blob;
                });
    }
    importFiles(file: FormData) {
        return this.http.post(this.url + '/ImportFile', file).map(
            (response: Response) => {
                let res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            });
    }
    ImportFileForSerials(file: FormData) {
        return this.http.post(this.url + '/ImportFileForSerials', file).map(
            (response: Response) => {
                let res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            });
    }
    // uploadJsonFiles(data: ImportModel) {
    //     return this.http.post(this.url + '/UploadJsonFiles', JSON.stringify(data), this.httpOptions).map(
    //         (response) => {
    //             let res = new ResponseModel();
    //             res.data = response['data'];
    //             res.message = response['message'];
    //             res.status = response['status'];
    //             return res;
    //         }
    //     );
    // }

    getViewDetails(Id) {
        return this.http.get(this.url + '/GetViewDetails?formType=' + Id).map(
            (response: Response) => {
                let res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            });
    }
    saveViewDetails(obj) {
        const urlValue: string = this.url + '/SaveViewDetails';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).map(
            (response) => {
                let res = new ResponseModel();
                res.data = response['data'];
                res.message = response['message'];
                res.status = response['status'];
                return res;
            }
        );
    }
    exportInvalidSerials(data: any) {
        return this.http.post(this.url + '/ExportInvalidSerials', data,
            { responseType: 'blob' })
            .map(
                (res) => {
                    var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    FileSaver.saveAs(blob, ConstantMessages.InvalidSerialSheet);
                    return blob;
                });
    }
    downloadDynamicExcelTemplate(entryFormId: number, fileName: string) {
        return this.http.post(this.url + '/DownloadDynamicExcelTemplate?entryFormId=' + entryFormId,''
        ,{ responseType: 'blob' }
        )
        .map(
            (res) => {
                var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, fileName+"Template.xlsx"); 
                return blob;
            });
    }
}
