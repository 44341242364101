export enum OrderOperation {
    None= 0,
    Add = 1,
    EditValues = 2 ,
    Approve = 3 ,
    Cancel = 4 ,
    DisApprove = 5,
    GetPending = 6,
    GetApproved = 7,
    GetAssigned = 8,
    GetInvoice = 9,
    GetInvoiced = 10,
    Invoicing=11,
    FullDelivery=12,
    PartialDelivery=13,
    ViewInvoiced=14,
    GetRejected =15,
    GetForSSCC=16,
    GetCollected = 17

}
