import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, OnChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedTableResult } from '../../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../../models/shared-table/table-properties.interface';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from '../../../services/data-definition/customer/customers.service';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DialogResult } from '../../../models/enums/dialog-result.enum';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CustomerSelectionLevel } from '../../../models/enums/customer-selection-level.enum';
import { GeolocationService } from '../../../services/geolocation.service';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';

@Component({
  selector: 'app-select-customers',
  templateUrl: './select-customers.component.html',
  styleUrls: ['./select-customers.component.css']
})
export class SelectCustomersComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('selectCustomerDialog', { static: true }) selectCustomerDialog: any;
  OkResult: DialogResult = DialogResult.Ok;

  @Input() currentId = 'customerSelection';
  @Input() addedCustomersList: any[] = [];
  @Input() customerSelectionLevel = CustomerSelectionLevel.OutletLevel;
  @Input() showDefault: boolean = false;
  @Input() isSalesRestrictionCustomers: boolean = false;
  @Input() isPromotions: boolean = false;
  @Input() channelId: number = -1;
  @Input() subChannelId: number = -1;
  @Input() groupId: number = -1;
  @Input() fillCustomerOrganizationIds: boolean = false;
  @Input() disableCustomerGroup = false;
  selectedOraganizationIds = '';
  isCustomerOutletDivisionHasRecords: boolean = false;
  query;
  public customerFilterForm: FormGroup;

  sharedTabelData: ITableProperties = {
    pageSize: 100,
    showPaginator: true,
    isOnline: true,
    showSearch: true,
    // showSearchBtn: true,
    isMultiSelection: true,
    showEditButton: false,
    rowOperations: [],
    multiSelectionOperations: [],
    specificActionWhenCheckAll: true,
    specificActionWhenCheckRow: true,
    columns: [
      { title: 'Desc_Customer', key: 'customerCodeName', isSortable: true, width: '35%' },
      { title: 'Desc_Outlet', key: 'outletCodeName', isSortable: true, width: '35%' },
      { title: 'Desc_Phone', key: 'phone', isSortable: true, width: '15%' },
      { title: 'Desc_Address', key: 'address', isSortable: true, width: '15%' }
    ]
  };
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  outletsFilter: any = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: 100
    },
    customerGroupId: -1,
    customerClassId: -1,
    territoryId: -1,
    selectedOraganizationIds: '',
    channelId: -1,
    subChannelId: -1,
    routeId: -1,
    customerTypeId: -1,
    showDefault: false,
    isCustomerOutletDivisionHasRecords: false
  }

  constructor(
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private customerService: CustomerService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private locationService: GeolocationService,
    private sessionData: SessionDataProvider,
  ) {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }
  ngOnInit() {
    if (this.isDivisionLevel()) {
      this.checkCustomerOutletDivisionIsExist();
    }
  }
  ngOnChanges() {
    this.initFilterForm();
  }
  ngOnDestroy() {
    this.config.backdrop = true;
    this.config.keyboard = true;
  }
  initFilterForm() {
    this.customerFilterForm = new FormGroup({
      customerGroupId: new FormControl({value: null, disabled: this.disableCustomerGroup}),
      customerClassId: new FormControl(null),
      channelId: new FormControl(null),
      subChannelId: new FormControl(null),
      territoryId: new FormControl(null),
      routeId: new FormControl(null),
      customerTypeId: new FormControl(null),
      divisionId: new FormControl(null),
      country: new FormControl(null),
      state: new FormControl(null),
      city: new FormControl(null),
      area: new FormControl(null),
      street: new FormControl(null),
    });
  }
  populateCustomers() {
    this.outletsFilter.customerGroupId = (!this.customerGroupInput.value || this.customerGroupInput.value === null) ? -1 : this.customerGroupInput.value;
    this.outletsFilter.customerClassId = (!this.customerClassInput.value || this.customerClassInput.value === null) ? -1 : this.customerClassInput.value;
    this.outletsFilter.channelId = (!this.customerChannelInput.value || this.customerChannelInput.value === null) ? -1 : this.customerChannelInput.value;
    this.outletsFilter.subChannelId = (!this.customerSubChannelInput.value || this.customerSubChannelInput.value === null) ? -1 : this.customerSubChannelInput.value;
    this.outletsFilter.territoryId = (!this.territoryIdInput.value || this.territoryIdInput.value === null) ? -1 : this.territoryIdInput.value;
    this.outletsFilter.routeId = (!this.routeIdlInput.value || this.routeIdlInput.value === null) ? -1 : this.routeIdlInput.value;
    this.outletsFilter.customerTypeId = (!this.customerTypeIdInput.value || this.customerTypeIdInput.value === null) ? -1 : this.customerTypeIdInput.value;
    this.outletsFilter.selectedOraganizationIds = this.selectedOraganizationIds ? this.selectedOraganizationIds.toString() : '';
    this.outletsFilter.showDefault = this.showDefault ? this.showDefault : false;
    this.outletsFilter.getAllData = false;
    this.outletsFilter.fillCustomerOrganizationIds = this.fillCustomerOrganizationIds;
    this.outletsFilter.isCustomerOutletDivisionHasRecords = this.isCustomerOutletDivisionHasRecords
    if (this.isSalesRestrictionCustomers || this.isPromotions) {
      this.prepareOutletsFilter();
    }

    this.coreSession.ModalLoading.Show();
    if (this.customerSelectionLevel === CustomerSelectionLevel.OutletLevel) {
      this.customerService.getOutletsListSharedTable(this.outletsFilter).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.reflectAddedBeforeCustomers(response.data);
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      }, (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      });
    } else {
      this.customerService.getCustomersListSharedTable(this.outletsFilter).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.reflectAddedBeforeCustomers(response.data);
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      }, (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      });
    }
  }
  reflectAddedBeforeCustomers(dataSource) {
    if (dataSource && dataSource.data && dataSource.data.length > 0) {
      dataSource.data.forEach(newCustomer => {
        var index = this.addedCustomersList.findIndex(addedCustomer => addedCustomer.customerId === newCustomer.customerId && addedCustomer.outletId === newCustomer.outletId);
        if (index >= 0) {
          newCustomer.isChecked = true;
          newCustomer.routesList = this.addedCustomersList[index].routesList;
          newCustomer = this.addedCustomersList[index];
        }
      })
    }
    this.dataSource = {
      totalItems: dataSource.totalItems,
      data: dataSource.data
    }
  }

  get customerGroupInput() {
    return this.customerFilterForm.get('customerGroupId');
  }
  get customerClassInput() {
    return this.customerFilterForm.get('customerClassId');
  }
  get customerChannelInput() {
    return this.customerFilterForm.get('channelId');
  }
  get customerSubChannelInput() {
    return this.customerFilterForm.get('subChannelId');
  }
  get routeIdlInput() {
    return this.customerFilterForm.get('routeId');
  }
  get territoryIdInput() {
    return this.customerFilterForm.get('territoryId');
  }
  get customerTypeIdInput() {
    return this.customerFilterForm.get('customerTypeId');
  }
  onFindCustomers() {
    this.populateCustomers();
  }
  onClearFilters() {
    this.customerFilterForm.reset();
  }
  onCheckRowOperation(customerOutlet) {
    var index = this.addedCustomersList.findIndex(x => x.customerId === customerOutlet.customerId && x.outletId === customerOutlet.outletId);
    if (customerOutlet.isChecked) {
      if (index < 0) {
        this.addedCustomersList.push(customerOutlet);
      }
    } else {
      if (index >= 0) {
        this.addedCustomersList.splice(index, 1);
      }
    }
  }
  onCheckAllOperation(checkAll: boolean) {
    this.dataSource.data.forEach(outlet => {
      var index = this.addedCustomersList.findIndex(x => x.customerId === outlet.customerId && x.outletId === outlet.outletId);
      if (index >= 0 && !checkAll) {
        this.addedCustomersList.splice(index, 1);
      } else if (index < 0 && checkAll) {
        this.addedCustomersList.push(outlet);
      }
    })
  }
  onTableFilterChanged(query: any) {
    this.outletsFilter.customListFilter = query;
    this.query = query;
    this.populateCustomers();
  }
  public showDialog(organizationIds: any) {
    if (this.customerSelectionLevel === CustomerSelectionLevel.CustomerLevel) {
      this.sharedTabelData.columns = [
        { title: 'Desc_Customer_Code', key: 'customerCode', isSortable: true, width: '35%' },
        { title: 'Desc_Customer_Name', key: 'customerName', isSortable: true, width: '35%' },
        { title: 'Desc_Phone', key: 'phone', isSortable: true, width: '15%' },
        { title: 'Desc_Address', key: 'address', isSortable: true, width: '15%' }
      ];
    } else {
      this.sharedTabelData.columns = [
        { title: 'Desc_Customer', key: 'customerCodeName', isSortable: true, width: '35%' },
        { title: 'Desc_Outlet', key: 'outletCodeName', isSortable: true, width: '35%' },
        { title: 'Desc_Phone', key: 'phone', isSortable: true, width: '15%' },
        { title: 'Desc_Address', key: 'address', isSortable: true, width: '15%' }
      ];
    }
    this.selectedOraganizationIds = organizationIds;
    this.coreSession.SetTitle('Select Customers');
    this.outletsFilter.customListFilter = {
      searchFilter: '',
      page: 0,
      pageSize: 100
    };
    this.customerFilterForm.reset();
    this.query = {
      searchFilter: '',
      page: 0,
      pageSize: 100
    };
    this.customerGroupInput.setValue(this.groupId && this.groupId != -1 ? this.groupId : null);
    this.customerChannelInput.setValue(this.channelId && this.channelId != -1 ? this.channelId : null);
    this.customerSubChannelInput.setValue(this.subChannelId && this.subChannelId != -1 ? this.subChannelId : null);

    this.populateCustomers();
    return this.modalService.open(this.selectCustomerDialog, { centered: true, size: 'xl' }).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          return this.addedCustomersList;
        } else {
          return -1;
        }
      });
  }

  onAreaSelected(area) {
  }

  prepareOutletsFilter() {
    this.outletsFilter = {
      customListFilter: {
        searchFilter: '',
        page: 0,
        pageSize: 100
      },
      customerGroupId: -1,
      customerClassId: -1,
      territoryId: -1,
      selectedOraganizationIds: '',
      channelId: -1,
      subChannelId: -1,
      routeId: -1,
      customerTypeId: -1,
      showDefault: false,
      divisionId: -1,
      countryId: -1,
      stateId: -1,
      cityId: -1,
      areaId: -1,
      streetId: -1,
      getAllData: false
    };
    this.outletsFilter.customerGroupId = (!this.customerGroupInput.value || this.customerGroupInput.value === null) ? -1 : this.customerGroupInput.value;
    this.outletsFilter.customerClassId = (!this.customerClassInput.value || this.customerClassInput.value === null) ? -1 : this.customerClassInput.value;
    this.outletsFilter.channelId = (!this.customerChannelInput.value || this.customerChannelInput.value === null) ? -1 : this.customerChannelInput.value;
    this.outletsFilter.subChannelId = (!this.customerSubChannelInput.value || this.customerSubChannelInput.value === null) ? -1 : this.customerSubChannelInput.value;
    this.outletsFilter.territoryId = (!this.territoryIdInput.value || this.territoryIdInput.value === null) ? -1 : this.territoryIdInput.value;
    this.outletsFilter.routeId = (!this.routeIdlInput.value || this.routeIdlInput.value === null) ? -1 : this.routeIdlInput.value;
    this.outletsFilter.customerTypeId = (!this.customerTypeIdInput.value || this.customerTypeIdInput.value === null) ? -1 : this.customerTypeIdInput.value;
    this.outletsFilter.selectedOraganizationIds = this.selectedOraganizationIds ? this.selectedOraganizationIds.toString() : '';
    this.outletsFilter.showDefault = this.showDefault ? this.showDefault : false;
    this.outletsFilter.divisionId = this.customerFilterForm.get('divisionId').value ? this.customerFilterForm.get('divisionId').value : -1;
    this.outletsFilter.countryId = this.customerFilterForm.get('country').value ? this.customerFilterForm.get('country').value : -1;
    this.outletsFilter.stateId = this.customerFilterForm.get('state').value ? this.customerFilterForm.get('state').value : -1;
    this.outletsFilter.cityId = this.customerFilterForm.get('city').value ? this.customerFilterForm.get('city').value : -1;
    this.outletsFilter.areaId = this.customerFilterForm.get('area').value ? this.customerFilterForm.get('area').value : -1;
    this.outletsFilter.streetId = this.customerFilterForm.get('street').value ? this.customerFilterForm.get('street').value : -1;
    this.outletsFilter.customListFilter = this.query ? this.query : this.outletsFilter.customListFilter;
    this.outletsFilter.getAllData = false;
    this.outletsFilter.fillCustomerOrganizationIds = this.fillCustomerOrganizationIds;
    this.outletsFilter.isCustomerOutletDivisionHasRecords = this.isCustomerOutletDivisionHasRecords
  }
  checkCustomerOutletDivisionIsExist() {
    this.coreSession.ModalLoading.Show();
    this.customerService.checkCustomerOutletDivisionIsExist().subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.isCustomerOutletDivisionHasRecords = response.data;
          // this.transactionForm.get('transactionId').setValue(this.transactionId);
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      }, (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  isDivisionLevel() {
    return this.sessionData.getConfigurationValue(ConstantConfigurations.ControlTransactionOnDivisionLevel).toLowerCase() === "true";
  }
}
