import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MasterDataService } from "../../../../content/master-data-definition/master-data.service";
import { ConstantMessages } from "../../../models/constants/constant-message";

@Component({
  selector: "app-organization-filter",
  templateUrl: "./organization-filter.component.html",
  styleUrls: ["./organization-filter.component.css"],
})
export class OrganizationFilterComponent implements OnInit, OnChanges {
  @Input() excludedOrganizationId = -1;
  @Input() currentFormControlName = "organizationId";
  @Input() hideLabel = false;
  @Input() parentForm: FormGroup;
  @Input() emitIdAndCaption = false;
  @Input() showCompanyOrganizationsOnly = false;
  @Input() showDistributorsOrganizationsOnly = false;
  @Input() labelDescription = "Desc_Organization";
  @Input() isMultiSelect = false;
  @Output() selectedOrganization = new EventEmitter();
  @Output()
  onChangeIdCaptionOrganization = new EventEmitter();
  organizationsList: any[];
  isOrganizationLoading = false;
  isRequired = false;
  dropdownOrganizationIdSettings = {
    singleSelection: false,
    idField: 'organizationId',
    textField: 'organizationCodeName',
    selectAllText: '',
    unSelectAllText: '',
    position: 'top',
    allowSearchFilter: true,
    searchPlaceholderText: '',
    itemsShowLimit: 1
  };
  constructor(private masterDataService: MasterDataService,
    private translateService: TranslateService) { }
  ngOnInit() {
    if (!this.isMultiSelect) {
      if (this.parentForm.get(this.currentFormControlName).validator != null) {
        const validator = this.parentForm
          .get(this.currentFormControlName)
          .validator({} as AbstractControl);
        if (validator && validator.required) {
          this.isRequired = true;
        }
      }
    } else {
      if (this.parentForm.get(this.currentFormControlName).validator != null) {
        const validator = this.parentForm
          .get(this.currentFormControlName)
          .validator({} as AbstractControl);
        if (validator && validator.required) {
          this.isRequired = true;
        }
      }
      this.dropdownOrganizationIdSettings.selectAllText = this.translateService.instant(ConstantMessages.SelectAllCaption);
      this.dropdownOrganizationIdSettings.unSelectAllText = this.translateService.instant(ConstantMessages.UnSelectAllCaption);
      this.dropdownOrganizationIdSettings.searchPlaceholderText = this.translateService.instant(ConstantMessages.SearchCaption);
    }
  }
  ngOnChanges() {
    this.populateorganizationsList();
  }
  populateorganizationsList() {
    this.isOrganizationLoading = true;

    if (this.showDistributorsOrganizationsOnly) {
      this.masterDataService
        .getDistributorOrganizations()
        .subscribe((result) => {
          this.organizationsList = result;
          this.fillOrganizationSelection();
          this.isOrganizationLoading = false;
        });
    } else {
      this.masterDataService
        .getOrganizationListExcludeOrganizationId(this.excludedOrganizationId)
        .subscribe((result) => {
          this.organizationsList = result;
          this.fillOrganizationSelection();
          this.isOrganizationLoading = false;
        });
    }
  }
  fillOrganizationSelection() {
    if (this.isMultiSelect) {
      if (this.parentForm.get(this.currentFormControlName).value != undefined && this.parentForm.get(this.currentFormControlName).value != null) {
        let org = []
        org = this.organizationsList.filter(x => x.organizationId === this.parentForm.get(this.currentFormControlName).value.find(o => o == x.organizationId)
        )
        this.parentForm.get(this.currentFormControlName).setValue(org)
      }
    }
  }
  onChangeOrganization(selectedOrganization: any) {
    if (!this.emitIdAndCaption)
      this.selectedOrganization.emit(selectedOrganization);
    else {
      let event = {
        id: selectedOrganization.organizationId,
        caption: selectedOrganization.organizationCodeName,
      };
      this.onChangeIdCaptionOrganization.emit(event);
    }
  }
}
