export enum MasterDataScreens {
    Employees = 1,
    Territories = 2,
    Customers = 3,
    Devices = 4,
    Items = 5,
    Vehicles = 6
}

export enum MasterDataStatus {
    Activate = 1,
    Deactivate = 2
}
