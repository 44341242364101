import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from '../../../../core/core.session';
import { ResponseModel } from '../../../models/api-models/api-models';
import { GeneralFilter } from '../../../models/general/generalFilter.model';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  private url = '';
  private httpOptions;

  constructor(
    private http: HttpClient,
    private coreSession: CoreSession,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.url = baseUrl + 'Warehouse';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  getFreeTrucks(truckFilter: any) {
    const urlValue: string = this.url + '/GetFreeTrucks';
    return this.http.post<ResponseModel>(urlValue, truckFilter).map(response => {
      return <any>response.data;
    });
  }
  getWarehouses(warehouseFilter: any) {
    const urlValue: string = this.url + '/GetWarehouses';
    return this.http
      .post<ResponseModel>(urlValue, warehouseFilter)
      .map(response => {
        return <any>response.data;
      });
  }
  //#region [StockConutingTask]
  // getStockCountingTasksStatus() {
  //   const urlValue: string = this.url + '/GetStockCountingTasksStatus';
  //   return this.http
  //     .get<ResponseModel>(urlValue
  //     )
  //     .map(response => {
  //       return <any>response.data;
  //     });
  // }
  // getStockCountingTasksSharedTableModel(stockCountingTasksFilter: any) {

  //   const urlValue: string = this.url + '/GetStockCountingTasksSharedTableModel';
  //   return this.http
  //     .post<ResponseModel>(urlValue, stockCountingTasksFilter)
  //     .map(response => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     });
  // }
  // SaveStockCountingTask(stockCountingTask: any) {
  //   const urlValue: string = this.url + '/InsertStockCountingTask';
  //   (urlValue);
  //   return this.http.post(urlValue, JSON.stringify(stockCountingTask), this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }
  // deleteStockCountingTask(warehouseId: number) {
  //   const urlValue: string = this.url + '/DeleteStockCountingTask';
  //   return this.http.post(urlValue, warehouseId, this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }
  // getStockCountingTaskItems(stockCountingTaskID: number) {
  //   const urlValue: string = this.url + '/GetStockCountingTaskItems';
  //   return this.http.post(urlValue, stockCountingTaskID).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }
  //#region
  getDestinationWarehouses(sourceWarehouseId: number) {
    const urlValue: string = this.url + '/GetDestinationWarehouses';
    return this.http
      .post<ResponseModel>(
        urlValue, sourceWarehouseId
      )
      .map(response => {
        return <any>response.data;
      });
  }
  getAllTrucks() {
    const urlValue: string = this.url + '/GetTrucks';
    return this.http
      .get<ResponseModel>(
        urlValue
      )
      .map(response => {
        return <any>response.data;
      });
  }
  getDamagedWarehouses() {
    const urlValue: string = this.url + '/GetDamagedWarehouses';
    return this.http
      .get<ResponseModel>(urlValue).map(response => {
        return <any>response.data;
      });
  }
  getSourceWarehouses(destinationWarehouseId: number) {
    const urlValue: string = this.url + '/GetSourceWarehouses';
    return this.http
      .post<ResponseModel>(
        urlValue, destinationWarehouseId
      )
      .map(response => {
        return <any>response.data;
      });
  }


  getWarehousesListSharedTable(filter: any) {
    const urlValue: string = this.url + '/GetWarehousesListSharedTable';
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(
        (response) => {
          const result = new ResponseModel();
          result.data = response['data'];
          result.message = response['message'];
          result.status = response['status'];
          return result;
        });
  }
  getWarehouseTypes(excludeCustomerVirtualActualWarehouses: boolean) {
    const urlValue: string = this.url + '/GetWarehouseTypes';
    let warehouseType = {
      excludeCustomerVirtualActualWarehouses: excludeCustomerVirtualActualWarehouses
    }
    return this.http.post<ResponseModel>(
      urlValue, warehouseType
    ).map(response => {
      return response.data;
    });
  }
  getWarehouseData(warehouseId: number, warehouseTypeId: number) {
    const urlValue = this.url + '/GetWarehouseData';
    let warehouseModel = {
      warehouseId: warehouseId,
      warehouseTypeId: warehouseTypeId
    }
    return this.http
      .post<ResponseModel>(urlValue, warehouseModel)
      .map(response => {
        return response;
      });
  }
  saveWarehouse(warehouse: any, isEditMode: boolean) {
    const urlValue: string = this.url + (isEditMode ? '/UpdateWarehouse' : '/InsertWarehouse');
    return this.http.post(urlValue, JSON.stringify(warehouse), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  deleteWarehouse(warehouseId: number) {
    const urlValue: string = this.url + '/DeleteWarehouse';
    return this.http.post(urlValue, warehouseId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }


  deactivateWarehouse(warehouseId: number) {
    const urlValue: string = this.url + '/UpdateWarehouseFromActiveToInactive';
    return this.http.post(urlValue, warehouseId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
  activateWarehouse(warehouseId: number) {
    const urlValue: string = this.url + '/UpdateWarehouseFromInactiveToActive';
    return this.http.post(urlValue, warehouseId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  GetCurrentStock(itemsFilter: GeneralFilter) {
    const urlValue: string = this.url + "/GetCurrentStock";
    return this.http.post(urlValue, JSON.stringify(itemsFilter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }
}
