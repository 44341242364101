import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { SessionDataProvider } from '../../core/session-data-provider.service';
import { ConstantConfigurations } from '../models/constants/constant-configuration';

@Pipe({name: 'numberOfDigitsRounder'})
export class NumberOfDigitsRounder implements PipeTransform {

  constructor(
    private sessionData: SessionDataProvider
  ){}
  number = +this.sessionData.getConfigurationValue(ConstantConfigurations.NumberOfDigits) ;
  transform(value): any {
    if(value){
      return formatNumber(parseFloat(this.formatNumeric(value, this.number)) , 'en' , '1.' + this.number + '-' + this.number ) ;
    }else{
      return formatNumber( 0 , 'en' , '1.' + this.number + '-' + this.number  ) ;

    }
  }
  formatNumeric(number, decimalPlaces) {

    if (isNaN(number)) {
      number = "0";
    }
    const factor = Math.pow(10, decimalPlaces);
    const adjustedNumber = Number((number * factor).toFixed(8));
    const truncatedNumber = Math.trunc(adjustedNumber);
    const decimalPart = adjustedNumber - truncatedNumber;
    const roundedDecimalPart = decimalPart >= 0.5 ? 1 : 0;
    const roundedNumber = (truncatedNumber + roundedDecimalPart) / factor;
    return roundedNumber.toString();
  }
}
