import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { EmployeeDeviceService } from '../../../../content/master-data-definition/employee-managment/device/employee-device.service';
import { MasterDataService } from '../../../../content/master-data-definition/master-data.service';

@Component({
  selector: 'app-device-filter',
  templateUrl: './device-filter.component.html',
  styleUrls: ['./device-filter.component.css']
})
export class DeviceFilterComponent implements OnInit, OnChanges {
  @Input () parentForm: FormGroup;
  @Input () organizationId : number;
  @Input () getFreeDevicesOnly = false;
  @Output() selectedevice = new EventEmitter();
  devicesList: any[];
  isDevicesLoading = false;
  isRequired = false;

  constructor(private employeeDeviceService: EmployeeDeviceService) {}

  ngOnInit() {
    if(this.parentForm.get('deviceSerial').validator != null){
      const validator = this.parentForm.get('deviceSerial').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
  }
  ngOnChanges(){
    this.populateDevicesList();
  }
  populateDevicesList(){
    this.isDevicesLoading = true;
    this.devicesList = [];
    let deviceFilter = {
      employeeId : -1,
      organizationId : this.organizationId ? this.organizationId : -1,
      getFreeDevicesOnly : false
    }
    this.employeeDeviceService.getDevices(deviceFilter).subscribe(result => {
    this.devicesList = result;
    this.isDevicesLoading = false;
  });
 }
  onChangeDevice(selectedDevice:any) {
    this.selectedevice.emit(selectedDevice)
  }
}

