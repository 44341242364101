import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomerClassModel, CustomerSubClassModel } from '../../../models/customer/customer-class.model';
import { EntryFormTypes } from '../../../models/enums/entry-form-types.enum';
import { IDateTimePickerProperties } from '../../../models/dateTimeModel/date-time-properties.interface';
import { DateTimeModel } from '../../../models/dateTimeModel/date-time.model';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { DatePipe } from '@angular/common';
import { CoreSession } from '../../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { FormProcessMode } from '../../../models/enums/form-process-mode.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { WarehouseStockService } from '../../../components/items/warehouse-stock-service';

@Component({
  selector: 'app-add-new-batch',
  templateUrl: './add-new-batch.component.html',
  styleUrls: ['./add-new-batch.component.css'],
  providers: [DatePipe]
})
export class AddNewBatchComponent implements OnInit {
  parentForm: FormGroup = new FormGroup({});
  @Input() formProcessMode: FormProcessMode;
  @Input() detailsWarehouseId = -1;
  @Input() isEditMode = false;
  @Input() isViewMode = false;
  @Input() packObj: any;
  @Input() saveEvent: Observable<void>;
  @Input() openedFromItemScreen = false;
  @Output() onAddingNewBatchEvent = new EventEmitter();
  filledQtyColor = '#f8f9fa';
  saveBtnSubscription: any;
  addedBatch: any = {

  };
  expiryDateProperties: IDateTimePickerProperties = {
    label: 'Desc_ExpiryDate',
    formControlName: 'expiryDate',
    isCalendarOnly: true

  };
  desiredExpiryDate: DateTimeModel = new DateTimeModel();
  batchNo: string = '';
  blockManualInsertionForBatchNo = false;
  numberOfStockDigits = 2;
  enableAddItemByLiters = false;
  invalidQtyValue = false;

  constructor(private sessionData: SessionDataProvider,
    private coreSession: CoreSession,
    private datepipe: DatePipe,
    private translateService: TranslateService,
    private warehouseStockService: WarehouseStockService) { }


  ngOnInit() {
    this.subscribeSaveClick();
    this.numberOfStockDigits = +this.sessionData.getConfigurationValue(ConstantConfigurations.NumberOfStockDigits);
      if (this.formProcessMode === FormProcessMode.InputVoucher || this.formProcessMode === FormProcessMode.OutputVoucher
      || this.formProcessMode === FormProcessMode.LoadRequest || this.formProcessMode === FormProcessMode.OffLoad) {
      this.enableAddItemByLiters = true;
    }
    this.fillFormData();
    if (this.sessionData.getConfigurationValue(ConstantConfigurations.BlockManuallyBatchInsertion).toLowerCase() === "true"){
      this.blockManualInsertionForBatchNo = true;
      this.parentForm.get('batchNo').disable();
    }
  }
  fillFormData() {
    this.parentForm = new FormGroup({
      batchNo: new FormControl(
        { value: this.batchNo.replace("'", "''").toUpperCase(), disabled: this.isViewMode },
        this.isEditMode ? null : Validators.required
      ), expiryDate: new FormControl(
        { value: this.desiredExpiryDate.date, disabled: this.isViewMode },
        this.isEditMode ? null : Validators.required
      ), quantity: new FormControl(
        { value: '', disabled: (this.isViewMode || (this.packObj.requiredByLiter && this.enableAddItemByLiters)) },
        this.isEditMode ? null : Validators.required
      ),
      startReading: new FormControl({ value: '', disabled: this.isViewMode }),
      endReading: new FormControl({ value: '', disabled: this.isViewMode })
    });
  }

  startEndReadingChanged() {
    var qty = 0;
    this.invalidQtyValue = false;
    if (+this.endReadingControl.value >= +this.startReadingControl.value) {
      qty = +this.endReadingControl.value - +this.startReadingControl.value;
    } else {
      this.invalidQtyValue = true;
    }
    this.parentForm.controls.quantity.setValue(qty);
    this.quantityControl.value.setValue(qty);
  }
  addNewBatch() {
    this.coreSession.markFormGroupTouched(this.parentForm);
    if (this.parentForm.invalid) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    } else if (this.openedFromItemScreen && (!this.parentForm.get('quantity').value || +this.parentForm.get('quantity').value === 0)) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgEnterQuantity));
      return;
    }

    this.addedBatch.itemId = this.packObj.itemId;
    this.addedBatch.packId = this.packObj.packId;
    this.addedBatch.batchNo = this.parentForm.value.batchNo;
    this.addedBatch.expiryDateModel = this.desiredExpiryDate;
    this.addedBatch.availableQtyInPcs = 0;
    this.addedBatch.actualAvailableQtyInPcs = 0;
    this.addedBatch.isNewBatch = true;
    this.addedBatch.requiredQty = +this.quantityControl.value;//this.parentForm.value.quantity;
    if(this.packObj.requiredByLiter){
      this.addedBatch.startReading = +this.startReadingControl.value;
      this.addedBatch.endReading = +this.endReadingControl.value;
    }
    if (this.addedBatch.requiredQty > 0) {
      this.addedBatch.backGroundColor = this.filledQtyColor;
    }
    let index = this.packObj.itemStockBatches.findIndex(
      i =>
        i.itemId === this.addedBatch.itemId &&
        i.batchNo === this.addedBatch.batchNo &&
        i.expiryDateModel.year == this.addedBatch.expiryDateModel.year &&
        i.expiryDateModel.month == this.addedBatch.expiryDateModel.month &&
        i.expiryDateModel.day == this.addedBatch.expiryDateModel.day
    );
    let selectedBatchesIndex = this.packObj.selectedBatches.findIndex(
      i =>
        i.itemId === this.addedBatch.itemId &&
        i.batchNo === this.addedBatch.batchNo &&
        i.expiryDateModel.year == this.addedBatch.expiryDateModel.year &&
        i.expiryDateModel.month == this.addedBatch.expiryDateModel.month &&
        i.expiryDateModel.day == this.addedBatch.expiryDateModel.day
    );
    if (index == -1) {

      this.packObj.itemStockBatches.push(this.addedBatch);
      this.addedBatch.allowDeleteNewBatch = true;

    } else {
      if (this.openedFromItemScreen) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgBatchExist));
        return;
      } else {
        if (this.addedBatch.requiredQty > 0) {
          this.packObj.itemStockBatches[index].backGroundColor = this.filledQtyColor;
          this.packObj.itemStockBatches[index].requiredQty = +this.addedBatch.requiredQty;
        }
      }
    }
    if (selectedBatchesIndex == -1) {
      this.packObj.selectedBatches.push(this.addedBatch);

    } else {
      if (this.addedBatch.requiredQty > 0) {
        this.packObj.selectedBatches[selectedBatchesIndex].backGroundColor = this.filledQtyColor;
        this.packObj.selectedBatches[selectedBatchesIndex].requiredQty = +this.addedBatch.requiredQty;
      }
    }
    if (this.openedFromItemScreen && this.formProcessMode === FormProcessMode.InputVoucher) {
      // call api to set WH qty for the added bacth if it exist before in warehouse stock
      this.addedBatch.warehouseId = this.detailsWarehouseId;
      this.getAvailableQtyFromSpecificBatch();
    } else {
      this.onAddingNewBatchEvent.emit();
    }
  }
  onDesiredExpiryDateSelected(date) {
    this.desiredExpiryDate = date;
    let batchNoFormat = this.sessionData.getConfigurationValue(ConstantConfigurations.BatchNumberFormat);
    if (batchNoFormat && batchNoFormat != '' && batchNoFormat != "No Format" && batchNoFormat != "NoFormat") {
      if (this.desiredExpiryDate && this.desiredExpiryDate.date)
        this.batchNo = this.datepipe.transform(this.desiredExpiryDate.date, batchNoFormat);
      else
        this.batchNo = '';
    }
    else {
      this.batchNo = this.datepipe.transform(this.desiredExpiryDate.date, this.coreSession.BatchNoFormat);
    }
    this.fillFormData();
  }

  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.saveEvent.subscribe(() => {
      this.addNewBatch();
    });
  }


  getAvailableQtyFromSpecificBatch() {
    this.coreSession.ModalLoading.Show();
    this.warehouseStockService.getAvailableQtyFromSpecificBatch(this.addedBatch).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.addedBatch.warehouseAvailableQtyInPCs = response.data;
          this.addedBatch.availableQtyInPcs = response.data;
          this.onAddingNewBatchEvent.emit();
        } else {
          this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }
  get startReadingControl() {
    return this.parentForm.get('startReading');
  }
  get endReadingControl() {
    return this.parentForm.get('endReading');
  }
  get quantityControl() {
    return this.parentForm.get('quantity');
  }
}
