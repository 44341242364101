import { Injectable } from "@angular/core";
import { AuthService } from "../../core/auth/auth.service";
import { ConstantStorage } from "../../shared/models/constants/constant-stroage";
import { CoreSession } from "../../core/core.session";
import { Router } from "@angular/router";
import { ConstantURLs } from "../../shared/models/constants/constant-URL";

const MINUTES_UNITL_AUTO_LOGOUT = 30; // in Minutes
const CHECK_INTERVALL = 60000; // in ms

@Injectable()
export class AutoLogoutService {
  taskId;

  constructor(
    private auth: AuthService,
    private coreSession: CoreSession,
    private router: Router
  ) {
    this.resetLastAction();
    this.checkForIdleTime();
    this.initInterval();
    this.startWatchingLocalStorage();
  }

  get lastAction() {
    return parseInt(localStorage.getItem(ConstantStorage.LastAction));
  }
  set lastAction(value) {
    localStorage.setItem(ConstantStorage.LastAction, value.toString());
  }

  initInterval() {
    this.taskId = setInterval(() => {
      this.checkForIdleTime();
    }, CHECK_INTERVALL);
  }

  resetLastAction() {
    this.lastAction = Date.now();
  }

  checkForIdleTime() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    let isAuthenticated = this.auth.isAuthenticated();
    if (isTimeout && isAuthenticated) {
      this.auth.logout();
    }
  }

  stopInterval() {
    clearInterval(this.taskId);
  }

  private startWatchingLocalStorage(): void {
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      const token = localStorage.getItem(ConstantStorage.TokenName);
      if (token == undefined) {
        this.coreSession.SetTitle("Login");
        this.router.navigate(["/" + ConstantURLs.loginURL]);
      }
    }
  }
}
