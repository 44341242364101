import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, range } from 'rxjs';
import { CoreSession } from '../../../../core/core.session';
import { SessionDataProvider } from '../../../../core/session-data-provider.service';
import { ConstantMessages } from '../../../models/constants/constant-message';
import * as cloneDeep from 'lodash/cloneDeep';
import { ItemPackModel } from '../../../models/Item/item.model';
import { SalesTransactionTypes } from '../../../models/enums/sales-transaction-types';
import { iterator } from 'rxjs/internal-compatibility';
import { ConstantConfigurations } from '../../../models/constants/constant-configuration';
import { CalculationService } from '../../../services/calculations.service';
import { PromotionOutputDetailTypes } from '../../../models/enums/promotion-output-detail-types.enum';
import { ReturnBenefitCalculationMode } from '../../../models/enums/benefitCalculationMode.enum';

@Component({
  selector: 'app-after-return-promotion',
  templateUrl: './after-return-promotion.component.html',
  styleUrls: ['./after-return-promotion.component.css']
})
export class AfterReturnPromotionComponent implements OnInit , OnDestroy{
  @Input('promotionRanges') promotionRanges: any[] = [];
  @Input('saveSubject') saveSubject: Observable<string>;
  @Input('allTransactionsIncludedInBenefit') allTransactionsIncludedInBenefit: any[] = [];
  @Input('customerItemBatchesList') customerItemBatchesList: any[] = [];
  @Input('soldItems') soldItems: any[] = [];
  @Input('returnBenefitMode') returnBenefitMode: ReturnBenefitCalculationMode = ReturnBenefitCalculationMode.None;
  @Input('productDiscountPromotions') productDiscountPromotions: any[] =[];
  @Input('prevProductDiscountItems') prevProductDiscountItems: any[] =[];
  @Input('allItemsReturnedAsDisc') allItemsReturnedAsDisc: any[] = [];
  @Input('productDiscountCalculationMode') productDiscountCalculationMode: number = -1;


  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() onSaveCompleted: EventEmitter<any> = new EventEmitter();
  
  saveBtnSubscription: any;
  benefitTypeForm: FormGroup;
  filledQtyColor = '#f8f9fa'; // filled qty color
  noColor = "#FFF"; // no color
  exceedAvlColor = "#FFE4B5";  // exceed available qty color
  forcedToReturnItemBatches: any[] = []; // For Quantity Returns
  forcedToReturnPackLevel: any[] = []; // For Quantity Returns
  forcedDiscountItemBatches: any[] = []; // For Discount Returns
  // tempForcedDiscountItemBatches: any[] = [];
  copyOfAllTransactionsIncludedInBenefit = [];
  applyRoundingPerLine: boolean = false;
  allPacksDiscount: any[] = [];
  allowConversion:boolean = false;
  itemsReturnedAsDiscount: any[] =[];
  prodDiscountItemsList:any [] = [];
  tempForcedToReturnPack: any = [];
  addToAllDiscountFromBenefits:any[] = [];
  rangesList: any[] = [];
  includeTaxInProductDiscount: boolean = false;
  returnProdDiscBenefitHistory: any[] = [];
  constructor(
    public coreSession: CoreSession,
    public sessionData: SessionDataProvider,
    private translateService: TranslateService,
    private calculationService: CalculationService
  ) { }

  ngOnInit(): void {
    this.applyRoundingPerLine = this.sessionData.getConfigurationValue(ConstantConfigurations.ApplyRoundingOnCalculations).toLowerCase() === "true";
    this.allowConversion = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowConversionInPromotion).toLowerCase() === "true";
    this.includeTaxInProductDiscount = this.sessionData.getConfigurationValue(ConstantConfigurations.IncludeTaxInProductDiscount).toLowerCase() === "true";
    this.copyOfAllTransactionsIncludedInBenefit = cloneDeep(this.allTransactionsIncludedInBenefit);
    this.initForm();
    this.prepareBenefitsForView();
    this.subscribeSaveBtn();
  }

  addToProductDiscountItems (
    prodDiscBenefitPackId,
    prodDiscBenefitItemId,
    prodDiscBenefitPGId,
    prodDiscQty,
    prerequisitesList,
    promotionId,
    benefitType,
    discountAmount,
    piecesInPack,
    basePrice,
    transactionId,
    taxPerc,
    rangeId:any,
    batchNo:any,
    expiryDateModel:any,
    range,
    returnValueInPcs
   ) {
     // This list contains all the benefit items returned from ProductDiscount Promotioms
    var idx = this.prodDiscountItemsList.findIndex(i => 
      i.benefitItemId === prodDiscBenefitItemId &&
      i.benefitPackId === prodDiscBenefitPackId &&
      i.benefitPackGroupId === prodDiscBenefitPGId &&
      i.promotionId === promotionId &&
      i.benefitType === benefitType &&
      i.transactionId === transactionId && 
      i.rangeId === rangeId
    );
    if(idx === -1) {
      var productDiscountModel = {
        benefitItemId: prodDiscBenefitItemId,
        benefitPackId: prodDiscBenefitPackId,
        benefitPackGroupId: prodDiscBenefitPGId,
        promotionId: promotionId,
        productDiscountQty: prodDiscQty,
        benefitType: benefitType, // 1 -> Qty , 2 -> Discount
        basePrice: basePrice,
        satisfiedPrerequisitesDetails: prerequisitesList,
        piecesInPack: piecesInPack,
        totalDiscountAmountOnItem: basePrice, 
        transactionId: transactionId,
        taxPercentage: taxPerc,
        discountAmount: 0,
        rangeId: rangeId,
        returnValueInPcs: returnValueInPcs,
        productDiscountDetails: this.getProductDiscountDetails(range,prodDiscBenefitItemId,prodDiscBenefitPackId,benefitType,prodDiscBenefitPGId),
        benefitOptionDetailTypeId: range.benefitsToReturn && range.benefitsToReturn.length > 0 ? range.benefitsToReturn[0].benefitOptionDetailTypeId : -1,
        rangeTransactionIds: range.rangeTransactionIds
      };
      this.prodDiscountItemsList.push(productDiscountModel);
    } 
  }

  prepareBenefitsForView() {
    this.promotionRanges.forEach(p => {
      if (p.benefitsToReturn[0].packGroupId > -1) {
        p.benefitsToReturn.forEach(b => {
          b.remainingBenefitAmount = b.returnValue;
        });
        if(
            p.benefitsToReturn[0].benefitOptionDetailTypeId === PromotionOutputDetailTypes.DiscountPercentageForGroupItems.valueOf() ||
            (p.benefitsToReturn[0].benefitOptionDetailTypeId === PromotionOutputDetailTypes.DiscountPercentageOrQtyOverGroupWithSameAmount.valueOf() && (!p.benefitsToReturn[0].allowedPacksFromPackGroup || p.benefitsToReturn[0].allowedPacksFromPackGroup.length == 0))
          ) {
          this.applyDiscountForDiscountRange(p);
        } 
        else if(p.isDiscount && !p.isDiscountCalculated) { // discount PackGroup-Level
          this.applyDiscountPackGroupLevel(p);
        }
      } else if (p.benefitsToReturn[0].packId > -1) { // Pack-Level
        if(p.benefitsToReturn[0].benefitOptionDetailTypeId === PromotionOutputDetailTypes.DiscountPercentageForItem.valueOf()) {
          this.applyDiscountForDiscountRange(p);
        } else if (p.isDiscount && !p.isDiscountCalculated) { // discount Pack-Level
          this.applyDiscountPackLevel(p);
        }
      } else if (
        p.benefitsToReturn[0].benefitOptionDetailTypeId === PromotionOutputDetailTypes.AllItemDiscountPercentage.valueOf()
        ) {
        this.applyDiscountForDiscountRange(p);
      }
    });
  }

  initForm() {
    this.benefitTypeForm = new FormGroup({
      isDiscount: new FormControl(),
    });
  }

  subscribeSaveBtn() {
    this.saveBtnSubscription = this.saveSubject.subscribe((string) => {
      if(string === "save") {
        this.onSaveClicked();
      } else if (string === "cancel") {
        this.onBackClicked()
      }
    });
  }
  
  onBackClicked() {
    this.cancel.emit();
  }

  onSaveClicked() {
    this.allTransactionsIncludedInBenefit = cloneDeep(this.copyOfAllTransactionsIncludedInBenefit);
    this.forcedToReturnItemBatches = [];
    this.rangesList = [];
    var promotions = this.promotionRanges;
    var errorMessage = "";    
    var isValid = true;
    var allDiscountFromBenefits = [];
    promotions.forEach(range => {
      if(!isValid) return;
      if(!range.benefitNotReturned) this.addToRangesList(range);
      if (range.benefitsToReturn[0].packGroupId > -1) {// range is on packGroup level
        if(!range.isDiscount)  {
          var result = this.validatePackGroupRange(range);
          if(result.notEnoughAvlQty || result.remainingReturnAmountWarning) {
            if(result.notEnoughAvlQty) { // Qty benefit
              errorMessage =  this.translateService.instant(ConstantMessages.MsgNotEnoughQtyFromAll);
            } else {
              errorMessage = this.translateService.instant(ConstantMessages.MsgFillRequiredBenefitForAllRanges);
            }
            isValid = false;
          }
        } else {  // discount benefit
          if(range.isDiscountCalculated) {
            // allDiscountFromBenefits;
            range.benefitsToReturn.map(x => x.discountItems ? allDiscountFromBenefits.push(...x.discountItems): allDiscountFromBenefits);
          } else {
            // Calculate discount....
            this.applyDiscountPackGroupLevel(range);
          }
        }
      } 
      else if(range.benefitsToReturn[0].packId > -1) { // range is on pack level
        if(!range.isDiscount) { // Qty benefit
          isValid = this.validatePackLevelRange(range);
          if(!isValid) {
            errorMessage =  this.translateService.instant(ConstantMessages.MsgNotEnoughQtyFromAll);
          }
        } else { // discount benefit
          if(range.isDiscountCalculated) {
            // allDiscountFromBenefits;
            range.benefitsToReturn.map(x => x.discountItems ? allDiscountFromBenefits.push(...x.discountItems): allDiscountFromBenefits);
          } else {
            // Calculate discount....
            this.applyDiscountPackLevel(range);
          }
        }
      } else {
        if(range.isDiscountCalculated) {
          range.benefitsToReturn.map(x => x.discountItems ? allDiscountFromBenefits.push(...x.discountItems): allDiscountFromBenefits);
        } else { // should never execute....discount for discountRange is calculated in onInit
          this.applyDiscountForDiscountRange(range); 
        }
      }
    });
    if(!isValid) {
      this.coreSession.showWarrning(
        this.translateService.instant(
          ConstantMessages.WarningCaption), 
          this.translateService.instant(errorMessage));
          return;
    } else {      
      this.forcedToReturnPackLevel.forEach(pack => {
        pack.returnFromInvoiceAvailableBatchList = this.forcedToReturnItemBatches.filter(x=> x.packId === pack.packId);
        pack.returnFromInvoiceAvailableBatchList.map(t => pack.requiredQty += t.requiredQuantity);
      });
      this.prepareListsForSaving();
      var result = {
        allDiscountFromBenefits: allDiscountFromBenefits, // discount amount distributed over prerequisites
        forcedToReturnItemBatches: this.forcedToReturnItemBatches && this.forcedToReturnItemBatches.length > 0? this.forcedToReturnItemBatches : [], // forced to return quantity benefits [on batch level]
        forcedToReturnPackLevel: this.forcedToReturnPackLevel && this.forcedToReturnPackLevel.length > 0? this.forcedToReturnPackLevel : [],// forced to return quantity benefits [on pack level]
        forcedDiscountItemBatches: this.forcedDiscountItemBatches && this.forcedDiscountItemBatches.length > 0?this.forcedDiscountItemBatches:[], // forced to return quantity benefits as discount [on batch level]
        benefitMode: this.returnBenefitMode, 
        prodDiscountItemsList: this.prodDiscountItemsList, // returned product discount benefit items
        tempForcedToReturnPack: this.tempForcedToReturnPack,// forced to return quantity benefits as discount [on pack level]
        rangesList: this.rangesList, // promotion ranges in the UI
        returnProdDiscBenefitHistory: this.returnProdDiscBenefitHistory // returned product discount benefit quantity per promotion,item,pack
      }
      this.onSaveCompleted.emit(result);
    }
  }

  validatePackLevelRange(range:any) {
    var isValid = true;
    range.benefitsToReturn.forEach(packLevelBenefit => {
      this.addToReturnProdDiscBenefitHistory(range.promotionId,packLevelBenefit.promotionOptionId,packLevelBenefit.promotionOptionDetailId,
        packLevelBenefit.packId, packLevelBenefit.itemId,packLevelBenefit.packGroupId,packLevelBenefit.returnValue);
      this.addToForcedToReturnPacks(packLevelBenefit);
      packLevelBenefit.requiredQty = packLevelBenefit.returnValue;
      var totalRequiredReturnQtyInPcs = packLevelBenefit.returnValue * packLevelBenefit.piecesInPack;

      range.rangeTransactionIds.forEach(transactionId => {
        var allItemBatchesPerTrans = this.allTransactionsIncludedInBenefit.filter(b => 
          b.transactionId === transactionId && b.itemId === packLevelBenefit.itemId);

        while(allItemBatchesPerTrans.length > 0 && totalRequiredReturnQtyInPcs > 0) {
          var txObj = this.getItemBatchWithMaxQty(allItemBatchesPerTrans);
          if(txObj.availableQtyInPcs > 0) {
            var takenValue = 0;
            if(totalRequiredReturnQtyInPcs <=  txObj.availableQtyInPcs) {
              txObj.availableQtyInPcs -= totalRequiredReturnQtyInPcs;
              txObj.totalRequiredReturnQtyInPcs += totalRequiredReturnQtyInPcs;
              takenValue = totalRequiredReturnQtyInPcs;
              totalRequiredReturnQtyInPcs = 0;
            } else {
              totalRequiredReturnQtyInPcs -= txObj.availableQtyInPcs;
              txObj.totalRequiredReturnQtyInPcs += txObj.availableQtyInPcs;
              takenValue = txObj.availableQtyInPcs;
              txObj.availableQtyInPcs = 0;
            }
            this.addToForcedToReturnItemBatches(txObj,packLevelBenefit,takenValue);
          }
        }   
      });
      if(totalRequiredReturnQtyInPcs > 0) {
        range.isValid = false;
        isValid = false;
        return;
      }
    });
    return isValid;
  }


  addBatchToItemPack(txObj: any,packId:number,piecesInPack:number) {
    var idx = this.forcedToReturnPackLevel.findIndex(i => 
      i.itemId === txObj.itemId &&
      i.packId === packId &&
      i.salesTransactionTypeId === SalesTransactionTypes.None
    );
    if(idx > -1) {
      txObj.requiredQuantity = txObj.totalRequiredReturnQtyInPcs / piecesInPack;
      this.forcedToReturnPackLevel[idx].returnFromInvoiceAvailableBatchList.push(txObj);
      // gross += ..... etc [packTotals]
    }
  }

  addToForcedToReturnItemBatches(txObj: any, packLevelBenefit: any, takenValue: number) {
    // This list contains all the benefit items returned as quantity [Batch-level]
    var i = this.forcedToReturnItemBatches.findIndex(t => 
      t.transactionId === txObj.transactionId &&
      t.batchNo === txObj.batchNo &&
      t.expiryDateModel.day === txObj.expiryDateModel.day &&
      t.expiryDateModel.month === txObj.expiryDateModel.month &&
      t.expiryDateModel.year === txObj.expiryDateModel.year &&
      t.expiryDateModel.date === txObj.expiryDateModel.date &&
      t.itemId === packLevelBenefit.itemId &&
      t.packId === packLevelBenefit.packId 
    );

    var batchObj = {
      transactionId: txObj.transactionId,
      batchNo: txObj.batchNo,
      expiryDateModel: txObj.expiryDateModel,
      originalAvailableQtyFromInvoice: txObj.originalAvailableQtyInPcs,
      availableQtyFromInvoice: txObj.availableQtyInPcs,
      itemId: packLevelBenefit.itemId,
      packId: packLevelBenefit.packId,
      requiredQuantity: takenValue / packLevelBenefit.piecesInPack,
      forcedToReturn: true,
      baseExciseTax: txObj.baseExciseTax,
      basePrice: txObj.basePrice,
      baseTaxPercentage: txObj.baseTaxPercentage,
      discountPercentage: txObj.discountPercentage,
      netPrice: txObj.netPrice,
      piecesInPack: packLevelBenefit.piecesInPack
    }
    if (i > -1) {
      this.forcedToReturnItemBatches[i].requiredQuantity = txObj.totalRequiredReturnQtyInPcs / packLevelBenefit.piecesInPack;
    } else {
      this.forcedToReturnItemBatches.push(batchObj);
    }
  }

  addToReturnProdDiscBenefitHistory(promotionId:number,promotionOptionId:number, promotionOptionDetailId:number,packId:number, itemId:number,packGroupId:number,packQuantity:number) {
    var prodDiscBenefitObj = { //BenefitModel
      promotionId: promotionId,
      promotionOptionId: promotionOptionId,
      promotionOptionDetailId: promotionOptionDetailId,
      itemId: itemId,
      packId: packId,
      packGroupId: packGroupId,
      packQuantity: packQuantity,
    }
    this.returnProdDiscBenefitHistory.push(prodDiscBenefitObj);
  }

  addToForcedToReturnPacks(packLevelBenefit:any) {
    // this list contains all the benefit items returned as quantity. [Pack-level]
    var i = this.forcedToReturnPackLevel.findIndex(t => 
      t.itemId === packLevelBenefit.itemId &&
      t.packId === packLevelBenefit.packId &&
      (this.returnBenefitMode === ReturnBenefitCalculationMode.RelatedToTargetBenefit? 
      t.salesTransactionTypeId === SalesTransactionTypes.FreeReturn : t.salesTransactionTypeId === SalesTransactionTypes.None) 
      );
    if (i === -1) {
      var itemPackObj = new ItemPackModel();
      itemPackObj.itemId = packLevelBenefit.itemId;
      itemPackObj.packId = packLevelBenefit.packId;
      itemPackObj.itemCodeName = packLevelBenefit.itemCodeName;
      itemPackObj.itemCode = packLevelBenefit.itemCode; 
      itemPackObj.itemName = packLevelBenefit.itemName; 
      itemPackObj.salesTransactionTypeId = 
        this.returnBenefitMode === ReturnBenefitCalculationMode.RelatedToTargetBenefit? SalesTransactionTypes.FreeReturn :SalesTransactionTypes.None;
      itemPackObj.uom = packLevelBenefit.uomDescription;
      itemPackObj.piecesInPack = packLevelBenefit.piecesInPack;
      itemPackObj.isFromProductDiscount = this.returnBenefitMode === ReturnBenefitCalculationMode.RelatedToTargetBenefit? false: true;
      this.forcedToReturnPackLevel.push(itemPackObj);
    } 
  }

  validatePackGroupRange(range:any) {
    var result = {
      remainingReturnAmountWarning: false,
      notEnoughAvlQty: false
    }
    var remainingReturnAmountWarning = false
    range.benefitsToReturn.map(b => {
      if(this.calculationService.getNumberOnStockDigitFormat(b.remainingBenefitAmount) > 0) {
        remainingReturnAmountWarning = true;
        return;
      }
    });
    if(!remainingReturnAmountWarning) {
      range.benefitsToReturn.forEach(packGroupBenefit => {
        packGroupBenefit.allowedPacksFromPackGroup.forEach(pack => {
          pack.requiredQty = pack.originalRequiredQty;
          if(pack.requiredQty > 0) {
            this.addToReturnProdDiscBenefitHistory(range.promotionId,packGroupBenefit.promotionOptionId,packGroupBenefit.promotionOptionDetailId,
              pack.packId, pack.itemId,packGroupBenefit.packGroupId,pack.requiredQty);
            this.addToForcedToReturnPacks(pack);
            this.fillRequiredPackQtyFromTransactions(range,pack);
          }
        });
      });
    } else {
      result = {
        remainingReturnAmountWarning: true,
        notEnoughAvlQty: false
      }
    }
    return result;
    // this.allTransactionsIncludedInBenefit
  }

  

  fillRequiredPackQtyFromTransactions(range:any,pack:any) {
    var copyOfPack = cloneDeep(pack);
    // Fill required Pack Qty from latest transaction in range transactions.
    // If qty in latest transaction is not enough, fill from the second latest transaction...
    // modify transaction availableQtyInPcs = availableQtyInPcs - pack.requiredQty * pack.piecesInPack.
    range.rangeTransactionIds.map(transactionId => { 
      var avgPrice = 0;
      var weightedPrice = 0;
      var totalQty = 0;
      // Transactions are ordered by transactionDate Descending.
      // start from latest transaction, deduct pack required qty.
      if(pack.requiredQty > 0) {
        var allItemBatchesPerTrans = this.allTransactionsIncludedInBenefit.filter(x => x.transactionId === transactionId &&
          x.itemId === pack.itemId); // same transaction might have multiple batches. get batch with max qty first.
        while(allItemBatchesPerTrans.length > 0 && pack.requiredQty > 0) {
          var txObj = this.getItemBatchWithMaxQty(allItemBatchesPerTrans);
          if(txObj.availableQtyInPcs > 0) {
            var takenValue = 0;
            if(pack.requiredQty * pack.piecesInPack <= txObj.availableQtyInPcs) {
              txObj.availableQtyInPcs -= pack.requiredQty  * pack.piecesInPack;
              txObj.totalRequiredReturnQtyInPcs += pack.requiredQty * pack.piecesInPack;
              takenValue = pack.requiredQty * pack.piecesInPack;
              pack.requiredQty = 0;
            } else {
              pack.requiredQty -= txObj.availableQtyInPcs/pack.piecesInPack;
              txObj.totalRequiredReturnQtyInPcs += txObj.availableQtyInPcs;
              takenValue = txObj.availableQtyInPcs
              txObj.availableQtyInPcs = 0;
            }
            weightedPrice += txObj.basePrice * takenValue;
            totalQty += takenValue;
            this.addToForcedToReturnItemBatches(txObj,copyOfPack,takenValue);
            if(this.returnBenefitMode === ReturnBenefitCalculationMode.ProductDiscountQuantityBenefit ||
              this.returnBenefitMode === ReturnBenefitCalculationMode.ProductDiscountQuantityOrDiscountBenefit) {
                avgPrice = weightedPrice/totalQty;
                var rangeId = this.getRangeId(range);
                this.addToProductDiscountItems(pack.packId,
                  pack.itemId,
                  pack.packGroupId ? pack.packGroupId : -1 ,
                  takenValue,
                  range.rangePrerequisites,
                  range.promotionId,
                  1, // Qty
                  0,
                  pack.piecesInPack,
                  avgPrice,
                  txObj.transactionId,
                  txObj.baseTaxPercentage,
                  rangeId,
                  txObj.batchNo,
                  txObj.expiryDateModel,
                  range,
                  pack.originalRequiredQty * pack.piecesInPack
                  );
              }
          }
        }
      }
    });
    
  }

  getItemBatchWithMaxQty(allItemBatchesPerTrans:any[]) {
    var itemBatchWithMaxQty = allItemBatchesPerTrans.reduce(function(prev, current) {
      return (prev.availableQtyInPcs > current.availableQtyInPcs) ? prev : current});
    var id = allItemBatchesPerTrans.findIndex(tx =>
      tx.transactionId === itemBatchWithMaxQty.transactionId &&
      tx.itemId === itemBatchWithMaxQty.itemId &&
      tx.batchNo === itemBatchWithMaxQty.batchNo &&
      tx.expiryDateModel.day === itemBatchWithMaxQty.expiryDateModel.day &&
      tx.expiryDateModel.month === itemBatchWithMaxQty.expiryDateModel.month &&
      tx.expiryDateModel.year === itemBatchWithMaxQty.expiryDateModel.year &&
      tx.expiryDateModel.date === itemBatchWithMaxQty.expiryDateModel.date);
    // var txObj = allItemBatchesPerTrans[id];
    allItemBatchesPerTrans.splice(id,1); 

    var id = this.allTransactionsIncludedInBenefit.findIndex(tx =>
      tx.transactionId === itemBatchWithMaxQty.transactionId &&
      tx.itemId === itemBatchWithMaxQty.itemId &&
      tx.batchNo === itemBatchWithMaxQty.batchNo &&
      tx.expiryDateModel.day === itemBatchWithMaxQty.expiryDateModel.day &&
      tx.expiryDateModel.month === itemBatchWithMaxQty.expiryDateModel.month &&
      tx.expiryDateModel.year === itemBatchWithMaxQty.expiryDateModel.year &&
      tx.expiryDateModel.date === itemBatchWithMaxQty.expiryDateModel.date);

    var txObj = this.allTransactionsIncludedInBenefit[id];
    return txObj;
  }

  onPackQuantityInput(pack,benefit) {
    pack.originalRequiredQty = this.calculationService.getNumberOnStockDigitFormat(pack.originalRequiredQty);
    pack.requiredQty = pack.originalRequiredQty;
    var totalReturnValue = this.calculationService.getNumberOnStockDigitFormat(benefit.returnValue);
   if(pack && pack !== undefined) {
    // available quantity from pack is the minimum between the two following qty's: 
    // - availableQtyInPcs => (sold + promoted qty's for pack in promotion range - returned in order).
    // - original taken benefit value for pack => from PromotionBenefitHistory table.
    var avlQty = pack.availableQtyToReturn < pack.availableQtyInPcs / pack.piecesInPack ? 
    pack.availableQtyToReturn : (pack.availableQtyInPcs / pack.piecesInPack);
    
    if(avlQty < pack.requiredQty) {
      // if requiredQuantity > availableQty For pack =>
      pack.backGroundColor = this.exceedAvlColor;
      setTimeout(() => {
        pack.requiredQty = null;
        pack.originalRequiredQty = null;
        pack.backGroundColor = this.noColor;
      }, 50);
      this.coreSession.showWarrning(
        this.translateService.instant(
          ConstantMessages.WarningCaption), 
          this.translateService.instant(ConstantMessages.RequiredMoreThanAvailable));
      return;
    } else if (
      this.calculationService.getNumberOnStockDigitFormat((pack.requiredQty * pack.piecesInPack / pack.packGroupPiecesInPack)) 
       > totalReturnValue) {
      // if requiredQuantity > total required quantity to return from all packs in current packGroup =>
      pack.backGroundColor = this.exceedAvlColor;
      setTimeout(() => {
        pack.requiredQty = null;
        pack.originalRequiredQty = null;
        pack.backGroundColor = this.noColor;
      }, 50);
      this.coreSession.showWarrning(
        this.translateService.instant(
          ConstantMessages.WarningCaption), 
          this.translateService.instant(ConstantMessages.RequiredMoreThanTotalReturnForPG));
      return;
    } else if (pack.requiredQty > 0) {
      var totalAddedQtyFromAllPacks = this.validateTotalQtyFromOtherPacks(benefit);
      if(totalAddedQtyFromAllPacks > totalReturnValue) {
        setTimeout(() => {
          pack.requiredQty = null;
          pack.originalRequiredQty = null;
          pack.backGroundColor = this.noColor;
        }, 50);
        this.coreSession.showWarrning(
          this.translateService.instant(
            ConstantMessages.WarningCaption), 
            this.translateService.instant(ConstantMessages.RequiredMoreThanTotalReturnForPG));
        return;
      } else {
        // benefit.remainingBenefitAmount = benefit.returnValue - totalAddedQtyFromAllPacks;
        pack.backGroundColor = this.filledQtyColor;
      }
    } else if (pack.requiredQty < 0) {
      setTimeout(() => {
        pack.requiredQty = null;
        pack.originalRequiredQty = null;
        pack.backGroundColor = this.noColor;
      }, 50);
      this.coreSession.showWarrning(
        this.translateService.instant(
          ConstantMessages.WarningCaption), 
          this.translateService.instant('Invalid Input'));
      return;
    }
    if(pack.requiredQty == 0) {
      pack.requiredQty = null;
      pack.originalRequiredQty = null;
      pack.backGroundColor = this.noColor;
    }
   }
  }

  onPackQuantityChange(pack,benefit) {
    var totalAddedQtyFromAllPacks = this.validateTotalQtyFromOtherPacks(benefit);
    benefit.remainingBenefitAmount = this.calculationService.getNumberOnStockDigitFormat(benefit.returnValue) - totalAddedQtyFromAllPacks;
    if(benefit.remainingBenefitAmount < 0) {
      benefit.remainingBenefitAmount = benefit.returnValue;
      pack.requiredQty = null;
      pack.originalRequiredQty = null;
    }
  }
  
  validateTotalQtyFromOtherPacks(benefit) {
    var totalAddedQty = 0;
    benefit.allowedPacksFromPackGroup.map(pack => {
      pack.requiredQty ? 
      totalAddedQty += pack.requiredQty * pack.piecesInPack / pack.packGroupPiecesInPack
      : totalAddedQty += 0;
    });
    return this.calculationService.getNumberOnStockDigitFormat(totalAddedQty);
  }

  onDiscountClicked(forceDiscount) {
    if(forceDiscount) {
      this.coreSession.showWarrning(
        this.translateService.instant(
          ConstantMessages.WarningCaption), 
          this.translateService.instant(ConstantMessages.MsgNoAvailableQtyToReturn));
    }
  }

  onApplyDiscount(range) {
    if(range.isDiscount) {
      if(range.benefitsToReturn[0].packId > -1 && range.benefitsToReturn[0].packGroupId === -1) {
        // pack level.
        this.applyDiscountPackLevel(range);
      } else {
        // packGroup level.
        // Reset requiredQty for all packs.
        range.benefitsToReturn.forEach(benefit => {
          benefit.remainingBenefitAmount = benefit.returnValue;
          benefit.allowedPacksFromPackGroup.forEach(pack => {
            pack.requiredQty = 0;
            pack.originalRequiredQty = 0;
          });
        });
        this.applyDiscountPackGroupLevel(range);
      }
    } else {
      range.isDiscount = false;
      this.deleteQtyAddedAsDiscount(range);
    }
  }

  deleteQtyAddedAsDiscount(range:any) {
    var rangeId = this.getRangeId(range);
    this.forcedDiscountItemBatches = this.forcedDiscountItemBatches.filter(x => x.rangeId !== rangeId);
    this.tempForcedToReturnPack = this.tempForcedToReturnPack.filter(x => x.promotionRangeId !== rangeId);
    this.prodDiscountItemsList = this.prodDiscountItemsList.filter(x => x.rangeId !== rangeId);
  }

  prepareListsForSaving() {
    if(this.forcedDiscountItemBatches && this.forcedDiscountItemBatches.length > 0) {
      var copyOfForcedDiscountItemBatches = cloneDeep(this.forcedDiscountItemBatches);
      this.forcedDiscountItemBatches = [];
      copyOfForcedDiscountItemBatches.forEach(i => {
        var idx = this.forcedDiscountItemBatches.findIndex(b =>
          b.transactionId === i.transactionId &&
          b.batchNo === i.batchNo &&
          b.itemId === i.itemId &&
          b.expiryDateModel.day === i.expiryDateModel.day &&
          b.expiryDateModel.month === i.expiryDateModel.month &&
          b.expiryDateModel.year === i.expiryDateModel.year &&
          b.expiryDateModel.date === i.expiryDateModel.date
          );
        if(idx === -1) {
          this.forcedDiscountItemBatches.push(i);
        } else {
          this.forcedDiscountItemBatches[idx].requiredQuantity += i.requiredQuantity;
        }
      });
    }
    if(this.tempForcedToReturnPack && this.tempForcedToReturnPack.length > 0) {
      var copyOfTempForcedToReturnPack = cloneDeep(this.tempForcedToReturnPack);
      this.tempForcedToReturnPack = [];
      copyOfTempForcedToReturnPack.forEach(i => {
        var idx = this.tempForcedToReturnPack.findIndex(b =>
          b.itemId === i.itemId &&
          b.packId === i.packId &&
          b.salesTransactionTypeId === i.salesTransactionTypeId &&
          b.isFromProductDiscount === i.isFromProductDiscount
          );
        if(idx === -1) { 
          this.tempForcedToReturnPack.push(i);
        } else {
          this.tempForcedToReturnPack[idx].returnFromInvoiceAvailableBatchList = 
            this.tempForcedToReturnPack[idx].returnFromInvoiceAvailableBatchList.concat(i.returnFromInvoiceAvailableBatchList);
        }
      });
    }
    if(this.prodDiscountItemsList && this.prodDiscountItemsList.length > 0) {
      var copyOfProdDiscountItemsList= cloneDeep(this.prodDiscountItemsList);
      this.prodDiscountItemsList = [];
      copyOfProdDiscountItemsList.forEach(i => {
        var idx = this.prodDiscountItemsList.findIndex(b =>
            b.benefitItemId === i.benefitItemId &&
            b.benefitPackId === i.benefitPackId &&
            b.benefitPackGroupId === i.benefitPackGroupId &&
            b.benefitType === i.benefitType &&
            b.transactionId === i.transactionId &&
            b.promotionId === i.promotionId 
            // Add compare prerequisites to each other.
          );
        if(idx === -1) { 
          this.prodDiscountItemsList.push(i);
        } else {
          // fi else ?... yes fi else => prepare and test this case.
        } 
      });
    }
  }

//  // #[region]NEW CODE NEEDS TEST.........................
  isThereAnyPacksWithAvailableBatches(benefitsPacksList:any[], rangeTransactionIds: any[]) {
    var isTherePacks = false;
    var index = -1;
    for (var i = 0; i < benefitsPacksList.length; i++) {
      if (this.isThereAnyBatchesWithAvailableQuantity(rangeTransactionIds,benefitsPacksList[i])) {
        isTherePacks = true;
        index = i;
        break;
      }
    }
    var result = {
      isTherePacks: isTherePacks,
      index: index
    };
    return result;
  }
  subtractDiscountBenefitQuantity(requiredQtyInPcs:number,packgroupBenefit:any,range:any,discountAmount:any) {
    // when quantity benefit is returned as discount, subtract the maximum available quantity from benefit packs
    // to cover the benefitReturnValue, (or as much as possible from the benefitReturnValue).
    var forceDiscount = range.forceDiscount;
    var currentIdx = 0;
    var maxIdx = packgroupBenefit.allowedPacksFromPackGroup.length - 1;
    var rangeId = this.getRangeId(range);
    var benefitAddedToProductDiscountItems = false;
    var actualRequiredQtyInPcs = requiredQtyInPcs;
   // for product discount benefits => totalRequiredBenefitQtyPcs = benefit return value, else = 0;
    var totalRequiredBenefitQtyPcs = 
    (this.returnBenefitMode === ReturnBenefitCalculationMode.ProductDiscountQuantityBenefit ||
    this.returnBenefitMode === ReturnBenefitCalculationMode.ProductDiscountQuantityOrDiscountBenefit) ? requiredQtyInPcs : 0;

    while ((currentIdx <= maxIdx) && actualRequiredQtyInPcs > 0) {
      
      var itemBatchWithMaxQty;
      var notEnoughQty = false;
      var takenQty = 0;
      var actualTakenQty = 0;
      var pack = packgroupBenefit.allowedPacksFromPackGroup[currentIdx];
      if(this.isThereAnyBatchesWithAvailableQuantity(range.rangeTransactionIds,pack)) { 
        itemBatchWithMaxQty = this.getPriceForDiscount(range.rangeTransactionIds,pack,true);
        if(itemBatchWithMaxQty) {
          var actualAvailableQtyInPcs =  itemBatchWithMaxQty.availableQtyInPcs;
          if(actualAvailableQtyInPcs >= actualRequiredQtyInPcs) {
            actualTakenQty = actualRequiredQtyInPcs;
            actualRequiredQtyInPcs = 0;
          } else { 
            actualRequiredQtyInPcs -= actualAvailableQtyInPcs;
            actualTakenQty = actualAvailableQtyInPcs;
            notEnoughQty = true;
          }
          itemBatchWithMaxQty.availableQtyInPcs -= actualTakenQty;
          if(actualTakenQty > 0) {
            this.addToForcedDiscountItemBatches(itemBatchWithMaxQty,pack.itemId, pack.packId,pack.piecesInPack,packgroupBenefit.returnValue,discountAmount,actualTakenQty,rangeId);
            var copyOfBatch = itemBatchWithMaxQty;
            this.addToTempForcedToReturnPacks(pack,itemBatchWithMaxQty,actualTakenQty,rangeId,totalRequiredBenefitQtyPcs); 
          }
          var takenQty = requiredQtyInPcs;
          if((this.returnBenefitMode === ReturnBenefitCalculationMode.ProductDiscountQuantityBenefit ||
            this.returnBenefitMode === ReturnBenefitCalculationMode.ProductDiscountQuantityOrDiscountBenefit)
            && takenQty > 0 && !benefitAddedToProductDiscountItems) {
              benefitAddedToProductDiscountItems = true;
              this.addToProductDiscountItems(pack.packId,
                pack.itemId,
                packgroupBenefit.packGroupId,
                takenQty, // product discount qty
                range.rangePrerequisites,
                range.promotionId,
                2, // discount
                0,
                pack.piecesInPack,
                itemBatchWithMaxQty.basePrice,
                itemBatchWithMaxQty.transactionId,
                itemBatchWithMaxQty.baseTaxPercentage,
                rangeId,
                itemBatchWithMaxQty.batchNo,
                itemBatchWithMaxQty.expiryDateModel,
                range,
                takenQty // returnValueInpcs
                );
          }
          if(notEnoughQty) {
            // current batch is empty... (not enough available quantity to cover return benefit)
            // move on to next batch of current pack if available.
            // if there are still batches for current pack with quantity > 0, use these batches
            if(!this.isThereAnyBatchesWithAvailableQuantity(range.rangeTransactionIds,pack)) { 
              currentIdx ++;
            }
          }
        }
      } else {
        currentIdx ++;
      }
    }
  }

  applyDiscountPackGroupLevel(range: any) {
    
    var continueCalculation = false;
    range.isDiscountCalculated = true;
    var rangeDiscountAmount = 0;
    var discountAmount  = 0;
    range.benefitsToReturn.forEach(packgroupBenefit => {
      
      var result1 = this.isThereAnyPacksWithAvailableBatches(packgroupBenefit.allowedPacksFromPackGroup,range.rangeTransactionIds);
      if(result1 && result1.isTherePacks && result1.index > -1) {
        var requiredQtyInPcs = packgroupBenefit.returnValue * packgroupBenefit.allowedPacksFromPackGroup[result1.index].packGroupPiecesInPack;
        var pack = packgroupBenefit.allowedPacksFromPackGroup[result1.index];
        var itemBatchWithMaxQty = this.getPriceForDiscount(range.rangeTransactionIds,pack);

        if (itemBatchWithMaxQty) {
          continueCalculation = true;
          // discountAmount = requiredQtyInPcs * itemBatchWithMaxQty.netPrice;
          discountAmount = this.getDiscountAmountFromProductDiscountDetails(range,packgroupBenefit.packGroupId,requiredQtyInPcs);
          if(this.applyRoundingPerLine) {
            discountAmount = this.calculationService.getNumberOnDigitFormat(discountAmount);
          }
          // subtract the maximum possible remaining (available) quantity to cover the whole benefit return value. 
          // the subtracted quantity will be saved as forcedReturnAsDiscount in [transactionDetail],
          // and will be saved in [ReturnInvoiceHistory].
          this.subtractDiscountBenefitQuantity(requiredQtyInPcs,packgroupBenefit,range,discountAmount);
        } // else ??, because itemBatchWithMaxQty is not supposed to be null as it is already checked in [isThereAnyPacksWithAvailableBatches]
      } else if (this.returnBenefitMode === ReturnBenefitCalculationMode.ProductDiscountQuantityBenefit && range.isProductDiscount) { 
        // all benefit packs are already returned in previous transactions.
        // promotion benefit is product discount.
        continueCalculation = true;
        var requiredQtyInPcs = packgroupBenefit.returnValue * packgroupBenefit.allowedPacksFromPackGroup[0].packGroupPiecesInPack;
        var pack = packgroupBenefit.allowedPacksFromPackGroup[0]; // select first pack by default.
        discountAmount = this.getProductDiscountAmountFromProductDiscountDetails(range,requiredQtyInPcs);
      } 
      if(continueCalculation) {
        var prerequisiteItems = range.rangePrerequisites.map(p => p.itemId);
        var prerequisitePacks = range.rangePrerequisites.map(p => p.packId);

        var validItemsForDiscount = this.soldItems.filter(i =>
          prerequisiteItems.includes(i.itemId) && 
          ((prerequisitePacks.includes(i.packId) && !this.allowConversion) || this.allowConversion) &&
          i.returnFromInvoiceAvailableBatchList.filter(b=> b.requiredQuantity > 0).filter(x => range.rangeTransactionIds.includes(x.transactionId)).length > 0 
        );
        if(validItemsForDiscount && validItemsForDiscount.length > 0) {
          var result = this.calculateDiscountForBenefit(validItemsForDiscount,range.rangeTransactionIds,discountAmount,range);
          if(result) {
            packgroupBenefit.discountAmount = result.discountAmount;
            rangeDiscountAmount += result.discountAmount;
            packgroupBenefit.discountItems = this.allPacksDiscount;
            this.allPacksDiscount = [];
          }
        }
      }
    });
  }

  getDiscountAmountFromProductDiscountDetails(range:any, packGroupId:number,requiredQtyInPcs:number): number {
    
    // this method is used when some batches are still available, the benefit net total is returned [without promoted discount as this is the first level].
    var discountAmount = 0;
    var prerequisiteItems = range.rangePrerequisites.map(p => p.itemId);
    var prerequisitePacks = range.rangePrerequisites.map(p => p.packId);
    var prerequisiteItems = range.rangePrerequisites.map(p => p.itemId);
    var prerequisitePacks = range.rangePrerequisites.map(p => p.packId);
    var productDiscountDetails = range.productDiscountDetails.filter(detail =>
      detail.benefitPackGroupId == packGroupId &&
      range.rangeTransactionIds.includes(detail.transactionId) &&
      prerequisiteItems.includes(detail.prerequisiteItemId) &&
      prerequisitePacks.includes(detail.prerequisitePackId));
    if(productDiscountDetails && productDiscountDetails.length > 0) {
      var totalOriginalAmount = 0;
      var totalOriginalQuantityInPcs = 0;
      var addedDiscountDetail = [];
      productDiscountDetails.forEach(detail => {
        var idx = addedDiscountDetail.findIndex(d => 
          d.transactionId === detail.transactionId &&
          d.promotionOptionId === detail.promotionOptionId &&
          d.promotionOptionDetailTypeId === detail.promotionOptionDetailTypeId &&
          d.promotionOptionDetailId === detail.promotionOptionDetailId &&
          d.promotionId === detail.promotionId &&
          d.benefitItemId === detail.benefitItemId &&
          d.benefitPackId === detail.benefitPackId &&
          d.originalBenefitPackQuantity === detail.originalBenefitPackQuantity &&
          d.discountAppliedOverOriginalBenefitQty === detail.discountAppliedOverOriginalBenefitQty
          );
          if(idx === -1) {
            
            addedDiscountDetail.push(detail); // to prevent adding the same benefit twice [the same benefit may be repeated for different prerequisites]
            totalOriginalAmount += detail.pdPackNetTotalWithoutDiscount;
            totalOriginalQuantityInPcs += detail.originalBenefitPackQuantity * detail.benefitPiecesInPack;
          }
        }); 
        discountAmount = totalOriginalAmount * (requiredQtyInPcs / totalOriginalQuantityInPcs);
    }
    return discountAmount;
  }

  getProductDiscountAmountFromProductDiscountDetails(range:any, requiredQtyInPcs:number) {
    // this method is used when all batches are returned, the product discount to be applied over prerequisites is returned.
    var discountAmount = 0;
    var prerequisiteItems = range.rangePrerequisites.map(p => p.itemId);
    var prerequisitePacks = range.rangePrerequisites.map(p => p.packId);
    var productDiscountDetails = range.productDiscountDetails.filter(detail =>
          range.rangeTransactionIds.includes(detail.transactionId) &&
          prerequisiteItems.includes(detail.prerequisiteItemId) &&
          prerequisitePacks.includes(detail.prerequisitePackId));
    if(productDiscountDetails && productDiscountDetails.length > 0) {
      var totalOriginalAmount = 0;
      var totalOriginalQuantityInPcs = 0;
      var addedDiscountDetail = [];
      productDiscountDetails.forEach(detail => {
        var idx = addedDiscountDetail.findIndex(d => 
          d.transactionId === detail.transactionId &&
          d.promotionOptionId === detail.promotionOptionId &&
          d.promotionOptionDetailTypeId === detail.promotionOptionDetailTypeId &&
          d.promotionOptionDetailId === detail.promotionOptionDetailId &&
          d.promotionId === detail.promotionId &&
          d.benefitItemId === detail.benefitItemId &&
          d.benefitPackId === detail.benefitPackId &&
          d.originalBenefitPackQuantity === detail.originalBenefitPackQuantity &&
          d.discountAppliedOverOriginalBenefitQty === detail.discountAppliedOverOriginalBenefitQty
          );
          if(idx === -1) {
            
            addedDiscountDetail.push(detail); // to prevent adding the same benefit twice [the same benefit may be repeated for different prerequisites]
            if(this.includeTaxInProductDiscount) {
              let taxableAmount = (detail.avgPrice * detail.benefitPiecesInPack * detail.originalBenefitPackQuantity) - detail.discountAppliedOverOriginalBenefitQty;
              let tax = taxableAmount * (detail.taxPercentage / 100);
              totalOriginalAmount += 
                taxableAmount + tax;
            } else {
              totalOriginalAmount += 
                (detail.avgPrice * detail.benefitPiecesInPack * detail.originalBenefitPackQuantity) - detail.discountAppliedOverOriginalBenefitQty;
            }
            // totalOriginalAmount += detail.pdPackNetTotalWithoutDiscount;
            totalOriginalQuantityInPcs += detail.originalBenefitPackQuantity * detail.benefitPiecesInPack;
          }
        });
        discountAmount = totalOriginalAmount * (requiredQtyInPcs / totalOriginalQuantityInPcs);
      } 
  return discountAmount;
  }
//  // #[end region]NEW CODE NEEDS TEST.........................

isThereAnyBatchesWithAvailableQuantity(rangeTransactionIds:any[],pack:any): boolean {
    var result = false;

    rangeTransactionIds.forEach(id => {
      var allItemBatchesPerTrans = this.allTransactionsIncludedInBenefit.filter(b => 
        b.transactionId === id &&
        b.itemId === pack.itemId);
      if(allItemBatchesPerTrans && allItemBatchesPerTrans.length > 0 &&
         allItemBatchesPerTrans.filter(x => x.availableQtyInPcs > 0).length > 0 // are there any batches with qty > 0 left?
         ) {
          result = true;
         }
    });
    return result;
  }

  addToTempForcedToReturnPacks(pack:any,batchObj,returnQtyInPcs:any,rangeId:any, totalRequiredReturnQtyInPcs: number) {
    // totalRequiredReturnQty => total required benefit quantity to return from promotion benefit.
    // returnQtyInPcs => taken quantity from current pack.
    
    var i = this.tempForcedToReturnPack.findIndex(t => 
      t.itemId === pack.itemId &&
      t.packId === pack.packId &&
      t.salesTransactionTypeId === SalesTransactionTypes.FreeReturnAsDiscount &&
      t.promotionRangeId === rangeId
      );
    if (i === -1) {
      var itemPackObj = new ItemPackModel();
      itemPackObj.itemId = pack.itemId;
      itemPackObj.packId = pack.packId;
      itemPackObj.itemCodeName = pack.itemCodeName;
      itemPackObj.itemCode = pack.itemCode; 
      itemPackObj.itemName = pack.itemName; 
      itemPackObj.uom = pack.uomDescription;
      itemPackObj.piecesInPack = pack.piecesInPack;
      itemPackObj.isFromProductDiscount = this.returnBenefitMode === ReturnBenefitCalculationMode.RelatedToTargetBenefit ||
      this.returnBenefitMode === ReturnBenefitCalculationMode.ProductDiscountQuantityOrDiscountBenefit ? false: true;
      batchObj.requiredQuantity = returnQtyInPcs / pack.piecesInPack;
      batchObj.salesTransactionTypeId = SalesTransactionTypes.FreeReturnAsDiscount;
      itemPackObj.promotionRangeId = rangeId;
      itemPackObj.returnFromInvoiceAvailableBatchList.push(batchObj);
      itemPackObj.salesTransactionTypeId = SalesTransactionTypes.FreeReturnAsDiscount;
      itemPackObj.requiredQty = returnQtyInPcs / pack.piecesInPack;
      itemPackObj.totalProductDiscountQuantity = totalRequiredReturnQtyInPcs / pack.piecesInPack; // required quantity to return [may not be available.]
      itemPackObj.price = batchObj.basePrice * pack.piecesInPack; // initialize price.
      this.tempForcedToReturnPack.push(itemPackObj);
    } else {
      
      this.tempForcedToReturnPack[i].requiredQty += returnQtyInPcs / pack.piecesInPack;
      batchObj.requiredQuantity = returnQtyInPcs / pack.piecesInPack;
      batchObj.salesTransactionTypeId = SalesTransactionTypes.FreeReturnAsDiscount;
      this.tempForcedToReturnPack[i].returnFromInvoiceAvailableBatchList.push(batchObj);
      // calculate Avg Price.
      var totalItemBatchGross = 0; // in current pack UOM
      var totalItemBatchQty = 0; // in current pack UOM
      this.tempForcedToReturnPack[i].returnFromInvoiceAvailableBatchList.map(b => {
        totalItemBatchGross += (b.basePrice * this.tempForcedToReturnPack[i].piecesInPack * batchObj.requiredQuantity);
        totalItemBatchQty += batchObj.requiredQuantity;
      });
      // to avoid division by zero, check totalItemBatchQty first.
      this.tempForcedToReturnPack[i].price = totalItemBatchQty > 0 ? totalItemBatchGross / totalItemBatchQty: 0;
    }
  }

  applyDiscountPackLevel(range:any) {
   
    var continueCalculation = false;
    range.isDiscountCalculated = true;
    range.benefitsToReturn.forEach(packBenefit => {
      var rangeDiscountAmount = 0;
      var rangeDiscountPercentage = 0;
      // Total required from this pack in pcs.
      var requiredQtyInPcs = packBenefit.returnValue * packBenefit.piecesInPack;
      // price => 
      // var lastInvoiceTransactionId = range.rangeTransactionIds[0];
      // Get all Transactions where TransactionId === lastInvoiceTransactionId.
      var itemBatchWithMaxQty = this.getPriceForDiscount(range.rangeTransactionIds,packBenefit);
      if(itemBatchWithMaxQty) {
        continueCalculation = true;

        var discountAmount = requiredQtyInPcs * itemBatchWithMaxQty.netPrice;
        if(this.applyRoundingPerLine) {
          discountAmount = this.calculationService.getNumberOnDigitFormat(discountAmount);
        }
        packBenefit.discountAmount = discountAmount;
        // Apply Discount On PreRequisites Included In Return Order.
        var rangeId = this.getRangeId(range);
        this.addToForcedDiscountItemBatches(itemBatchWithMaxQty,packBenefit.itemId, packBenefit.packId,packBenefit.piecesInPack,packBenefit.returnValue,discountAmount,requiredQtyInPcs,rangeId);
      } else if (this.returnBenefitMode === ReturnBenefitCalculationMode.ProductDiscountQuantityBenefit) {
        // In this case, if item has product discount value, but all product discount items are already returned
        // and processed, then, refer to rangeObj Product discount details to find the discount amount to be applied
        // over range prerequisites.
        continueCalculation = true;
        var prerequisiteItems = range.rangePrerequisites.map(p => p.itemId);
        var prerequisitePacks = range.rangePrerequisites.map(p => p.packId);
        var i = range.productDiscountDetails.findIndex(x => prerequisiteItems.includes(x.itemId) && prerequisitePacks.includes(x.packId));
        if(i > -1) {
          // This is wrong -> this is all the of product discount amount. this should be divided by correct
          // return value.
          discountAmount = range.productDiscountDetails[i].productDiscountAmount;
        }
      }
      if(continueCalculation) {
        var prerequisiteItems = range.rangePrerequisites.map(p => p.itemId);
        var prerequisitePacks = range.rangePrerequisites.map(p => p.packId);

        var validItemsForDiscount = this.soldItems.filter(i =>
           prerequisiteItems.includes(i.itemId) && 
           ((prerequisitePacks.includes(i.packId) && !this.allowConversion) || this.allowConversion) &&
           i.returnFromInvoiceAvailableBatchList.filter(x => range.rangeTransactionIds.includes(x.transactionId)).length > 0
        );
        if(validItemsForDiscount && validItemsForDiscount.length > 0) {
          var result = this.calculateDiscountForBenefit(validItemsForDiscount,range.rangeTransactionIds,discountAmount,range);
          if(result) {
            packBenefit.discountAmount = result.discountAmount; // after validating....
            rangeDiscountAmount += result.discountAmount;
            packBenefit.discountItems = this.allPacksDiscount;
            this.allPacksDiscount = [];
          }
        } else {
          packBenefit.noValidItems = true;
          range.benefitNotReturned = true;
        }
      }
    });
  }

  getRangeId(range:any): number {
    // This method returns the unique Id of each promotion range [Based on the Index].
    var Id = -1;
    var idx = this.promotionRanges.findIndex(p => 
      p.promotionId === range.promotionId &&
      p.startDateModel.day === range.startDateModel.day &&
      p.startDateModel.month === range.startDateModel.month &&
      p.startDateModel.year === range.startDateModel.year &&
      p.startDateModel.date === range.startDateModel.date &&
      p.endDateModel.day === range.endDateModel.day &&
      p.endDateModel.month === range.endDateModel.month &&
      p.endDateModel.year === range.endDateModel.year &&
      p.endDateModel.date === range.endDateModel.date 
      );
    if(idx > -1) {
      Id = idx + 1;
    }
    return Id;
  }

  applyDiscountForDiscountRange(range:any) {
    
    var doesExist = false;
    var rangeDiscountAmount = 0;
    range.isDiscountCalculated = true;
    range.benefitsToReturn.forEach(discountAmountBenefit => {
      discountAmountBenefit.actualReturnValue = discountAmountBenefit.returnValue;
      var discountAmount = discountAmountBenefit.returnValue;
      discountAmountBenefit.discountAmount = discountAmount;
      if(this.applyRoundingPerLine) {
        discountAmount = this.calculationService.getNumberOnDigitFormat(discountAmount);
      }
      var prerequisiteItems = range.rangePrerequisites.map(p => p.itemId);
      var prerequisitePacks = range.rangePrerequisites.map(p => p.packId);

      var validItemsForDiscount = this.getValidItemsForDiscount(range,discountAmountBenefit,prerequisiteItems,prerequisitePacks);
      if(validItemsForDiscount && validItemsForDiscount.length > 0) {
        var result = this.calculateDiscountForBenefit(validItemsForDiscount,range.rangeTransactionIds,discountAmountBenefit.returnValue,range);
          if(result) {
            rangeDiscountAmount += result.discountAmount;
            if(!discountAmountBenefit.discountItems) {
              discountAmountBenefit.discountItems = this.allPacksDiscount;
            } else {
              discountAmountBenefit.discountItems = discountAmountBenefit.discountItems.concat(this.allPacksDiscount);
            }

            this.allPacksDiscount = [];
          }
      } else {
        discountAmountBenefit.noValidItems = true;
      }
    });
  }

  getProductDiscountDetails(range:any, itemId:number, packId:number, benefitType:number,pdBenefitPackGroupId: number) {
    var productDiscountDetails = [];
    if(benefitType == 2) { // this is needed when benefit of multiple packs[ex. unit and pallet] is taken and returned as discount.
      // the first pack is selected for return by default, so a bug occurs as discount applied over first pack only is
      // selected, so we need to sum the total discount over the benefit pack group and apply it to the
      // pack selected by default.
      productDiscountDetails = range.productDiscountDetails.filter(detail => 
        detail.promotionId == range.promotionId 
        && range.rangeTransactionIds.includes(detail.transactionId) 
        && detail.benefitPackGroupId == pdBenefitPackGroupId);
    } else {
      productDiscountDetails = range.productDiscountDetails.filter(detail => detail.benefitPackId === packId && 
        detail.benefitItemId === itemId &&
        range.rangeTransactionIds.includes(detail.transactionId));
    }
    return productDiscountDetails;
  }

  addToRangesList(range:any) {
    this.rangesList.push(range);
  }

  getValidItemsForDiscount(range:any,benefit:any,prerequisiteItems:any[],prerequisitePacks:any[]) {
    // This method finds the valid Items for discount based on:
    // 1. the benefit type of the promotion.
    // 2. transactions included in promotion range.
    // 3. previously taken benefit packs [in sales].
    
    var allAddedItems = this.soldItems.concat(this.allItemsReturnedAsDisc);
    // ItemsReturnedAsDiscount are added here to sold items so that
    // when calculating the discount amount for items the right ratio of discount will be applied.
    // example: 1 B returned as discount 1 B returned as item -> total discount on B = 4. 1/2 * 4 = 2 should be applied on the 
    // item B applied as Quantity.
    // var allAddedItems = this.soldItems.
    var validItemsForDiscount = [];
    if(benefit.benefitOptionDetailTypeId === PromotionOutputDetailTypes.DiscountPercentageForGroupItems) {
      var validPackIds = benefit.allowedPacksFromPackGroup.map(b => b.packId);
      var validItemIds = benefit.allowedPacksFromPackGroup.map(b => b.itemId);
      validItemsForDiscount = allAddedItems.filter(i =>
        i.returnFromInvoiceAvailableBatchList.filter(xx => xx.requiredQuantity > 0).filter(x => range.rangeTransactionIds.includes(x.transactionId)).length > 0 &&
        // validPackIds.includes(i.packId)
        validItemIds.includes(i.itemId)
        );
    } else if (benefit.benefitOptionDetailTypeId === PromotionOutputDetailTypes.DiscountPercentageForItem) {
      validItemsForDiscount = allAddedItems.filter(i =>
        i.returnFromInvoiceAvailableBatchList.filter(xx => xx.requiredQuantity > 0).filter(x => range.rangeTransactionIds.includes(x.transactionId)).length > 0 &&
        benefit.packId === i.packId
      );
    } else if (benefit.benefitOptionDetailTypeId === PromotionOutputDetailTypes.DiscountPercentageOrQtyOverGroupWithSameAmount) {
      validItemsForDiscount = allAddedItems.filter(i =>
        range.rangePrerequisites.map(i => i.itemId).includes(i.itemId) &&
        // range.rangePrerequisites.map(i => i.packId).includes(i.packId) &&
        i.returnFromInvoiceAvailableBatchList.filter(xx => xx.requiredQuantity > 0).filter(x => range.rangeTransactionIds.includes(x.transactionId)).length > 0
      );
    } else if (benefit.benefitOptionDetailTypeId === PromotionOutputDetailTypes.AllItemDiscountPercentage) {
      validItemsForDiscount = allAddedItems.filter(i =>
        i.returnFromInvoiceAvailableBatchList.filter(xx => xx.requiredQuantity > 0).filter(x => range.rangeTransactionIds.includes(x.transactionId)).length > 0
      );
    }
    return validItemsForDiscount;
  }

  calculateDiscountForBenefit(itemsList:any[],rangeTransactionIds:any[],benefitDiscountAmount:number,range:any) {
    // This method calculates the appropriate discount amount to applied over each item of passed list.
    // for each item the discount = discountValue * (itemGross / sum(itemsList gross));
    // The sum of all items applied discount values should equal discountValue.
    // disc1 + disc2 + ... + discn === discountValue;
    var totalDiscountAmount = 0; // from all items in this range.
    var totalDiscountPct = 0;
    var result;
    var items = []; // contains all items with their totals from batches.
    var grossFromAllItems = 0;
    var reqFromAllItems = 0;
    var totalReqQtyInPreReqUOM = 0; 
    itemsList.forEach(i => {
      var validBatches = i.returnFromInvoiceAvailableBatchList.filter(x => rangeTransactionIds.includes(x.transactionId) && x.requiredQuantity > 0)
      var totalReqQty = 0; // Total Required qty of pack over transactions within current range.
      var grossTotal = 0; // Gross Total from all batches;
      var currentItemTotalReqQtyInPreReqUOM = 0;
      var targetUOMQty = 0;
      var prereqDetailObj ;
      // find item's gross total, and total required quantity.
      if(
        // i.isReturnedAsDiscount && 
        i.salesTransactionTypeId == SalesTransactionTypes.FreeReturnAsDiscount &&
        i.totalProductDiscountQuantity > 0 
        ) {
          totalReqQty = i.totalProductDiscountQuantity;
          grossTotal = i.totalProductDiscountQuantity * i.price;
      } else {
        validBatches.map(b => {
          totalReqQty += b.requiredQuantity;
          grossTotal += b.basePrice * b.requiredQuantity * i.piecesInPack;
        });
      }
      // validBatches.map(b => {
      //   totalReqQty += b.requiredQuantity;
      //   grossTotal += b.basePrice * b.requiredQuantity * i.piecesInPack;
      // });
      reqFromAllItems += totalReqQty;
      grossFromAllItems += grossTotal;
      if(range && this.productDiscountCalculationMode == 1 && range.isProductDiscount) {
        prereqDetailObj = range.rangePrerequisites.find(detail => detail.itemId === i.itemId);
        if(prereqDetailObj) {
          targetUOMQty = prereqDetailObj.prereqUOMQuantity;
          currentItemTotalReqQtyInPreReqUOM = totalReqQty * i.piecesInPack / targetUOMQty;
          totalReqQtyInPreReqUOM += currentItemTotalReqQtyInPreReqUOM;
        }
      }

      if(totalReqQty > 0 && grossTotal > 0) {
        var obj = {
          packId: i.packId,
          itemId: i.itemId,
          grossTotal: grossTotal, 
          requiredQty: totalReqQty, // not used anymore [i think]....
          transactions: rangeTransactionIds, // not used anymore [i think]....
          isFromProductDiscount: i.isFromProductDiscount ? true:false,
          isReturnedAsDiscount: i.isReturnedAsDiscount? true:false,
          requiredQtyInTargetUOM: currentItemTotalReqQtyInPreReqUOM
        }
        items.push(obj);
      }
    });
    items.forEach(i => {
      if(this.productDiscountCalculationMode == 1 && range && range.isProductDiscount) {
        var productDiscountQuantityRatio = 1;
        productDiscountQuantityRatio = i.requiredQtyInTargetUOM / totalReqQtyInPreReqUOM;
        var discAmount = benefitDiscountAmount * productDiscountQuantityRatio;
        i.discountAmount = discAmount;
        i.discountPercentage = (discAmount / i.grossTotal) * 100;
        totalDiscountAmount += discAmount; // => this should be equal to [benefitDiscountAmount].
      } else {
        var productDiscountGrossRatio = 1;
        productDiscountGrossRatio = i.grossTotal / grossFromAllItems;
        var discAmount = benefitDiscountAmount * productDiscountGrossRatio;
        i.discountAmount = discAmount;
        i.discountPercentage = (discAmount / i.grossTotal) * 100;
        totalDiscountAmount += discAmount; // => this should be equal to [benefitDiscountAmount].
      }
    });
    this.allPacksDiscount = items;
    result = {
      discountAmount: totalDiscountAmount, 
    }
    return result;
  }

  getPriceForDiscount(rangeTransactionIds:any[],packBenefit:any, excludeZeroQty?: boolean) {
    // This method finds the batch object with the maximum quantity in range valid transactions
    // starting from last invoice date and descending..
    var found = false;
    var itemBatchWithMaxQty;
    rangeTransactionIds.forEach(id => {
      if(found) return;
      var allItemBatchesPerTrans = this.allTransactionsIncludedInBenefit.filter(b => 
        b.transactionId === id &&
        b.itemId === packBenefit.itemId &&
        ((b.availableQtyInPcs > 0 && excludeZeroQty) || !excludeZeroQty)
        );
      if(allItemBatchesPerTrans && allItemBatchesPerTrans.length > 0) {
        itemBatchWithMaxQty = allItemBatchesPerTrans.reduce(function(prev, current) {
          return (prev.availableQtyInPcs > current.availableQtyInPcs) ? prev : current});
        found = true;
      }
    });
    return itemBatchWithMaxQty;
  }

  addToForcedDiscountItemBatches(txBatchObj:any,itemId:number,packId:number,piecesInPack:number,returnValue:number,discountAmount:number,requiredQuantityInPcs:number,rangeId:number) {
    // To save in ReturnInvoiceHistory. to affect customer batches list.
    var idx = this.forcedDiscountItemBatches.findIndex(t =>
      t.itemId === txBatchObj.itemId &&
      t.transactionId === txBatchObj.transactionId &&
      t.batchNo === txBatchObj.batchNo &&
      t.expiryDateModel.day === txBatchObj.expiryDateModel.day &&
      t.expiryDateModel.month === txBatchObj.expiryDateModel.month &&
      t.expiryDateModel.year === txBatchObj.expiryDateModel.year &&
      t.expiryDateModel.date === txBatchObj.expiryDateModel.date &&
      t.rangeId === rangeId
      );
    if(idx === -1) {
      var batchObj = {
        transactionId: txBatchObj.transactionId,
        batchNo: txBatchObj.batchNo,
        expiryDateModel: txBatchObj.expiryDateModel,
        itemId: itemId,
        requiredQuantity: requiredQuantityInPcs, 
        rangeId: rangeId
      }
      this.forcedDiscountItemBatches.push(batchObj);
    } else {
      // this.forcedDiscountItemBatches[idx].requiredQuantity += returnValue * piecesInPack; requiredQuantityInPcs
      this.forcedDiscountItemBatches[idx].requiredQuantity += requiredQuantityInPcs;
    }
  }

  hideApplyDiscountToggle(promotionRange:any) {
    var hide = false;
    if(
      promotionRange.benefitsToReturn[0].benefitOptionDetailTypeId === PromotionOutputDetailTypes.AllItemDiscountPercentage.valueOf() ||
      promotionRange.benefitsToReturn[0].benefitOptionDetailTypeId === PromotionOutputDetailTypes.DiscountPercentageForGroupItems.valueOf() ||
      promotionRange.benefitsToReturn[0].benefitOptionDetailTypeId === PromotionOutputDetailTypes.DiscountPercentageForItem.valueOf() ||
      (
        promotionRange.benefitsToReturn[0].benefitOptionDetailTypeId === PromotionOutputDetailTypes.DiscountPercentageOrQtyOverGroupWithSameAmount.valueOf() &&
        (!promotionRange.benefitsToReturn[0].allowedPacksFromPackGroup || promotionRange.benefitsToReturn[0].allowedPacksFromPackGroup.length == 0 )
      )
    ) {
      hide = true;
    }
    return hide;
  }

  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
  }

}
