import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogResult } from '../../models/enums/dialog-result.enum';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreSession } from '../../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../models/constants/constant-message';
import { DateTimeModel } from '../../models/dateTimeModel/date-time.model';
import { IDateTimePickerProperties } from '../../models/dateTimeModel/date-time-properties.interface';

@Component({
  selector: 'app-service-setup-dialog',
  templateUrl: './service-setup-dialog.component.html',
  styleUrls: ['./service-setup-dialog.component.css']
})
export class ServiceSetupDialogComponent implements OnInit, OnChanges {

  //#region [DECLARATIONS]

  @Input() events: Observable<void>;
  @Input() serviceSetupObj: any = {};
  @ViewChild('content', { static: true }) content: any;
  OkResult: DialogResult = DialogResult.Ok;
  form: FormGroup;
  timeToRunDate: DateTimeModel;
  timeToRunDateProperties: IDateTimePickerProperties = {
    label: 'Desc_Time_To_Run',
    formControlName: 'timeToRunDate',
  };
  //#endregion

  //#region [CONSTRUCTOR]
  constructor(private modalService: NgbModal, private coreSession: CoreSession,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
  }
  ngOnChanges(){
    if (this.serviceSetupObj && this.serviceSetupObj.serviceId > 0){
      this.fillFormInEditMode();
      this.onCertainTimeChange();
    }
  }
  //#endregion


  //#region [EVENTS]  

  onSaveClicked(modal) {
    this.fillServiceSetupObject();
    if (this.form.invalid) {
      this.coreSession.markFormGroupTouched(this.form as FormGroup);
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }
    if (this.serviceSetupObj.isCertainTimeRuning && (!this.timeToRunControl || (this.timeToRunControl.value == null))) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgSelectDate));
      return;
    }
    if (!this.serviceSetupObj.isCertainTimeRuning && (!this.numberOfMinutesControl || (this.numberOfMinutesControl.value == null))) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgFillNumberOfMin));
      return;
    }
    else {
      this.saveData(modal);
    }
  }

  onDateSelected(date: any) {
    if (date)
      this.timeToRunDate = date;
  }

  onCertainTimeChange() {
    if (this.certainTimeControl.value) {
      this.numberOfMinutesControl.setValue(null);
      this.numberOfMinutesControl.disable();
      this.timeToRunControl.enable();
    }
    else {
      this.timeToRunControl.setValue(null);
      this.timeToRunControl.disable();
      this.numberOfMinutesControl.enable();
    }
  }

  //#endregion

  //#region [METHODS]
  saveData(modal) {
    modal.close(DialogResult.Ok);

  }
  fillServiceSetupObject() {
    this.serviceSetupObj.isCertainTimeRuning = this.certainTimeControl ? this.certainTimeControl.value : false;
    //this.serviceSetupObj.timeToRun = this.timeToRunControl ? this.timeToRunControl.value : null;
    this.serviceSetupObj.numberOfMinutesToSleep = + (this.numberOfMinutesControl ? this.numberOfMinutesControl.value : 0);
    this.serviceSetupObj.timeToRunModel = this.timeToRunDate;
    this.serviceSetupObj.isWebService = this.isWebService.value;
  }
  initForm() {
    this.form = new FormGroup({
      certainTime: new FormControl(false),
      isWebService: new FormControl(false),

      timeToRunDate: new FormControl(
        {
          value: this.timeToRunDate,
          disabled: false
        }
      ),
      numberOfMinutes: new FormControl(null)
    });
  }
  fillFormInEditMode() {
    this.certainTimeControl.setValue(this.serviceSetupObj.isCertainTimeRuning);
    this.numberOfMinutesControl.setValue(!this.serviceSetupObj.isCertainTimeRuning ? this.serviceSetupObj.numberOfMinutesToSleep : null);
    this.timeToRunControl.setValue(this.serviceSetupObj.isCertainTimeRuning ? this.serviceSetupObj.timeToRun : null);
    this.isWebService.setValue(this.serviceSetupObj.isWebService);

    // disable 
    if (!this.serviceSetupObj.isCertainTimeRuning) {
      this.timeToRunControl.setValue(null);
      this.timeToRunControl.disable();
      this.numberOfMinutesControl.enable();
    }
    else {
      this.timeToRunControl.enable();
      this.numberOfMinutesControl.setValue(null);
      this.numberOfMinutesControl.disable();
    }
  }
  public showDialog() {
    
      this.onCertainTimeChange();
    return this.modalService.open(this.content, { centered: true }).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          return this.serviceSetupObj;
        } else {
          return -1;
        }
      });
  }
  //#endregion

  //#region [PROPERTIES]

  get certainTimeControl() {
    return this.form.get('certainTime');
  }
  get timeToRunControl() {
    return this.form.get('timeToRunDate');
  }
  get numberOfMinutesControl() {
    return this.form.get('numberOfMinutes');
  }
  get isWebService() {
    return this.form.get('isWebService');
  }
  //#endregion
}
