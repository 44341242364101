import { Component, OnInit } from '@angular/core';
import { ConstantURLs } from '../../../models/constants/constant-URL';

@Component({
  selector: 'app-web-form-list-master-data',
  templateUrl: './web-form-list-master-data.component.html',
  styleUrls: ['./web-form-list-master-data.component.css']
})
export class WebFormListMasterDataComponent implements OnInit {
  mainUrl = ConstantURLs.dataDefinitionURL;
  mainName = 'Desc_Data_Definition';
  constructor() {
  }
  ngOnInit() {
  }
}
