import { Component, OnInit, ViewChild } from "@angular/core";
import { CoreSession } from "../../../../core/core.session";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ConstantMessages } from "../../../../shared/models/constants/constant-message";
import { DialogMode } from "../../../../shared/models/enums/dialog-mode.enum";
import { TranslateService } from "@ngx-translate/core";
import { ModalDialogComponent } from "../../../../shared/components/modal-dialog/modal-dialog.component";
import { SetupService } from "../setup.service";
import { ModalLoadingComponent } from "../../../../shared/components/modal-loading/modal-loading.component";
import { Router } from "@angular/router";
import { ConstantURLs } from "../../../../shared/models/constants/constant-URL";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-setup-wizard",
  templateUrl: "./setup-wizard.component.html",
  styleUrls: ["./setup-wizard.component.css"],
})
export class SetupWizardComponent implements OnInit {
  @ViewChild("dialogModal", { static: true }) dialogModal: ModalDialogComponent;
  @ViewChild("loadingModal", { static: true })
  loadingModal: ModalLoadingComponent;

  public loginForm: FormGroup;

  showLoginTab = true;
  showGeneralTab = false;
  progressWidth = "16.660000000000004%";
  siteInfo: any = {};

  logoPath = "";

  constructor(
    private coreSession: CoreSession,
    private setupService: SetupService,
    private translateService: TranslateService,
    private router: Router,
    private TitleService: Title
  ) {}

  ngOnInit() {
    this.TitleService.setTitle("Sonic - Setup");
    this.logoPath =
      this.coreSession +
      'url("' +
      this.coreSession.baseURL +
      'assets/img/sonic_logo.png") 0 / contain no-repeat';
    this.loginForm = new FormGroup({
      username: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
    });
  }

  login() {
    if (this.loginForm.invalid) {
      this.coreSession.showInfo("Info", "Please insert username & passowrd");
      return;
    }
    this.loadingModal.Show();
    this.setupService
      .setupEnviroment(this.username.value, this.password.value)
      .subscribe(
        (response) => {
          this.loadingModal.Hide();
          if (response.status >= 0) {
            this.siteInfo = response.data;
            this.showLoginTab = false;
            this.showGeneralTab = true;
            this.progressWidth = "49.99333333333334%";
          } else {
            this.dialogModal.ShowMessage(
              response.message,
              DialogMode.Ok,
              this.translateService.instant(ConstantMessages.ErrorCaption)
            );
          }
        },
        (error) => {
          this.loadingModal.Hide();

          this.dialogModal.ShowMessage(
            ConstantMessages.ErrorHappened,
            DialogMode.Ok,
            this.translateService.instant(ConstantMessages.ErrorCaption)
          );
        }
      );
  }

  goToLtigoToLoginScreen() {
    this.TitleService.setTitle("Sonic - Login");
    this.router.navigate(["/" + ConstantURLs.loginURL]);
  }
  get username() {
    return this.loginForm.get("username");
  }
  get password() {
    return this.loginForm.get("password");
  }
}
