import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreSession } from '../../../core/core.session';
import { ImportDataTypes } from '../../models/import/ImportDataTypes';
import { SharedTableResult } from '../../models/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../models/shared-table/table-properties.interface';

@Component({
  selector: 'app-imported-excel-errors-dialog',
  templateUrl: './imported-excel-errors-dialog.component.html',
  styleUrls: ['./imported-excel-errors-dialog.component.css']
})
export class ImportedExcelErrorsDialogComponent implements OnInit {
  @ViewChild('invalidExcelRowsDialog', { static: true }) invalidExcelRowsDialog: any;
  @Output('onCloseDialog') onCloseDialog = new EventEmitter();
  @Input() importedExcelErrors: any[] = [];;
  @Input() importDataType: ImportDataTypes = ImportDataTypes.None;
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    isOnline: false,
    showSearch: false,
    showSearchBtn: false,
    showNewBtn: false,
    isMultiSelection: false,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      { title: 'Desc_Number', key: 'rowNumber', isSortable: false, width: '30%' },
      { title: 'Desc_Message', key: 'message', isSortable: false, width: '70%' },
    ]
  };
  constructor(private coreSession: CoreSession,
    private modalService: NgbModal) { }

  ngOnInit() {
    if(this.importDataType === ImportDataTypes.AppData) {
      this.tableData.columns[1].useTranslate = true;
    }
    this.dataSource = {
      totalItems: this.importedExcelErrors.length,
      data: this.importedExcelErrors
    };
  }

  ngOnChanges(){
    if(this.invalidExcelRowsDialog){
      this.dataSource = null;
      this.dataSource = {
        totalItems: this.importedExcelErrors.length,
        data: this.importedExcelErrors
      };
    }
  }

  public showDialog() {
    this.dataSource = {
      data: this.importedExcelErrors,
      totalItems: this.importedExcelErrors.length
    }
    return this.modalService.open(this.invalidExcelRowsDialog, { centered: true, size: 'lg', backdrop: 'static' }).result.then(
      (result) => {
        return result;
      });

  }

  closeDialog() {
    this.onCloseDialog.emit();
  }

}
