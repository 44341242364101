export class CustomerPointsBalanceModel {

    customerId: number;
    outletId: number;
    customerCode: string;
    customerName: string;
    customerCodeName: string;
    outletCode: string;
    outletName: string;
    outletCodeName: string;
    pointsBalance: number;
    actionId: number;
    oldPointsBalance: number;
    newPointsBalance: number;
    note: string;

    CustomerPointsBalanceModel() {
        this.customerId = -1;
        this.outletId = -1;
        this.customerCode = '';
        this.customerName = '';
        this.customerCodeName = '';
        this.outletCode = '';
        this.outletName = '';
        this.outletCodeName = '';
        this.pointsBalance = 0;
        this.actionId = -1;
        this.oldPointsBalance = 0;
        this.newPointsBalance = 0;
        this.note = null;
    }
}