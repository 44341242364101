import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CoreSession } from '../../core/core.session';
import { ConstantMessages } from '../models/constants/constant-message';
import { RowOperation } from '../models/enums/shared-table-operation.enum';


@Injectable({
  providedIn: "root"
})
export class PdfCreatorService {
  private url = "";
  constructor(private http: HttpClient,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    @Inject("BASE_URL") baseUrl: string) {
    this.url = baseUrl + "PdfCreator";
  }


  ExportReport(transaction: any, isDownLoad?, isInvoiceOrderMode?) {
    const urlValue = this.url + "/ExportReport";
    if(transaction.isReprintReport){
      this.coreSession.ModalLoading.Show();
    }
    this.http.post(urlValue, transaction, { responseType: "blob" }).map(res => {
      var blob = new Blob([res], { type: "application/pdf" });
      return blob;
    }).subscribe(
      success => {
        const fileURL = URL.createObjectURL(success);

        if (isDownLoad == RowOperation.downLoad) {
          const downloadLink = document.createElement("a");
          downloadLink.href = fileURL;
          downloadLink.download = transaction.transactionId;
          downloadLink.click();
        } else {
          if (isInvoiceOrderMode) {
            const newFileURL = fileURL + "#toolbar=0"; // This removes the ToolBar 'DownLoad Button' in the PDF.
            window.open(newFileURL, "_blank");
          } else {
            const newFileURL = fileURL;
            window.open(newFileURL, "_blank");
          }
        }

        if(transaction.isReprintReport){
          this.coreSession.ModalLoading.Hide();
        }
      },
      err => {
        if (transaction.isReprintReport) {
          this.coreSession.ModalLoading.Hide();
        }
      }
    );
  } 
}
