import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { PricesAndOffersService } from '../../../../content/master-data-definition/prices-and-offers-management/prices-and-offers.service';
import { PriceService } from '../../../services/data-definition/prices-and-offers/price.service';

@Component({
  selector: 'app-price-list-types-filter',
  templateUrl: './price-list-types-filter.component.html',
  styleUrls: ['./price-list-types-filter.component.css']
})
export class PriceListTypesFilterComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Output() selectedType = new EventEmitter();

  priceListTypeDataSource: any[];
  isPriceListTypesLoading = false;
  isRequired = false;

  constructor(private priceService:PriceService) { }

  ngOnInit() {
    if(this.parentForm.get('priceListTypeId').validator != null){
      const validator = this.parentForm.get('priceListTypeId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populatePriceListTypesList();
  }
  populatePriceListTypesList(){
    this.isPriceListTypesLoading = true;
    this.priceService.getPriceListTypes().subscribe(result => {
      this.priceListTypeDataSource = result.data;
      this.isPriceListTypesLoading = false;
    });
  }
onChangeType(selectedType:any){
  this.selectedType.emit(selectedType);
}
}
