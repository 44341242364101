import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MasterDataService } from '../../../content/master-data-definition/master-data.service';
import { CoreSession } from '../../../core/core.session';
import { ConstantMessages } from '../../models/constants/constant-message';
import { MasterDataScreens, MasterDataStatus } from '../../models/enums/master-data-screens';
import { ReasonModel } from '../../models/reason-model/reason-model';
import { SharedDialogComponent } from '../shared-dialog/shared-dialog.component';

@Component({
  selector: 'app-activation-deactivation-reason-entry',
  templateUrl: './activation-deactivation-reason-entry.component.html',
  styleUrls: ['./activation-deactivation-reason-entry.component.css']
})
export class ActivationDeactivationReasonEntryComponent implements OnChanges {
  @ViewChild('templateEntry', { static: true }) templateEntry: SharedDialogComponent;
  @Input() entryObject = new ReasonModel();
  @Input() showReasonDialog = false;
  @Output('Result') result = new EventEmitter();
  @Output('isContainsData') isContainsData = new EventEmitter();
  reasonsList: [];
  reasonId: number;
  constructor(
    public coreSession: CoreSession,
    private translateService: TranslateService,
    private masterDataService: MasterDataService
  ) {
  }

  ngOnChanges() {
    if (this.entryObject)
      this.fillActivateDeactivateReason();
  }

  fillActivateDeactivateReason() {
    this.reasonId = null;
    this.coreSession.ModalLoading.Show();
    this.masterDataService.getActivateDeactivateReason(this.entryObject.screenId, this.entryObject.status).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data && response.data.length > 0) {
            this.reasonsList = response.data;
            this.entryObject.isContainsData = true;
            this.showEntryModalReason();
          } else {
            this.entryObject.isContainsData = false;
            //this.onCloseClicked();
          }
          this.result.emit(this.entryObject.isContainsData)
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      })
  }



  onDialogResult(event) {
    if (this.reasonId) {
      if (event && event.saveCLicked) {
        let auditMaster = {
          screenID: this.entryObject.screenId,
          referenceId: this.entryObject.referenceId,
          reasonId: this.reasonId,
          status: this.entryObject.status
        };
        this.coreSession.ModalLoading.Show();
        this.masterDataService.insertAuditMasterReason(auditMaster).subscribe(
          result => {
            this.coreSession.ModalLoading.Hide();
            this.onCloseClicked();
            if (result.status != null && result.status >= 0) {
              //this.result.emit();
              this.entryObject.isContainsData = true;
              this.result.emit(this.entryObject.isContainsData)
            } else {
              this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), result.message);
            }
          },
          error => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
          }
        );
      }
    } else {
      this.coreSession.showWarrning(this.translateService.instant(ConstantMessages.WarningCaption), this.translateService.instant(ConstantMessages.MsgSelectReason));
      return;
    }
  }


  showEntryModalReason() {
    this.templateEntry.Show(true).then(
      (res) => {
      }
    );
  }

  onCloseClicked() {
    this.templateEntry.Close();
  }

}
