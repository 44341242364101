export enum PromotionOutputDetailTypes {
  DiscountAmount = 1,
  DiscountPercentage = 2,
  ItemQuantity = 3,
  SummationOverGroup = 4,
  GroupEqualQuantity = 5,
  DiscountAmountForItem = 6,
  DiscountPercentageForItem = 7,
  DiscountAmountForGroupItems = 8,  // foreach item in group
  DiscountPercentageForGroupItems = 9, // foreach item in group
  FreeItemsWithCertainValue = 10,
  AllItemDiscountAmount = 11,
  AllItemDiscountPercentage = 12,
  GetCertainQuantityFromGiftItem = 13,
  ItemBatchQuantity = 14,
  DiscountAmountForItemBatch = 15,
  DiscountPercentageForItemBacth = 16,
  RetailContractDiscount = 17,
  OpenSteps = 18,
  WholesalesContractsDiscount = 19,
  CustomerPoints = 20,
  SimpleTargetItemQuantity = 21,
  SimpleTargetSummationOverGroup = 22,
  FreeItemsOnInvoicePercentage = 23, // selection free items from selected group
  DynamicTargetDiscount = 24,
  DynamicTargetSummationOverGroup = 25,
  SummationOverGroupOnTypeLevel = 26,
  DiscountAmountOverGroup = 27,
  FixedPriceOverItem = 28,
  FixedPriceOverAllItem = 29,
  FixedPriceOverGroup = 30,
  DiscountPercentageOrQtyOverGroupWithSameAmount = 31,
  DiscountPercentageForGroupItemsOnTypeLevel = 32,
  FixedPriceOverGroupOnTypeLevel = 33,
  DiscountAmountOverTransaction = 34
}
