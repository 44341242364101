import { Component, OnInit } from "@angular/core";
import { CoreSession } from "../../core/core.session";

@Component({
  selector: "app-no-menus-available",
  templateUrl: "./no-menus-available.component.html",
  styleUrls: ["./no-menus-available.component.css"],
})
export class NoMenusAvailableComponent implements OnInit {
  noMenusMsg = "Desc_No_Menus_Available";
  constructor(public coreSession: CoreSession) {}

  ngOnInit() {}
}
