import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { ItemCategoryModel } from '../../../models/Item/item.model';
import { FormGroup, AbstractControl } from '@angular/forms';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { EchoService } from '../../../../echo/echo.service';
import { ItemCategoryService } from '../../../services/data-definition/product/item-category.service';

@Component({
  selector: 'app-item-category-filter',
  templateUrl: './item-category-filter.component.html',
  styleUrls: ['./item-category-filter.component.css']
})
export class ItemCategoryFilterComponent implements OnInit, OnChanges {
  allCategoryList: ItemCategoryModel[];
  filteredCategoryList: any[] = [];
  isLoading = false;
  @Input() parentForm: FormGroup;
  @Input() hiddenSelected: false;
  @Input() divisionId: number = -1;
  @Input() filterByDivision: false;
  @Input() isCustomerApp: false;
  @Output() onCategorySelected = new EventEmitter();
  isRequired = false;
  constructor(private echoService: EchoService,
    private itemCategoryService: ItemCategoryService) { }

  ngOnInit() {
    if (this.parentForm.get('itemCategoryId').validator != null) {
      const validator = this.parentForm.get('itemCategoryId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
  }
  ngOnChanges() {
    if (this.hiddenSelected && this.filterByDivision) {
      this.parentForm.get('itemCategoryId').setValue(null);
    }
    // if list is empty then fill all categories
    if (!this.allCategoryList || this.allCategoryList.length === 0) {
      this.populateItemCategory();
    } else {
      // get category of selected division
      this.fillCategoryFilteredByDivision();
    }
    // if (this.filterByDivision) {
    //   this.divisionId = this.parentForm.get('divisionId').value;
    // }

  }
  populateItemCategory() {
    this.isLoading = true;
    if(this.isCustomerApp){
      this.echoService.getEchoCategories(this.divisionId > 0 ? this.divisionId : -1).subscribe(result => {
        this.allCategoryList = result.data;
        this.fillCategoryFilteredByDivision();
        this.isLoading = false;
      });
    }else{
      this.itemCategoryService.getAllCategories(-1).subscribe(result => {
        this.allCategoryList = result.data;
        this.fillCategoryFilteredByDivision();
        this.isLoading = false;
      });
    }

  }
  fillCategoryFilteredByDivision() {
    this.filteredCategoryList = [];
    if (this.divisionId && this.divisionId > 0) {
      this.filteredCategoryList = this.allCategoryList.filter(x => x.divisionId === this.divisionId);
    } else {
      this.filteredCategoryList = this.allCategoryList;
    }
  }
  onCategoryChanged(category: any) {
    this.onCategorySelected.emit(category);

  }
}
