import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CoreSession } from "../../../../core/core.session";
import { ResponseModel } from "../../../models/api-models/api-models";

@Injectable({
  providedIn: 'root'
})

export class VehicleService {

  private url = "";
  httpOptions;
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession
  ) {
    this.url = baseUrl + "Vehicle";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  getVehiclesListSharedTable(vehiclesFilter: any) {
    const urlValue: string = this.url + '/GetVehicleListSharedTable';
    return this.http.post(urlValue, JSON.stringify(vehiclesFilter), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  getVehicleData(vehicleId: number) {
    const urlValue = this.url + '/GetVehicleData';
    return this.http
      .post<ResponseModel>(urlValue, vehicleId)
      .map(response => {
        return response;
      });
  }

  deleteVehicle(warehouseId: number) {
    const urlValue: string = this.url + '/deleteVehicle';
    return this.http.post(urlValue, warehouseId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  deactivateVehicle(warehouseId: number) {
    const urlValue: string = this.url + '/UpdateVehicleFromActiveToInactive';
    return this.http.post(urlValue, warehouseId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  activateVehicle(warehouseId: number) {
    const urlValue: string = this.url + '/UpdateVehicleFromInactiveToActive';
    return this.http.post(urlValue, warehouseId, this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  getVehicleTypes() {
    const urlValue: string = this.url + '/GetVehicleTypes';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response.data;
    });
  }
  getVehicleAvailableWarehousesToAdd(vehicleFilter) {
    const urlValue: string = this.url + '/GetVehicleAvailableWarehousesToAdd';
    return this.http.post<ResponseModel>(urlValue, vehicleFilter).map(response => {
      return response.data;
    });
  }
  saveVehicle(vehicle: any, isEditMode: boolean) {
    const urlValue: string = this.url + (isEditMode ? '/UpdateVehicle' : '/InsertVehicle');
    (urlValue);
    return this.http.post(urlValue, JSON.stringify(vehicle), this.httpOptions).map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    );
  }

  getEmployeeLoadVehicle(vehicleId: number) {
    const urlValue: string = this.url + '/GetEmployeeLoadingVehicle';
    return this.http
      .post<ResponseModel>(
        urlValue, vehicleId
      )
      .map(response => {
        return <any>response.data;
      });
  }

  getAllVehicles(vehicleFilter: any) {
    const urlValue: string = this.url + '/GetAllVehicles';
    return this.http.post<ResponseModel>(urlValue, vehicleFilter).map(response => {
      return <any>response.data;
    });
  }
}
