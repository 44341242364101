import { ConstantStorage } from "../../shared/models/constants/constant-stroage";
import {
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { ConstantURLs } from "../../shared/models/constants/constant-URL";
import { AuthGuard } from "./auth.gurad";
import "rxjs/add/operator/catch";
import { of } from "rxjs";

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  public constructor(private router: Router, private authGuard: AuthGuard) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(ConstantStorage.TokenName);
    let languageId = localStorage.getItem(ConstantStorage.LanguageId);

    let authReq = req.clone({
      headers: req.headers.set("Authorization", "Bearer " + token),
      reportProgress: true
    });    

    if(!languageId){
      languageId = "1" ;
    }
    
    let authReqWithLanguage = req.clone({
      headers: authReq.headers.append("LanguageId", languageId),
      reportProgress: true
    });

    return next.handle(authReqWithLanguage).catch(x => this.handleAuthError(x));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      localStorage.removeItem(ConstantStorage.TokenName);
      localStorage.removeItem(ConstantStorage.CurrentOperator);
      localStorage.removeItem(ConstantStorage.generalData);
      this.router.navigate(["/" + ConstantURLs.loginURL]);
      //navigate /delete cookies or whatever
      // this.router.navigateByUrl(`/login`);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return Observable.of(err.message);
    }
    return Observable.throw(err);
  }
}
