import { Component, OnInit, ViewChild } from '@angular/core';
import { NavBarService } from '../sidebar/sidebar.service';
import { ModalLoadingComponent } from '../../shared/components/modal-loading/modal-loading.component';
import { CoreSession } from '../core.session';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
   isMinimized = true;
   isSettingClicked = true;
   @ViewChild('loadingModal', { static: true }) loadingModal: ModalLoadingComponent;
  constructor(
    private navService: NavBarService,
    private coreSession: CoreSession) {
  }

  ngOnInit() {
    this.coreSession.ModalLoading = this.loadingModal;
    this.navService.MinimizedClicked.subscribe(
      (isMin: boolean) => {
        this.isMinimized = isMin;
      }
    );
    this.navService.SettingsClicked.subscribe(
      (setting: boolean) => {
        this.isSettingClicked = setting;
      }
    );
  }
}
