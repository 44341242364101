export enum WarehouseTransactionOperation {
  LoadRequest = 1,
  LoadAffectedStockInFO = 2,
  FOTransferIn = 3,
  FOTransferOut = 4,
  Offload = 5,
  InputVoucher = 6,
  OutputVoucher = 7,
  LoadOut = 8,
  StockLoadIn = 9,
  ReturnsLoadIn = 10,
  Replenish = 11,
  Remove = 12,
  DamagedStock = 13,
  VirtualLoadRequest = 14,
  VirtualOffloadRequest = 15,
  ExtraLoad = 16,
  ManufacturingDefects = 17,
  DirectSalesFromMainWarehouse = 18,
  DirectSalesFromVehicle = 19,
  MarkedNonSellableItems = 20,
  TransferIn = 21,
  TransferOut = 22,
  StockCounting = 23,
  DirectReturnToVehicle = 24,
  SerialReassignment = 25,
  MidDayLoad = 26,
  BackToStoreOffload = 27,
  SendToHelloJordan = 28,
  SendToMainWarehouse = 29,
  SerialsAggregation = 30,
  QualityControl = 31,
  ROITransferOut = 32,
  ROITransferIn = 33,
  ProductionIn = 34,
  ProductionOut = 35,
  ReceiveHelloJordan = 36,
  ResendHelloJordan = 37,
  UnlockSerials = 40,
  BackToStoreReset = 41
}
