import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { KPIService } from '../../../../content/performance/kpi/kpi.service';
import { ConstantMessages } from '../../../models/constants/constant-message';
import { KPISubType } from '../../../models/enums/kpi/kpi-sub-type-types';
import { KPIType } from '../../../models/enums/kpi/kpi-type';
import { KPIValueType } from '../../../models/enums/scorecard/kpi-value-type.enum';
import { CommissionService } from '../../../services/achievements/commission.service';
import { BrandService } from '../../../services/data-definition/product/brand.service';
import { DivisionService } from '../../../services/data-definition/product/division.service';
import { ItemCategoryService } from '../../../services/data-definition/product/item-category.service';
import { ItemGroupService } from '../../../services/data-definition/product/item-group.service';
import { ItemService } from '../../../services/data-definition/product/item.service';

@Component({
  selector: 'app-kpi-filter',
  templateUrl: './kpi-filter.component.html',
  styleUrls: ['./kpi-filter.component.css']
})
export class KpiFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() parentForm: FormGroup;

  @Input() currentKPITypeFormControlName = "kpiTypeId";
  @Input() currentKPISubTypeFormControlName = "kpiSubTypeId";
  @Input() currentDetailFormControlName = "detailId";
  @Input() currentValueTypeFormControlName = "valueTypeId";
  @Input() events: Observable<void>;

  currentPackTypeFormControlName = "packTypeId"; // this value cannot be changed as this filter uses packTypeFilter which depends on this value.

  @Input() showKpiSubType: boolean = true;
  @Input() showDetail: boolean = true;
  @Input() showValueType: boolean = true;
  @Input() showPackType: boolean = true;

  @Input() kpiTypeControlId: string = 'kpiType';
  @Input() kpiSubTypeControlId: string = 'kpiSubType';
  @Input() detailControlId: string = 'detail';
  @Input() valueTypeControlId: string = 'valueType';
  @Input() packTypeControlId: string = 'packTypeId';
  @Input() commissionControlId: string = 'commissionControlId';
  @Input() commissionIdControlName = 'commissionId';
  @Input() isMultiSelect: boolean = false;
  @Input() showCommission: boolean = false;
  @Output() onChangeKPIType = new EventEmitter();
  @Output() onChangeKPISubType = new EventEmitter();
  @Output() onChangeDetail = new EventEmitter();
  @Output() onChangeValueType = new EventEmitter();
  @Output() onChangePackType = new EventEmitter();
  @Output() onChangeCommission = new EventEmitter();
  isKpiTypeRequired: boolean = false;
  isKpiSubTypeRequired: boolean = false;
  isDetailRequired: boolean = false;
  isValueTypeRequired: boolean = false;
  isPackTypeRequired: boolean = false;

  kpiTypeDataSource: any[] = [];
  kpiSubTypeDataSource: any[] = [];
  detailDataSource: any[] = [];
  valueTypeDataSource: any[] = [];

  isKpiTypeLoading: boolean = false;
  isDetailLoading: boolean = false;

  detailControlBindValue: string = '';
  detailControlBindLabel: string = '';
  detailLabel: string = '';

  itemsDataSource: any[] = [];
  itemGroupsDataSource: any[] = [];
  itemCategoriesDataSource: any[] = [];
  brandsDataSource: any[] = [];
  divisionDataSource: any[] = [];

  hideKpiSubTypeForCurrentKpiType: boolean = true;
  hideDetailForCurrentKpiSubType: boolean = true;
  hidePackTypeForCurrentValueType: boolean = true;

  initialFormValidators: any[] = [];
  commissionDataSource: any[] = [];
  selectedKpiTypeId: number;
  selectedKpiSubTypeId: number;
  selectedDetailId: number;
  selectedvalueTypeId: number;
  selectedPackTypeId: number;
  selectedDetailIds: any[] = [];
  resetSubscription: any;



  dynamicTypeDropdownSettings = {
    singleSelection: false,
    idField: 'detailId',
    textField: 'detailDescription',
    selectAllText: this.translateService.instant(ConstantMessages.SelectAllCaption),
    unSelectAllText: this.translateService.instant(ConstantMessages.UnSelectAllCaption),
    searchPlaceholderText: this.translateService.instant(ConstantMessages.SearchCaption),
    allowSearchFilter: true,
    itemsShowLimit: 1,
  };
  constructor(private kpiService: KPIService,
    private itemService: ItemService,
    private itemGroupService: ItemGroupService,
    private itemCategoryService: ItemCategoryService,
    private brandService: BrandService,
    private divisionService: DivisionService,
    private translateService: TranslateService,
    private commissionService: CommissionService) { }

  ngOnInit(): void {
    this.resetDetails();
    this.dynamicTypeDropdownSettings.selectAllText = this.translateService.instant(ConstantMessages.SelectAllCaption);
    this.dynamicTypeDropdownSettings.unSelectAllText = this.translateService.instant(ConstantMessages.UnSelectAllCaption);
    this.dynamicTypeDropdownSettings.searchPlaceholderText = this.translateService.instant(ConstantMessages.SearchCaption);

    this.setValidators();
    this.populateKPITypes();
    this.populateValueTypeDS(-1);
    if (this.showCommission) {
      this.populateCommissionList();
    }
  }

  resetDetails() {
    this.resetSubscription = this.events.subscribe(() => {
      this.onkpiTypeSelected(null);
    });
  }

  ngOnChanges(): void {
    this.selectedKpiTypeId = this.parentForm.get(this.currentKPITypeFormControlName).value;
    this.selectedKpiSubTypeId = this.parentForm.get(this.currentKPISubTypeFormControlName).value;
    if (this.isMultiSelect) {
      this.selectedDetailIds = this.parentForm.get(this.currentDetailFormControlName).value;
    } else {
      this.selectedDetailId = this.parentForm.get(this.currentDetailFormControlName).value;
    }

    this.selectedvalueTypeId = this.parentForm.get(this.currentValueTypeFormControlName).value;
    this.selectedPackTypeId = this.parentForm.get(this.currentPackTypeFormControlName).value;
    if (this.isMultiSelect) {
      this.setisMultiSelectDetailValidator();
    }
  }

  ngOnDestroy(): void {
    this.resetSubscription.unsubscribe();
  }


  populateKPITypes() {
    this.isKpiTypeLoading = true;
    this.kpiService.getKPITypes().subscribe(result => {
      this.isKpiTypeLoading = false;
      if (result) {
        this.kpiTypeDataSource = result;
        if (this.selectedKpiTypeId > 0) {
          let selectedKpiObject = this.kpiTypeDataSource.find(kpiType => kpiType.kpiTypeId === this.selectedKpiTypeId);
          this.kpiTypeChanged(selectedKpiObject);
        }
      }
    });
  }

  populateValueTypeDS(kpiTypeId: number, kpiSubtypeId: number = -1) {
    this.valueTypeDataSource = [];
    let value = {
      valueTypeId: KPIValueType.Value.valueOf(),
      valueTypeDescription: this.translateService.instant("Desc_Value")
    };

    let volume = {
      valueTypeId: KPIValueType.Volume.valueOf(),
      valueTypeDescription: this.translateService.instant("Desc_Volume")
    };

    this.valueTypeDataSource.push(value);
    if (kpiTypeId !== KPIType.Collection.valueOf() && kpiTypeId !== KPIType.Productive.valueOf() && kpiSubtypeId != KPISubType.NoOfInvoices.valueOf() && kpiTypeId !== KPIType.Merchandizer.valueOf() && kpiSubtypeId != KPISubType.ShelfShare.valueOf() && kpiSubtypeId != KPISubType.Pyramids.valueOf()) {
      this.valueTypeDataSource.push(volume);
    }
  }

  onkpiTypeSelected(kpiType: any) {
    this.selectedDetailId = -1; // reset detail related to KpiSubType
    this.selectedKpiSubTypeId = -1; // reset kpiSubType related to KpiType
    this.selectedPackTypeId = -1; // reset packtype related to value type
    this.selectedvalueTypeId = -1; // reset value type related to kpiType
    this.selectedDetailIds = [];

    this.parentForm.get(this.currentKPISubTypeFormControlName).setValue(null);
    this.parentForm.get(this.currentValueTypeFormControlName).setValue(null);

    this.parentForm.get(this.currentKPISubTypeFormControlName).setValidators(null);
    this.parentForm.get(this.currentPackTypeFormControlName).setValidators(null);

    this.resetDetailFormControlInfo();

    this.hideKpiSubTypeForCurrentKpiType = true;
    this.kpiTypeChanged(kpiType);
    this.onChangeKPIType.emit(kpiType);
  }

  onkpiSubTypeSelected(kpiSubType: any) {
    this.resetDetailFormControlInfo();

    this.kpiSubTypeChanged(kpiSubType);
    this.onChangeKPISubType.emit(kpiSubType);
  }

  kpiTypeChanged(kpiType: any) {
    //currentDetailFormControlName
    if (kpiType && kpiType != undefined) {
      this.kpiSubTypeDataSource = kpiType.kpiSubTypeList ? kpiType.kpiSubTypeList : [];
      if (this.kpiSubTypeDataSource.length === 0) {
        // no subtypes for current kpi type-> 1. hide kpiSubType Dropdown, 2. reset selected kpiSubType value, 3. set validators = null
        this.hideKpiSubTypeForCurrentKpiType = true;
        this.parentForm.get(this.currentKPISubTypeFormControlName).setValue(null);
        this.parentForm.get(this.currentKPISubTypeFormControlName).setValidators(null);
        this.parentForm.get(this.currentKPISubTypeFormControlName).updateValueAndValidity();
      } else {
        // kpi has subtypes-> show kpiSubtype downdown, 2. set kpiSubType validators based on provided (parentForm), 3. if isEdit set kpiSubType value.
        this.hideKpiSubTypeForCurrentKpiType = false;
        this.setKpiSubTypeValidators();
        this.setValueTypeValidators();
        if (this.selectedKpiSubTypeId > -1) {
          let kpiSubTypeObject = this.kpiSubTypeDataSource.find(subType => subType.kpiSubTypeId === this.selectedKpiSubTypeId);
          this.kpiSubTypeChanged(kpiSubTypeObject);
        }
      }
    }
    let valueType = this.selectedvalueTypeId > 0 ? this.valueTypeDataSource.find(valueType => valueType.valueTypeId === this.selectedvalueTypeId) : null;
    this.valueTypeChanged(valueType);
    this.populateValueTypeDS(kpiType ? kpiType.kpiTypeId : -1);
  }

  kpiSubTypeChanged(kpiSubType) {
    if (kpiSubType && kpiSubType != undefined) {
      switch (kpiSubType.kpiSubTypeId) {
        case KPISubType.Items.valueOf(): {
          this.populateItemsList();
          this.setDetailFormControlInfo('Desc_Item', this.isMultiSelect ? false : true, 'itemId', 'itemCodeName');
          this.populateValueTypeDS(kpiSubType.kpiTypeId, KPISubType.Items.valueOf());
          break;
        }
        case KPISubType.ItemGroups.valueOf(): {
          this.populateItemGroupsList();
          this.setDetailFormControlInfo('Desc_Item_Group', true, 'itemGroupId', 'itemGroupName');
          this.populateValueTypeDS(kpiSubType.kpiTypeId, KPISubType.ItemGroups.valueOf());
          break;
        }
        case KPISubType.ItemCategory.valueOf(): {
          this.populateItemCategories();
          this.setDetailFormControlInfo('Desc_Item_Category', true, 'itemCategoryId', 'categoryNameCode');
          this.populateValueTypeDS(kpiSubType.kpiTypeId, KPISubType.ItemCategory.valueOf());
          break;
        }
        case KPISubType.ItemBrand.valueOf(): {
          this.populateItemBrands();
          this.setDetailFormControlInfo('Desc_Brand', true, 'brandId', 'brandName');
          this.populateValueTypeDS(kpiSubType.kpiTypeId, KPISubType.ItemBrand.valueOf());
          break;
        }
        case KPISubType.Division.valueOf(): {
          this.populateDivisions();
          this.setDetailFormControlInfo('Desc_Division', true, 'divisionId', 'divisionCodeName');
          this.populateValueTypeDS(kpiSubType.kpiTypeId, KPISubType.Division.valueOf());
          break;
        }
        case KPISubType.ItemDistribution.valueOf(): {
          this.populateItemsList();
          this.setDetailFormControlInfo('Desc_Item', this.isMultiSelect ? false : true, 'itemId', 'itemCodeName');
          this.populateValueTypeDS(kpiSubType.kpiTypeId, KPISubType.ItemDistribution.valueOf());
          break;
        }
        case KPISubType.NoOfInvoices.valueOf(): {
          this.setDetailFormControlInfo('', false, '', '');
          this.populateValueTypeDS(kpiSubType.kpiTypeId, KPISubType.NoOfInvoices.valueOf());
          break;
        }
        case KPISubType.ShelfShare.valueOf(): {
          this.populateValueTypeDS(kpiSubType.kpiTypeId, KPISubType.ShelfShare.valueOf());
          break;
        }
        case KPISubType.Pyramids.valueOf(): {
          this.populateValueTypeDS(kpiSubType.kpiTypeId, KPISubType.Pyramids.valueOf());
          break;
        }
        default: {
          this.populateKPISubTypeDynamicList(kpiSubType.kpiTypeId, kpiSubType.kpiSubTypeId, kpiSubType.kpiSubTypeDescription);
        }
      }
    } else {
      this.setDetailFormControlInfo('', false, '', '');
    }
  }

  onDetailSelected(detail: any) {
    this.onChangeDetail.emit(detail);
  }

  onValueTypeSelected(valueType: any) {
    this.hidePackTypeForCurrentValueType = true;
    this.resetPackTypeFormControlInfo();
    this.valueTypeChanged(valueType);
    this.onChangeValueType.emit(valueType);
  }

  onCommissionSelected(event) {
    this.onChangeCommission.emit(event);
  }

  valueTypeChanged(valueType: any) {
    if (valueType && valueType != undefined && valueType.valueTypeId === KPIValueType.Volume) {
      // value type changed to (Volume)-> 1. show packType dropdown, 2. set PackTypeId validators from provided (parentForm),
      this.setPackTypeValidators();
      this.hidePackTypeForCurrentValueType = false;
    } else {
      // value type changed to (value) OR was Reset-> 1. hide packType downdown, 2. reset packTypeId value , 3. set packTypeId validators = null
      this.hidePackTypeForCurrentValueType = true;
      this.resetPackTypeFormControlInfo();
    }
  }

  onPackTypeSelected(packType: any) {
    this.onChangePackType.emit(packType);
  }

  resetPackTypeFormControlInfo() {
    this.isPackTypeRequired = false;
    this.parentForm.get(this.currentPackTypeFormControlName).setValue(null);
    this.parentForm.get(this.currentPackTypeFormControlName).setValidators(null);
    this.parentForm.get(this.currentPackTypeFormControlName).updateValueAndValidity();
    this.selectedPackTypeId = -1;
  }

  resetDetailFormControlInfo() {
    this.parentForm.get(this.currentDetailFormControlName).setValue(null);
    this.detailLabel = '';
    this.detailDataSource = [];
    this.hideDetailForCurrentKpiSubType = true;
    this.detailControlBindLabel = '';
    this.detailControlBindValue = '';
    this.parentForm.get(this.currentDetailFormControlName).setValidators(null);
    this.parentForm.get(this.currentDetailFormControlName).updateValueAndValidity();
  }

  setDetailFormControlInfo(labelDescription: string, showDetail: boolean, detailBindValue: string, detailBindLabel: string) {

    this.dynamicTypeDropdownSettings = {
      singleSelection: false,
      idField: detailBindValue,
      textField: detailBindLabel,
      selectAllText: this.translateService.instant(ConstantMessages.SelectAllCaption),
      unSelectAllText: this.translateService.instant(ConstantMessages.UnSelectAllCaption),
      searchPlaceholderText: this.translateService.instant(ConstantMessages.SearchCaption),
      allowSearchFilter: true,
      itemsShowLimit: 1,
    };

    this.detailLabel = labelDescription;
    this.hideDetailForCurrentKpiSubType = !showDetail;
    this.detailControlBindValue = detailBindValue;
    this.detailControlBindLabel = detailBindLabel;
    if (showDetail) {
      this.setDetailValidators();
    } else {
      this.parentForm.get(this.currentDetailFormControlName).setValue(null);
      this.parentForm.get(this.currentDetailFormControlName).setValidators(null);
      this.parentForm.get(this.currentDetailFormControlName).updateValueAndValidity();
      this.selectedDetailId = -1;
      this.selectedDetailIds = [];
    }
  }

  populateItemsList() {
    let itemFilter: any = {
      ItemCategoryId: -1,
      divisionId: -1,
      brandId: -1,
      modelId: -1,
      itemId: -1,
      excludeCompetitorItem: true,
      IsLazyLodingCombo: true,
      selectItemId: -1,
    };
    if (!this.itemsDataSource || this.itemsDataSource.length == 0) {
      this.isDetailLoading = true;
      this.itemService.getItemsListSharedTable(itemFilter).subscribe(result => {
        this.isDetailLoading = false;
        this.itemsDataSource = result && result.data.data ? result.data.data : [];
        this.detailDataSource = this.itemsDataSource;
      });
    } else {
      this.detailDataSource = this.itemsDataSource;
    }
  }

  populateItemGroupsList() {
    if (!this.itemGroupsDataSource || this.itemGroupsDataSource.length == 0) {
      const itemFilter = {
        packId: -1,
        customListFilter: {
          searchFilter: '',
          page: 0,
          pageSize: 1000
        }
      };
      this.isDetailLoading = true;
      this.itemGroupService.getAllItemGroupLazyLoading(itemFilter).subscribe(result => {
        this.isDetailLoading = false;
        this.itemGroupsDataSource = result && result.data.data ? result.data.data : [];
        this.detailDataSource = this.itemGroupsDataSource;

      });
    } else {
      this.detailDataSource = this.itemGroupsDataSource;
    }
  }


  populateItemCategories() {
    if (!this.itemCategoriesDataSource || this.itemCategoriesDataSource.length == 0) {
      this.isDetailLoading = true;
      this.itemCategoryService.getAllCategories(-1).subscribe(result => {
        this.isDetailLoading = false;
        this.itemCategoriesDataSource = result && result.data ? result.data : [];
        this.detailDataSource = this.itemCategoriesDataSource;
      });
    } else {
      this.detailDataSource = this.itemCategoriesDataSource;
    }
  }

  populateItemBrands() {
    if (!this.brandsDataSource || this.brandsDataSource.length == 0) {
      this.isDetailLoading = true;
      this.brandService.getAllBrands().subscribe(result => {
        this.isDetailLoading = false;
        this.brandsDataSource = result && result.data ? result.data : [];
        this.detailDataSource = this.brandsDataSource;
      });
    } else {
      this.detailDataSource = this.brandsDataSource;
    }
  }

  populateDivisions() {
    if (!this.divisionDataSource || this.divisionDataSource.length == 0) {
      this.isDetailLoading = true;
      this.divisionService.getAllDivisions(-1).subscribe(result => {
        this.isDetailLoading = false;
        this.divisionDataSource = result && result.data ? result.data : [];
        this.detailDataSource = this.divisionDataSource;
      });
    } else {
      this.detailDataSource = this.divisionDataSource;
    }
  }
  populateCommissionList() {
    let filter = {
      commissionModeId: -1,
      commissionTypeId: -1,
      achievementTypeId: -1,
      cretaedBy: -1,
      organizationId: -1,
      excludeShopContract: true
    }
    if (!this.commissionDataSource || this.divisionDataSource.length == 0) {
      this.commissionService.getCommissionList(filter).subscribe(result => {
        this.isDetailLoading = false;
        this.commissionDataSource = result && result.data.data ? result.data.data : [];
      });
    }
  }


  populateKPISubTypeDynamicList(typeId, subTypeId, kpiSubTypeDescription) {
    this.isDetailLoading = true;
    this.kpiService.getKPISubTypeDynamicList(typeId, subTypeId).subscribe(result => {
      this.isDetailLoading = false;
      if (result && result.length > 0) {
        this.detailDataSource = result;
        this.setDetailFormControlInfo(kpiSubTypeDescription, true, 'detailId', 'detailDescription');
      } else {
        this.detailDataSource = [];
        this.setDetailFormControlInfo('', false, '', '');
      }
    });
  }

  setValidators() {
    this.setKpiTypeValidators();
    this.setKpiSubTypeValidators(true);
    this.setDetailValidators(true);
    this.setValueTypeValidators(true);
    this.setPackTypeValidators(true);
    if (this.showCommission) {
      this.setCommissionValidator();
    }

  }

  setKpiTypeValidators() {
    if (this.parentForm.get(this.currentKPITypeFormControlName).validator != null) {
      const validator = this.parentForm.get(this.currentKPITypeFormControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isKpiTypeRequired = true;
      }
    }
    this.parentForm.get(this.currentKPITypeFormControlName).updateValueAndValidity();
  }

  setKpiSubTypeValidators(fromInit: boolean = false) {
    // reads the validator from the original parentForm provided to the filter,
    // as the validators values may changes depending on the selected kpiType (if kpiType has no subTypes, subTypes validator becomes null).
    this.isKpiSubTypeRequired = false;

    let controlValidator: ValidatorFn = fromInit ?
      this.parentForm.get(this.currentKPISubTypeFormControlName).validator :
      this.initialFormValidators.find(obj => obj.formControlName === this.currentKPISubTypeFormControlName).validator;

    if (this.showKpiSubType && controlValidator != null) {
      const validator = controlValidator({} as AbstractControl);
      if (validator && validator.required) {
        this.isKpiSubTypeRequired = true;
        this.parentForm.get(this.currentKPISubTypeFormControlName).setValidators(Validators.required)
      }
    }
    if (fromInit) {
      let controlValidator = {
        formControlName: this.currentKPISubTypeFormControlName,
        validator: this.parentForm.get(this.currentKPISubTypeFormControlName).validator
      }
      this.initialFormValidators.push(controlValidator);
    }
    this.parentForm.get(this.currentKPISubTypeFormControlName).updateValueAndValidity();
  }

  setDetailValidators(fromInit: boolean = false) {
    // reads the validator from the original parentForm provided to the filter,
    // as the validators values may change depending on the selected KpiSubType (if subtype has no details, detail validator becomes null).
    this.isDetailRequired = false;

    let controlValidator: ValidatorFn = fromInit ?
      this.parentForm.get(this.currentDetailFormControlName).validator :
      this.initialFormValidators.find(obj => obj.formControlName === this.currentDetailFormControlName).validator;

    if (this.showDetail && controlValidator != null) {
      const validator = controlValidator({} as AbstractControl);
      if (validator && validator.required) {
        this.isDetailRequired = true;
        this.parentForm.get(this.currentDetailFormControlName).setValidators(Validators.required)
      }
    }
    if (fromInit) {
      let controlValidator = {
        formControlName: this.currentDetailFormControlName,
        validator: this.parentForm.get(this.currentDetailFormControlName).validator
      }
      this.initialFormValidators.push(controlValidator);
    }
    this.parentForm.get(this.currentDetailFormControlName).updateValueAndValidity();
  }

  setValueTypeValidators(fromInit: boolean = false) {
    this.isValueTypeRequired = false;

    let controlValidator: ValidatorFn = fromInit ?
      this.parentForm.get(this.currentValueTypeFormControlName).validator :
      this.initialFormValidators.find(obj => obj.formControlName === this.currentValueTypeFormControlName).validator;


    if (this.showValueType && controlValidator != null) {
      const validator = controlValidator({} as AbstractControl);
      if (validator && validator.required) {
        this.isValueTypeRequired = true;
        this.parentForm.get(this.currentValueTypeFormControlName).setValidators(Validators.required);
      }
    }
    if (fromInit) {
      let controlValidator = {
        formControlName: this.currentValueTypeFormControlName,
        validator: this.parentForm.get(this.currentValueTypeFormControlName).validator
      }
      this.initialFormValidators.push(controlValidator);
    }
    this.parentForm.get(this.currentValueTypeFormControlName).updateValueAndValidity();
  }

  setPackTypeValidators(fromInit: boolean = false) {
    this.isPackTypeRequired = false;

    let controlValidator: ValidatorFn = fromInit ?
      this.parentForm.get(this.currentPackTypeFormControlName).validator :
      this.initialFormValidators.find(obj => obj.formControlName === this.currentPackTypeFormControlName).validator;


    if (this.showPackType && controlValidator != null) {
      const validator = controlValidator({} as AbstractControl);
      if (validator && validator.required) {
        this.isPackTypeRequired = true;
        this.parentForm.get(this.currentPackTypeFormControlName).setValidators(Validators.required);
      }
    }
    if (fromInit) {
      let controlValidator = {
        formControlName: this.currentPackTypeFormControlName,
        validator: this.parentForm.get(this.currentPackTypeFormControlName).validator
      }
      this.initialFormValidators.push(controlValidator);
    }
    this.parentForm.get(this.currentPackTypeFormControlName).updateValueAndValidity();
  }

  setCommissionValidator() {
    this.parentForm.get(this.commissionIdControlName).setValidators(Validators.required);
    this.parentForm.get(this.currentPackTypeFormControlName).updateValueAndValidity();
  }

  setisMultiSelectDetailValidator() {
    if (this.isMultiSelect &&
        (this.selectedKpiSubTypeId == KPISubType.Items.valueOf() || this.selectedKpiSubTypeId == KPISubType.ItemDistribution.valueOf() || this.selectedKpiSubTypeId == KPISubType.Pyramids.valueOf() || this.selectedKpiSubTypeId == KPISubType.ShelfShare.valueOf() || this.selectedKpiTypeId == KPIType.Productive.valueOf() || this.selectedKpiTypeId == KPIType.Collection.valueOf() || this.selectedKpiSubTypeId == KPISubType.Invoices.valueOf() || this.selectedKpiSubTypeId == KPISubType.NoOfInvoices.valueOf())) {
      this.parentForm.get(this.currentDetailFormControlName).setValidators(Validators.nullValidator);
    } else {
      this.parentForm.get(this.currentDetailFormControlName).setValidators(Validators.required);
    }
    this.parentForm.get(this.currentDetailFormControlName).updateValueAndValidity();
  }

}
